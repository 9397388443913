import { DropDownList } from '@progress/kendo-react-dropdowns';
import { StackLayout } from '@progress/kendo-react-layout';

export function ListSortOptions<TSortBy>({
    value,
    onChange,
    options,
    defaultValue
}: {
    value?: TSortBy;
    onChange?: (value: TSortBy) => void;
    options: TSortBy[];
    defaultValue: TSortBy;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            <span className="k-flex-shrink-0">Sort by:</span>
            <DropDownList data={options} value={value ?? defaultValue} className="sort-by-picker" onChange={e => onChange?.(e.value)} />
        </StackLayout>
    );
}
