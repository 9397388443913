import { ReducedResearch, researchService } from '../../services/researchService';
import { RemoteDataComboBox } from '../common/remoteDataComboBox';
import { FormFieldProps } from '../ui/inputs';

export function ResearchPicker({ ideaId, id, value, onChange, disabled, valid }: FormFieldProps<ReducedResearch | null | undefined> & { ideaId: string }) {
    return (
        <RemoteDataComboBox
            id={id}
            value={value}
            disabled={disabled}
            valid={valid}
            dataItemKey="id"
            textField="title"
            placeholder="Select research..."
            emptyListText="No research found."
            fetchItems={filter =>
                researchService
                    .getProblemValidationResearchItems(ideaId, undefined, filter ? undefined : 5, filter || undefined)
                    .then(researchList => researchList.research)
            }
            onChange={onChange}
        />
    );
}
