import { StackLayout } from '@progress/kendo-react-layout';
import { Fragment } from 'react';
import { ReactComponent as LeftRightArrowIcon } from '../../icons/arrow-left-right.svg';
import { BoxType, canvasService } from '../../services/canvasService';
import { StableGroupsList } from '../../services/common';
import { JourneyTaskRelatedItem } from '../../services/journeyService';
import { CanvasItemView } from '../canvas/canvasItemView';

export function TaskRelatedItemsView({ items }: { items: JourneyTaskRelatedItem[] }) {
    if (!items.length) return null;

    const itemsGroupedByBox = new StableGroupsList<JourneyTaskRelatedItem, BoxType>(i => i.box);
    itemsGroupedByBox.add(...items);
    const itemsGroupedByBoxArray = itemsGroupedByBox.asArray();

    if (itemsGroupedByBoxArray.length === 1) {
        return (
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1 k-mt-1">
                {items.map(relatedItem => (
                    <CanvasItemView key={relatedItem.itemId} itemId={relatedItem.itemId} box={relatedItem.box} />
                ))}
            </StackLayout>
        );
    }

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4 k-mt-2">
            {itemsGroupedByBoxArray.map((relatedItemsGroup, relatedItemsGroupIndex) => (
                <Fragment key={relatedItemsGroup.key}>
                    {relatedItemsGroupIndex > 0 && <LeftRightArrowIcon className="k-icp-icon k-icp-icon-size-6 k-align-self-center" />}
                    <div className="k-flex-1">
                        <div className="k-fs-sm k-icp-subtle-text k-mb-1">{canvasService.getBoxMetadata(relatedItemsGroup.key).title}</div>
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1">
                            {relatedItemsGroup.items.map(relatedItem => (
                                <CanvasItemView key={relatedItem.itemId} itemId={relatedItem.itemId} box={relatedItem.box} />
                            ))}
                        </StackLayout>
                    </div>
                </Fragment>
            ))}
        </StackLayout>
    );
}
