import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnonymousUserZone } from './components/auth/anonymousUserZone';
import { InvisibleReCaptchaScope } from './components/common/invisibleReCaptcha';
import { GlobalErrorBoundary } from './components/errors/globalErrorBoundary';
import { GlossaryTermsListener } from './components/glossary/glossaryTermsListener';
import { CookiesNotice } from './components/legal/cookiesNotice';
import { PlatformNotificationsGroup } from './components/notifications/platformNotifications';
import PopupNotifications from './components/notifications/popupNotifications';
import AppModal from './components/ui/appModal';
import UserZone from './components/user/userZone';
import { useAppNewVersionListener } from './hooks/appHooks';
import { useRealTimeUpdates } from './hooks/realTimeUpdatesHooks';
import { useSoleToggleZone } from './hooks/toggleHooks';
import { ActivationPage } from './pages/auth/activationPage';
import { AuthenticationPage, LoginWithPasswordPage, PendingActivationErrorPage } from './pages/auth/authenticationPage';
import { ForgottenPasswordPage } from './pages/auth/forgottenPasswordPage';
import { RegistrationPage } from './pages/auth/registrationPage';
import { ResetPasswordPage } from './pages/auth/resetPasswordPage';
import { BookMeetingPage } from './pages/booking/bookMeetingPage';
import { CalendarPage } from './pages/calendar/calendarPage';
import { CreateSchedulePage } from './pages/calendar/createSchedulePage';
import { EditSchedulePage } from './pages/calendar/editSchedulePage';
import { EventsPage } from './pages/calendar/eventsPage';
import { SchedulesPage } from './pages/calendar/schedulesPage';
import { ViewSchedulePage } from './pages/calendar/viewSchedulePage';
import CanvasPage from './pages/canvas/canvasPage';
import { CompaniesPage } from './pages/contacts/companiesPage';
import { ContactsPage } from './pages/contacts/contactsPage';
import { CreateCompanyPage } from './pages/contacts/createCompanyPage';
import { CreatePersonPage } from './pages/contacts/createPersonPage';
import { EditCompanyPage } from './pages/contacts/editCompanyPage';
import { EditPersonPage } from './pages/contacts/editPersonPage';
import { PeoplePage } from './pages/contacts/peoplePage';
import { ViewCompanyPage } from './pages/contacts/viewCompanyPage';
import { ViewPersonPage } from './pages/contacts/viewPersonPage';
import ErrorPage from './pages/error/error';
import GlossaryPage from './pages/glossary/glossaryPage';
import { AddCustomerProblemsHypothesisPage } from './pages/hypotheses/addCustomerProblemsHypothesisPage';
import { CustomerProblemsHypothesesPage } from './pages/hypotheses/customerProblemsHypothesesPage';
import { EditHypothesisPage } from './pages/hypotheses/editHypothesisPage';
import { FeasibilityAndViabilityHypothesesPage } from './pages/hypotheses/feasibilityAndViabilityHypothesesPage';
import { GoToMarketHypothesesPage } from './pages/hypotheses/goToMarketHypothesesPage';
import { HypothesesPage } from './pages/hypotheses/hypothesesPage';
import { SolutionHypothesesPage } from './pages/hypotheses/solutionHypothesesPage';
import { ViewHypothesisPage } from './pages/hypotheses/viewHypothesisPage';
import { InterviewModalPage } from './pages/interviews/interviewModalPage';
import { JourneyPage } from './pages/journey/journeyPage';
import { TaskPage } from './pages/journey/taskPage';
import { TestJourneyPage } from './pages/journey/testJourneyPage';
import { WelcomePage } from './pages/journey/welcomePage';
import { AuthLayout } from './pages/layouts/authLayout';
import { ContactsLayout } from './pages/layouts/contactsLayout';
import { HypothesesLayout } from './pages/layouts/hypothesesLayout';
import PlatformLayout from './pages/layouts/platformLayout';
import { PublicTopNavLayout } from './pages/layouts/publicTopNavLayout';
import StartupLayout from './pages/layouts/startupLayout';
import TopNavLayout from './pages/layouts/topNavLayout';
import { AboutPage } from './pages/profile/aboutPage';
import { BillingPortalPage } from './pages/profile/billingPortalPage';
import { ChangePasswordPage } from './pages/profile/changePasswordPage';
import { DeleteAccountPage } from './pages/profile/deleteAccountPage';
import { EditBillingInfoPage } from './pages/profile/editBillingInfoPage';
import EditProfilePage from './pages/profile/editProfilePage';
import { EditSocialInfoPage } from './pages/profile/editSocialInfoPage';
import LogoutPage from './pages/profile/logoutPage';
import { ManageSubscriptionPage } from './pages/profile/manageSubscriptionPage';
import { SetFlagPage } from './pages/profile/setFlagPage';
import { SubscribePage } from './pages/profile/subscribePage';
import { SubscriptionErrorPage } from './pages/profile/subscriptionErrorPage';
import { UpdatePaymentPage } from './pages/profile/updatePaymentPage';
import ViewProfilePage from './pages/profile/viewProfilePage';
import { ResearchDetailsPage } from './pages/research/researchDetailsPage';
import { ResearchHypothesesPage } from './pages/research/researchHypothesesPage';
import { ResearchInsightsPage } from './pages/research/researchInsightsPage';
import { ResearchPage } from './pages/research/researchPage';
import { ResearchSummaryPage } from './pages/research/researchSummaryPage';
import AcceptInvitePage from './pages/startups/acceptInvitePage';
import DifferentUserLoggedErrorPage from './pages/startups/differentUserLoggedPage';
import StartupEditPage from './pages/startups/startupEditPage';
import StartupSettingsPage from './pages/startups/startupSettingsPage';
import StartupsPage from './pages/startups/startupsPage';
import { googleTagManager } from './scripts/googleTagManager';
import './state/realTimeUpdates';
import { store } from './state/store';

const welcomePath = 'welcome';
const CookiePolicyPage = React.lazy(() => import('./pages/legal/cookiePolicyPage'));
const PrivacyPolicyPage = React.lazy(() => import('./pages/legal/privacyPolicyPage'));
const TermsOfUsePage = React.lazy(() => import('./pages/legal/termsOfUsePage'));

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route element={<AppLayout />}>
                        <Route path="" element={<UserZone welcomePath={`/${welcomePath}`} />}>
                            <Route path="" element={<PopupNotifications />}>
                                <Route path="" element={<PlatformLayout />}>
                                    <Route path={welcomePath} element={<WelcomePage />} />

                                    <Route path="startups/:ideaId" element={<StartupLayout />}>
                                        <Route path="journey" element={<JourneyPage />} />
                                        <Route path="journey/tasks/:sequenceTag/:taskTag/:variationTag" element={<TaskPage />} />
                                        <Route path="journey/tasks/:sequenceTag/:taskTag" element={<TaskPage />} />
                                        <Route path="canvas" element={<CanvasPage />} />

                                        <Route path="hypotheses" element={<HypothesesLayout />}>
                                            <Route path="customers-problems/create" element={<AddCustomerProblemsHypothesisPage />} />
                                            <Route path=":hypothesisGroup/:hypothesisId/view" element={<ViewHypothesisPage />} />
                                            <Route path=":hypothesisGroup/:hypothesisId/edit" element={<EditHypothesisPage />} />
                                            <Route path="" element={<HypothesesPage />}>
                                                <Route path="solution" element={<SolutionHypothesesPage />} />
                                                <Route path="gtm" element={<GoToMarketHypothesesPage />} />
                                                <Route path="feasibility" element={<FeasibilityAndViabilityHypothesesPage />} />
                                                <Route path="customers-problems" element={<CustomerProblemsHypothesesPage />} />
                                                <Route index element={<Navigate to="customers-problems" replace={true} />} />
                                            </Route>
                                        </Route>

                                        <Route path="research">
                                            <Route path=":researchId" element={<ResearchDetailsPage />}>
                                                <Route path="hypotheses" element={<ResearchHypothesesPage />} />
                                                <Route path="insights" element={<ResearchInsightsPage />} />
                                                <Route index element={<ResearchSummaryPage />} />
                                            </Route>
                                            <Route index element={<ResearchPage />} />
                                        </Route>

                                        <Route path="calendar" element={<Outlet />}>
                                            <Route path="schedules/create" element={<CreateSchedulePage />} />
                                            <Route path="schedules/:scheduleId/edit" element={<EditSchedulePage />} />
                                            <Route path="schedules/:scheduleId" element={<ViewSchedulePage />} />
                                            <Route path="" element={<CalendarPage />}>
                                                <Route path="schedules" element={<SchedulesPage />} />
                                                <Route index element={<EventsPage />} />
                                            </Route>
                                        </Route>

                                        <Route path="contacts" element={<ContactsLayout />}>
                                            <Route path="people/create" element={<CreatePersonPage />} />
                                            <Route path="people/:personId/edit" element={<EditPersonPage />} />
                                            <Route path="people/:personId" element={<ViewPersonPage />} />
                                            <Route path="companies/create" element={<CreateCompanyPage />} />
                                            <Route path="companies/:companyId" element={<ViewCompanyPage />} />
                                            <Route path="companies/:companyId/edit" element={<EditCompanyPage />} />
                                            <Route path="" element={<ContactsPage />}>
                                                <Route path="companies" element={<CompaniesPage />} />
                                                <Route index element={<PeoplePage />} />
                                            </Route>
                                        </Route>

                                        <Route path="interviews">
                                            <Route path=":interviewId" element={<InterviewModalPage />} />
                                        </Route>

                                        <Route path="glossary" element={<GlossaryPage />} />
                                        <Route path="settings">
                                            <Route path="edit" element={<StartupEditPage />} />
                                            <Route index element={<StartupSettingsPage />} />
                                        </Route>
                                    </Route>

                                    <Route index element={<StartupsPage />} />
                                </Route>

                                <Route path="" element={<TopNavLayout />}>
                                    <Route path="account">
                                        <Route path="edit" element={<EditProfilePage />} />
                                        <Route path="change-password" element={<ChangePasswordPage />} />
                                        <Route path="edit-social" element={<EditSocialInfoPage />} />
                                        <Route path="set-flag/:flag/:flagState" element={<SetFlagPage />} />
                                        <Route path="billing-portal" element={<BillingPortalPage />} />
                                        <Route path="subscription-error" element={<SubscriptionErrorPage />} />
                                        <Route path="subscribe" element={<SubscribePage />} />
                                        <Route path="subscription">
                                            <Route path="edit-billing" element={<EditBillingInfoPage />} />
                                            <Route path="update-payment" element={<UpdatePaymentPage />} />
                                            <Route index element={<ManageSubscriptionPage />} />
                                        </Route>
                                        <Route path="about" element={<AboutPage />} />
                                        <Route path="delete" element={<DeleteAccountPage />} />
                                        <Route index element={<ViewProfilePage />} />
                                    </Route>
                                    <Route path="invites/error/different-user" element={<DifferentUserLoggedErrorPage />} />
                                </Route>
                            </Route>
                        </Route>

                        <Route element={<EnsureTagManagerLayout />}>
                            <Route path="" element={<PublicTopNavLayout suppressLogoLink={false} />}>
                                <Route path="invites/accept/:inviteSecret" element={<AcceptInvitePage />} />
                            </Route>
                            <Route element={<AuthLayout />}>
                                <Route element={<AnonymousUserZone />}>
                                    <Route path="login" element={<AuthenticationPage />} />
                                    <Route path="register" element={<RegistrationPage />} />
                                    <Route path="login/activate" element={<PendingActivationErrorPage />} />
                                    <Route path="login/password" element={<LoginWithPasswordPage />} />
                                    <Route path="forgot-password" element={<ForgottenPasswordPage />} />
                                    <Route path="reset-password" element={<ResetPasswordPage />} />
                                </Route>

                                <Route path="activate" element={<ActivationPage />} />
                            </Route>
                            <Route path="legal" element={<PublicTopNavLayout contentClassName="hex-bottom-right-bg" />}>
                                <Route path="terms-of-use" element={<TermsOfUsePage />} />
                                <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                                <Route path="cookie-policy" element={<CookiePolicyPage />} />
                            </Route>
                            <Route path="bookings" element={<PublicTopNavLayout contentClassName="k-py-4 hex-bottom-right-bg" />}>
                                <Route path=":code" element={<BookMeetingPage />} />
                            </Route>
                            <Route path="invitation/" />
                            <Route path="account/logout" element={<LogoutPage />} />
                            <Route path="journey-test" element={<TestJourneyPage />} />
                            <Route path="403" element={<ErrorPage errorCode={403} />} />
                            <Route path="500" element={<ErrorPage errorCode={500} />} />
                            <Route path="*" element={<ErrorPage errorCode={404} />} />
                        </Route>
                    </Route>
                </Routes>
            </Router>
        </Provider>
    );
}

function AppLayout() {
    return (
        <>
            <GlobalErrorBoundary>
                <AppWrapper />
            </GlobalErrorBoundary>
            <PlatformNotificationsGroup />
            <ToastContainer
                position="bottom-right"
                autoClose={20000}
                stacked
                transition={Slide}
                toastClassName="notifications-popup"
                bodyClassName="notifications-popup-body"
            />
        </>
    );
}

function AppWrapper() {
    useSoleToggleZone();
    const { ContextProvider: RealTimeUpdatesContextProvider, contextValue: realTimeUpdatesContextValue } = useRealTimeUpdates();
    useAppNewVersionListener();

    return (
        <RealTimeUpdatesContextProvider value={realTimeUpdatesContextValue}>
            <Helmet>
                <title>Icanpreneur Platform</title>
                <meta name="viewport" content="width=900" />
            </Helmet>
            <InvisibleReCaptchaScope>
                <Outlet />
                <GlossaryTermsListener />
                <AppModal />
                <CookiesNotice />
            </InvisibleReCaptchaScope>
        </RealTimeUpdatesContextProvider>
    );
}

function EnsureTagManagerLayout() {
    useEffect(() => {
        googleTagManager.ensureInitialized();
    }, []);

    return <Outlet />;
}

export default App;
