import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ActiveAccountView, InvalidActivationView } from '../../components/auth/activation';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { P } from '../../components/ui/typography';
import { UserActivationResult, authenticationService } from '../../services/authenticationService';

export function ActivationPage() {
    const activationInProgress = useRef(false);
    const [queryParams] = useSearchParams();
    const emailAddress = queryParams.get('email');
    const secretCode = queryParams.get('secret');
    const [activationResult, setActivationResult] = useState<UserActivationResult>();

    useEffect(() => {
        if (!emailAddress || !secretCode) {
            setActivationResult(UserActivationResult.Invalid);

            return;
        }

        if (activationInProgress.current) return;
        activationInProgress.current = true;

        (async () => {
            try {
                const activationResult = await authenticationService.activate(emailAddress, secretCode);
                setActivationResult(activationResult);
            } finally {
                activationInProgress.current = false;
            }
        })();
    }, [secretCode, emailAddress]);

    if (typeof activationResult === 'undefined') return <LoadingIndicator size="big" />;
    switch (activationResult) {
        case UserActivationResult.Activated:
            return <ActiveAccountView heading="Your email address is confirmed!">Are you ready to start your entrepreneurial journey?</ActiveAccountView>;
        case UserActivationResult.AlreadyActivated:
            return <ActiveAccountView heading="Email address already confirmed">Your email address has already been confirmed</ActiveAccountView>;
        case UserActivationResult.Expired:
            return (
                <InvalidActivationView heading="Oops, this link has expired">
                    <P>Click the button below if you would like to request new confirmation link.</P>
                </InvalidActivationView>
            );
        case UserActivationResult.Invalid:
            return (
                <InvalidActivationView heading="Oops, this link is invalid">
                    <div>
                        <P className="!k-mb-4">If you have copied the link, make sure you are copying it in its entirety.</P>
                        <P>Click the button below if you would like to request new confirmation link.</P>
                    </div>
                </InvalidActivationView>
            );
    }
}
