import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useGlobalCanvas } from '../../../hooks/canvasHooks';
import { useInsightsCatalogContextFromInsights, useInterviewInsightsCatalog, useMergedInsightsContext } from '../../../hooks/insightsHooks';
import { useInterviewInsights, useInterviewInsightsCoverage, useInterviewQuotes, useInterviewSections } from '../../../hooks/interviewHooks';
import { useInsightsCatalogContextFromResearch } from '../../../hooks/researchHooks';
import { useIntQueryParam } from '../../../hooks/routerHooks';
import { InsightPropertyType, InsightType } from '../../../services/insightsService';
import { Interview, InterviewType } from '../../../services/interviewsService';
import { InterviewQuoteType } from '../../../services/researchService';
import { useAppDispatch } from '../../../state/hooks';
import { saveCanvasInsightParameterInstantValues } from '../../insights/insightEditor';
import { InterviewEntrySelectionContext, InterviewSectionsConversationList, useInterviewEntrySelectionContextValue } from '../entries/interviewEntries';
import { InterviewContentHandle } from '../interviewModal';
import { InterviewTwoColumnLayout } from '../interviewModalLayout';
import { InsightsFromCatalogEditor, InsightsFromCatalogEditorHandle } from './insightsCatalog';
import { findTemplateInCatalog, resolveNewInsightAlternativeSolutionId } from './insightsCommon';

export const InterviewInsightsContent = forwardRef<
    InterviewContentHandle,
    { ideaId: string; interviewId: number; interview?: Interview; isReadOnly?: boolean }
>(function InterviewInsightsContent({ ideaId, interviewId, interview, isReadOnly }, ref) {
    const insightsEditorRef = useRef<InsightsFromCatalogEditorHandle>(null);
    useImperativeHandle(
        ref,
        () => ({
            onComplete() {
                return insightsEditorRef.current?.commit();
            }
        }),
        []
    );

    const { interviewSections } = useInterviewSections(ideaId, interview?.id);
    const insightsCatalog = useInterviewInsightsCatalog(InterviewType.ProblemDiscoveryInterview);
    const {
        insights: interviewInsights,
        addJobToBeDoneInsights,
        addAlternativeSolutionInsights,
        attachInsight,
        deleteInsight,
        detachInsight,
        updateInsight
    } = useInterviewInsights(ideaId, interviewId);

    const { quotes, addInsightQuote, updateQuote, deleteQuote } = useInterviewQuotes(ideaId, interviewId, InterviewQuoteType.Insight);
    const dispatch = useAppDispatch();

    const shouldLoadCanvas =
        insightsCatalog !== undefined &&
        insightsCatalog.context.properties.some(
            p =>
                p.type === InsightPropertyType.CustomerSegment ||
                p.type === InsightPropertyType.JobToBeDone ||
                p.type === InsightPropertyType.AlternativeSolution
        );
    useGlobalCanvas(shouldLoadCanvas ? ideaId : undefined, true);

    const [researchContextProperties, research] = useInsightsCatalogContextFromResearch(ideaId, insightsCatalog?.context, interview?.researchId);
    const insightsContextProperties = useInsightsCatalogContextFromInsights(insightsCatalog?.context, interviewInsights);
    const insightsCatalogContext = useMergedInsightsContext([insightsContextProperties, researchContextProperties]);
    const [interviewInsightsCoverage, createInterviewInsightsCoverage, updateInterviewInsightsCoverage] = useInterviewInsightsCoverage(ideaId, interviewId);

    const interviewEntrySelectionContextValue = useInterviewEntrySelectionContextValue();
    const initiallySelectedQuoteId = useIntQueryParam('quoteId');
    const [selection, setSelection] = useState<{ quoteId: number; entryId: number }>();

    const shouldSetSelectionFromQuote = !selection && initiallySelectedQuoteId !== undefined && quotes !== undefined;
    const selectionSetFromQuote = useRef(false);
    useEffect(() => {
        if (selectionSetFromQuote.current || !shouldSetSelectionFromQuote || initiallySelectedQuoteId === undefined) return;
        selectionSetFromQuote.current = true;

        const quoteToSelect = quotes.find(q => q.id === initiallySelectedQuoteId);
        if (quoteToSelect) setSelection({ quoteId: quoteToSelect.id, entryId: quoteToSelect.entry.id });
    }, [initiallySelectedQuoteId, quotes, shouldSetSelectionFromQuote]);

    return (
        <InterviewEntrySelectionContext.Provider value={interviewEntrySelectionContextValue}>
            <InterviewTwoColumnLayout
                sideContent={
                    <InterviewSectionsConversationList
                        interviewSections={interviewSections}
                        interviewee={interview?.contact}
                        onEntryAnswerClick={interviewEntrySelectionContextValue.onEntrySelected}
                        selectedEntryId={selection?.entryId}
                    />
                }
            >
                <InsightsFromCatalogEditor
                    ref={insightsEditorRef}
                    ideaId={ideaId}
                    interviewId={interviewId}
                    customerSegmentId={research?.customerSegmentId}
                    jobToBeDoneId={research?.jobToBeDoneId}
                    catalog={insightsCatalog}
                    context={insightsCatalogContext}
                    insights={interviewInsights}
                    quotes={quotes}
                    insightsCoverage={interviewInsightsCoverage}
                    onAddTemplatedInsight={(sectionTag, questionTag, templateTag, parametersValues, forkValue) => {
                        if (!insightsCatalog) throw new Error('Insights catalog not loaded');
                        if (!research) throw new Error('Research not loaded');
                        if (research.jobToBeDoneId === undefined) throw new Error('The interview research has no JTBD associated');

                        setSelection(undefined);
                        const foundTemplateData = findTemplateInCatalog(insightsCatalog, sectionTag, questionTag, templateTag);
                        if (!foundTemplateData) throw new Error(`Insight template not found: ${sectionTag}, ${questionTag}, ${templateTag}`);
                        const [section, group, , template] = foundTemplateData;

                        if (template.type === InsightType.JobToBeDone) {
                            return addJobToBeDoneInsights({
                                template: {
                                    catalog: insightsCatalog.id,
                                    section: sectionTag,
                                    question: questionTag,
                                    template: templateTag
                                },
                                parameterValues: parametersValues,
                                customerSegmentId: research.customerSegmentId,
                                jobToBeDoneId: research.jobToBeDoneId
                            });
                        }

                        if (template.type === InsightType.AlternativeSolution) {
                            let alternativeSolutionId = resolveNewInsightAlternativeSolutionId(
                                insightsCatalog,
                                section,
                                group,
                                forkValue,
                                template,
                                parametersValues
                            );

                            return addAlternativeSolutionInsights({
                                template: {
                                    catalog: insightsCatalog.id,
                                    section: sectionTag,
                                    question: questionTag,
                                    template: templateTag
                                },
                                parameterValues: parametersValues,
                                customerSegmentId: research.customerSegmentId,
                                jobToBeDoneId: research.jobToBeDoneId,
                                alternativeSolutionId: alternativeSolutionId
                            });
                        }

                        throw new Error('Unsupported insight template type' + template.type);
                    }}
                    onRelateInsight={(...args) => {
                        setSelection(undefined);
                        return attachInsight(...args);
                    }}
                    onDeleteInsight={(...args) => {
                        setSelection(undefined);
                        return deleteInsight(...args);
                    }}
                    onDetachInsight={(...args) => {
                        setSelection(undefined);
                        return detachInsight(...args);
                    }}
                    onUpdateInsight={async (insightId, parameterValues, template) => {
                        setSelection(undefined);
                        const updatedValues = await saveCanvasInsightParameterInstantValues(dispatch, true, template.parameters, parameterValues);
                        return updateInsight(insightId, updatedValues);
                    }}
                    onCreateQuote={(insightId: number, entryId: number, startIndex: number, endIndex: number) => {
                        setSelection(undefined);
                        return addInsightQuote({ interviewId, insightId, entryId, fromPosition: startIndex, toPosition: endIndex });
                    }}
                    onUpdateQuote={(...args) => {
                        setSelection(undefined);
                        return updateQuote(...args);
                    }}
                    onDeleteQuote={(...args) => {
                        setSelection(undefined);
                        return deleteQuote(...args);
                    }}
                    onQuoteClick={quoteId => {
                        const selectedQuote = quotes?.find(q => q.id === quoteId);
                        if (!selectedQuote) return;
                        selection?.quoteId === quoteId ? setSelection(undefined) : setSelection({ entryId: selectedQuote.entry.id, quoteId: quoteId });
                    }}
                    onCreateInsightsCoverage={
                        insightsCatalog &&
                        ((sectionTag, questionTag, branch, coverage) => {
                            setSelection(undefined);
                            return createInterviewInsightsCoverage({
                                catalog: insightsCatalog.id,
                                section: sectionTag,
                                question: questionTag,
                                branch: branch,
                                coverage: coverage
                            });
                        })
                    }
                    onUpdateInsightsCoverage={(...args) => {
                        setSelection(undefined);
                        return updateInterviewInsightsCoverage(...args);
                    }}
                    selectedQuoteId={selection?.quoteId}
                    readonly={isReadOnly}
                />
            </InterviewTwoColumnLayout>
        </InterviewEntrySelectionContext.Provider>
    );
});
