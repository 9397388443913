import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { alternateToggle, registerToggle, resetAllToggles, unregisterToggle } from '../state/ui/soleToggleSlice';

let toggleId = 0;

export const useSoleToggle = (initialState?: boolean): [boolean, () => void] => {
    const currentToggleId = useMemo(() => (toggleId++).toString(), []);
    const dispatch = useAppDispatch();

    const toggleState = useAppSelector(s => s.soleToggle[currentToggleId]);

    useEffect(() => {
        dispatch(registerToggle({ id: currentToggleId, state: !!initialState }));

        return () => {
            dispatch(unregisterToggle(currentToggleId));
        };
    }, [currentToggleId, initialState, dispatch]);

    const alternateState = useCallback(() => {
        dispatch(alternateToggle(currentToggleId));
    }, [currentToggleId, dispatch]);

    const currentToggleState = typeof toggleState === 'undefined' ? !!initialState : toggleState;

    return [currentToggleState, alternateState];
};

export const useSoleToggleZone = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const windowClickCallback = () => dispatch(resetAllToggles());

        window.addEventListener('click', windowClickCallback);

        return () => {
            window.removeEventListener('click', windowClickCallback);
        };
    }, [dispatch]);
};
