import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { processWithGlobalErrorHandler } from '../../services/common';
import { Idea, ideasService } from '../../services/ideasService';
import { UserRole } from '../../services/usersService';
import { RootState } from '../store';

export interface IdeaState {
    current?: Idea;
    role?: UserRole;
}

const initialState: IdeaState = {};

type LoadedIdeaData = { idea: Idea | null; role?: UserRole };

const loadIdeaByIdAction = async (ideaId: string, currentUserId: string | undefined) => {
    const [loadedIdea, membership] = await Promise.all([ideasService.get(ideaId), ideasService.getMembers(ideaId)]);
    const currentUserRole = membership.find(m => m.user.userId === currentUserId)?.role;

    return { idea: loadedIdea, role: currentUserRole };
};

export const loadIdeaById = createAsyncThunk<LoadedIdeaData, string, { state: RootState }>(
    'idea/load',
    processWithGlobalErrorHandler((ideaId, { getState }) => loadIdeaByIdAction(ideaId, getState().user?.userId))
);

export const reloadCurrentIdea = createAsyncThunk<LoadedIdeaData | undefined, void, { state: RootState }>(
    'idea/reload',
    processWithGlobalErrorHandler((_, { getState }) => {
        const state = getState();
        const currentIdeaId = state.idea.current?.uniqueId;
        if (!currentIdeaId) return;
        return loadIdeaByIdAction(currentIdeaId, state.user?.userId);
    })
);

export const updateIdea = createAsyncThunk<Idea, Omit<Partial<Idea>, 'uniqueId'>, { state: RootState }>(
    'idea/update',
    processWithGlobalErrorHandler(async (data: Omit<Partial<Idea>, 'uniqueId'>, { getState }) => {
        const currentIdea = getState().idea.current;
        if (!currentIdea) throw new Error('No current idea!');

        const updatedIdea = await ideasService.partialUpdate(currentIdea.uniqueId, { ...data });

        return updatedIdea;
    })
);

export const ideaSlice = createSlice({
    name: 'idea',
    initialState,
    reducers: {
        clearIdea: () => {
            return initialState;
        },
        setIdea: (state, action: PayloadAction<Idea>) => {
            state.current = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadIdeaById.pending, (state, action) => {
                if (state.current && state.current.uniqueId !== action.meta.arg) {
                    return initialState;
                }
            })
            .addCase(loadIdeaById.fulfilled, (state, action: PayloadAction<LoadedIdeaData>) => {
                if (action.payload.idea) {
                    state.current = action.payload.idea;
                    state.role = action.payload.role;
                }
            })
            .addCase(updateIdea.fulfilled, (state, action: PayloadAction<Idea>) => {
                state.current = action.payload;
            })
            .addCase(reloadCurrentIdea.fulfilled, (state, action: PayloadAction<LoadedIdeaData | undefined>) => {
                if (!action.payload || !action.payload.idea) {
                    return initialState;
                }

                state.current = action.payload.idea;
                state.role = action.payload.role;
            });
    }
});

export const { clearIdea, setIdea } = ideaSlice.actions;

export default ideaSlice.reducer;
