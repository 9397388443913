import { Button } from '@progress/kendo-react-buttons';
import { Error as ErrorComponent } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { useRef, useState } from 'react';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { ReactComponent as CloseIcon } from '../../icons/x.svg';
import { combineClassNames } from '../../services/common';
import { HttpException } from '../../services/httpServiceBase';
import { PriceDiscount, stripeBillingService } from '../../services/stripeBillingService';
import { DivButton } from '../common/DivButton';
import { FormFieldProps } from '../ui/inputs';

export type PromoDiscount = PriceDiscount & { code: string };
export function PromoCodeInput({
    value,
    onChange,
    disabled,
    size,
    addPromoCodeButtonText,
    showClearButton
}: FormFieldProps<PromoDiscount | undefined> & { size?: 'medium' | 'small'; addPromoCodeButtonText?: string; showClearButton?: boolean }) {
    const [isInsertingPromoCode, setIsInsertingPromoCode] = useState(false);
    const [insertingPromoCode, setInsertingPromoCode] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>();
    const [applyDisabled, applyCallbackCreator] = useSingleClickButton<Parameters<typeof tryToApplyPromoCode>, ReturnType<typeof tryToApplyPromoCode>>();
    const insertingWrapperElementRef = useRef<HTMLDivElement>(null);

    async function tryToApplyPromoCode(cancelOnEmpty: boolean) {
        if (!isInsertingPromoCode || errorMessage || applyDisabled || disabled) return;

        if (!insertingPromoCode) {
            if (cancelOnEmpty) setIsInsertingPromoCode(false);
            return;
        }

        try {
            const discount = await stripeBillingService.getDiscount(insertingPromoCode);
            onChange?.({
                value: {
                    ...discount,
                    code: insertingPromoCode
                }
            });
        } catch (e) {
            if (e instanceof HttpException && e.status === 409) {
                setErrorMessage("We don't recognize this promo code");
            } else throw e;
        }
    }

    const submitPromoCode = applyCallbackCreator(async (cancelOnEmpty: boolean) => {
        await tryToApplyPromoCode(cancelOnEmpty);
    });

    function cancelInsertingPromoCode() {
        setIsInsertingPromoCode(false);
        setInsertingPromoCode('');
    }

    return (
        <>
            {value ? (
                <StackLayout
                    align={{ horizontal: 'center', vertical: 'middle' }}
                    className={combineClassNames('k-gap-1', size === 'small' ? 'k-my-thin' : undefined)}
                >
                    <span className="k-fs-sm">Promo code applied.</span>
                    <Button
                        type="button"
                        size="small"
                        fillMode="link"
                        themeColor="secondary"
                        onClick={() => {
                            setIsInsertingPromoCode(false);
                            onChange?.({ value: undefined });
                        }}
                        disabled={disabled}
                    >
                        Remove my promo code
                    </Button>
                </StackLayout>
            ) : isInsertingPromoCode ? (
                <div
                    ref={insertingWrapperElementRef}
                    className="-w100"
                    onBlur={e => {
                        if (e.relatedTarget != null && insertingWrapperElementRef.current && insertingWrapperElementRef.current.contains(e.relatedTarget)) {
                            return;
                        }

                        submitPromoCode(true);
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            submitPromoCode(false);
                        } else if (e.key === 'Escape') cancelInsertingPromoCode();
                    }}
                >
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                        <span
                            className={combineClassNames(
                                'k-textbox k-input k-input-solid k-rounded-md k-flex-1',
                                size === 'small' ? 'k-input-sm' : 'k-input-md',
                                errorMessage ? 'k-invalid' : undefined
                            )}
                        >
                            <input
                                className="k-input-inner"
                                value={insertingPromoCode}
                                onChange={e => {
                                    setErrorMessage(undefined);
                                    setInsertingPromoCode(e.target.value);
                                }}
                                autoFocus
                                placeholder="Promo code"
                            />
                            <span className="k-input-suffix">
                                <span
                                    className={combineClassNames(
                                        'k-button k-button-sm k-button-rectangle k-button-link k-button-link-secondary k-rounded-md k-mr-1',
                                        size === 'small' ? 'k-my-hair' : undefined,
                                        applyDisabled || errorMessage || disabled ? 'k-disabled' : undefined
                                    )}
                                    tabIndex={0}
                                    onClick={() => submitPromoCode(false)}
                                >
                                    Apply
                                </span>
                            </span>
                        </span>
                        {showClearButton && (
                            <DivButton fillMode="flat" size={size} className="k-icp-svg-icon-button" onClick={cancelInsertingPromoCode}>
                                <CloseIcon className="k-icp-icon" />
                            </DivButton>
                        )}
                    </StackLayout>
                    {errorMessage && <ErrorComponent>{errorMessage}</ErrorComponent>}
                </div>
            ) : (
                <Button
                    type="button"
                    fillMode="flat"
                    themeColor="secondary"
                    size={size}
                    className={size === 'small' ? 'k-my-thin' : undefined}
                    onClick={() => {
                        setErrorMessage(undefined);
                        setInsertingPromoCode('');
                        setIsInsertingPromoCode(true);
                    }}
                    disabled={disabled}
                >
                    {addPromoCodeButtonText || 'I have a promo code'}
                </Button>
            )}
        </>
    );
}
