import { Button } from '@progress/kendo-react-buttons';
import { ReactComponent as MinusIcon } from '../../icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { combineClassNames } from '../../services/common';
import { FormFieldProps } from '../ui/inputs';

export function SeatsInput({
    id,
    value,
    onChange,
    disabled,
    valid,
    min,
    placeholder
}: FormFieldProps<number | undefined> & { min?: number; placeholder?: string }) {
    return (
        <span className={combineClassNames('k-textbox k-input k-input-md k-input-solid k-rounded-md', valid ? undefined : 'k-invalid')}>
            <span className="k-input-prefix">
                <Button
                    type="button"
                    fillMode="flat"
                    className="k-icp-svg-icon-button !k-border-none"
                    disabled={disabled || (min !== undefined && value !== undefined && value <= min)}
                    onClick={
                        onChange &&
                        (() => {
                            if (value === undefined) onChange({ value: min ?? 1 });
                            else if (min === undefined || value - 1 >= min) onChange({ value: value - 1 });
                            else onChange({ value: min });
                        })
                    }
                >
                    <MinusIcon className="k-icp-icon" />
                </Button>
            </span>
            <input
                id={id}
                className="k-input-inner k-text-center"
                placeholder={placeholder}
                style={{ width: 80 }}
                value={value ?? ''}
                onChange={
                    onChange &&
                    (e => {
                        if (!e.target.value) {
                            onChange({ value: undefined });
                            return;
                        }

                        let newValue = parseInt(e.target.value);
                        if (Number.isNaN(newValue)) return;
                        if (min !== undefined && newValue < min) newValue = min;

                        onChange({ value: newValue });
                    })
                }
                disabled={disabled}
            />
            <span className="k-input-suffix">
                <Button
                    type="button"
                    fillMode="flat"
                    className="k-icp-svg-icon-button !k-border-none"
                    disabled={disabled}
                    onClick={onChange && (() => onChange({ value: value === undefined ? min ?? 1 : value + 1 }))}
                >
                    <PlusIcon className="k-icp-icon" />
                </Button>
            </span>
        </span>
    );
}
