import { Button, ButtonProps } from '@progress/kendo-react-buttons';
import { ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { resetSidePanel, showInSidePanel } from '../../state/ui/sidePanelSlice';

export default function SidePanelButton({ view, viewParams, children, ...props }: { view?: string; viewParams?: any; children: ReactNode } & ButtonProps) {
    const currentView = useAppSelector(s => s.sidePanel.view);
    const dispatch = useAppDispatch();

    const onClick = () => {
        if (!view) dispatch(resetSidePanel());
        else if (currentView === view) dispatch(resetSidePanel());
        else dispatch(showInSidePanel({ view: view, params: viewParams }));
    };

    props.className += ' k-icp-svg-icon-button';

    return (
        <Button fillMode="flat" size="large" {...props} onClick={onClick}>
            {children}
        </Button>
    );
}
