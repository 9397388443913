import { To, useLocation, useParams, useResolvedPath, useSearchParams } from 'react-router-dom';
import { authenticationIntentUrlParamName, returnUrlParamName, SetValuesType } from '../services/common';
import { NotFoundException } from '../services/httpServiceBase';

export const useIsOnPage = (to: To, end?: boolean) => {
    const locationPath = useLocation().pathname.toLowerCase();
    const resolvedPath = useResolvedPath(to).pathname.toLowerCase();

    return locationPath === resolvedPath || (!end && locationPath.startsWith(resolvedPath) && locationPath.charAt(resolvedPath.length) === '/');
};

export const useQueryParam = (queryParamName: string) => {
    const [queryParams] = useSearchParams();

    return queryParams.get(queryParamName);
};

export function useIntQueryParam(paramName: string) {
    const paramValue = useQueryParam(paramName);
    if (!paramValue) return undefined;

    const paramNumberValue = parseInt(paramValue);
    if (!Number.isInteger(paramNumberValue)) return undefined;

    return paramNumberValue;
}

export const useReturnUrl = (defaultValue = '/') => {
    const returnUrl = useQueryParam(returnUrlParamName) || defaultValue;
    if ((!returnUrl.startsWith('/') || returnUrl.startsWith('//')) && !returnUrl.startsWith(window.location.origin)) throw new Error('Invalid return URL');

    return returnUrl;
};

export function useIdeaParams() {
    const { ideaId } = useParams();
    if (!ideaId) throw new NotFoundException();

    return { ideaId };
}

export function useScheduleParams() {
    const { ideaId, scheduleId: scheduleIdParam } = useParams();
    if (!ideaId || !scheduleIdParam) throw new NotFoundException();
    const scheduleId = parseInt(scheduleIdParam);

    return { ideaId, scheduleId };
}

export function useResearchParams() {
    const { ideaId, researchId: researchIdParam } = useParams();
    if (!ideaId || !researchIdParam) throw new NotFoundException();
    const researchId = parseInt(researchIdParam);

    return { ideaId, researchId };
}

export const supportedAuthenticationIntents = new Set(['login', 'register'] as const);
export type AuthenticationIntent = SetValuesType<typeof supportedAuthenticationIntents>;
const defaultAuthenticationIntent: AuthenticationIntent = 'login';
export function useAuthenticationIntent(): AuthenticationIntent {
    const passedIntent = useQueryParam(authenticationIntentUrlParamName);
    if (!passedIntent) return defaultAuthenticationIntent;

    const passedIntentLowerCased = passedIntent.toLowerCase() as AuthenticationIntent;
    if (supportedAuthenticationIntents.has(passedIntentLowerCased)) return passedIntentLowerCased;

    return defaultAuthenticationIntent;
}
