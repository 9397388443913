import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import SidePanel from '../../components/sidebar/sidePanel';
import TopNav from '../../components/topNav/topNav';
import UserNavMenu from '../../components/topNav/userNavMenu';
import { combineClassNames } from '../../services/common';

export default function TopNavLayout({ children, mainColumnClassName }: { children?: ReactNode; mainColumnClassName?: string }) {
    return (
        <div className="page">
            <TopNav right={<UserNavMenu />} />
            <div className="page-content page-content-columns">
                <div className={combineClassNames('main-column', mainColumnClassName)}>{children || <Outlet />}</div>
                <SidePanel />
            </div>
        </div>
    );
}
