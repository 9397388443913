import { StackLayout } from '@progress/kendo-react-layout';
import { Fragment } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../icons/arrow-left.svg';
import { combineClassNames } from '../../services/common';
import { HypothesisLikelihood } from '../../services/hypothesesService';
import LoadingIndicator from '../ui/loadingIndicator';

import { hypothesisLikelihoodLabelMap } from './common';

type ScaleRange = {
    likelihood: HypothesisLikelihood;
    from: number;
    to: number;
    color: string;
};

const scaleRanges: ScaleRange[] = [
    {
        likelihood: HypothesisLikelihood.VeryLikely,
        from: 80,
        to: 100,
        color: '#D9EDDC'
    },
    {
        likelihood: HypothesisLikelihood.Likely,
        from: 60,
        to: 80,
        color: '#ECF6ED'
    },
    {
        likelihood: HypothesisLikelihood.Neutral,
        from: 40,
        to: 60,
        color: '#FEF9EB'
    },
    {
        likelihood: HypothesisLikelihood.Unlikely,
        from: 20,
        to: 40,
        color: '#FAEBEB'
    },
    {
        likelihood: HypothesisLikelihood.VeryUnlikely,
        from: 0,
        to: 20,
        color: '#F6D6D6'
    }
];

export type ResearchHypothesisEvaluation = { researchId: number; researchTitle: string; evaluatedPercentage: number; isSpecial: boolean };

export function HypothesisResearchScaleView({
    evaluationData,
    className,
    isLoading = false
}: {
    evaluationData?: ResearchHypothesisEvaluation[];
    isLoading?: boolean;
    className?: string;
}) {
    const sortedResearchItems = evaluationData ? [...evaluationData].sort((r1, r2) => r2.evaluatedPercentage - r1.evaluatedPercentage) : undefined;
    const hasResearch = sortedResearchItems && sortedResearchItems.length > 0;

    const avgItem =
        evaluationData && evaluationData.length > 1
            ? Math.floor(evaluationData.reduce((acc, curr) => acc + curr.evaluatedPercentage, 0) / evaluationData.length)
            : undefined;

    if (avgItem && hasResearch) {
        sortedResearchItems.push({ researchId: -1, researchTitle: 'Average of all researches', evaluatedPercentage: avgItem, isSpecial: true });
    }

    return (
        <div className={combineClassNames('hypothesis-research-scale k-fs-sm k-w-full', className)}>
            {scaleRanges.map(range => (
                <Fragment key={range.likelihood}>
                    <strong className="k-text-right k-pb-3">{hypothesisLikelihoodLabelMap[range.likelihood]}</strong>
                    <StackLayout
                        className={combineClassNames(
                            'k-ml-2 k-mr-4 k-px-1 k-pt-3 k-pb-6 k-place-self-stretch hypothesis-research-scale-range',
                            range.from === 0 ? 'hypothesis-research-scale-range-lowest' : undefined
                        )}
                        align={{ horizontal: 'center', vertical: 'middle' }}
                        style={{ backgroundColor: range.color }}
                    >
                        {range.to === 100 ? `≥${range.from}%` : range.from === 0 ? `<${range.to}%` : `${range.from}-${range.to}%`}
                    </StackLayout>
                    {hasResearch && (
                        <div>
                            {sortedResearchItems
                                .filter(
                                    research =>
                                        research.evaluatedPercentage >= range.from &&
                                        (range.to === 100 ? research.evaluatedPercentage <= range.to : research.evaluatedPercentage < range.to)
                                )
                                .map(research => (
                                    <StackLayout
                                        key={research.researchId}
                                        className={combineClassNames(
                                            'k-gap-1',
                                            research.isSpecial && research.researchId === -1 ? 'k-color-error' : research.isSpecial ? 'k-color-secondary' : ''
                                        )}
                                        align={{ horizontal: 'start', vertical: 'top' }}
                                    >
                                        <LeftArrowIcon className="k-icp-icon k-icp-icon-size-4 k-mr-thin k-mt-hair k-shrink-0" />{' '}
                                        <div>
                                            {research.researchTitle} - <strong>{research.evaluatedPercentage}%</strong>
                                        </div>
                                    </StackLayout>
                                ))}
                        </div>
                    )}
                </Fragment>
            ))}
            {!hasResearch && isLoading && (
                <div style={{ gridRowEnd: scaleRanges.length }} className="hypothesis-research-scale-loader">
                    <LoadingIndicator  size={'small'} />
                </div>
            )}
            {!hasResearch && !isLoading && (
                <span
                    className="k-icp-subtle-text k-text-center k-px-2 k-pb-3 hypothesis-research-scale-empty-message"
                    style={{ gridRowEnd: scaleRanges.length + 1 }}
                >
                    Related research, when concluded, will appear here
                </span>
            )}
        </div>
    );
}
