import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox, Input, InputHandle } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popover, PopoverActionsBar } from '@progress/kendo-react-tooltip';
import { ComponentType, useEffect, useRef, useState } from 'react';
import { useSoleToggle } from '../../hooks/toggleHooks';
import { ReactComponent as SimpleListIcon } from '../../icons/grid-alt.svg';
import { ReactComponent as CardsListIcon } from '../../icons/grid.svg';
import { ReactComponent as AdvancedFiltersIcon } from '../../icons/properties.svg';
import { BoxType } from '../../services/canvasService';
import { debounce } from '../../services/common';
import { ContactTag, ContactsListViewMode, ContactsSortBy, PeopleFilter } from '../../services/contactsService';
import { ContactsListPreferences, listPreferencesService } from '../../services/listPreferencesService';
import { useRequireCanvasBoxItemsInZone } from '../canvas/canvasItemsZone';
import { CountriesComboBox, useCountries } from '../common/countries';
import { ListSortOptions } from '../common/listPageComponents';
import { CompanyIdPicker } from './contactPicker';
import { ContactTagsPicker } from './edit';

export type FilterOptions<TActiveFilters extends {}> = {
    searchText?: string;
    activeFilters?: TActiveFilters;
};

export type ListOptions<TActiveFilters extends {}> = ContactsListPreferences & {
    filter?: FilterOptions<TActiveFilters>;
};

export type AdvancedFilterProps<TActiveFilters extends {}> = {
    ideaId: string;
    value?: TActiveFilters;
    onChange: (value: TActiveFilters | undefined) => void;
    onSubmit: () => void;
    onClear: () => void;
};

export type PeopleActiveFilters = Omit<PeopleFilter, 'search' | 'createdFrom' | 'createdTo' | 'tagIds'> & {
    createdWithin?: { from: Date; to: Date };
    tags?: ContactTag[];
};

export function ContactListOptions<TActiveFilters extends {}>({
    ideaId,
    placeholder,
    options,
    onChange,
    AdvancedFilterComponent
}: {
    ideaId: string;
    placeholder?: string;
    options: ListOptions<TActiveFilters>;
    onChange: (value: ListOptions<TActiveFilters>, type: 'filter' | 'sort' | 'view') => void;
    AdvancedFilterComponent?: ComponentType<AdvancedFilterProps<TActiveFilters>>;
}) {
    return (
        <>
            <ContactsFilter
                ideaId={ideaId}
                placeholder={placeholder}
                value={options.filter}
                onChange={v => onChange({ ...options, filter: v }, 'filter')}
                AdvancedFilterComponent={AdvancedFilterComponent}
            />
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-6">
                <ListSortOptions
                    value={options.sortBy}
                    onChange={v => {
                        const newValue = { ...options, sortBy: v };
                        listPreferencesService.saveContactsListPreferences(newValue);
                        onChange(newValue, 'sort');
                    }}
                    options={[ContactsSortBy.Recent, ContactsSortBy.Alphabetical]}
                    defaultValue={ContactsSortBy.Recent}
                />
                <ListViewOptions
                    value={options.viewMode}
                    onChange={v => {
                        const newValue = { ...options, viewMode: v };
                        listPreferencesService.saveContactsListPreferences(newValue);
                        onChange(newValue, 'view');
                    }}
                />
            </StackLayout>
        </>
    );
}

function ContactsFilter<TActiveFilters extends {}>({
    ideaId,
    placeholder,
    value,
    onChange,
    AdvancedFilterComponent
}: {
    ideaId: string;
    placeholder?: string;
    value?: FilterOptions<TActiveFilters>;
    onChange: (options: FilterOptions<TActiveFilters> | undefined) => void;
    AdvancedFilterComponent?: ComponentType<AdvancedFilterProps<TActiveFilters>>;
}) {
    const [searchText, setSearchText] = useState(value?.searchText);
    const [activeFilters, setActiveFilters] = useState(value?.activeFilters);

    const [showAdvancedFilters, toggleShowAdvancedFilters] = useSoleToggle();
    const searchBoxRef = useRef<InputHandle>(null);
    const wrapperElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => setSearchText(value?.searchText), [value?.searchText]);
    useEffect(() => setActiveFilters(value?.activeFilters), [value?.activeFilters]);

    function closeAdvancedFilters() {
        if (showAdvancedFilters) toggleShowAdvancedFilters();
    }

    function commitFilters() {
        if (searchText || activeFilters) onChange({ searchText: searchText ? searchText : undefined, activeFilters: activeFilters });
        else onChange(undefined);

        closeAdvancedFilters();
    }

    const hasAdvancedFilters = typeof AdvancedFilterComponent !== 'undefined';

    return (
        <div ref={wrapperElementRef} className="k-pos-relative k-flex-1" onClick={e => e.stopPropagation()}>
            <Input
                ref={searchBoxRef}
                placeholder={placeholder}
                value={searchText ?? ''}
                onChange={e => setSearchText(e.value)}
                onKeyUp={e => {
                    if (e.key === 'Enter') {
                        commitFilters();
                    } else if (e.key === 'Escape') {
                        setSearchText(undefined);
                        onChange(value?.activeFilters ? { ...value, searchText: undefined } : undefined);
                        closeAdvancedFilters();
                    }
                }}
                className={hasAdvancedFilters ? '!k-pr-10' : undefined}
            />
            {hasAdvancedFilters && (
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-pos-absolute k-pos-middle-end k-mr-2">
                    <Button
                        fillMode="flat"
                        size="small"
                        className="k-icp-svg-icon-button"
                        togglable={true}
                        selected={showAdvancedFilters}
                        onClick={toggleShowAdvancedFilters}
                    >
                        <AdvancedFiltersIcon className="k-icp-icon" />
                    </Button>
                </StackLayout>
            )}

            {hasAdvancedFilters && (
                <Popover
                    anchor={searchBoxRef.current?.element}
                    appendTo={wrapperElementRef.current}
                    callout={false}
                    position="bottom"
                    show={showAdvancedFilters}
                    className="contacts-advanced-filter"
                >
                    <AdvancedFilterComponent
                        ideaId={ideaId}
                        value={activeFilters}
                        onChange={setActiveFilters}
                        onSubmit={commitFilters}
                        onClear={() => {
                            setActiveFilters(value?.activeFilters);
                            closeAdvancedFilters();
                        }}
                    />
                    <PopoverActionsBar>
                        <StackLayout align={{ horizontal: 'end', vertical: 'middle' }} className="k-gap-4">
                            <Button themeColor="primary" onClick={commitFilters}>
                                Search
                            </Button>
                            <Button
                                fillMode="flat"
                                onClick={() => {
                                    closeAdvancedFilters();
                                    setSearchText(undefined);
                                    setActiveFilters(undefined);
                                    onChange(undefined);
                                }}
                            >
                                Clear filters
                            </Button>
                        </StackLayout>
                    </PopoverActionsBar>
                </Popover>
            )}
        </div>
    );
}

export function PeopleAdvancedFilter({ ideaId, value, onChange, onSubmit, onClear }: AdvancedFilterProps<PeopleActiveFilters>) {
    const countriesList = useCountries();
    const customerSegments = useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);
    const isSubmitPreventedRef = useRef(false);

    const allowSubmitDebounced = debounce(() => {
        isSubmitPreventedRef.current = false;
    }, 300);

    function preventSubmitTemporary() {
        isSubmitPreventedRef.current = true;
        allowSubmitDebounced();
    }

    function updateFilter(updatedFilters: Partial<PeopleActiveFilters>) {
        let hasUpdate = false;
        const newValue = { ...value };
        for (const filterName in updatedFilters) {
            if (!Object.prototype.hasOwnProperty.call(updatedFilters, filterName)) return;
            const filterPropertyName = filterName as keyof PeopleActiveFilters;

            const filterValue: any = updatedFilters[filterPropertyName];
            if (!filterValue && !value?.[filterPropertyName]) continue;

            hasUpdate = true;
            if (!filterValue) delete newValue[filterPropertyName];
            else newValue[filterPropertyName] = filterValue;
        }

        if (!hasUpdate) return;

        preventSubmitTemporary();
        if (!Object.keys(newValue).length) onChange(undefined);
        else onChange(newValue);
    }

    return (
        <div
            onKeyUp={e => {
                if (e.key === 'Enter') {
                    if (!isSubmitPreventedRef.current) onSubmit();
                } else if (e.key === 'Escape') onClear();
            }}
            className="k-d-grid advanced-filters-layout k-gap-4 k-align-items-center"
        >
            <label htmlFor="af_fob-title">Job title:</label>
            <Input id="af_fob-title" placeholder="Job title" value={value?.jobTitle ?? ''} onChange={e => updateFilter({ jobTitle: e.value })} />

            <label htmlFor="af_company">Company:</label>
            <CompanyIdPicker
                id="af_company"
                ideaId={ideaId}
                placeholder="Company"
                value={value?.companyId}
                onChange={e => updateFilter({ companyId: e.value })}
                onClose={preventSubmitTemporary}
            />

            <label htmlFor="af_city">From:</label>
            <StackLayout align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-2">
                <Input id="af_city" placeholder="City" value={value?.city ?? ''} onChange={e => updateFilter({ city: e.value })} />
                <CountriesComboBox
                    data={countriesList}
                    placeholder="Country"
                    value={value?.countryCode && countriesList ? countriesList.find(c => c.code === value?.countryCode) : null}
                    onChange={e => updateFilter({ countryCode: e.value ? e.value.code : undefined })}
                    onClose={preventSubmitTemporary}
                />
            </StackLayout>

            {!customerSegments || customerSegments.length ? (
                <>
                    <label htmlFor="af_segment">Related to segment:</label>
                    <DropDownList
                        data={customerSegments}
                        id="af_segment"
                        loading={!customerSegments}
                        dataItemKey="id"
                        textField="content"
                        value={value?.customerSegmentId && customerSegments ? customerSegments.find(c => c.id === value?.customerSegmentId) : null}
                        onChange={e => updateFilter({ customerSegmentId: e.value ? e.value.id : undefined })}
                        onClose={preventSubmitTemporary}
                    />
                </>
            ) : (
                undefined
            )}

            <label htmlFor="af_tags">Tags:</label>
            <ContactTagsPicker
                id="af_tags"
                ideaId={ideaId}
                allowCreate={false}
                placeholder="Tags"
                value={value?.tags ?? []}
                onChange={e => updateFilter({ tags: e.value && e.value.length ? e.value : undefined })}
            />

            <label htmlFor="af_withinDate">Created within:</label>
            <DateRangePicker
                startDateInputSettings={{ label: undefined, placeholder: 'Start date' }}
                endDateInputSettings={{ label: undefined, placeholder: 'End date' }}
                className="k-justify-content-stretch"
                value={value && value.createdWithin ? { start: value.createdWithin.from, end: value.createdWithin.to } : undefined}
                onChange={e => {
                    if (e.value.start && e.value.end)
                        // Add 24 hours (minus 1 second since the kendo picker does not accept the date otherwise) to include the entire selected end day
                        updateFilter({ createdWithin: { from: e.value.start, to: new Date(e.value.end.getTime() + 24 * 60 * 60 * 1000 - 1000) } });
                    else updateFilter({ createdWithin: undefined });
                }}
            />

            <label>Social:</label>
            <StackLayout align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-2">
                <Checkbox
                    label="Has LinkedIn"
                    checked={value?.requireLinkedIn ?? false}
                    onChange={e => updateFilter({ requireLinkedIn: e.value ? true : undefined })}
                />
                <Checkbox
                    label="Has Twitter"
                    checked={value?.requireTwitter ?? false}
                    onChange={e => updateFilter({ requireTwitter: e.value ? true : undefined })}
                />
                <Checkbox
                    label="Has Facebook"
                    checked={value?.requireFacebook ?? false}
                    onChange={e => updateFilter({ requireFacebook: e.value ? true : undefined })}
                />
            </StackLayout>
        </div>
    );
}

function ListViewOptions({ value, onChange }: { value?: ContactsListViewMode; onChange?: (value: ContactsListViewMode) => void }) {
    const currentViewMode = value ?? ContactsListViewMode.Card;
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            <span className="k-flex-shrink-0">Show as:</span>
            <ButtonGroup className="k-icp-flat-button-group">
                <Button
                    fillMode="flat"
                    className="k-icp-svg-icon-button"
                    togglable={true}
                    selected={currentViewMode === ContactsListViewMode.Card}
                    onClick={() => currentViewMode !== ContactsListViewMode.Card && onChange?.(ContactsListViewMode.Card)}
                >
                    <CardsListIcon className="k-icp-icon" />
                </Button>
                <Button
                    fillMode="flat"
                    className="k-icp-svg-icon-button"
                    togglable={true}
                    selected={currentViewMode === ContactsListViewMode.List}
                    onClick={() => currentViewMode !== ContactsListViewMode.List && onChange?.(ContactsListViewMode.List)}
                >
                    <SimpleListIcon className="k-icp-icon" />
                </Button>
            </ButtonGroup>
        </StackLayout>
    );
}
