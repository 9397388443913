import { appConfig } from '../config';
import { addRequestHeader, ErrorWithOperationDisplayName, executeExclusively, pathWithReturnUrlToCurrentPage } from './common';
import { ErrorResponse, HttpException, HttpServiceBase, RequestAuthenticator, RequestMethod } from './httpServiceBase';

type AuthTokens = {
    authToken: string;
    refreshToken: string;
};

export enum CodeStatus {
    MissingOrUsed,
    Expired,
    Valid
}

export enum SubscriptionType {
    Monthly = 'Monthly',
    Annual = 'Annual'
}

export type CreateUserData = {
    emailAddress: string;
    password: string;
    firstName: string;
    lastName: string;
    expectation?: string;
    confirmationCode?: string;
};

export enum UserActivationResult {
    Activated,
    AlreadyActivated,
    Expired,
    Invalid
}

export interface RecaptchaConfiguration {
    enabled: boolean;
    siteKey: string;
}

export type AuthenticationContext = {
    action: 'registered' | 'logged-in';
    origin: string;
};

class AuthenticationService extends HttpServiceBase {
    private readonly loginPagePath = '/login';

    constructor() {
        super('/api/auth');
    }

    @ErrorWithOperationDisplayName('Register user')
    async registerUser(data: CreateUserData, recaptchaToken?: string | null): Promise<void> {
        const authTokens = await this.performRequest<AuthTokens>({
            path: '/sign-up',
            method: RequestMethod.POST,
            body: data,
            headers: recaptchaToken ? { [appConfig.recaptchaTokenHeaderName]: recaptchaToken } : undefined
        });

        authenticationTokenStore.setTokens(authTokens.authToken, authTokens.refreshToken);
    }

    @ErrorWithOperationDisplayName('Authorize user')
    authorize(emailAddress: string, password: string, recaptchaToken?: string | null): Promise<AuthTokens> {
        return this.performRequest<AuthTokens>({
            path: '/sign-in',
            method: RequestMethod.POST,
            body: {
                emailAddress,
                password
            },
            headers: recaptchaToken ? { [appConfig.recaptchaTokenHeaderName]: recaptchaToken } : undefined
        });
    }

    @ErrorWithOperationDisplayName('Reauthorize user')
    reauthorize(refreshToken: string): Promise<AuthTokens> {
        return this.performRequest({
            path: '/refresh-tokens',
            method: RequestMethod.POST,
            body: {
                token: refreshToken
            }
        });
    }

    @ErrorWithOperationDisplayName('Revoke authorization token')
    revokeAuthorization(refreshToken: string): Promise<AuthTokens | undefined> {
        return this.performRequest<AuthTokens>({
            path: '/sign-out',
            method: RequestMethod.POST,
            body: {
                token: refreshToken
            }
        }).catch(e => {
            if (e instanceof HttpException && e.status === 400) return;

            return Promise.reject(e);
        });
    }

    @ErrorWithOperationDisplayName('Send forgotten password request')
    async forgotPassword(emailAddress: string, returnUrl: string, recaptchaToken?: string | null): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: '/forgot-password',
            method: RequestMethod.POST,
            body: {
                emailAddress,
                returnUrl
            },
            headers: recaptchaToken ? { [appConfig.recaptchaTokenHeaderName]: recaptchaToken } : undefined
        });
    }

    @ErrorWithOperationDisplayName('Get reset password code status')
    async getResetPasswordCodeStatus(code: string): Promise<CodeStatus> {
        try {
            await this.performRequestWithoutParsingResponse({
                path: '/change-password-with-code',
                method: RequestMethod.HEAD,
                queryParams: {
                    secretCode: code
                }
            });
        } catch (e) {
            if (e instanceof HttpException) {
                if (e.status === 400) return CodeStatus.MissingOrUsed;
                if (e.status === 410) return CodeStatus.Expired;
            }

            throw e;
        }

        return CodeStatus.Valid;
    }

    @ErrorWithOperationDisplayName('Change password')
    async resetPassword(code: string, password: string, recaptchaToken?: string | null): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: '/change-password-with-code',
            method: RequestMethod.POST,
            body: {
                secretCode: code,
                newPassword: password
            },
            headers: recaptchaToken ? { [appConfig.recaptchaTokenHeaderName]: recaptchaToken } : undefined
        });
    }

    @ErrorWithOperationDisplayName('Change password')
    async changePassword(oldPassword: string, newPassword: string, recaptchaToken?: string | null): Promise<void> {
        const authTokens = await this.performRequest<AuthTokens>({
            path: '/change-password',
            method: RequestMethod.POST,
            body: {
                oldPassword,
                newPassword
            },
            headers: recaptchaToken ? { [appConfig.recaptchaTokenHeaderName]: recaptchaToken } : undefined
        });

        authenticationTokenStore.setTokens(authTokens.authToken, authTokens.refreshToken);
    }

    @ErrorWithOperationDisplayName('Get recaptcha config')
    getRecaptchaConfig(): Promise<RecaptchaConfiguration> {
        return this.performRequest({
            path: '/recaptcha-config',
            method: RequestMethod.GET
        });
    }

    @ErrorWithOperationDisplayName('Resend activation email')
    resendActivationEmail(emailAddress: string, returnUrl: string, recaptchaToken?: string | null): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: '/resend-email-confirmation',
            method: RequestMethod.POST,
            body: {
                emailAddress,
                returnUrl
            },
            headers: recaptchaToken ? { [appConfig.recaptchaTokenHeaderName]: recaptchaToken } : undefined
        });
    }

    @ErrorWithOperationDisplayName('Activate user')
    async activate(emailAddress: string, secretCode: string): Promise<UserActivationResult> {
        try {
            await this.performRequestWithoutParsingResponse({
                path: `/confirm-email`,
                method: RequestMethod.POST,
                body: {
                    emailAddress,
                    secretCode
                }
            });
        } catch (e) {
            if (e instanceof HttpException) {
                if (e.status === 400) return UserActivationResult.Invalid;
                if (e.status === 410) return UserActivationResult.Expired;
                if (e.status === 409) {
                    const errorData = e.parseResponse<ErrorResponse>();
                    if (errorData.message === 'Cannot confirm user with status Active.') return UserActivationResult.AlreadyActivated;
                }
            }

            throw e;
        }

        return UserActivationResult.Activated;
    }

    @ErrorWithOperationDisplayName('Log in')
    async login(email: string, password: string, recaptchaToken?: string | null) {
        const authTokens = await authenticationService.authorize(email, password, recaptchaToken);
        authenticationTokenStore.setTokens(authTokens.authToken, authTokens.refreshToken);
    }

    @ErrorWithOperationDisplayName('Log out')
    logout(): void {
        const refreshToken = authenticationTokenStore.getRefreshToken();
        authenticationTokenStore.deleteTokens();
        if (refreshToken) authenticationService.revokeAuthorization(refreshToken);
    }

    isOnLoginPage() {
        return window.location.pathname === this.loginPagePath;
    }

    getLoginUrl() {
        return pathWithReturnUrlToCurrentPage(this.loginPagePath);
    }
}

export const authenticationService = new AuthenticationService();

class AuthenticationTokenStore {
    private readonly authTokenKey = 'icp.auth_token';
    private readonly refreshTokenKey = 'icp.refresh_token';

    private readonly authTokenStorage: Storage;
    private readonly refreshTokenStorage: Storage;

    constructor() {
        this.authTokenStorage = window.localStorage;
        this.refreshTokenStorage = window.localStorage;
        window.addEventListener('storage', this.onStorageChange.bind(this));
    }

    setTokens(authToken: string, refreshToken: string): void {
        this.setAuthToken(authToken);
        this.setRefreshToken(refreshToken);
    }

    getAuthToken(): string | null {
        return this.authTokenStorage.getItem(this.authTokenKey);
    }

    getRefreshToken(): string | null {
        return this.refreshTokenStorage.getItem(this.refreshTokenKey);
    }

    deleteTokens(): void {
        this.authTokenStorage.removeItem(this.authTokenKey);
        this.refreshTokenStorage.removeItem(this.refreshTokenKey);
    }

    private setAuthToken(token: string): void {
        this.authTokenStorage.setItem(this.authTokenKey, token);
    }

    private setRefreshToken(refreshToken: string): void {
        this.refreshTokenStorage.setItem(this.refreshTokenKey, refreshToken);
    }

    private onStorageChange(e: StorageEvent) {
        if (e.storageArea !== this.refreshTokenStorage || e.key !== this.refreshTokenKey) return;

        if ((!e.oldValue && e.newValue) || (e.oldValue && !e.newValue)) window.location.reload();
    }
}

export const authenticationTokenStore = new AuthenticationTokenStore();

const AUTH_HEADER_NAME = 'x-icp-auth';
class TokenRequestAuthenticator implements RequestAuthenticator {
    authenticateRequest(request: RequestInit): void {
        const authToken = authenticationTokenStore.getAuthToken();
        if (!authToken) return;

        addRequestHeader(request, AUTH_HEADER_NAME, authToken);
    }

    refreshAuthentication(): Promise<boolean> {
        const refreshToken = authenticationTokenStore.getRefreshToken();
        if (!refreshToken) return Promise.resolve(false);

        const currentAuthToken = authenticationTokenStore.getAuthToken();
        // Resolve concurrent requests in the current tab and across tabs since the tokens are stored in a tabs shared storage
        return executeExclusively('reauthenticateRequest', () => {
            // If the token was reissued while waiting the lock - return immediately
            const newAuthToken = authenticationTokenStore.getAuthToken();
            if (newAuthToken && newAuthToken !== currentAuthToken) return true;

            return authenticationService
                .reauthorize(refreshToken)
                .then(newTokens => {
                    authenticationTokenStore.setTokens(newTokens.authToken, newTokens.refreshToken);

                    return true;
                })
                .catch(e => {
                    if (e instanceof HttpException && (e.status === 400 || e.status === 409)) return false;

                    return Promise.reject(e);
                });
        });
    }
}

HttpServiceBase.authenticator = new TokenRequestAuthenticator();

type ExternalAuthenticationContext = {
    trigger: 'login' | 'registration';
    origin: string;
};
export type SecuredExternalAuthenticationContext = ExternalAuthenticationContext & {
    timestamp: number;
    signature: string;
};

class ExternalAuthenticationService {
    private readonly externalAuthenticationContextKey = 'icp.external_auth_context';

    private readonly externalAuthenticationContextStorage: Storage;

    constructor() {
        this.externalAuthenticationContextStorage = window.sessionStorage;
    }

    tryReadAndDestroyExternalAuthenticationContext(): ExternalAuthenticationContext | undefined {
        const serializedExternalAuthenticationContext = this.externalAuthenticationContextStorage.getItem(this.externalAuthenticationContextKey);
        if (!serializedExternalAuthenticationContext) return;

        this.externalAuthenticationContextStorage.removeItem(this.externalAuthenticationContextKey);
        const securedExternalAuthenticationContext: SecuredExternalAuthenticationContext = JSON.parse(serializedExternalAuthenticationContext);

        const currentAuthToken = authenticationTokenStore.getAuthToken();
        // Verify that the context is created for the current access token (with the same JWT signature)
        if (!currentAuthToken || !currentAuthToken.endsWith('.' + securedExternalAuthenticationContext.signature)) return;

        const contextAgeInMilliseconds = Date.now() - securedExternalAuthenticationContext.timestamp;
        // Token is older than 5 mins
        if (contextAgeInMilliseconds > 5 * 60 * 1000) return;

        return {
            trigger: securedExternalAuthenticationContext.trigger,
            origin: securedExternalAuthenticationContext.origin
        };
    }
}

export const externalAuthenticationService = new ExternalAuthenticationService();
