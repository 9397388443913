import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import { useCopyToClipboard } from '../../hooks/commonHooks';

export function ContentSectionWithCopy({
    children,
    textToCopy,
    textToCopyDescription,
    richTextToCopy
}: {
    children?: ReactNode;
    textToCopy?: string | (() => string);
    textToCopyDescription?: string;
    richTextToCopy?: string;
}) {
    const copyToClipboard = useCopyToClipboard();
    const disabled = !textToCopy || !textToCopyDescription;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-flex-1 k-gap-2 k-justify-content-between">
            {children}
            <Button
                type="button"
                fillMode="flat"
                icon="copy"
                disabled={disabled}
                className='k-flex-shrink-0'
                onClick={
                    disabled
                        ? undefined
                        : () => {
                              const text = typeof textToCopy === 'function' ? textToCopy() : textToCopy;
                              copyToClipboard(text, textToCopyDescription, richTextToCopy);
                          }
                }
            />
        </StackLayout>
    );
}
