import { ErrorWithOperationDisplayName } from './common';
import { HttpServiceBase } from './httpServiceBase';

export interface Term {
    id: string;
    term: string;
    description: string;
}

class TermsService extends HttpServiceBase {
    constructor() {
        super('/api/terms');
    }

    @ErrorWithOperationDisplayName('Get term')
    getTerm(id: string): Promise<Term> {
        return this.performRequest<Term>({
            path: `/${id}`
        });
    }

    @ErrorWithOperationDisplayName('Get all term')
    getAllTerms(): Promise<Term[]> {
        return this.performRequest<Term[]>({
            path: '/'
        });
    }
}

export const termsService = new TermsService();
