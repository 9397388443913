import { Button } from '@progress/kendo-react-buttons';
import { ComboBox, ComboBoxHandle } from '@progress/kendo-react-dropdowns';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGlobalCanvas } from '../../hooks/canvasHooks';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { ReactComponent as EditIcon } from '../../icons/edit-3.svg';
import { ReactComponent as CancelEditIcon } from '../../icons/x.svg';
import { BoxItem, BoxType } from '../../services/canvasService';
import { saveItem } from '../../state/canvas/canvasSlice';
import { useAppDispatch } from '../../state/hooks';
import { DropDownButtonFooterWithButton, listNoDataRenderWithText } from '../common/dropDownCommon';
import { ManageAlternativeSolutionsDialog } from './manageAlternativeSolutionsDialog';

type UpdatedAlternativeSolutionValue = null | undefined | BoxItem | { id: number; content: string };
export function AlternativeSolutionEditor({
    alternativeSolutionId,
    onChange,
    placeholder,
    createButtonText,
    updateButtonText,
    canEdit,
    valid,
    allowNewItemCancel,
    onCancel,
    excludedAlternativeSolutionIds
}: {
    alternativeSolutionId?: number;
    onChange?: (e: { value: number }) => Promise<unknown> | void;
    placeholder?: string;
    createButtonText?: string;
    updateButtonText?: string;
    canEdit?: boolean;
    valid?: boolean;
    allowNewItemCancel?: boolean;
    onCancel?: () => void;
    excludedAlternativeSolutionIds?: number[];
}) {
    const { canvas } = useGlobalCanvas(undefined, true);
    const alternativeSolutions = getAlternativeSolutions();
    const alternativeSolution =
        alternativeSolutionId !== undefined && alternativeSolutions ? alternativeSolutions.find(i => i.id === alternativeSolutionId) : undefined;
    const [isEditing, setIsEditing] = useState(false);
    const [updatedAlternativeSolutionValue, setUpdatedAlternativeSolutionValue] = useState<string | number | null>();
    const updatedAlternativeSolution = useMemo<UpdatedAlternativeSolutionValue>(() => {
        if (updatedAlternativeSolutionValue === null) return null;
        if (!alternativeSolutions || updatedAlternativeSolutionValue === undefined) return undefined;
        if (typeof updatedAlternativeSolutionValue === 'number') return alternativeSolutions.find(as => as.id === updatedAlternativeSolutionValue);

        return { content: updatedAlternativeSolutionValue, id: -Date.now() };
    }, [alternativeSolutions, updatedAlternativeSolutionValue]);
    const [commitDisabled, commitAlternativeSolutionCallbackCreator] = useSingleClickButton<
        Parameters<typeof commitAlternativeSolution>,
        ReturnType<typeof commitAlternativeSolution>
    >();
    const dispatch = useAppDispatch();
    const alternativeSolutionComboBoxRef = useRef<ComboBoxHandle>(null);
    const autoFocusAlternativeSolutionComboBox = useRef<boolean>();

    useEffect(() => {
        if (!isEditing || !autoFocusAlternativeSolutionComboBox.current) return;

        autoFocusAlternativeSolutionComboBox.current = undefined;

        alternativeSolutionComboBoxRef.current?.focus();
    }, [isEditing]);

    function getAlternativeSolutions() {
        const alternativeSolutions = canvas.boxes?.find(b => b.type === BoxType.AlternativeSolutions)?.items;
        if (!alternativeSolutions) return alternativeSolutions;

        return excludedAlternativeSolutionIds && excludedAlternativeSolutionIds.length
            ? alternativeSolutions.filter(alternativeSolution => !excludedAlternativeSolutionIds.includes(alternativeSolution.id))
            : alternativeSolutions;
    }

    function findAlternativeSolutionByContent(content: string) {
        if (!content) return undefined;

        const normalizedContent = content.toLowerCase().trim();
        return alternativeSolutions?.find(as => as.content.toLowerCase().trim() === normalizedContent);
    }

    function resetEditState() {
        setIsEditing(false);
        setUpdatedAlternativeSolutionValue(undefined);
        setShowManageDialog(undefined);
    }

    async function onAlternativeSolutionChanged(alternativeSolutionId: number) {
        await onChange?.({ value: alternativeSolutionId });
        resetEditState();
    }

    async function commitAlternativeSolution(alternativeSolutionValue: number | string) {
        if (alternativeSolutionId === alternativeSolutionValue) {
            resetEditState();
            return;
        }

        if (typeof alternativeSolutionValue === 'number') {
            await onAlternativeSolutionChanged(alternativeSolutionValue);
            return;
        }

        const existingAlternativeSolution = findAlternativeSolutionByContent(alternativeSolutionValue);
        if (existingAlternativeSolution) {
            if (existingAlternativeSolution.id === alternativeSolutionId) {
                resetEditState();
                return;
            }

            await onAlternativeSolutionChanged(existingAlternativeSolution.id);
            return;
        }

        const newOrUpdatedAlternativeSolutionData = await dispatch(
            saveItem({
                itemId: alternativeSolutionId,
                boxType: BoxType.AlternativeSolutions,
                content: alternativeSolutionValue
            })
        ).unwrap();

        if (newOrUpdatedAlternativeSolutionData) {
            if (alternativeSolutionId === undefined) await onAlternativeSolutionChanged(newOrUpdatedAlternativeSolutionData.itemId);
            else resetEditState();
        }
    }

    const [showManageDialog, setShowManageDialog] = useState<boolean>();
    const skipCommitOnEnterRef = useRef<boolean>();

    const [alternativeSolutionsFilter, setAlternativeSolutionsFilter] = useState<string>();
    const alternativeSolutionsToShow = useMemo(() => {
        if (!alternativeSolutionsFilter || !alternativeSolutions) return alternativeSolutions;

        return alternativeSolutions.filter(as => as.content.toLowerCase().includes(alternativeSolutionsFilter.toLowerCase()));
    }, [alternativeSolutions, alternativeSolutionsFilter]);

    if (alternativeSolution && !isEditing)
        return (
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                <div className="k-input k-input-md k-rounded-md k-icp-component-border !k-w-auto">
                    <div className="k-input-inner">{alternativeSolution.content}</div>
                </div>
                {canEdit && (
                    <Button
                        className="k-icp-svg-icon-button"
                        fillMode="flat"
                        onClick={() => {
                            setIsEditing(true);
                            setUpdatedAlternativeSolutionValue(undefined);
                            autoFocusAlternativeSolutionComboBox.current = true;
                        }}
                    >
                        <EditIcon className="k-icp-icon" />
                    </Button>
                )}
            </StackLayout>
        );

    const commitAlternativeSolutionCallback = commitAlternativeSolutionCallbackCreator(commitAlternativeSolution);
    const alternativeSolutionComboBoxValue = updatedAlternativeSolution === undefined ? alternativeSolution : updatedAlternativeSolution;

    function commitCurrentUpdatedAlternativeSolution() {
        if (updatedAlternativeSolution === null) return;
        if (updatedAlternativeSolution === undefined) {
            resetEditState();
            return;
        }

        if (updatedAlternativeSolution.id >= 0) return commitAlternativeSolutionCallback(updatedAlternativeSolution.id);
        else return commitAlternativeSolutionCallback(updatedAlternativeSolution.content);
    }

    function resolveUpdatedAlternativeSolutionValue(newValue: { content: string } | BoxItem) {
        if ('id' in newValue && newValue.id >= 0) return newValue.id;

        const existingAlternativeSolution = findAlternativeSolutionByContent(newValue.content);
        if (existingAlternativeSolution) return existingAlternativeSolution.id;

        return newValue.content;
    }

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            <div
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        if (skipCommitOnEnterRef.current) {
                            skipCommitOnEnterRef.current = undefined;
                            return;
                        }

                        commitCurrentUpdatedAlternativeSolution();
                    } else if (e.key === 'Escape') resetEditState();
                }}
                className="k-flex-1"
            >
                <ComboBox
                    ref={alternativeSolutionComboBoxRef}
                    value={alternativeSolutionComboBoxValue}
                    data={alternativeSolutionsToShow}
                    dataItemKey="id"
                    textField="content"
                    placeholder={placeholder}
                    disabled={!canEdit || commitDisabled}
                    valid={valid}
                    listNoDataRender={listNoDataRenderWithText('No alternative solutions found.', !alternativeSolutions)}
                    loading={!alternativeSolutions}
                    footer={
                        <DropDownButtonFooterWithButton onClick={() => setShowManageDialog(true)}>Manage Alternative solutions</DropDownButtonFooterWithButton>
                    }
                    allowCustom
                    onChange={e => {
                        if (!e.value) {
                            setUpdatedAlternativeSolutionValue(null);
                            return;
                        }

                        const updatedValue = resolveUpdatedAlternativeSolutionValue(e.value);
                        setUpdatedAlternativeSolutionValue(updatedValue);

                        const shouldCommit = e.syntheticEvent.type === 'keydown' && (e.syntheticEvent as React.KeyboardEvent<HTMLElement>).key === 'Enter';
                        if (shouldCommit) {
                            skipCommitOnEnterRef.current = true;
                            commitAlternativeSolution(updatedValue);
                        }
                    }}
                    filterable
                    onFilterChange={e => setAlternativeSolutionsFilter(e.filter.value)}
                />
            </div>
            <Button type="button" disabled={!canEdit || commitDisabled || !alternativeSolutionComboBoxValue} onClick={commitCurrentUpdatedAlternativeSolution}>
                {isEditing ? updateButtonText || 'Update alternative solution' : createButtonText || 'Create alternative solution'}
            </Button>
            {(isEditing || allowNewItemCancel) && (
                <Button
                    className="k-icp-svg-icon-button"
                    fillMode="flat"
                    onClick={() => {
                        resetEditState();
                        onCancel?.();
                    }}
                    disabled={commitDisabled}
                >
                    <CancelEditIcon className="k-icp-icon" />
                </Button>
            )}
            {showManageDialog && <ManageAlternativeSolutionsDialog onClose={() => setShowManageDialog(undefined)} />}
        </StackLayout>
    );
}
