import { Fade } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { clearNotifications, removeNotification } from '../../state/notifications/platformNotificationsSlice';

export function PlatformNotificationsGroup() {
    const notifications = useAppSelector(s => s.platformNotifications);
    const dispatch = useAppDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(clearNotifications(true));
    }, [dispatch, location]);

    useEffect(() => {
        return () => {
            dispatch(clearNotifications(false));
        };
    }, [dispatch]);

    const notificationsList: JSX.Element[] = [];
    for (let i = notifications.length - 1; i >= 0; i--) {
        const notification = notifications[i];
        notificationsList.push(
            <Notification
                key={notification.key}
                type={{ style: notification.type, icon: false }}
                closable={!notification.removeCloseButton}
                onClose={() => dispatch(removeNotification(notification.key, false))}
            >
                <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-8">
                    <div
                        className="k-icp-notification-content"
                        dangerouslySetInnerHTML={notification.htmlContent ? { __html: notification.htmlContent } : undefined}
                    >
                        {notification.content}
                    </div>
                    {notification.actionText && (
                        <Button
                            themeColor="secondary"
                            fillMode="link"
                            size="large"
                            className={`${notification.type === 'error' ? 'k-icp-notification-btn-error ' : ''}${
                                !notification.type ? 'k-button-link-alt ' : ''
                            }!k-px-1 !k-py-0 !k-border-0`}
                            onClick={() => dispatch(removeNotification(notification.key, true))}
                        >
                            {notification.actionText}
                        </Button>
                    )}
                </StackLayout>
            </Notification>
        );
    }

    return (
        <NotificationGroup className="platform-notifications-group">
            <Fade appear={true} enter={true} exit={true} className="platform-notifications-list">
                {notificationsList}
            </Fade>
        </NotificationGroup>
    );
}
