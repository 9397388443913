import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { TaskEditorProps } from '.';
import { appConfig } from '../../../config';
import { useSingleClickButton } from '../../../hooks/commonHooks';
import { ReactComponent as WarningIcon } from '../../../icons/alert-triangle.svg';
import { useResearchJobToBeDone } from '../../../pages/journey/extensibility/researchExtensions';
import { BoxType, canvasService } from '../../../services/canvasService';
import { combineClassNames } from '../../../services/common';
import { dateTimeService } from '../../../services/dateTimeService';
import { JobToBeDoneEditorParams, JourneyTaskGuidanceHints } from '../../../services/journeyService';
import { researchService } from '../../../services/researchService';
import { deleteItemWithUndo, saveItem } from '../../../state/canvas/canvasSlice';
import { useAppDispatch } from '../../../state/hooks';
import { NotificationData, addNotification } from '../../../state/notifications/platformNotificationsSlice';
import CanvasBoxItem from '../../canvas/item';
import { HintsDefaultLayout } from '../../common/Hints';
import { RegenerateScriptModal } from '../../interviewScript/regenerateScriptModal';
import { EditorErrorsList } from './shared/editorErrorsList';
import { EditorMainArea } from './shared/editorMainArea';
import { EditorTwoColumnLayout, EditorTwoColumnLayoutMode } from './shared/editorTwoColumnLayout';
import { RelatedCanvasBoxes } from './shared/relatedCanvasBoxes';

export function JobToBeDoneEditor(props: TaskEditorProps<JobToBeDoneEditorParams>) {
    const dispatch = useAppDispatch();
    const [isLoadingJTBD, researchJobToBeDone, updateResearchJobToBeDoneId] = useResearchJobToBeDone(
        props.ideaId,
        props.params.researchId,
        props.taskData,
        props.setTaskData
    );

    const interviewScript = props.taskData.researchInterviewScript?.[props.params.researchId];
    const fiveMinsBeforeNow = dateTimeService.addMinutes(new Date(), -5);
    const interviewScriptTimeout = interviewScript && !interviewScript.ready && !interviewScript.error && interviewScript.createdOn < fiveMinsBeforeNow;
    const isGeneratingInterviewScript = interviewScript && !interviewScript.ready && !interviewScript.error && !interviewScriptTimeout;

    const hints = props.guidance ? props.guidance.find((g): g is JourneyTaskGuidanceHints => g.id === props.params.hintsRef) : undefined;
    const [askForScriptRegeneration, setAskForScriptRegeneration] = useState(false);
    const [dialogBtnsDisabled, scriptRegenerationWrapper] = useSingleClickButton<[], Promise<void>>();

    const generateInterviewScript = scriptRegenerationWrapper(async () => {
        const researchId = props.params.researchId;
        const newScript = await researchService.createInterviewScript(props.ideaId, researchId);

        props.setTaskData(taskData => {
            if (!taskData.researchInterviewScript) return taskData;

            const researchToUpdate = taskData.problemValidationResearch?.[researchId];
            if (!researchToUpdate) return taskData;

            return {
                ...taskData,
                problemValidationResearch: {
                    ...taskData.problemValidationResearch,
                    [researchId]: {
                        ...researchToUpdate,
                        interviewScriptId: newScript.id
                    }
                }
            };
        });
    });

    const updateJobToBeDone = async function(jobToBeDoneContent: string) {
        const customerSegmentId = props.params.customerSegmentId;

        const saveResult = await dispatch(
            saveItem({
                boxType: BoxType.JobsToBeDone,
                itemId: researchJobToBeDone?.id,
                content: jobToBeDoneContent,
                relatedItemIds: researchJobToBeDone?.relatedItemIds
                    ? researchJobToBeDone.relatedItemIds.includes(customerSegmentId)
                        ? researchJobToBeDone.relatedItemIds
                        : [...researchJobToBeDone.relatedItemIds, customerSegmentId]
                    : [customerSegmentId]
            })
        ).unwrap();

        if (!saveResult) return;

        if (!researchJobToBeDone) await updateResearchJobToBeDoneId(saveResult.itemId);

        if (interviewScript && interviewScript.ready) {
            setAskForScriptRegeneration(true);
        }
    };

    const deleteJobToBeDone = async function() {
        if (!researchJobToBeDone?.id) return;

        await updateResearchJobToBeDoneId(undefined);
        const jobToBeDoneStats = await canvasService.getItemStats(props.ideaId, BoxType.JobsToBeDone, researchJobToBeDone.id);
        const canDeleteJobToBeDone = !jobToBeDoneStats.researchCount && !jobToBeDoneStats.insightCount;
        const reAttachJobToBeDoneToResearch = async () => {
            await updateResearchJobToBeDoneId(researchJobToBeDone.id);
        };

        const restoreJobToBeDoneNotificationData: NotificationData = { content: 'Job-to-be-Done.', actionText: 'Undo' };
        if (canDeleteJobToBeDone)
            await dispatch(deleteItemWithUndo(BoxType.JobsToBeDone, researchJobToBeDone.id, restoreJobToBeDoneNotificationData, reAttachJobToBeDoneToResearch));
        else dispatch(addNotification(restoreJobToBeDoneNotificationData, reAttachJobToBeDoneToResearch));
    };

    const showErrors = props.isEditing && !!props.errors?.length;

    return (
        <>
            <EditorTwoColumnLayout
                mode={EditorTwoColumnLayoutMode.EqualColumns}
                firstColumn={
                    <EditorMainArea className="k-h-full">
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-h-full">
                            <div className={combineClassNames('k-flex-1 k-icp-panel k-p-4 k-pt-2 !k-rounded-md', showErrors ? '!k-border-error' : undefined)}>
                                <div className="k-fs-lg k-font-weight-medium k-mb-3">Job-to-be-Done</div>
                                <div>
                                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                                        {isLoadingJTBD ? (
                                            <Skeleton shape="text" />
                                        ) : (
                                            <JobToBeDoneInlineEditor
                                                key={researchJobToBeDone?.id}
                                                value={researchJobToBeDone?.content}
                                                onSave={value => updateJobToBeDone(value)}
                                                onDelete={deleteJobToBeDone}
                                                allowEditing={props.isEditing && !isGeneratingInterviewScript}
                                                onBeginCommit={props.onBeginCommit}
                                                onEndCommit={props.onEndCommit}
                                                className={combineClassNames('!k-mb-0', isGeneratingInterviewScript ? 'k-disabled' : undefined)}
                                                hints={hints && <HintsDefaultLayout title={hints.title} hints={hints.items} />}
                                            />
                                        )}
                                        {isGeneratingInterviewScript && (
                                            <StackLayout
                                                orientation="vertical"
                                                align={{ horizontal: 'center', vertical: 'top' }}
                                                className="k-gap-2 k-rounded k-icp-bg-warning-8 k-px-4 k-py-2 k-text-center"
                                            >
                                                <WarningIcon className="k-icp-icon k-icp-icon-size-6" />
                                                <p>
                                                    Interview script generation in progress. <br /> Job-to-be-Done will become editable shortly.
                                                </p>
                                            </StackLayout>
                                        )}
                                    </StackLayout>
                                </div>
                            </div>
                            <EditorErrorsList isEditing={props.isEditing} errors={props.errors} />
                        </StackLayout>
                    </EditorMainArea>
                }
                secondColumn={
                    <RelatedCanvasBoxes
                        boxes={props.params.secondaryBoxes}
                        navigate={props.navigate}
                        showItemsOrRelatedItemsTo={[{ box: BoxType.CustomerSegments, itemId: props.params.customerSegmentId }]}
                    />
                }
            />
            {askForScriptRegeneration && (
                <RegenerateScriptModal
                    btnsDisabled={dialogBtnsDisabled}
                    regenerateScriptAction={async () => {
                        await generateInterviewScript();
                        setAskForScriptRegeneration(false);
                    }}
                    onClose={() => setAskForScriptRegeneration(false)}
                />
            )}
        </>
    );
}

function JobToBeDoneInlineEditor({
    value,
    onSave,
    onDelete,
    allowEditing,
    onBeginCommit,
    onEndCommit,
    hints,
    className
}: {
    value?: string;
    onSave?: (value: string) => Promise<void>;
    onDelete?: () => void;
    allowEditing?: boolean;
    onBeginCommit?: () => void;
    onEndCommit?: () => void;
    hints?: React.ReactElement;
    className?: string;
}) {
    const [isEditing, setIsEditing] = useState(!value);

    function onCancel() {
        if (value) setIsEditing(false);
    }

    return (
        <CanvasBoxItem
            itemContent={value}
            onSave={async (isExplicit, content) => {
                await onSave?.(content);
                setIsEditing(false);
            }}
            onDelete={onDelete}
            onEdit={() => setIsEditing(true)}
            isEditing={isEditing}
            canEdit={allowEditing}
            itemShorthand={appConfig.canvas.boxes.JobsToBeDone.itemShorthand}
            onCancel={onCancel}
            elementAttributes={{ className }}
            onBeginCommit={onBeginCommit}
            onEndCommit={onEndCommit}
            hints={hints}
            autoShowHints
            autoFocus
        />
    );
}
