import { Button } from '@progress/kendo-react-buttons';
import { ReactNode, useState } from 'react';
import { ReactComponent as ExpandCollapseIcon } from '../../icons/chevrons-up.svg';
import { combineClassNames } from '../../services/common';

export const ExpandCollapsibleBlock = ({
    className,
    enabled = true,
    collapsed,
    children
}: {
    className: string;
    enabled?: boolean;
    collapsed?: boolean;
    children?: ReactNode;
}) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    return (
        <div className={combineClassNames(enabled ? (isCollapsed ? 'k-icp-expand-collapsible-collapsed' : undefined) : undefined, className)}>
            <div className="k-icp-expand-collapsible-content">{children}</div>
            {enabled && (
                <Button
                    fillMode="flat"
                    themeColor="secondary"
                    size="small"
                    className="k-icp-svg-icon-button k-icp-expand-collapsible-toggle"
                    onClick={() => setIsCollapsed(c => !c)}
                >
                    <ExpandCollapseIcon className="k-icp-icon k-text-base" />
                </Button>
            )}
        </div>
    );
};
