import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpServiceBase } from '../services/httpServiceBase';
import { useAppDispatch } from '../state/hooks';
import { refreshLicense } from '../state/user/userSlice';

export const logoutPagePath = '/account/logout';

export function useRefreshAuthentication() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return useCallback(
        async (navigateToUrl: string, pullLicense?: boolean) => {
            if (HttpServiceBase.authenticator) {
                const refreshedAuthentication = await HttpServiceBase.authenticator.refreshAuthentication();
                if (refreshedAuthentication) {
                    if (pullLicense) await dispatch(refreshLicense());
                    navigate(navigateToUrl);
                } else navigate(logoutPagePath);
            } else navigate(logoutPagePath);
        },
        [dispatch, navigate]
    );
}
