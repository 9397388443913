import { Component, ComponentType, PropsWithChildren } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { authenticationService } from '../../services/authenticationService';
import { pathWithReturnUrlToCurrentPage } from '../../services/common';
import { HttpException, NotFoundException } from '../../services/httpServiceBase';
import { addErrorNotification } from '../../state/notifications/platformNotificationsSlice';
import { resetCurrentUser } from '../../state/user/userSlice';

class GlobalErrorBoundary extends Component<GlobalErrorBoundaryProps> {
    private readonly subscriptionErrorPathName = '/account/subscription-error';

    private onUnhandledErrorDelegate;
    private onUnhandledRejectionDelegate;

    constructor(props: GlobalErrorBoundaryProps) {
        super(props);

        this.onUnhandledErrorDelegate = this.onUnhandledError.bind(this);
        this.onUnhandledRejectionDelegate = this.onUnhandledRejection.bind(this);
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        // Call set state due to the following issue: https://github.com/reactjs/reactjs.org/issues/3028
        this.setState(null);
        this.errorHandler(error);
    }

    componentDidMount() {
        window.addEventListener('error', this.onUnhandledErrorDelegate);
        window.addEventListener('unhandledrejection', this.onUnhandledRejectionDelegate);
        (window as any).globalErrorHandler = this.errorHandler.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('error', this.onUnhandledErrorDelegate);
        window.removeEventListener('unhandledrejection', this.onUnhandledRejectionDelegate);
        delete (window as any).globalErrorHandler;
    }

    onUnhandledError(e: ErrorEvent) {
        // Ignore errors that will be processed by the error boundary.
        // SEE: https://github.com/facebook/react/issues/10474
        if (e.error && e.error.stack && e.error.stack.indexOf('invokeGuardedCallbackDev') >= 0 && e.error.stack.indexOf('at render') >= 0) {
            return true;
        }

        this.errorHandler(e.error);
    }

    onUnhandledRejection(e: PromiseRejectionEvent) {
        this.errorHandler(e.reason);
    }

    errorHandler(error: any) {
        this.tryHandleError(error);
    }

    isUnauthorizedError(error: any) {
        return error instanceof HttpException && error.status === 401;
    }

    isSubscriptionError(error: any) {
        return error instanceof HttpException && error.status === 402;
    }

    isForbiddenError(error: any) {
        return error instanceof HttpException && error.status === 403;
    }

    isNotFoundError(error: any) {
        return (error instanceof HttpException && error.status === 404) || error instanceof NotFoundException;
    }

    shouldIgnoreError(error: any) {
        // This error is thrown by FireFox when a request does not receive a response (for example because request cancelled due to load of a new html document or a request is blocked by ad blocker)
        // Open Mozilla issue about this: https://bugzilla.mozilla.org/show_bug.cgi?id=1280189
        return error instanceof TypeError && error.message === 'NetworkError when attempting to fetch resource.';
    }

    tryHandleError(error: any) {
        if (!error) return;

        if (this.isUnauthorizedError(error)) {
            this.props.resetCurrentUser();
            authenticationService.logout();
            if (!authenticationService.isOnLoginPage()) this.props.navigate(authenticationService.getLoginUrl());
            return;
        }

        if (this.isForbiddenError(error)) {
            this.props.navigate('403');
            return;
        }

        if (this.isNotFoundError(error)) {
            this.props.navigate('404');
            return;
        }

        if (this.isSubscriptionError(error)) {
            if (window.location.pathname !== this.subscriptionErrorPathName)
                this.props.navigate(pathWithReturnUrlToCurrentPage(this.subscriptionErrorPathName));
            return;
        }

        if (this.shouldIgnoreError(error)) {
            return;
        }

        if (error.displayText || error.operationDisplayName) {
            if (!error.errorBoundaryHandled) {
                error.errorBoundaryHandled = true;
                this.props.addErrorNotification(error.displayText ? error.displayText : `Failed to ${error.operationDisplayName.toLowerCase()}.`);
            }

            return;
        }

        this.props.navigate('500');
    }

    render() {
        return this.props.children;
    }
}

function withNavigate<Props>(Component: ComponentType<Props>): ComponentType<Omit<Props, 'navigate'>> {
    return function(props: any) {
        const navigate = useNavigate();

        return <Component {...props} navigate={navigate} />;
    };
}

const reduxGlobalErrorBoundaryConnector = connect(null, { addErrorNotification, resetCurrentUser });
type GlobalErrorBoundaryProps = PropsWithChildren<{ navigate: NavigateFunction } & ConnectedProps<typeof reduxGlobalErrorBoundaryConnector>>;
const GlobalErrorBoundaryWithNavigate = withNavigate(GlobalErrorBoundary);
const ReduxGlobalErrorBoundary = reduxGlobalErrorBoundaryConnector(GlobalErrorBoundaryWithNavigate);

export { ReduxGlobalErrorBoundary as GlobalErrorBoundary };
