import { useCallback, useEffect, useState } from 'react';
import { immutableAdd, immutableAddAt, immutableAddOrUpdate, immutableRemove, immutableUpdate } from '../services/common';
import {
    CreateTemplatedAlternativeSolutionInsightData,
    CreateTemplatedInsightData,
    Insight,
    InsightCoverage,
    InsightCoverageEntry,
    InsightCoverageEntryData,
    InsightParameterValue,
    insightsService
} from '../services/insightsService';
import { HypothesisVerdict, InterviewEntry, InterviewHypothesisVerdict, InterviewSection, interviewsService } from '../services/interviewsService';
import {
    RealTimeUpdateInsightCoverageData,
    RealTimeUpdateInsightEventData,
    RealTimeUpdateInterviewEntryEventData,
    RealTimeUpdateInterviewHypothesisVerdictEventData,
    RealTimeUpdateInterviewQuoteEventData,
    realTimeUpdatesEventHub
} from '../services/realTimeUpdatesService';
import {
    InterviewHypothesisQuote,
    InterviewHypothesisQuoteData,
    InterviewInsightQuote,
    InterviewInsightQuoteData,
    InterviewQuote,
    InterviewQuoteType,
    researchService
} from '../services/researchService';
import { useAppDispatch } from '../state/hooks';
import { addNotification } from '../state/notifications/platformNotificationsSlice';
import { useAsRef } from './commonHooks';

export function useInterviewQuotes(
    ideaId: string,
    interviewId: number,
    type: InterviewQuoteType.Hypothesis
): {
    quotes: InterviewHypothesisQuote[] | undefined;
    addHypothesisQuote: (data: InterviewHypothesisQuoteData) => Promise<void>;
    addInsightQuote: (data: InterviewInsightQuoteData) => Promise<void>;
    updateQuote: (quoteId: number, fromPosition: number, toPosition: number) => Promise<void>;
    deleteQuote: (quoteId: number) => Promise<void>;
};
export function useInterviewQuotes(
    ideaId: string,
    interviewId: number,
    type: InterviewQuoteType.Insight
): {
    quotes: InterviewInsightQuote[] | undefined;
    addHypothesisQuote: (data: InterviewHypothesisQuoteData) => Promise<void>;
    addInsightQuote: (data: InterviewInsightQuoteData) => Promise<void>;
    updateQuote: (quoteId: number, fromPosition: number, toPosition: number) => Promise<void>;
    deleteQuote: (quoteId: number) => Promise<void>;
};
export function useInterviewQuotes(
    ideaId: string,
    interviewId: number
): {
    quotes: InterviewQuote[] | undefined;
    addHypothesisQuote: (data: InterviewHypothesisQuoteData) => Promise<void>;
    addInsightQuote: (data: InterviewInsightQuoteData) => Promise<void>;
    updateQuote: (quoteId: number, fromPosition: number, toPosition: number) => Promise<void>;
    deleteQuote: (quoteId: number) => Promise<void>;
};
export function useInterviewQuotes(
    ideaId: string,
    interviewId: number,
    type?: InterviewQuoteType
): {
    quotes: InterviewQuote[] | undefined;
    addHypothesisQuote: (data: InterviewHypothesisQuoteData) => Promise<void>;
    addInsightQuote: (data: InterviewInsightQuoteData) => Promise<void>;
    updateQuote: (quoteId: number, fromPosition: number, toPosition: number) => Promise<void>;
    deleteQuote: (quoteId: number) => Promise<void>;
} {
    const [quotes, setQuotes] = useState<InterviewQuote[]>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        researchService.getInterviewQuotesForInterview(ideaId, interviewId, type).then(setQuotes);

        async function onHypothesisQuoteUpserted(e: RealTimeUpdateInterviewQuoteEventData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;
            if (type === InterviewQuoteType.Hypothesis && e.hypothesisId === undefined) return;
            if (type === InterviewQuoteType.Insight && e.insightId === undefined) return;

            const upsertedQuote = await researchService.getInterviewQuote(ideaId, e.quoteId);
            setQuotes(quotes => immutableAddOrUpdate(quotes, upsertedQuote, r => r.id === e.quoteId));
        }

        function onHypothesisQuoteDeleted(e: RealTimeUpdateInterviewQuoteEventData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;
            if (type === InterviewQuoteType.Hypothesis && e.hypothesisId === undefined) return;
            if (type === InterviewQuoteType.Insight && e.insightId === undefined) return;

            setQuotes(quotes => immutableRemove(quotes, q => q.id !== e.quoteId));
        }

        realTimeUpdatesEventHub.addEventListener('interview', 'quoteAdd', onHypothesisQuoteUpserted);
        realTimeUpdatesEventHub.addEventListener('interview', 'quoteUpdate', onHypothesisQuoteUpserted);
        realTimeUpdatesEventHub.addEventListener('interview', 'quoteDelete', onHypothesisQuoteDeleted);
        realTimeUpdatesEventHub.addEventListener('interview', 'quoteRestore', onHypothesisQuoteUpserted);
    }, [ideaId, interviewId, type]);

    async function addHypothesisQuote(data: InterviewHypothesisQuoteData) {
        if (type && type !== InterviewQuoteType.Hypothesis) throw new Error('Cannot add hypothesis quote');

        const newQuote = await researchService.createInterviewHypothesisQuote(ideaId, data);
        setQuotes(quotes => immutableAdd(quotes, newQuote));
    }

    async function addInsightQuote(data: InterviewInsightQuoteData) {
        if (type && type !== InterviewQuoteType.Insight) throw new Error('Cannot add insight quote');

        const newQuote = await researchService.createInterviewInsightQuote(ideaId, data);
        setQuotes(quotes => immutableAdd(quotes, newQuote));
    }

    async function updateQuote(quoteId: number, fromPosition: number, toPosition: number) {
        const updatedQuote = await researchService.updateInterviewQuote(ideaId, quoteId, fromPosition, toPosition);
        if (type && updatedQuote.type !== type) return;

        setQuotes(quotes => immutableAddOrUpdate(quotes, updatedQuote, q => q.id === quoteId));
    }

    async function deleteQuote(quoteId: number) {
        const quoteIdex = quotes?.findIndex(q => q.id === quoteId);

        await researchService.deleteInterviewQuote(ideaId, quoteId);
        setQuotes(quotes => immutableRemove(quotes, q => q.id !== quoteId));

        dispatch(
            addNotification({ content: 'Quote removed.', actionText: 'Undo' }, async () => {
                const restoredQuote = await researchService.restoreInterviewQuote(ideaId, quoteId);
                if (type && restoredQuote.type !== type) return;
                setQuotes(quotes =>
                    quoteIdex !== undefined && quoteIdex !== -1 ? immutableAddAt(quotes, restoredQuote, quoteIdex) : immutableAdd(quotes, restoredQuote)
                );
            })
        );
    }

    return { quotes, addHypothesisQuote, addInsightQuote, updateQuote, deleteQuote };
}

export function useInterviewSections(ideaId: string, interviewId?: number, loadCallback?: (interviewSections: InterviewSection[]) => void) {
    const initialLoadCallbackRef = useAsRef(loadCallback);
    const [interviewSections, setInterviewSections] = useState<InterviewSection[]>();

    const loadInterviewSections = useCallback(
        (invokeCallback?: boolean) =>
            interviewId === undefined
                ? undefined
                : interviewsService.getInterviewSections(ideaId, interviewId).then(interviewSections => {
                      setInterviewSections(interviewSections);
                      invokeCallback && initialLoadCallbackRef.current?.(interviewSections);
                  }),
        [ideaId, initialLoadCallbackRef, interviewId]
    );

    useEffect(() => {
        setInterviewSections(undefined);
        loadInterviewSections(true);
    }, [loadInterviewSections]);

    const updateInterviewEntryInState = useCallback(function(sectionId: number, entry: InterviewEntry) {
        setInterviewSections(sections => {
            if (!sections) return sections;

            const updatedSections = immutableUpdate<InterviewSection>(
                sections,
                sectionToUpdate => ({
                    ...sectionToUpdate,
                    entries: immutableUpdate(sectionToUpdate.entries, entry, e => e.id === entry.id)
                }),
                section => section.id === sectionId
            );

            return updatedSections;
        });
    }, []);

    const addInterviewEntryToState = useCallback(function(sectionId: number, afterEntryId: number | undefined, entry: InterviewEntry) {
        setInterviewSections(sections => {
            if (!sections) return sections;

            const updatedSections = immutableUpdate<InterviewSection>(
                sections,
                sectionToUpdate => {
                    const addAtTheBeginning = afterEntryId === undefined;
                    const updatedEntries = addAtTheBeginning ? [entry, ...sectionToUpdate.entries] : [...sectionToUpdate.entries];
                    if (!addAtTheBeginning) {
                        const insertAtIndex = updatedEntries.findIndex(e => e.id === afterEntryId) + 1;
                        updatedEntries.splice(insertAtIndex, 0, entry);
                    }

                    return {
                        ...sectionToUpdate,
                        entries: updatedEntries
                    };
                },
                section => section.id === sectionId
            );

            return updatedSections;
        });
    }, []);

    const removeInterviewEntryFromState = useCallback(function(sectionId: number, entryId: number) {
        setInterviewSections(sections => {
            if (!sections) return sections;

            const updatedSections = immutableUpdate<InterviewSection>(
                sections,
                sectionToUpdate => ({
                    ...sectionToUpdate,
                    entries: immutableRemove(sectionToUpdate.entries, e => e.id !== entryId)
                }),
                section => section.id === sectionId
            );

            return updatedSections;
        });
    }, []);

    useEffect(() => {
        if (interviewId === undefined) return;

        function onInterviewEntryAddedOrRestored(e: RealTimeUpdateInterviewEntryEventData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;
            loadInterviewSections();
        }

        async function onInterviewEntryUpdated(e: RealTimeUpdateInterviewEntryEventData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;
            const updatedEntry = await interviewsService.getInterviewEntry(ideaId, interviewId, e.interviewEntryId);
            updateInterviewEntryInState(e.interviewSectionId, updatedEntry);
        }

        function onInterviewEntryDeleted(e: RealTimeUpdateInterviewEntryEventData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;
            removeInterviewEntryFromState(e.interviewSectionId, e.interviewEntryId);
        }

        realTimeUpdatesEventHub.addEventListener('interview', 'entryAdd', onInterviewEntryAddedOrRestored);
        realTimeUpdatesEventHub.addEventListener('interview', 'entryUpdate', onInterviewEntryUpdated);
        realTimeUpdatesEventHub.addEventListener('interview', 'entryDelete', onInterviewEntryDeleted);
        realTimeUpdatesEventHub.addEventListener('interview', 'entryRestore', onInterviewEntryAddedOrRestored);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview', 'entryAdd', onInterviewEntryAddedOrRestored);
            realTimeUpdatesEventHub.removeEventListener('interview', 'entryUpdate', onInterviewEntryUpdated);
            realTimeUpdatesEventHub.removeEventListener('interview', 'entryDelete', onInterviewEntryDeleted);
            realTimeUpdatesEventHub.removeEventListener('interview', 'entryRestore', onInterviewEntryAddedOrRestored);
        };
    }, [ideaId, interviewId, loadInterviewSections, removeInterviewEntryFromState, updateInterviewEntryInState]);

    return { interviewSections, loadInterviewSections, updateInterviewEntryInState, addInterviewEntryToState, removeInterviewEntryFromState };
}

export function useInterviewHypothesesVerdicts(ideaId: string, interviewId: number) {
    const [verdicts, setVerdicts] = useState<InterviewHypothesisVerdict[]>();

    function updateHypothesisVerdictInState(hypothesisId: number, updatedVerdict: InterviewHypothesisVerdict | null) {
        setVerdicts(verdicts =>
            updatedVerdict
                ? immutableAddOrUpdate(verdicts, updatedVerdict, v => v.hypothesisId === hypothesisId)
                : immutableRemove(verdicts, v => v.hypothesisId !== hypothesisId)
        );
    }

    async function updateHypothesisVerdict(hypothesisId: number, verdict: HypothesisVerdict | null) {
        if (verdict) {
            const updatedVerdict = await interviewsService.updateInterviewHypothesesVerdict(ideaId, interviewId, hypothesisId, verdict);
            updateHypothesisVerdictInState(hypothesisId, updatedVerdict);
        } else {
            await interviewsService.resetInterviewHypothesesVerdict(ideaId, interviewId, hypothesisId);
            updateHypothesisVerdictInState(hypothesisId, null);
        }
    }

    useEffect(() => {
        interviewsService.getInterviewHypothesesVerdicts(ideaId, interviewId).then(setVerdicts);

        async function onInterviewHypothesisVerdictUpdated(e: RealTimeUpdateInterviewHypothesisVerdictEventData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;

            const updatedVerdict = await interviewsService.getInterviewHypothesesVerdict(ideaId, interviewId, e.hypothesisId);
            updateHypothesisVerdictInState(e.hypothesisId, updatedVerdict);
        }

        function onInterviewHypothesisVerdictDeleted(e: RealTimeUpdateInterviewHypothesisVerdictEventData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;
            updateHypothesisVerdictInState(e.hypothesisId, null);
        }

        realTimeUpdatesEventHub.addEventListener('interview', 'hypothesisVerdictUpdate', onInterviewHypothesisVerdictUpdated);
        realTimeUpdatesEventHub.addEventListener('interview', 'hypothesisVerdictDelete', onInterviewHypothesisVerdictDeleted);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview', 'hypothesisVerdictUpdate', onInterviewHypothesisVerdictUpdated);
            realTimeUpdatesEventHub.removeEventListener('interview', 'hypothesisVerdictDelete', onInterviewHypothesisVerdictDeleted);
        };
    }, [ideaId, interviewId]);

    return [verdicts, updateHypothesisVerdict] as const;
}

export function useInterviewInsights(ideaId: string, interviewId: number) {
    const [insights, setInsights] = useState<Insight[]>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        insightsService.getAllInsights(ideaId, interviewId).then(setInsights);

        async function onInsightAddedOrRestored(e: RealTimeUpdateInsightEventData) {
            if (e.ideaId !== ideaId) return;

            const insight = await insightsService.getInsight(ideaId, e.insightId);
            if (!insight.interviewIds.includes(interviewId)) return;

            setInsights(insights => immutableAddOrUpdate(insights, insight, existingInsight => existingInsight.id === e.insightId));
        }

        async function onInsightUpdated(e: RealTimeUpdateInsightEventData) {
            if (e.ideaId !== ideaId) return;

            const insight = await insightsService.getInsight(ideaId, e.insightId);
            if (insight.interviewIds.includes(interviewId))
                setInsights(insights => immutableAddOrUpdate(insights, insight, existingInsight => existingInsight.id === e.insightId));
            else setInsights(insights => immutableRemove(insights, insight => insight.id !== e.insightId));
        }

        function onInsightDeleted(e: RealTimeUpdateInsightEventData) {
            if (e.ideaId !== ideaId) return;

            setInsights(insights => immutableRemove(insights, insight => insight.id !== e.insightId));
        }

        realTimeUpdatesEventHub.addEventListener('insight', 'add', onInsightAddedOrRestored);
        realTimeUpdatesEventHub.addEventListener('insight', 'update', onInsightUpdated);
        realTimeUpdatesEventHub.addEventListener('insight', 'delete', onInsightDeleted);
        realTimeUpdatesEventHub.addEventListener('insight', 'restore', onInsightAddedOrRestored);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('insight', 'add', onInsightAddedOrRestored);
            realTimeUpdatesEventHub.removeEventListener('insight', 'update', onInsightUpdated);
            realTimeUpdatesEventHub.removeEventListener('insight', 'delete', onInsightDeleted);
            realTimeUpdatesEventHub.removeEventListener('insight', 'restore', onInsightAddedOrRestored);
        };
    }, [ideaId, interviewId]);

    async function addJobToBeDoneInsights(data: CreateTemplatedInsightData) {
        const newInsights = await insightsService.createJobToBeDoneInsight(ideaId, interviewId, data);
        setInsights(insights => immutableAdd(insights, newInsights));
    }

    async function addAlternativeSolutionInsights(data: CreateTemplatedAlternativeSolutionInsightData) {
        const newInsights = await insightsService.createAlternativeSolutionInsight(ideaId, interviewId, data);
        setInsights(insights => immutableAdd(insights, newInsights));
    }

    async function attachInsight(insightId: number) {
        const attachedInsight = await insightsService.attachInsightToInterview(ideaId, insightId, interviewId);
        setInsights(insights => immutableAdd(insights, attachedInsight));
    }

    async function deleteInsight(insightId: number, onUndo?: () => Promise<void>) {
        await insightsService.deleteInsight(ideaId, insightId);
        setInsights(insights => immutableRemove(insights, i => i.id !== insightId));

        dispatch(
            addNotification({ content: 'Insight deleted.', actionText: 'Undo' }, async () => {
                await onUndo?.();
                const restoredInsight = await insightsService.restoreInsight(ideaId, insightId);
                setInsights(quotes => immutableAdd(quotes, restoredInsight));
            })
        );
    }

    async function detachInsight(insightId: number, onUndo?: () => Promise<void>) {
        await insightsService.detachInsightFromInterview(ideaId, insightId, interviewId);
        setInsights(insights => immutableRemove(insights, i => i.id !== insightId));

        dispatch(
            addNotification({ content: 'Insight removed.', actionText: 'Undo' }, async () => {
                await onUndo?.();
                const reAttachedInsight = await insightsService.attachInsightToInterview(ideaId, insightId, interviewId);
                setInsights(quotes => immutableAdd(quotes, reAttachedInsight));
            })
        );
    }

    async function updateInsight(insightId: number, parametersValues: InsightParameterValue[]) {
        const updatedInsight = await insightsService.updateInsight(ideaId, insightId, parametersValues);
        setInsights(insights => immutableAddOrUpdate(insights, updatedInsight, i => i.id === insightId));
    }

    return { insights, addJobToBeDoneInsights, addAlternativeSolutionInsights, attachInsight, deleteInsight, detachInsight, updateInsight };
}

export function useInterviewInsightsCoverage(ideaId: string, interviewId: number) {
    const [insightsCoverage, setInsightsCoverage] = useState<InsightCoverageEntry[]>();

    useEffect(() => {
        insightsService.getInterviewInsightsCoverageList(ideaId, interviewId).then(setInsightsCoverage);

        async function onInsightCoverageUpserted(e: RealTimeUpdateInsightCoverageData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;

            const coverageEntry = await insightsService.getInterviewInsightCoverage(ideaId, interviewId, e.entryId);
            setInsightsCoverage(insightsCoverage => immutableAddOrUpdate(insightsCoverage, coverageEntry, e => e.id === coverageEntry.id));
        }

        async function onInsightCoverageDeleted(e: RealTimeUpdateInsightCoverageData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;

            setInsightsCoverage(insightsCoverage => immutableRemove(insightsCoverage, entry => entry.id !== e.entryId));
        }

        realTimeUpdatesEventHub.addEventListener('insight', 'coverageEntryAdd', onInsightCoverageUpserted);
        realTimeUpdatesEventHub.addEventListener('insight', 'coverageEntryUpdate', onInsightCoverageUpserted);
        realTimeUpdatesEventHub.addEventListener('insight', 'coverageEntryDelete', onInsightCoverageDeleted);
        realTimeUpdatesEventHub.addEventListener('insight', 'coverageEntryRestore', onInsightCoverageUpserted);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('insight', 'coverageEntryAdd', onInsightCoverageUpserted);
            realTimeUpdatesEventHub.removeEventListener('insight', 'coverageEntryUpdate', onInsightCoverageUpserted);
            realTimeUpdatesEventHub.removeEventListener('insight', 'coverageEntryDelete', onInsightCoverageDeleted);
            realTimeUpdatesEventHub.removeEventListener('insight', 'coverageEntryRestore', onInsightCoverageUpserted);
        };
    }, [ideaId, interviewId]);

    async function createInsightsCoverage(data: InsightCoverageEntryData) {
        const createdEntry = await insightsService.createInterviewInsightsCoverage(ideaId, interviewId, data);
        setInsightsCoverage(insightsCoverage => immutableAdd(insightsCoverage, createdEntry));
    }

    async function updateInsightsCoverage(entryId: number, coverage: InsightCoverage) {
        const updatedEntry = await insightsService.setInterviewInsightsCoverage(ideaId, interviewId, entryId, coverage);
        setInsightsCoverage(insightsCoverage => immutableAddOrUpdate(insightsCoverage, updatedEntry, e => e.id === entryId));
    }

    return [insightsCoverage, createInsightsCoverage, updateInsightsCoverage] as const;
}
