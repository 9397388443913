import { ReactNode } from 'react';
import { combineClassNames } from '../../services/common';

export function TriangleArrow({
    baseFirstPart,
    baseSecondPart,
    height,
    borderWidth = 1,
    direction,
    fill,
    fillColor,
    borderFill,
    borderColor,
    showHinge,
    className,
    style
}: {
    baseFirstPart: number;
    baseSecondPart: number;
    height: number;
    borderWidth?: number;
    direction?: TriangleDirection;
    fill?: TriangleFill;
    fillColor?: string;
    borderFill?: TriangleFill;
    borderColor?: string;
    showHinge?: boolean;
    className?: string;
    style?: React.CSSProperties;
}) {
    const baseSide = baseFirstPart + baseSecondPart;
    const firstSide = Math.sqrt(Math.pow(baseFirstPart, 2) + Math.pow(height, 2));
    const secondSide = Math.sqrt(Math.pow(baseSecondPart, 2) + Math.pow(height, 2));

    const backgroundScale = 1 - (borderWidth * (firstSide + secondSide)) / (height * baseSide);

    const backgroundBaseFirstPart = baseFirstPart * backgroundScale;
    const backgroundBaseSecondPart = baseSecondPart * backgroundScale;
    const backgroundHeight = height * backgroundScale;

    const backgroundLeftPosition = (borderWidth * firstSide) / height;
    const backgroundRightPosition = (borderWidth * secondSide) / height;

    const backgroundStyle: React.CSSProperties =
        direction === 'down' || !direction
            ? { top: -height, left: -baseFirstPart + backgroundLeftPosition }
            : direction === 'up'
            ? { bottom: -height, left: backgroundRightPosition - baseSecondPart }
            : direction === 'left'
            ? { bottom: backgroundRightPosition - baseSecondPart, right: -height }
            : { bottom: backgroundLeftPosition - baseFirstPart, left: -height };

    return (
        <Triangle
            height={height}
            baseFirstPart={baseFirstPart}
            baseSecondPart={baseSecondPart}
            fill={borderWidth ? borderFill : fill}
            fillColor={borderWidth ? borderColor : fillColor}
            direction={direction}
            className={className}
            style={style}
        >
            {backgroundScale > 0 && (
                <Triangle
                    height={backgroundHeight}
                    baseFirstPart={backgroundBaseFirstPart}
                    baseSecondPart={backgroundBaseSecondPart}
                    style={backgroundStyle}
                    fill={fill}
                    fillColor={fillColor}
                    direction={direction}
                />
            )}
            {showHinge && !!borderWidth && backgroundScale > 0 && (
                <TriangleHinge
                    borderWidth={borderWidth}
                    length={backgroundBaseFirstPart + backgroundBaseSecondPart}
                    directionOffset={-height}
                    firstOffset={backgroundLeftPosition - baseFirstPart}
                    secondOffset={backgroundRightPosition - baseSecondPart}
                    direction={direction}
                    fill={fill}
                    fillColor={fillColor}
                />
            )}
        </Triangle>
    );
}

export type TriangleDirection = 'down' | 'left' | 'up' | 'right';
export type TriangleFill =
    | 'base'
    | 'current'
    | 'white'
    | 'black'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'dark'
    | 'light'
    | 'inverse';
export function Triangle({
    baseFirstPart,
    baseSecondPart,
    height,
    style,
    direction = 'down',
    children,
    fill,
    className,
    fillColor
}: {
    baseFirstPart: number;
    baseSecondPart: number;
    height: number;
    style?: React.CSSProperties;
    direction?: TriangleDirection;
    children?: ReactNode;
    fill?: TriangleFill;
    className?: string;
    fillColor?: string;
}) {
    const [mainBorder, firstBorder, lastBorder] = resolveBorderNamesFromTriangleDirection(direction);
    const fillClassName = resolveTriangleClassFromFill(fill);

    return (
        <div
            style={{
                width: 0,
                height: 0,
                [`${mainBorder}Width`]: height,
                [`${mainBorder}Color`]: fillColor,
                [`${mainBorder}Style`]: 'solid',
                [`${firstBorder}Width`]: baseFirstPart,
                [`${firstBorder}Color`]: 'transparent',
                [`${firstBorder}Style`]: 'solid',
                [`${lastBorder}Width`]: baseSecondPart,
                [`${lastBorder}Color`]: 'transparent',
                [`${lastBorder}Style`]: 'solid',
                position: 'absolute',
                ...style
            }}
            className={combineClassNames(fillClassName, className)}
        >
            {children}
        </div>
    );
}

function TriangleHinge({
    borderWidth,
    length,
    directionOffset,
    firstOffset,
    secondOffset,
    direction,
    fill,
    fillColor
}: {
    borderWidth: number;
    length: number;
    directionOffset: number;
    firstOffset: number;
    secondOffset: number;
    direction?: TriangleDirection;
    fill?: TriangleFill;
    fillColor?: string;
}) {
    const positionStyle: React.CSSProperties =
        direction === 'down' || !direction
            ? { top: directionOffset - borderWidth, left: firstOffset }
            : direction === 'up'
            ? { bottom: directionOffset - borderWidth, left: secondOffset }
            : direction === 'left'
            ? { bottom: secondOffset, right: directionOffset - borderWidth }
            : { bottom: firstOffset, left: directionOffset - borderWidth };

    const isHorizontal = !direction || direction === 'down' || direction === 'up';
    const borderToRender = isHorizontal ? 'Top' : 'Left';
    const style: React.CSSProperties = {
        position: 'absolute',
        width: isHorizontal ? length : 0,
        height: isHorizontal ? 0 : length,
        [`border${borderToRender}Width`]: borderWidth,
        [`border${borderToRender}Style`]: 'solid',
        [`border${borderToRender}Color`]: fillColor
    };

    return <div style={{ ...style, ...positionStyle }} className={resolveTriangleClassFromFill(fill)} />;
}

function resolveBorderNamesFromTriangleDirection(direction: TriangleDirection) {
    switch (direction) {
        case 'down':
            return ['borderTop', 'borderLeft', 'borderRight'] as const;
        case 'left':
            return ['borderRight', 'borderTop', 'borderBottom'] as const;
        case 'up':
            return ['borderBottom', 'borderRight', 'borderLeft'] as const;
        case 'right':
            return ['borderLeft', 'borderBottom', 'borderTop'] as const;
    }
}

function resolveTriangleClassFromFill(fill?: TriangleFill) {
    if (!fill) return undefined;

    if (fill === 'base') return 'k-icp-component-border';
    return `k-border-${fill}`;
}
