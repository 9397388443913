import { Outlet } from 'react-router-dom';
import { CanvasItemsZone } from '../../components/canvas/canvasItemsZone';

export function ContactsLayout() {
    return (
        <CanvasItemsZone>
            <Outlet />
        </CanvasItemsZone>
    );
}
