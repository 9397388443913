import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, ReactNode, useState } from 'react';
import exampleImageUrl from '../../images/examples-illustration.svg';
import guidanceImageUrl from '../../images/guidance-illustration.svg';
import interviewQuestionUsageExampleUrl from '../../images/interview-questions-usage-example.svg';
import interviewBackgroundIllustrationUrl from '../../images/interview-script-background.svg';
import interviewIllustrationUrl from '../../images/interview-script-illustration.svg';
import scheduleIllustrationUrl from '../../images/schedule-illustration.svg';
import { GraphicData, combineClassNames } from '../../services/common';
import {
    JourneyTaskGuidance,
    JourneyTaskGuidanceWithExamples,
    JourneyTaskGuidanceWithGraphic,
    JourneyTaskGuidanceWithGraphicPopup
} from '../../services/journeyService';
import { InlineEditor, InlineEditorViewerProps } from '../common/inlineEditor';
import { GraphicModal } from '../common/modal';
import { PagedContent } from '../ui/pagedContent';

export function TaskGuidance({ guidanceRef, availableGuidance }: { guidanceRef: string; availableGuidance: JourneyTaskGuidance[] }) {
    const guidance = availableGuidance.find(g => g.id === guidanceRef);
    if (!guidance) throw new Error('Missing guidance with id: ' + guidanceRef);
    if (guidance.type === 'guidance-examples') return <GuidanceWithExamples guidance={guidance} />;
    if (guidance.type === 'guidance-graphic') return <GuidanceWithGraphic guidance={guidance} />;
    if (guidance.type === 'guidance-graphic-popup') return <GuidanceWithGraphicPopup guidance={guidance} />;

    throw new Error('Unsupported guidance type: ' + guidance.type);
}

function GuidanceWithExamples({ guidance }: { guidance: JourneyTaskGuidanceWithExamples }) {
    return (
        <GuidanceLayout>
            <GuidanceItemsCard items={guidance.items} />
            <GuidanceExamplesCard examplesPageSize={guidance.examplesPageSize} examples={guidance.examples} />
        </GuidanceLayout>
    );
}

function GuidanceWithGraphic({ guidance }: { guidance: JourneyTaskGuidanceWithGraphic }) {
    return (
        <GuidanceLayout className="task-guidance-graphic-layout">
            <GuidanceItemsCard items={guidance.items} />
            <GuidanceGraphic graphic={guidance.graphic} />
        </GuidanceLayout>
    );
}

function GuidanceWithGraphicPopup({ guidance }: { guidance: JourneyTaskGuidanceWithGraphicPopup }) {
    const [popupOpen, setPopupOpen] = useState(false);
    const popupGraphic = graphicsRepository[guidance.popupGraphic];

    return (
        <GuidanceLayout>
            <GuidanceItemsCard items={guidance.items} />
            <GuidanceGraphicWithContent
                background={guidance.backgroundGraphic}
                graphic={guidance.graphic}
                content={
                    <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-4">
                        <p className="k-text-center" style={{ maxWidth: 290 }}>
                            {guidance.text}
                        </p>
                        <Button themeColor="secondary" fillMode="outline" onClick={() => setPopupOpen(true)}>
                            {guidance.buttonText}
                        </Button>
                    </StackLayout>
                }
            />
            {popupGraphic && popupOpen && <GraphicModal graphic={popupGraphic} onClose={() => setPopupOpen(false)} heading={guidance.popupTitle} />}
        </GuidanceLayout>
    );
}

function GuidanceLayout({ children, className }: { children: ReactNode; className?: string }) {
    return <div className={combineClassNames('k-p-4 k-d-grid k-grid-cols-2 k-align-items-start k-gap-6', className)}>{children}</div>;
}

function GuidanceItemsCard({ items }: { items: string[] }) {
    return (
        <TaskStepCard image={guidanceImageUrl} title="Guidance" type="plain">
            <ul className="k-fs-sm">
                {items.map((guidanceItemText, guidanceItemIndex) => (
                    <li key={guidanceItemIndex} dangerouslySetInnerHTML={{ __html: guidanceItemText }} />
                ))}
            </ul>
        </TaskStepCard>
    );
}

function GuidanceExamplesCard<TExamples, TViewerSettings>({
    examples,
    examplesPageSize,
    viewer,
    viewerSettings
}: {
    examples: TExamples[];
    examplesPageSize: number;
    viewer?: ComponentType<InlineEditorViewerProps<TExamples, TViewerSettings>>;
    viewerSettings?: TViewerSettings;
}) {
    return (
        <TaskStepCard image={exampleImageUrl} title="Examples" type="outlined">
            <PagedContent
                pageSize={examplesPageSize}
                items={examples.map((example, exampleIndex) => (
                    <div key={exampleIndex} className="canvas-box-item">
                        <InlineEditor value={example} viewer={viewer} viewClassName="!k-icp-text-sm" viewerSettings={viewerSettings} />
                    </div>
                ))}
            />
        </TaskStepCard>
    );
}

function TaskStepCard({ image, title, children, type }: { image: string; title: string; children?: ReactNode; type: 'plain' | 'outlined' }) {
    return (
        <div className={combineClassNames('!k-pt-2 formatted-content-area', type === 'outlined' ? 'k-popup k-popover-body !k-border-none' : undefined)}>
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3 k-mb-4">
                <img src={image} width="32" height="32" alt="" />
                <strong className="k-fs-lg k-icp-font-weight-medium">{title}</strong>
            </StackLayout>

            {children}
        </div>
    );
}

const graphicsRepository: Record<string, GraphicData | undefined> = {
    interview: {
        url: interviewIllustrationUrl,
        width: 218,
        height: 130,
        description: 'Interview illustration'
    },
    'interview-bg': {
        url: interviewBackgroundIllustrationUrl,
        width: 397,
        height: 260,
        description: 'Interview background illustration'
    },
    'interview-script-structure': {
        url: interviewQuestionUsageExampleUrl,
        width: 864,
        height: 534,
        description: 'Interview questions usage example'
    },
    schedule: {
        url: scheduleIllustrationUrl,
        width: 334,
        height: 220,
        description: 'Schedule illustration'
    }
};
function GuidanceGraphic({ graphic }: { graphic: string }) {
    const graphicData = graphicsRepository[graphic];
    if (!graphicData) return null;

    return <img src={graphicData.url} width={graphicData.width} height={graphicData.height} alt={graphicData.description} />;
}

function GuidanceGraphicWithContent({ graphic, content, background }: { graphic: string; content: ReactNode; background: string }) {
    const graphicData = graphicsRepository[graphic];
    const backgroundData = background ? graphicsRepository[background] : undefined;
    if (!graphicData) return null;

    return (
        <StackLayout
            style={{ background: `url(${backgroundData?.url}) center / contain no-repeat` }}
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'top' }}
            className="k-w-full k-h-full k-pt-4"
        >
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'stretch' }} className="k-gap-4">
                <img src={graphicData.url} width={graphicData.width} height={graphicData.height} alt={graphicData.description} />
                {content}
            </StackLayout>
        </StackLayout>
    );
}
