import { useGlobalCanvas } from '../../hooks/canvasHooks';
import { BoxType } from '../../services/canvasService';
import CanvasBoxItem from './item';

export const CanvasItemView = ({ itemId, box, className }: { itemId: number; box: BoxType; className?: string }) => {
    const { canvas, relatableItems } = useGlobalCanvas();
    const canvasItem = canvas.boxes?.find(b => b.type === box)?.items.find(i => i.id === itemId);

    if (!canvasItem) return null;

    return (
        <CanvasBoxItem
            relatableItems={relatableItems}
            itemContent={canvasItem.content}
            relatedItemIds={canvasItem.relatedItemIds}
            itemColorCode={canvasItem.colorCode}
            elementAttributes={className ? { className } : undefined}
        />
    );
};
