export class TabbedNav {
    private readonly cursorElement: HTMLElement;

    constructor(private readonly navElement: HTMLElement) {
        const cursorElement = this.navElement.querySelector<HTMLElement>('.k-icp-tabbed-nav-cursor');
        if (!cursorElement) throw new Error('No cursor element found!');
        this.cursorElement = cursorElement;

        this.onNavMouseLeave = this.onNavMouseLeave.bind(this);
        this.onNavMouseOver = this.onNavMouseOver.bind(this);

        this.navElement.addEventListener('mouseleave', this.onNavMouseLeave);
        this.navElement.addEventListener('mouseover', this.onNavMouseOver);

        this.markSelectedElement();
    }

    private markSelectedElement() {
        const selectedNavItem = this.navElement.querySelector<HTMLElement>(':scope > .active');
        this.markElement(selectedNavItem);
    }

    private onNavMouseLeave(e: MouseEvent) {
        if (e.target !== this.navElement) return;

        this.markSelectedElement();
    }

    private onNavMouseOver(e: MouseEvent) {
        const hoveredElement = e.target as HTMLElement;
        if (!hoveredElement || hoveredElement.parentElement !== this.navElement) return;

        this.markElement(hoveredElement);
    }

    private markElement(elementToMark: HTMLElement | undefined | null) {
        if (!elementToMark) {
            this.cursorElement.style.opacity = '';
            this.cursorElement.style.left = '';
            this.cursorElement.style.width = '';

            return;
        }

        this.cursorElement.style.opacity = elementToMark.classList.contains('active') ? '1' : '0.3';
        this.cursorElement.style.left = `${elementToMark.offsetLeft}px`;
        this.cursorElement.style.width = `${elementToMark.offsetWidth}px`;
    }

    destroy() {
        this.navElement.removeEventListener('mouseleave', this.onNavMouseLeave);
        this.navElement.removeEventListener('mouseover', this.onNavMouseOver);
    }
}
