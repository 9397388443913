import { useEffect, useState } from 'react';
import { realTimeUpdatesEventHub } from '../services/realTimeUpdatesService';
import { generateInitials, getPreferredColorIndex, PartiallyUpdateUserData, User, usersService } from '../services/usersService';
import { useAppDispatch } from '../state/hooks';
import { setCurrentUser } from '../state/user/userSlice';

export const useProfile = () => {
    const [currentProfile, setCurrentProfile] = useState<User & { initials: string; colorIndex: number }>();
    const dispatch = useAppDispatch();

    const setProfileFromUser = (user: User) => {
        setCurrentProfile({ ...user, initials: generateInitials(user, 2), colorIndex: getPreferredColorIndex(user) });
    };

    useEffect(() => {
        const loadCurrentUserProfile = async () => {
            const user = await usersService.getCurrent();
            setProfileFromUser(user);
        };

        loadCurrentUserProfile();

        realTimeUpdatesEventHub.addEventListener('user', 'profileUpdate', loadCurrentUserProfile);

        return () => realTimeUpdatesEventHub.removeEventListener('user', 'profileUpdate', loadCurrentUserProfile);
    }, []);

    const updateProfile = async (profile: PartiallyUpdateUserData) => {
        const updatedUser = await usersService.partiallyUpdateCurrent(profile);
        dispatch(setCurrentUser(updatedUser));
        setProfileFromUser(updatedUser);
    };

    return {
        currentProfile,
        updateProfile
    };
};
