import { StackLayout } from '@progress/kendo-react-layout';
import { BoxItem } from '../../services/canvasService';
import { combineClassNames } from '../../services/common';

export type CanvasItemSimpleViewProps = { item: BoxItem; singleLine?: boolean; className?: string; size?: 'small' | 'medium' };
export function CanvasItemSimpleView({ item, singleLine, className, size }: CanvasItemSimpleViewProps) {
    const wrapperClasses = [];
    wrapperClasses.push(size === 'small' ? 'k-gap-1' : 'k-gap-2');
    if (singleLine) wrapperClasses.push('k-max-w-full k-white-space-nowrap');
    if (size === 'small') wrapperClasses.push('k-fs-sm');
    if (className) wrapperClasses.push(className);

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className={wrapperClasses.join(' ')}>
            {item.colorCode && (
                <span
                    className={combineClassNames('palette-color k-flex-shrink-0', size === 'small' ? 'palette-color-sm -mt-0.75' : 'k-mt-thin')}
                    style={{ backgroundColor: `#${item.colorCode}` }}
                ></span>
            )}
            <span className={singleLine ? 'k-text-ellipsis' : undefined} title={singleLine ? item.content : undefined}>
                {item.content}
            </span>
        </StackLayout>
    );
}
