import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactElement, useState } from 'react';
import { combineClassNames } from '../../services/common';

export const PagedContent = ({ items, pageSize }: { items: ReactElement[]; pageSize: number }) => {
    const pages: ReactElement[][] = [];
    const pagesCount = Math.floor((items.length - 1) / pageSize) + 1;
    const [activePageIndex, setActivePageIndex] = useState(0);

    for (let itemIndex = 0, pageIndex = 0; pageIndex < pagesCount; pageIndex++) {
        const itemsInPage: ReactElement[] = [];
        pages.push(itemsInPage);
        for (let itemIndexInPage = 0; itemIndexInPage < pageSize && itemIndex < items.length; itemIndexInPage++, itemIndex++) {
            const item = items[itemIndex];
            itemsInPage.push(item);
        }
    }

    return (
        <div>
            <div>{pages[activePageIndex]}</div>
            {pagesCount > 1 && (
                <StackLayout align={{ horizontal: 'center' }} className="k-gap-1 k-mt-3">
                    {pages.map((p, pageIndex) => (
                        <Button
                            key={pageIndex}
                            fillMode="flat"
                            themeColor="secondary"
                            size="small"
                            className="k-icon-button"
                            onClick={() => setActivePageIndex(pageIndex)}
                        >
                            <div
                                className={combineClassNames(
                                    'k-d-flex k-align-items-center k-justify-content-center k-icp-paged-content-page-indicator',
                                    pageIndex === activePageIndex ? 'k-icp-paged-content-page-indicator-current' : undefined
                                )}
                            ></div>
                        </Button>
                    ))}
                </StackLayout>
            )}
        </div>
    );
};
