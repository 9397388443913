import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { useCallback, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import { CloseButtonProps } from 'react-toastify/dist/components';
import { AsyncOperationsQueue } from '../../services/common';
import { Notification, notificationsService } from '../../services/notificationsService';
import { realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { generateInitials, getPreferredColorIndex } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { notificationActionsMap, notificationPresentersMap } from '../topNav/notifications';
import { Icon } from '../ui/icon';
import UserAvatar from '../user/userAvatar';

export default function PopupNotifications() {
    const MAX_VISIBLE_NOTIFICATIONS_SIZE = 3;
    const visibleNotificationsToToastId = useRef<Map<number, Id>>(new Map());
    const schedulingNotificationsQueue = useRef<AsyncOperationsQueue | undefined>();
    const alreadyReadNotificationIds = useRef<number[]>([]);
    const isAuthenticated = useAppSelector(s => s.user) !== null;

    const ShownLastUnshownPopupNotifications = useCallback(async (notificationsCount: number) => {
        if (notificationsCount <= 0) return;
        const unshownNotifications = (await notificationsService.getPending())
            .filter(x => !visibleNotificationsToToastId.current.has(x.id))
            .slice(0, notificationsCount);

        if (!unshownNotifications || !unshownNotifications.length) return;

        const dismissToastOnClose = (notificationId: number) => {
            const toastId = visibleNotificationsToToastId.current.get(notificationId);
            if (toastId) toast.dismiss(toastId);
        };

        unshownNotifications.reverse().forEach(notification => {
            const tostId = toast(<PopupNotificationContent notification={notification} onActionExecuted={() => dismissToastOnClose(notification.id)} />, {
                closeButton: CloseButton,
                onClick: async (event: any) => {
                    if (alreadyReadNotificationIds.current.includes(notification.id)) return;
                    await notificationsService.markAsRead(notification.id);
                    alreadyReadNotificationIds.current.push(notification.id);
                },
                onOpen: async (event: any) => {
                    setTimeout(() => notificationsService.markAsShown(notification.id), 1000);
                },
                onClose: () => {
                    visibleNotificationsToToastId.current.delete(notification.id);
                    schedulingNotificationsQueue.current?.execute(() => ShownLastUnshownPopupNotifications(1));
                }
            });
            visibleNotificationsToToastId.current.set(notification.id, tostId);
        });
    }, []);

    useEffect(() => {
        if (!isAuthenticated) return;

        const fetchNotifications = async () => {
            await schedulingNotificationsQueue.current?.execute(() =>
                ShownLastUnshownPopupNotifications(MAX_VISIBLE_NOTIFICATIONS_SIZE - visibleNotificationsToToastId.current.size)
            );
        };

        const visibleNotifications = visibleNotificationsToToastId.current;
        schedulingNotificationsQueue.current = new AsyncOperationsQueue();

        const refreshTimeout = setTimeout(fetchNotifications, 1000); // Check with a delay since this is not the most important request on the page

        realTimeUpdatesEventHub.addEventListener('connection', 'reconnected', fetchNotifications);

        return () => {
            schedulingNotificationsQueue.current = undefined;
            realTimeUpdatesEventHub.removeEventListener('connection', 'reconnected', fetchNotifications);
            clearTimeout(refreshTimeout);
            visibleNotifications.clear();
            alreadyReadNotificationIds.current = [];
            toast.dismiss();
        };
    }, [ShownLastUnshownPopupNotifications, isAuthenticated]);

    useEffect(() => {
        const scheduleNewNotification = async () => {
            await schedulingNotificationsQueue.current?.execute(() =>
                visibleNotificationsToToastId.current.size < MAX_VISIBLE_NOTIFICATIONS_SIZE ? ShownLastUnshownPopupNotifications(1) : Promise.resolve()
            );
        };

        realTimeUpdatesEventHub.addEventListener('notification', 'new', scheduleNewNotification);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('notification', 'new', scheduleNewNotification);
        };
    }, [ShownLastUnshownPopupNotifications]);

    const CloseButton = (props: CloseButtonProps) => (
        <Button
            type="button"
            fillMode="flat"
            size="small"
            onClick={props.closeToast}
            className="k-icp-svg-icon-button k-align-self-start k-flex-shrink-0"
            style={{ bottom: 4 }}
        >
            <Icon kendoIconName="close" className="k-icp-icon" />
        </Button>
    );

    const PopupNotificationContent = ({ notification, onActionExecuted }: { notification: Notification; onActionExecuted?: Function }) => {
        const initiator = notification.initiator;
        const Presenter = notificationPresentersMap[notification.event.type];
        if (!Presenter) return null;
        const hasActions = notification.actions && !!notification.actions.length && !!notificationActionsMap[notification.event.type];

        return (
            <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-gap-3">
                <UserAvatar initials={generateInitials(initiator, 2)} picture={initiator.picture} colorIndex={getPreferredColorIndex(initiator)} />
                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'stretch' }} className="k-gap-2">
                    <div>
                        <Presenter {...notification.event.data} />
                    </div>
                    {hasActions && (
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                            {React.createElement(notificationActionsMap[notification.event.type]!, {
                                notificationsData: notification.event.data,
                                onActionExecuted
                            })}
                        </StackLayout>
                    )}
                </StackLayout>
            </StackLayout>
        );
    };

    return <Outlet />;
}
