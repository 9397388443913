import { Form } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryParam, useReturnUrl } from '../../hooks/routerHooks';
import passwordResetImageUrl from '../../images/auth/password-reset-illustration.svg';
import invalidResetPasswordImageUrl from '../../images/auth/reset-password-link-invalid-illustration.svg';
import { AuthForm, AuthFormSubmitButton } from '../../pages/layouts/authLayout';
import { authenticationService } from '../../services/authenticationService';
import { pathWithReturnUrl } from '../../services/common';
import { ErrorResponse, ErrorsResponse, HttpException } from '../../services/httpServiceBase';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import { useInvisibleReCaptcha } from '../common/invisibleReCaptcha';
import { PasswordField } from '../ui/inputs';
import { H1, P } from '../ui/typography';
import { ForgottenPasswordRequested } from './forgottenPassword';

export function InvalidResetPasswordView() {
    return (
        <ResetPasswordErrorView heading="Oops, this link is invalid">
            <div>
                <P className="!k-mb-4">If you have copied the link, make sure you are copying it in its entirety.</P>
                <P>To request a new link, submit a new password reset request.</P>
            </div>
        </ResetPasswordErrorView>
    );
}

export function ExpiredResetPasswordView() {
    return (
        <ResetPasswordErrorView heading="Oops, this link has expired">
            <P>Click the button below if you would like to submit a new request to reset your password.</P>
        </ResetPasswordErrorView>
    );
}

export function ResetPasswordForm() {
    const returnUrl = useReturnUrl();
    const resetPasswordCode = useQueryParam('secret');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>();
    const dispatch = useAppDispatch();
    const getReCaptchaToken = useInvisibleReCaptcha();

    if (passwordChanged)
        return (
            <>
                <H1>Password changed!</H1>
                <img src={passwordResetImageUrl} width="230" height="160" alt="Password changed" />
                <StackLayout className="auth-page-content-section k-gap-8" orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
                    <P>Your have successfully changed your password.</P>
                    <Link
                        to={pathWithReturnUrl('/login', returnUrl)}
                        className="k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md k-align-self-center"
                    >
                        Log in
                    </Link>
                </StackLayout>
            </>
        );

    async function onChangePasswordSubmit(data: Record<string, string>) {
        try {
            const reCaptchaToken = await getReCaptchaToken?.();
            await authenticationService.resetPassword(resetPasswordCode!, data.password, reCaptchaToken);
            setPasswordChanged(true);
        } catch (e) {
            let handledError = false;
            if (e instanceof HttpException) {
                if (e.status === 400) {
                    const errorData = e.parseResponse<ErrorResponse | ErrorsResponse>();
                    if (errorData.message instanceof Array && errorData.message.some(e => e.startsWith('newPassword'))) {
                        setPasswordErrorMessage('Password does not meet the requirements');

                        handledError = true;
                    } else if (typeof errorData.message === 'string' && errorData.message.startsWith('Password change code')) {
                        dispatch(addNotification({ content: 'Reset password link is invalid', type: 'error' }));

                        handledError = true;
                    }
                } else if (e.status === 410) {
                    dispatch(addNotification({ content: 'Reset password link has expired', type: 'error' }));

                    handledError = true;
                }
            }

            if (!handledError) throw e;
        }
    }

    return (
        <>
            <H1>Change password</H1>
            <div className="auth-page-content-section">
                <Form
                    onSubmit={onChangePasswordSubmit}
                    ignoreModified={true}
                    render={formRenderProps => (
                        <AuthForm button={<AuthFormSubmitButton disabled={!formRenderProps.allowSubmit}>Change my password</AuthFormSubmitButton>} gap={6}>
                            <div>
                                <div>Your password must contain:</div>
                                <ul className="k-m-0 k-pl-6">
                                    <li>at least 8 characters</li>
                                    <li>a lower case letter</li>
                                    <li>an upper case letter</li>
                                    <li>a number</li>
                                </ul>
                            </div>
                            <PasswordField label="New password" errorMessage={passwordErrorMessage} onChange={() => setPasswordErrorMessage(undefined)} />
                        </AuthForm>
                    )}
                />
            </div>
        </>
    );
}

export function ResetPasswordErrorView({ heading, children }: { heading: string; children?: ReactNode }) {
    const returnUrl = useReturnUrl();
    const email = useQueryParam('email');
    const navigate = useNavigate();
    const [requestedNewResetPassword, setRequestedNewResetPassword] = useState(false);
    const dispatch = useAppDispatch();
    const getReCaptchaToken = useInvisibleReCaptcha();

    async function requestNewResetPassword() {
        if (!email) {
            navigate(pathWithReturnUrl('/forgot-password', returnUrl));
            return;
        }

        try {
            const reCaptchaToken = await getReCaptchaToken?.();
            await authenticationService.forgotPassword(email, returnUrl, reCaptchaToken);
            setRequestedNewResetPassword(true);
        } catch (e) {
            if (e instanceof HttpException && e.status === 404) {
                dispatch(addNotification({ content: `User with email ${email} does not exist`, type: 'error' }));
            } else throw e;
        }
    }

    if (requestedNewResetPassword) return <ForgottenPasswordRequested emailAddress={email!} />;

    return (
        <>
            <H1>{heading}</H1>
            <img src={invalidResetPasswordImageUrl} width="230" height="160" alt="Invalid reset password link           " />
            <div className="auth-page-content-section">
                <StackLayout className="k-gap-8" orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
                    {children}
                    <AuthFormSubmitButton onClick={requestNewResetPassword} className="k-align-self-center">
                        Request reset instructions
                    </AuthFormSubmitButton>
                </StackLayout>
            </div>
            <div className="auth-page-content-section k-pt-4 k-icp-bordered-top k-icp-component-border k-text-center">
                <Link
                    to={pathWithReturnUrl('/login', returnUrl)}
                    className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md"
                >
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                        <span className="k-icon k-i-arrow-chevron-left"></span> Back to Login
                    </StackLayout>
                </Link>
            </div>
        </>
    );
}
