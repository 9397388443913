import { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { appConfig } from '../../config';
import { immutableRemove } from '../../services/common';

export type InvisibleReCaptchaContextValue = {
    getToken: () => Promise<string | null | undefined>;
    requestReCaptcha: () => number | undefined;
    releaseReCaptcha: (requestId: number) => void;
};
const InvisibleReCaptchaContext = createContext<InvisibleReCaptchaContextValue | undefined>(undefined);

// The purpose of this component is to load the ReCaptcha component only once for the entire application (when needed) since when removed the reCaptcha is throwing random errors (Timeout, access to null props, etc.). More details:
// https://github.com/dozoisch/react-google-recaptcha/issues/103
// https://github.com/google/recaptcha/issues/269
// https://github.com/dozoisch/react-google-recaptcha/issues/129
export function InvisibleReCaptchaScope({ children }: { children?: ReactNode }) {
    const reCaptchaRef = useRef<ReCAPTCHA>(null);
    const [renderReCaptcha, setRenderReCaptcha] = useState(false);
    const requestIdRef = useRef(1);
    const [reCaptchaRequests, setReCaptchaRequests] = useState<number[]>([]);

    const invisibleReCaptchaContextValue = useMemo<InvisibleReCaptchaContextValue>(
        () => ({
            async getToken() {
                if (!reCaptchaRef.current) return undefined;

                const reCaptchaToken = await reCaptchaRef.current.executeAsync();
                reCaptchaRef.current.reset();

                return reCaptchaToken;
            },
            requestReCaptcha() {
                if (!appConfig.recaptchaConfig.enabled) return undefined;

                setRenderReCaptcha(true);
                const requestId = requestIdRef.current++;
                setReCaptchaRequests(requests => [...requests, requestId]);

                return requestId;
            },
            releaseReCaptcha(requestId) {
                if (!appConfig.recaptchaConfig.enabled) return;

                setReCaptchaRequests(requests => immutableRemove(requests, r => r !== requestId));
            }
        }),
        []
    );

    return (
        <InvisibleReCaptchaContext.Provider value={invisibleReCaptchaContextValue}>
            {children}
            {appConfig.recaptchaConfig.enabled && renderReCaptcha && (
                <ReCAPTCHA
                    ref={reCaptchaRef}
                    size="invisible"
                    sitekey={appConfig.recaptchaConfig.siteKey}
                    className={reCaptchaRequests.length ? undefined : 'k-visibility-invisible'}
                />
            )}
        </InvisibleReCaptchaContext.Provider>
    );
}

export function useInvisibleReCaptcha() {
    const invisibleReCaptchaContext = useContext(InvisibleReCaptchaContext);

    useEffect(() => {
        if (!invisibleReCaptchaContext) return;

        const reCaptchaRequestId = invisibleReCaptchaContext.requestReCaptcha();

        if (reCaptchaRequestId === undefined) return;

        return () => invisibleReCaptchaContext.releaseReCaptcha(reCaptchaRequestId);
    }, [invisibleReCaptchaContext]);

    return invisibleReCaptchaContext?.getToken;
}
