import { InterviewStage } from '../../services/interviewsService';
import { interviewStageGuidanceContentProvider } from '../../services/interviewStageGuidanceContentProvider';
import { ResearchContactStage } from '../../services/researchService';
import { researchContactStagesPresentation, ResearchLabel } from '../research/researchContactStageView';

export function interviewStageGuidanceRender(stage: InterviewStage) {
    const guidanceContent = interviewStageGuidanceContentProvider.getGuidance(stage);
    if (!guidanceContent) return null;

    if (typeof guidanceContent === 'string') return guidanceContent;

    if (!guidanceContent.length) return null;

    return guidanceContent.map((content, contentIndex) => (
        <div className="list-item-like" key={contentIndex}>
            {content}
        </div>
    ));
}

export function InterviewStageGuidance({ stage, className }: { stage: InterviewStage; className?: string }) {
    const renderedGuidance = interviewStageGuidanceRender(stage);
    if (!renderedGuidance) return null;

    if (typeof renderedGuidance === 'string') return <div className={className}>{renderedGuidance}</div>;

    return <div className={className}>{renderedGuidance}</div>;
}

export type InterviewStagePresentation = {
    label: string;
    className: string;
};

export const interviewStagesPresentation: Record<InterviewStage, InterviewStagePresentation> = {
    [InterviewStage.NotStarted]: {
        ...researchContactStagesPresentation[ResearchContactStage.NotInterviewed],
        label: 'Interview not started'
    },
    [InterviewStage.Started]: {
        ...researchContactStagesPresentation[ResearchContactStage.InterviewPending],
        label: 'Interview in progress'
    },
    [InterviewStage.PendingReview]: researchContactStagesPresentation[ResearchContactStage.Interviewed],
    [InterviewStage.PendingInsightCapture]: researchContactStagesPresentation[ResearchContactStage.Reviewed],
    [InterviewStage.PendingHypothesesEvaluation]: researchContactStagesPresentation[ResearchContactStage.InsightsCaptured],
    [InterviewStage.Complete]: researchContactStagesPresentation[ResearchContactStage.Complete]
};

export function InterviewStageView({ stage }: { stage: InterviewStage }) {
    const stagePresentation = interviewStagesPresentation[stage];

    return <ResearchLabel className={stagePresentation.className}>{stagePresentation.label}</ResearchLabel>;
}
