import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, Fragment, ReactElement, ReactNode, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { findCanvasItem, useGlobalCanvas } from '../../../hooks/canvasHooks';
import { ReactComponent as InsightQuestionAnsweredIcon } from '../../../images/insight-question-answered-illustration.svg';
import { ReactComponent as InsightQuestionIgnoredIcon } from '../../../images/insight-question-ignored-illustration.svg';
import { ReactComponent as InsightQuestionIcon } from '../../../images/insight-question-illustration.svg';
import { BoxType } from '../../../services/canvasService';
import { AsyncOperationsTracker, groupBy, toAsyncTrackableOperation } from '../../../services/common';
import { domService } from '../../../services/domService';
import {
    Insight,
    InsightCatalog,
    InsightContextProperty,
    InsightCoverage,
    InsightCoverageEntry,
    InsightParameterValue,
    InsightPropertyType,
    InsightQuestion,
    InsightTemplate
} from '../../../services/insightsService';
import { InterviewInsightQuote } from '../../../services/researchService';
import { BoundDropDownButton } from '../../common/boundDropDownButton';
import { ConstantTextToken } from '../../common/tokenizedTextEditor';
import { ValidationScope, ValidationScopeHandle, ValidationUnit, ValidationUnitHandle, ValidationUnitValidator } from '../../common/validation';
import { InsightEditor, InsightEditorHandle } from '../../insights/insightEditor';
import LoadingIndicator from '../../ui/loadingIndicator';
import { InterviewItemEditorView, InterviewItemEditorViewHandle } from '../interviewItem';
import { InterviewItemQuotesEditList, InterviewItemQuotesGroupedByInterview, QuotesPresentation } from '../interviewItemQuotes';
import { AddInsightModal, PickInsightModal } from './insightModal';
import {
    ForkedInsightFilter,
    getForkingValueKey,
    getInsightsCoverage,
    getInsightsCoverageBranchFromForkValue,
    getInsightsForQuestion,
    groupInsightsByOrigin,
    groupInsightsCoverageByOrigin,
    isDisabledForkedItem,
    useExpandedInsightsCatalog
} from './insightsCommon';

export type InsightsFromCatalogEditorHandle = { commit: () => Promise<boolean> };
export type InsightsFromCatalogEditorProps = {
    ideaId: string;
    interviewId?: number;
    customerSegmentId?: number;
    jobToBeDoneId?: number;
    catalog?: InsightCatalog;
    context?: Record<string, unknown>;
    insights?: Insight[];
    quotes?: InterviewInsightQuote[];
    insightsCoverage?: InsightCoverageEntry[];
    onAddTemplatedInsight?: (
        sectionTag: string,
        questionTag: string,
        templateTag: string,
        parametersValues: InsightParameterValue[],
        forkValue?: unknown
    ) => Promise<void>;
    onRelateInsight?: (insightId: number) => Promise<void>;
    onDeleteInsight?: (insightId: number, onUndo?: () => Promise<void>) => Promise<void>;
    onDetachInsight?: (insightId: number, onUndo?: () => Promise<void>) => Promise<void>;
    onUpdateInsight?: (insightId: number, parameterValues: InsightParameterValue[], template: InsightTemplate) => Promise<void>;
    onCreateInsightsCoverage?: (sectionTag: string, questionTag: string, branch: string | null, coverage: InsightCoverage) => Promise<void>;
    onUpdateInsightsCoverage?: (insightsCoverageId: number, coverage: InsightCoverage) => Promise<void>;
    onCreateQuote?: (insightId: number, entryId: number, startIndex: number, endIndex: number) => Promise<void>;
    onUpdateQuote?: (quoteId: number, startIndex: number, endIndex: number) => Promise<void>;
    onDeleteQuote?: (quoteId: number) => Promise<void>;
    onQuoteClick?: (quoteId: number) => void;
    selectedQuoteId?: number;
    readonly?: boolean;
    hideEmptyLayers?: boolean;
    quotesPresentation?: QuotesPresentation;
};
export const InsightsFromCatalogEditor = forwardRef<InsightsFromCatalogEditorHandle, InsightsFromCatalogEditorProps>(function InsightsFromCatalogEditor(
    {
        ideaId,
        interviewId,
        customerSegmentId,
        jobToBeDoneId,
        catalog,
        context,
        insights,
        quotes,
        insightsCoverage,
        onAddTemplatedInsight,
        onRelateInsight,
        onDeleteInsight,
        onDetachInsight,
        onUpdateInsight,
        onCreateInsightsCoverage,
        onUpdateInsightsCoverage,
        onCreateQuote,
        onUpdateQuote,
        onDeleteQuote,
        onQuoteClick,
        selectedQuoteId,
        readonly,
        hideEmptyLayers,
        quotesPresentation
    },
    ref
) {
    const questionsEditorsMapRef = useRef<Map<string, InsightQuestionEditorHandle>>(new Map());
    const insightsEditorsMapRef = useRef<Map<number, InsightEditorHandle>>(new Map());
    const insightsValidationScopeRef = useRef<ValidationScopeHandle>(null);
    const operationsTracker = useRef(new AsyncOperationsTracker());

    const expandedInsightsCatalog = useExpandedInsightsCatalog(catalog, context);
    const [addInsightData, setAddInsightData] = useState<{ section: string; question: string; forkValue?: unknown }>();
    const [pickInsightData, setPickInsightData] = useState<{ section: string; question: string; forkFilter?: ForkedInsightFilter }>();
    const newInsightQuestion = useMemo(() => {
        if (!addInsightData || !catalog) return undefined;
        const section = catalog?.sections.find(s => s.tag === addInsightData.section);
        if (!section) return undefined;
        for (const group of section.groups) {
            const question = group.questions.find(q => q.tag === addInsightData.question);
            if (question) return question;
        }

        return undefined;
    }, [addInsightData, catalog]);
    const [addingQuoteForInsightId, setAddingQuoteForInsightId] = useState<number>();

    useImperativeHandle(
        ref,
        () => ({
            async commit() {
                await operationsTracker.current.await();

                if (insightsValidationScopeRef.current) {
                    insightsValidationScopeRef.current.validate();

                    if (!insightsValidationScopeRef.current.isValid) {
                        const invalidInsightEditor = Array.from(insightsEditorsMapRef.current.values()).find(e => !e.isValid);
                        let invalidElement = invalidInsightEditor?.element;
                        if (!invalidElement) {
                            const invalidQuestionEditor = Array.from(questionsEditorsMapRef.current.values()).find(e => !e.isValid);
                            invalidElement = invalidQuestionEditor?.element;
                        }
                        if (invalidElement) domService.scrollVerticallyIntoViewIfNeeded(invalidElement);
                    }

                    return insightsValidationScopeRef.current.isValid;
                }

                return true;
            }
        }),
        []
    );

    if (!expandedInsightsCatalog) return <LoadingIndicator size="big" className="k-display-block -block-center" />;

    const insightsByOrigin = insights && groupInsightsByOrigin(insights);
    const quotesByInsight = quotes && groupBy(quotes, q => q.insightId);
    const insightsCoverageByOrigin = insightsCoverage && groupInsightsCoverageByOrigin(insightsCoverage);
    const selectedQuoteInsightId = quotes && selectedQuoteId !== undefined ? quotes.find(q => q.id === selectedQuoteId)?.insightId : undefined;

    const trackableOnUpdateInsight = toAsyncTrackableOperation(operationsTracker.current, onUpdateInsight);
    const trackableOnCreateQuote = toAsyncTrackableOperation(operationsTracker.current, onCreateQuote);
    const trackableOnUpdateQuote = toAsyncTrackableOperation(operationsTracker.current, onUpdateQuote);
    const trackableOnDeleteQuote = toAsyncTrackableOperation(operationsTracker.current, onDeleteQuote);

    const allInsightsSections = expandedInsightsCatalog.sections.map(section => {
        const isForkedSection = 'forkingData' in section;
        const isSectionDisabled = isForkedSection && isDisabledForkedItem(section, context);
        const forkedSectionPropertyData = isForkedSection ? catalog?.context.properties.find(p => p.name === section.forkingData.propertyName) : undefined;
        const sectionForkingValueKey = isForkedSection ? getForkingValueKey(section.forkingData.value, forkedSectionPropertyData) : undefined;
        const sectionKey = sectionForkingValueKey ? `${section.definition.tag}_${sectionForkingValueKey}` : section.definition.tag;

        const allSectionGroups = section.groups.map(group => {
            const isForkedGroup = 'forkingData' in group;
            const isGroupDisabled = isSectionDisabled || (isForkedGroup && isDisabledForkedItem(group, context));
            const forkedGroupPropertyData = isForkedGroup ? catalog?.context.properties.find(p => p.name === group.forkingData.propertyName) : undefined;
            const groupForkingValueKey = isForkedGroup ? getForkingValueKey(group.forkingData.value, forkedGroupPropertyData) : undefined;
            const groupKey = groupForkingValueKey ? `${group.definition.title}_${groupForkingValueKey}` : group.definition.title;

            const allQuestions = group.questions.map(question => {
                const forkedInsightFilter =
                    isForkedGroup && forkedGroupPropertyData
                        ? {
                              propertyType: forkedGroupPropertyData.type,
                              value: group.forkingData.value
                          }
                        : isForkedSection && forkedSectionPropertyData
                        ? {
                              propertyType: forkedSectionPropertyData.type,
                              value: section.forkingData.value
                          }
                        : undefined;
                const forkValue = isForkedGroup ? group.forkingData.value : isForkedSection ? section.forkingData.value : undefined;
                const questionInsights =
                    insightsByOrigin && getInsightsForQuestion(insightsByOrigin, section.definition.tag, question.tag, forkedInsightFilter);
                const questionInsightsCoverage =
                    insightsCoverageByOrigin && getInsightsCoverage(insightsCoverageByOrigin, section.definition.tag, question.tag, forkValue);

                const questionKey = `${sectionKey}_${groupKey}_${question.tag}`;

                return {
                    key: questionKey,
                    insights: questionInsights,
                    insightsCoverage: questionInsightsCoverage,
                    forkValue,
                    forkFilter: forkedInsightFilter,
                    definition: question,
                    isSelected:
                        selectedQuoteInsightId !== undefined && questionInsights !== undefined
                            ? questionInsights.some(i => i.id === selectedQuoteInsightId)
                            : false
                } as const;
            });

            const questions = hideEmptyLayers ? allQuestions.filter(q => q.insights && q.insights.length) : allQuestions;

            const commonGroupProps = {
                key: groupKey,
                isDisabled: isGroupDisabled,
                questions
            };

            if (isForkedGroup)
                return {
                    ...commonGroupProps,
                    isForked: true,
                    forkedPropertyData: forkedGroupPropertyData,
                    forkingValue: group.forkingData.value,
                    definition: group.definition
                } as const;

            return {
                ...commonGroupProps,
                isForked: false,
                definition: group.definition
            } as const;
        });

        const sectionGroups = hideEmptyLayers ? allSectionGroups.filter(g => g.questions.length) : allSectionGroups;

        const commonSectionProps = {
            key: sectionKey,
            isDisabled: isSectionDisabled,
            groups: sectionGroups
        };

        if (isForkedSection)
            return {
                ...commonSectionProps,
                isForked: true,
                forkedPropertyData: forkedSectionPropertyData,
                forkingValue: section.forkingData.value,
                definition: section.definition
            } as const;

        return {
            ...commonSectionProps,
            isForked: false,
            definition: section.definition
        } as const;
    });

    const insightsSections = hideEmptyLayers ? allInsightsSections.filter(s => s.groups.length) : allInsightsSections;

    return (
        <ValidationScope ref={insightsValidationScopeRef}>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6 k-icp-component-border">
                {insightsSections.map((section, sectionIndex) => (
                    <InterviewSectionLayout
                        key={section.key}
                        title={section.definition.title}
                        subTitle={section.isForked ? <ForkedSectionSubtitle value={section.forkingValue} property={section.forkedPropertyData} /> : undefined}
                        separate={sectionIndex !== 0}
                    >
                        {section.groups.map(group => (
                            <InterviewGroupLayout
                                key={group.key}
                                title={
                                    group.isForked ? (
                                        <ForkedGroupTitle
                                            title={group.definition.title}
                                            value={group.forkingValue}
                                            property={group.forkedPropertyData}
                                            forkingContextProperties={group.definition.contextProperties}
                                        />
                                    ) : section.isForked ? (
                                        <ForkedGroupTitle
                                            title={group.definition.title}
                                            value={section.forkingValue}
                                            property={section.forkedPropertyData}
                                            forkingContextProperties={section.definition.contextProperties}
                                        />
                                    ) : (
                                        group.definition.title
                                    )
                                }
                            >
                                {group.questions.map(question => (
                                    <InsightQuestionEditor
                                        ref={r =>
                                            r ? questionsEditorsMapRef.current.set(question.key, r) : questionsEditorsMapRef.current.delete(question.key)
                                        }
                                        registerInsightEditorRef={(insightId, r) =>
                                            r ? insightsEditorsMapRef.current.set(insightId, r) : insightsEditorsMapRef.current.delete(insightId)
                                        }
                                        key={question.definition.tag}
                                        ideaId={ideaId}
                                        interviewId={interviewId}
                                        validationPrefix={`${section.key}_${group.key}`}
                                        question={question.definition}
                                        insightsCoverage={question.insightsCoverage?.coverage}
                                        insights={question.insights}
                                        disabled={group.isDisabled}
                                        selected={question.isSelected}
                                        onAddInsight={() =>
                                            setAddInsightData({
                                                section: section.definition.tag,
                                                question: question.definition.tag,
                                                forkValue: question.forkValue
                                            })
                                        }
                                        onPickExistingInsights={() =>
                                            setPickInsightData({
                                                section: section.definition.tag,
                                                question: question.definition.tag,
                                                forkFilter: question.forkFilter
                                            })
                                        }
                                        onIgnoreQuestion={toAsyncTrackableOperation(
                                            operationsTracker.current,
                                            () =>
                                                (question.insightsCoverage
                                                    ? question.insightsCoverage.coverage === InsightCoverage.NoInsights
                                                        ? undefined
                                                        : onUpdateInsightsCoverage?.(question.insightsCoverage.id, InsightCoverage.NoInsights)
                                                    : onCreateInsightsCoverage?.(
                                                          section.definition.tag,
                                                          question.definition.tag,
                                                          getInsightsCoverageBranchFromForkValue(question.forkValue),
                                                          InsightCoverage.NoInsights
                                                      )) ?? Promise.resolve()
                                        )}
                                        quotesByInsight={quotesByInsight}
                                        onDeleteInsight={
                                            onDeleteInsight &&
                                            toAsyncTrackableOperation(operationsTracker.current, async (insightId: number) => {
                                                const questionInsightsCoverage = question.insightsCoverage;
                                                const shouldUpdateInsightCoverage =
                                                    questionInsightsCoverage &&
                                                    questionInsightsCoverage.coverage !== InsightCoverage.NotCaptured &&
                                                    onUpdateInsightsCoverage &&
                                                    question.insights &&
                                                    question.insights.length === 1 &&
                                                    question.insights[0].id === insightId;
                                                if (shouldUpdateInsightCoverage)
                                                    await onUpdateInsightsCoverage(questionInsightsCoverage.id, InsightCoverage.NotCaptured);
                                                await onDeleteInsight(
                                                    insightId,
                                                    shouldUpdateInsightCoverage
                                                        ? () => onUpdateInsightsCoverage(questionInsightsCoverage.id, InsightCoverage.Captured)
                                                        : undefined
                                                );
                                            })
                                        }
                                        onDetachInsight={
                                            onDetachInsight &&
                                            toAsyncTrackableOperation(operationsTracker.current, async (insightId: number) => {
                                                const questionInsightsCoverage = question.insightsCoverage;
                                                const shouldUpdateInsightCoverage =
                                                    questionInsightsCoverage &&
                                                    questionInsightsCoverage.coverage !== InsightCoverage.NotCaptured &&
                                                    onUpdateInsightsCoverage &&
                                                    question.insights &&
                                                    question.insights.length === 1 &&
                                                    question.insights[0].id === insightId;
                                                if (shouldUpdateInsightCoverage)
                                                    await onUpdateInsightsCoverage(questionInsightsCoverage.id, InsightCoverage.NotCaptured);
                                                await onDetachInsight(
                                                    insightId,
                                                    shouldUpdateInsightCoverage
                                                        ? () => onUpdateInsightsCoverage(questionInsightsCoverage.id, InsightCoverage.Captured)
                                                        : undefined
                                                );
                                            })
                                        }
                                        onUpdateInsight={trackableOnUpdateInsight}
                                        addingQuoteForInsightId={addingQuoteForInsightId}
                                        onAddQuiteForInsight={setAddingQuoteForInsightId}
                                        onCancelAddingQuote={() => setAddingQuoteForInsightId(undefined)}
                                        onCreateQuote={trackableOnCreateQuote}
                                        onUpdateQuote={trackableOnUpdateQuote}
                                        onDeleteQuote={trackableOnDeleteQuote}
                                        onQuoteClick={onQuoteClick}
                                        selectedQuoteId={selectedQuoteId}
                                        readonly={readonly}
                                        quotesPresentation={quotesPresentation}
                                    />
                                ))}
                            </InterviewGroupLayout>
                        ))}
                    </InterviewSectionLayout>
                ))}
                {!readonly && addInsightData && newInsightQuestion && (
                    <AddInsightModal
                        onClose={() => setAddInsightData(undefined)}
                        question={newInsightQuestion.content}
                        templates={newInsightQuestion.insightTemplates}
                        context={context}
                        forkValue={addInsightData.forkValue}
                        onSave={
                            onAddTemplatedInsight &&
                            (async (templateTag, parametersValues) => {
                                const questionInsightsCoverage =
                                    insightsCoverageByOrigin &&
                                    getInsightsCoverage(insightsCoverageByOrigin, addInsightData.section, addInsightData.question, addInsightData.forkValue);

                                await (questionInsightsCoverage
                                    ? questionInsightsCoverage.coverage === InsightCoverage.Captured
                                        ? undefined
                                        : onUpdateInsightsCoverage?.(questionInsightsCoverage.id, InsightCoverage.Captured)
                                    : onCreateInsightsCoverage?.(
                                          addInsightData.section,
                                          addInsightData.question,
                                          getInsightsCoverageBranchFromForkValue(addInsightData.forkValue),
                                          InsightCoverage.Captured
                                      ));

                                await onAddTemplatedInsight(
                                    addInsightData.section,
                                    addInsightData.question,
                                    templateTag,
                                    parametersValues,
                                    addInsightData.forkValue
                                );
                            })
                        }
                    />
                )}
                {!readonly && pickInsightData && customerSegmentId !== undefined && jobToBeDoneId !== undefined && (
                    <PickInsightModal
                        ideaId={ideaId}
                        customerSegmentId={customerSegmentId}
                        jobToBeDoneId={jobToBeDoneId}
                        catalogId={catalog?.id}
                        sectionTag={pickInsightData.section}
                        questionTag={pickInsightData.question}
                        forkFilter={pickInsightData.forkFilter}
                        ignoredInterviewId={interviewId}
                        onClose={() => setPickInsightData(undefined)}
                        onSelectInsight={
                            onRelateInsight &&
                            (async insightId => {
                                const questionInsightsCoverage =
                                    insightsCoverageByOrigin &&
                                    getInsightsCoverage(
                                        insightsCoverageByOrigin,
                                        pickInsightData.section,
                                        pickInsightData.question,
                                        pickInsightData.forkFilter?.value
                                    );

                                await (questionInsightsCoverage
                                    ? questionInsightsCoverage.coverage === InsightCoverage.Captured
                                        ? undefined
                                        : onUpdateInsightsCoverage?.(questionInsightsCoverage.id, InsightCoverage.Captured)
                                    : onCreateInsightsCoverage?.(
                                          pickInsightData.section,
                                          pickInsightData.question,
                                          getInsightsCoverageBranchFromForkValue(pickInsightData.forkFilter?.value),
                                          InsightCoverage.Captured
                                      ));

                                await onRelateInsight(insightId);
                            })
                        }
                    />
                )}
            </StackLayout>
        </ValidationScope>
    );
});

function InterviewSectionLayout({ title, subTitle, separate, children }: { title: string; subTitle?: ReactElement; separate?: boolean; children?: ReactNode }) {
    return (
        <Fragment>
            {separate && <div className="k-separator" />}
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1">
                    <strong className="k-fs-lg">{title}</strong>
                    {subTitle}
                </StackLayout>
                {children}
            </StackLayout>
        </Fragment>
    );
}

function InterviewGroupLayout({ title, children }: { title: ReactNode; children?: ReactNode }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            <div>{title}</div>
            {children}
        </StackLayout>
    );
}

function ForkedSectionSubtitle({ value, property }: { value: unknown; property?: InsightContextProperty }) {
    if (!property) return null;

    if (property.type === InsightPropertyType.AlternativeSolution && property.name === 'hiredSolutions')
        return <HiredAlternativeSolutionForkedSectionSubtitle value={value} />;

    return null;
}

function HiredAlternativeSolutionForkedSectionSubtitle({ value }: { value: unknown }) {
    const { canvas } = useGlobalCanvas(undefined, true);
    if (!value) return <span className="k-icp-subtle-text">No hired solution specified</span>;

    if (typeof value !== 'number') throw Error('Forking value for alternative solution should be number');

    const alternativeSolution = findCanvasItem(canvas?.boxes, BoxType.AlternativeSolutions, value);
    return (
        <div>
            <span className="k-icp-subtle-text">for hired solution: </span>
            {alternativeSolution ? alternativeSolution.content : <Skeleton shape="text" style={{ width: 200 }} />}
        </div>
    );
}

function ForkedGroupTitle({
    title,
    value,
    property,
    forkingContextProperties
}: {
    title: string;
    value: unknown;
    property?: InsightContextProperty;
    forkingContextProperties: string[];
}) {
    if (!property) return <>{title}</>;

    if (property.type === InsightPropertyType.AlternativeSolution) {
        return <AlternativeSolutionForkedGroupTitle title={title} value={value} property={property} forkingContextProperties={forkingContextProperties} />;
    }

    return <>{title}</>;
}

function AlternativeSolutionForkedGroupTitle({
    title,
    value,
    property,
    forkingContextProperties
}: {
    title: string;
    value: unknown;
    property: InsightContextProperty;
    forkingContextProperties: string[];
}) {
    const { canvas } = useGlobalCanvas(undefined, true);

    if (!value) {
        if (forkingContextProperties.length > 1) return <>{title} for alternative solution</>;

        return (
            <>
                {title} for {property.name === 'hiredSolutions' ? 'hired' : property.name === 'rejectedSolutions' ? 'not hired' : 'alternative'} solution
            </>
        );
    }

    if (typeof value !== 'number') throw Error('Forking value for alternative solution should be number');

    const alternativeSolution = findCanvasItem(canvas.boxes, BoxType.AlternativeSolutions, value);
    return (
        <>
            <span>{title} for </span>
            <ConstantTextToken>{alternativeSolution ? alternativeSolution.content : <Skeleton shape="text" style={{ width: 200 }} />}</ConstantTextToken>
        </>
    );
}

type InsightQuestionEditorHandle = { element?: HTMLElement | null; isValid: boolean };
type InsightQuestionEditorProps = {
    ideaId: string;
    interviewId?: number;
    validationPrefix: string;
    question: InsightQuestion;
    insightsCoverage?: InsightCoverage;
    insights?: Insight[];
    disabled?: boolean;
    onAddInsight?: () => void;
    onPickExistingInsights?: () => void;
    onIgnoreQuestion?: () => Promise<void>;
    quotesByInsight?: Partial<Record<number, InterviewInsightQuote[]>>;
    onDeleteInsight?: (insightId: number) => Promise<void>;
    onDetachInsight?: (insightId: number) => Promise<void>;
    onUpdateInsight?: (insightId: number, parameterValues: InsightParameterValue[], template: InsightTemplate) => Promise<void>;
    addingQuoteForInsightId?: number;
    onAddQuiteForInsight?: (insightId: number) => void;
    onCancelAddingQuote?: () => void;
    onCreateQuote?: (insightId: number, entryId: number, startIndex: number, endIndex: number) => Promise<void>;
    onUpdateQuote?: (quoteId: number, startIndex: number, endIndex: number) => Promise<void>;
    onDeleteQuote?: (quoteId: number) => Promise<void>;
    registerInsightEditorRef?: (insightId: number, ref: InsightEditorHandle | null) => void;
    onQuoteClick?: (quoteId: number) => void;
    selectedQuoteId?: number;
    readonly?: boolean;
    quotesPresentation?: QuotesPresentation;
    selected?: boolean;
};
const InsightQuestionEditor = forwardRef<InsightQuestionEditorHandle, InsightQuestionEditorProps>(function InsightQuestionEditor(
    {
        ideaId,
        interviewId,
        validationPrefix,
        question,
        insightsCoverage,
        insights,
        disabled,
        onAddInsight,
        onPickExistingInsights,
        onIgnoreQuestion,
        quotesByInsight,
        onDeleteInsight,
        onDetachInsight,
        onUpdateInsight,
        addingQuoteForInsightId,
        onAddQuiteForInsight,
        onCancelAddingQuote,
        onCreateQuote,
        onUpdateQuote,
        onDeleteQuote,
        registerInsightEditorRef,
        onQuoteClick,
        selectedQuoteId,
        readonly,
        quotesPresentation,
        selected
    },
    ref
) {
    const hasInsights = insights && insights.length > 0;
    const isIgnored = insightsCoverage !== undefined && insightsCoverage === InsightCoverage.NoInsights;
    const isValid = isIgnored || hasInsights;
    const validationUnitRef = useRef<ValidationUnitHandle>(null);
    const editorViewRef = useRef<InterviewItemEditorViewHandle>(null);

    useImperativeHandle(
        ref,
        () => ({
            get element() {
                return editorViewRef.current?.element;
            },
            get isValid() {
                if (!validationUnitRef.current) return true;

                return validationUnitRef.current.isValid;
            }
        }),
        []
    );

    // is valid is added as a dependency in order to trigger validation when the isValid property is changed from outside of the component
    const validator = useCallback<ValidationUnitValidator<boolean | undefined>>(
        isValid => (isValid ? undefined : 'Add insight or mark that no insights are uncovered'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isValid]
    );

    return (
        <ValidationUnit
            ref={validationUnitRef}
            name={`${validationPrefix}_question_${question.tag}`}
            value={isValid}
            validator={disabled || readonly ? undefined : validator}
        >
            {(questionErrorMessage, onQuestionValidityChange) => (
                <InsightQuestionEditorView
                    ref={editorViewRef}
                    question={question}
                    disabled={disabled}
                    icon={resolveInsightQuestionEditorIcon(hasInsights, insightsCoverage)}
                    disableIgnoreQuestion={isIgnored || hasInsights}
                    errorMessage={questionErrorMessage}
                    onAddInsight={onAddInsight}
                    onPickExistingInsights={onPickExistingInsights}
                    onIgnoreQuestion={
                        onIgnoreQuestion &&
                        (async () => {
                            await onIgnoreQuestion();
                            onQuestionValidityChange(true, true);
                        })
                    }
                    readonly={readonly}
                    selected={selected}
                >
                    {hasInsights ? (
                        <InsightsSeparatedList
                            ideaId={ideaId}
                            interviewId={interviewId}
                            insights={insights}
                            quotesByInsight={quotesByInsight}
                            onDeleteInsight={
                                onDeleteInsight &&
                                (async (insightId: number) => {
                                    await onDeleteInsight(insightId);
                                    onQuestionValidityChange(isIgnored || insights.length > 1, true);
                                })
                            }
                            onDetachInsight={
                                onDetachInsight &&
                                (async (insightId: number) => {
                                    await onDetachInsight(insightId);
                                    onQuestionValidityChange(isIgnored || insights.length > 1, true);
                                })
                            }
                            onUpdateInsight={onUpdateInsight}
                            addingQuoteForInsightId={addingQuoteForInsightId}
                            onAddQuiteForInsight={onAddQuiteForInsight}
                            onCancelAddingQuote={onCancelAddingQuote}
                            onCreateQuote={onCreateQuote}
                            onUpdateQuote={onUpdateQuote}
                            onDeleteQuote={onDeleteQuote}
                            registerInsightEditorRef={registerInsightEditorRef}
                            onQuoteClick={onQuoteClick}
                            selectedQuoteId={selectedQuoteId}
                            readonly={readonly}
                            quotesPresentation={quotesPresentation}
                        />
                    ) : isIgnored ? (
                        <span className="k-icp-subtle-text">No insights</span>
                    ) : (
                        undefined
                    )}
                </InsightQuestionEditorView>
            )}
        </ValidationUnit>
    );
});

function resolveInsightQuestionEditorIcon(hasInsights: boolean | undefined, coverage: InsightCoverage | undefined) {
    if (hasInsights) return InsightQuestionAnsweredIcon;

    if (!coverage) return undefined;

    if (coverage === InsightCoverage.NoInsights) return InsightQuestionIgnoredIcon;
    if (coverage === InsightCoverage.Captured) return InsightQuestionAnsweredIcon;

    return undefined;
}

type InsightQuestionEditorViewProps = {
    question: InsightQuestion;
    children?: ReactNode;
    disabled?: boolean;
    onAddInsight?: () => void;
    onPickExistingInsights?: () => void;
    onIgnoreQuestion?: () => void;
    icon?: ComponentType<React.SVGProps<SVGSVGElement>>;
    disableIgnoreQuestion?: boolean;
    errorMessage?: string;
    readonly?: boolean;
    selected?: boolean;
};
const InsightQuestionEditorView = forwardRef<InterviewItemEditorViewHandle, InsightQuestionEditorViewProps>(function InsightQuestionEditorView(
    { question, children, disabled, onAddInsight, onPickExistingInsights, onIgnoreQuestion, icon, disableIgnoreQuestion, errorMessage, readonly, selected },
    ref
) {
    const Icon = icon ?? InsightQuestionIcon;

    return (
        <InterviewItemEditorView ref={ref} disabled={disabled} errorMessage={errorMessage} selected={selected}>
            <div className="k-p-4">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                    <Icon />
                    <strong className="k-flex-1">{question.content}</strong>
                    {!readonly && (
                        <BoundDropDownButton
                            items={
                                disabled
                                    ? undefined
                                    : [
                                          {
                                              text: 'Add new insight',
                                              action: onAddInsight
                                          },
                                          {
                                              text: 'Pick existing insight',
                                              action: onPickExistingInsights
                                          },
                                          {
                                              text: 'No insight uncovered',
                                              disabled: disableIgnoreQuestion,
                                              action: onIgnoreQuestion
                                          }
                                      ]
                            }
                            size="small"
                            fillMode="flat"
                            themeColor="secondary"
                            text="Capture insight"
                            icon="arrow-60-down"
                            buttonClass="k-flex-row-reverse"
                            disabled={disabled}
                        />
                    )}
                </StackLayout>
                {children && <div className="k-mt-4 k-ml-9">{children}</div>}
            </div>
        </InterviewItemEditorView>
    );
});

function InsightsSeparatedList({
    ideaId,
    interviewId,
    insights,
    quotesByInsight,
    onDeleteInsight,
    onDetachInsight,
    onUpdateInsight,
    addingQuoteForInsightId,
    onAddQuiteForInsight,
    onCancelAddingQuote,
    onCreateQuote,
    onUpdateQuote,
    onDeleteQuote,
    registerInsightEditorRef,
    onQuoteClick,
    selectedQuoteId,
    readonly,
    quotesPresentation
}: {
    ideaId: string;
    interviewId?: number;
    insights: Insight[];
    quotesByInsight?: Partial<Record<number, InterviewInsightQuote[]>>;
    onDeleteInsight?: (insightId: number) => void;
    onDetachInsight?: (insightId: number) => void;
    onUpdateInsight?: (insightId: number, parameterValues: InsightParameterValue[], template: InsightTemplate) => Promise<void>;
    addingQuoteForInsightId?: number;
    onAddQuiteForInsight?: (insightId: number) => void;
    onCancelAddingQuote?: () => void;
    onCreateQuote?: (insightId: number, entryId: number, startIndex: number, endIndex: number) => Promise<void>;
    onUpdateQuote?: (quoteId: number, startIndex: number, endIndex: number) => Promise<void>;
    onDeleteQuote?: (quoteId: number) => Promise<void>;
    registerInsightEditorRef?: (insightId: number, ref: InsightEditorHandle | null) => void;
    onQuoteClick?: (quoteId: number) => void;
    selectedQuoteId?: number;
    readonly?: boolean;
    quotesPresentation?: QuotesPresentation;
}) {
    const [editQuoteId, setEditQuoteId] = useState<number>();
    function stopEditingQuote(quoteId: number) {
        setEditQuoteId(id => (id === quoteId ? undefined : id));
    }

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4 k-icp-component-border">
            {insights.map((insight, insightIndex) => (
                <Fragment key={insight.id}>
                    {insightIndex > 0 && <div className="k-separator" />}
                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                        <InsightEditor
                            ref={registerInsightEditorRef && (r => registerInsightEditorRef(insight.id, r))}
                            content={insight.template.content}
                            parameters={insight.template.parameters}
                            parametersValues={insight.parameterValues}
                            onDelete={() =>
                                insight.interviewIds.length > 1 &&
                                interviewId !== undefined &&
                                insight.interviewIds.some(insightInterviewId => insightInterviewId !== interviewId)
                                    ? onDetachInsight?.(insight.id)
                                    : onDeleteInsight?.(insight.id)
                            }
                            onSave={onUpdateInsight && (parameterValues => onUpdateInsight(insight.id, parameterValues, insight.template))}
                            disabled={readonly}
                        />
                        {!quotesPresentation || quotesPresentation === QuotesPresentation.FlatList ? (
                            <InterviewItemQuotesEditList
                                ideaId={ideaId}
                                currentInterviewId={interviewId}
                                quotes={quotesByInsight?.[insight.id]}
                                addingQuote={insight.id === addingQuoteForInsightId}
                                onAddQuote={onAddQuiteForInsight && (() => onAddQuiteForInsight(insight.id))}
                                onCancelAddingQuote={onCancelAddingQuote}
                                editQuoteId={editQuoteId}
                                onEditQuote={quoteId => {
                                    onCancelAddingQuote?.();
                                    setEditQuoteId(quoteId);
                                }}
                                onCancelEditingQuote={stopEditingQuote}
                                onCreateQuote={
                                    onCreateQuote &&
                                    ((entryId: number, startIndex: number, endIndex: number) =>
                                        onCreateQuote(insight.id, entryId, startIndex, endIndex).finally(onCancelAddingQuote))
                                }
                                onUpdateQuote={
                                    onUpdateQuote &&
                                    ((quoteId: number, startIndex: number, endIndex: number) =>
                                        onUpdateQuote(quoteId, startIndex, endIndex).finally(() => stopEditingQuote(quoteId)))
                                }
                                onDeleteQuote={onDeleteQuote}
                                onQuoteClick={onQuoteClick}
                                selectedQuoteId={selectedQuoteId}
                                scrollToSelectedQuote
                                disabled={readonly}
                            />
                        ) : (
                            <InterviewItemQuotesGroupedByInterview
                                ideaId={ideaId}
                                quotes={quotesByInsight ? quotesByInsight[insight.id] ?? [] : undefined}
                                onSelectedQuote={onQuoteClick && ((_, quoteId) => onQuoteClick(quoteId))}
                                selectedQuoteId={selectedQuoteId}
                            />
                        )}
                    </StackLayout>
                </Fragment>
            ))}
        </StackLayout>
    );
}
