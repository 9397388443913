import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PickersList } from '../../components/common/pickersList';
import { likelihoodDropDownValues, hypothesisTypeLabelMap, hypothesisValidators, useHypothesisParams } from '../../components/hypotheses/common';
import { HypothesisEditMode, HypothesisEditor } from '../../components/hypotheses/hypothesisEditor';
import { HypothesisResearchScale } from '../../components/hypotheses/hypothesisResearchScale';
import { ValidatedInput, ValidatedInputHorizontalLayout } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1, H3, P } from '../../components/ui/typography';
import { useSingleClickButton } from '../../hooks/commonHooks';
import {
    CustomerProblemAlternativeSolutionHypothesis,
    CustomerProblemAlternativeSolutionSatisfactionHypothesis,
    CustomerProblemPainLevelHypothesis,
    Hypothesis,
    HypothesisLikelihood,
    HypothesisType,
    hypothesesService
} from '../../services/hypothesesService';
import { ReducedResearch, researchService } from '../../services/researchService';

export function EditHypothesisPage() {
    const { ideaId, hypothesisGroup, hypothesisId } = useHypothesisParams();

    const [hypothesis, setHypothesis] = useState<Hypothesis>();
    const navigate = useNavigate();

    useEffect(() => {
        hypothesesService.getHypothesis(ideaId, hypothesisGroup, hypothesisId).then(setHypothesis);
    }, [hypothesisGroup, hypothesisId, ideaId]);

    const [updateHypothesisDisabled, updateHypothesisCallbackCreator] = useSingleClickButton<
        Parameters<typeof onUpdateHypothesisSubmit>,
        ReturnType<typeof onUpdateHypothesisSubmit>
    >();

    async function onUpdateHypothesisSubmit(data: Record<string, any>) {
        if (!hypothesis) return;

        const relatedResearch: ReducedResearch[] | undefined = data.research;
        const hasRelatedResearch = relatedResearch && relatedResearch.length > 0;
        const likelihood: HypothesisLikelihood | null | undefined = hasRelatedResearch ? data.likelihood?.value ?? null : undefined;
        const researchIds = hasRelatedResearch ? relatedResearch.map(r => r.id) : [];

        switch (hypothesis.type) {
            case HypothesisType.PainLevel:
                const updatedPainLevelHypothesis: CustomerProblemPainLevelHypothesis = data.hypothesis;
                await hypothesesService.partiallyUpdateCustomerProblemPainLevelHypothesis(ideaId, hypothesis.id, {
                    likelihood: likelihood,
                    painLevel: hasRelatedResearch ? undefined : updatedPainLevelHypothesis.painLevel,
                    percentage: hasRelatedResearch ? undefined : updatedPainLevelHypothesis.percentage,
                    researchIds
                });
                break;
            case HypothesisType.AlternativeSolutionUsage:
                const updatedAlternativeSolutionHypothesis: CustomerProblemAlternativeSolutionHypothesis = data.hypothesis;
                await hypothesesService.partiallyUpdateCustomerProblemAlternativeSolutionHypothesis(ideaId, hypothesis.id, {
                    likelihood: likelihood ?? null,
                    percentage: hasRelatedResearch ? undefined : updatedAlternativeSolutionHypothesis.percentage,
                    researchIds
                });
                break;
            case HypothesisType.AlternativeSolutionSatisfaction:
                const updatedAlternativeSolutionSatisfactionHypothesis: CustomerProblemAlternativeSolutionSatisfactionHypothesis = data.hypothesis;
                await hypothesesService.partiallyUpdateCustomerProblemAlternativeSolutionSatisfactionHypothesis(ideaId, hypothesis.id, {
                    likelihood: likelihood ?? null,
                    percentage: hasRelatedResearch ? undefined : updatedAlternativeSolutionSatisfactionHypothesis.percentage,
                    satisfactionLevel: hasRelatedResearch ? undefined : updatedAlternativeSolutionSatisfactionHypothesis.satisfactionLevel,
                    researchIds
                });
                break;
        }

        navigate('./../view');
    }

    if (!hypothesis) return <LoadingIndicator size="big" className="!k-pos-absolute k-centered" />;

    return (
        <>
            <H1 className="heading-row">Edit hypothesis</H1>
            <Form
                initialValues={{
                    likelihood: hypothesis.likelihood ? likelihoodDropDownValues.find(l => l.value === hypothesis.likelihood) : undefined,
                    hypothesis: hypothesis,
                    research: hypothesis.research
                }}
                ignoreModified={true}
                onSubmit={updateHypothesisCallbackCreator(onUpdateHypothesisSubmit)}
                render={formRenderProps => {
                    const currentResearch: ReducedResearch[] | undefined = formRenderProps.valueGetter('research');
                    const hasResearch = currentResearch && currentResearch.length;
                    const editHypothesisDefinition = !hasResearch;

                    return (
                        <FormElement>
                            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
                                <div className="k-flex-1">
                                    <div className="k-mb-10">
                                        <H3 className="!k-mb-4">Hypothesis builder</H3>
                                        <Field
                                            name="hypothesis"
                                            component={ValidatedInput}
                                            inputType={HypothesisEditor}
                                            editMode={editHypothesisDefinition ? HypothesisEditMode.EditUserDefined : HypothesisEditMode.NoEdit}
                                            bordered={true}
                                            validate={editHypothesisDefinition}
                                            validator={editHypothesisDefinition ? hypothesisValidators[hypothesis.type] : undefined}
                                            hint={editHypothesisDefinition ? undefined : 'The hypothesis cannot be edited because there is related research.'}
                                        />
                                    </div>
                                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                                        <H3>Details</H3>
                                        <ValidatedInputHorizontalLayout
                                            label={<Label className="k-text-disabled">Hypothesis type</Label>}
                                            input={
                                                <DropDownList
                                                    disabled={true}
                                                    value={<span className="k-d-inline-block">{hypothesisTypeLabelMap[hypothesis.type]}</span>}
                                                />
                                            }
                                        />

                                        <Field
                                            name="research"
                                            component={ValidatedInput}
                                            label="Related research"
                                            inputType={ResearchPickersList}
                                            layout={ValidatedInputHorizontalLayout}
                                            ideaId={ideaId}
                                            customerSegmentId={hypothesis.customerSegmentId}
                                        />

                                        <Field
                                            name="likelihood"
                                            component={ValidatedInput}
                                            label="Likelihood"
                                            inputType={DropDownList}
                                            layout={ValidatedInputHorizontalLayout}
                                            data={likelihoodDropDownValues}
                                            textField="text"
                                            dataItemKey="value"
                                            disabled={!hasResearch}
                                            defaultItem={{
                                                text: hasResearch ? 'Select likelihood' : 'Relate research first...',
                                                value: null
                                            }}
                                            hint="Select an objective aggregate of the likelihoods of all research, as outlined in the Success Scale."
                                        />
                                    </StackLayout>
                                </div>
                                <StackLayout
                                    orientation="vertical"
                                    align={{ horizontal: 'stretch', vertical: 'top' }}
                                    className="-w2 k-gap-2 k-icp-panel-base k-rounded-lg !k-p-4"
                                >
                                    <H3>Success scale</H3>
                                    <P>Use this scale to estimate how likely your idea is to succeed in the market.</P>
                                    <HypothesisResearchScale
                                        hypothesisId={hypothesisId}
                                        hypothesisGroup={hypothesisGroup}
                                        ideaId={ideaId}
                                        researchIdsInContext={(formRenderProps.valueGetter('research') as ReducedResearch[] | undefined)?.map(r => r.id)}
                                        className="k-mt-4"
                                    />
                                </StackLayout>
                            </StackLayout>

                            <StackLayout className="k-gap-4 k-mt-14" align={{ horizontal: 'center' }}>
                                <Button type="submit" themeColor="primary" size="large" disabled={!formRenderProps.allowSubmit || updateHypothesisDisabled}>
                                    Save changes
                                </Button>
                                <Button type="reset" size="large" onClick={() => navigate('./../view')}>
                                    Discard
                                </Button>
                            </StackLayout>
                        </FormElement>
                    );
                }}
            />
        </>
    );
}
function ResearchPickersList({
    ideaId,
    customerSegmentId,
    value,
    onChange,
    disabled,
    valid
}: {
    ideaId: string;
    customerSegmentId: number;
    value?: ReducedResearch[];
    onChange?: (e: { value: ReducedResearch[] | undefined }) => void;
    disabled?: boolean;
    valid?: boolean;
}) {
    return (
        <PickersList
            dataItemKey="id"
            textField="title"
            fetchItems={filter =>
                researchService
                    .getProblemValidationResearchItems(ideaId, undefined, filter ? undefined : 5, filter || undefined, customerSegmentId)
                    .then(researchList => researchList.research)
            }
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder="Select research"
            valid={valid}
            emptyListText="No research found."
        />
    );
}
