import { ComboBox, ComboBoxFilterChangeEvent, ComboBoxProps } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useRef, useState } from 'react';
import { Country, countriesService } from '../../services/countriesService';
import LoadingIndicator from '../ui/loadingIndicator';
import { Skeleton } from '@progress/kendo-react-indicators';

export function CountryAndCityView({ countryCode, city }: { countryCode?: string | null; city?: string | null }) {
    const [country, setCountry] = useState<Country>();

    useEffect(() => {
        if (!countryCode || country?.code === countryCode) return;

        countriesService.findCountryByCode(countryCode).then(setCountry);
    }, [country?.code, countryCode]);

    if (countryCode && city)
        return (
            <>
                {country ? <>{country.name}, {city}</> : <Skeleton style={{width: 200}} />}
            </>
        );

    if (countryCode) return country ? <>{country.name}</> : <Skeleton style={{width: 200}}/>;

    return city ? <>{city}</> : null;
}

export function useCountries() {
    const [countriesList, setCountriesList] = useState<Country[] | undefined>();
    useEffect(() => {
        countriesService.getAllCountries().then(setCountriesList);
    }, []);

    return countriesList;
}

export function useCountryFor(objectWithCountryCode?: { countryCode?: string | null }): [Country | undefined | null, Country[] | undefined] {
    const countriesList = useCountries();
    const country = useRef<Country | undefined | null>();

    if (typeof country.current === 'undefined' && countriesList && objectWithCountryCode)
        country.current = objectWithCountryCode.countryCode ? countriesList.find(c => c.code === objectWithCountryCode.countryCode) ?? null : null;

    return [country.current, countriesList];
}

export function CountriesComboBox(props: ComboBoxProps) {
    const [filteredCountries, setFilteredCountries] = useState<Country[] | undefined>();

    function onFilterChange(event: ComboBoxFilterChangeEvent) {
        if (!event.filter.value || !props.data) {
            setFilteredCountries(undefined);
            return;
        }

        const countries: Country[] = props.data;
        setFilteredCountries(countries.filter(c => c.name.toLowerCase().includes(event.filter.value.toLowerCase())));
    }

    const isLoading = !props.data;
    return (
        <ComboBox
            {...props}
            data={filteredCountries ?? props.data}
            textField="name"
            dataItemKey="code"
            loading={isLoading}
            filterable={true}
            suggest={true}
            onFilterChange={onFilterChange}
            listNoDataRender={e => React.cloneElement(e, undefined, <div>{isLoading ? <LoadingIndicator size="small" /> : 'NO COUNTRIES FOUND.'}</div>)}
        />
    );
}
