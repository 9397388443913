import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequireCanvasBoxItemsInZone } from '../../components/canvas/canvasItemsZone';
import { useCountries } from '../../components/common/countries';
import { PersonEditor, defaultDropDownItem, useAdvancedPersonValidation } from '../../components/contacts/edit';
import { H1 } from '../../components/ui/typography';

import { useStickyFooter } from '../../hooks/commonHooks';
import { BoxType } from '../../services/canvasService';
import { ContactTag, PersonEditData, contactsService } from '../../services/contactsService';

export function CreatePersonPage() {
    const { ideaId } = useParams();
    const countriesList = useCountries();
    const customerSegments = useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);
    const navigate = useNavigate();
    const advancedPersonValidation = useAdvancedPersonValidation();

    const footerRef = useStickyFooter();

    return (
        <>
            <H1 className="!k-mb-6 !k-px-6">Add contact</H1>
            <Form
                onSubmit={async (data: Record<string, any>) => {
                    if (!(await advancedPersonValidation.validate(data.emailAddress, data.phoneNumber))) return;

                    const createData: PersonEditData = {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        emailAddress: data.emailAddress || null,
                        phoneNumber: data.phoneNumber || null,
                        sex: data.sex === defaultDropDownItem ? undefined : data.sex,
                        birthDate: data.birthDate,
                        maritalStatus: data.maritalStatus === defaultDropDownItem ? undefined : data.maritalStatus,

                        address: data.address || null,
                        city: data.city || null,
                        countryCode: data.country?.code,
                        facebook: data.facebook || null,
                        linkedIn: data.linkedIn || null,
                        twitter: data.twitter || null,
                        picture: data.picture,

                        referredByContactId: data.referredByContact ? data.referredByContact.id : undefined,
                        jobTitle: data.jobTitle || null,
                        companyId: data.company ? data.company.id : undefined,

                        workPhoneNumber: data.workPhoneNumber || null,
                        otherAddress: data.otherAddress || null,
                        secondaryEmailAddress: data.secondaryEmailAddress || null,

                        educationLevel: data.educationLevel === defaultDropDownItem ? undefined : data.educationLevel,
                        educationField: data.educationField || null,

                        customerSegmentIds: data.customerSegmentIds,
                        tagIds: (data.tags as ContactTag[] | undefined)?.map(t => t.id)
                    };

                    await contactsService.createPerson(ideaId!, createData);
                    navigate('..');
                }}
                ignoreModified={true}
                render={formRenderProps => (
                    <FormElement noValidate={true}>
                        <PersonEditor
                            ideaId={ideaId!}
                            countriesList={countriesList}
                            customerSegments={customerSegments}
                            reachOutFieldsErrorMessage={advancedPersonValidation.reachOutFieldsErrorMessage}
                            phoneNumberErrorMessage={advancedPersonValidation.phoneNumberErrorMessage}
                            onReachOutFieldChange={advancedPersonValidation.onReachOutFieldChange}
                            className="k-px-6"
                        />
                        <StackLayout
                            ref={r => (footerRef.current = r?.element ?? null)}
                            align={{ horizontal: 'center', vertical: 'top' }}
                            className="k-mt-11 k-gap-4 k-px-6 k-py-3 sticky-footer"
                        >
                            <Button themeColor="primary" type="submit" size="large" disabled={!formRenderProps.allowSubmit}>
                                Save contact
                            </Button>
                            <Button type="reset" size="large" onClick={() => navigate('..')}>
                                Cancel
                            </Button>
                        </StackLayout>
                    </FormElement>
                )}
            />
        </>
    );
}
