import { SubscriptionType } from './authenticationService';
import { WithValue } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpException, HttpServiceBase, RequestMethod } from './httpServiceBase';

export type StripeSetupIntent = { id: string; clientSecret: string };
export type Price = { amount: number; currency: string };
export type PriceDiscount = { percentOff?: number; amountOff?: number; amountOffCurrency?: string; durationInMonths?: number; name: string };
export type PricingData = { monthlyPrice: Price; annualPrice: Price; discount?: PriceDiscount; trialPeriodInDays: number };
export enum LicenseStatus {
    OK = 'OK',
    NeedsSync = 'NeedsSync',
    NeedsSubscription = 'NeedsSubscription',
    NeedsPayment = 'NeedsPayment',
    ContactSupport = 'ContactSupport'
}

export type BillingValidation = { licenseStatus: LicenseStatus };
export enum CustomerType {
    Individual = 'Individual',
    Company = 'Company'
}

export enum SubscriptionStatus {
    Active = 'Active',
    Canceled = 'Canceled',
    Incomplete = 'Incomplete',
    IncompleteExpired = 'IncompleteExpired',
    PastDue = 'PastDue',
    Paused = 'Paused',
    Trialing = 'Trialing',
    Unpaid = 'Unpaid'
}

export type InvoiceDetails = {
    coupon: PriceDiscount | undefined | null;
    amount_due: number;
    currency: string;
};

export type SubscriptionData = {
    status: SubscriptionStatus;
    type: SubscriptionType;
    expires: Date;
    price: Price;
    latestInvoice?: InvoiceDetails;
    upcomingInvoice?: InvoiceDetails;
    discount?: PriceDiscount | null;
} & (
    | {
          cancels: true;
          cancelsAt: Date;
      }
    | {
          cancels: false;
      }
);

export type NewSubscriptionData = {
    subscriptionType: SubscriptionType;
    promotionCode?: string;
    billingInfo?: WithValue<BillingInfo, 'customerType' | 'name' | 'country' | 'city' | 'address'>;
    paymentMethod?: UpdatePaymentMethodData;
};

export type BillingInfo = {
    customerType?: CustomerType | null;
    name?: string | null;
    country?: string | null;
    city?: string | null;
    address?: string | null;
    postalCode?: string | null;
    phoneNumber?: string | null;
    taxIds: TaxId[];
};

export type TaxId = {
    type: TaxIdType;
    value: string;
};

export enum TaxIdType {
    BGUic = 'bg_uic',
    EUVat = 'eu_vat'
}

export type UpdatePaymentMethodData = {
    setupIntentId: string;
};

export type PaymentMethod = {
    cardBrand: string;
    last4digits: string;
    expiresMonth: number;
    expiresYear: number;
};

class StripeBillingService extends HttpServiceBase {
    constructor() {
        super('/api/billing');
    }

    createPaymentSetupIntent() {
        return this.performRequest<StripeSetupIntent>({
            path: '/intents',
            method: RequestMethod.POST
        });
    }

    cancelPaymentSetupIntent(id: string) {
        return this.performRequestWithoutParsingResponse({
            path: `/intents/${id}/cancel`,
            method: RequestMethod.POST
        });
    }

    getBillingPortalUrl(returnUrl: string): Promise<string> {
        return this.performRequest<{ url: string }>({
            path: '/current-user/billing-portal',
            queryParams: {
                returnUrl
            }
        }).then(p => p.url);
    }

    getPricing(): Promise<PricingData> {
        return this.performRequest<PricingData>({
            path: '/pricing'
        });
    }

    validateBilling(): Promise<BillingValidation> {
        return this.performRequest({
            path: '/current-user/validation'
        });
    }

    pullLicenseDataFromStripe(): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: '/current-user/sync',
            method: RequestMethod.POST
        });
    }

    getSubscription(): Promise<SubscriptionData> {
        return this.performRequest<SubscriptionData>({
            path: '/current-user/subscriptions'
        }).then(sub => {
            dateTimeService.ensureDateField(sub, 'expires');
            if (sub.cancels) dateTimeService.ensureDateField(sub, 'cancelsAt');
            return sub;
        });
    }

    startSubscription(data: NewSubscriptionData): Promise<SubscriptionData> {
        return this.performRequest<SubscriptionData>({
            path: '/current-user/subscriptions',
            method: RequestMethod.POST,
            body: data
        });
    }

    getDiscount(promoCode: string): Promise<PriceDiscount> {
        return this.performRequest<PriceDiscount>({
            path: `/discounts/${promoCode}`
        });
    }

    getCurrentBillingInfo(): Promise<BillingInfo> {
        return this.performRequest({
            path: '/current-user/details'
        });
    }

    updateBillingInfo(billingInfo: BillingInfo): Promise<BillingInfo> {
        return this.performRequest({
            path: '/current-user/details',
            method: RequestMethod.PUT,
            body: billingInfo
        });
    }

    getCurrentDefaultPaymentMethod(): Promise<PaymentMethod | null> {
        return this.performRequest<PaymentMethod>({
            path: '/current-user/payment-method'
        }).catch(error => {
            if (error instanceof HttpException && error.status === 404) return null;

            return Promise.reject(error);
        });
    }

    updatePaymentMethod(setupIntentId: string): Promise<PaymentMethod> {
        return this.performRequest<PaymentMethod>({
            path: '/current-user/payment-method',
            method: RequestMethod.PUT,
            body: { setupIntentId }
        });
    }

    isDateExpired(expiresMonth: number, expiresYear: number) {
        const currentDate = new Date();
        const currentMonth = currentDate.getUTCMonth() + 1;
        const currentYear = currentDate.getUTCFullYear();
        return expiresYear < currentYear || (expiresYear === currentYear && expiresMonth < currentMonth);
    }
}

export const stripeBillingService = new StripeBillingService();
