import { realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { store } from '../store';
import { clearIdea, reloadCurrentIdea } from './ideaSlice';

realTimeUpdatesEventHub.addEventListener('membership', 'change', e => {
    const currentState = store.getState();
    if (!currentState.idea.current || !currentState.user || currentState.idea.current.uniqueId !== e.ideaId || currentState.user.userId !== e.userId) return;

    store.dispatch(reloadCurrentIdea());
});

realTimeUpdatesEventHub.addEventListener('membership', 'revoke', e => {
    const currentState = store.getState();
    if (!currentState.idea.current || !currentState.user || currentState.idea.current.uniqueId !== e.ideaId || currentState.user.userId !== e.userId) return;

    store.dispatch(clearIdea());
});

realTimeUpdatesEventHub.addEventListener('idea', 'updated', e => {
    const currentState = store.getState();
    if (!currentState.idea.current || currentState.idea.current.uniqueId !== e.ideaId) return;

    store.dispatch(reloadCurrentIdea());
});

realTimeUpdatesEventHub.addEventListener('idea', 'deleted', e => {
    const currentState = store.getState();
    if (!currentState.idea.current || currentState.idea.current.uniqueId !== e.ideaId) return;

    store.dispatch(clearIdea());
});
