export function getOrdinalSuffix(ordinal: number) {
    const lastDigit = Math.abs(ordinal % 10);
    const lastTwoDigits = Math.abs(ordinal % 100);

    if (lastDigit === 1 && lastTwoDigits !== 11) {
        return 'st';
    }

    if (lastDigit === 2 && lastTwoDigits !== 12) {
        return 'nd';
    }

    if (lastDigit === 3 && lastTwoDigits !== 13) {
        return 'rd';
    }

    return 'th';
}

export function capitalizeFirstLetter(string?: string | null) {
    if (!string) return string;

    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function separateJoinedWords(wordsString?: string | null) {
    if (!wordsString) return wordsString;

    let result = '';
    for (let letterIndex = 0; letterIndex < wordsString.length; letterIndex++) {
        const letter = wordsString[letterIndex];
        if (letter.toUpperCase() === letter && result.length) result += ' ';

        result += letter;
    }

    return result;
}

export function getFirstWord(message: string) {
    const endOfFirstWord = message.indexOf(' ');
    if (endOfFirstWord === -1) return undefined;

    return message.substring(0, endOfFirstWord);
}
