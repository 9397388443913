import { StackLayout } from '@progress/kendo-react-layout';
import { Fragment } from 'react';
import { combineClassNames } from '../../services/common';

export type RangeViewStop = {
    percentage: number;
    label: string;
    labelSuffix?: string;
};
const stopLength = 7; // 5px circle + 2 * 1px gap
export function RangeView({
    stops,
    activeRangesIndices,
    className,
    hideStops
}: {
    stops: RangeViewStop[];
    activeRangesIndices?: number[];
    className?: string;
    hideStops?: boolean;
}) {
    const sortedStops = [...stops].sort((s1, s2) => s1.percentage - s2.percentage);

    let previousStopPercentage = 0;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className={combineClassNames('k-gap-hair', className)}>
            {sortedStops.map((stop, stopIndex) => {
                const rangeSize = stop.percentage - previousStopPercentage;
                previousStopPercentage = stop.percentage;
                return (
                    <Fragment key={stopIndex}>
                        <RangeViewRangeBarView
                            percentage={rangeSize}
                            isActive={activeRangesIndices?.includes(stopIndex)}
                            lengthAdjustment={hideStops ? undefined : stopIndex ? stopLength : stopLength / 2}
                        />
                        {!hideStops && <RangeViewStopView label={stop.label} suffix={stop.labelSuffix} />}
                    </Fragment>
                );
            })}
            <RangeViewRangeBarView
                percentage={100 - previousStopPercentage}
                isActive={activeRangesIndices?.includes(sortedStops.length)}
                lengthAdjustment={sortedStops.length && !hideStops ? stopLength / 2 : undefined}
            />
        </StackLayout>
    );
}

function RangeViewRangeBarView({ percentage, isActive, lengthAdjustment }: { percentage: number; isActive?: boolean; lengthAdjustment?: number }) {
    return (
        <div
            style={{ flexBasis: lengthAdjustment ? `calc(${percentage}% - ${lengthAdjustment}px)` : `calc(${percentage}%` }}
            className={combineClassNames('k-flex-auto  k-h-1px', isActive ? 'k-bg-secondary' : 'k-icp-bg-secondary-24')}
        />
    );
}

function RangeViewStopView({ label, suffix }: { label: string; suffix?: string }) {
    return (
        <div className="k-pos-relative k-bg-secondary k-rounded-full k-shrink-0" style={{ width: 5, height: 5 }}>
            <div className="k-pos-absolute k-pos-top-center k-fs-xs" style={{ transform: 'translateX(-50%) translateY(-100%)' }}>
                {label}
                {suffix && (
                    <div className="k-pos-absolute k-pos-middle-end" style={{ transform: 'translateY(-50%) translateX(100%)' }}>
                        {suffix}
                    </div>
                )}
            </div>
        </div>
    );
}
