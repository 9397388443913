import { useEffect, useRef } from 'react';
import { useGlobalCanvas } from '../../../hooks/canvasHooks';
import { meetingsService } from '../../../services/activitiesService';
import { BoxItem, BoxType } from '../../../services/canvasService';
import { immutableAddOrUpdate, immutableUpdate } from '../../../services/common';
import { contactsService } from '../../../services/contactsService';
import { InterviewScript, interviewScriptsService } from '../../../services/interviewScriptsService';
import { interviewsService, InterviewType } from '../../../services/interviewsService';
import { PlatformArea } from '../../../services/journeyService';
import {
    RealTimeUpdateInterviewEventData,
    RealTimeUpdateInterviewScriptData,
    RealTimeUpdateMeetingEventData,
    RealTimeUpdatePersonEventData,
    RealTimeUpdatePersonUpdateEventData,
    RealTimeUpdateResearchContactEventData,
    RealTimeUpdateResearchEventData,
    RealTimeUpdateResearchReachOutEventData,
    realTimeUpdatesEventHub
} from '../../../services/realTimeUpdatesService';
import { ProblemValidationResearch, ResearchContact, researchService, ResearchType } from '../../../services/researchService';
import { TaskData, TaskRenderExtension, taskRenderExtensions } from './common';

declare module './common' {
    interface TaskData {
        problemValidationResearch?: Partial<Record<number, ProblemValidationResearch>>;
        researchInterviewScript?: Partial<Record<number, InterviewScript | null>>;
        researchContacts?: Partial<Record<number, ResearchContact[]>>;
    }
}

export function useResearchJobToBeDone(
    ideaId: string,
    researchId: number,
    taskData: TaskData,
    setTaskData: React.Dispatch<React.SetStateAction<TaskData>>
): [boolean, BoxItem | undefined, (jobToBeDoneId?: number) => Promise<void>] {
    const jobToBeDoneId = taskData.problemValidationResearch?.[researchId]?.jobToBeDoneId;
    const { canvas } = useGlobalCanvas(undefined, true);
    const currentJobToBeDone =
        typeof jobToBeDoneId !== 'number' ? undefined : canvas.boxes?.find(b => b.type === BoxType.JobsToBeDone)?.items.find(i => i.id === jobToBeDoneId);

    async function updateResearchJobToBeDoneId(jobToBeDoneId?: number) {
        const updatedResearch = await researchService.partiallyUpdateProblemValidationResearch(ideaId, researchId, {
            jobToBeDoneId: jobToBeDoneId ?? null
        });

        setTaskData(taskData => ({ ...taskData, problemValidationResearch: { ...taskData.problemValidationResearch, [researchId]: updatedResearch } }));
    }

    return [!!canvas.loadingIdeaId, currentJobToBeDone, updateResearchJobToBeDoneId];
}

const useProblemValidationResearchTask: TaskRenderExtension = (ideaId, task, taskBody, taskData, setTaskData) => {
    const isProblemValidationResearchTask =
        task &&
        task.platformArea === PlatformArea.Research &&
        task.areaDetail.type === PlatformArea.Research &&
        task.areaDetail.researchType === ResearchType.ProblemValidation;
    let researchIdsDependencies: number[] | undefined;
    let researchIdsDependenciesKey: string | undefined;
    if (isProblemValidationResearchTask && taskBody) {
        for (const taskPage of taskBody.pages) {
            const editor = taskPage.editor;
            if (!editor || !('researchId' in editor.params)) continue;

            const researchId = editor.params.researchId;
            if (!researchIdsDependencies) {
                researchIdsDependencies = [researchId];
                researchIdsDependenciesKey = researchId.toString();
            } else if (!researchIdsDependencies.includes(researchId)) {
                researchIdsDependencies.push(researchId);
                researchIdsDependenciesKey += `_${researchId}`;
            }
        }
    }

    const researchIdsDependenciesRef = useRef(researchIdsDependencies);
    researchIdsDependenciesRef.current = researchIdsDependencies;
    useEffect(() => {
        const researchIdsDependencies = researchIdsDependenciesRef.current;
        if (!researchIdsDependencies) return;

        (async () => {
            const loadedResearch = await Promise.all(
                researchIdsDependencies.map(researchId => researchService.getProblemValidationResearch(ideaId, researchId))
            );

            const researchMap = loadedResearch.reduce<Record<number, ProblemValidationResearch>>((researchMap, research) => {
                researchMap[research.id] = research;

                return researchMap;
            }, {});
            setTaskData(taskData => ({ ...taskData, problemValidationResearch: researchMap }));
        })();

        async function onResearchUpdated(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId || !researchIdsDependencies?.includes(e.researchId)) return;
            const updatedResearch = await researchService.getProblemValidationResearch(ideaId, e.researchId);
            setTaskData(taskData => ({ ...taskData, problemValidationResearch: { ...taskData.problemValidationResearch, [e.researchId]: updatedResearch } }));
        }

        realTimeUpdatesEventHub.addEventListener('research', 'update', onResearchUpdated);

        return () => realTimeUpdatesEventHub.removeEventListener('research', 'update', onResearchUpdated);
    }, [ideaId, researchIdsDependenciesKey, setTaskData]);
};

taskRenderExtensions.push(useProblemValidationResearchTask);

const useProblemValidationResearchInterviewScript: TaskRenderExtension = (ideaId, task, taskBody, taskData, setTaskData) => {
    let researchIdToInterviewScriptIdMappings: { researchId: number; interviewScriptId?: number }[] | undefined;
    let interviewScriptIdsKey: string | undefined;
    if (taskData.problemValidationResearch && taskBody) {
        for (const taskPage of taskBody.pages) {
            const editor = taskPage.editor;
            if (!editor || editor.type !== 'interview-script') continue;

            const researchId = editor.params.researchId;
            const interviewScriptId = taskData.problemValidationResearch[researchId]?.interviewScriptId;
            if (!researchIdToInterviewScriptIdMappings) {
                researchIdToInterviewScriptIdMappings = [{ researchId, interviewScriptId }];
                interviewScriptIdsKey = String(interviewScriptId);
            } else if (!researchIdToInterviewScriptIdMappings.some(m => m.researchId === researchId)) {
                researchIdToInterviewScriptIdMappings.push({ researchId, interviewScriptId });
                interviewScriptIdsKey += `_${interviewScriptId}`;
            }
        }
    }

    const researchIdToInterviewScriptIdMappingsRef = useRef(researchIdToInterviewScriptIdMappings);
    researchIdToInterviewScriptIdMappingsRef.current = researchIdToInterviewScriptIdMappings;

    useEffect(() => {
        const researchIdToInterviewScriptIdMappings = researchIdToInterviewScriptIdMappingsRef.current;
        if (!researchIdToInterviewScriptIdMappings) return;

        (async () => {
            const researchIdToInterviewScriptMappings = await Promise.all(
                researchIdToInterviewScriptIdMappings.map(mapping => {
                    if (!mapping.interviewScriptId) {
                        return Promise.resolve({ researchId: mapping.researchId, interviewScript: null });
                    }
                    return interviewScriptsService.getInterviewScript(ideaId, mapping.interviewScriptId).then(interviewScript => {
                        return { researchId: mapping.researchId, interviewScript: interviewScript };
                    });
                })
            );

            const researchIdToInterviewScriptMap = researchIdToInterviewScriptMappings.reduce<Record<number, InterviewScript | null>>(
                (researchIdToInterviewScriptMap, researchIdToInterviewScriptMapping) => {
                    researchIdToInterviewScriptMap[researchIdToInterviewScriptMapping.researchId] = researchIdToInterviewScriptMapping.interviewScript;

                    return researchIdToInterviewScriptMap;
                },
                {}
            );

            setTaskData(taskData => ({ ...taskData, researchInterviewScript: researchIdToInterviewScriptMap }));
        })();

        async function onInterviewScriptUpdated(e: RealTimeUpdateInterviewScriptData) {
            if (
                e.ideaId !== ideaId ||
                e.interviewType !== InterviewType.ProblemDiscoveryInterview ||
                !researchIdToInterviewScriptIdMappings?.some(m => m.interviewScriptId === e.interviewScriptId)
            )
                return;

            const updatedInterviewScript = await interviewScriptsService.getInterviewScript(ideaId, e.interviewScriptId);
            setTaskData(taskData => {
                if (!taskData.researchInterviewScript) return taskData;

                let updatedTaskData: TaskData | undefined;
                for (const researchIdProp in taskData.researchInterviewScript) {
                    if (!Object.prototype.hasOwnProperty.call(taskData.researchInterviewScript, researchIdProp)) continue;
                    const interviewScript = taskData.researchInterviewScript[researchIdProp];
                    if (interviewScript?.id !== updatedInterviewScript.id) continue;
                    const researchId = parseInt(researchIdProp);
                    updatedTaskData = updatedTaskData ?? { ...taskData };
                    updatedTaskData.researchInterviewScript = {
                        ...updatedTaskData.researchInterviewScript,
                        [researchId]: updatedInterviewScript
                    };
                }

                return updatedTaskData ?? taskData;
            });
        }

        realTimeUpdatesEventHub.addEventListener('interview', 'scriptUpdate', onInterviewScriptUpdated);

        return () => realTimeUpdatesEventHub.removeEventListener('interview', 'scriptUpdate', onInterviewScriptUpdated);
    }, [ideaId, interviewScriptIdsKey, setTaskData]);
};

taskRenderExtensions.push(useProblemValidationResearchInterviewScript);

const useProblemValidationResearchContacts: TaskRenderExtension = (ideaId, task, taskBody, taskData, setTaskData) => {
    let researchIds: Set<number> | undefined;
    let researchIdsKey: string | undefined;
    if (taskBody) {
        for (const taskPage of taskBody.pages) {
            const editor = taskPage.editor;
            if (!editor || (editor.type !== 'research-contacts' && editor.type !== 'research-meeting-invites' && editor.type !== 'research-interviews'))
                continue;

            if (!researchIds) researchIds = new Set();
            researchIds.add(editor.params.researchId);
        }

        if (researchIds && researchIds.size) researchIdsKey = Array.from(researchIds.keys()).join('_');
    }

    const researchIdsRef = useRef(researchIds);
    researchIdsRef.current = researchIds;

    const researchContactsRef = useRef(taskData.researchContacts);
    researchContactsRef.current = taskData.researchContacts;
    useEffect(() => {
        const researchIds = researchIdsRef.current;
        if (!researchIds) return;

        async function loadResearchContacts() {
            const loadedResearchContacts = await Promise.all(
                Array.from(researchIds!.values()).map(researchId =>
                    researchService.getProblemValidationResearchContacts(ideaId, researchId).then(contacts => ({ researchId, contacts }))
                )
            );

            const researchIdToContactsMap = loadedResearchContacts.reduce<Record<number, ResearchContact[]>>(
                (researchIdToContactsMap, researchIdToContactsMapping) => {
                    researchIdToContactsMap[researchIdToContactsMapping.researchId] = researchIdToContactsMapping.contacts;

                    return researchIdToContactsMap;
                },
                {}
            );
            setTaskData(taskData => ({ ...taskData, researchContacts: researchIdToContactsMap }));
        }
        loadResearchContacts();

        async function onResearchContactsUpdated(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId || e.researchType !== ResearchType.ProblemValidation) return;
            const researchIds = researchIdsRef.current;
            if (!researchIds || !researchIds.has(e.researchId)) return;

            const updatedResearchContacts = await researchService.getProblemValidationResearchContacts(e.ideaId, e.researchId);
            setTaskData(taskData => ({
                ...taskData,
                researchContacts: {
                    ...taskData.researchContacts,
                    [e.researchId]: updatedResearchContacts
                }
            }));
        }

        async function updateResearchContact(researchId: number, contactId: number) {
            const updatedResearchContact = await researchService.getProblemValidationResearchContact(ideaId, researchId, contactId);
            setTaskData(taskData => {
                const researchContacts = taskData.researchContacts ? { ...taskData.researchContacts } : {};
                researchContacts[researchId] = immutableAddOrUpdate(
                    researchContacts[researchId],
                    updatedResearchContact,
                    researchContact => researchContact.contact.id === contactId
                );

                return {
                    ...taskData,
                    researchContacts: researchContacts
                };
            });
        }

        function onResearchContactUpdated(e: RealTimeUpdateResearchContactEventData) {
            if (e.ideaId !== ideaId || e.researchType !== ResearchType.ProblemValidation) return;
            const researchIds = researchIdsRef.current;
            if (!researchIds || !researchIds.has(e.researchId)) return;

            updateResearchContact(e.researchId, e.contactId);
        }

        function updateResearchContacts(
            condition: (researchContact: ResearchContact) => boolean,
            update: (researchContact: ResearchContact) => ResearchContact
        ) {
            setTaskData(taskData => {
                if (!taskData.researchContacts) return taskData;
                let updatedResearchContacts: typeof taskData.researchContacts | undefined;
                for (const researchIdProp in taskData.researchContacts) {
                    if (!Object.prototype.hasOwnProperty.call(taskData.researchContacts, researchIdProp)) continue;

                    const currentResearchContacts = taskData.researchContacts[researchIdProp];
                    if (!currentResearchContacts) continue;
                    const updatedCurrentResearchContacts = immutableUpdate(currentResearchContacts, update, condition);
                    if (updatedCurrentResearchContacts === currentResearchContacts) continue;

                    if (!updatedResearchContacts) updatedResearchContacts = { ...taskData.researchContacts };
                    updatedResearchContacts[researchIdProp] = updatedCurrentResearchContacts;
                }

                if (updatedResearchContacts)
                    return {
                        ...taskData,
                        researchContacts: updatedResearchContacts
                    };

                return taskData;
            });
        }

        function onResearchReachOutChanged(e: RealTimeUpdateResearchReachOutEventData) {
            if (e.ideaId !== ideaId || e.researchType !== ResearchType.ProblemValidation) return;
            const researchIds = researchIdsRef.current;
            if (!researchIds || !researchIds.has(e.researchId)) return;

            updateResearchContact(e.researchId, e.contactId);
        }

        async function onMeetingUpserted(e: RealTimeUpdateMeetingEventData) {
            if (e.ideaId !== ideaId) return;
            const upsertedMeeting = await meetingsService.getMeeting(e.ideaId, e.meetingId);
            if (!upsertedMeeting.research) return;
            const researchIds = researchIdsRef.current;
            if (!researchIds || !researchIds.has(upsertedMeeting.research.id)) return;

            updateResearchContact(upsertedMeeting.research.id, upsertedMeeting.contact.id);
        }

        function onMeetingDeleted(e: RealTimeUpdateMeetingEventData) {
            if (e.ideaId !== ideaId) return;

            const researchIdsWithMeeting = findResearchWithResearchContact(rc => !!rc.meetingActivity && rc.meetingActivity.meeting.id === e.meetingId);
            if (!researchIdsWithMeeting || !researchIdsWithMeeting.length) return;

            if (researchIdsWithMeeting.length === 1) updateResearchContact(researchIdsWithMeeting[0], e.contactId);
            else loadResearchContacts();
        }

        function findResearchWithResearchContact(condition: (researchContact: ResearchContact) => boolean): number[] | undefined {
            const researchContacts = researchContactsRef.current;
            if (!researchContacts) return undefined;

            let researchIds: number[] | undefined;
            for (const researchIdProp in researchContacts) {
                if (!Object.prototype.hasOwnProperty.call(researchContacts, researchIdProp)) continue;
                const currentResearchContacts = researchContacts[researchIdProp];
                if (!currentResearchContacts) continue;
                if (currentResearchContacts.some(condition)) {
                    if (!researchIds) researchIds = [];
                    researchIds.push(parseInt(researchIdProp));
                }
            }

            return researchIds;
        }

        function isContactInResearch(contactId: number): boolean {
            const researchIdsWithContact = findResearchWithResearchContact(rc => rc.contact.id === contactId);

            return researchIdsWithContact !== undefined && researchIdsWithContact.length > 0;
        }

        async function onPersonUpdated(e: RealTimeUpdatePersonUpdateEventData) {
            if (e.ideaId !== ideaId || !isContactInResearch(e.personId)) return;

            const updatedContact = await contactsService.getPersonById(ideaId, e.personId);
            updateResearchContacts(
                rc => rc.contact.id === e.personId,
                rc => ({ ...rc, contact: updatedContact })
            );
        }

        function onPersonDeleted(e: RealTimeUpdatePersonEventData) {
            if (e.ideaId !== ideaId || !isContactInResearch(e.personId)) return;

            loadResearchContacts();
        }

        function onPersonRestored(e: RealTimeUpdatePersonEventData) {
            if (e.ideaId !== ideaId) return;

            loadResearchContacts();
        }

        async function onInterviewUpserted(e: RealTimeUpdateInterviewEventData) {
            if (e.ideaId !== ideaId) return;
            const upsertedInterview = await interviewsService.getInterview(e.ideaId, e.interviewId);
            const researchIds = researchIdsRef.current;
            if (!researchIds || !researchIds.has(upsertedInterview.researchId)) return;

            updateResearchContact(upsertedInterview.researchId, upsertedInterview.contact.id);
        }

        function onInterviewDeleted(e: RealTimeUpdateInterviewEventData) {
            if (e.ideaId !== ideaId) return;

            const researchIdsWithInterview = findResearchWithResearchContact(rc => !!rc.interview && rc.interview.id === e.interviewId);
            if (!researchIdsWithInterview || !researchIdsWithInterview.length) return;

            loadResearchContacts();
        }

        realTimeUpdatesEventHub.addEventListener('research', 'contactsUpdated', onResearchContactsUpdated);
        realTimeUpdatesEventHub.addEventListener('research', 'contactUpdated', onResearchContactUpdated);

        realTimeUpdatesEventHub.addEventListener('research', 'reachOutAdd', onResearchReachOutChanged);
        realTimeUpdatesEventHub.addEventListener('research', 'reachOutUpdate', onResearchReachOutChanged);
        realTimeUpdatesEventHub.addEventListener('research', 'reachOutDelete', onResearchReachOutChanged);
        realTimeUpdatesEventHub.addEventListener('research', 'reachOutRestore', onResearchReachOutChanged);

        realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingAdd', onMeetingUpserted);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingUpdate', onMeetingUpserted);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingRestore', onMeetingUpserted);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingDelete', onMeetingDeleted);

        realTimeUpdatesEventHub.addEventListener('contact', 'personUpdate', onPersonUpdated);
        realTimeUpdatesEventHub.addEventListener('contact', 'personDelete', onPersonDeleted);
        realTimeUpdatesEventHub.addEventListener('contact', 'personRestore', onPersonRestored);

        realTimeUpdatesEventHub.addEventListener('interview', 'add', onInterviewUpserted);
        realTimeUpdatesEventHub.addEventListener('interview', 'update', onInterviewUpserted);
        realTimeUpdatesEventHub.addEventListener('interview', 'restore', onInterviewUpserted);
        realTimeUpdatesEventHub.addEventListener('interview', 'delete', onInterviewDeleted);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('research', 'contactsUpdated', onResearchContactsUpdated);

            realTimeUpdatesEventHub.removeEventListener('research', 'reachOutAdd', onResearchReachOutChanged);
            realTimeUpdatesEventHub.removeEventListener('research', 'reachOutUpdate', onResearchReachOutChanged);
            realTimeUpdatesEventHub.removeEventListener('research', 'reachOutDelete', onResearchReachOutChanged);
            realTimeUpdatesEventHub.removeEventListener('research', 'reachOutRestore', onResearchReachOutChanged);

            realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingAdd', onMeetingUpserted);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingUpdate', onMeetingUpserted);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingRestore', onMeetingUpserted);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingDelete', onMeetingDeleted);

            realTimeUpdatesEventHub.removeEventListener('contact', 'personUpdate', onPersonUpdated);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personDelete', onPersonDeleted);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personRestore', onPersonRestored);

            realTimeUpdatesEventHub.removeEventListener('interview', 'add', onInterviewUpserted);
            realTimeUpdatesEventHub.removeEventListener('interview', 'update', onInterviewUpserted);
            realTimeUpdatesEventHub.removeEventListener('interview', 'restore', onInterviewUpserted);
            realTimeUpdatesEventHub.removeEventListener('interview', 'delete', onInterviewDeleted);
        };
    }, [ideaId, researchIdsKey, setTaskData]);
};

taskRenderExtensions.push(useProblemValidationResearchContacts);
