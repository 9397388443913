import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, ReactNode } from 'react';
import { researchTypeTextMap } from '.';
import { CanvasItemSimpleView } from '../../components/canvas/canvasItemSimpleView';
import { H3 } from '../../components/ui/typography';
import { findCanvasItem, useGlobalCanvas } from '../../hooks/canvasHooks';
import { ReactComponent as CalendarIcon } from '../../icons/calendar-slot.svg';
import { ReactComponent as ClipboardCheckIcon } from '../../icons/clipboard-check.svg';
import { ReactComponent as InterviewIcon } from '../../icons/interview.svg';
import { ReactComponent as PieChartIcon } from '../../icons/pie-chart.svg';
import interviewIllustrationUrl from '../../images/interview-illustration.svg';
import { BoxType } from '../../services/canvasService';
import { dateTimeService } from '../../services/dateTimeService';
import { ProblemValidationResearch } from '../../services/researchService';
import { useTabbedLayoutData } from '../layouts/tabbedLayout';

export function ResearchSummaryPage() {
    const research = useTabbedLayoutData<ProblemValidationResearch | null | undefined>();
    const { canvas } = useGlobalCanvas(undefined, true);
    const customerSegment = research && canvas.boxes ? findCanvasItem(canvas.boxes, BoxType.CustomerSegments, research.customerSegmentId) ?? null : undefined;
    const jobToBeDone = research && canvas.boxes ? findCanvasItem(canvas.boxes, BoxType.JobsToBeDone, research.jobToBeDoneId) ?? null : undefined;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-8">
            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-flex-1 k-gap-10">
                <ResearchInfoPanel title="Research type & template" icon={InterviewIcon}>
                    <ResearchInfoData label={research ? researchTypeTextMap[research.type] : undefined}>
                        {research ? research.title : undefined}
                    </ResearchInfoData>
                </ResearchInfoPanel>
                {(!research || research.startDate || research.startDate) && (
                    <ResearchInfoPanel title="Research period" icon={CalendarIcon}>
                        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                            {(!research || research.startDate) && (
                                <ResearchInfoData label="Start date">
                                    {research && research.startDate ? dateTimeService.stringifyToDay(research.startDate) : undefined}
                                </ResearchInfoData>
                            )}
                            {(!research || (research.startDate && research.endDate)) && <div>-</div>}
                            {(!research || research.endDate) && (
                                <ResearchInfoData label="End date">
                                    {research && research.endDate ? dateTimeService.stringifyToDay(research.endDate) : undefined}
                                </ResearchInfoData>
                            )}
                        </StackLayout>
                    </ResearchInfoPanel>
                )}
                {customerSegment !== null && (
                    <ResearchInfoPanel title="Customer segment" icon={PieChartIcon}>
                        {customerSegment ? <CanvasItemSimpleView item={customerSegment} /> : <Skeleton shape="text" style={{ width: 200 }} />}
                    </ResearchInfoPanel>
                )}

                {jobToBeDone !== null && (
                    <ResearchInfoPanel title="Job-to-be-Done" icon={ClipboardCheckIcon}>
                        <ResearchInfoData label={null}>{jobToBeDone ? <CanvasItemSimpleView item={jobToBeDone} /> : undefined}</ResearchInfoData>
                    </ResearchInfoPanel>
                )}
            </StackLayout>
            <img src={interviewIllustrationUrl} alt="Interview" width="420" height="300" className="-w2" />
        </StackLayout>
    );
}

function ResearchInfoPanel({ title, icon: Icon, children }: { title: string; icon: ComponentType<React.SVGProps<SVGSVGElement>>; children?: ReactNode }) {
    return (
        <div>
            <H3 className="!k-mb-1">{title}</H3>
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-icp-panel !k-rounded k-py-2 k-pl-4 k-pr-6 k-gap-3">
                <Icon className="k-icp-icon k-icp-icon-size-6 k-m-1 k-shrink-0" />
                <div className="-mt-1.5">{children}</div>
            </StackLayout>
        </div>
    );
}

function ResearchInfoData({ children, label }: { children?: ReactNode; label?: string | null }) {
    return (
        <div>
            <div className="k-mb-thin">{children ?? <Skeleton shape="text" style={{ width: 200 }} />}</div>
            {label !== null && <div className="k-fs-sm k-icp-subtle-text">{label ?? <Skeleton shape="text" style={{ width: 100 }} />}</div>}
        </div>
    );
}
