import { Button } from '@progress/kendo-react-buttons';
import { FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { createContext, ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { appConfig } from '../../config';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { combineClassNames } from '../../services/common';

type AuthLayoutContextValue = { setHeaderContent: (content: ReactNode) => void };
const AuthLayoutContext = createContext<AuthLayoutContextValue>({ setHeaderContent(_) {} });

export function useAuthLayoutContext() {
    return useContext(AuthLayoutContext);
}

export function AuthLayout() {
    const [headerContent, setHeaderContent] = useState<ReactNode>();
    const authLayoutContextValue = useMemo<AuthLayoutContextValue>(() => ({ setHeaderContent }), []);

    function onMouseMove(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const element = e.currentTarget;
        const elementRect = element.getBoundingClientRect();

        const mouseXCenterOffset = e.clientX - (elementRect.left + elementRect.width / 2);
        const mouseYCenterOffset = e.clientY - (elementRect.top + elementRect.height / 2);

        element.style.setProperty('--mouse-x-offset', `${mouseXCenterOffset}px`);
        element.style.setProperty('--mouse-y-offset', `${mouseYCenterOffset}px`);
    }

    return (
        <AuthLayoutContext.Provider value={authLayoutContextValue}>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=1.0, minimum-scale=1.0, maximum-scale=1.0" />
            </Helmet>
            <div className="auth-page" onMouseMove={onMouseMove}>
                <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="auth-page-content k-p-6 k-pt-4 k-gap-12">
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-align-self-stretch">
                        <a href={appConfig.marketingWebsiteUrl} className="top-nav-logo">
                            <Logo className="top-nav-logo-image" />
                        </a>
                        {headerContent}
                    </StackLayout>
                    <Outlet />
                </StackLayout>
            </div>
        </AuthLayoutContext.Provider>
    );
}

export function AuthForm({
    children,
    button,
    secondaryButton,
    footer,
    gap
}: {
    children?: ReactNode;
    button?: ReactElement;
    secondaryButton?: ReactElement;
    footer?: ReactNode;
    gap?: number;
}) {
    return (
        <FormElement noValidate={true}>
            <StackLayout className="k-gap-8" orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
                <StackLayout className={`k-gap-${gap ?? 8}`} orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
                    {children}
                </StackLayout>

                {(typeof button !== 'undefined' || typeof secondaryButton !== 'undefined') && (
                    <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-4">
                        {button}
                        {secondaryButton}
                    </StackLayout>
                )}

                {footer && <AuthFormFooter>{footer}</AuthFormFooter>}
            </StackLayout>
        </FormElement>
    );
}

export function AuthFormRow({ children }: { children?: ReactNode }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            {children}
        </StackLayout>
    );
}

export function AuthFormSubmitButton({
    children,
    disabled,
    onClick,
    className
}: {
    children: string;
    disabled?: boolean;
    onClick?: () => void;
    className?: string;
}) {
    return (
        <Button type="submit" themeColor="primary" size="large" disabled={disabled} onClick={onClick} className={className}>
            {children}
        </Button>
    );
}

export function AuthFormFooter({ children, className }: { children: ReactNode; className?: string }) {
    return <div className={combineClassNames('k-pt-4 k-icp-bordered-top k-icp-component-border k-text-center', className)}>{children}</div>;
}
