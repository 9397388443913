import { createContext, useContext } from 'react';

export type StartupLayoutContextType = {
    pageContainerRef: React.RefObject<HTMLElement>;
    pageContainerClassName: string | undefined;
    setPageContainerClassName: (className: string | undefined) => void;
};

export const StartupLayoutContext = createContext<StartupLayoutContextType>({
    pageContainerRef: { current: null },
    pageContainerClassName: undefined,
    setPageContainerClassName(_) {
        throw new Error('Use in StartupLayoutContext.Provider');
    }
});

export function useStartupLayout() {
    const startupLayoutContext = useContext(StartupLayoutContext);

    return startupLayoutContext;
}
