import { Form } from '@progress/kendo-react-form';
import { useState } from 'react';
import { AuthLocationState } from '.';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { AuthForm, AuthFormSubmitButton } from '../../pages/layouts/authLayout';
import { authenticationService } from '../../services/authenticationService';
import { ErrorResponse, HttpException } from '../../services/httpServiceBase';
import { useInvisibleReCaptcha } from '../common/invisibleReCaptcha';
import { EmailField, PasswordField } from '../ui/inputs';

export function LoginWithPasswordForm({ onLoggedIn, authLocationState }: { onLoggedIn?: () => void; authLocationState: AuthLocationState }) {
    const { email, hasInvitation } = authLocationState;
    const [generalError, setGeneralError] = useState<string>();
    const getReCaptchaToken = useInvisibleReCaptcha();

    const [submitDisabled, submitActionCreator] = useSingleClickButton<Parameters<typeof onSubmitLoginForm>, ReturnType<typeof onSubmitLoginForm>>();

    if (!email) return null;

    async function onSubmitLoginForm(data: Record<string, string>) {
        if (!email) return;

        try {
            const reCaptchaToken = await getReCaptchaToken?.();
            await authenticationService.login(email, data.password, reCaptchaToken);

            onLoggedIn?.();
        } catch (e) {
            if (e instanceof HttpException && e.status === 400) {
                const errorResponse = e.parseResponse<ErrorResponse>();
                setGeneralError(errorResponse.message);
            } else throw e;
        }
    }

    const loginText = hasInvitation ? 'Log in and join startup' : 'Log in';

    return (
        <Form
            onSubmit={submitActionCreator(onSubmitLoginForm)}
            onSubmitClick={() => setGeneralError(undefined)}
            ignoreModified={true}
            initialValues={{ emailAddress: email }}
            render={formRenderProps => (
                <AuthForm button={<AuthFormSubmitButton disabled={!formRenderProps.allowSubmit || submitDisabled}>{loginText}</AuthFormSubmitButton>}>
                    <EmailField showBackToLogin={!hasInvitation} readOnly className="k-disabled" />
                    <PasswordField showForgotPasswordLink={true} />
                    {generalError && <span className="k-text-error">{generalError}</span>}
                </AuthForm>
            )}
        />
    );
}
