import { guid } from '@progress/kendo-react-common';
import { appConfig } from '../config';
import { SubscriptionType } from '../services/authenticationService';
import { InterviewStage } from '../services/interviewsService';
import { CustomerType } from '../services/stripeBillingService';
import { LicenseData, ReducedUser } from '../services/usersService';

class GoogleTagManager {
    private readonly window: Window & Record<any, any>;
    private readonly dataLayerBuffer: Record<string, any>[] = [];
    private isInitialized = false;

    constructor(private readonly containerId: string | null | undefined, private readonly appVersion: string) {
        this.window = window;
    }

    ensureInitialized() {
        if (this.isInitialized) return;

        this.initialize();
    }

    setUserData(user?: ReducedUser, license?: LicenseData) {
        if (this.isInitialized) this.triggerEvent('user_info', this.getUserInfoEventData(user, license));
        else this.initialize(user, license);
    }

    reportUserRegisteredEvent(origin: string) {
        this.triggerEvent('sign_up', { context: origin });
    }

    reportUserLoggedInEvent(origin: string) {
        this.triggerEvent('user_authenticated', { context: origin });
    }

    reportPassedWelcomeScreenEvent() {
        this.triggerEvent('welcome_screen_passed');
    }

    reportStartupCreatedEvent() {
        this.triggerEvent('startup_created');
    }

    reportTaskCompletedEvent(taskIndex: number) {
        this.triggerEvent('task_completed', { task_index: taskIndex });
    }

    reportInterviewStageChanged(newStage: InterviewStage) {
        if (newStage !== InterviewStage.PendingReview) return;

        this.triggerEvent('validation_interview_completed');
    }

    reportUserInvitedEvent(type: string) {
        this.triggerEvent('referral_sent', { type });
    }

    reportSubscriptionStartedEvent(totalPrice: number, type: SubscriptionType, customerType: CustomerType, quantity: number) {
        this.triggerEvent('initial_payment', {
            value: totalPrice,
            payment_type: type,
            customer_type: customerType
        });
        this.triggerEvent('purchase', {
            transaction_id: guid(),
            value: totalPrice,
            currency: 'EUR',
            items: [
                {
                    item_id: type === SubscriptionType.Monthly ? 0 : 1,
                    item_name: `Icanpreneur platform - ${type}`,
                    price: totalPrice / quantity,
                    quantity: quantity,
                    item_variant: type,
                    item_category: customerType
                }
            ]
        });
    }

    private initialize(user?: ReducedUser, license?: LicenseData) {
        if (!this.containerId || this.isInitialized) return;
        this.window.dataLayer = this.window.dataLayer || [];
        const gtm = this;
        this.window.gtag = function gtag() {
            gtm.window.dataLayer.push(arguments);
        };
        this.window.gtag('consent', 'default', { ad_user_data: 'granted', ad_personalization: 'granted', ad_storage: 'granted', analytics_storage: 'granted' });
        this.window.dataLayer.push({ event: 'user_info', ...this.getUserInfoEventData(user, license) });
        this.flushBuffer();
        this.window.dataLayer.push({ app_version: this.appVersion });
        this.window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        this.renderScript();

        this.isInitialized = true;
    }

    private flushBuffer() {
        if (this.dataLayerBuffer.length) {
            this.dataLayerBuffer.forEach(d => this.window.dataLayer.push(d));
            this.dataLayerBuffer.length = 0;
        }
    }

    private getUserInfoEventData(user?: ReducedUser, license?: LicenseData) {
        return {
            user_id: user?.userId ?? undefined,
            //'user_type': user?.type ?? undefined, // TO be added once company accounts are implemented
            user_firstName: user?.firstName ?? undefined,
            user_lastName: user?.lastName ?? undefined,
            user_email: user?.emailAddress ?? undefined,
            user_licenseType: license?.type ?? undefined
        };
    }

    private renderScript() {
        if (!this.containerId) return;

        const firstScriptElementOnPage = document.getElementsByTagName('script')[0];
        const tagManagerScriptElement = document.createElement('script');
        tagManagerScriptElement.async = true;
        tagManagerScriptElement.src = 'https://www.googletagmanager.com/gtm.js?id=' + this.containerId;
        firstScriptElementOnPage.parentNode!.insertBefore(tagManagerScriptElement, firstScriptElementOnPage);
    }

    private triggerEvent(eventName: string, data?: Record<string, any>) {
        if (!this.containerId) return;

        const eventData = { event: eventName, ...data };
        this.pushDataToDataLayer(eventData);
    }

    private pushDataToDataLayer(data: Record<string, any>) {
        if (!this.isInitialized) {
            this.dataLayerBuffer.push(data);
            return;
        }

        this.window.dataLayer = this.window.dataLayer || [];
        this.window.dataLayer.push(data);
    }
}

export const googleTagManager = new GoogleTagManager(appConfig.googleTagManagerContainerId, appConfig.appVersion);
