import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { RadioGroup } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { hypothesisTypeLabelMap, hypothesisValidators } from '../../components/hypotheses/common';
import { HypothesisEditMode, HypothesisEditor } from '../../components/hypotheses/hypothesisEditor';
import { ValidatedInput } from '../../components/ui/inputs';
import { H1, H3 } from '../../components/ui/typography';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { HypothesisType, hypothesesService } from '../../services/hypothesesService';

export function AddCustomerProblemsHypothesisPage() {
    const { ideaId } = useParams();
    const initialHypothesisType = HypothesisType.PainLevel;
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);

    const [createHypothesisDisabled, createHypothesisCallbackCreator] = useSingleClickButton<
        Parameters<typeof onCreateHypothesisSubmit>,
        ReturnType<typeof onCreateHypothesisSubmit>
    >();

    async function onCreateHypothesisSubmit(data: Record<string, any>) {
        if (!ideaId) return;
        const hypothesisType: HypothesisType = data.type;
        switch (hypothesisType) {
            case HypothesisType.PainLevel:
                await hypothesesService.createCustomerProblemPainLevelHypothesis(ideaId, {
                    customerSegmentId: data.hypothesis.customerSegmentId,
                    customerProblemId: data.hypothesis.customerProblemId,
                    painLevel: data.hypothesis.painLevel,
                    percentage: data.hypothesis.percentage
                });
                break;
            case HypothesisType.AlternativeSolutionUsage:
                await hypothesesService.createCustomerProblemAlternativeSolutionHypothesis(ideaId, {
                    customerSegmentId: data.hypothesis.customerSegmentId,
                    customerProblemId: data.hypothesis.customerProblemId,
                    percentage: data.hypothesis.percentage,
                    alternativeSolutionId: data.hypothesis.alternativeSolutionId
                });
                break;
            case HypothesisType.AlternativeSolutionSatisfaction:
                await hypothesesService.createCustomerProblemAlternativeSolutionSatisfactionHypothesis(ideaId, {
                    customerSegmentId: data.hypothesis.customerSegmentId,
                    customerProblemId: data.hypothesis.customerProblemId,
                    percentage: data.hypothesis.percentage,
                    alternativeSolutionId: data.hypothesis.alternativeSolutionId,
                    satisfactionLevel: data.hypothesis.satisfactionLevel
                });
                break;
        }

        navigate('..');
    }

    return (
        <>
            <H1 className="heading-row">Add Customers-Problems Hypothesis</H1>
            <div className="page-content-middle">
                <Form
                    ref={formRef}
                    initialValues={{ type: initialHypothesisType, hypothesis: { type: initialHypothesisType } }}
                    ignoreModified={true}
                    onSubmit={createHypothesisCallbackCreator(onCreateHypothesisSubmit)}
                    render={formRenderProps => (
                        <FormElement>
                            <div className="k-pb-8 k-mb-10 k-icp-component-border k-border-b-solid k-border-b">
                                <H3 className="!k-mb-4">Hypothesis type</H3>
                                <Field
                                    name="type"
                                    component={ValidatedInput}
                                    inputType={RadioGroup}
                                    className="!k-gap-1"
                                    data={[
                                        {
                                            label: `Hypothesis for ${hypothesisTypeLabelMap[HypothesisType.PainLevel]}`,
                                            value: HypothesisType.PainLevel
                                        },
                                        {
                                            label: `Hypothesis for ${hypothesisTypeLabelMap[HypothesisType.AlternativeSolutionUsage]}`,
                                            value: HypothesisType.AlternativeSolutionUsage
                                        },
                                        {
                                            label: `Hypothesis for ${hypothesisTypeLabelMap[HypothesisType.AlternativeSolutionSatisfaction]}`,
                                            value: HypothesisType.AlternativeSolutionSatisfaction
                                        }
                                    ]}
                                    onChange={e => formRenderProps.onChange('hypothesis', { value: { type: e.value } })}
                                />
                            </div>
                            <div>
                                <H3 className="!k-mb-4">Hypothesis builder</H3>
                                <Field
                                    name="hypothesis"
                                    component={ValidatedInput}
                                    inputType={HypothesisEditor}
                                    editMode={HypothesisEditMode.EditAll}
                                    bordered={true}
                                    validate={formRef.current?.touched['hypothesis']}
                                    validator={hypothesisValidators[formRenderProps.valueGetter('type') as HypothesisType]}
                                />
                            </div>
                            <StackLayout className="k-gap-4 k-mt-14" align={{ horizontal: 'center' }}>
                                <Button type="submit" themeColor="primary" size="large" disabled={!formRenderProps.allowSubmit || createHypothesisDisabled}>
                                    Save hypothesis
                                </Button>
                                <Button type="button" size="large" onClick={() => navigate('..')}>
                                    Cancel
                                </Button>
                            </StackLayout>
                        </FormElement>
                    )}
                />
            </div>
        </>
    );
}
