import { To } from 'react-router-dom';
import { TaskData } from '../../../pages/journey/extensibility/common';
import { JourneyTaskGuidance } from '../../../services/journeyService';

export type TaskEditorProps<TParams> = {
    ideaId: string;
    isEditing?: boolean;
    isHidden?: boolean;
    onBeginCommit: () => void;
    onEndCommit: (isValid?: boolean) => void;
    errors?: string[];
    navigate: (to: To) => void;
    params: TParams;
    guidance?: JourneyTaskGuidance[];
    taskData: TaskData;
    setTaskData: React.Dispatch<React.SetStateAction<TaskData>>;
};

export type EditorCommitResult = {
    isValid: boolean;
    preventScroll?: boolean;
};
export type TaskEditorRef = {
    commit?: () => void | boolean | EditorCommitResult;
};

/**
 * When completing a step or task, async api modification operations from editors can be wrapped with this method to ensure that the operation is finished first.
 * This method essentially notifies a global task button about the state of local operations. This also ensures that validation is performed after all changes are commited.
 */
export function useTaskCommitOperation(props: TaskEditorProps<unknown>, callback?: () => void) {
    return function createCommitOperation<T extends (...args: any) => any>(operation: T): (...args: Parameters<T>) => ReturnType<T> {
        function onEndOperation() {
            props.onEndCommit();
            callback?.();
        }

        return function(...args: Parameters<T>): ReturnType<T> {
            props.onBeginCommit();
            let isAsync = false;
            try {
                const result = operation(...args);
                if (result instanceof Promise) {
                    isAsync = true;
                    return result.finally(onEndOperation) as ReturnType<T>;
                }

                return result;
            } finally {
                if (!isAsync) onEndOperation();
            }
        };
    };
}

export function normalizeEditorCommitResult(commitResult: void | boolean | EditorCommitResult): EditorCommitResult {
    if (commitResult === undefined)
        return {
            isValid: true
        };

    if (typeof commitResult === 'boolean') return { isValid: commitResult };

    return commitResult;
}
