import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Error as ErrorComponent } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInvisibleReCaptcha } from '../../components/common/invisibleReCaptcha';
import { PasswordField, passwordRequirementsMessage } from '../../components/ui/inputs';
import { H1 } from '../../components/ui/typography';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { authenticationService } from '../../services/authenticationService';
import { ErrorResponse, ErrorsResponse, HttpException } from '../../services/httpServiceBase';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

function tryHandleChangePasswordError(e: unknown): { fieldName: 'oldPassword' | 'newPassword' | 'all'; message: string } | undefined {
    if (!(e instanceof HttpException)) return;

    if (e.status === 400) {
        const errorData = e.parseResponse<ErrorResponse | ErrorsResponse>();
        if (errorData.message instanceof Array) {
            const oldPasswordError = errorData.message.find(e => e.startsWith('oldPassword'));
            if (oldPasswordError) return { fieldName: 'oldPassword', message: oldPasswordError.replace('oldPassword', 'Password') };

            if (errorData.message.find(e => e.startsWith('newPassword'))) return { fieldName: 'newPassword', message: passwordRequirementsMessage };
        } else if (errorData.message === 'New password cannot match old password.')
            return { fieldName: 'newPassword', message: 'New and current password should be different' };
        else if (errorData.message === 'Old password does not match.') return { fieldName: 'oldPassword', message: 'Wrong password' };
    } else if (e.status === 409) {
        const errorData = e.parseResponse<ErrorResponse>();
        if (errorData.message?.startsWith('Cannot change password for user with status'))
            return { fieldName: 'all', message: 'Confirm your email to change the password.' };
    }

    return undefined;
}

export function ChangePasswordPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [currentPasswordError, setCurrentPasswordError] = useState<string>();
    const [newPasswordError, setNewPasswordError] = useState<string>();
    const [generalError, setGeneralError] = useState<string>();
    const getReCaptchaToken = useInvisibleReCaptcha();
    const [changeDisabled, changePasswordCallbackCreator] = useSingleClickButton<
        Parameters<typeof onChangePasswordSubmit>,
        ReturnType<typeof onChangePasswordSubmit>
    >();

    async function onChangePasswordSubmit(data: Record<string, string>) {
        setGeneralError(undefined);
        try {
            const reCaptchaToken = await getReCaptchaToken?.();
            await authenticationService.changePassword(data.password, data.newPassword, reCaptchaToken);
            dispatch(addNotification({ content: 'Password successfully changed', type: 'success' }));
            navigate('..');
        } catch (e) {
            const handledErrorData = tryHandleChangePasswordError(e);
            if (!handledErrorData) throw e;
            if (handledErrorData.fieldName === 'newPassword') setNewPasswordError(handledErrorData.message);
            else if (handledErrorData.fieldName === 'oldPassword') setCurrentPasswordError(handledErrorData.message);
            else if (handledErrorData.fieldName === 'all') setGeneralError(handledErrorData.message);
            else throw e;
        }
    }

    return (
        <div className="page-content-section">
            <H1 className="heading-row">Change password</H1>
            <div className="page-content-middle">
                <Form
                    onSubmit={changePasswordCallbackCreator(onChangePasswordSubmit)}
                    ignoreModified={true}
                    render={formRenderProps => (
                        <FormElement>
                            <StackLayout className="k-gap-6" orientation="vertical">
                                <StackLayout className="k-gap-6 k-px-8 k-pb-8" orientation="vertical">
                                    <PasswordField
                                        label="Current password"
                                        errorMessage={currentPasswordError}
                                        onChange={() => setCurrentPasswordError(undefined)}
                                    />
                                    <PasswordField
                                        name="newPassword"
                                        label="New password"
                                        errorMessage={newPasswordError}
                                        onChange={() => setNewPasswordError(undefined)}
                                        showHint={true}
                                    />
                                    {generalError && <ErrorComponent className="!k-fs-md !k-mt-0">{generalError}</ErrorComponent>}
                                </StackLayout>

                                <StackLayout className="k-gap-6" align={{ horizontal: 'center' }}>
                                    <Button type="submit" themeColor="primary" size="large" disabled={!formRenderProps.allowSubmit || changeDisabled}>
                                        Change password
                                    </Button>
                                    <Button type="button" fillMode="flat" size="large" onClick={() => navigate('..')}>
                                        Cancel
                                    </Button>
                                </StackLayout>
                            </StackLayout>
                        </FormElement>
                    )}
                />
            </div>
        </div>
    );
}
