import { PopupPropsContext } from '@progress/kendo-react-popup';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { BackToTaskButton } from '../../components/journey/backToTaskButton';
import Sidebar from '../../components/sidebar/sidebar';
import IdeaZone from '../../components/startup/ideaZone';
import { StartupLayoutContext, StartupLayoutContextType } from '../../hooks/startupHooks';
import { combineClassNames } from '../../services/common';

export default function StartupLayout() {
    const [pageContainerClassName, setPageContainerClassName] = useState<string | undefined>();
    const pageContainerRef = useRef<HTMLElement>(null);

    const location = useLocation();
    useEffect(() => {
        if (!pageContainerRef.current || !pageContainerRef.current.scrollTop) return;

        pageContainerRef.current.scrollTop = 0;
    }, [location.pathname]);

    const startupLayoutContextValue: StartupLayoutContextType = { pageContainerRef: pageContainerRef, pageContainerClassName, setPageContainerClassName };

    return (
        <IdeaZone>
            <Sidebar />
            <main className={combineClassNames('main-column page-content-section', pageContainerClassName)} ref={pageContainerRef}>
                <BackToTaskButton scrollingContainerRef={pageContainerRef} />
                <PopupPropsContext.Provider value={p => (p.appendTo ? p : { ...p, appendTo: pageContainerRef.current })}>
                    <StartupLayoutContext.Provider value={startupLayoutContextValue}>
                        <Outlet />
                    </StartupLayoutContext.Provider>
                </PopupPropsContext.Provider>
            </main>
        </IdeaZone>
    );
}
