import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { RadioButtonProps, RadioGroup } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { Suspense, lazy, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    AllowedTaxIdTypes,
    BillingInfoValidators,
    CountriesPostalCodesSettings,
    UICCountryCodes,
    collectTaxIds
} from '../../components/billing/billingDataUtils';
import { TaxIdsFormPickerHandle } from '../../components/billing/taxIdsPicker';
import { CountriesComboBox, useCountries } from '../../components/common/countries';
import { FormFieldProps, ValidatedInput, ValidatedInputHorizontalLayout, defaultValidators } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { useBillingInfo } from '../../hooks/subscriptionHooks';
import { Country } from '../../services/countriesService';
import { BillingInfo, CustomerType, TaxId, TaxIdType } from '../../services/stripeBillingService';

const TaxIdsFormPickerLazy = lazy(() => import('../../components/billing/taxIdsPicker'));

const { customerTypeValidators, uicValidators, nameValidators } = BillingInfoValidators;

const customerTypeOptions: Pick<RadioButtonProps, 'label' | 'value'>[] = [
    {
        label: 'Individual',
        value: CustomerType.Individual
    },
    {
        label: 'Company',
        value: CustomerType.Company
    }
];

function CustomerTypePicker({ id, value, onChange, valid, disabled }: FormFieldProps<CustomerType>) {
    return <RadioGroup name={id} data={customerTypeOptions} value={value} onChange={onChange} valid={valid} disabled={disabled} layout="horizontal" />;
}

type BillingUpdateData = {
    country: Country;
    city: string;
    address: string;
    postalCode?: string;
    customerType: CustomerType;
    name: string;
    uic?: string;
    taxIds?: TaxId[];
};

export const EditBillingInfoPage = () => {
    const { billingInfo, updateBillingInfo } = useBillingInfo();
    const navigate = useNavigate();
    const countries = useCountries();
    const [btnDisabled, submitAction] = useSingleClickButton();
    const taxIdsPickerRef = useRef<TaxIdsFormPickerHandle>(null);

    const onUpdateBillingInfo = async (formData: Record<string, any>) => {
        const billingData = formData as BillingUpdateData;

        const updateBillingData: BillingInfo = {
            address: billingData.address,
            city: billingData.city,
            customerType: billingData.customerType,
            name: billingData.name,
            postalCode: billingData.country.code in CountriesPostalCodesSettings ? billingData.postalCode : undefined,
            country: billingData.country.code,
            taxIds:
                billingData.customerType === CustomerType.Company
                    ? collectTaxIds(billingData.country.code, billingData.taxIds, billingData.uic, taxIdsPickerRef.current?.applicableTaxTypes())
                    : []
        };

        await updateBillingInfo(updateBillingData);
        navigate('..');
    };

    const initialBillingInfo = (billingInfo: BillingInfo) => {
        const taxIds = billingInfo.taxIds.filter(taxId => taxId.type !== TaxIdType.BGUic);
        return {
            ...billingInfo,
            taxIds,
            uic: billingInfo.taxIds.filter(taxId => taxId.type === TaxIdType.BGUic)?.[0]?.value,
            country: countries?.filter(c => c.code === billingInfo.country)?.[0]
        };
    };

    return (
        <div className="page-content-section">
            <H1 className="heading-row">Edit billing information</H1>

            <div className="page-content-middle">
                {billingInfo ? (
                    <Form
                        initialValues={initialBillingInfo(billingInfo)}
                        onSubmit={submitAction(onUpdateBillingInfo)}
                        ignoreModified={true}
                        render={formRenderProps => {
                            const selectedCountry: Country | undefined = formRenderProps.valueGetter('country');
                            const postalCodeSettings = selectedCountry ? CountriesPostalCodesSettings[selectedCountry.code] : undefined;
                            const customerType: CustomerType | undefined = formRenderProps.valueGetter('customerType');
                            return (
                                <FormElement>
                                    <StackLayout className="k-gap-6" orientation="vertical">
                                        <Field label="Name" name="name" component={ValidatedInput} validator={nameValidators} maxLength={50} />
                                        <Field
                                            label="Country"
                                            name="country"
                                            component={ValidatedInput}
                                            validator={defaultValidators.countryValidators}
                                            inputType={CountriesComboBox}
                                            data={countries}
                                        />
                                        <Field
                                            label="City"
                                            name="city"
                                            validator={defaultValidators.cityValidators}
                                            component={ValidatedInput}
                                            maxLength={50}
                                        />
                                        <Field
                                            name="address"
                                            component={ValidatedInput}
                                            label="Address"
                                            validator={defaultValidators.addressValidators}
                                            wrapperClass="k-flex-1"
                                        />
                                        {postalCodeSettings && (
                                            <Field
                                                name="postalCode"
                                                component={ValidatedInput}
                                                label={postalCodeSettings.label}
                                                validator={postalCodeSettings.validators}
                                                wrapperClass="k-flex-1"
                                            />
                                        )}
                                        <Field
                                            name="customerType"
                                            component={ValidatedInput}
                                            layout={ValidatedInputHorizontalLayout}
                                            label="Bill to:"
                                            validator={customerTypeValidators}
                                            inputType={CustomerTypePicker}
                                            wrapperClass="k-icp-horizontal-input-compact"
                                            labelClassName="k-mr-8"
                                        />
                                        {customerType &&
                                            customerType === CustomerType.Company &&
                                            selectedCountry &&
                                            UICCountryCodes.has(selectedCountry.code) && (
                                                <Field name="uic" component={ValidatedInput} label="UIC" validator={uicValidators} wrapperClass="k-flex-1" />
                                            )}

                                        {customerType === CustomerType.Company && (
                                            <Suspense>
                                                <TaxIdsFormPickerLazy
                                                    ref={taxIdsPickerRef}
                                                    fieldName="taxIds"
                                                    countryCode={selectedCountry?.code}
                                                    taxIdsTypeFilter={AllowedTaxIdTypes}
                                                />
                                            </Suspense>
                                        )}

                                        <StackLayout className="k-gap-6" align={{ horizontal: 'center' }}>
                                            <Button type="submit" themeColor="primary" size="large" disabled={!formRenderProps.allowSubmit || btnDisabled}>
                                                Update
                                            </Button>
                                            <Button type="button" fillMode="flat" size="large" onClick={() => navigate('..')}>
                                                Cancel
                                            </Button>
                                        </StackLayout>
                                    </StackLayout>
                                </FormElement>
                            );
                        }}
                    />
                ) : (
                    <div className="k-text-center">
                        <LoadingIndicator size="big" />
                    </div>
                )}
            </div>
        </div>
    );
};
