import { ErrorWithOperationDisplayName, FileResponse } from './common';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';

export enum FeedbackType {
    Positive = 'Positive',
    Negative = 'Negative',
    Suggestion = 'Suggestion'
}

export interface Feedback {
    id: number;
    type: FeedbackType;
    context: string;
    description: string;
    screenshot?: string;
}

class FeedbackService extends HttpServiceBase {
    constructor() {
        super('/api/feedback');
    }

    @ErrorWithOperationDisplayName('Submit feedback')
    async submitFeedback(type: FeedbackType, context: string, description: string, screenshot?: string) {
        const feedbackToSubmit: Feedback = {
            id: 0, // won't get read either way
            type: type,
            context: context,
            description: description,
            screenshot: screenshot
        };

        const submittedFeedback = await this.performRequest<Feedback>({
            path: '',
            body: feedbackToSubmit,
            method: RequestMethod.POST
        });

        return submittedFeedback;
    }

    @ErrorWithOperationDisplayName('Upload feedback screenshot')
    uploadScreenshot(file: File): Promise<FileResponse> {
        const dataToPost = new FormData();
        dataToPost.append('file', file);

        return this.performRequest({ path: '/screenshots', method: RequestMethod.POST, body: dataToPost });
    }
}

export const feedbackService = new FeedbackService();
