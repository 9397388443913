import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useNavigate } from 'react-router-dom';
import { UrlInput, ValidatedInput, defaultValidators } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { useProfile } from '../../hooks/profileHooks';

export function EditSocialInfoPage() {
    const navigate = useNavigate();
    const { currentProfile, updateProfile } = useProfile();

    async function onSubmitSocialInfo(data: { [name: string]: any }) {
        await updateProfile({
            linkedInProfileUrl: data.linkedInProfileUrl ? data.linkedInProfileUrl : null,
            facebookProfileUrl: data.facebookProfileUrl ? data.facebookProfileUrl : null
        });

        navigate('..');
    }

    return (
        <div className="page-content-section">
            <H1 className="heading-row">Edit social info</H1>
            <div className="page-content-middle">
                {currentProfile ? (
                    <Form
                        initialValues={currentProfile}
                        onSubmit={onSubmitSocialInfo}
                        ignoreModified={true}
                        render={formRenderProps => (
                            <FormElement>
                                <StackLayout className="k-gap-6" orientation="vertical">
                                    <StackLayout className="k-gap-6 k-px-8 k-pb-8" orientation="vertical">
                                        <Field
                                            name="linkedInProfileUrl"
                                            component={ValidatedInput}
                                            inputType={UrlInput}
                                            label="LinkedIn"
                                            validator={defaultValidators.linkedInValidators}
                                            maxLength={2048}
                                            placeholder="Add details..."
                                        />
                                        <Field
                                            name="facebookProfileUrl"
                                            component={ValidatedInput}
                                            inputType={UrlInput}
                                            label="Facebook"
                                            validator={defaultValidators.facebookValidators}
                                            maxLength={2048}
                                            placeholder="Add details..."
                                        />
                                    </StackLayout>
                                    <StackLayout className="k-gap-6" align={{ horizontal: 'center' }}>
                                        <Button type="submit" themeColor="primary" size="large" disabled={!formRenderProps.allowSubmit}>
                                            Update
                                        </Button>
                                        <Button type="button" fillMode="flat" size="large" onClick={() => navigate('..')}>
                                            Cancel
                                        </Button>
                                    </StackLayout>
                                </StackLayout>
                            </FormElement>
                        )}
                    />
                ) : (
                    <div className="k-text-center">
                        <LoadingIndicator size="big" />
                    </div>
                )}
            </div>
        </div>
    );
}
