import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactComponent as WarningIcon } from '../../icons/alert-triangle.svg';

export type RelatedEntryData = {
    count: number;
    entryName: string;
    pluralEntryName?: string;
    isParent?: boolean;
};
export function DeletionRelatedEntriesNotice({ relationsData, entryName }: { relationsData?: RelatedEntryData[]; entryName?: string }) {
    const existingRelatedEntries = relationsData?.filter(e => e.count);
    if (existingRelatedEntries && !existingRelatedEntries.length) return null;

    const childEntries = existingRelatedEntries?.filter(e => !e.isParent);
    const parentEntries = existingRelatedEntries?.filter(e => e.isParent);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2 k-p-2 k-rounded k-icp-panel-base k-mt-4">
            {!childEntries || childEntries.length ? (
                <RelatedEntriesGroup title="Related entities will become inaccessible:" relationsData={childEntries} />
            ) : (
                undefined
            )}
            {parentEntries && parentEntries.length ? (
                <RelatedEntriesGroup title={`${entryName || 'Entry'} will be removed from:`} relationsData={parentEntries} />
            ) : (
                undefined
            )}
        </StackLayout>
    );
}

function RelatedEntriesGroup({ title, relationsData }: { title: string; relationsData?: RelatedEntryData[] }) {
    if (relationsData && !relationsData.length) return null;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3 k-fs-sm">
            <WarningIcon className="k-icp-icon k-icp-icon-size-4 k-text-error" />
            <div className="formatted-content-area">
                <div>{title}</div>
                <ul className="compressed-list">
                    {relationsData ? (
                        relationsData.map(relationData => (
                            <li key={relationData.entryName}>
                                {relationData.count}{' '}
                                {relationData.count === 1 ? relationData.entryName : relationData.pluralEntryName || `${relationData.entryName}s`}
                            </li>
                        ))
                    ) : (
                        <li>
                            <Skeleton shape="text" style={{ width: '25%' }} />
                        </li>
                    )}
                </ul>
            </div>
        </StackLayout>
    );
}
