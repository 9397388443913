import { Pager as KendoPager } from '@progress/kendo-react-data-tools';
import { PagerPageSizes } from '@progress/kendo-react-data-tools/dist/npm/pager/PagerPageSizes';
import { StackLayout } from '@progress/kendo-react-layout';

export function Pager(props: { total: number; skip: number; take: number; onPageChange: (skip: number, take: number) => void }) {
    if (!props.total) return null;

    function onPageChange(e: { skip: number; take: number }) {
        props.onPageChange(e.skip, e.take);
    }

    return (
        <StackLayout className="k-mt-6 k-p-2 k-justify-content-between" align={{ horizontal: 'start', vertical: 'middle' }}>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                <div>
                    {Math.min(props.skip + 1, props.total)} - {Math.min(props.skip + props.take, props.total)} of {props.total}
                </div>
                <div className="k-separator k-icp-component-border" />
                <div className="k-pager-md">
                    <PagerPageSizes pageChange={onPageChange} pageSize={props.take} pageSizes={[20, 60, 100]} />
                </div>
            </StackLayout>
            {props.take < props.total && (
                <KendoPager
                    total={props.total}
                    skip={props.skip}
                    take={props.take}
                    onPageChange={onPageChange}
                    buttonCount={5}
                    previousNext={true}
                    info={false}
                    className="!k-p-0"
                />
            )}
        </StackLayout>
    );
}
