import { Link } from 'react-router-dom';
import taskUnderConstructionImageUrl from '../../images/task-under-construction.svg';
import { H2 } from '../ui/typography';

export function TaskUnderConstructionView() {
    return (
        <div className="k-text-center k-mt-16">
            <img src={taskUnderConstructionImageUrl} alt="Coming soon" width="750" height="316" className="responsive-image k-display-inline-block k-mb-12" />
            <div className="page-content-middle">
                <H2 className="!k-mb-4">Work in Progress</H2>
                <div className="k-fs-lg k-mb-10">
                    We are working hard to have this task ready for you. If you want to move forward even before the task gets implemented in the platform,
                    please reach out to us in our{' '}
                    <a href="https://discord.gg/Qe986qJ3th" target="_blank" rel="noreferrer" className="k-button-link-secondary">
                        Discord Community
                    </a>{' '}
                    and we will gladly help.
                </div>
            </div>
            <Link to="../journey" className="k-button k-button-lg k-button-outline k-button-outline-secondary k-rounded-md">
                Back to Journey
            </Link>
        </div>
    );
}
