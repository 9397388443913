import { findCanvasItem, useGlobalCanvas } from '../../hooks/canvasHooks';
import { BoxType } from '../../services/canvasService';
import { ReducedPerson } from '../../services/contactsService';
import {
    CustomerProblemAlternativeSolutionHypothesis,
    CustomerProblemAlternativeSolutionSatisfactionHypothesis,
    CustomerProblemPainLevelHypothesis,
    Hypothesis,
    HypothesisType,
    PainLevel
} from '../../services/hypothesesService';
import { TextTokenWrapper, TextTokensEditorLayout } from '../common/tokenizedTextEditor';

export function PersonalizedHypothesisView({
    person,
    hypothesis,
    bordered,
    plainText
}: {
    person: ReducedPerson;
    hypothesis: Hypothesis;
    bordered?: boolean;
    plainText?: boolean;
}) {
    const type = hypothesis.type;
    if (type === HypothesisType.PainLevel)
        return <PersonalizedCustomerProblemPainLevelHypothesisView person={person} hypothesis={hypothesis} bordered={bordered} plainText={plainText} />;
    if (type === HypothesisType.AlternativeSolutionUsage)
        return (
            <PersonalizedCustomerProblemAlternativeSolutionHypothesisView person={person} hypothesis={hypothesis} bordered={bordered} plainText={plainText} />
        );
    if (type === HypothesisType.AlternativeSolutionSatisfaction)
        return (
            <PersonalizedCustomerProblemAlternativeSolutionSatisfactionHypothesisView
                person={person}
                hypothesis={hypothesis}
                bordered={bordered}
                plainText={plainText}
            />
        );

    throw new Error('Unknown hypothesis type: ' + type);
}

function PersonalizedCustomerProblemPainLevelHypothesisView({
    person,
    hypothesis,
    bordered,
    plainText
}: {
    person: ReducedPerson;
    hypothesis: CustomerProblemPainLevelHypothesis;
    bordered?: boolean;
    plainText?: boolean;
}) {
    const { canvas } = useGlobalCanvas(undefined, true);
    const customerSegment = findCanvasItem(canvas.boxes, BoxType.CustomerSegments, hypothesis.customerSegmentId);
    const customerProblem = findCanvasItem(canvas.boxes, BoxType.Problem, hypothesis.customerProblemId);

    return (
        <TextTokensEditorLayout bordered={bordered} compactLines={plainText}>
            {person.firstName} {person.lastName} as a member of{' '}
            <TextTokenWrapper isLoading={hypothesis.customerSegmentId !== undefined && !customerSegment} loaderWidth={250} plainText={plainText}>
                {customerSegment?.content}
            </TextTokenWrapper>{' '}
            rates{' '}
            <TextTokenWrapper isLoading={hypothesis.customerProblemId !== undefined && !customerProblem} loaderWidth={200} plainText={plainText}>
                {customerProblem?.content}
            </TextTokenWrapper>{' '}
            as a <TextTokenWrapper plainText={plainText}>{hypothesis.painLevel === PainLevel.NoPain ? 'No pain' : hypothesis.painLevel}</TextTokenWrapper>{' '}
            problem.
        </TextTokensEditorLayout>
    );
}

function PersonalizedCustomerProblemAlternativeSolutionHypothesisView({
    person,
    hypothesis,
    bordered,
    plainText
}: {
    person: ReducedPerson;
    hypothesis: CustomerProblemAlternativeSolutionHypothesis;
    bordered?: boolean;
    plainText?: boolean;
}) {
    const { canvas } = useGlobalCanvas(undefined, true);
    const customerSegment = findCanvasItem(canvas.boxes, BoxType.CustomerSegments, hypothesis.customerSegmentId);
    const customerProblem = findCanvasItem(canvas.boxes, BoxType.Problem, hypothesis.customerProblemId);
    const alternativeSolution = findCanvasItem(canvas.boxes, BoxType.AlternativeSolutions, hypothesis.alternativeSolutionId);

    return (
        <TextTokensEditorLayout bordered={bordered} compactLines={plainText}>
            {person.firstName} {person.lastName} as a member of{' '}
            <TextTokenWrapper isLoading={hypothesis.customerSegmentId !== undefined && !customerSegment} loaderWidth={250} plainText={plainText}>
                {customerSegment?.content}
            </TextTokenWrapper>{' '}
            currently addresses{' '}
            <TextTokenWrapper isLoading={hypothesis.customerProblemId !== undefined && !customerProblem} loaderWidth={200} plainText={plainText}>
                {customerProblem?.content}
            </TextTokenWrapper>{' '}
            by using{' '}
            <TextTokenWrapper isLoading={hypothesis.alternativeSolutionId !== undefined && !alternativeSolution} loaderWidth={150} plainText={plainText}>
                {alternativeSolution?.content}
            </TextTokenWrapper>
            .
        </TextTokensEditorLayout>
    );
}

function PersonalizedCustomerProblemAlternativeSolutionSatisfactionHypothesisView({
    person,
    hypothesis,
    bordered,
    plainText
}: {
    person: ReducedPerson;
    hypothesis: CustomerProblemAlternativeSolutionSatisfactionHypothesis;
    bordered?: boolean;
    plainText?: boolean;
}) {
    const { canvas } = useGlobalCanvas(undefined, true);
    const customerSegment = findCanvasItem(canvas.boxes, BoxType.CustomerSegments, hypothesis.customerSegmentId);
    const customerProblem = findCanvasItem(canvas.boxes, BoxType.Problem, hypothesis.customerProblemId);
    const alternativeSolution = findCanvasItem(canvas.boxes, BoxType.AlternativeSolutions, hypothesis.alternativeSolutionId);

    return (
        <TextTokensEditorLayout bordered={bordered} compactLines={plainText}>
            {person.firstName} {person.lastName} as a member of{' '}
            <TextTokenWrapper isLoading={hypothesis.customerSegmentId !== undefined && !customerSegment} loaderWidth={250} plainText={plainText}>
                {customerSegment?.content}
            </TextTokenWrapper>{' '}
            who currently addresses{' '}
            <TextTokenWrapper isLoading={hypothesis.customerProblemId !== undefined && !customerProblem} loaderWidth={200} plainText={plainText}>
                {customerProblem?.content}
            </TextTokenWrapper>{' '}
            by using{' '}
            <TextTokenWrapper isLoading={hypothesis.alternativeSolutionId !== undefined && !alternativeSolution} loaderWidth={150} plainText={plainText}>
                {alternativeSolution?.content}
            </TextTokenWrapper>{' '}
            is <TextTokenWrapper plainText={plainText}>{hypothesis.satisfactionLevel}</TextTokenWrapper> by that solution.
        </TextTokensEditorLayout>
    );
}
