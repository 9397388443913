import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CellTemplateProps, createCellTemplateFromComponent } from '../../components/common/grid';
import { CompanyCard } from '../../components/contacts/contactCard';
import { ContactListOptions, FilterOptions, ListOptions } from '../../components/contacts/contactListOptions';
import { EmptyContactsView } from '../../components/contacts/view';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { Pager } from '../../components/ui/pager';
import UserAvatar from '../../components/user/userAvatar';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { generateInitials, getPreferredColorIndex } from '../../services/common';
import { Companies, Company, ContactsListViewMode, ContactsSortBy, contactsService } from '../../services/contactsService';
import { listPreferencesService } from '../../services/listPreferencesService';
import {
    RealTimeUpdateCompanyEventData,
    RealTimeUpdatePersonEventData,
    RealTimeUpdatePersonUpdateEventData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { useTabbedLayoutContext } from '../layouts/tabbedLayout';

export function CompaniesPage() {
    const { ideaId } = useParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canExecuteActions = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    const { setHeaderContent } = useTabbedLayoutContext();
    const [listOptions, setListOptions] = useState<ListOptions<{}>>(() => listPreferencesService.getContactsListPreferences());
    const [skip, setSkip] = useState(0);
    const navigate = useNavigate();

    const [companiesData, setCompaniesData] = useState<Companies>();

    useEffect(() => {
        setHeaderContent(<ContactListOptions ideaId={ideaId!} placeholder="Search for a company" options={listOptions} onChange={setListOptions} />);

        return () => setHeaderContent(undefined);
    }, [ideaId, listOptions, setHeaderContent]);

    const loadCompanies = useCallback((ideaId: string, sortBy: ContactsSortBy, filter: FilterOptions<{}> | undefined, skip: number, take: number) => {
        contactsService.getCompanies(ideaId, sortBy, filter?.searchText, skip, take).then(setCompaniesData);
    }, []);

    useEffect(() => {
        setCompaniesData(undefined);
        loadCompanies(ideaId!, listOptions.sortBy, listOptions.filter, skip, listOptions.pageSize);
    }, [ideaId, listOptions.filter, listOptions.sortBy, skip, listOptions.pageSize, loadCompanies]);

    useEffect(() => {
        function reloadCurrentPage(e: RealTimeUpdateCompanyEventData) {
            if (e.ideaId !== ideaId) return;

            loadCompanies(ideaId, listOptions.sortBy, listOptions.filter, skip, listOptions.pageSize);
        }

        realTimeUpdatesEventHub.addEventListener('contact', 'companyAdd', reloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('contact', 'companyRestore', reloadCurrentPage);
        // We do full reload on update instead of updating the specific record because of the sorting
        realTimeUpdatesEventHub.addEventListener('contact', 'companyUpdate', reloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('contact', 'companyDelete', reloadCurrentPage);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyAdd', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyRestore', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyUpdate', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyDelete', reloadCurrentPage);
        };
    }, [ideaId, listOptions.filter, listOptions.sortBy, skip, listOptions.pageSize, loadCompanies]);

    useEffect(() => {
        if (!companiesData) return;

        const isCompanyVisible = (companyId?: number) => companyId && companiesData.companies.some(c => c.id === companyId);

        const reloadCurrentPage = (e: RealTimeUpdatePersonEventData) => {
            if (e.ideaId !== ideaId || !isCompanyVisible(e.companyId)) return;

            loadCompanies(ideaId, listOptions.sortBy, listOptions.filter, skip, listOptions.pageSize);
        };

        const reloadCurrentPageOnPersonUpdate = (e: RealTimeUpdatePersonUpdateEventData) => {
            if (e.ideaId !== ideaId || e.companyId === e.previousCompanyId || (!isCompanyVisible(e.companyId) && !isCompanyVisible(e.previousCompanyId)))
                return;

            loadCompanies(ideaId, listOptions.sortBy, listOptions.filter, skip, listOptions.pageSize);
        };

        realTimeUpdatesEventHub.addEventListener('contact', 'personAdd', reloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('contact', 'personDelete', reloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('contact', 'personRestore', reloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('contact', 'personUpdate', reloadCurrentPageOnPersonUpdate);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('contact', 'personAdd', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personDelete', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personRestore', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personUpdate', reloadCurrentPageOnPersonUpdate);
        };
    }, [ideaId, listOptions.filter, listOptions.sortBy, skip, listOptions.pageSize, companiesData, loadCompanies]);

    if (!companiesData) return <LoadingIndicator size="big" className="!k-pos-absolute k-centered" />;

    if (!companiesData.companies.length) return <EmptyContactsView filtered={!!listOptions.filter} contactTypeName="company" contactsTypeName="companies" />;

    const pager = (
        <Pager
            total={companiesData.totalCount}
            skip={skip}
            take={listOptions.pageSize}
            onPageChange={(skip, take) => {
                const newListOptions = { ...listOptions, pageSize: take };
                listPreferencesService.saveContactsListPreferences(newListOptions);
                setListOptions(newListOptions);
                setSkip(skip);
            }}
        />
    );

    if (listOptions.viewMode === ContactsListViewMode.Card)
        return (
            <>
                <div className="k-d-grid cards-grid k-align-items-start k-gap-4">
                    {companiesData.companies.map(c => (
                        <Link key={c.id} className="k-link" to={`${c.id}`}>
                            <CompanyCard company={c} />
                        </Link>
                    ))}
                </div>
                {pager}
            </>
        );

    return (
        <>
            <Grid
                data={companiesData.companies}
                resizable={true}
                reorderable={true}
                onRowClick={e => navigate((e.dataItem as Company).id.toString())}
                className="k-grid-no-scrollbar k-icp-grid-navigatable"
            >
                <GridColumn title="Name" cell={CompanyInfoGridCellTemplate} />
                <GridColumn field="website" title="Website" width={240} />
                <GridColumn field="city" title="City" width={160} />
                <GridColumn field="createdOn" title="Created on" format="{0:d MMM y}" width={160} />
                {canExecuteActions && <GridColumn title="Actions" cell={CompanyActionsCellTemplate} width={76} />}
            </Grid>
            {pager}
        </>
    );
}

const CompanyInfoGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: company }: CellTemplateProps<Company>) {
    const companyNameParts = company.name.split(' ');
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3 k-no-click">
            <UserAvatar
                picture={company.picture}
                initials={generateInitials(2, companyNameParts[0], companyNameParts[1])}
                colorIndex={getPreferredColorIndex(company.id)}
            />

            <strong className="k-fs-lg k-icp-hoverable">{company.name}</strong>
        </StackLayout>
    );
});

const CompanyActionsCellTemplate = createCellTemplateFromComponent(function({ dataItem: company }: CellTemplateProps<Company>) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Link
                to={`${company.id}/edit`}
                className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md k-icp-svg-icon-button"
            >
                <EditIcon className="k-icp-icon" />
            </Link>
        </StackLayout>
    );
});
