import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config';
import { useCacheBust } from '../../hooks/commonHooks';
import successImage from '../../images/success-animation.svg';
import { JourneyTaskCompletion, JourneyTaskNavigationInstruction, JourneyTaskNavigationTarget } from '../../services/journeyService';
import { H1, P } from '../ui/typography';

export const TaskCompletionDialog = ({ taskCompletion, onClose }: { taskCompletion: JourneyTaskCompletion; onClose?: () => void }) => {
    const navigate = useNavigate();
    const executeNavigationInstruction = (navigationInstruction: JourneyTaskNavigationInstruction) => {
        let navigateUrl =
            navigationInstruction.target === JourneyTaskNavigationTarget.GoToCanvas
                ? '../canvas'
                : navigationInstruction.target === JourneyTaskNavigationTarget.GoToJourney
                ? '../journey'
                : `../${appConfig.startup.defaultScreenUrl}`;

        const highlights = navigationInstruction.highlights;
        if (highlights && highlights.length) {
            navigateUrl += '?' + highlights.map(h => `highlight=${encodeURIComponent(h)}`).join('&');
        }

        navigate(navigateUrl);
    };

    const animationCacheBust = useCacheBust();

    return (
        <Dialog title=" " className="k-icp-dialog-no-title" onClose={onClose} width={480}>
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-4 k-px-8 k-pb-4">
                <img src={`${successImage}?t=${animationCacheBust}`} alt="Task completion" width={480} height={86} className="responsive-image" />
                <div className="k-text-center">
                    <H1 className="!k-mb-3">{taskCompletion.completionPromptTitle}</H1>
                    <P>{taskCompletion.completionPromptContent}</P>
                </div>
            </StackLayout>
            <DialogActionsBar layout="center">
                {taskCompletion.buttons.map((b, i) => (
                    <Button key={i} themeColor={i === 0 ? 'primary' : 'base'} onClick={() => executeNavigationInstruction(b.navigationInstruction)}>
                        {b.text}
                    </Button>
                ))}
            </DialogActionsBar>
        </Dialog>
    );
};
