import { Label } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import americanExpressLogoUrl from '../../images/cards/amex.svg';
import dinersClubLogoUrl from '../../images/cards/diners.svg';
import discoverLogoUrl from '../../images/cards/discover.svg';
import defaultCardLogoUrl from '../../images/cards/generic.svg';
import jsbLogoUrl from '../../images/cards/jcb.svg';
import masterCardLogoUrl from '../../images/cards/mastercard.svg';
import unionPayLogoUrl from '../../images/cards/unionpay.svg';
import visaLogoUrl from '../../images/cards/visa.svg';

// Icons source: https://github.com/aaronfagan/svg-credit-card-payment-icons
const cardBrandLogos: Partial<Record<string, string>> = {
    amex: americanExpressLogoUrl,
    diners: dinersClubLogoUrl,
    discover: discoverLogoUrl,
    // 'eftpos_au': '',
    jcb: jsbLogoUrl,
    mastercard: masterCardLogoUrl,
    unionpay: unionPayLogoUrl,
    visa: visaLogoUrl
};

export function PaymentCardView({
    last4digits,
    brand,
    expiresMonth,
    expiresYear,
    label,
    className
}: {
    last4digits: string;
    brand?: string;
    expiresMonth?: number;
    expiresYear?: number;
    label?: string;
    className?: string;
}) {
    const cardLogoUrl = (brand ? cardBrandLogos[brand] : undefined) || defaultCardLogoUrl;

    return (
        <div className={className}>
            {label && <Label>{label}</Label>}
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                <img src={cardLogoUrl} alt={brand || 'Card'} width="35" className="k-rounded-sm" />
                <div className="k-flex-1">**** **** **** {last4digits}</div>
                <CardExpirationView expiresMonth={expiresMonth} expiresYear={expiresYear} />
            </StackLayout>
        </div>
    );
}

function CardExpirationView({ expiresMonth, expiresYear }: { expiresMonth?: number; expiresYear?: number }) {
    if (!expiresMonth || !expiresYear) return null;

    const monthExpirationString = String(expiresMonth).padStart(2, '0');

    return (
        <span>
            Expires {monthExpirationString}/{expiresYear}
        </span>
    );
}
