import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as TaskIcon } from '../../icons/task-alt.svg';
import { combineClassNames, debounce } from '../../services/common';
import { useAppSelector } from '../../state/hooks';

export function BackToTaskButton({ scrollingContainerRef }: { scrollingContainerRef: React.RefObject<HTMLElement> }) {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { ideaId } = useParams();

    const { suppressed, tasks: recentTasks } = useAppSelector(s => s.recentTasks);
    const recentTaskForCurrentIdea = ideaId ? recentTasks[ideaId] : undefined;
    const hasRecentTasks = !suppressed && !!recentTaskForCurrentIdea;
    const navigate = useNavigate();

    useEffect(() => {
        if (!scrollingContainerRef.current || !hasRecentTasks) return;

        const updateIsCollapsed = debounce(() => {
            setIsCollapsed(currentPageContainer.scrollTop !== 0);
        }, 100);

        updateIsCollapsed();

        const currentPageContainer = scrollingContainerRef.current;
        currentPageContainer.addEventListener('scroll', updateIsCollapsed, { passive: true });

        return () => currentPageContainer.removeEventListener('scroll', updateIsCollapsed);
    }, [scrollingContainerRef, hasRecentTasks]);

    if (!hasRecentTasks) return null;

    return (
        <div className="back-to-task-btn-location-wrapper">
            <div className="back-to-task-btn-placeholder">
                <div className={combineClassNames('back-to-task-btn-wrapper', isCollapsed ? 'back-to-task-btn-wrapper--collapsed' : undefined)}>
                    <Button
                        fillMode="outline"
                        themeColor="secondary"
                        className="back-to-task-btn !k-py-2 !k-pr-4"
                        onClick={() =>
                            navigate(
                                `./journey/tasks/${recentTaskForCurrentIdea.sequence}/${recentTaskForCurrentIdea.tag}${
                                    recentTaskForCurrentIdea.variation ? `/${recentTaskForCurrentIdea.variation}` : ''
                                }`
                            )
                        }
                    >
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                            <div className="current-task-icon-wrapper k-p-1 k-rounded-full k-mr-thin">
                                <TaskIcon className="k-icp-icon-size-4 k-icp-icon" />
                            </div>
                            <span>Back to Task {recentTaskForCurrentIdea.index}</span>
                        </StackLayout>
                    </Button>
                </div>
            </div>
        </div>
    );
}
