import { Slide } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import cookieImageUrl from '../../images/cookie.svg';
import { P } from '../ui/typography';

const acceptedCookiesStorageKey = 'cookies.accepted';
export function CookiesNotice() {
    const alreadyAcceptedCookies = localStorage.getItem(acceptedCookiesStorageKey) === 'true';
    const [showCookiesNotice, setShowCookiesNotice] = useState(!alreadyAcceptedCookies);

    if (!showCookiesNotice) return null;

    function acceptCookies() {
        localStorage.setItem(acceptedCookiesStorageKey, 'true');
        setShowCookiesNotice(false);
    }

    return (
        <Slide appear direction="up" style={{zIndex: 99999}} componentChildClassName="cookies-notice-wrapper" className="k-display-block">
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="cookies-notice k-gap-6 k-p-6">
                <img src={cookieImageUrl} width="84" height="85" alt="Cookies notice" />
                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-6">
                    <P className="formatted-content-area">
                        We use cookies & other similar technology to collect data to improve your experience on our site, as described in our{' '}
                        <a href="/legal/privacy-policy" target="_blank" rel="noreferrer">
                            Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a href="/legal/cookie-policy" target="_blank" rel="noreferrer">
                            Cookie Policy
                        </a>
                        .
                    </P>
                    <Button onClick={acceptCookies} themeColor="secondary" size="large" className="k-align-self-stretch">
                        Accept and close
                    </Button>
                </StackLayout>
            </StackLayout>
        </Slide>
    );
}
