import { meetingsService, reachOutsService } from './activitiesService';
import { ErrorWithOperationDisplayName } from './common';
import { Meeting, OpenSlot, ReachOut, ResearchReachOut, eventDateFieldsParser } from './events';
import { HttpServiceBase } from './httpServiceBase';
import { researchService } from './researchService';

export enum ScheduleItemType {
    ReachOut = 'ReachOut',
    Meeting = 'Meeting',
    OpenSlot = 'OpenSlot',
    ResearchReachOut = 'ResearchReachOut'
}

type ScheduleItemTypeDataMap = {
    [ScheduleItemType.ReachOut]: { propertyName: 'reachOut'; type: ReachOut };
    [ScheduleItemType.Meeting]: { propertyName: 'meeting'; type: Meeting };
    [ScheduleItemType.OpenSlot]: { propertyName: 'openSlot'; type: OpenSlot };
    [ScheduleItemType.ResearchReachOut]: { propertyName: 'researchReachOut'; type: ResearchReachOut };
};

export type ScheduleItemOfType<T extends ScheduleItemType> = {
    type: T;
} & {
    [P in ScheduleItemTypeDataMap[T]['propertyName']]: ScheduleItemTypeDataMap[T]['type'];
};

export type ScheduleItem = {
    [P in ScheduleItemType]: ScheduleItemOfType<P>;
}[ScheduleItemType];

export class ScheduledItem {
    readonly id: string;
    readonly title: string;
    readonly start: Date;
    readonly end: Date;
    readonly isAllDay: boolean | undefined;

    constructor(readonly data: ScheduleItem) {
        const eventData = ScheduledItem.resolveScheduledEventCommonData(data);
        this.id = eventData.id;
        this.title = eventData.title;
        this.start = eventData.start;
        if (!eventData.end || eventData.start.getTime() === eventData.end.getTime()) {
            this.end = new Date(eventData.start);
            this.end.setTime(this.end.getTime() + 1);
        } else this.end = eventData.end;
        this.isAllDay = eventData.isAllDay;
    }

    private static resolveScheduledEventCommonData(
        event: ScheduleItem
    ): { id: string; title: string; start: Date; end: Date | undefined; isAllDay: boolean | undefined } {
        const eventType = event.type;
        if (event.type === ScheduleItemType.Meeting) {
            return {
                id: `${event.type}_${event.meeting.id}`,
                title: event.meeting.title,
                start: event.meeting.startTime,
                end: event.meeting.endTime,
                isAllDay: event.meeting.allDay
            };
        }

        if (event.type === ScheduleItemType.ReachOut) {
            return {
                id: `${event.type}_${event.reachOut.id}`,
                title: event.reachOut.topic,
                start: event.reachOut.date,
                end: undefined,
                isAllDay: undefined
            };
        }

        if (event.type === ScheduleItemType.OpenSlot) {
            return {
                id: `${event.type}_${event.openSlot.schedule.id}_${event.openSlot.slot.startTime.getTime()}_${event.openSlot.slot.endTime.getTime()}`,
                title: `Slot for ${event.openSlot.schedule.title}`,
                start: event.openSlot.slot.startTime,
                end: event.openSlot.slot.endTime,
                isAllDay: undefined
            };
        }

        if (event.type === ScheduleItemType.ResearchReachOut) {
            return {
                id: `${event.type}_${event.researchReachOut.id}`,
                title: `Invite for ${event.researchReachOut.research.title}`,
                start: event.researchReachOut.date,
                end: undefined,
                isAllDay: undefined
            };
        }

        throw new Error(`Unknown event type: ${eventType}`);
    }
}

class EventsService extends HttpServiceBase {
    constructor() {
        super('/api/scheduling');
    }

    protected ensureItemDateFieldsType(item: ScheduleItem) {
        switch (item.type) {
            case ScheduleItemType.ReachOut:
                eventDateFieldsParser[ScheduleItemType.ReachOut](item.reachOut);
                break;
            case ScheduleItemType.Meeting:
                eventDateFieldsParser[ScheduleItemType.Meeting](item.meeting);
                break;
            case ScheduleItemType.OpenSlot:
                eventDateFieldsParser[ScheduleItemType.OpenSlot](item.openSlot);
                break;
            case ScheduleItemType.ResearchReachOut:
                eventDateFieldsParser[ScheduleItemType.ResearchReachOut](item.researchReachOut);
                break;
        }
    }

    private remapItemFields(item: ScheduleItem) {
        if (item.type !== ScheduleItemType.OpenSlot) return;

        const untypedItem: any = item;
        item.openSlot = {
            schedule: untypedItem.schedule,
            slot: untypedItem.slot
        };

        delete untypedItem.schedule;
        delete untypedItem.slot;
    }

    @ErrorWithOperationDisplayName('Get events')
    getEvents(ideaId: string, fromDate: Date, toDate: Date): Promise<ScheduledItem[]> {
        return this.performRequest<ScheduleItem[]>({
            path: `/${ideaId}/events`,
            queryParams: {
                from: fromDate.toISOString(),
                to: toDate.toISOString()
            }
        }).then(events =>
            events.map(e => {
                this.remapItemFields(e);
                this.ensureItemDateFieldsType(e);
                return new ScheduledItem(e);
            })
        );
    }

    @ErrorWithOperationDisplayName('Delete event')
    deleteEvent(ideaId: string, item: ScheduledItem): Promise<unknown> {
        switch (item.data.type) {
            case ScheduleItemType.ReachOut:
                return reachOutsService.deleteActivity(ideaId, item.data.reachOut.contact.id, item.data.reachOut.id);
            case ScheduleItemType.Meeting:
                return meetingsService.deleteMeeting(ideaId, item.data.meeting.id);
            case ScheduleItemType.OpenSlot:
                throw new Error('Deleting slots is not supported');
            case ScheduleItemType.ResearchReachOut:
                return researchService.deleteProblemValidationResearchReachOut(ideaId, item.data.researchReachOut.research.id, item.data.researchReachOut.id);
        }
    }

    @ErrorWithOperationDisplayName('Reschedule event')
    rescheduleEvent(ideaId: string, item: ScheduledItem): Promise<ScheduledItem> {
        switch (item.data.type) {
            case ScheduleItemType.ReachOut:
                return reachOutsService
                    .rescheduleReachOut(ideaId, item.data.reachOut.contact.id, item.data.reachOut.id, item.start)
                    .then(r => new ScheduledItem({ type: ScheduleItemType.ReachOut, reachOut: r }));
            case ScheduleItemType.Meeting:
                return meetingsService
                    .rescheduleMeeting(ideaId, item.data.meeting.id, item.start, item.end, item.isAllDay)
                    .then(m => new ScheduledItem({ type: ScheduleItemType.Meeting, meeting: m }));
            case ScheduleItemType.OpenSlot:
                throw new Error('Rescheduling slots is not supported');
            case ScheduleItemType.ResearchReachOut:
                return researchService
                    .partiallyUpdateProblemValidationResearchReachOut(ideaId, item.data.researchReachOut.research.id, item.data.researchReachOut.id, {
                        date: item.start
                    })
                    .then(r => new ScheduledItem({ type: ScheduleItemType.ResearchReachOut, researchReachOut: r }));
        }
    }
}

export const eventsService = new EventsService();
