import { RealTimeUpdateCanvasItemEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { store } from '../store';
import { refreshBox, refreshCanvas, refreshItem, removeItem } from './canvasSlice';

const refreshCanvasItem = (e: RealTimeUpdateCanvasItemEventData) => {
    const currentState = store.getState();
    if (!currentState.canvas.ideaId || currentState.canvas.ideaId !== e.ideaId) return;

    store.dispatch(refreshItem(e.box, e.itemId));
};

realTimeUpdatesEventHub.addEventListener('idea', 'itemAdd', refreshCanvasItem);
realTimeUpdatesEventHub.addEventListener('idea', 'itemRestore', refreshCanvasItem);
realTimeUpdatesEventHub.addEventListener('idea', 'itemUpdate', refreshCanvasItem);
realTimeUpdatesEventHub.addEventListener('idea', 'itemDelete', e => {
    const currentIdeaId = store.getState().canvas.ideaId;
    if (!currentIdeaId || currentIdeaId !== e.ideaId) return;

    store.dispatch(removeItem({ boxType: e.box, itemId: e.itemId }));
});
realTimeUpdatesEventHub.addEventListener('idea', 'boxReorder', e => {
    const currentState = store.getState();
    if (!currentState.canvas.ideaId || currentState.canvas.ideaId !== e.ideaId) return;

    store.dispatch(refreshBox(e.box));
});
realTimeUpdatesEventHub.addEventListener('connection', 'reconnected', e => {
    const currentState = store.getState();
    if (!currentState.canvas.ideaId) return;

    store.dispatch(refreshCanvas());
});
