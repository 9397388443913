import { useEffect, useRef, useState } from 'react';
import { Company, ContactsSortBy, Person, ReducedPerson, contactsService } from '../../services/contactsService';
import { RemoteDataComboBox } from '../common/remoteDataComboBox';
import { CreateCompanyDialog, CreatePersonDialog } from './edit';
import { CompanySimpleView, PersonSimpleView } from './view';

export function PersonPicker({
    ideaId,
    value,
    onChange,
    excludedPeopleIds,
    placeholder
}: {
    ideaId: string;
    value?: Person | ReducedPerson | null;
    onChange?: (e: { value: Person | undefined | null }) => void;
    excludedPeopleIds?: number[];
    placeholder?: string;
}) {
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const createPersonDataRef = useRef<{ firstName: string; lastName?: string }>();

    return (
        <>
            {showCreateDialog && (
                <CreatePersonDialog
                    ideaId={ideaId}
                    onCancel={() => setShowCreateDialog(false)}
                    initialData={createPersonDataRef.current}
                    onCreated={newPerson => {
                        setShowCreateDialog(false);
                        onChange?.({ value: newPerson });
                    }}
                />
            )}
            <RemoteDataComboBox
                dataItemKey="id"
                textField="name"
                placeholder={placeholder ?? 'Add details...'}
                value={value}
                onChange={onChange}
                emptyListText="No contacts found."
                renderItem={p => <PersonSimpleView person={p} showJobTitle={true} />}
                fetchItems={filter =>
                    contactsService
                        .getPeople(
                            ideaId,
                            filter ? ContactsSortBy.Alphabetical : ContactsSortBy.Recent,
                            filter ? { search: filter } : undefined,
                            undefined,
                            filter ? undefined : 5
                        )
                        .then(people =>
                            excludedPeopleIds && excludedPeopleIds.length
                                ? people.people.filter(person => !excludedPeopleIds.includes(person.id))
                                : people.people
                        )
                }
                insertItemText="New contact"
                onInsertItem={text => {
                    if (text && text !== value?.name) {
                        createPersonDataRef.current = contactsService.parsePersonNamesFromString(text);
                    } else createPersonDataRef.current = undefined;
                    setShowCreateDialog(true);
                }}
            />
        </>
    );
}

export function CompanyPicker({
    id,
    ideaId,
    value,
    onChange,
    allowCreate,
    placeholder,
    onClose
}: {
    id?: string;
    ideaId: string;
    value?: Company | null;
    onChange?: (e: { value: Company | undefined | null }) => void;
    allowCreate?: boolean;
    placeholder?: string;
    onClose?: () => void;
}) {
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const createCompanyNameRef = useRef<string>();

    return (
        <>
            {showCreateDialog && (
                <CreateCompanyDialog
                    ideaId={ideaId}
                    onCancel={() => setShowCreateDialog(false)}
                    initialName={createCompanyNameRef.current}
                    onCreated={newCompany => {
                        setShowCreateDialog(false);
                        onChange?.({ value: newCompany });
                    }}
                />
            )}
            <RemoteDataComboBox
                id={id}
                dataItemKey="id"
                textField="name"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                emptyListText="No companies found."
                renderItem={c => <CompanySimpleView company={c} />}
                fetchItems={filter =>
                    contactsService
                        .getCompanies(
                            ideaId,
                            filter ? ContactsSortBy.Alphabetical : ContactsSortBy.Recent,
                            filter ? filter : undefined,
                            undefined,
                            filter ? undefined : 5
                        )
                        .then(c => c.companies)
                }
                insertItemText={allowCreate ? 'New company' : undefined}
                onInsertItem={
                    allowCreate
                        ? text => {
                              createCompanyNameRef.current = text !== value?.name ? text || undefined : undefined;
                              setShowCreateDialog(true);
                          }
                        : undefined
                }
                onClose={onClose}
            />
        </>
    );
}

export function CompanyIdPicker({
    id,
    ideaId,
    value,
    onChange,
    placeholder,
    onClose
}: {
    id?: string;
    ideaId: string;
    value?: number;
    onChange?: (e: { value: number | undefined }) => void;
    placeholder?: string;
    onClose?: () => void;
}) {
    const [company, setCompany] = useState<Company | null>();
    const companyIdRef = useRef(company?.id);
    companyIdRef.current = company?.id;
    const requestedCompanyId = useRef<number>();
    useEffect(() => {
        if (value === companyIdRef.current) return;
        if (!value) {
            if (companyIdRef.current) setCompany(undefined);

            return;
        }

        if (value === requestedCompanyId.current) return;
        requestedCompanyId.current = value;

        contactsService.getCompanyById(ideaId, value).then(c => {
            if (c.id !== requestedCompanyId.current) return;
            setCompany(c);
        });
    }, [ideaId, value]);

    return (
        <CompanyPicker
            id={id}
            ideaId={ideaId}
            value={company}
            onChange={
                onChange
                    ? e => {
                          setCompany(e.value);
                          onChange({ value: e.value?.id });
                      }
                    : undefined
            }
            allowCreate={false}
            placeholder={placeholder}
            onClose={onClose}
        />
    );
}
