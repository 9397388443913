import { DropDownButton } from '@progress/kendo-react-buttons';
import { Input, InputHandle, InputProps, NumericTextBox, NumericTextBoxHandle, NumericTextBoxProps, TextArea, TextAreaHandle, TextAreaProps } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { Popover } from '@progress/kendo-react-tooltip';
import { ComponentType, KeyboardEvent, ReactNode, RefAttributes, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { combineClassNames } from '../../services/common';
import { domService } from '../../services/domService';
import { DivButton } from './DivButton';
import { BoundDropDownButton, DropDownButtonItem } from './boundDropDownButton';

export type InlineEditorHandle = {
    editor?: InlineEditorComponentHandle
}

export type InlineEditorProps<TValue, TEditorSettings, TViewerSettings> = {
    isEditing?: boolean;
    value?: TValue;
    onEdit?: () => any;
    onCancel?: (isExplicit: boolean) => any;
    onSave?: (isExplicit: boolean) => any | Promise<any>;
    onEditorChange?: (e: { value: TValue }) => void;
    actions?: DropDownButtonItem[],
    viewActionsWrapper?: ComponentType<{children?: React.ReactNode}>,
    errorMessage?: string;
    editor?: ComponentType<InlineEditorComponentProps<TValue, TEditorSettings> & RefAttributes<InlineEditorComponentHandle>>,
    editorSettings?: TEditorSettings,
    viewer?: ComponentType<InlineEditorViewerProps<TValue, TViewerSettings>>,
    viewerSettings?: TViewerSettings,
    autoFocus?: boolean;
    onAutoFocus?: () => void;
    editControlsClassname?: string;
    hideEditControls?: boolean;
    additionalEditControls?: ReactNode;
    disabled?: boolean,
    viewClassName?: string,
    editClassName?: string,
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    invalid?: boolean,
    allowFloatingItems?: boolean
}

export const InlineEditor = forwardRef(function<TValue, TEditorSettings, TViewerSettings>({
    isEditing,
    value,
    onEdit,
    onCancel,
    onSave,
    onEditorChange,
    actions,
    errorMessage,
    editor: Editor,
    editorSettings,
    viewer: Viewer = InlineEditorDefaultViewer<TValue, TViewerSettings>,
    viewActionsWrapper: ViewActionsWrapper,
    viewerSettings,
    autoFocus,
    onAutoFocus,
    editControlsClassname,
    hideEditControls,
    additionalEditControls,
    disabled,
    editClassName,
    viewClassName,
    onClick,
    invalid,
    allowFloatingItems
}: InlineEditorProps<TValue, TEditorSettings, TViewerSettings>, ref: React.ForwardedRef<InlineEditorHandle>) {
    const hasError = !!errorMessage;
    const editorRef = useRef<InlineEditorComponentHandle>(null);
    const editingWrapperRef = useRef<HTMLDivElement>(null);
    const viewWrapperRef = useRef<HTMLDivElement>(null);
    const hasActions = actions && actions.length > 0;
    const [saveDisabled, saveCallbackCreator] = useSingleClickButton<[isExplicit: boolean], any | Promise<any>>();
    const saveCallback = onSave ? saveCallbackCreator(onSave) : undefined;
    const editMenuDropDownButtonRef = useRef<DropDownButton>(null);

    useImperativeHandle(ref, () => ({
        get editor() {
            return editorRef.current ?? undefined;
        }
    }), [])

    const onAutoFocusRef = useRef(onAutoFocus);
    onAutoFocusRef.current = onAutoFocus;
    useEffect(() => {
        if (!isEditing || !autoFocus) return;

        //window.requestAnimationFrame(() => {
            editorRef.current?.focus?.();
            onAutoFocusRef.current?.();
        //});
    }, [isEditing, autoFocus]);

    function onEditingWrapperKeyDown(e: KeyboardEvent<HTMLDivElement>) {
        if (e.key === 'Enter') {
            e.preventDefault();
            saveCallback?.(true);
        } else if (e.key === 'Escape') {
            e.stopPropagation();
            onCancel?.(true);
        }
    }

    function onEditingWrapperBlur(e: React.FocusEvent<HTMLDivElement, Element>) {
        if (isDisabled) return;

        if (
            e.relatedTarget != null && editingWrapperRef.current && editingWrapperRef.current.contains(e.relatedTarget)) {
            return;
        }

        saveCallback?.(false);
    };

    function onViewWrapperRightClick(e: React.MouseEvent<HTMLDivElement>) {
        if (!hasActions) return;

        e.preventDefault();
        const menuButton: any = editMenuDropDownButtonRef.current;
        if (menuButton) menuButton.onClickMainButton(e);
    }

    const isDisabled = saveDisabled || disabled;
    const defaultClassInputSize = viewClassName && (viewClassName.includes("k-input-sm") || viewClassName.includes("k-input-md") || viewClassName.includes("k-input-lg")) ? undefined : 'k-input-md';
    const boundDropDownButton = <BoundDropDownButton
                                    ref={editMenuDropDownButtonRef}
                                    fillMode="flat"
                                    size="small"
                                    icon="more-horizontal"
                                    popupSettings={{
                                        anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                                        popupAlign: { horizontal: 'right', vertical: 'top' }
                                    }}
                                    items={actions}
                                    className='inline-editor-actions k-my--1px'/>

    return isEditing ? (
        <div ref={editingWrapperRef} onBlur={onEditingWrapperBlur} onKeyDown={onEditingWrapperKeyDown} className={combineClassNames(hasActions ? 'inline-editor-edit-has-actions' : editClassName)} onClick={onClick}>
            <PopupPropsContext.Provider value={p => editingWrapperRef.current && !p.appendTo && !allowFloatingItems ? ({ ...p, appendTo: editingWrapperRef.current }) : p}>
                {Editor && <Editor ref={editorRef} disabled={isDisabled} onChange={onEditorChange} valid={!hasError && !invalid} value={value} settings={editorSettings} />}
                <Popover
                    appendTo={editingWrapperRef.current}
                    anchor={editorRef.current?.element}
                    show={hasError}
                    position="right"
                    collision={{ horizontal: 'flip', vertical: 'fit' }}
                    popoverClass="k-icp-tooltip-popover k-icp-popover-error"
                    animate={false}
                    positionMode="fixed"
                >
                    {errorMessage}
                </Popover>

                {!hideEditControls && (
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className={combineClassNames("k-mt-1 k-justify-content-between k-flex-row-reverse", editControlsClassname)}>
                        <StackLayout align={{ vertical: 'middle' }} className="k-gap-1">
                            <DivButton
                                fillMode="flat"
                                size="small"
                                icon="check"
                                className="icon-button-success"
                                disabled={isDisabled || !saveCallback}
                                onClick={saveCallback && (() => saveCallback(true))}
                            />
                            <DivButton
                                fillMode="flat"
                                size="small"
                                icon="close"
                                className="icon-button-error"
                                disabled={isDisabled || !onCancel}
                                onClick={onCancel && (() => onCancel(true))}
                            />
                        </StackLayout>

                        {additionalEditControls}
                    </StackLayout>
                )}
            </PopupPropsContext.Provider>
        </div>
    ) : (
        <div ref={viewWrapperRef} onDoubleClick={onEdit} onContextMenu={hasActions ? onViewWrapperRightClick : undefined} className={combineClassNames('k-input k-rounded-md !k-overflow-visible k-icp-component-border inline-editor-view', defaultClassInputSize, onEdit || hasActions ? 'inline-editor-view-editable' : undefined, hasActions ? 'inline-editor-view-has-actions' : undefined, viewClassName) } onClick={onClick}>
            <PopupPropsContext.Provider value={p => viewWrapperRef.current && !p.appendTo ? ({ ...p, appendTo: viewWrapperRef.current }) : p}>
                <StackLayout align={{ horizontal: 'start', vertical: 'bottom' }} className={combineClassNames('k-input-inner', hasActions ? '!k-pr-thin' : undefined)}>
                    <Viewer value={value} settings={viewerSettings} />
                    {hasActions && (
                        ViewActionsWrapper ? (
                            <ViewActionsWrapper>
                                {boundDropDownButton}
                            </ViewActionsWrapper>)
                            :  boundDropDownButton
                    )}
                </StackLayout>
            </PopupPropsContext.Provider>
        </div>
    );
}) as <TValue, TEditorSettings, TViewerSettings>(props: InlineEditorProps<TValue, TEditorSettings, TViewerSettings> & React.RefAttributes<InlineEditorHandle>) => React.ReactElement | null;

export type InlineEditorComponentHandle = {
    focus?: () => void,
    element?: HTMLElement | null
}

export type InlineEditorComponentProps<TValue, TSettings = {}> = {
    value?: TValue,
    valid?: boolean,
    disabled?: boolean,
    onChange?: (e: { value: TValue}) => void,
    settings?: TSettings
}

export const InlineEditorNumericTextBox = forwardRef<InlineEditorComponentHandle, InlineEditorComponentProps<number | null, NumericTextBoxProps>>(function (props, ref) {
    const numericTextBoxRef = useRef<NumericTextBoxHandle>(null);
    useImperativeHandle(ref, () => ({
        focus() {
            const numericTextBoxDomElement = numericTextBoxRef.current;
            if (!numericTextBoxDomElement) return;

            numericTextBoxDomElement.focus();
        },
        get element() {
            return numericTextBoxRef.current?.element
        }
    }), []);

    return <NumericTextBox ref={numericTextBoxRef} {...props.settings} value={props.value} valid={props.valid} disabled={props.disabled} onChange={props.onChange}  />;
});

export const InlineEditorInput = forwardRef<InlineEditorComponentHandle, InlineEditorComponentProps<string, InputProps>>(function (props, ref) {
    const inputRef = useRef<InputHandle>(null);
    useImperativeHandle(ref, () => ({
        focus() {
            const inputDomElement = inputRef.current;
            if (!inputDomElement) return;

            inputRef.current.focus();
        },
        get element() {
            return inputRef.current?.element
        }
    }), []);

    return <Input ref={inputRef} {...props.settings} value={props.value} valid={props.valid} disabled={props.disabled} onChange={props.onChange} />;
});

export const InlineEditorTextArea = forwardRef<InlineEditorComponentHandle, InlineEditorComponentProps<string, TextAreaProps>>(function (props, ref) {
    const textAreaRef = useRef<TextAreaHandle>(null);
    useImperativeHandle(ref, () => ({
        focus() {
            const textAreaDomElement = textAreaRef.current?.element.current;
            if (!textAreaDomElement) return;

            domService.focusTextArea(textAreaDomElement);
        },
        get element() {
            return textAreaRef.current?.element.current?.parentElement
        }
    }), []);

    const addNewLineOnCurrentCaretPosition = () => {
        const textAreaDomElement = textAreaRef.current?.element.current;
        if (!textAreaDomElement) return;

        domService.textAreaAddContentOnCurrentCaretPosition(textAreaDomElement, '\n');
        props.onChange?.({ value: textAreaDomElement.value});
    };

    function onKeyDown(e: KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === 'Enter' && (e.ctrlKey || e.altKey || e.shiftKey)) {
            e.stopPropagation();
            e.preventDefault();
            addNewLineOnCurrentCaretPosition();
        }
    }

    return <TextArea ref={textAreaRef} {...props.settings} className={combineClassNames('inline-editor-padded inline-editor-text-area', props.settings?.className)} value={props.value} valid={props.valid} disabled={props.disabled} onChange={props.onChange} onKeyDown={e => {
        onKeyDown(e);
        props.settings?.onKeyDown?.(e);
    }} />
});

export type InlineEditorViewerProps<TValue, TViewerSettings> = {
    value?: TValue,
    renderValue?: (value?: TValue) => ReactNode,
    settings?: TViewerSettings
}

export function InlineEditorDefaultViewer<TValue, TViewerSettings>({ value, renderValue } : InlineEditorViewerProps<TValue, TViewerSettings>) {
    if (!value) return null;

    return <InlineEditorViewerDefaultLayout>{renderValue ? renderValue(value) : typeof value === 'string' ? value : String(value)}</InlineEditorViewerDefaultLayout>;
}

export function InlineEditorViewerDefaultLayout({ children, onClick } : { children: ReactNode, onClick?: () => void }) {
    return <div className="k-white-space-pre-wrap k-flex-1 -bw" onClick={onClick}>{children}</div>
}