import { hypothesisGroupLabelMap } from '../../components/hypotheses/common';
import { EmptyHypothesesView } from '../../components/hypotheses/emptyHypothesesView';
import { HypothesisGroup } from '../../services/hypothesesService';

export function SolutionHypothesesPage() {
    return (
        <EmptyHypothesesView
            hypothesesGroupName={hypothesisGroupLabelMap[HypothesisGroup.Solution]}
            assumptionsDescriptions="how you plan to solve the specific problems faced by your target customers"
        />
    );
}
