import { ErrorResponse, ErrorsResponse, HttpException } from '../httpServiceBase';

const decorateAndRethrowErrorWithOperation = (error: any, operationDisplayName: string) => {
    error.operationDisplayName = operationDisplayName;
    throw error;
};

function wrapWithErrorHandler<T extends (...args: any) => any>(originalFunction: T, handler: (error: any) => void): T {
    return function(this: any, ...args: Parameters<T>) {
        try {
            const result = originalFunction.apply(this, args);
            if (result instanceof Promise) return result.catch(error => handler(error));

            return result;
        } catch (error) {
            handler(error);
        }
    } as T;
}

export const processErrorWithGlobalHandler = (error: any, rethrow: boolean) => {
    const globalHandler = (window as any).globalErrorHandler;
    if (globalHandler) globalHandler(error);

    if (rethrow) throw error;
};

const WithErrorHandler = (handler: (error: any) => void) => (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    if (!descriptor || !(descriptor.value instanceof Function)) return;

    descriptor.value = wrapWithErrorHandler(descriptor.value, handler);
};

export const ErrorWithOperationDisplayName = (operationDisplayName: string) =>
    WithErrorHandler(error => decorateAndRethrowErrorWithOperation(error, operationDisplayName));

export const processWithGlobalErrorHandler = <T extends (...args: any) => any>(func: T): T =>
    wrapWithErrorHandler(func, e => processErrorWithGlobalHandler(e, true));

export async function executeWithServerErrorInToast<TResult>(
    action: () => Promise<TResult>,
    errorStatusCode = 400,
    updateToastMessage?: (message: string) => string
) {
    try {
        return await action();
    } catch (e) {
        if (e instanceof HttpException && e.status === errorStatusCode) {
            const errorData = e.parseResponse<ErrorResponse | ErrorsResponse>();
            if (errorData?.message) {
                const toastMessage = errorData.message instanceof Array ? errorData.message.join(' ') : errorData.message;
                (e as any).displayText = updateToastMessage ? updateToastMessage(toastMessage) : toastMessage;
            }
        }

        throw e;
    }
}
