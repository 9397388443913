import { useEffect, useState } from 'react';
import { immutableAddOrUpdate, immutableRemove } from '../services/common';
import { RealTimeUpdateInterviewQuoteEventData, realTimeUpdatesEventHub } from '../services/realTimeUpdatesService';
import { InterviewHypothesisQuote, InterviewQuoteType, researchService } from '../services/researchService';

export function useHypothesesInterviewQuotesDataSource(ideaId: string, hypothesisId: number) {
    const [hypothesisInterviewQuotes, setHypothesisInterviewQuotes] = useState<InterviewHypothesisQuote[] | undefined>();

    //Initial load
    useEffect(() => {
        const fetchInterviewQuotes = async (ideaId: string, hypothesisId: number) => {
            const interviewQuotes = await researchService.getInterviewQuotesForHypothesis(ideaId, hypothesisId);
            setHypothesisInterviewQuotes(interviewQuotes);
        };

        fetchInterviewQuotes(ideaId, hypothesisId);
    }, [ideaId, hypothesisId]);

    //Real-time updates
    useEffect(() => {
        const onHypothesisQuoteAddOrUpdate = async (quoteData: RealTimeUpdateInterviewQuoteEventData) => {
            if (quoteData.ideaId !== ideaId || quoteData.hypothesisId !== hypothesisId) return;

            const interviewQuote = await researchService.getInterviewQuote(ideaId, quoteData.quoteId);
            if (interviewQuote.type !== InterviewQuoteType.Hypothesis) return;

            setHypothesisInterviewQuotes(prevQuotes => {
                return immutableAddOrUpdate(prevQuotes, interviewQuote, q => q.id === quoteData.quoteId);
            });
        };

        const onHypothesisQuoteDeleted = (quoteData: RealTimeUpdateInterviewQuoteEventData) => {
            if (quoteData.ideaId !== ideaId || quoteData.hypothesisId !== hypothesisId) return;

            setHypothesisInterviewQuotes(prevQuotes => {
                return immutableRemove(prevQuotes, q => q.id !== quoteData.quoteId);
            });
        };

        realTimeUpdatesEventHub.addEventListener('interview', 'quoteAdd', onHypothesisQuoteAddOrUpdate);
        realTimeUpdatesEventHub.addEventListener('interview', 'quoteUpdate', onHypothesisQuoteAddOrUpdate);
        realTimeUpdatesEventHub.addEventListener('interview', 'quoteDelete', onHypothesisQuoteDeleted);
        realTimeUpdatesEventHub.addEventListener('interview', 'quoteRestore', onHypothesisQuoteAddOrUpdate);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview', 'quoteAdd', onHypothesisQuoteAddOrUpdate);
            realTimeUpdatesEventHub.removeEventListener('interview', 'quoteUpdate', onHypothesisQuoteAddOrUpdate);
            realTimeUpdatesEventHub.removeEventListener('interview', 'quoteDelete', onHypothesisQuoteDeleted);
            realTimeUpdatesEventHub.removeEventListener('interview', 'quoteRestore', onHypothesisQuoteAddOrUpdate);
        };
    }, [ideaId, hypothesisId]);

    return hypothesisInterviewQuotes;
}
