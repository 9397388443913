import { ContactsListViewMode, ContactsSortBy } from './contactsService';
import { SchedulesSortBy } from './schedulesService';

type ListPreferences = {
    pageSize: number;
};

export type ContactsListPreferences = ListPreferences & {
    sortBy: ContactsSortBy;
    viewMode: ContactsListViewMode;
};

export type HypothesesListPreferences = ListPreferences;
export type SchedulesListPreferences = ListPreferences & {
    sortBy: SchedulesSortBy;
};

export type ResearchListPreferences = ListPreferences;

class ListPreferencesService {
    private readonly defaultPageSize = 60;

    private readonly contactsListPreferencesStorageKey = 'contacts';
    private readonly defaultContactsListPreferences: ContactsListPreferences = {
        sortBy: ContactsSortBy.Recent,
        viewMode: ContactsListViewMode.Card,
        pageSize: this.defaultPageSize
    };

    private readonly hypothesesListPreferencesStorageKey = 'hypotheses';
    private readonly defaultHypothesesListPreferences: HypothesesListPreferences = {
        pageSize: this.defaultPageSize
    };

    private readonly schedulesListPreferencesStorageKey = 'schedules';
    private readonly defaultSchedulesListPreferences: SchedulesListPreferences = {
        sortBy: SchedulesSortBy.Recent,
        pageSize: this.defaultPageSize
    };

    private readonly researchListPreferencesStorageKey = 'research';
    private readonly defaultResearchListPreferences: ResearchListPreferences = {
        pageSize: this.defaultPageSize
    };

    private getListPreferences<TPreferences extends ListPreferences>(key: string, defaultPreferences: TPreferences): TPreferences {
        const serializedPreferences = localStorage.getItem(`icp.${key}.listPreferences`);
        if (!serializedPreferences) return defaultPreferences;
        const storedPreferences = JSON.parse(serializedPreferences);
        return { ...defaultPreferences, ...storedPreferences };
    }

    private saveListPreferences(key: string, listPreferences: ListPreferences) {
        const serializedPreferences = JSON.stringify(listPreferences);
        localStorage.setItem(`icp.${key}.listPreferences`, serializedPreferences);
    }

    getContactsListPreferences() {
        return this.getListPreferences<ContactsListPreferences>(this.contactsListPreferencesStorageKey, this.defaultContactsListPreferences);
    }

    saveContactsListPreferences({ sortBy, viewMode, pageSize }: ContactsListPreferences) {
        const clonedPreferences: ContactsListPreferences = { sortBy, viewMode, pageSize };
        this.saveListPreferences(this.contactsListPreferencesStorageKey, clonedPreferences);
    }

    getHypothesesListPreferences() {
        return this.getListPreferences<HypothesesListPreferences>(this.hypothesesListPreferencesStorageKey, this.defaultHypothesesListPreferences);
    }

    saveHypothesesListPreferences({ pageSize }: HypothesesListPreferences) {
        this.saveListPreferences(this.hypothesesListPreferencesStorageKey, { pageSize });
    }

    getSchedulesListPreferences() {
        return this.getListPreferences<SchedulesListPreferences>(this.schedulesListPreferencesStorageKey, this.defaultSchedulesListPreferences);
    }

    saveSchedulesListPreferences({ sortBy, pageSize }: SchedulesListPreferences) {
        const clonedPreferences: SchedulesListPreferences = { sortBy, pageSize };
        this.saveListPreferences(this.schedulesListPreferencesStorageKey, clonedPreferences);
    }

    getResearchListPreferences() {
        return this.getListPreferences<ResearchListPreferences>(this.researchListPreferencesStorageKey, this.defaultResearchListPreferences);
    }

    saveResearchListPreferences({ pageSize }: ResearchListPreferences) {
        this.saveListPreferences(this.researchListPreferencesStorageKey, { pageSize });
    }
}

export const listPreferencesService = new ListPreferencesService();
