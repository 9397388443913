import { StackLayout } from '@progress/kendo-react-layout';
import { Fragment, useEffect, useRef, useState } from 'react';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1, H2 } from '../../components/ui/typography';
import { useStartupLayout } from '../../hooks/startupHooks';
import { StickyHeaderObserver } from '../../scripts/stickyElements';
import { combineClassNames } from '../../services/common';
import { Term, termsService } from '../../services/termsService';

export default function GlossaryPage() {
    const [groupedTerms, setGroupedTerms] = useState<Record<string, Term[]>>();
    const { pageContainerRef, setPageContainerClassName } = useStartupLayout();
    const stickyHeaderRef = useRef<HTMLElement | null>();

    useEffect(() => {
        if (!groupedTerms) return;

        let stickyHeaderObserver: StickyHeaderObserver;
        if (stickyHeaderRef.current && pageContainerRef.current) {
            stickyHeaderObserver = new StickyHeaderObserver(
                stickyHeaderRef.current,
                pageContainerRef.current,
                'page-sticky-header-stuck',
                'k-button',
                'k-selected'
            );
        }

        return () => {
            stickyHeaderObserver?.destroy();
        };
    }, [groupedTerms, pageContainerRef]);

    useEffect(() => {
        (async () => {
            const allTerms = await termsService.getAllTerms();
            const termsGroupedByFirstLetter: Record<string, Term[]> = {};
            allTerms.forEach(t => {
                if (!t.term || t.term.length === 0) return;

                const firstLetter = t.term.charAt(0).toUpperCase();
                let letterGroup = termsGroupedByFirstLetter[firstLetter];
                if (!letterGroup) {
                    termsGroupedByFirstLetter[firstLetter] = letterGroup = [];
                }
                letterGroup.push(t);
            });

            setGroupedTerms(termsGroupedByFirstLetter);
        })();
    }, []);

    useEffect(() => {
        setPageContainerClassName('k-px-0');

        return () => setPageContainerClassName(undefined);
    }, [setPageContainerClassName]);

    const alphabet = [];
    const firstLetterCharCode = 'A'.charCodeAt(0);
    const lastLetterCharCode = 'Z'.charCodeAt(0);
    for (let i = firstLetterCharCode; i <= lastLetterCharCode; i++) {
        alphabet.push(String.fromCharCode(i));
    }

    return (
        <>
            <H1 className="heading-row !k-px-6">Glossary</H1>
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-5">
                <H2 className="!k-px-6">Only the terms you need</H2>
                <StackLayout
                    ref={r => (stickyHeaderRef.current = r?.element)}
                    align={{ horizontal: 'center', vertical: 'top' }}
                    className="k-py-3 page-sticky-header k-align-self-stretch"
                >
                    {alphabet.map(letter => (
                        <a
                            key={letter}
                            className={combineClassNames(
                                'k-button k-button-md k-button-rectangle k-button-flat k-button-flat-secondary k-rounded-md k-icon-button',
                                !groupedTerms || !groupedTerms[letter] ? 'k-disabled' : undefined
                            )}
                            href={`#group-${letter}`}
                        >
                            <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-icp-icon-size-4">
                                {letter}
                            </StackLayout>
                        </a>
                    ))}
                </StackLayout>
                {groupedTerms ? (
                    <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="page-content-middle k-px-6 k-gap-4">
                        {Object.keys(groupedTerms)
                            .sort()
                            .map((groupName, groupIndex, allGroups) => {
                                const termsInGroup = groupedTerms[groupName];
                                return (
                                    <Fragment key={groupName}>
                                        <span id={`group-${groupName}`} className="k-h1">
                                            {groupName}
                                        </span>
                                        {termsInGroup.map((term, termIndex) => (
                                            <Fragment key={term.id}>
                                                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1">
                                                    <strong className="k-fs-lg">{term.term}</strong>
                                                    <div dangerouslySetInnerHTML={{ __html: term.description }} className="formatted-content-area" />
                                                </StackLayout>
                                                {!(groupIndex === allGroups.length - 1 && termIndex === termsInGroup.length - 1) && (
                                                    <div className="full-horizontal-separator"></div>
                                                )}
                                            </Fragment>
                                        ))}
                                    </Fragment>
                                );
                            })}
                    </StackLayout>
                ) : (
                    <LoadingIndicator size="big" className="k-px-6" />
                )}
            </StackLayout>
        </>
    );
}
