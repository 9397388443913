import { Button } from '@progress/kendo-react-buttons';
import { IconWrap } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import {
    DateHeaderCell,
    DateHeaderCellProps,
    ITEMS_SELECT_ACTION,
    MonthView,
    Scheduler,
    SchedulerEditItem,
    SchedulerEditItemAction,
    SchedulerEditItemProps,
    SchedulerEditItemState,
    SchedulerEditSlot,
    SchedulerEditSlotProps,
    SchedulerFormProps,
    SchedulerHeader,
    SchedulerHeaderProps,
    SchedulerItem,
    SchedulerItemContent,
    SchedulerItemHandle,
    SchedulerItemProps,
    SchedulerRemoveDialogProps,
    SchedulerSlot,
    SchedulerSlotHandle,
    SchedulerSlotProps,
    WeekView,
    WorkWeekView,
    useSchedulerDateRangeContext,
    useSchedulerEditItemFormItemContext
} from '@progress/kendo-react-scheduler';
import { FORM_ITEM_ACTION } from '@progress/kendo-react-scheduler/dist/npm/items/hooks/use-form-item';
import { DateRange, EditableProp, SchedulerItemMouseEvent } from '@progress/kendo-react-scheduler/dist/npm/models';
import { SchedulerHandle } from '@progress/kendo-react-scheduler/dist/npm/models/SchedulerHandle';
import { Popover } from '@progress/kendo-react-tooltip';
import { caretAltLeftIcon } from '@progress/kendo-svg-icons';
import React, { CSSProperties, ComponentType, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, resolvePath, useParams } from 'react-router-dom';
import { canvasItemsZoneDependent, useRequireCanvasBoxItemsInZone } from '../../components/canvas/canvasItemsZone';
import { DateAndTimeView, DateRangeView } from '../../components/common/date';
import { PersonSimpleView } from '../../components/contacts/view';
import { DeleteMeetingDialog, completedReachOutIcon, reachOutIcon, reachOutLabel } from '../../components/events/eventsDialogs';
import { ScheduledItemDialog } from '../../components/events/scheduledItemDialog';
import { InterviewMainActionButton } from '../../components/interview/interviewMainActionButton';
import { InterviewStageView, interviewStageGuidanceRender } from '../../components/interview/interviewStage';
import { Icon } from '../../components/ui/icon';
import { TextWithShowMoreByLines } from '../../components/ui/textWithShowMoreByLines';
import { H2 } from '../../components/ui/typography';
import UserAvatar from '../../components/user/userAvatar';
import { useCanvasBoxItemWithFallback } from '../../hooks/canvasHooks';
import { useAsRef, useCopyToClipboard } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { ReactComponent as AgendaIcon } from '../../icons/agenda.svg';
import { ReactComponent as ResearchReachOutIcon } from '../../icons/bell.svg';
import { ReactComponent as InterviewGuidanceIcon } from '../../icons/book.svg';
import { ReactComponent as CalendarSlotIcon } from '../../icons/calendar-slot.svg';
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';
import { ReactComponent as CompletedResearchReachOutIcon } from '../../icons/check-circle-closed.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { ReactComponent as EyeIcon } from '../../icons/eye.svg';
import { ReactComponent as InterviewIcon } from '../../icons/interview.svg';
import { ReactComponent as EmailIcon } from '../../icons/mail.svg';
import { ReactComponent as LocationIcon } from '../../icons/map-pin-1.svg';
import { ReactComponent as DeleteIcon } from '../../icons/trash-2.svg';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import { reachOutsService } from '../../services/activitiesService';
import { ScheduledItemCreatedEventData, appEventHub } from '../../services/appEvents';
import { BoxType } from '../../services/canvasService';
import { colorHexToRGB, combineClassNames, generateInitials, getPreferredColorIndex, resolveAbsoluteUrl } from '../../services/common';
import { ReducedPerson } from '../../services/contactsService';
import { dateTimeService } from '../../services/dateTimeService';
import { ReachOutType } from '../../services/events';
import { ScheduleItem, ScheduleItemOfType, ScheduleItemType, ScheduledItem, eventsService } from '../../services/eventsService';
import {
    RealTimeUpdateMeetingEventData,
    RealTimeUpdateReachOutEventData,
    RealTimeUpdateResearchReachOutEventData,
    RealTimeUpdateScheduleEventData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';
import { researchService } from '../../services/researchService';
import {
    ReducedUser,
    UserRole,
    generateInitials as generateUserInitials,
    getPreferredColorIndex as getUserPreferredColorIndex
} from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

export const EventsPage = canvasItemsZoneDependent(function EventsPage() {
    const { ideaId } = useParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEdit = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    const schedulerRef = useRef<SchedulerHandle>(null);
    const [events, setEvents] = useState<ScheduledItem[]>();
    useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);

    const schedulerScrollToCurrentTime = useCallback(() => {
        if (!schedulerRef.current || !schedulerRef.current.element) return;

        const schedulerElement = schedulerRef.current.element;
        const dayView = schedulerElement.querySelector('.k-scheduler-day-view');
        if (!dayView || dayView.clientHeight >= dayView.scrollHeight) return;

        const currentTimeMarker = dayView.querySelector<HTMLElement>('.k-current-time');
        if (currentTimeMarker) {
            const dayViewHead = dayView.querySelector('.k-scheduler-head');
            dayView.scrollTop = currentTimeMarker.offsetTop - dayView.clientHeight / 2 + (dayViewHead?.clientHeight ?? 0);
        } else {
            const firstWorkingSlot = dayView.querySelector<HTMLElement>('.k-scheduler-body .k-scheduler-cell.k-slot-cell:not(.k-nonwork-hour)'); // first working slot
            if (firstWorkingSlot) dayView.scrollTop = firstWorkingSlot.offsetTop;
        }
    }, []);

    useEffect(() => {
        schedulerScrollToCurrentTime();
    }, [schedulerScrollToCurrentTime]);

    const realTimeUpdatesDependenciesDataRef = useRef<{ startDate?: Date; endDate?: Date; events?: ScheduledItem[] }>({});
    realTimeUpdatesDependenciesDataRef.current.events = events;
    const loadEvents = useCallback(
        (startDate: Date, endDate: Date) => {
            realTimeUpdatesDependenciesDataRef.current.startDate = startDate;
            realTimeUpdatesDependenciesDataRef.current.endDate = endDate;

            if (!ideaId) return;
            eventsService.getEvents(ideaId, startDate, endDate).then(setEvents);
        },
        [ideaId]
    );
    const reloadEvents = useCallback(() => {
        if (!realTimeUpdatesDependenciesDataRef.current.startDate || !realTimeUpdatesDependenciesDataRef.current.endDate) return;

        loadEvents(realTimeUpdatesDependenciesDataRef.current.startDate, realTimeUpdatesDependenciesDataRef.current.endDate);
    }, [loadEvents]);

    const schedulerDataSourceContextValue = useMemo<SchedulerDataSourceContextValue>(
        () => ({
            requestData(dateRange: DateRange) {
                loadEvents(dateRange.start, dateRange.end);
            }
        }),
        [loadEvents]
    );
    useEffect(() => {
        function rangeOverlapsWithCurrentRange(start: Date, end: Date) {
            const currentStart = realTimeUpdatesDependenciesDataRef.current.startDate;
            const currentEnd = realTimeUpdatesDependenciesDataRef.current.endDate;
            if (!currentStart || !currentEnd) return false;

            return end >= currentStart && start <= currentEnd;
        }

        function onEventUpserted(event: ScheduleItem) {
            const scheduledItem = new ScheduledItem(event);
            setEvents(events => (events ? [...events.filter(e => e.id !== scheduledItem.id), scheduledItem] : [scheduledItem]));
        }

        async function onReachOutUpserted(e: RealTimeUpdateReachOutEventData) {
            if (e.ideaId !== ideaId) return;
            const reachOut = await reachOutsService.getActivity(e.ideaId, e.contactId, e.reachOutId);

            onEventUpserted({ type: ScheduleItemType.ReachOut, reachOut: reachOut });
        }

        function onReachOutDeleted(e: RealTimeUpdateReachOutEventData) {
            if (e.ideaId !== ideaId) return;

            setEvents(events =>
                events ? events.filter(event => event.data.type !== ScheduleItemType.ReachOut || event.data.reachOut.id !== e.reachOutId) : events
            );
        }

        async function onMeetingUpserted(e: RealTimeUpdateMeetingEventData) {
            if (e.ideaId !== ideaId) return;

            // Always reload all events since the meeting may hide or show slots
            reloadEvents();
        }

        function onMeetingDeleted(e: RealTimeUpdateMeetingEventData) {
            if (e.ideaId !== ideaId) return;

            // Always reload all events since slots may be shown
            reloadEvents();
        }

        function hasSlotsFromSchedule(scheduleId: number): boolean {
            return (
                realTimeUpdatesDependenciesDataRef.current.events !== undefined &&
                realTimeUpdatesDependenciesDataRef.current.events.some(
                    event => event.data.type === ScheduleItemType.OpenSlot && event.data.openSlot.schedule.id === scheduleId
                )
            );
        }

        async function onScheduleUpdate(e: RealTimeUpdateScheduleEventData) {
            if (e.ideaId !== ideaId || (!hasSlotsFromSchedule(e.scheduleId) && !rangeOverlapsWithCurrentRange(e.actualStartTime, e.actualEndTime))) return;

            reloadEvents();
        }

        function onScheduleDelete(e: RealTimeUpdateScheduleEventData) {
            if (e.ideaId !== ideaId) return;

            setEvents(events =>
                events ? events.filter(event => event.data.type !== ScheduleItemType.OpenSlot || event.data.openSlot.schedule.id !== e.scheduleId) : events
            );
        }

        function onScheduleAddOrRestore(e: RealTimeUpdateScheduleEventData) {
            if (e.ideaId !== ideaId || !rangeOverlapsWithCurrentRange(e.actualStartTime, e.actualEndTime)) return;

            reloadEvents();
        }

        function onScheduledItemCreatedInTab(e: ScheduledItemCreatedEventData) {
            if (shouldReloadEventsOnItemEvent(e.item)) {
                reloadEvents();
                return;
            }

            setEvents(events => (events ? [...events, e.item] : [e.item]));
        }

        async function onResearchReachOutUpserted(e: RealTimeUpdateResearchReachOutEventData) {
            if (e.ideaId !== ideaId) return;
            const researchReachOut = await researchService.getProblemValidationResearchReachOut(e.ideaId, e.researchId, e.researchReachOutId);

            onEventUpserted({ type: ScheduleItemType.ResearchReachOut, researchReachOut: researchReachOut });
        }

        function onResearchReachOutDeleted(e: RealTimeUpdateResearchReachOutEventData) {
            if (e.ideaId !== ideaId) return;

            setEvents(events =>
                events
                    ? events.filter(event => event.data.type !== ScheduleItemType.ResearchReachOut || event.data.researchReachOut.id !== e.researchReachOutId)
                    : events
            );
        }

        realTimeUpdatesEventHub.addEventListener('contact', 'reachOutAdd', onReachOutUpserted);
        realTimeUpdatesEventHub.addEventListener('contact', 'reachOutUpdate', onReachOutUpserted);
        realTimeUpdatesEventHub.addEventListener('contact', 'reachOutDelete', onReachOutDeleted);
        realTimeUpdatesEventHub.addEventListener('contact', 'reachOutRestore', onReachOutUpserted);

        realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingAdd', onMeetingUpserted);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingUpdate', onMeetingUpserted);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingDelete', onMeetingDeleted);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'meetingRestore', onMeetingUpserted);

        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleAdd', onScheduleAddOrRestore);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleUpdate', onScheduleUpdate);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleDelete', onScheduleDelete);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleRestore', onScheduleAddOrRestore);

        appEventHub.addEventListener('scheduledItem', 'created', onScheduledItemCreatedInTab);

        realTimeUpdatesEventHub.addEventListener('research', 'reachOutAdd', onResearchReachOutUpserted);
        realTimeUpdatesEventHub.addEventListener('research', 'reachOutUpdate', onResearchReachOutUpserted);
        realTimeUpdatesEventHub.addEventListener('research', 'reachOutDelete', onResearchReachOutDeleted);
        realTimeUpdatesEventHub.addEventListener('research', 'reachOutRestore', onResearchReachOutUpserted);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('contact', 'reachOutAdd', onReachOutUpserted);
            realTimeUpdatesEventHub.removeEventListener('contact', 'reachOutUpdate', onReachOutUpserted);
            realTimeUpdatesEventHub.removeEventListener('contact', 'reachOutDelete', onReachOutDeleted);
            realTimeUpdatesEventHub.removeEventListener('contact', 'reachOutRestore', onReachOutUpserted);

            realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingAdd', onMeetingUpserted);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingUpdate', onMeetingUpserted);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingDelete', onMeetingDeleted);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'meetingRestore', onMeetingUpserted);

            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleAdd', onScheduleAddOrRestore);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleUpdate', onScheduleUpdate);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleDelete', onScheduleDelete);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleRestore', onScheduleAddOrRestore);

            appEventHub.removeEventListener('scheduledItem', 'created', onScheduledItemCreatedInTab);

            realTimeUpdatesEventHub.removeEventListener('research', 'reachOutAdd', onResearchReachOutUpserted);
            realTimeUpdatesEventHub.removeEventListener('research', 'reachOutUpdate', onResearchReachOutUpserted);
            realTimeUpdatesEventHub.removeEventListener('research', 'reachOutDelete', onResearchReachOutDeleted);
            realTimeUpdatesEventHub.removeEventListener('research', 'reachOutRestore', onResearchReachOutUpserted);
        };
    }, [ideaId, reloadEvents]);

    return (
        <SchedulerDataSourceContext.Provider value={schedulerDataSourceContextValue}>
            <Scheduler
                height={500}
                className="k-icp-scheduler-adaptive-height"
                footer={EmptyFooter}
                ref={schedulerRef}
                onViewChange={e => e.value.endsWith('week') && window.requestAnimationFrame(schedulerScrollToCurrentTime)}
                onDateChange={() => window.requestAnimationFrame(schedulerScrollToCurrentTime)}
                header={DataBoundSchedulerHeader}
                data={events}
                item={SchedulerEventItem}
                editable={canEdit}
                editItem={SchedulerEventEditItem}
                slot={SchedulerEventCreatingSlot}
                editSlot={SchedulerEventCreatingEditSlot}
                form={SchedulerEventForm}
                onDataChange={e => {
                    if (!ideaId) return;

                    // If a meeting is created, updated or deleted - refresh all events since slots may also be updated
                    const requireDataReload = [e.created, e.updated, e.deleted].some(collection => collection.some(e => shouldReloadEventsOnItemEvent(e)));
                    const asyncOperations: Promise<unknown>[] = [];
                    if (e.created.length && !requireDataReload) setEvents(events => (events ? [...events, ...e.created] : [...e.created]));

                    for (const deletedItem of e.deleted as ScheduledItem[]) {
                        asyncOperations.push(
                            eventsService
                                .deleteEvent(ideaId, deletedItem)
                                .then(
                                    requireDataReload ? undefined : () => setEvents(events => (events ? events.filter(e => e.id !== deletedItem.id) : events))
                                )
                        );
                    }
                    for (const updatedItem of e.updated) {
                        // If the item has updatedItemId set the change is triggered from the edit form
                        // and we do not have to apply any changes on the server.
                        // Otherwise the change is triggered from drag and drop or resize and we need to update the dates on the server
                        if (typeof updatedItem.updatedItemId === 'string') {
                            if (requireDataReload) continue;
                            const updatedScheduledItem: ScheduledItem = updatedItem.item;
                            setEvents(events =>
                                events ? events.map(e => (e.id === updatedItem.updatedItemId ? updatedScheduledItem : e)) : [updatedScheduledItem]
                            );
                        } else {
                            const updatedScheduledItem: ScheduledItem = updatedItem;
                            asyncOperations.push(
                                eventsService
                                    .rescheduleEvent(ideaId, updatedItem)
                                    .then(
                                        requireDataReload
                                            ? undefined
                                            : updatedEvent =>
                                                  setEvents(events =>
                                                      events ? events.map(e => (e.id === updatedScheduledItem.id ? updatedEvent : e)) : [updatedEvent]
                                                  )
                                    )
                            );
                        }
                    }

                    if (requireDataReload) Promise.all(asyncOperations).then(reloadEvents);
                }}
            >
                <WorkWeekView showWorkHours={false} workDayStart="09:00" dateHeaderCell={CurrentDayHeaderCell} />
                <WeekView showWorkHours={false} workDayStart="09:00" dateHeaderCell={CurrentDayHeaderCell} />
                <MonthView slot={CurrentDaySlot} itemsPerSlot={Number.POSITIVE_INFINITY} />
            </Scheduler>
        </SchedulerDataSourceContext.Provider>
    );
});

type ScheduledItemFromEvent = ScheduledItem | { updatedItemId: string; item: ScheduledItem };
function shouldReloadEventsOnItemEvent(item: ScheduledItemFromEvent) {
    const scheduledItem = 'updatedItemId' in item ? item.item : item;

    return scheduledItem.data.type === ScheduleItemType.Meeting;
}

export type SchedulerDataSourceContextValue = { requestData?: (dateRange: DateRange) => void };
const SchedulerDataSourceContext = createContext<SchedulerDataSourceContextValue>({});

function DataBoundSchedulerHeader(props: SchedulerHeaderProps) {
    const { requestData } = useContext(SchedulerDataSourceContext);
    const dateRange = useSchedulerDateRangeContext();
    const lastRequestDateRangeRef = useRef<DateRange | undefined>();

    useEffect(() => {
        if (!requestData) return;

        function shouldRequestDataForRange() {
            if (!lastRequestDateRangeRef.current) return true;
            if (lastRequestDateRangeRef.current.start <= dateRange.start && lastRequestDateRangeRef.current.end >= dateRange.end) return false; // The new range is a sub-range of the already requested one

            return true;
        }

        if (!shouldRequestDataForRange()) return;

        lastRequestDateRangeRef.current = dateRange;

        requestData(dateRange);
    }, [requestData, dateRange]);

    return <SchedulerHeader {...props} />;
}

function EmptyFooter() {
    return null;
}

function CurrentDayHeaderCell(props: DateHeaderCellProps) {
    return (
        <DateHeaderCell {...props} className={combineClassNames(props.className, dateTimeService.isToday(props.date) ? 'k-heading-cell-today' : undefined)} />
    );
}

function CurrentDaySlot(props: SchedulerSlotProps) {
    return (
        <SchedulerEventCreatingSlot
            {...props}
            className={combineClassNames(props.className, dateTimeService.isToday(props.start) ? 'k-slot-cell-today' : undefined)}
        />
    );
}
function emptyAction() {
    return undefined;
}
function SchedulerEventEditItem(props: SchedulerEditItemProps) {
    const originalOnClickAction = props.onClickAction;
    const onClickAction: (
        event: SchedulerItemMouseEvent,
        props: SchedulerEditItemProps,
        state: SchedulerEditItemState
    ) => SchedulerEditItemAction | SchedulerEditItemAction[] = useCallback(
        (e, props, state) => {
            if (state.selected) return { type: ITEMS_SELECT_ACTION.remove };

            return (originalOnClickAction ?? SchedulerEditItem.defaultProps?.onClickAction)?.(e, props, state);
        },
        [originalOnClickAction]
    );

    const event: ScheduledItem = props.dataItem;
    const allowedEdit =
        event.data.type === ScheduleItemType.Meeting || event.data.type === ScheduleItemType.ReachOut || event.data.type === ScheduleItemType.ResearchReachOut;
    const allowedResize = event.data.type === ScheduleItemType.Meeting;
    const allowedDrag =
        event.data.type === ScheduleItemType.Meeting ||
        event.data.type === ScheduleItemType.ReachOut ||
        (event.data.type === ScheduleItemType.ResearchReachOut && !event.data.researchReachOut.sent);
    const editable = useMemo<EditableProp | undefined>(() => {
        if (!props.editable) return undefined;

        if (props.editable === true)
            return {
                add: true,
                drag: allowedDrag,
                edit: allowedEdit,
                remove: true,
                resize: allowedResize,
                select: true
            };

        if (props.editable.edit === allowedEdit && props.editable.drag === allowedDrag && props.editable.resize === allowedResize) return props.editable;

        return { ...props.editable, edit: allowedEdit, drag: allowedDrag, resize: allowedResize };
    }, [props.editable, allowedDrag, allowedEdit, allowedResize]);

    // set dragItem to null when editable.drag is false due to bug in kendo that does not respect the property value in all cases
    // set onDoubleClickAction to emptyAction when editable.edit is false due to bug in kendo  that does not respect the property value in all cases
    return (
        <SchedulerEditItem
            {...props}
            editable={editable}
            onClickAction={onClickAction}
            onDoubleClickAction={!editable || !editable.edit ? emptyAction : props.onDoubleClickAction}
            onKeyDownAction={
                !editable || !editable.edit
                    ? (...args) => {
                          const onKeyDownAction = props.onKeyDownAction ?? SchedulerEditItem.defaultProps?.onKeyDownAction;
                          if (!onKeyDownAction) return undefined;

                          const actions = onKeyDownAction(...args);
                          if (Array.isArray(actions)) return actions.filter(a => !a || a.type !== FORM_ITEM_ACTION.set);
                          else if (actions && actions.type === FORM_ITEM_ACTION.set) return undefined;
                          return actions;
                      }
                    : props.onKeyDownAction
            }
            removeDialog={SchedulerEventRemoveDialog}
            dragItem={!editable || editable.drag ? props.dragItem : null}
        />
    );
}

function SchedulerEventItem(props: SchedulerItemProps) {
    const itemRef = useRef<SchedulerItemHandle>(null);
    const event: ScheduledItem = props.dataItem;
    const ScheduledEventItem = getScheduledEventItem(event.data.type);
    const ScheduledEventPreview = getScheduledEventPreview(event.data.type);
    const [scheduledItemToEdit] = useSchedulerEditItemFormItemContext();

    const editable = useMemo<EditableProp | undefined>(() => {
        if (!props.editable) return undefined;

        if (props.editable === true)
            return {
                add: true,
                drag: true,
                edit: true,
                remove: false, // Hard code remove to false in order to hide the 'X' button in the top right corner of the cell
                resize: true,
                select: true
            };

        if (!props.editable.remove) return props.editable;

        return { ...props.editable, remove: false };
    }, [props.editable]);

    return (
        <>
            <SchedulerItem ref={itemRef} {...props} editable={editable}>
                {!props.resizeHint && <span className="k-event-actions">{props.tail && <IconWrap name="caret-alt-left" icon={caretAltLeftIcon} />}</span>}
                {!props.resizeHint && (
                    <div className="-h100">
                        <ScheduledEventItem event={event.data as ScheduleItemOfType<ScheduleItemType>} />
                    </div>
                )}
            </SchedulerItem>
            <ScheduledEventPreview
                event={event.data as ScheduleItemOfType<ScheduleItemType>}
                anchor={itemRef.current?.element}
                show={!scheduledItemToEdit && props.selected}
                onEdit={
                    (typeof props.editable === 'object'
                      ? props.editable?.edit
                      : props.editable)
                        ? e => itemRef.current && props.onDoubleClick && props.onDoubleClick({ target: itemRef.current, syntheticEvent: e })
                        : undefined
                }
                onClose={e => itemRef.current && props.onClick && props.onClick({ target: itemRef.current, syntheticEvent: e })}
                onDelete={
                    (typeof props.editable === 'object'
                      ? props.editable?.remove
                      : props.editable)
                        ? e => itemRef.current && props.onRemoveClick && props.onRemoveClick({ target: itemRef.current, syntheticEvent: e })
                        : undefined
                }
                editable={editable}
            />
        </>
    );
}

type ScheduledEventItemProps = {
    colorHex?: string;
    icon?: ComponentType<React.SVGProps<SVGSVGElement>>;
    kendoIconName?: string;
    children?: string;
    person?: ReducedPerson;
    user?: ReducedUser | null;
    overdue?: boolean;
};
function ScheduledEventItem({ colorHex, icon, kendoIconName, children, person, user, overdue }: ScheduledEventItemProps) {
    return (
        <SchedulerItemContent
            className="k-event-template scheduled-event-container activity"
            style={colorHex ? ({ '--activity-color': colorHexToRGB(colorHex).join(', ') } as CSSProperties) : undefined}
            title={children}
        >
            <div className="scheduled-event-content">
                <div className="activity-marker k-rounded-sm">&nbsp;</div>
                <Icon kendoIconName={kendoIconName} icon={icon} className={combineClassNames('scheduled-event-icon', overdue ? 'k-text-error' : undefined)} />
                <span className="scheduled-event-text k-text-ellipsis">{children}</span>
                <div className="scheduled-event-people k-icp-avatar-compact-list k-icp-avatar-compact-list-sm k-white-space-nowrap">
                    {person && (
                        <UserAvatar
                            initials={generateInitials(2, person.firstName, person.lastName)}
                            picture={person.picture}
                            colorIndex={getPreferredColorIndex(person.id)}
                            size="small"
                        />
                    )}
                    {user && (
                        <UserAvatar
                            initials={generateUserInitials(user, 2)}
                            picture={user.picture}
                            colorIndex={getUserPreferredColorIndex(user)}
                            size="small"
                        />
                    )}
                </div>
            </div>
        </SchedulerItemContent>
    );
}

type ScheduledEventOfTypeItemProps<TEvent extends ScheduleItemType> = {
    event: ScheduleItemOfType<TEvent>;
};

function ScheduledReachOutItem({ event }: ScheduledEventOfTypeItemProps<ScheduleItemType.ReachOut>) {
    const isPast = event.reachOut.date < new Date();

    return (
        <ScheduledEventItem
            icon={isPast ? completedReachOutIcon[event.reachOut.type] : reachOutIcon[event.reachOut.type]}
            person={event.reachOut.contact}
            user={event.reachOut.user}
        >
            {getScheduledEventItemSummary(event)}
        </ScheduledEventItem>
    );
}

function ScheduledMeetingItem({ event }: ScheduledEventOfTypeItemProps<ScheduleItemType.Meeting>) {
    const meetingResearchCustomerSegment = useCanvasBoxItemWithFallback(BoxType.CustomerSegments, event.meeting.research?.customerSegmentId);
    return (
        <ScheduledEventItem icon={InterviewIcon} person={event.meeting.contact} user={event.meeting.user} colorHex={meetingResearchCustomerSegment?.colorCode}>
            {getScheduledEventItemSummary(event)}
        </ScheduledEventItem>
    );
}

function ScheduledOpenSlotItem({ event }: ScheduledEventOfTypeItemProps<ScheduleItemType.OpenSlot>) {
    const scheduleResearchCustomerSegment = useCanvasBoxItemWithFallback(
        BoxType.CustomerSegments,
        event.openSlot.schedule.relatedResearch[0]?.customerSegmentId
    );
    return (
        <ScheduledEventItem icon={CalendarSlotIcon} user={event.openSlot.schedule.user} colorHex={scheduleResearchCustomerSegment?.colorCode}>
            {getScheduledEventItemSummary(event)}
        </ScheduledEventItem>
    );
}

function ScheduledResearchReachOutItem({ event }: ScheduledEventOfTypeItemProps<ScheduleItemType.ResearchReachOut>) {
    const isOverdue = !event.researchReachOut.sent && event.researchReachOut.date < new Date();
    const researchReachOutCustomerSegment = useCanvasBoxItemWithFallback(BoxType.CustomerSegments, event.researchReachOut.research.customerSegmentId);

    return (
        <ScheduledEventItem
            icon={event.researchReachOut.sent ? CompletedResearchReachOutIcon : ResearchReachOutIcon}
            person={event.researchReachOut.contact}
            user={event.researchReachOut.user}
            overdue={isOverdue}
            colorHex={researchReachOutCustomerSegment?.colorCode}
        >
            {getScheduledEventItemSummary(event)}
        </ScheduledEventItem>
    );
}

function getScheduledEventItemSummary(event: ScheduleItem) {
    switch (event.type) {
        case ScheduleItemType.ReachOut:
            const isPast = event.reachOut.date < new Date();
            return `${isPast ? 'Reached' : 'Reach'} out to ${event.reachOut.contact.firstName} ${event.reachOut.contact.lastName}${
                event.reachOut.type !== ReachOutType.Other ? ` via ${reachOutLabel[event.reachOut.type]}` : ''
            }`;
        case ScheduleItemType.Meeting:
            return `Meeting with ${event.meeting.contact.firstName} ${event.meeting.contact.lastName}`;
        case ScheduleItemType.OpenSlot:
            return `Slot for ${event.openSlot.schedule.title}`;
        case ScheduleItemType.ResearchReachOut:
            return `${event.researchReachOut.sent ? 'Invited' : 'Invite'} ${event.researchReachOut.contact.firstName} ${
                event.researchReachOut.contact.lastName
            } for ${event.researchReachOut.research.title}`;
    }
}

function getScheduledEventItem<TEvent extends ScheduleItemType>(eventType: TEvent): ComponentType<ScheduledEventOfTypeItemProps<TEvent>> {
    switch (eventType) {
        case ScheduleItemType.ReachOut:
            return ScheduledReachOutItem as any;
        case ScheduleItemType.Meeting:
            return ScheduledMeetingItem as any;
        case ScheduleItemType.OpenSlot:
            return ScheduledOpenSlotItem as any;
        case ScheduleItemType.ResearchReachOut:
            return ScheduledResearchReachOutItem as any;
        default:
            throw new Error(`Unknown event type: ${eventType}`);
    }
}

type EventPreviewProps = {
    anchor?: HTMLElement | null;
    show?: boolean;
    title?: string;
    onEdit?: (e: React.MouseEvent) => void;
    onClose?: (e: React.MouseEvent) => void;
    onDelete?: (e: React.MouseEvent) => void;
    person?: ReducedPerson;
    children?: ReactNode;
    header?: ReactNode;
    titleContent?: ReactNode;
    editIcon?: ComponentType<React.SVGProps<SVGSVGElement>>;
    personAdditionalContent?: ReactNode;
    topContent?: ReactNode;
    editable?: boolean | EditableProp;
};
function EventPreview({
    anchor,
    show,
    title,
    onEdit,
    onClose,
    onDelete,
    person,
    children,
    header,
    titleContent,
    editIcon,
    personAdditionalContent,
    topContent
}: EventPreviewProps) {
    const EditIconComponent = editIcon ?? EditIcon;
    return (
        <Popover
            style={{ width: 480 }}
            className="k-icp-no-padding-popover"
            anchor={anchor}
            show={show}
            title={
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3 k-justify-content-between">
                    <H2 className="!k-font-medium k-flex-1">{title}</H2>
                    {titleContent}
                    {(onEdit || onDelete) && (
                        <div>
                            {onEdit && (
                                <Button
                                    type="button"
                                    className="k-icp-svg-icon-button"
                                    onClick={e => {
                                        onClose?.(e);
                                        onEdit(e);
                                    }}
                                    fillMode="flat"
                                >
                                    <EditIconComponent className="k-icp-icon" />
                                </Button>
                            )}
                            {onDelete && (
                                <Button
                                    type="button"
                                    className="k-icp-svg-icon-button"
                                    onClick={e => {
                                        onClose?.(e);
                                        onDelete?.(e);
                                    }}
                                    fillMode="flat"
                                >
                                    <DeleteIcon className="k-icp-icon" />
                                </Button>
                            )}
                        </div>
                    )}
                    <Button type="button" icon="close" onClick={onClose} fillMode="flat" />
                </StackLayout>
            }
            callout={false}
            collision={{ horizontal: 'flip', vertical: 'fit' }}
            position="right"
            margin={{ horizontal: 12, vertical: 0 }}
        >
            {(person || header) && (
                <div className="k-p-4 k-icp-panel-base">
                    {person && <PersonSimpleView person={person} showJobTitle={false} renderAsLink={true} additionalContent={personAdditionalContent} />}
                    {header}
                </div>
            )}
            {topContent && (
                <StackLayout
                    orientation="vertical"
                    align={{ horizontal: 'start', vertical: 'top' }}
                    className="k-gap-4 k-p-4 k-border-b k-border-b-solid k-icp-component-border"
                >
                    {topContent}
                </StackLayout>
            )}
            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4 k-p-4">
                {children}
            </StackLayout>
        </Popover>
    );
}

function EventDetail({
    kendoIconName,
    icon,
    children,
    className
}: {
    kendoIconName?: string;
    icon?: ComponentType<React.SVGProps<SVGSVGElement>>;
    children?: ReactNode;
    className?: string;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className={combineClassNames('k-gap-7', className)}>
            <Icon kendoIconName={kendoIconName} icon={icon} className="k-mt-thin k-flex-shrink-0" />
            {children}
        </StackLayout>
    );
}

function EventUserDetails({ user, label }: { user: ReducedUser; label?: string }) {
    return (
        <EventDetail icon={UserIcon}>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                {label && <span className="k-icp-subtle-text">{label}</span>}
                <span>
                    {user.firstName} {user.lastName}
                </span>
            </StackLayout>
        </EventDetail>
    );
}

type EventOfTypePreviewProps<TEvent extends ScheduleItemType> = Omit<EventPreviewProps, 'title' | 'person'> & {
    event: ScheduleItemOfType<TEvent>;
};

function ReachOutEventPreview({ event, ...eventPreviewProps }: EventOfTypePreviewProps<ScheduleItemType.ReachOut>) {
    return (
        <EventPreview {...eventPreviewProps} title="Reach out" person={event.reachOut.contact}>
            <EventDetail icon={CalendarIcon}>
                <DateAndTimeView date={event.reachOut.date} />
            </EventDetail>
            <EventDetail icon={AgendaIcon}>
                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1">
                    <div>{event.reachOut.topic}</div>
                    {event.reachOut.details && <div className="max-lines-3">{event.reachOut.details}</div>}
                </StackLayout>
            </EventDetail>
            <EventUserDetails user={event.reachOut.user} label="Assigned to:" />
        </EventPreview>
    );
}

function MeetingEventPreview({ event, ...eventPreviewProps }: EventOfTypePreviewProps<ScheduleItemType.Meeting>) {
    const meeting = event.meeting;
    const { ideaId } = useParams();
    return (
        <EventPreview
            {...eventPreviewProps}
            title="Meeting"
            person={meeting.contact}
            personAdditionalContent={meeting.interview ? <InterviewStageView stage={meeting.interview.stage} /> : undefined}
            topContent={
                meeting.interview ? (
                    <EventDetail icon={InterviewGuidanceIcon}>
                        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                            <TextWithShowMoreByLines lines={3}>{interviewStageGuidanceRender(meeting.interview.stage)}</TextWithShowMoreByLines>
                            <InterviewMainActionButton ideaId={ideaId!} interviewId={meeting.interview.id} interviewStage={meeting.interview.stage} />
                        </StackLayout>
                    </EventDetail>
                ) : (
                    undefined
                )
            }
        >
            <EventDetail icon={EmailIcon}>{meeting.toEmailAddress}</EventDetail>
            <EventDetail icon={AgendaIcon}>
                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1">
                    <div>{meeting.title}</div>
                    {meeting.description && <div className="max-lines-3">{meeting.description}</div>}
                </StackLayout>
            </EventDetail>
            <EventDetail icon={CalendarIcon}>
                <DateRangeView start={meeting.startTime} end={meeting.endTime} isAllDay={meeting.allDay} />
            </EventDetail>
            {meeting.location && <EventDetail icon={LocationIcon}>{meeting.location}</EventDetail>}
            <EventUserDetails user={meeting.user} label="Host:" />
        </EventPreview>
    );
}

function OpenSlotEventPreview({ event, ...eventPreviewProps }: EventOfTypePreviewProps<ScheduleItemType.OpenSlot>) {
    const schedule = event.openSlot.schedule;
    const copyToClipboard = useCopyToClipboard();
    const bookingPagePath = `/bookings/${schedule.code}`;
    const [bookingTheSlot, setBookingTheSlot] = useState<boolean>();
    const { ideaId } = useParams();

    return (
        <>
            <EventPreview
                {...eventPreviewProps}
                title="Meeting slot"
                titleContent={
                    (eventPreviewProps.editable === true || (typeof eventPreviewProps.editable === 'object' && eventPreviewProps.editable.add)) && (
                        <Button
                            type="button"
                            fillMode="flat"
                            themeColor="secondary"
                            size="small"
                            onClick={e => {
                                eventPreviewProps.onClose?.(e);
                                setBookingTheSlot(true);
                            }}
                        >
                            Book slot
                        </Button>
                    )
                }
                header={
                    <>
                        <div className="k-fs-sm">This meeting slot is part of schedule</div>
                        <strong className="k-fs-lg">{schedule.title}</strong>
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-mt-1 k-icp-component-border">
                            <Link
                                to={bookingPagePath}
                                className="k-button k-button-sm k-button-link k-button-link-secondary k-rounded-md"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Open booking page
                            </Link>
                            <div className="k-separator" />
                            <Button
                                size="small"
                                fillMode="link"
                                themeColor="secondary"
                                onClick={() => {
                                    const absoluteBookingPagePath = resolvePath(bookingPagePath).pathname;
                                    const absoluteBookingPageUrl = resolveAbsoluteUrl(absoluteBookingPagePath);
                                    copyToClipboard(absoluteBookingPageUrl, 'Booking page link');
                                }}
                            >
                                Copy link to booking page
                            </Button>
                            <div className="k-separator" />
                            <Link to={`../schedules/${schedule.id}`} className="k-button k-button-sm k-button-link k-button-link-secondary k-rounded-md">
                                Open schedule
                            </Link>
                        </StackLayout>
                    </>
                }
            >
                <EventDetail icon={CalendarIcon}>
                    <DateRangeView start={event.openSlot.slot.startTime} end={event.openSlot.slot.endTime} />
                </EventDetail>
                <EventUserDetails user={schedule.user} label="Host:" />
            </EventPreview>
            {bookingTheSlot && (
                <ScheduledItemDialog
                    ideaId={ideaId!}
                    type={ScheduleItemType.Meeting}
                    initialData={{ start: event.openSlot.slot.startTime, end: event.openSlot.slot.endTime }}
                    newItemAdditionalProps={{ initialResearchId: schedule.relatedResearch.length ? schedule.relatedResearch[0].id : null }}
                    onSubmit={newInterview => {
                        setBookingTheSlot(undefined);
                        appEventHub.trigger('scheduledItem', 'created', { item: newInterview });
                    }}
                    onCancel={() => setBookingTheSlot(undefined)}
                />
            )}
        </>
    );
}

function ResearchReachOutEventPreview({ event, ...eventPreviewProps }: EventOfTypePreviewProps<ScheduleItemType.ResearchReachOut>) {
    const isOverdue = !event.researchReachOut.sent && event.researchReachOut.date < new Date();

    return (
        <EventPreview
            {...eventPreviewProps}
            title="Invite"
            person={event.researchReachOut.contact}
            editIcon={event.researchReachOut.sent ? EyeIcon : undefined}
        >
            <EventDetail icon={InterviewIcon}>{event.researchReachOut.research.title}</EventDetail>
            <EventDetail icon={CalendarIcon}>
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                    <DateAndTimeView date={event.researchReachOut.date} />
                    {isOverdue && (
                        <>
                            <span className="dot-separator" />
                            <span className="k-text-uppercase k-text-error k-fs-sm">Overdue</span>
                        </>
                    )}
                </StackLayout>
            </EventDetail>
            {event.researchReachOut.user && <EventUserDetails user={event.researchReachOut.user} label="Assigned to:" />}
        </EventPreview>
    );
}

function getScheduledEventPreview<TEvent extends ScheduleItemType>(eventType: TEvent): ComponentType<EventOfTypePreviewProps<TEvent>> {
    switch (eventType) {
        case ScheduleItemType.ReachOut:
            return ReachOutEventPreview as any;
        case ScheduleItemType.Meeting:
            return MeetingEventPreview as any;
        case ScheduleItemType.OpenSlot:
            return OpenSlotEventPreview as any;
        case ScheduleItemType.ResearchReachOut:
            return ResearchReachOutEventPreview as any;
        default:
            throw new Error(`Unknown event type: ${eventType}`);
    }
}

type NewScheduledItem = { type: ScheduleItemType; start: Date; end?: Date; newItemAdditionalProps?: Partial<Record<string, any>> };
export type SchedulerEventCreationContextValue = { canCreate: boolean; setCanCreate: (value: boolean) => void; onCreate: (newItem: NewScheduledItem) => void };
const SchedulerEventCreationContext = createContext<SchedulerEventCreationContextValue>({ canCreate: false, setCanCreate() {}, onCreate() {} });

function SchedulerEventCreatingEditSlot(props: SchedulerEditSlotProps) {
    const [formItem, setFormItem] = React.useState<any>(null);
    const [canCreateItem, setCanCreateItem] = useState(false);

    const handleFormItemChange = React.useCallback<(e: any) => void>(e => {
        setCanCreateItem(!!e.value);
        if (!e.value) setFormItem(null);
    }, []);

    const schedulerEventCreationContextValue = useMemo<SchedulerEventCreationContextValue>(
        () => ({
            canCreate: canCreateItem,
            setCanCreate: setCanCreateItem,
            onCreate(newItem) {
                setCanCreateItem(false);
                setFormItem(newItem);
            }
        }),
        [canCreateItem]
    );

    return (
        <SchedulerEventCreationContext.Provider value={schedulerEventCreationContextValue}>
            <SchedulerEditSlot {...props} formItem={formItem} onFormItemChange={handleFormItemChange} />
        </SchedulerEventCreationContext.Provider>
    );
}

function SchedulerEventCreatingSlot(props: SchedulerSlotProps) {
    const slotRef = useRef<SchedulerSlotHandle>(null);
    const schedulerEventCreationContextValue = useContext(SchedulerEventCreationContext);

    useEffect(() => {
        if (!props.selected && schedulerEventCreationContextValue.canCreate) {
            schedulerEventCreationContextValue.setCanCreate(false);
        }
    }, [props.selected, schedulerEventCreationContextValue, schedulerEventCreationContextValue.canCreate]);

    function getEventRangeForSlot() {
        if (props.isAllDay) {
            const currentTime = new Date();
            const start = new Date(props.start);
            start.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds(), currentTime.getMilliseconds());
            const end = new Date(start);
            dateTimeService.addHours(end, 1);

            return { start, end };
        }

        return { start: props.start, end: props.end };
    }

    return (
        <>
            <SchedulerSlot ref={slotRef} {...props} />
            <Popup
                anchor={slotRef.current?.element}
                show={schedulerEventCreationContextValue.canCreate}
                anchorAlign={{ horizontal: 'right', vertical: 'top' }}
                popupAlign={{ horizontal: 'left', vertical: 'top' }}
                collision={{ horizontal: 'flip', vertical: 'fit' }}
                popupClass="k-list k-list-md"
            >
                <div
                    className="k-list-item"
                    onClick={() => {
                        const eventRange = getEventRangeForSlot();
                        schedulerEventCreationContextValue.onCreate({ type: ScheduleItemType.Meeting, start: eventRange.start, end: eventRange.end });
                    }}
                >
                    New meeting
                </div>
                <div
                    className="k-list-item"
                    onClick={() => {
                        const eventRange = getEventRangeForSlot();
                        schedulerEventCreationContextValue.onCreate({
                            type: ScheduleItemType.Meeting,
                            start: eventRange.start,
                            end: eventRange.end,
                            newItemAdditionalProps: { initialResearchId: null }
                        });
                    }}
                >
                    New interview
                </div>
                <div
                    className="k-list-item"
                    onClick={() => {
                        const eventRange = getEventRangeForSlot();
                        schedulerEventCreationContextValue.onCreate({ type: ScheduleItemType.ReachOut, start: eventRange.start });
                    }}
                >
                    New reach out
                </div>
            </Popup>
        </>
    );
}

function SchedulerEventForm(props: SchedulerFormProps) {
    const scheduledItem: ScheduledItem | undefined = props.dataItem && props.dataItem.id && props.dataItem.data ? props.dataItem : undefined;
    const newItemData: NewScheduledItem | undefined = props.dataItem && props.dataItem.type ? props.dataItem : undefined;
    const { ideaId } = useParams();

    if (!scheduledItem && !newItemData) return null;

    return ideaId ? (
        <ScheduledItemDialog
            ideaId={ideaId}
            type={newItemData ? newItemData.type : undefined}
            initialData={
                newItemData
                    ? newItemData.type === ScheduleItemType.Meeting
                        ? { start: newItemData.start, end: newItemData.end }
                        : newItemData.type === ScheduleItemType.ReachOut
                        ? { start: newItemData.start }
                        : undefined
                    : undefined
            }
            newItemAdditionalProps={newItemData?.newItemAdditionalProps}
            scheduledItem={scheduledItem}
            onSubmit={item =>
                props.onSubmit({
                    value: scheduledItem ? { updatedItemId: scheduledItem.id, item: item } : item,
                    target: { props: props },
                    syntheticEvent: {} as React.SyntheticEvent<any>
                })
            }
            onCancel={() => props.onCancel?.({ value: null, target: { props: props }, syntheticEvent: {} as React.SyntheticEvent<any> })}
        />
    ) : null;
}

function SchedulerEventRemoveDialog(props: SchedulerRemoveDialogProps) {
    const { show, element } = useConfirmDialog();
    const showRef = useRef(show);
    showRef.current = show;
    const propsRef = useRef(props);
    propsRef.current = props;

    const scheduledItem: ScheduledItem = props.dataItem;
    const useDefaultDialog = scheduledItem.data.type !== ScheduleItemType.Meeting;

    function triggerOnConfirm() {
        props.onConfirm?.({ value: scheduledItem, target: { props: props }, syntheticEvent: {} as React.SyntheticEvent<any> });
    }

    function triggerOnCancel() {
        props.onCancel?.({ value: null, target: { props: props }, syntheticEvent: {} as React.SyntheticEvent<any> });
    }

    const triggerOnConfirmRef = useAsRef(triggerOnConfirm);
    const triggerOnCancelRef = useAsRef(triggerOnCancel);

    useEffect(() => {
        if (!useDefaultDialog) return;
        showRef.current({
            title: 'Delete event',
            content: (
                <>
                    Are you sure you want to delete <strong>“{getScheduledEventItemSummary(scheduledItem.data)}”</strong> event?
                </>
            ),
            confirmButtonText: 'Delete event',
            callback: triggerOnConfirmRef.current,
            onCancel: triggerOnCancelRef.current
        });
    }, [scheduledItem, triggerOnCancelRef, triggerOnConfirmRef, useDefaultDialog]);

    if (!useDefaultDialog) {
        const eventType = scheduledItem.data.type;
        if (eventType === ScheduleItemType.Meeting)
            return <DeleteMeetingDialog meeting={scheduledItem.data.meeting} onDelete={triggerOnConfirm} onCancel={triggerOnCancel} />;
        else throw new Error('Unsupported custom delete dialog for event: ', eventType);
    }

    return element ?? null;
}
