import { useEffect } from 'react';
import { useCanvasBoxItemsInZone } from '../components/canvas/canvasItemsZone';
import { DeletionRelatedEntriesNotice, RelatedEntryData } from '../components/common/deletionRelatedEntriesNotice';
import { BoxItem, BoxType, CanvasBox, canvasService } from '../services/canvasService';
import { Awaitable } from '../services/common';
import { clearCanvas, deleteItemWithUndo, loadCanvasByIdeaId } from '../state/canvas/canvasSlice';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { useConfirmDialog } from './dialogHooks';

export const useGlobalCanvas = (ideaId?: string, suppressRelatableItems?: boolean) => {
    const canvas = useAppSelector(s => s.canvas);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!ideaId) return;

        const loadCanvasPromise = dispatch(loadCanvasByIdeaId(ideaId));

        return () => {
            loadCanvasPromise.abort();
            dispatch(clearCanvas());
        };
    }, [ideaId, dispatch]);

    const relatableItems = suppressRelatableItems
        ? undefined
        : canvas.boxes?.reduce<BoxItem[]>((allItems, box) => {
              allItems.push(...box.items.filter(i => i.colorCode));
              return allItems;
          }, []);
    if (relatableItems) relatableItems.sort((i1, i2) => i1.id - i2.id);

    return { canvas, relatableItems };
};

export function useCanvasBoxItemsWithFallback(box: BoxType): BoxItem[] | undefined {
    const { canvas } = useGlobalCanvas(undefined, true);
    const canvasBoxItemsInZone = useCanvasBoxItemsInZone(box);

    return canvas.boxes?.find(b => b.type === box)?.items ?? canvasBoxItemsInZone;
}

export function useCanvasBoxItemWithFallback(box: BoxType, itemId?: number): BoxItem | undefined {
    const boxItems = useCanvasBoxItemsWithFallback(box);

    return itemId === undefined ? undefined : boxItems?.find(i => i.id === itemId);
}

export function findCanvasItem(boxes: CanvasBox[] | undefined, boxType: BoxType, itemId: number | undefined): BoxItem | undefined {
    if (!boxes || itemId === undefined) return undefined;

    const box = boxes.find(b => b.type === boxType);
    if (!box) return undefined;

    const item = box.items.find(i => i.id === itemId);
    return item;
}

export function useDeleteItem() {
    const { show: showConfirmDeletionDialog, element: confirmDeletionDialog } = useConfirmDialog();
    const { canvas } = useGlobalCanvas(undefined, true);
    const dispatch = useAppDispatch();

    function deleteItemWithoutConfirmation(boxType: BoxType, itemId: number, onRestored?: () => void) {
        return dispatch(
            deleteItemWithUndo(
                boxType,
                itemId,
                {
                    content: 'Item deleted.',
                    actionText: 'Undo'
                },
                onRestored
            )
        );
    }

    async function deleteItem(boxType: BoxType, itemId: number, onRestored?: () => void): Promise<boolean> {
        if (canvas.ideaId === undefined) return false;

        const itemStats = await canvasService.getItemStats(canvas.ideaId, boxType, itemId);
        const itemRelatedEntriesData: RelatedEntryData[] = [
            {
                count: itemStats.hypothesisCount,
                entryName: 'hypothesis',
                pluralEntryName: 'hypotheses'
            },
            {
                count: itemStats.insightCount,
                entryName: 'insight'
            },
            {
                count: itemStats.researchCount,
                entryName: 'research',
                pluralEntryName: 'research'
            }
        ];

        if (itemRelatedEntriesData.some(r => r.count)) {
            const itemToDelete = findCanvasItem(canvas.boxes, boxType, itemId);
            const boxMetadata = canvasService.getBoxMetadata(boxType);
            const deleteItemAwaitable = new Awaitable<boolean>();
            showConfirmDeletionDialog({
                title: `Delete ${boxMetadata.itemName}`,
                content: (
                    <>
                        <div>Are you sure you want to delete {itemToDelete ? <strong>“{itemToDelete.content}”</strong> : ` this ${boxMetadata.itemName}`}?</div>
                        <DeletionRelatedEntriesNotice entryName={boxMetadata.itemName} relationsData={itemRelatedEntriesData} />
                    </>
                ),
                confirmCheckboxText: 'I acknowledge that this cannot be undone',
                confirmButtonText: `Delete ${boxMetadata.itemName}`,
                callback: async () => {
                    await deleteItemWithoutConfirmation(boxType, itemId, onRestored);
                    deleteItemAwaitable.resolve(true);
                },
                onCancel: () => deleteItemAwaitable.resolve(false)
            });

            return deleteItemAwaitable.promise;
        } else {
            await deleteItemWithoutConfirmation(boxType, itemId, onRestored);
            return true;
        }
    }

    return [deleteItem, confirmDeletionDialog] as const;
}
