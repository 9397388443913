import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popover } from '@progress/kendo-react-tooltip';
import { Fragment, ReactNode, createContext, useContext, useRef } from 'react';
import { useSoleToggle } from '../../hooks/toggleHooks';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as MultiplyIcon } from '../../icons/x.svg';
import { SubscriptionType } from '../../services/authenticationService';
import { combineClassNames } from '../../services/common';
import { Price, PriceDiscount, PricingData } from '../../services/stripeBillingService';
import { ValidatedInputLayoutProps } from '../ui/inputs';
import { RangeView, RangeViewStop } from '../ui/rangeView';
import { TriangleArrow } from '../ui/triangleArrow';
import {
    VolumeDiscountDefinition,
    getApplicableVolumeDiscount,
    getCurrencySymbol,
    getDiscountedPriceAmount,
    getPriceAmountForSubscriptionType,
    getVolumeDiscountedPriceAmount,
    stringifyPriceAmount,
    volumeDiscounts
} from './billingDataUtils';

export type SubscriptionInfoContextValue = {
    pricing?: PricingData;
    subscriptionType?: SubscriptionType;
    seatsCount?: number;
    currentPrice?: Price;
    currentPriceCurrencySymbol?: string;
    volumeDiscount?: VolumeDiscountDefinition;
    volumeDiscountedPriceAmount?: number;
    appliedDiscount?: PriceDiscount;
    discountedPriceAmount?: number;
    monthlyPriceAmount?: number;
    totalPriceAmount?: number;
};
const SubscriptionInfoContext = createContext<SubscriptionInfoContextValue>({});

export function useSubscriptionInfo() {
    return useContext(SubscriptionInfoContext);
}

export function SubscriptionInfoZone({
    pricing,
    discount,
    subscriptionType,
    seatsCount,
    children
}: {
    pricing?: PricingData;
    discount?: PriceDiscount;
    subscriptionType?: SubscriptionType;
    seatsCount?: number;
    children?: ReactNode;
}) {
    const currentPrice = subscriptionType && pricing ? (subscriptionType === SubscriptionType.Monthly ? pricing.monthlyPrice : pricing.annualPrice) : undefined;
    const currentPriceCurrencySymbol = currentPrice && getCurrencySymbol(currentPrice.currency);

    const volumeDiscount = getApplicableVolumeDiscount(seatsCount);
    const volumeDiscountedPriceAmount = currentPrice && getVolumeDiscountedPriceAmount(currentPrice.amount, volumeDiscount);

    const appliedDiscount = discount ?? pricing?.discount;
    const discountedPriceAmount =
        volumeDiscountedPriceAmount === undefined ? undefined : getDiscountedPriceAmount(volumeDiscountedPriceAmount, appliedDiscount);

    const monthlyPriceAmount =
        discountedPriceAmount === undefined ? undefined : getPriceAmountForSubscriptionType(discountedPriceAmount, subscriptionType!, SubscriptionType.Monthly);
    const totalPriceAmount = discountedPriceAmount === undefined ? undefined : discountedPriceAmount * (seatsCount ?? 0);

    const subscriptionData: SubscriptionInfoContextValue = {
        pricing,
        subscriptionType,
        seatsCount,
        currentPrice,
        currentPriceCurrencySymbol,
        volumeDiscount,
        volumeDiscountedPriceAmount,
        appliedDiscount,
        discountedPriceAmount,
        monthlyPriceAmount,
        totalPriceAmount
    };

    return <SubscriptionInfoContext.Provider value={subscriptionData}>{children}</SubscriptionInfoContext.Provider>;
}

export function SubscriptionInfoBox({ children, bottomContent }: { children?: ReactNode; bottomContent?: ReactNode }) {
    const {
        subscriptionType,
        appliedDiscount,
        monthlyPriceAmount,
        currentPriceCurrencySymbol,
        volumeDiscountedPriceAmount,
        discountedPriceAmount,
        totalPriceAmount
    } = useSubscriptionInfo();

    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'center', vertical: 'top' }}
            className="k-rounded-lg k-border k-border-solid k-border-secondary k-pt-10 k-px-10 k-pb-4 k-pos-relative k-overflow-hidden -w1"
        >
            {appliedDiscount && <BoxDiscountLabel discount={appliedDiscount} />}
            <StackLayout align={{ horizontal: 'start', vertical: 'bottom' }} className="k-gap-2" style={{ letterSpacing: -2 }}>
                {monthlyPriceAmount === undefined ? (
                    <Skeleton shape="rectangle" style={{ width: 96, height: 56 }} />
                ) : (
                    <>
                        <span style={{ fontSize: 48, lineHeight: '56px' }}>
                            {currentPriceCurrencySymbol}
                            {stringifyPriceAmount(monthlyPriceAmount)}
                        </span>
                        {appliedDiscount && volumeDiscountedPriceAmount !== undefined && (
                            <span style={{ lineHeight: '40px' }}>
                                <span
                                    style={{ fontSize: 24, lineHeight: '28px' }}
                                    className="k-d-inline-block k-align-middle k-icp-placeholder-text oblique-strike-through oblique-strike-through-bold"
                                >
                                    {currentPriceCurrencySymbol}
                                    {stringifyPriceAmount(
                                        getPriceAmountForSubscriptionType(volumeDiscountedPriceAmount, subscriptionType!, SubscriptionType.Monthly)
                                    )}
                                </span>
                            </span>
                        )}
                    </>
                )}
            </StackLayout>
            <div className="k-fs-sm k-icp-subtle-text k-mb-6">
                per seat/month
                {subscriptionType === SubscriptionType.Annual && discountedPriceAmount !== undefined
                    ? `, ${currentPriceCurrencySymbol}${stringifyPriceAmount(discountedPriceAmount)} billed yearly`
                    : undefined}
            </div>
            {children}
            <div className="k-separator k-mb-4 k-icp-component-border k-pos-relative">
                <TriangleArrow baseFirstPart={6.5} baseSecondPart={6.5} height={8} fill="white" borderFill="inherit" className="k-pos-top-center" showHinge />
            </div>

            <StackLayout
                align={{ horizontal: 'center', vertical: 'middle' }}
                className={combineClassNames('k-gap-1 k-fs-lg', bottomContent ? 'k-mb-1' : undefined)}
            >
                <span>Total due today:</span>
                <strong>
                    {totalPriceAmount === undefined ? (
                        <Skeleton shape="text" className="!k-display-inline-block" style={{ width: 33 }} />
                    ) : (
                        `${currentPriceCurrencySymbol}${stringifyPriceAmount(totalPriceAmount)}`
                    )}
                </strong>
                <PriceInfoButton />
            </StackLayout>

            {bottomContent}
        </StackLayout>
    );
}

function BoxDiscountLabel({ discount }: { discount: PriceDiscount }) {
    return (
        <div
            className="k-pb-1 k-pt-thin k-icp-bg-secondary-12 k-text-secondary k-pos-absolute"
            style={{ transform: 'rotate(-30deg)', transformOrigin: 'bottom left', top: 55, left: 0, width: 203 }}
            title={discount.name}
        >
            <strong className="k-fs-lg" style={{ marginRight: 44 }}>
                {discount.percentOff
                    ? `${discount.percentOff}% OFF`
                    : discount.amountOff && discount.amountOffCurrency
                    ? `${getCurrencySymbol(discount.amountOffCurrency)}${discount.amountOff} OFF`
                    : undefined}
            </strong>
            <div className="k-icp-text-smaller k-mt--0.5 k-text-ellipsis k-pl-3 k-pr-10">{discount.name}</div>
        </div>
    );
}

function VolumeDiscountLabel({ percentage, className }: { percentage: number; className?: string }) {
    return (
        <div className={combineClassNames('k-rounded-sm k-bg-secondary k-fs-xs k-font-bold k-text-white k-text-uppercase -px-0.75 k-pos-relative', className)}>
            <TriangleArrow
                baseFirstPart={3.5}
                baseSecondPart={3.5}
                height={5}
                direction="left"
                fill="secondary"
                borderWidth={0}
                className="k-pos-middle-start"
                style={{ transform: 'translateX(-100%) translateY(-50%)' }}
            />
            {percentage}% off
        </div>
    );
}

function SubscriptionInfoButton({ title, children, className }: { title: string; children?: ReactNode; className?: string }) {
    const [isOpen, toggleIsOpen] = useSoleToggle(false);
    const toggleButtonRef = useRef<Button>(null);

    return (
        <>
            <Button
                ref={toggleButtonRef}
                type="button"
                size="small"
                fillMode="flat"
                className={combineClassNames('k-icp-svg-icon-button', className)}
                togglable
                selected={isOpen}
                onClick={
                    isOpen
                        ? undefined
                        : e => {
                              toggleIsOpen();
                              e.stopPropagation();
                          }
                }
            >
                <InfoIcon className="k-icp-icon" />
            </Button>
            <Popover
                anchor={toggleButtonRef.current?.element}
                show={isOpen}
                position="right"
                collision={{ horizontal: 'flip', vertical: 'fit' }}
                positionMode="fixed"
                className="k-icp-no-padding-popover"
                style={{ width: 200 }}
            >
                <div className="k-p-2" onClick={e => e.stopPropagation()}>
                    <strong className="k-d-block k-mb-3">{title}</strong>
                    {children}
                </div>
            </Popover>
        </>
    );
}

export function DiscountsInfoButton({ className }: { className?: string }) {
    return (
        <SubscriptionInfoButton title="Discount per seats" className={className}>
            <div className="k-display-grid k-gap-x-2 k-gap-y-3 k-py-2" style={{ gridTemplateColumns: '1fr auto' }}>
                {volumeDiscounts.map(volumeDiscount => {
                    const hasUpperBound = volumeDiscount.maxSeats !== undefined;
                    const discountRangeViewStops: RangeViewStop[] = [
                        {
                            label: String(volumeDiscount.minSeats),
                            labelSuffix: hasUpperBound ? undefined : '+',
                            percentage: (volumeDiscount.minSeats * 100) / 14
                        }
                    ];

                    if (hasUpperBound)
                        discountRangeViewStops.push({
                            label: String(volumeDiscount.maxSeats),
                            percentage: (volumeDiscount.maxSeats! * 100) / 14
                        });
                    return (
                        <Fragment key={volumeDiscount.discountPercentage}>
                            <RangeView stops={discountRangeViewStops} activeRangesIndices={[1]} />
                            <VolumeDiscountLabel percentage={volumeDiscount.discountPercentage} className="k-justify-self-start" />
                        </Fragment>
                    );
                })}
            </div>
        </SubscriptionInfoButton>
    );
}

export function PriceInfoButton() {
    const {
        pricing,
        subscriptionType,
        seatsCount,
        currentPriceCurrencySymbol,
        volumeDiscount,
        appliedDiscount,
        monthlyPriceAmount,
        totalPriceAmount
    } = useSubscriptionInfo();

    const appliedDiscounts: string[] = [];
    if (appliedDiscount) appliedDiscounts.push(appliedDiscount.name);
    if (subscriptionType === SubscriptionType.Annual) appliedDiscounts.push('early subscription');
    if (volumeDiscount) appliedDiscounts.push('multiple seats');

    const oneMonthPriceAmount = pricing?.monthlyPrice.amount;
    const subscriptionPeriodInMonths = subscriptionType && subscriptionType === SubscriptionType.Monthly ? 1 : 12;
    const totalSaving =
        oneMonthPriceAmount !== undefined && subscriptionPeriodInMonths !== undefined && totalPriceAmount !== undefined && seatsCount !== undefined
            ? oneMonthPriceAmount * subscriptionPeriodInMonths * seatsCount - totalPriceAmount
            : undefined;

    return (
        <SubscriptionInfoButton title="Price breakdown">
            <div
                className="k-display-grid k-gap-x-2 k-gap-y-1 k-justify-content-start k-align-items-center k-fs-sm k-mb-3"
                style={{ gridTemplateColumns: 'repeat(5, auto)' }}
            >
                <div className="k-text-disabled k-fs-xs">Price</div>
                <div className="k-text-disabled k-fs-xs k-col-start-3">Period</div>
                <div className="k-text-disabled k-fs-xs k-col-start-5">Seats</div>

                <div>
                    {monthlyPriceAmount === undefined ? (
                        <Skeleton shape="text" style={{ width: 25 }} />
                    ) : (
                        `${currentPriceCurrencySymbol}${stringifyPriceAmount(monthlyPriceAmount)}`
                    )}
                </div>
                <div>
                    <MultiplyIcon className="k-icp-icon k-icp-icon-size-3 k-text-disabled" />
                </div>
                <div>
                    {subscriptionPeriodInMonths === undefined ? (
                        <Skeleton shape="text" style={{ width: 60 }} />
                    ) : (
                        `${subscriptionPeriodInMonths} month${subscriptionPeriodInMonths === 1 ? '' : 's'}`
                    )}
                </div>
                <div>
                    <MultiplyIcon className="k-icp-icon k-icp-icon-size-3 k-text-disabled" />
                </div>
                <div>{seatsCount ?? 0}</div>
            </div>
            <div className="k-separator k-mb-4 k-icp-component-border k-pos-relative k-mb-4">
                <TriangleArrow baseFirstPart={6.5} baseSecondPart={6.5} height={8} fill="white" borderFill="inherit" className="k-pos-top-center" showHinge />
            </div>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1 k-mb-1">
                <span className="k-fs-sm">Total due today:</span>
                <strong>
                    {totalPriceAmount === undefined ? (
                        <Skeleton shape="text" style={{ width: 30 }} />
                    ) : (
                        `${currentPriceCurrencySymbol}${stringifyPriceAmount(totalPriceAmount)}`
                    )}
                </strong>
            </StackLayout>
            {totalSaving && (
                <div className="k-icp-text-smaller k-text-disabled">
                    You save {currentPriceCurrencySymbol}
                    {stringifyPriceAmount(totalSaving)}
                    {appliedDiscounts.length > 0 && (
                        <>
                            {' '}
                            with{' '}
                            {appliedDiscounts.reduce(
                                (accumulatedDiscounts, discountText, discountIndex, allDiscounts) =>
                                    `${accumulatedDiscounts}${discountIndex === allDiscounts.length - 1 ? ' and' : ','} ${discountText}`
                            )}
                        </>
                    )}
                    .
                </div>
            )}
        </SubscriptionInfoButton>
    );
}

export function DiscountsInputLayout({ label, input, infoElement, className }: ValidatedInputLayoutProps) {
    return (
        <StackLayout align={{ horizontal: 'center', vertical: 'top' }} className={combineClassNames('k-gap-4', className)}>
            <div className="-mt-5px">{label}</div>
            <div>
                {input}
                {infoElement}
            </div>
            <DiscountsInfoButton className="k-mt-1" />
        </StackLayout>
    );
}
