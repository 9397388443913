import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { lazy, ReactNode, Suspense, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { StripeCardPickerHandle } from '../../components/billing/stripe';
import { H1 } from '../../components/ui/typography';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { useBillingInfo, usePaymentSetupIntent } from '../../hooks/subscriptionHooks';
import { stripeBillingService } from '../../services/stripeBillingService';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
const StripeCardPicker = lazy(() => import('../../components/billing/stripe').then(m => ({ default: m.StripeCardPicker })));
const StripeZone = lazy(() => import('../../components/billing/stripe').then(m => ({ default: m.StripeZone })));

export const UpdatePaymentPage = () => {
    const [paymentSetupIntent, resetPaymentSetupIntent, cancelPaymentSetupIntent] = usePaymentSetupIntent(true);
    const [btnDisabled, submitAction] = useSingleClickButton();

    const { billingInfo } = useBillingInfo();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const cardPickerRef = useRef<StripeCardPickerHandle>(null);

    const updatePaymentInfo = async () => {
        if (!paymentSetupIntent) return;
        const cardSetupConfirmed = await cardPickerRef.current?.confirmSetup(billingInfo?.country ?? undefined, billingInfo?.postalCode ?? undefined);
        if (cardSetupConfirmed === false) return;
        await stripeBillingService.updatePaymentMethod(paymentSetupIntent.id);
        navigate('..');
    };

    return (
        <div className="page-content-section">
            <H1 className="heading-row">Update payment method</H1>
            <div className="page-content-middle">
                <Suspense fallback={<PaymentCardLoader />}>
                    {paymentSetupIntent && billingInfo ? (
                        <StripeZone clientSecret={paymentSetupIntent.clientSecret}>
                            <StripeCardPicker
                                showCountryPicker={!billingInfo.country}
                                ref={cardPickerRef}
                                onInvalidIntent={() =>
                                    dispatch(
                                        addNotification(
                                            {
                                                content: 'Your payment method confirmation session has expired.',
                                                type: 'error',
                                                actionText: 'Reset session',
                                                timeout: 0,
                                                removeCloseButton: true
                                            },
                                            resetPaymentSetupIntent,
                                            undefined,
                                            true
                                        )
                                    )
                                }
                                render={(children, isIntentInUnexpectedState, errorMessage) => (
                                    <StackLayout orientation="vertical" className="k-gap-6">
                                        <SubscribeFormPanel errorMessage={isIntentInUnexpectedState ? undefined : errorMessage}>{children}</SubscribeFormPanel>
                                        <StackLayout className="k-gap-6" align={{ horizontal: 'center' }}>
                                            <Button
                                                size="large"
                                                themeColor="primary"
                                                onClick={submitAction(updatePaymentInfo)}
                                                disabled={isIntentInUnexpectedState || btnDisabled}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                size="large"
                                                fillMode="flat"
                                                onClick={() => {
                                                    cancelPaymentSetupIntent();
                                                    navigate('..');
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </StackLayout>
                                    </StackLayout>
                                )}
                            />
                        </StripeZone>
                    ) : (
                        <PaymentCardLoader />
                    )}
                </Suspense>
            </div>
        </div>
    );
};

function PaymentCardLoader() {
    return (
        <SubscribeFormPanel>
            <Skeleton shape="rectangle" style={{ width: '100%', height: 32 }} />
            <StackLayout className="k-gap-2">
                <Skeleton shape="rectangle" style={{ height: 32 }} />
                <Skeleton shape="rectangle" style={{ height: 32 }} />
            </StackLayout>
        </SubscribeFormPanel>
    );
}

function SubscribeFormPanel({ children, errorMessage }: { children?: ReactNode; outline?: boolean; errorMessage?: string }) {
    return (
        <div className="k-px-8 k-pb-8">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className={'k-gap-6'}>
                {children}
            </StackLayout>
            {errorMessage && <span className="k-display-block k-text-error k-mt-8">{errorMessage}</span>}
        </div>
    );
}
