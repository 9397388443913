import { Button } from '@progress/kendo-react-buttons';
import questionIllustrationSrc from '../../images/question-illustration.svg';
import { NoTitleModal } from '../common/modal';

export const RegenerateScriptModal = ({
    btnsDisabled,
    regenerateScriptAction,
    onClose
}: {
    btnsDisabled?: boolean;
    regenerateScriptAction: () => Promise<void>;
    onClose: () => void;
}) => {
    return (
        <NoTitleModal
            btnsDisabled={btnsDisabled}
            noCloseIcon
            data={{
                graphic: {
                    url: questionIllustrationSrc,
                    width: 86,
                    height: 86,
                    description: ''
                },
                heading: 'Generate new interview script',
                description:
                    'Do you want to use the updated Job-to-be-Done to generate an entirely new interview script? Generating a new script will result in the loss of all changes and additions made in the current one.',
                mainButtonText: 'Generate new interview script',
                cancelButtonText: 'Keep current script'
            }}
            mainButton={
                <Button type="button" themeColor="secondary" fillMode="outline" disabled={btnsDisabled} onClick={regenerateScriptAction}>
                    Generate new interview script
                </Button>
            }
            onMainAction={regenerateScriptAction}
            onClose={onClose}
        />
    );
};
