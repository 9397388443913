import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { useSingleClickButton } from '../../hooks/commonHooks';
import inviteToPlatformIllustrationUrl from '../../images/invite-to-platform-illustration.svg';
import { googleTagManager } from '../../scripts/googleTagManager';
import { HttpException } from '../../services/httpServiceBase';
import { platformService } from '../../services/platformService';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import { ValidatedInput, defaultValidators } from '../ui/inputs';
import { P } from '../ui/typography';

export function InviteToPlatformModal({ onClose }: { onClose?: () => void }) {
    const [inviteDisabled, inviteSingleClickCallbackCreator] = useSingleClickButton();
    const [inviteErrorMessage, setInviteErrorMessage] = useState<string>();
    const dispatch = useAppDispatch();

    return (
        <Dialog onClose={onClose} title="Invite to icanpreneur platform" width={560}>
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-8">
                <img src={inviteToPlatformIllustrationUrl} width="250" height="168" alt="Invite to platform" />

                <Form
                    onSubmit={inviteSingleClickCallbackCreator(async (data: Record<string, any>) => {
                        const emailAddress = data.emailAddress;

                        try {
                            await platformService.inviteNewUser(emailAddress);
                            googleTagManager.reportUserInvitedEvent('platform');
                            dispatch(addNotification({ content: `Invitation was sent to ${emailAddress}` }));
                            onClose?.();
                        } catch (e) {
                            if (e instanceof HttpException && e.status === 409) setInviteErrorMessage('The user is already a member of the platform');
                            else throw e;
                        }
                    })}
                    ignoreModified={true}
                    render={formRenderProps => (
                        <FormElement noValidate={true} className="k-pb-4">
                            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-4">
                                <P>Invite a friend to join the icanpreneur platform</P>

                                <Field
                                    name="emailAddress"
                                    type="email"
                                    component={ValidatedInput}
                                    validator={defaultValidators.emailValidators}
                                    placeholder="Email"
                                    wrapperClass="k-align-self-stretch"
                                    errorMessage={inviteErrorMessage}
                                    onChange={() => setInviteErrorMessage(undefined)}
                                />

                                <Button disabled={!formRenderProps.allowSubmit || inviteDisabled} type="submit" themeColor="primary" size="large">
                                    Send invite
                                </Button>
                            </StackLayout>
                        </FormElement>
                    )}
                />
            </StackLayout>
        </Dialog>
    );
}
