export type TimelineNodeCoordinates = { x: number; y: number };
export type JourneyNodesCoordinates = (TimelineNodeCoordinates | TimelineNodeCoordinates[])[];
export enum TimelineNodeType {
    Default = 'Default',
    Start = 'Start',
    Disabled = 'Disabled'
}
export type TimelineSimpleNode = { type: TimelineNodeType; isVisitable: boolean };
export type TimelineVariableNode = { groupKey: string | number; variationsTypes: TimelineNodeType[] };
export type TimelineNode = TimelineSimpleNode | TimelineVariableNode;
type NodeTravelData = { isTraveled: boolean; isTravelTip: boolean; isCurrentlyTraveled: boolean };
type PathTravelData = { isTraveled: boolean; isCurrentlyTraveled: boolean };
type JourneyTravelData = (NodeTravelData | NodeTravelData[])[];
export type NodeLocation = number | { nodeIndex: number; variationIndex: number };

const emptyLineDash: Iterable<number> = [];
type DrawingStyle = { strokeWidth: number; strokeColor: string; fillColor: string; lineDash: Iterable<number> };
export class JourneyPhaseForkedTimelineDrawer {
    private readonly turnRadius = 35;
    private readonly xEdgeOffset = 12;
    private readonly variableNodesXOffset = 50;
    private readonly boundaryVariableNodesYOffset = 50;
    private readonly variableNodesYDistance = 140;
    private readonly arrowWidth = 10;
    private readonly arrowHeight = 8;
    private readonly arrowTurnOffset = 15;
    private readonly arrowVariableNodeOffset = 39;
    private readonly nodePointRadius = 4;
    private readonly nodeBorderWidth = 1;
    private readonly pathWidth = 1;
    private readonly parallelPathsMinDistance = 70;

    private readonly horizontalHiddenZoneXOffset = 70;
    private readonly horizontalHiddenZoneHeight = 24;
    private readonly verticalHiddenZoneYOffset = 40;
    private readonly verticalHiddenZoneXSpread = 12;
    private readonly verticalHiddenZoneHeight = 60;

    private readonly pathColor = '#8F8F8F';
    private readonly currentProgressPathColor = '#D6E3F1';
    private readonly traveledPathColor = '#0053A6';
    private readonly nodeBorderColor = '#00000029';
    private readonly nodeColor;
    private readonly disabledNodeColor = '#EBEBEB';
    private readonly startNodeColor = '#DAC972';
    private readonly startNodeBorderColor = '#1D1D1B80';

    private readonly defaultPathStyle: DrawingStyle = {
        strokeWidth: this.pathWidth,
        strokeColor: this.pathColor,
        fillColor: this.pathColor,
        lineDash: [4, 4]
    };
    private readonly clearDefaultPathStyle: DrawingStyle = {
        strokeWidth: this.pathWidth,
        strokeColor: this.pathColor,
        fillColor: this.pathColor,
        lineDash: emptyLineDash
    };
    private readonly traveledPathStyle: DrawingStyle = {
        strokeWidth: this.pathWidth,
        strokeColor: this.traveledPathColor,
        fillColor: this.traveledPathColor,
        lineDash: emptyLineDash
    };
    private readonly currentPathStyle: DrawingStyle = {
        strokeWidth: 7,
        strokeColor: this.currentProgressPathColor,
        fillColor: this.currentProgressPathColor,
        lineDash: emptyLineDash
    };

    private readonly defaultArrowStyle: DrawingStyle = {
        strokeWidth: 0,
        strokeColor: this.pathColor,
        fillColor: this.pathColor,
        lineDash: emptyLineDash
    };
    private readonly traveledArrowStyle: DrawingStyle = {
        strokeWidth: 0,
        strokeColor: this.traveledPathColor,
        fillColor: this.traveledPathColor,
        lineDash: emptyLineDash
    };

    private readonly defaultNodeStyle: DrawingStyle;
    private readonly startNodeStyles: DrawingStyle = {
        strokeWidth: this.nodeBorderWidth,
        strokeColor: this.startNodeBorderColor,
        fillColor: this.startNodeColor,
        lineDash: emptyLineDash
    };
    private readonly disabledNodeStyles: DrawingStyle = {
        strokeWidth: this.nodeBorderWidth,
        strokeColor: this.nodeBorderColor,
        fillColor: this.disabledNodeColor,
        lineDash: emptyLineDash
    };
    private readonly maxProgressOuterNodeStyles: DrawingStyle = {
        strokeWidth: this.nodeBorderWidth,
        strokeColor: this.nodeBorderColor,
        fillColor: this.traveledPathColor,
        lineDash: emptyLineDash
    };
    private readonly maxProgressNodeStyles: DrawingStyle = {
        strokeWidth: this.nodeBorderWidth,
        strokeColor: '#FFFFFF',
        fillColor: this.traveledPathColor,
        lineDash: emptyLineDash
    };

    private readonly journeyEndTravelData: NodeTravelData = {
        isCurrentlyTraveled: false,
        isTraveled: false,
        isTravelTip: false
    };

    private renderingContext!: CanvasRenderingContext2D;
    private readonly virtualActions: Function[];

    constructor(
        private readonly canvas: HTMLCanvasElement,
        private readonly nodes: TimelineNode[],
        private readonly currentNodeLocation?: NodeLocation,
        private readonly hiddenZonesNodeIndices?: number[],
        private readonly traveledEntirePath?: boolean
    ) {
        if (this.nodes.length < 2) throw new Error('Minimum number of nodes is 2');
        this.virtualActions = [];

        const canvasStyle = window.getComputedStyle(this.canvas);
        this.nodeColor = canvasStyle.getPropertyValue('--journey-phase-color');
        this.defaultNodeStyle = {
            strokeWidth: this.nodeBorderWidth,
            strokeColor: this.nodeBorderColor,
            fillColor: this.nodeColor,
            lineDash: emptyLineDash
        };

        const renderingContext = this.canvas.getContext('2d');
        if (!renderingContext) throw new Error('Canvas 2d context not supported.');
        this.renderingContext = renderingContext;
    }

    public draw() {
        const travelData = this.generateTravelData();

        this.clearCanvas();
        this.resetState();
        this.updateCanvasWidth();

        const [ nodesCoordinates, pathEndCoordinates] = this.virtualDrawJourneyPath(travelData);

        this.setCanvasHeight(Math.abs(pathEndCoordinates.y));
        this.applyVirtualDrawingActions();

        this.drawNodes(nodesCoordinates, travelData);
        this.drawHiddenZones(nodesCoordinates, pathEndCoordinates)

        return nodesCoordinates.map(c => c instanceof Array ? c.map(c => ({...c, y: c.y - pathEndCoordinates.y})) : {...c, y: c.y - pathEndCoordinates.y});
    }

    private virtualDrawJourneyPath(travelData: JourneyTravelData) : [JourneyNodesCoordinates, TimelineNodeCoordinates] {
        let currentNodeCoordinates: TimelineNodeCoordinates = { x: this.canvas.clientWidth / 2, y: -(this.nodePointRadius + this.nodeBorderWidth) };

        let doRightTurn = true;
        const nodesCoordinates: JourneyNodesCoordinates = [];
        for (let nodeIndex = 0; nodeIndex < this.nodes.length; nodeIndex++) {
            const currentNode = this.nodes[nodeIndex];
            const nextNodeIndex = this.getNextNodeIndex(nodeIndex);
            const nextNode = nextNodeIndex !== undefined ? this.nodes[nextNodeIndex] : undefined;
            const isCurrentNodeSimple = this.isSimpleNode(currentNode)
            const drawCurrentNodeAsSimple = isCurrentNodeSimple || currentNode.variationsTypes.length <= 1;
            const drawNextNodeAsSimple = !nextNode || this.isSimpleNode(nextNode) || nextNode.variationsTypes.length <= 1;
            if (drawCurrentNodeAsSimple) {
                nodesCoordinates.push(isCurrentNodeSimple ? currentNodeCoordinates : [currentNodeCoordinates]);
                if (drawNextNodeAsSimple) currentNodeCoordinates = this.connectSimpleNodes(currentNodeCoordinates, nodeIndex, nextNodeIndex, doRightTurn, travelData);
                else currentNodeCoordinates = this.connectSimpleNodeWithVariableNode(currentNodeCoordinates, nodeIndex, nextNodeIndex!, doRightTurn, travelData);
            } else {
                let variationsCoordinates: TimelineNodeCoordinates[];
                if (drawNextNodeAsSimple)
                    [variationsCoordinates, currentNodeCoordinates] = this.connectVariableNodeWithSimpleNode(
                        currentNodeCoordinates,
                        nodeIndex,
                        nextNodeIndex,
                        doRightTurn,
                        travelData
                    );
                else if (currentNode.groupKey === nextNode.groupKey)
                    [variationsCoordinates, currentNodeCoordinates] = this.connectVariableNodesWithinTheSameGroup(
                        currentNodeCoordinates,
                        nodeIndex,
                        nextNodeIndex!,
                        doRightTurn,
                        travelData
                    );

                else
                    [variationsCoordinates, currentNodeCoordinates] = this.connectVariableNodesFromDifferentGroups(
                        currentNodeCoordinates,
                        nodeIndex,
                        nextNodeIndex!,
                        doRightTurn,
                        travelData
                    );

                nodesCoordinates.push(variationsCoordinates);
            }

            if (drawCurrentNodeAsSimple || drawNextNodeAsSimple) doRightTurn = !doRightTurn;
        }
        return [ nodesCoordinates, currentNodeCoordinates ];
    }

    private connectSimpleNodes(
        currentNodeCoordinates: TimelineNodeCoordinates,
        currentNodeIndex: number,
        nextNodeIndex: number | undefined,
        doRightTurn: boolean,
        travelData: JourneyTravelData
    ): TimelineNodeCoordinates {
        const nextNodeCoordinates = this.getNextSimpleNodeCoordinates(currentNodeCoordinates.y, doRightTurn, nextNodeIndex === undefined);
        const pathTravelData = this.getPathTravelData(currentNodeIndex, nextNodeIndex, travelData);
        this.virtualAction(
            this.drawSimplePathBetweenPoints,
            currentNodeCoordinates.x,
            currentNodeCoordinates.y,
            nextNodeCoordinates.x,
            nextNodeCoordinates.y,
            false,
            pathTravelData,
            false
        );
        this.virtualDrawArrowAfterNodeTurn(currentNodeCoordinates.x, currentNodeCoordinates.y, doRightTurn ? 'right' : 'left', pathTravelData.isTraveled);

        return { x: nextNodeCoordinates.x, y: nextNodeCoordinates.y };
    }

    private getNextSimpleNodeCoordinates(currentPointY: number, doRightTurn: boolean, isEnd: boolean): TimelineNodeCoordinates {
        const endNodeY = currentPointY - 2 * this.turnRadius;
        if (isEnd) return { x: this.canvas.clientWidth / 2, y: endNodeY };
        else return { x: doRightTurn ? this.canvas.clientWidth - this.xEdgeOffset : this.xEdgeOffset, y: endNodeY };
    }

    private connectSimpleNodeWithVariableNode(
        currentNodeCoordinates: TimelineNodeCoordinates,
        currentNodeIndex: number,
        nextNodeIndex: number,
        doRightTurn: boolean,
        travelData: JourneyTravelData
    ): TimelineNodeCoordinates {
        const nextNode = this.getVariableNode(nextNodeIndex);
        if (!nextNode.variationsTypes.length) throw new Error('Variations cannot be empty');
        const variableNodeCoordinates = this.getVariableNodeAfterSimpleNodeCoordinates(currentNodeCoordinates.y, doRightTurn, nextNode.variationsTypes.length);
        const drawingActionsQueue = new OverlappingPathDrawingActionsOrderedQueue();
        let hasTraveledPath = false;
        for (let variationIndex = 0; variationIndex < variableNodeCoordinates.length; variationIndex++) {
            const variableNodePosition = variableNodeCoordinates[variationIndex];
            const pathTravelData = this.getPathTravelData(currentNodeIndex, { nodeIndex: nextNodeIndex, variationIndex: variationIndex }, travelData);
            drawingActionsQueue.enqueueAction(pathTravelData, () =>
                this.virtualAction(
                    this.drawSimplePathBetweenPoints,
                    currentNodeCoordinates.x,
                    currentNodeCoordinates.y,
                    variableNodePosition.x,
                    variableNodePosition.y,
                    false,
                    pathTravelData,
                    true
                )
            );

            hasTraveledPath ||= pathTravelData.isTraveled;
        }
        drawingActionsQueue.applyOrderedActions();

        this.virtualDrawArrowAfterNodeTurn(currentNodeCoordinates.x, currentNodeCoordinates.y, doRightTurn ? 'right' : 'left', hasTraveledPath);

        return { x: variableNodeCoordinates[0].x, y: variableNodeCoordinates[0].y };
    }

    private getVariableNodeAfterSimpleNodeCoordinates(simpleNodeY: number, doRightTurn: boolean, numberOfVariations: number): TimelineNodeCoordinates[] {
        const firstVariationPosition = this.getNextSimpleNodeCoordinates(simpleNodeY, doRightTurn, false);
        firstVariationPosition.y -= this.boundaryVariableNodesYOffset;

        return this.getNodeVariationsCoordinates(firstVariationPosition.x, firstVariationPosition.y, doRightTurn, numberOfVariations);
    }

    private getNodeVariationsCoordinates(firstVariationX: number, firstVariationY: number, alignRight: boolean, numberOfVariations: number) {
        const variationsXOffset = this.xEdgeOffset + 2 * this.turnRadius + this.variableNodesXOffset;
        const maxVariationX = alignRight ? variationsXOffset : this.canvas.clientWidth - variationsXOffset;
        const variationsNodesXDistance = (maxVariationX - firstVariationX) / (numberOfVariations > 1 ? numberOfVariations - 1 : 1);
        const variationCoordinates: TimelineNodeCoordinates[] = [];
        let currentVariationX = firstVariationX;
        for (let variationIndex = 0; variationIndex < numberOfVariations; variationIndex++) {
            variationCoordinates.push({ x: currentVariationX, y: firstVariationY });
            currentVariationX += variationsNodesXDistance;
        }

        return variationCoordinates;
    }

    private connectVariableNodeWithSimpleNode(
        currentNodeCoordinates: TimelineNodeCoordinates,
        currentNodeIndex: number,
        nextNodeIndex: number | undefined,
        doRightTurn: boolean,
        travelData: JourneyTravelData
    ): [TimelineNodeCoordinates[], TimelineNodeCoordinates] {
        const currentNode = this.getVariableNode(currentNodeIndex);
        if (!currentNode.variationsTypes.length) throw new Error('Variations cannot be empty');
        const nextNodeCoordinates = this.getNextSimpleNodeCoordinates(currentNodeCoordinates.y, doRightTurn, nextNodeIndex === undefined);
        nextNodeCoordinates.y -= this.boundaryVariableNodesYOffset;

        const variableNodeCoordinates = this.getNodeVariationsCoordinates(
            currentNodeCoordinates.x,
            currentNodeCoordinates.y,
            !doRightTurn,
            currentNode.variationsTypes.length
        );
        const drawingActionsQueue = new OverlappingPathDrawingActionsOrderedQueue();
        let isFirstVariationPathTraveled = false;
        for (let variationIndex = 0; variationIndex < variableNodeCoordinates.length; variationIndex++) {
            const variableNodePosition = variableNodeCoordinates[variationIndex];
            const pathTravelData = this.getPathTravelData({ nodeIndex: currentNodeIndex, variationIndex: variationIndex }, nextNodeIndex, travelData);
            drawingActionsQueue.enqueueAction(pathTravelData, () =>
                this.virtualAction(
                    this.drawSimplePathBetweenPoints,
                    variableNodePosition.x,
                    variableNodePosition.y,
                    nextNodeCoordinates.x,
                    nextNodeCoordinates.y,
                    true,
                    pathTravelData,
                    true
                )
            );

            if (variationIndex === 0) isFirstVariationPathTraveled = pathTravelData.isTraveled;
        }
        drawingActionsQueue.applyOrderedActions();

        this.virtualDrawArrowAfterNodeTurn(
            currentNodeCoordinates.x,
            currentNodeCoordinates.y - this.boundaryVariableNodesYOffset,
            doRightTurn ? 'right' : 'left',
            isFirstVariationPathTraveled
        );

        return [variableNodeCoordinates, { x: nextNodeCoordinates.x, y: nextNodeCoordinates.y }];
    }

    private connectVariableNodesWithinTheSameGroup(
        currentNodeCoordinates: TimelineNodeCoordinates,
        currentNodeIndex: number,
        nextNodeIndex: number,
        doRightTurn: boolean,
        travelData: JourneyTravelData
    ): [TimelineNodeCoordinates[], TimelineNodeCoordinates] {
        const firstNode = this.getVariableNode(currentNodeIndex);
        const secondNode = this.getVariableNode(nextNodeIndex);
        if (firstNode.variationsTypes.length === 0 || firstNode.variationsTypes.length !== secondNode.variationsTypes.length)
            throw new Error(`Invalid number of nodes variations in groups: ${firstNode.variationsTypes.length} ${secondNode.variationsTypes.length}`);

        const variableNodeCoordinates = this.getNodeVariationsCoordinates(
            currentNodeCoordinates.x,
            currentNodeCoordinates.y,
            !doRightTurn,
            firstNode.variationsTypes.length
        );
        const nextVariableNodeCoordinates = variableNodeCoordinates.map<TimelineNodeCoordinates>(c => ({
            x: c.x,
            y: c.y - this.variableNodesYDistance
        }));
        for (let variableNodeIndex = 0; variableNodeIndex < variableNodeCoordinates.length; variableNodeIndex++) {
            const startVariableNode = variableNodeCoordinates[variableNodeIndex];
            const endVariableNode = nextVariableNodeCoordinates[variableNodeIndex];
            const pathTravelData = this.getPathTravelData(
                { nodeIndex: currentNodeIndex, variationIndex: variableNodeIndex },
                { nodeIndex: nextNodeIndex, variationIndex: variableNodeIndex },
                travelData
            );
            this.virtualAction(
                this.drawSimplePathBetweenPoints,
                startVariableNode.x,
                startVariableNode.y,
                endVariableNode.x,
                endVariableNode.y,
                false,
                pathTravelData,
                false
            );
            this.virtualDrawArrowAfterVariableNode(startVariableNode.x, startVariableNode.y, pathTravelData.isTraveled);
        }

        return [variableNodeCoordinates, { x: nextVariableNodeCoordinates[0].x, y: nextVariableNodeCoordinates[0].y }];
    }

    private connectVariableNodesFromDifferentGroups(
        currentNodeCoordinates: TimelineNodeCoordinates,
        currentNodeIndex: number,
        nextNodeIndex: number,
        doRightTurn: boolean,
        travelData: JourneyTravelData
    ): [TimelineNodeCoordinates[], TimelineNodeCoordinates] {
        const firstNode = this.getVariableNode(currentNodeIndex);
        const secondNode = this.getVariableNode(nextNodeIndex);
        if (firstNode.variationsTypes.length === 0 || secondNode.variationsTypes.length === 0)
            throw new Error(`Invalid number of nodes variations in groups: ${firstNode.variationsTypes.length} ${secondNode.variationsTypes.length}`);

        const intermediatePointCoordinates = this.getNextSimpleNodeCoordinates(currentNodeCoordinates.y, doRightTurn, false);
        intermediatePointCoordinates.y -= this.boundaryVariableNodesYOffset;

        const firstVariableNodeCoordinates = this.getNodeVariationsCoordinates(
            currentNodeCoordinates.x,
            currentNodeCoordinates.y,
            !doRightTurn,
            firstNode.variationsTypes.length
        );
        const secondVariableNodeCoordinates = this.getVariableNodeAfterSimpleNodeCoordinates(
            intermediatePointCoordinates.y,
            !doRightTurn,
            secondNode.variationsTypes.length
        );
        const drawingActionsQueue = new OverlappingPathDrawingActionsOrderedQueue();
        let hasTraveledPath = false;
        let hasFirstVariationPathTraveled = false;
        for (let firstNodeVariationIndex = 0; firstNodeVariationIndex < firstVariableNodeCoordinates.length; firstNodeVariationIndex++) {
            const firstNodeVariationCoordinates = firstVariableNodeCoordinates[firstNodeVariationIndex];
            for (let secondNodeVariationIndex = 0; secondNodeVariationIndex < secondVariableNodeCoordinates.length; secondNodeVariationIndex++) {
                const secondNodeVariationCoordinates = secondVariableNodeCoordinates[secondNodeVariationIndex];
                const pathTravelData = this.getPathTravelData(
                    { nodeIndex: currentNodeIndex, variationIndex: firstNodeVariationIndex },
                    { nodeIndex: nextNodeIndex, variationIndex: secondNodeVariationIndex },
                    travelData
                );
                drawingActionsQueue.enqueueAction(pathTravelData, () =>
                    this.virtualAction(
                        this.drawPathBetweenPointsWithIntermediatePoint,
                        firstNodeVariationCoordinates.x,
                        firstNodeVariationCoordinates.y,
                        intermediatePointCoordinates.x,
                        intermediatePointCoordinates.y,
                        secondNodeVariationCoordinates.x,
                        secondNodeVariationCoordinates.y,
                        true,
                        pathTravelData,
                        true
                    )
                );

                hasTraveledPath ||= pathTravelData.isTraveled;
                if (firstNodeVariationIndex === 0) hasFirstVariationPathTraveled ||= pathTravelData.isTraveled;
            }
        }

        drawingActionsQueue.applyOrderedActions();

        this.virtualDrawArrowAfterNodeTurn(
            currentNodeCoordinates.x,
            currentNodeCoordinates.y - this.boundaryVariableNodesYOffset,
            doRightTurn ? 'right' : 'left',
            hasFirstVariationPathTraveled
        );
        this.virtualDrawArrowAfterNodeTurn(intermediatePointCoordinates.x, intermediatePointCoordinates.y, doRightTurn ? 'left' : 'right', hasTraveledPath);

        return [firstVariableNodeCoordinates, { x: secondVariableNodeCoordinates[0].x, y: secondVariableNodeCoordinates[0].y }];
    }

    private drawSimplePathBetweenPoints(
        startNodeX: number,
        startNodeY: number,
        endNodeX: number,
        endNodeY: number,
        verticalCompensationAtTheBeginning: boolean,
        travelData: PathTravelData,
        clearDefaultPath: boolean
    ) {
        this.renderingContext.beginPath();
        this.renderingContext.moveTo(startNodeX, startNodeY);
        this.defineSimplePathBetweenPoints(startNodeX, endNodeX, startNodeY, endNodeY, verticalCompensationAtTheBeginning);

        this.strokeJourneyPath(travelData, clearDefaultPath);
    }

    private drawPathBetweenPointsWithIntermediatePoint(
        startNodeX: number,
        startNodeY: number,
        intermediatePointX: number,
        intermediatePointY: number,
        endNodeX: number,
        endNodeY: number,
        verticalCompensationAtTheBeginning: boolean,
        travelData: PathTravelData,
        clearDefaultPath: boolean
    ) {
        this.renderingContext.beginPath();
        this.renderingContext.moveTo(startNodeX, startNodeY);
        this.defineSimplePathBetweenPoints(startNodeX, intermediatePointX, startNodeY, intermediatePointY, verticalCompensationAtTheBeginning);
        this.defineSimplePathBetweenPoints(intermediatePointX, endNodeX, intermediatePointY, endNodeY, false);

        this.strokeJourneyPath(travelData, clearDefaultPath);
    }

    private defineSimplePathBetweenPoints(
        startNodeX: number,
        endNodeX: number,
        startNodeY: number,
        endNodeY: number,
        verticalCompensationAtTheBeginning: boolean
    ) {
        const nodesXDistance = Math.abs(startNodeX - endNodeX);
        const nodesYDistance = Math.abs(startNodeY - endNodeY);
        const minTurnsDistance = 2 * this.turnRadius;
        if (nodesXDistance < minTurnsDistance || nodesYDistance < minTurnsDistance) this.renderingContext.lineTo(endNodeX, endNodeY);
        else {
            const isVerticalCompensationNeeded = nodesYDistance > minTurnsDistance;
            let turnsStartY: number;
            if (isVerticalCompensationNeeded && verticalCompensationAtTheBeginning) {
                turnsStartY = endNodeY + minTurnsDistance;
                this.renderingContext.lineTo(startNodeX, turnsStartY);
            } else {
                turnsStartY = startNodeY;
            }
            if (startNodeX < endNodeX) {
                this.defineTopLeftCircleArc(startNodeX, turnsStartY, this.turnRadius);
                const horizontalLineEndX = endNodeX - this.turnRadius;
                const horizontalLineEndY = turnsStartY - this.turnRadius;
                this.renderingContext.lineTo(horizontalLineEndX, horizontalLineEndY);
                this.defineBottomRightCircleArc(horizontalLineEndX, horizontalLineEndY, this.turnRadius);
            } else {
                this.defineTopRightCircleArc(startNodeX, turnsStartY, this.turnRadius);
                const horizontalLineEndX = endNodeX + this.turnRadius;
                const horizontalLineEndY = turnsStartY - this.turnRadius;
                this.renderingContext.lineTo(horizontalLineEndX, horizontalLineEndY);
                this.defineBottomLeftCircleArc(horizontalLineEndX, horizontalLineEndY, this.turnRadius);
            }

            if (isVerticalCompensationNeeded && !verticalCompensationAtTheBeginning) this.renderingContext.lineTo(endNodeX, endNodeY);
        }
    }

    private strokeJourneyPath(travelData: PathTravelData, clearDefaultPath: boolean) {
        let isDefaultPath = true;
        if (travelData.isCurrentlyTraveled) {
            isDefaultPath = false;

            this.executeWithGlobalCompositeOperation(() => {
                this.applyDrawingStyles(this.currentPathStyle);
                this.renderingContext.stroke();
            }, 'destination-over');
        }
        if (travelData.isTraveled) {
            isDefaultPath = false;
            this.applyDrawingStyles(this.traveledPathStyle);
            this.renderingContext.stroke();
        }

        if (isDefaultPath) {
            if (clearDefaultPath) this.clearAlongCurrentPath();
            this.applyDrawingStyles(this.defaultPathStyle);
            this.renderingContext.stroke();
        }
    }

    private clearAlongCurrentPath() {
        this.executeWithGlobalCompositeOperation(() => {
            this.applyDrawingStyles(this.clearDefaultPathStyle);
            this.renderingContext.stroke();
        }, 'destination-out');
    }

    private executeWithGlobalCompositeOperation(action: () => void, compositeOperation: GlobalCompositeOperation) {
        const currentCompositeOperation = this.renderingContext.globalCompositeOperation;
        try {
            this.renderingContext.globalCompositeOperation = compositeOperation;
            action();
        } finally {
            this.renderingContext.globalCompositeOperation = currentCompositeOperation;
        }
    }

    private drawNodes(nodesCoordinates: JourneyNodesCoordinates, travelData: JourneyTravelData) {
        for (let nodeIndex = 0; nodeIndex < nodesCoordinates.length; nodeIndex++) {
            const nodeCoordinates = nodesCoordinates[nodeIndex];
            if (nodeCoordinates instanceof Array)
                for (let variationIndex = 0; variationIndex < nodeCoordinates.length; variationIndex++) {
                    const variationCoordinates = nodeCoordinates[variationIndex];
                    const nodeLocation: NodeLocation = { nodeIndex: nodeIndex, variationIndex: variationIndex };
                    const nodeTravelData = this.getNodeTravelData(nodeLocation, travelData);
                    const nodeType = this.getNodeType(nodeLocation);
                    this.drawNode(variationCoordinates.x, variationCoordinates.y, nodeType, nodeTravelData.isTravelTip);
                }
            else {
                const nodeTravelData = this.getNodeTravelData(nodeIndex, travelData);
                const nodeType = this.getNodeType(nodeIndex);
                this.drawNode(nodeCoordinates.x, nodeCoordinates.y, nodeType, nodeTravelData.isTravelTip);
            }
        }
    }

    private drawNode(nodeX: number, nodeY: number, type: TimelineNodeType, isTravelTip: boolean) {
        if (type === TimelineNodeType.Start) this.applyDrawingStyles(this.startNodeStyles);
        else if (isTravelTip) {
            this.applyDrawingStyles(this.maxProgressOuterNodeStyles);
            this.drawCircle(nodeX, nodeY, this.nodePointRadius * 1.5);
            this.applyDrawingStyles(this.maxProgressNodeStyles);
        } else this.applyDrawingStyles(type === TimelineNodeType.Disabled ? this.disabledNodeStyles : this.defaultNodeStyle);

        this.drawCircle(nodeX, nodeY, this.nodePointRadius);
    }

    private clearCanvas() {
        this.renderingContext.clearRect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
    }

    private resetState() {
        this.renderingContext.resetTransform();
        this.renderingContext.moveTo(0, 0);
        this.canvas.width = 0;
        this.canvas.removeAttribute('height');
        this.canvas.style.width = '';
        this.canvas.style.height = '';
    }

    private updateCanvasWidth() {
        const canvasWidth = Math.max(this.canvas.parentElement?.clientWidth ?? this.canvas.clientWidth, this.getCanvasMinWidth());

        const scaleFactor = this.getDrawingScaleFactor();

        this.canvas.width = canvasWidth * scaleFactor;
        this.canvas.style.width = canvasWidth + 'px';
    }

    private getCanvasMinWidth() {
        const maxParallelPathsCount = Math.max(
            ...this.nodes.filter((n): n is TimelineVariableNode => !this.isSimpleNode(n)).map(n => n.variationsTypes.length)
        );

        const minWidthWithoutVariations = 2 * this.xEdgeOffset + 2 * this.turnRadius;
        if (maxParallelPathsCount) return minWidthWithoutVariations + this.variableNodesXOffset + (maxParallelPathsCount - 1) * this.parallelPathsMinDistance;
        return minWidthWithoutVariations;
    }

    private setCanvasHeight(canvasHeight: number) {
        const scaleFactor = this.getDrawingScaleFactor();

        this.canvas.height = canvasHeight * scaleFactor;
        this.canvas.style.height = canvasHeight + 'px';
        this.renderingContext.translate(0, canvasHeight * scaleFactor);
    }

    private getDrawingScaleFactor() {
        return Math.max(window.devicePixelRatio, 2);
    }

    private applyVirtualDrawingActions() {
        const scaleFactor = this.getDrawingScaleFactor();
        this.renderingContext.scale(scaleFactor, scaleFactor);

        this.virtualActions.forEach(action => action());
        this.virtualActions.length = 0;
    }

    private virtualAction<TAction extends (...args: any) => any>(action: TAction, ...args: Parameters<TAction>) {
        const virtualAction = action.bind(this, ...args);
        this.virtualActions.push(virtualAction);
    }

    private defineBottomRightCircleArc(x: number, y: number, radius: number) {
        this.renderingContext.arcTo(x + radius, y, x + radius, y - radius, radius);
    }

    private defineBottomLeftCircleArc(x: number, y: number, radius: number) {
        this.renderingContext.arcTo(x - radius, y, x - radius, y - radius, radius);
    }

    private defineTopLeftCircleArc(x: number, y: number, radius: number) {
        this.renderingContext.arcTo(x, y - radius, x + radius, y - radius, radius);
    }

    private defineTopRightCircleArc(x: number, y: number, radius: number) {
        this.renderingContext.arcTo(x, y - radius, x - radius, y - radius, radius);
    }

    private isSimpleNode(node: TimelineNode): node is TimelineSimpleNode {
        return 'type' in node;
    }

    private virtualDrawArrowAfterNodeTurn(nodeX: number, nodeY: number, direction: 'left' | 'right', isTraveled: boolean) {
        this.virtualAction(this.applyDrawingStyles, isTraveled ? this.traveledArrowStyle : this.defaultArrowStyle);
        this.virtualAction(
            this.drawHorizontalArrow,
            nodeX + (this.turnRadius + this.arrowTurnOffset) * (direction === 'left' ? -1 : 1),
            nodeY - this.turnRadius,
            direction
        );
    }

    private virtualDrawArrowAfterVariableNode(nodeX: number, nodeY: number, isTraveled: boolean) {
        this.virtualAction(this.applyDrawingStyles, isTraveled ? this.traveledArrowStyle : this.defaultArrowStyle);
        this.virtualAction(this.drawVerticalArrow, nodeX, nodeY - this.arrowVariableNodeOffset, 'up');
    }

    private drawHorizontalArrow(x: number, y: number, direction: 'left' | 'right') {
        this.renderingContext.beginPath();
        this.renderingContext.moveTo(x, y);
        this.renderingContext.lineTo(x, y - this.arrowWidth / 2);
        this.renderingContext.lineTo(x + this.arrowHeight * (direction === 'left' ? -1 : 1), y);
        this.renderingContext.lineTo(x, y + this.arrowWidth / 2);
        this.renderingContext.closePath();
        this.renderingContext.fill();
    }

    private drawVerticalArrow(x: number, y: number, direction: 'up' | 'down') {
        this.renderingContext.beginPath();
        this.renderingContext.moveTo(x, y);
        this.renderingContext.lineTo(x + this.arrowWidth / 2, y);
        this.renderingContext.lineTo(x, y + this.arrowHeight * (direction === 'up' ? -1 : 1));
        this.renderingContext.lineTo(x - this.arrowWidth / 2, y);
        this.renderingContext.closePath();
        this.renderingContext.fill();
    }

    private drawCircle(x: number, y: number, radius: number) {
        this.renderingContext.beginPath();
        this.renderingContext.arc(x, y, radius, 0, 2 * Math.PI, false);
        this.renderingContext.fill();
        this.renderingContext.stroke();
    }

    private applyDrawingStyles(style: DrawingStyle) {
        this.renderingContext.lineWidth = style.strokeWidth;
        this.renderingContext.strokeStyle = style.strokeColor;
        this.renderingContext.fillStyle = style.fillColor;
        this.renderingContext.setLineDash(style.lineDash);
    }

    private drawHiddenZones(nodesCoordinates: JourneyNodesCoordinates, pathEndCoordinates: TimelineNodeCoordinates) {
        if (!this.hiddenZonesNodeIndices || !this.hiddenZonesNodeIndices.length) return;
        for (const hiddenZoneIndex of this.hiddenZonesNodeIndices) {
            const nodeWithHiddenZone = this.nodes[hiddenZoneIndex];
            const nextNodeIndex = this.getNextNodeIndex(hiddenZoneIndex);
            const nextNode = nextNodeIndex !== undefined ? this.nodes[nextNodeIndex] : undefined;
            const treatCurrentNodeAsSimple = this.isSimpleNode(nodeWithHiddenZone) || nodeWithHiddenZone.variationsTypes.length <= 1;
            const treatNextNodeAsSimple = !nextNode || this.isSimpleNode(nextNode) || nextNode.variationsTypes.length <= 1;
            if (treatCurrentNodeAsSimple) {
                const nodeCoordinates = this.getSimpleNodeCoordinates(hiddenZoneIndex, nodesCoordinates);
                if (!nextNode || nextNodeIndex === undefined) this.drawHiddenZoneBetweenPoints(nodeCoordinates, pathEndCoordinates, this.horizontalHiddenZoneHeight, -this.horizontalHiddenZoneXOffset, 0, 'horizontal');
                else if (treatNextNodeAsSimple) {
                    const nextNodeCoordinates = this.getSimpleNodeCoordinates(nextNodeIndex, nodesCoordinates);
                    this.drawHiddenZoneBetweenPoints(nodeCoordinates, nextNodeCoordinates, this.horizontalHiddenZoneHeight, -this.horizontalHiddenZoneXOffset, 0, 'horizontal');
                }
                else {
                    const nextNodeCoordinates = this.getVariableNodeCoordinates(nextNodeIndex, nodesCoordinates);
                    this.drawHiddenZoneBetweenPoints(nextNodeCoordinates[0], nextNodeCoordinates[nextNodeCoordinates.length -1], this.verticalHiddenZoneHeight, this.verticalHiddenZoneXSpread, this.verticalHiddenZoneYOffset, 'vertical');
                }
            }
            else {
                const nodeCoordinates = this.getVariableNodeCoordinates(hiddenZoneIndex, nodesCoordinates);
                if (!nextNode || nextNodeIndex === undefined || treatNextNodeAsSimple) this.drawHiddenZoneBetweenPoints(nodeCoordinates[0], nodeCoordinates[nodeCoordinates.length -1], this.verticalHiddenZoneHeight, this.verticalHiddenZoneXSpread, -this.verticalHiddenZoneYOffset, 'vertical');
                else {
                    const nextNodeCoordinates = this.getVariableNodeCoordinates(nextNodeIndex, nodesCoordinates);
                    if (nodeWithHiddenZone.groupKey === nextNode.groupKey) this.drawHiddenZoneBetweenPoints(nextNodeCoordinates[0], nextNodeCoordinates[nextNodeCoordinates.length -1], this.verticalHiddenZoneHeight, this.verticalHiddenZoneXSpread, this.verticalHiddenZoneYOffset, 'vertical');
                    else this.drawHiddenZoneBetweenPoints({ x: 0, y: nodeCoordinates[0].y }, { x: this.canvas.clientWidth, y: nextNodeCoordinates[0].y }, this.verticalHiddenZoneHeight, 0, 0, 'vertical');
                }
            }
        }
    }

    private drawHiddenZoneBetweenPoints(firstPoint: TimelineNodeCoordinates, secondPoint: TimelineNodeCoordinates, height: number, xSpread: number, yOffset: number, orientation: 'horizontal' | 'vertical') {
        const y = (firstPoint.y + secondPoint.y - height) / 2 + yOffset;
        const x = Math.min(firstPoint.x, secondPoint.x) - xSpread;
        const width = Math.abs(firstPoint.x - secondPoint.x) + 2 * xSpread;
        if (width <= 0) return;

        const hiddenZoneGradient = orientation === "horizontal" ? this.renderingContext.createLinearGradient(x, 0, x + width, 0) : this.renderingContext.createLinearGradient(0, y, 0, y + height);
        hiddenZoneGradient.addColorStop(0, '#FFFFFF00');
        hiddenZoneGradient.addColorStop(0.333, '#FFFFFF');
        hiddenZoneGradient.addColorStop(0.666, '#FFFFFF');
        hiddenZoneGradient.addColorStop(1, '#FFFFFF00');
        this.renderingContext.fillStyle = hiddenZoneGradient;
        this.executeWithGlobalCompositeOperation(() => this.renderingContext.fillRect(x, y, width, height), 'destination-out');
    }

    private getSimpleNodeCoordinates(nodeIndex: number, nodesCoordinates: JourneyNodesCoordinates) : TimelineNodeCoordinates {
        const nodeCoordinates = nodesCoordinates[nodeIndex];
        if (nodeCoordinates instanceof Array) {
            if (nodeCoordinates.length !== 1) throw new Error(`Unexpected variable coordinates at index ${nodeIndex}`);

            return nodeCoordinates[0];
        }

        return nodeCoordinates;
    }

    private getVariableNodeCoordinates(nodeIndex: number, nodesCoordinates: JourneyNodesCoordinates) : TimelineNodeCoordinates[] {
        const nodeCoordinates = nodesCoordinates[nodeIndex];
        if (!(nodeCoordinates instanceof Array)) throw new Error(`Unexpected simple coordinates at index ${nodeIndex}`);

        return nodeCoordinates;
    }

    private generateTravelData(): JourneyTravelData {
        const travelData = this.generateDefaultTravelData();
        if (this.nodes.length && !this.traveledEntirePath) {
            const firstNode = this.nodes[0];
            if (this.isSimpleNode(firstNode) || !firstNode.variationsTypes.length) this.updateTravelData(travelData, 0);
            else this.updateTravelData(travelData, { nodeIndex: 0, variationIndex: 0 });
        }

        if (this.currentNodeLocation) this.updateCurrentTravelData(travelData, this.currentNodeLocation);

        return travelData;
    }

    private generateDefaultTravelData() {
        const defaultTraveledValue = !!this.traveledEntirePath;
        const defaultIsCurrentlyTraveled = defaultTraveledValue && this.currentNodeLocation === undefined;
        this.journeyEndTravelData.isTraveled = defaultTraveledValue;
        this.journeyEndTravelData.isCurrentlyTraveled = defaultIsCurrentlyTraveled;
        const travelData: JourneyTravelData = [];
        for (const node of this.nodes) {
            if (this.isSimpleNode(node)) travelData.push({ isTraveled: defaultTraveledValue, isTravelTip: false, isCurrentlyTraveled: defaultIsCurrentlyTraveled });
            else if (!node.variationsTypes.length) travelData.push([{ isTraveled: defaultTraveledValue, isTravelTip: false, isCurrentlyTraveled: defaultIsCurrentlyTraveled }]);
            else
                travelData.push(
                    node.variationsTypes.map<NodeTravelData>(() => ({ isTraveled: defaultTraveledValue, isTravelTip: false, isCurrentlyTraveled: defaultIsCurrentlyTraveled }))
                );
        }

        return travelData;
    }

    // returns true if the node matching the passed location is traveled
    // Simple node and Variable nodes variation is considered traveled when is not disabled and there is path of traveled nodes from the start to it
    // Milestone (Simple not visitable node) is considered traveled when there is path of traveled nodes from the start to it and there is traveled node right after it
    private updateTravelData(travelData: JourneyTravelData, nodeLocation: NodeLocation): boolean {
        const node = this.getNode(nodeLocation);
        const nodeIndex = this.getNodeIndex(nodeLocation);
        const nextNodeIndex = this.getNextNodeIndex(nodeIndex);
        const nextNode = nextNodeIndex !== undefined ? this.nodes[nextNodeIndex] : undefined;
        const nodeTravelData = this.getNodeTravelData(nodeLocation, travelData);
        if (nodeTravelData.isTraveled) return true;

        if (this.isSimpleNode(node)) {
            if (node.isVisitable) {
                const isNodeTraveled = node.type !== TimelineNodeType.Disabled;
                nodeTravelData.isTraveled = isNodeTraveled;
                let hasDescendantTraveledNode = false;
                // traverse next nodes only if the current node is traveled
                if (isNodeTraveled && nextNode) {
                    if (this.isSimpleNode(nextNode) || !nextNode.variationsTypes.length) hasDescendantTraveledNode = this.updateTravelData(travelData, nodeIndex + 1);
                    else
                        for (let nextNodeVariationIndex = 0; nextNodeVariationIndex < nextNode.variationsTypes.length; nextNodeVariationIndex++) {
                            const nextVariationIsTraveled = this.updateTravelData(travelData, {
                                nodeIndex: nodeIndex + 1,
                                variationIndex: nextNodeVariationIndex
                            });
                            if (nextVariationIsTraveled) hasDescendantTraveledNode = true;
                        }
                }

                nodeTravelData.isTravelTip = isNodeTraveled && !hasDescendantTraveledNode;
                return isNodeTraveled;
            } else {
                // Simple "not visitable" node is a milestone - its traveled status depends on whether there is at least one traveled node after it
                if (nextNode) {
                    let hasDescendantTraveledNode = false;
                    if (this.isSimpleNode(nextNode) || !nextNode.variationsTypes.length) hasDescendantTraveledNode = this.updateTravelData(travelData, nodeIndex + 1);
                    else
                        for (let nextNodeVariationIndex = 0; nextNodeVariationIndex < nextNode.variationsTypes.length; nextNodeVariationIndex++) {
                            const nextVariationIsTraveled = this.updateTravelData(travelData, {
                                nodeIndex: nodeIndex + 1,
                                variationIndex: nextNodeVariationIndex
                            });
                            if (nextVariationIsTraveled) hasDescendantTraveledNode = true;
                        }

                    // A milestone cannot be a travel tip so we are not setting it
                    nodeTravelData.isTraveled = hasDescendantTraveledNode;
                    return hasDescendantTraveledNode;
                } else return false;
            }
        } else {
            const isSimpleNodeLocation = this.isSimpleNodeLocation(nodeLocation);
            if (isSimpleNodeLocation && node.variationsTypes.length > 1) throw new Error('Invalid state');

            const isNodeTraveled = node.variationsTypes.length ? node.variationsTypes[isSimpleNodeLocation ? 0 : nodeLocation.variationIndex] !== TimelineNodeType.Disabled : false;
            nodeTravelData.isTraveled = isNodeTraveled;
            let hasDescendantTraveledNode = false;
            // traverse next nodes only if the current node is traveled
            if (isNodeTraveled && nextNode) {
                if (this.isSimpleNode(nextNode) || !nextNode.variationsTypes.length) hasDescendantTraveledNode = this.updateTravelData(travelData, nodeIndex + 1);
                else {
                    if (nextNode.groupKey === node.groupKey)
                        // If the current node is within the same group as the next one - we traverse only the same variation since only it is directly connected to the current node
                        hasDescendantTraveledNode = this.updateTravelData(travelData, {
                            nodeIndex: nodeIndex + 1,
                            variationIndex: isSimpleNodeLocation ? 0 : nodeLocation.variationIndex
                        });
                    // If the nodes are in different groups - traverse all variations since the current node is connected to all variations
                    else
                        for (let nextNodeVariationIndex = 0; nextNodeVariationIndex < nextNode.variationsTypes.length; nextNodeVariationIndex++) {
                            const nextVariationIsTraveled = this.updateTravelData(travelData, {
                                nodeIndex: nodeIndex + 1,
                                variationIndex: nextNodeVariationIndex
                            });
                            if (nextVariationIsTraveled) hasDescendantTraveledNode = true;
                        }
                }
            }
            nodeTravelData.isTravelTip = isNodeTraveled && !hasDescendantTraveledNode;

            return isNodeTraveled;
        }
    }

    private updateCurrentTravelData(travelData: JourneyTravelData, nodeLocation: NodeLocation) {
        const nodeTravelData = this.getNodeTravelData(nodeLocation, travelData);
        if (nodeTravelData.isCurrentlyTraveled) return;
        const nodeType = this.getNodeType(nodeLocation);
        if (nodeType === TimelineNodeType.Disabled) return;

        const nodeIndex = this.getNodeIndex(nodeLocation);
        const previousNodeIndex = nodeIndex > 0 ? nodeIndex - 1 : undefined;
        const previousNode = previousNodeIndex !== undefined ? this.nodes[previousNodeIndex] : undefined;
        const node = this.getNode(nodeLocation);
        nodeTravelData.isCurrentlyTraveled = true;
        if (!previousNode || previousNodeIndex === undefined) return;

        if (this.isSimpleNode(node)) {
            if (this.isSimpleNode(previousNode) || !previousNode.variationsTypes.length) this.updateCurrentTravelData(travelData, previousNodeIndex);
            else for (let previousNodeVariationIndex = 0; previousNodeVariationIndex < previousNode.variationsTypes.length; previousNodeVariationIndex++)
                this.updateCurrentTravelData(travelData, { nodeIndex: previousNodeIndex, variationIndex: previousNodeVariationIndex });
        }
        else {
            const isSimpleLocation = this.isSimpleNodeLocation(nodeLocation)
            if (isSimpleLocation && node.variationsTypes.length > 1) throw new Error('Invalid state');
            if (this.isSimpleNode(previousNode) || !previousNode.variationsTypes.length) this.updateCurrentTravelData(travelData, previousNodeIndex);
            else if (node.groupKey === previousNode.groupKey) this.updateCurrentTravelData(travelData, { nodeIndex: previousNodeIndex, variationIndex: isSimpleLocation ? 0 : nodeLocation.variationIndex });
            else for (let previousNodeVariationIndex = 0; previousNodeVariationIndex < previousNode.variationsTypes.length; previousNodeVariationIndex++)
                this.updateCurrentTravelData(travelData, { nodeIndex: previousNodeIndex!, variationIndex: previousNodeVariationIndex });
        }
    }

    private isSimpleNodeLocation(location: NodeLocation): location is number {
        return typeof location === 'number';
    }

    private getNode(location: NodeLocation): TimelineNode {
        if (this.isSimpleNodeLocation(location)) {
            const node = this.nodes[location];
            if (!node || (!this.isSimpleNode(node) && node.variationsTypes.length > 1)) throw new Error('Invalid node location');
            return node;
        } else {
            const node = this.nodes[location.nodeIndex];
            if (!node) throw new Error('Invalid node location');
            if (this.isSimpleNode(node)) {
                if (location.variationIndex) throw new Error('Invalid node location');
                return node;
            }

            if (!node.variationsTypes.length && !location.variationIndex) return node;
            if (location.variationIndex >= node.variationsTypes.length) throw new Error('Invalid node location');
                
            return node;
        }
    }

    private getVariableNode(nodeIndex: number): TimelineVariableNode {
        const node = this.nodes[nodeIndex];
        if (this.isSimpleNode(node)) throw new Error('Invalid node index - found node is not variable');

        return node;
    }

    private getNodeIndex(location: NodeLocation): number {
        return this.isSimpleNodeLocation(location) ? location : location.nodeIndex;
    }

    private getNextNodeIndex(nodeIndex: number): number | undefined {
        const nextNodeIndex = nodeIndex + 1;
        if (nextNodeIndex < this.nodes.length) return nextNodeIndex;

        return undefined;
    }

    private getNodeTravelData(nodeLocation: NodeLocation, travelData: JourneyTravelData) {
        if (this.isSimpleNodeLocation(nodeLocation)) {
            const nodeTravelData = travelData[nodeLocation];
            if (nodeTravelData instanceof Array) {
                if (nodeTravelData.length !== 1) throw new Error('Invalid travel data');
                return nodeTravelData[0];
            }

            return nodeTravelData;
        }

        const nodeTravelData = travelData[nodeLocation.nodeIndex];
        if (!(nodeTravelData instanceof Array)) {
            if (nodeLocation.variationIndex) throw new Error('Invalid travel data');

            return nodeTravelData;
        }
        return nodeTravelData[nodeLocation.variationIndex];
    }

    private getNodeType(location: NodeLocation) {
        const node = this.getNode(location);
        const isSimpleNode = this.isSimpleNode(node);
        if (isSimpleNode) return node.type;

        if (this.isSimpleNodeLocation(location)) return node.variationsTypes.length ? node.variationsTypes[0] : TimelineNodeType.Disabled;
        else if (!location.variationIndex && !node.variationsTypes.length) return TimelineNodeType.Disabled;
        else return node.variationsTypes[location.variationIndex];
    }

    private getPathTravelData(startNodeLocation: NodeLocation, endNodeLocation: NodeLocation | undefined, travelData: JourneyTravelData): PathTravelData {
        const startNodeTravelData = this.getNodeTravelData(startNodeLocation, travelData);
        const endNodeTravelData = endNodeLocation === undefined ? this.journeyEndTravelData : this.getNodeTravelData(endNodeLocation, travelData);

        return {
            isTraveled: startNodeTravelData.isTraveled && endNodeTravelData.isTraveled,
            isCurrentlyTraveled: startNodeTravelData.isCurrentlyTraveled && endNodeTravelData.isCurrentlyTraveled
        };
    }
}

class OverlappingPathDrawingActionsOrderedQueue {
    private readonly actionBuckets: ((() => void)[] | undefined)[] = [];

    enqueueAction(pathTravelData: PathTravelData, action: () => void) {
        const bucketIndex = this.getBucketIndex(pathTravelData);
        const bucket = this.actionBuckets[bucketIndex] ?? (this.actionBuckets[bucketIndex] = []);
        bucket.push(action);
    }

    applyOrderedActions() {
        for (const actionBucket of this.actionBuckets) {
            if (!actionBucket) continue;
            for (const pathDrawingAction of actionBucket) {
                pathDrawingAction();
            }
        }
    }

    private getBucketIndex(pathTravelData: PathTravelData): number {
        if (pathTravelData.isCurrentlyTraveled) return 1;
        if (pathTravelData.isTraveled) return 2;

        return 0;
    }
}
