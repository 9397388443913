import { DateRangePicker, SelectionRange } from '@progress/kendo-react-dateinputs';
import { Field, FormRenderProps } from '@progress/kendo-react-form';
import { Checkbox, Input, NumericTextBox, TextArea } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { ReactComponent as TimeZoneIcon } from '../../icons/timezone.svg';
import { combineClassNames } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { MinimalProblemValidationResearch, ReducedResearch } from '../../services/researchService';
import { LocationOption, LocationOptionType, TimetableEntry, TimetableTimeValue } from '../../services/schedulesService';
import { UserRole } from '../../services/usersService';
import { KeyboardNavigatableDateInput } from '../common/date';
import { TimeZonePicker } from '../common/timeZonePicker';
import { ValidationScope, ValidationScopeHandle } from '../common/validation';
import { StartupMemberPicker } from '../startup/startupMemberPicker';
import {
    ValidatedInput,
    ValidatedInputCheckboxLayout,
    ValidatedInputHorizontalLayout,
    maxLengthValidator,
    numberInRangeValidator,
    requiredValidator
} from '../ui/inputs';
import { H3 } from '../ui/typography';
import { EventLocationsConfigurator } from './eventLocationsConfigurator';
import { DaysSlotsEditor, WeeklySlotsEditor } from './slotsEditors';

export type ScheduleEditorHandle = { validate: () => void };

const titleValidators = [requiredValidator('Schedule name'), maxLengthValidator('Schedule name', 300)];
const hostValidators = [requiredValidator('Team member')];
export const locationValidators = [
    requiredValidator('Location'),
    (value: LocationOption[] | undefined) => {
        if (!value || !value.length) return;
        const inPersonLocation = value.find(l => l.type === LocationOptionType.InPerson);
        if (!inPersonLocation || inPersonLocation.details) return;

        return 'Value for the In Person location is required when the option is checked';
    }
];
const scheduleRangeValidators = [
    (value: SelectionRange | null | undefined) => {
        if (!value) return 'Start and end dates are required';
        if (!value.start) return 'Start date is required';
        if (!value.end) return 'End date is required';

        return undefined;
    },
    (value: SelectionRange | null | undefined) => {
        if (!value || !value.start || !value.end) return undefined;

        if (value.start > value.end) return 'End date should be after start date';
        return undefined;
    }
];
const durationValidators = [requiredValidator('Duration'), numberInRangeValidator('Duration', 15)];
const bufferBeforeValidators = [numberInRangeValidator('Buffer before event', 0)];
const bufferAfterValidators = [numberInRangeValidator('Buffer after event', 0)];
const descriptionValidators = [maxLengthValidator('Description', 1000)];
export const timeZoneValidators = [requiredValidator('Timezone')];
export const ScheduleEditor = forwardRef<
    ScheduleEditorHandle,
    {
        ideaId: string;
        scheduleId?: number;
        className?: string;
        formRenderProps: FormRenderProps;
        creatingForResearch?: MinimalProblemValidationResearch;
    }
>(function({ ideaId, scheduleId, className, formRenderProps, creatingForResearch }, ref) {
    const timetableEntriesValidationScopeRef = useRef<ValidationScopeHandle>(null);
    const timetableSpecialEntriesValidationScopeRef = useRef<ValidationScopeHandle>(null);

    const isEditing = scheduleId !== undefined;
    const timeZone: string | null | undefined = formRenderProps.valueGetter('timeZone');
    const slotLength: number =
        (formRenderProps.valueGetter('durationMinutes') ?? 45) +
        (formRenderProps.valueGetter('bufferBeforeMinutes') ?? 0) +
        (formRenderProps.valueGetter('bufferAfterMinutes') ?? 0);

    const isCreatingForResearch = scheduleId === undefined && creatingForResearch !== undefined;

    const timetableEntriesValidator = useCallback(() => {
        if (!timetableEntriesValidationScopeRef.current) return;

        if (!timetableEntriesValidationScopeRef.current.isValid) return 'Invalid entries';
    }, []);

    const timetableSpecialEntriesValidator = useCallback(() => {
        if (!timetableSpecialEntriesValidationScopeRef.current) return;

        if (!timetableSpecialEntriesValidationScopeRef.current.isValid) return 'Invalid special entries';
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            validate() {
                timetableEntriesValidationScopeRef.current?.validate();
                timetableSpecialEntriesValidationScopeRef.current?.validate();
            }
        }),
        []
    );

    const relatedResearch: ReducedResearch[] | undefined = isEditing ? formRenderProps.valueGetter('relatedResearch') : undefined;
    const research = isCreatingForResearch ? creatingForResearch : relatedResearch && relatedResearch.length ? relatedResearch[0] : undefined;

    const timetableEntries: TimetableEntry[] | undefined = formRenderProps.valueGetter('timetable.entries');
    const defaultStartTimesByWeekDay = timetableEntries?.reduce<Partial<Record<number, TimetableTimeValue[]>>>((defaultStartTimesByWeekDay, entry) => {
        defaultStartTimesByWeekDay[entry.dayOfWeek] = entry.startTimes;

        return defaultStartTimesByWeekDay;
    }, {});

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className={combineClassNames('k-gap-10', className)}>
            <ScheduleEditorGroup title="Basic information">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                    <Field
                        name="title"
                        component={ValidatedInput}
                        label="Schedule name"
                        validator={titleValidators}
                        maxLength={300}
                        layout={ValidatedInputHorizontalLayout}
                        placeholder="Add title..."
                        disabled={isCreatingForResearch}
                    />
                    <Field
                        name="user"
                        component={ValidatedInput}
                        label="Team member"
                        inputType={StartupMemberPicker}
                        validator={hostValidators}
                        ideaId={ideaId}
                        roles={[UserRole.Administrator, UserRole.Editor]}
                        placeholder="Select the host of the meetings..."
                        disabled={isEditing || isCreatingForResearch}
                        layout={ValidatedInputHorizontalLayout}
                    />
                    {research && (
                        <ValidatedInputHorizontalLayout
                            label={<Label className="k-text-disabled">Related research</Label>}
                            input={<Input disabled={true} value={research.title} />}
                        />
                    )}
                    <Field
                        name="scheduleRange"
                        component={ValidatedInput}
                        label="Date range"
                        inputType={DateRangePicker}
                        validator={scheduleRangeValidators}
                        layout={ValidatedInputHorizontalLayout}
                        startDateInputSettings={{ label: undefined, placeholder: 'Start date' }}
                        startDateInput={KeyboardNavigatableDateInput}
                        endDateInputSettings={{ label: undefined, placeholder: 'End date' }}
                        endDateInput={KeyboardNavigatableDateInput}
                        disabled={research !== undefined}
                        hint={research ? 'Date range is inherited from research' : undefined}
                    />
                    {!isCreatingForResearch && (
                        <Field
                            name="durationMinutes"
                            component={ValidatedInput}
                            label="Event duration"
                            validator={durationValidators}
                            inputType={NumericTextBox}
                            format="# 'min'"
                            min={15}
                            step={15}
                            layout={ValidatedInputHorizontalLayout}
                            placeholder="Add duration..."
                        />
                    )}
                    {!isCreatingForResearch && (
                        <Field
                            name="bufferBeforeMinutes"
                            component={ValidatedInput}
                            label="Buffer before event"
                            validator={bufferBeforeValidators}
                            inputType={NumericTextBox}
                            format="# 'min'"
                            min={0}
                            step={5}
                            layout={ValidatedInputHorizontalLayout}
                            placeholder="0 min"
                        />
                    )}
                    {!isCreatingForResearch && (
                        <Field
                            name="bufferAfterMinutes"
                            component={ValidatedInput}
                            label="Buffer after event"
                            validator={bufferAfterValidators}
                            inputType={NumericTextBox}
                            format="# 'min'"
                            min={0}
                            step={5}
                            layout={ValidatedInputHorizontalLayout}
                            placeholder="15 min"
                        />
                    )}
                    {!isCreatingForResearch && (
                        <ValidatedInputHorizontalLayout
                            label={<Label>Booking notice</Label>}
                            input={
                                <Field
                                    name="allowSameDayBooking"
                                    component={ValidatedInput}
                                    inputType={Checkbox}
                                    label="Allow same day booking"
                                    layout={ValidatedInputCheckboxLayout}
                                    labelClassName="!k-mb-0"
                                    wrapperClass="k-mt-1"
                                />
                            }
                        />
                    )}

                    <Field
                        name="timeZone"
                        component={ValidatedInput}
                        label="Timezone"
                        validator={timeZoneValidators}
                        inputType={TimeZonePicker}
                        layout={ValidatedInputHorizontalLayout}
                        placeholder="Select timezone..."
                    />
                    {!isCreatingForResearch && (
                        <Field
                            name="description"
                            component={ValidatedInput}
                            label="Description"
                            maxLengthCount={1000}
                            validator={descriptionValidators}
                            inputType={TextArea}
                            rows={3}
                            layout={ValidatedInputHorizontalLayout}
                            placeholder="Add description visible to attendees..."
                        />
                    )}
                </StackLayout>
            </ScheduleEditorGroup>
            <ScheduleEditorGroup title="Location">
                <Field name="locationOptions" component={ValidatedInput} validator={locationValidators} inputType={EventLocationsConfigurator} />
            </ScheduleEditorGroup>
            <ScheduleEditorGroup title="Weekly slots">
                <TimeZoneNotice timeZone={timeZone} className="k-mb-4">
                    Define meeting days and start times
                </TimeZoneNotice>
                <ValidationScope ref={timetableEntriesValidationScopeRef}>
                    <Field
                        name="timetable.entries"
                        component={ValidatedInput}
                        inputType={WeeklySlotsEditor}
                        slotLength={slotLength}
                        triggerOnChangeIfInvalid={true}
                        validator={timetableEntriesValidator}
                        hideValidation={true}
                    />
                </ValidationScope>
            </ScheduleEditorGroup>
            <ScheduleEditorGroup title="Special day slots" addBottomBorder={false}>
                <TimeZoneNotice timeZone={timeZone} className="k-mb-4">
                    Override weekly slot start times or block off a day
                </TimeZoneNotice>
                <ValidationScope ref={timetableSpecialEntriesValidationScopeRef}>
                    <Field
                        name="timetable.specialEntries"
                        component={ValidatedInput}
                        inputType={DaysSlotsEditor}
                        slotLength={slotLength}
                        triggerOnChangeIfInvalid={true}
                        validator={timetableSpecialEntriesValidator}
                        hideValidation={true}
                        defaultStartTimesByWeekDay={defaultStartTimesByWeekDay}
                    />
                </ValidationScope>
            </ScheduleEditorGroup>
        </StackLayout>
    );
});

function ScheduleEditorGroup({ title, children, addBottomBorder = true }: { title: string; children?: ReactNode; addBottomBorder?: boolean }) {
    return (
        <div className={combineClassNames('k-pb-8', addBottomBorder ? 'k-border-b k-border-b-solid k-icp-component-border' : undefined)}>
            <H3 className="!k-mb-4">{title}</H3>
            {children}
        </div>
    );
}

function TimeZoneNotice({ timeZone, children, className }: { timeZone?: string | null; children?: string; className?: string }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className={combineClassNames('k-gap-2', className)}>
            <div className="k-flex-1 k-icp-subtle-text">{children}</div>
            {timeZone && (
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                    <TimeZoneIcon className="k-icp-icon k-icp-icon-size-4" />
                    {<span className="k-fs-sm">All slots defined in {dateTimeService.getShortTimeZoneTitle(timeZone)}</span>}
                </StackLayout>
            )}
        </StackLayout>
    );
}
