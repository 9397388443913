import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export type SelectionRangeTextBoxOptions = {
    snapToWord?: boolean;
    interchangeableHandles?: boolean;
};

export type SelectionRangeTextBoxProps = {
    text: string;
    selectionStart: number;
    selectionEnd: number;
    onSelectionChange?: (startIndex: number, endIndex: number) => void;
    options?: SelectionRangeTextBoxOptions;
};

export const SelectionRangeTextBox = ({
    text,
    selectionStart,
    selectionEnd,
    onSelectionChange,
    options = { snapToWord: true, interchangeableHandles: false }
}: SelectionRangeTextBoxProps) => {
    const selectionContainerRef = useRef<HTMLDivElement | null>(null);
    const lastMouseXPos = useRef<number>(0);
    const handleOne = useRef<HTMLDivElement | null>(null);
    const handleTwo = useRef<HTMLDivElement | null>(null);
    const spanRef = useRef<HTMLSpanElement | null>(null);
    const [textMarkers, setTextMarkers] = useState<{ charPts: CharParams[]; indices: Indices[] } | undefined>();
    const [draggingState, setDraggingState] = useState<DraggingState | null>(null);

    const getIndices = () => {
        if (draggingState != null) {
            return draggingState.indices;
        } else {
            return { startIdx: selectionStart, endIdx: selectionEnd };
        }
    };

    const setHandlePosition = (handleRef: React.RefObject<HTMLDivElement>, isStart: boolean, charPositions: CharParams[], index: number) => {
        const elementHandle = handleRef.current!;
        const shadowHeight = parseInt(getComputedStyle(selectionContainerRef.current!).getPropertyValue('--selection-range-start-handle-offset'), 10);
        const shadowHeightParsed = isNaN(shadowHeight) ? 0 : shadowHeight;
        elementHandle.style.left = `${charPositions[index].x + (isStart ? shadowHeightParsed : 0)}px`;
        elementHandle.style.top = `${charPositions[index].y}px`;
    };

    const availableIndices = getIndices();
    const { before: beforeSelection, selection, after: afterSelection } =
        availableIndices.endIdx >= availableIndices.startIdx
            ? splitSelection(text, availableIndices.startIdx, availableIndices.endIdx)
            : splitSelection(text, availableIndices.endIdx, availableIndices.startIdx);

    useLayoutEffect(() => {
        //Initialize text characters coordinates whenever the component resizes or the text changes
        const calcCoordinates = () => {
            if (!selectionContainerRef.current) return;
            const charPts = getAllCharacterParams(selectionContainerRef.current, ['selection-range']);
            const indices = findWordMarkers(text);
            setTextMarkers({ charPts, indices });
        };

        const calcCoordinatesOnResize = (entries: ResizeObserverEntry[]) => {
            calcCoordinates();
        };

        const observerTarget = selectionContainerRef.current;
        if (!observerTarget) return;

        calcCoordinates();
        const resizeObserver = new ResizeObserver(calcCoordinatesOnResize);
        resizeObserver.observe(observerTarget);

        return () => {
            resizeObserver.unobserve(observerTarget);
        };
    }, [text]);

    useEffect(() => {
        //Update handle positions whenever the selection changes
        if (!textMarkers || !textMarkers.charPts.length || !handleOne.current || !handleTwo.current || !selectionContainerRef.current) return;
        const { charPts } = textMarkers;
        const handleHeight = parseInt(window.getComputedStyle(selectionContainerRef.current).lineHeight, 10);

        if (!isNaN(handleHeight)) {
            const shadowHeight = (handleHeight - charPts[0].height) / 2;
            spanRef.current!.style.setProperty('--selection-range-handle-shadow-height', `${shadowHeight}px`);
        }

        setHandlePosition(handleOne, true, charPts, selectionStart);
        setHandlePosition(handleTwo, false, charPts, selectionEnd);
    }, [textMarkers, selectionStart, selectionEnd]);

    useEffect(() => {
        const handleMouseUp = (event: MouseEvent) => {
            selectionContainerRef.current?.classList.remove('selection-range-dragging-cursor');
            if (!draggingState || !onSelectionChange || !textMarkers) return;
            const draggingHandlePositions = draggingState.indices;
            const { charPts } = textMarkers!;
            const handleStart =
                draggingHandlePositions.endIdx >= draggingHandlePositions.startIdx ? draggingHandlePositions.startIdx : draggingHandlePositions.endIdx;
            const handleEnd =
                draggingHandlePositions.endIdx >= draggingHandlePositions.startIdx ? draggingHandlePositions.endIdx : draggingHandlePositions.startIdx;
            const { handleIdx, isStart } =
                draggingState.draggingHandleId === 'one' ? { handleIdx: handleStart, isStart: true } : { handleIdx: handleEnd, isStart: false };

            setHandlePosition(getHandleRef(draggingState.draggingHandleId), isStart, charPts, handleIdx);
            setDraggingState(null);
            onSelectionChange(handleStart, handleEnd);
        };

        const isDraggingHandleStart = (draggingState: DraggingState): boolean => {
            const indices = draggingState.indices;
            if (draggingState.draggingHandleId === 'one' && indices.startIdx <= indices.endIdx) {
                return true;
            } else if (draggingState.draggingHandleId === 'one') {
                return false;
            } else if (draggingState.draggingHandleId === 'two' && indices.startIdx <= indices.endIdx) {
                return false;
            } else if (draggingState.draggingHandleId === 'two') {
                return true;
            }

            //should be unreachable
            throw new Error('Invalid dragging state');
        };

        const handleMouseMove = (event: MouseEvent) => {
            if (!handleOne.current || !handleTwo.current || !selectionContainerRef.current || !draggingState || !onSelectionChange) return;

            const draggingDirection = findDraggingDirection(lastMouseXPos.current, event.clientX);
            lastMouseXPos.current = event.clientX;
            if (!textMarkers || draggingDirection === 'same') return;

            const { charPts, indices } = textMarkers;

            const containerRect = selectionContainerRef.current.getBoundingClientRect();
            const xInsideContainer = Math.max(event.clientX - containerRect.left, 0);
            const yInsideContainer = Math.max(event.clientY - containerRect.top, 0);

            let closestIndex = getClosestCharPtIdx(charPts, { x: xInsideContainer, y: yInsideContainer });

            const interachangeableHandles = options.interchangeableHandles ?? false;
            const isHandleStart = isDraggingHandleStart(draggingState);
            if (options.snapToWord) {
                closestIndex = snapCursorToWord(closestIndex, draggingDirection, isHandleStart, indices, interachangeableHandles);
            }

            if (draggingState.draggingHandleId === 'one') {
                if (!interachangeableHandles && closestIndex >= draggingState.indices.endIdx) return;
                setHandlePosition(getHandleRef(draggingState.draggingHandleId), isHandleStart, charPts, closestIndex);
                setDraggingState(state => ({ ...state!, indices: { startIdx: closestIndex, endIdx: state!.indices.endIdx } }));
            } else if (draggingState.draggingHandleId === 'two') {
                if (!interachangeableHandles && closestIndex <= draggingState.indices.startIdx) return;
                setHandlePosition(getHandleRef(draggingState.draggingHandleId), isHandleStart, charPts, closestIndex);
                setDraggingState(state => ({ ...state!, indices: { startIdx: state!.indices.startIdx, endIdx: closestIndex } }));
            }
        };

        const getHandleRef = (handle: 'one' | 'two') => (handle === 'one' ? handleOne : handleTwo);

        if (draggingState) {
            document.addEventListener('mousemove', handleMouseMove);
        }
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [draggingState, onSelectionChange, options.interchangeableHandles, options.snapToWord, textMarkers]);

    const handleMouseDown = (handle: 'one' | 'two') => () => {
        selectionContainerRef.current?.classList.add('selection-range-dragging-cursor');
        setDraggingState({ draggingHandleId: handle, indices: { startIdx: selectionStart, endIdx: selectionEnd } });
    };

    return (
        <div className="selection-range-container" ref={selectionContainerRef} data-testid="selection-container">
            {beforeSelection}
            <span ref={spanRef} className="selection-range">
                {selection}
            </span>
            {afterSelection}
            <div
                ref={handleOne}
                data-testid="selection-handle-one"
                className={'selection-range-handle' + (availableIndices.startIdx <= availableIndices.endIdx ? ' selection-range-handle-start' : '')}
                onMouseDown={handleMouseDown('one')}
            />
            <div
                ref={handleTwo}
                data-testid="selection-handle-two"
                className={'selection-range-handle' + (availableIndices.startIdx <= availableIndices.endIdx ? '' : ' selection-range-handle-start')}
                onMouseDown={handleMouseDown('two')}
            />
        </div>
    );
};

type DraggingState = {
    indices: Indices;
    draggingHandleId: 'one' | 'two';
};

type CharParams = {
    x: number;
    y: number;
    height: number;
};

type Indices = {
    startIdx: number;
    endIdx: number;
};

const traverseNodesRecursive = (node: ChildNode, callback: (node: ChildNode, key: number) => void, traverseCondition: (node: ChildNode) => boolean): void => {
    node.childNodes.forEach((node, idx) => {
        callback(node, idx);

        if (traverseCondition(node)) {
            traverseNodesRecursive(node, callback, traverseCondition);
        }
    });
};

const getAllCharacterParams = (container: HTMLElement, childContainerClasses?: string[]): CharParams[] => {
    const coordinates: CharParams[] = [];
    const docRange = document.createRange();
    const containerRect = container.getBoundingClientRect();
    const lineHeight = parseInt(window.getComputedStyle(container).lineHeight, 10);

    const traverseCondition = (node: ChildNode) =>
        node.nodeType === Node.ELEMENT_NODE &&
        ((node as Element).tagName.toLowerCase() === 'div' ||
            !childContainerClasses ||
            childContainerClasses.some(c => (node as Element).classList.contains(c)));

    let lastChildTextNodeCoordinates: { x: number; y: number; height: number } | undefined;

    traverseNodesRecursive(
        container,
        (node, idx) => {
            if (node.nodeType !== Node.TEXT_NODE) return;
            const textContent = node.textContent || '';
            let rect: DOMRect | undefined;
            for (let i = 0; i < textContent.length; i++) {
                docRange.setStart(node, i);
                docRange.setEnd(node, i + 1);
                rect = docRange.getBoundingClientRect();

                // Line height is different to span height so we have to add offset
                const offset = !isNaN(lineHeight) ? (lineHeight - rect.height) / 2 : 0;
                coordinates.push({ x: rect.left - containerRect.left, y: rect.top - containerRect.top - offset, height: rect.height });
            }

            if (rect) {
                const offset = !isNaN(lineHeight) ? (lineHeight - rect.height) / 2 : 0;
                lastChildTextNodeCoordinates = { x: rect.right - containerRect.left, y: rect.top - containerRect.top - offset, height: rect.height };
            }
        },
        traverseCondition
    );

    if (lastChildTextNodeCoordinates) {
        coordinates.push({ x: lastChildTextNodeCoordinates.x, y: lastChildTextNodeCoordinates.y, height: lastChildTextNodeCoordinates.height });
    }

    docRange.detach();
    return coordinates;
};

const findWordMarkers = (text: string): Indices[] => {
    const wordRegExp = /[\w\p{P}]+/dgu;

    let curMatchArray: any = null;
    let indicesArray: { startIdx: number; endIdx: number }[] = [];
    while ((curMatchArray = wordRegExp.exec(text)) !== null) {
        const indices = curMatchArray.indices![0];
        indicesArray.push({ startIdx: indices[0], endIdx: indices[1] });
    }
    return indicesArray;
};

const splitSelection = (text: string, selectionStart: number, selectionEnd: number) => {
    const before = text.slice(0, selectionStart);
    const selection = text.slice(selectionStart, selectionEnd);
    const after = text.slice(selectionEnd);

    return { before, selection, after };
};

const findDraggingDirection = (lastMouseXPos: number, currentMouseXPos: number): 'right' | 'left' | 'same' => {
    if (currentMouseXPos > lastMouseXPos) {
        return 'right';
    }

    if (currentMouseXPos < lastMouseXPos) {
        return 'left';
    }

    return 'same';
};

const averagePosIdx = (startIdx: number, endIdx: number, offset: number = 0, direction: 'left' | 'right' = 'left'): number => {
    const calcOffset = direction === 'left' ? -offset : offset;
    const offsetInBounds = Math.abs(calcOffset) > Math.abs(endIdx - startIdx) ? 0 : calcOffset;
    return Math.floor((startIdx + endIdx + offsetInBounds) / 2);
};

const snapCursorToWord = (
    closestIndex: number,
    draggingDirection: 'left' | 'right',
    isHandleStart: boolean,
    wordsIndices: Indices[],
    interchangeableHandles: boolean
): number => {
    for (let i = 0; i < wordsIndices.length; i++) {
        const ind = wordsIndices[i];
        let startIdx = ind.startIdx;
        let endIdx = ind.endIdx;

        //When handles are interchangeable, word snapping currently works only with white spaces
        if (!interchangeableHandles) {
            if (!isHandleStart) {
                startIdx = i === 0 ? ind.startIdx : wordsIndices[i - 1].endIdx;
                endIdx = ind.endIdx;
            } else {
                startIdx = ind.startIdx;
                endIdx = i === wordsIndices.length - 1 ? ind.endIdx : wordsIndices[i + 1].startIdx;
            }
        }

        let avgPosIdx = averagePosIdx(startIdx, endIdx);
        if (draggingDirection === 'right' && closestIndex > startIdx) {
            if (closestIndex === avgPosIdx) {
                return endIdx;
            } else if (closestIndex < avgPosIdx) {
                return startIdx;
            } else if (closestIndex < endIdx) {
                return endIdx;
            }
        } else if (draggingDirection === 'left' && closestIndex < endIdx) {
            if (closestIndex === avgPosIdx) {
                return startIdx;
            } else if (closestIndex > avgPosIdx) {
                return endIdx;
            } else if (closestIndex > startIdx) {
                return startIdx;
            }
        }
    }

    return closestIndex;
};

const getClosestCharPtIdx = (charPts: CharParams[], point: { x: number; y: number }): number => {
    let closestPointIdx = -1;
    let minDistance = Number.MAX_VALUE;

    charPts?.forEach((pt, index) => {
        const distance = Math.sqrt(Math.pow(pt.x - point.x, 2) + Math.pow(pt.y - point.y, 2));
        if (distance < minDistance) {
            minDistance = distance;
            closestPointIdx = index;
        }
    });

    return closestPointIdx;
};
