import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import {
    DateRangePicker,
    DateRangePickerHandle,
    DateRangePickerProps,
    SelectionRange,
    TimePicker,
    TimePickerHandle,
    TimePickerProps
} from '@progress/kendo-react-dateinputs';
import { Field, FieldRenderProps } from '@progress/kendo-react-form';
import { Input, InputHandle, InputProps, TextArea, TextAreaHandle, TextAreaProps } from '@progress/kendo-react-inputs';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { Avatar, StackLayout } from '@progress/kendo-react-layout';
import React, { ComponentType, ReactElement, ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import isPostalCode, { PostalCodeLocale } from 'validator/lib/isPostalCode';
import isUrl from 'validator/lib/isURL';
import { appConfig } from '../../config';
import { useFileUpload, useSingleFileUpload } from '../../hooks/fileHooks';
import { useAuthenticationIntent, useReturnUrl } from '../../hooks/routerHooks';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as ShowPasswordIcon } from '../../icons/eye-off.svg';
import { ReactComponent as HidePasswordIcon } from '../../icons/eye.svg';
import { ReactComponent as ImageIcon } from '../../icons/image.svg';
import { ReactComponent as UploadIcon } from '../../icons/upload.svg';
import { ReactComponent as CloseIcon } from '../../icons/x-circle.svg';
import {
    FileResponse,
    authenticationIntentUrlParamName,
    combineClassNames,
    pathWithReturnUrl,
    returnUrlParamName,
    urlWithQueryParams
} from '../../services/common';
import { ideasService } from '../../services/ideasService';
import { usersService } from '../../services/usersService';
import { getButtonClassName } from '../common/DivButton';
import { KeyboardNavigatableDateInput } from '../common/date';
import LoadingIndicator from './loadingIndicator';
import { SvgIconButtonContent } from './svgIconButtonContent';

type ValidatedInputProps = FieldRenderProps & {
    id?: string;
    label?: string;
    hint?: ReactNode;
    inputType?: ComponentType<any>;
    wrapperClass?: string;
    maxLengthCount?: number;
    labelClassName?: string;
    labelType?: ComponentType<ValidatedInputLabelProps>;
    errorMessage?: ReactNode;
    layout?: ComponentType<ValidatedInputLayoutProps>;
    hideValidation?: boolean;
    hideErrorMessage?: boolean;
};
export const ValidatedInput = (fieldRenderProps: ValidatedInputProps) => {
    const {
        id,
        validationMessage,
        visited,
        label,
        name,
        hint,
        inputType,
        wrapperClass,
        maxLengthCount,
        labelClassName,
        labelType,
        errorMessage,
        layout,
        hideValidation,
        hideErrorMessage,
        valid,
        touched,
        modified,
        ...others
    } = fieldRenderProps;
    const InputType = inputType || Input;
    const LabelType = labelType || ValidatedInputLabel;
    const Layout = layout || ValidatedInputDefaultLayout;
    const editorId = id ?? name;
    const showValidationMessage = ((!valid && !!validationMessage) || !!errorMessage) && !hideValidation;
    const showHint = (!showValidationMessage || hideErrorMessage) && hint;

    return (
        <Layout
            className={wrapperClass}
            label={
                label ? (
                    <LabelType text={label} editorId={editorId} isValid={!showValidationMessage} disabled={others.disabled} className={labelClassName} />
                ) : (
                    undefined
                )
            }
            input={<InputType {...others} valid={!showValidationMessage} id={editorId} />}
            infoElement={
                <StackLayout
                    align={{ horizontal: 'start', vertical: 'top' }}
                    className={`k-gap-2 ${!!maxLengthCount ? ' k-justify-content-between k-flex-row-reverse' : ''}`}
                >
                    {!!maxLengthCount && (
                        <Hint
                            direction="end"
                            editorDisabled={others.disabled}
                            className={`k-text-nowrap${(others.value?.length || 0) > maxLengthCount ? ' k-form-error' : ''}`}
                        >
                            {others.value?.length || 0} / {maxLengthCount}
                        </Hint>
                    )}
                    {showHint && <Hint editorDisabled={others.disabled}>{hint}</Hint>}
                    {showValidationMessage && !hideErrorMessage && <Error>{errorMessage || validationMessage}</Error>}
                </StackLayout>
            }
        />
    );
};

export type FormFieldProps<TValue> = {
    id: string;
    value?: TValue;
    onChange?: (e: { value: TValue }) => void;
    valid?: boolean;
    disabled?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
};

export type ValidatedInputLayoutProps = { label?: ReactElement; input: ReactElement; infoElement?: ReactElement; className?: string };
function ValidatedInputDefaultLayout({ label, input, infoElement, className }: ValidatedInputLayoutProps) {
    return (
        <div className={className}>
            {label}
            {input}
            {infoElement}
        </div>
    );
}

export function ValidatedInputHorizontalLayout({ label, input, infoElement, className }: ValidatedInputLayoutProps) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className={className}>
            {label && <div className="k-icp-horizontal-input-label">{label}</div>}
            <div className="k-flex-grow">
                {input}
                {infoElement}
            </div>
        </StackLayout>
    );
}

export function ValidatedInputCheckboxLayout({ label, input, infoElement, className }: ValidatedInputLayoutProps) {
    return (
        <div className={className}>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                {input}
                {label}
            </StackLayout>
            {infoElement}
        </div>
    );
}

type ValidatedInputLabelProps = { text: string; editorId?: string; isValid?: boolean; disabled?: boolean; className?: string };
function ValidatedInputLabel(props: ValidatedInputLabelProps) {
    return (
        <Label editorId={props.editorId} editorValid={props.isValid} editorDisabled={props.disabled} className={props.className}>
            {props.text}
        </Label>
    );
}

export const AvatarInput = ({
    name,
    onChange,
    value,
    initials,
    colorIndex,
    className
}: {
    name: string;
    onChange: (event: { target?: any; value?: string | null }) => void;
    value?: string | null;
    initials?: string;
    colorIndex?: number;
    className?: string;
}) => {
    const avatarClasses = ['k-icp-badged-image-input'];

    if (value) {
        avatarClasses.push('k-icp-badged-image-input-full');
    } else {
        avatarClasses.push('k-icp-badged-image-input-empty');
    }

    let border = true;
    if (!value && initials && colorIndex) {
        border = false;
        avatarClasses.push(`k-icp-avatar-simple-bg-${colorIndex}`);
    }

    return (
        <BaseBadgedImageInput
            name={name}
            onChange={onChange}
            value={value}
            uploadFunction={f => usersService.uploadAvatar(f)}
            className={className}
            border={border}
            accept={appConfig.avatars.allowedExtensions.join(', ')}
            emptyContent={initials ? <span>{initials}</span> : <ImageIcon className="k-icp-icon" />}
            avatarClasses={avatarClasses.join(' ')}
            filesValidators={defaultValidators.avatarValidators}
            canEdit={true}
        />
    );
};

export const LogoInput = ({
    name,
    onChange,
    ideaId,
    value,
    className,
    canEdit = true,
    noValueAvatarClasses,
    hint,
    error
}: {
    name: string;
    onChange: (event: { target?: any; value?: string | null }) => void;
    ideaId: string;
    value?: string | null;
    className?: string;
    canEdit?: boolean;
    noValueAvatarClasses?: string[];
    hint?: string;
    error?: string;
}) => {
    const avatarClasses = ['k-icp-badged-image-input'];

    if (value) {
        avatarClasses.push('k-icp-avatar-transparent-border');
        avatarClasses.push('k-icp-badged-image-input-full');
    } else {
        avatarClasses.push('k-icp-avatar-simple-bg-0');
        avatarClasses.push('k-icp-avatar-gradient-bg');
        avatarClasses.push('k-icp-badged-image-input-empty');
        avatarClasses.push('k-icp-avatar-default-text');

        if (noValueAvatarClasses) avatarClasses.push(...noValueAvatarClasses);
    }

    return (
        <BaseBadgedImageInput
            name={name}
            onChange={onChange}
            value={value}
            uploadFunction={f => ideasService.uploadLogo(ideaId, f)}
            border={!!value}
            className={className}
            accept={appConfig.logos.allowedExtensions.join(', ')}
            emptyContent={<span>LOGO</span>}
            avatarClasses={avatarClasses.join(' ')}
            filesValidators={defaultValidators.logoValidators}
            canEdit={canEdit}
            hint={hint}
            error={error}
        />
    );
};

export const BadgeUploadInput = ({
    id,
    onChange,
    value,
    upload,
    className
}: {
    id: string;
    onChange: (event: { target?: any; value?: string | null }) => void;
    upload: (file: File) => Promise<FileResponse>;
    value?: string | null;
    className?: string;
}) => {
    const avatarClasses = ['k-icp-badged-image-input'];

    if (value) {
        avatarClasses.push('k-icp-badged-image-input-full');
    } else {
        avatarClasses.push('k-icp-badged-image-input-empty');
    }

    return (
        <BaseBadgedImageInput
            name={id}
            onChange={onChange}
            value={value}
            uploadFunction={upload}
            className={className}
            border={true}
            accept={appConfig.logos.allowedExtensions.join(', ')}
            emptyContent={<ImageIcon className="k-icp-icon" />}
            avatarClasses={avatarClasses.join(' ')}
            filesValidators={defaultValidators.logoValidators}
            canEdit={true}
        />
    );
};

const BaseBadgedImageInput = ({
    name,
    onChange,
    value,
    emptyContent,
    border,
    className,
    avatarClasses,
    accept,
    uploadFunction,
    filesValidators,
    canEdit,
    hint,
    error
}: {
    name: string;
    onChange: (event: { target?: any; value?: string | null }) => void;
    value?: string | null;
    emptyContent?: ReactNode;
    border?: boolean;
    className?: string;
    avatarClasses: string;
    accept: string;
    uploadFunction: (file: File) => Promise<FileResponse>;
    filesValidators: ((value: File[] | null) => string | undefined)[];
    canEdit: boolean;
    hint?: string;
    error?: string;
}) => {
    const { uploadRef, onInputChange: onAvatarChange, clearValue: clearAvatar, validationError, dropZoneRef, draggedOver } = useSingleFileUpload(
        onChange,
        uploadFunction,
        value,
        filesValidators
    );

    const avatarType = value ? 'image' : 'text';

    return (
        <div
            className={combineClassNames(className, `k-icp-badged-image-drop-area${draggedOver ? ' k-icp-badged-image-drop-area-dragged-over' : ''} `)}
            ref={canEdit ? dropZoneRef : undefined}
        >
            <Avatar className={`k-avatar-xl ${avatarClasses}`} rounded="full" type={avatarType} border={border} themeColor="base">
                {canEdit && (
                    <input
                        ref={uploadRef}
                        autoComplete="off"
                        id={name}
                        type="file"
                        tabIndex={-1}
                        onChange={onAvatarChange}
                        accept={accept}
                        multiple={false}
                        className="k-icp-hidden-file-input"
                    />
                )}
                {value ? (
                    <React.Fragment>
                        <img src={value} alt="avatar" referrerPolicy="no-referrer" />
                        {canEdit && (
                            <StackLayout className="k-icp-badged-image-input-controls" align={{ horizontal: 'center' }}>
                                <label
                                    className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button"
                                    htmlFor={name}
                                >
                                    <span className="k-button-text">
                                        <EditIcon className="k-icp-icon" />
                                    </span>
                                </label>
                                <Button type="button" fillMode="flat" onClick={clearAvatar} className="k-icp-svg-icon-button">
                                    <DeleteIcon className="k-icp-icon" />
                                </Button>
                            </StackLayout>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {emptyContent}
                        {canEdit && (
                            <label htmlFor={name} className="k-icp-badged-image-input-empty-trigger k-align-items-center k-justify-content-center">
                                <UploadIcon className="k-icp-icon" />
                            </label>
                        )}
                    </React.Fragment>
                )}
            </Avatar>

            {hint && canEdit && <div className="k-form-hint k-mt-2">{hint}</div>}
            {(validationError || error) && <Error className="k-mt-2">{validationError || error}</Error>}

            <div className="k-icp-badged-image-drag-notice">
                <UploadIcon className="k-icp-icon" />
                <span className="k-mx-2 k-icp-subtle-text">Release here ...</span>
            </div>
        </div>
    );
};

export const FileUpload = ({
    name,
    filesValidators,
    onChange,
    value,
    accept,
    uploadFunction,
    multiple,
    label,
    hint
}: {
    name: string;
    filesValidators?: ((value: File[] | null) => string | undefined)[];
    onChange: (event: { target?: any; value?: string | string[] | null }) => void;
    value?: string[] | string | null;
    accept?: string;
    uploadFunction: (file: File) => Promise<FileResponse>;
    multiple: boolean;
    label?: string;
    hint?: string;
}) => {
    const { onInputChange, uploadRef, removeItem, validationError, draggedOver, dropZoneRef, uploading } = useFileUpload(
        onChange,
        multiple,
        uploadFunction,
        value,
        filesValidators
    );
    const values: string[] = !value ? [] : Array.isArray(value) ? value : [value];
    const Icon = draggedOver ? UploadIcon : ImageIcon;
    const showInput = !values.length && !uploading;

    return (
        <div>
            {label && (
                <Label editorId={name} editorValid={!validationError}>
                    {label}
                </Label>
            )}
            <div
                className={`k-rounded k-input-solid k-input k-text-center k-icp-file-upload k-gap-1 ${
                    showInput && draggedOver ? ' k-icp-file-upload-dragged-over' : ''
                }`}
                ref={dropZoneRef}
            >
                {showInput ? (
                    <React.Fragment>
                        <input
                            ref={uploadRef}
                            autoComplete="off"
                            id={name}
                            type="file"
                            tabIndex={-1}
                            onChange={onInputChange}
                            accept={accept}
                            multiple={multiple}
                            className="k-icp-hidden-file-input"
                        />
                        <Icon className="k-icp-icon" />
                        {draggedOver ? (
                            <span className="k-mx-2 k-icp-subtle-text">Release here ...</span>
                        ) : (
                            <span className="k-mx-2">
                                <span className="k-icp-subtle-text">Drag &amp; drop, Paste or </span>
                                <label className="k-link k-button-link-secondary" htmlFor={name}>
                                    Browse
                                </label>
                            </span>
                        )}
                    </React.Fragment>
                ) : uploading ? (
                    <LoadingIndicator size="small" />
                ) : (
                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                        {values.map(v => (
                            <div key={v} className="k-icp-file-upload-uploaded-item-wrapper">
                                <div className="k-icp-file-upload-uploaded-item">
                                    <img src={v} alt="" />
                                    <Button
                                        type="button"
                                        fillMode="flat"
                                        size="small"
                                        className="k-icp-file-upload-remove-item-btn k-icp-svg-icon-button"
                                        onClick={() => removeItem(v)}
                                    >
                                        <CloseIcon className="k-icp-icon" />
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </StackLayout>
                )}
            </div>
            {showInput && (
                <React.Fragment>
                    {hint && <Hint>{hint}</Hint>}
                    {validationError && <Error className="k-mt-2">{validationError}</Error>}
                </React.Fragment>
            )}
        </div>
    );
};

export function OptionalFieldLabel(props: ValidatedInputLabelProps) {
    return (
        <Label editorId={props.editorId} editorValid={props.isValid} editorDisabled={props.disabled} className={props.className}>
            <span>
                {props.text} <span className="k-text-disabled k-text-italic">(Optional)</span>
            </span>
        </Label>
    );
}

function EmailFieldLabelWithBackToLogin(props: ValidatedInputLabelProps) {
    const returnUrl = useReturnUrl();
    const intent = useAuthenticationIntent();

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-justify-content-between  k-mb-2">
            <ValidatedInputLabel {...props} className={combineClassNames(props.className, '!k-mb-0')} />

            <Link
                className={getButtonClassName({ fillMode: 'link', themeColor: 'secondary', size: 'small' })}
                to={urlWithQueryParams('/login', {
                    [returnUrlParamName]: returnUrl,
                    [authenticationIntentUrlParamName]: intent
                })}
            >
                Change email
            </Link>
        </StackLayout>
    );
}

export function EmailField({
    errorMessage,
    onChange,
    showBackToLogin,
    className,
    readOnly
}: {
    errorMessage?: ReactNode;
    onChange?: () => void;
    showBackToLogin?: boolean;
    className?: string;
    readOnly?: boolean;
}) {
    return (
        <Field
            name="emailAddress"
            component={ValidatedInput}
            labelType={showBackToLogin ? EmailFieldLabelWithBackToLogin : undefined}
            label="Email"
            type="email"
            validator={defaultValidators.emailValidators}
            errorMessage={errorMessage}
            onChange={onChange}
            className={className}
            autoComplete={readOnly ? undefined : 'email'}
            disabled={readOnly}
        />
    );
}

function PasswordFieldLabelWithForgotPassword(props: ValidatedInputLabelProps) {
    const returnUrl = useReturnUrl();

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-justify-content-between k-mb-2">
            <ValidatedInputLabel {...props} className={combineClassNames(props.className, '!k-mb-0')} />

            <Link
                className={getButtonClassName({ fillMode: 'link', themeColor: 'secondary', size: 'small' })}
                to={pathWithReturnUrl('/forgot-password', returnUrl)}
            >
                Forgot it?
            </Link>
        </StackLayout>
    );
}

function PasswordInput(params: InputProps) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="k-pos-relative">
            <Input {...params} className={combineClassNames('!k-pr-8', params.className)} type={showPassword ? 'text' : 'password'} />
            <Button
                type="button"
                fillMode="flat"
                size="small"
                className="!k-pos-absolute k-pos-middle-end k-icp-svg-icon-button k-mr-1"
                onClick={() => setShowPassword(s => !s)}
            >
                {showPassword ? <HidePasswordIcon className="k-icp-icon" /> : <ShowPasswordIcon className="k-icp-icon" />}
            </Button>
        </div>
    );
}

export const passwordRequirementsMessage = 'Password must contain: at least 8 characters, a lower case letter, an upper case letter, a number.';

export function PasswordField({
    showForgotPasswordLink,
    errorMessage,
    onChange,
    label,
    hideLabel,
    name,
    showHint,
    autoComplete
}: {
    showForgotPasswordLink?: boolean;
    errorMessage?: string;
    onChange?: () => void;
    label?: string;
    hideLabel?: boolean;
    name?: string;
    showHint?: boolean;
    autoComplete?: string;
}) {
    return (
        <Field
            name={name || 'password'}
            component={ValidatedInput}
            inputType={PasswordInput}
            labelType={showForgotPasswordLink ? PasswordFieldLabelWithForgotPassword : undefined}
            label={hideLabel ? undefined : label || 'Password'}
            validator={showHint ? defaultValidators.hintingPasswordValidators : defaultValidators.passwordValidators}
            errorMessage={errorMessage}
            onChange={onChange}
            autoComplete={autoComplete}
            hint={showHint ? passwordRequirementsMessage : undefined}
        />
    );
}

const wellKnownUrlProtocols = ['https://', 'http://'];
function tryGetWellKnownProtocolFromString(value?: string | null): [string | undefined, string | undefined | null] {
    if (!value) return [undefined, value];

    for (const wellKnownUrlProtocol of wellKnownUrlProtocols) {
        if (value.toLocaleLowerCase().startsWith(wellKnownUrlProtocol)) {
            return [value.slice(0, wellKnownUrlProtocol.length), value.slice(wellKnownUrlProtocol.length)];
        }
    }

    return [undefined, value];
}

export function UrlInput({
    icon,
    valid,
    className,
    value,
    onChange,
    ...rest
}: { icon?: string; valid?: boolean; value?: string | null; onChange: (e: { value?: string | null }) => void } & Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange'
>) {
    const [protocol, valueWithoutProtocol] = tryGetWellKnownProtocolFromString(value);
    const [defaultProtocol, setDefaultProtocol] = useState(protocol || wellKnownUrlProtocols[0]);

    const classNames = [];
    if (className) classNames.push(className);
    if (valid === false) classNames.push('k-invalid');
    if (rest.disabled) classNames.push('k-disabled');

    return (
        <span className={combineClassNames('k-textbox k-input k-input-md k-input-solid k-rounded-md', classNames.length ? classNames.join(' ') : undefined)}>
            {icon && (
                <span className="k-input-prefix">
                    <span
                        className={`k-icon k-i-${icon} k-icp-subtle-text k-ml-1
                `}
                        role="presentation"
                    ></span>
                </span>
            )}
            <input
                {...rest}
                className={combineClassNames('k-input-inner', icon ? '!k-pl-1' : undefined)}
                value={valueWithoutProtocol ?? ''}
                onChange={
                    onChange
                        ? e => {
                              if (!e.target.value) {
                                  onChange({ value: undefined });
                                  return;
                              }

                              const [newProtocol, newValueWithoutProtocol] = tryGetWellKnownProtocolFromString(e.target.value);
                              if (newProtocol) {
                                  setDefaultProtocol(newProtocol);
                                  onChange({ value: e.target.value });
                              } else onChange({ value: `${defaultProtocol}${newValueWithoutProtocol}` });
                          }
                        : undefined
                }
            />
        </span>
    );
}

export const ChangeOnBlurInput = forwardRef<InputHandle, InputProps>(function(props, ref) {
    const [tempValue, setTempValue] = useState<string>();
    const inputRef = useRef<InputHandle>(null);
    useImperativeHandle(ref, () => inputRef.current!);

    function isFocused() {
        if (!inputRef.current) return false;

        return inputRef.current.element === document.activeElement;
    }

    return (
        <Input
            ref={inputRef}
            {...props}
            value={tempValue !== undefined ? tempValue : props.value}
            onChange={e => {
                if (isFocused()) setTempValue(e.value);
                else {
                    props.onChange?.(e);
                    setTempValue(undefined);
                }
            }}
            onBlur={e => {
                if (tempValue !== undefined) {
                    props.onChange?.({ nativeEvent: e.nativeEvent, target: inputRef.current!, value: tempValue, syntheticEvent: e });
                    setTempValue(undefined);
                }
                props.onBlur?.(e);
            }}
            onKeyDown={e => {
                if (e.key === 'Enter' && tempValue !== undefined) {
                    props.onChange?.({ nativeEvent: e.nativeEvent, target: inputRef.current!, value: tempValue, syntheticEvent: e });
                    setTempValue(undefined);
                }
                props.onKeyDown?.(e);
            }}
        />
    );
});

export const ChangeOnBlurTextArea = forwardRef<TextAreaHandle, TextAreaProps>(function(props, ref) {
    const [tempValue, setTempValue] = useState<string>();
    const inputRef = useRef<TextAreaHandle>(null);
    useImperativeHandle(ref, () => inputRef.current!);

    function isFocused() {
        if (!inputRef.current) return false;

        return inputRef.current.element.current === document.activeElement;
    }

    return (
        <TextArea
            ref={inputRef}
            {...props}
            value={tempValue !== undefined ? tempValue : props.value}
            onChange={e => {
                if (isFocused()) setTempValue(e.value);
                else {
                    props.onChange?.(e);
                    setTempValue(undefined);
                }
            }}
            onBlur={e => {
                if (tempValue !== undefined) {
                    props.onChange?.({ nativeEvent: e.nativeEvent, target: inputRef.current!, value: tempValue, syntheticEvent: e.syntheticEvent });
                    setTempValue(undefined);
                }
                props.onBlur?.(e);
            }}
            onKeyDown={e => {
                if ((e.ctrlKey || e.metaKey) && e.key === 'Enter' && tempValue !== undefined) {
                    props.onChange?.({ nativeEvent: e.nativeEvent, target: inputRef.current!, value: tempValue, syntheticEvent: e });
                    setTempValue(undefined);
                }

                props.onKeyDown?.(e);
            }}
        />
    );
});

export function ChangeOnBlurTimePicker(props: TimePickerProps) {
    const pickerRef = useRef<TimePickerHandle>(null);
    const [tempValue, setTempValue] = useState<Date | null>();

    function isTimePickerInputFocused() {
        if (
            pickerRef.current &&
            pickerRef.current.dateInput &&
            pickerRef.current.dateInput.element &&
            document.activeElement === pickerRef.current.dateInput.element
        )
            return true;
        return false;
    }

    const tempValueRef = useRef(tempValue);
    tempValueRef.current = tempValue;
    const onChangeRef = useRef(props.onChange);
    onChangeRef.current = props.onChange;

    useEffect(() => {
        if (!pickerRef.current?.element) return;

        const pickerElement = pickerRef.current.element;

        function onPickerKeyDown(e: KeyboardEvent) {
            if (e.key === 'Enter' && tempValueRef.current !== undefined) {
                onChangeRef.current?.({
                    nativeEvent: e,
                    target: pickerRef.current!,
                    value: tempValueRef.current,
                    syntheticEvent: {
                        ...e,
                        nativeEvent: e,
                        target: e.target!,
                        isDefaultPrevented: () => e.defaultPrevented,
                        isPropagationStopped: () => false,
                        persist: () => {}
                    },
                    show: false
                });
                setTempValue(undefined);
            }
        }

        pickerElement.addEventListener('keydown', onPickerKeyDown);

        return () => {
            pickerElement.removeEventListener('keydown', onPickerKeyDown);
        };
    }, []);

    return (
        <TimePicker
            ref={pickerRef}
            {...props}
            value={tempValue !== undefined ? tempValue : props.value}
            onChange={e => {
                if (isTimePickerInputFocused()) setTempValue(e.value);
                else {
                    props.onChange?.(e);
                    setTempValue(undefined);
                }
            }}
            onBlur={e => {
                if (tempValue !== undefined) {
                    props.onChange?.({ nativeEvent: e.nativeEvent, target: pickerRef.current!, value: tempValue, syntheticEvent: e, show: false });
                    setTempValue(undefined);
                }
                props.onBlur?.(e);
            }}
            dateInput={KeyboardNavigatableDateInput}
        />
    );
}

export function ChangeOnBlurDateRangePicker(props: DateRangePickerProps) {
    const pickerRef = useRef<DateRangePickerHandle>(null);
    const [tempValue, setTempValue] = useState<SelectionRange>();

    function isPickerInputFocused() {
        if (!pickerRef.current || !pickerRef.current.element) return;

        const dateInputWrapperElements = Array.from(pickerRef.current.element.querySelectorAll('.k-dateinput'));
        const isDateInputFocused = dateInputWrapperElements.some(e => e.contains(document.activeElement));

        return isDateInputFocused;
    }

    return (
        <DateRangePicker
            ref={pickerRef}
            {...props}
            value={tempValue ?? props.value}
            onChange={e => {
                if (isPickerInputFocused()) setTempValue(e.value);
                else {
                    props.onChange?.(e);
                    setTempValue(undefined);
                }
            }}
            onBlur={e => {
                if (tempValue) {
                    props.onChange?.({ nativeEvent: e.nativeEvent, target: pickerRef.current!, value: tempValue, syntheticEvent: e });
                    setTempValue(undefined);
                }
                props.onBlur?.(e);
            }}
        />
    );
}

export type ButtonGroupSelectorOption<TValue> = {
    value: TValue;
    text: string;
    icon?: ComponentType<React.SVGProps<SVGSVGElement>>;
};

export function ButtonGroupSelector<TValue>({
    value,
    onChange,
    disabled,
    readOnly,
    options
}: {
    value?: TValue;
    onChange?: (e: { value: TValue | undefined }) => void;
    disabled?: boolean;
    readOnly?: boolean;
    options?: ButtonGroupSelectorOption<TValue>[];
}) {
    if (!options || !options.length) return null;

    return (
        <ButtonGroup disabled={disabled} className="k-icp-flat-button-group">
            {options.map(option => (
                <Button
                    key={option.text}
                    togglable={true}
                    fillMode="flat"
                    type="button"
                    selected={value === option.value}
                    onClick={onChange && !readOnly ? () => onChange({ value: value === option.value ? undefined : option.value }) : undefined}
                    disabled={readOnly && value !== option.value}
                >
                    {option.icon ? (
                        <SvgIconButtonContent icon={option.icon} gap={2} className="k-font-weight-normal">
                            {option.text}
                        </SvgIconButtonContent>
                    ) : (
                        <span className="k-font-weight-normal">{option.text}</span>
                    )}
                </Button>
            ))}
        </ButtonGroup>
    );
}

export const requiredValidator = (fieldName: string, templatedMessage = true) => {
    return (value: any) => {
        return value ? '' : templatedMessage ? `${fieldName} is required` : fieldName;
    };
};

export const maxLengthValidator = (fieldName: string, maxLength: number, templatedMessage = true) => {
    return (value: string | undefined | null) => {
        return !value || value.length <= maxLength ? '' : templatedMessage ? `${fieldName} should not exceed ${maxLength} characters` : fieldName;
    };
};

export const regExValidator = (regEx: RegExp, errorMessage: string) => {
    return (value: string | undefined | null) => {
        return !value || regEx.test(value) ? '' : errorMessage;
    };
};

export const fileExtensionValidator = (...allowedExtensions: string[]) => {
    return (value: File[] | null) => {
        if (!value || value.length === 0) return '';
        for (let index = 0; index < value.length; index++) {
            const file = value[index];
            const extension = getFileExtension(file?.name);

            const isExtensionAllowed = !!allowedExtensions.find(e => e.toLowerCase() === extension.toLowerCase());
            if (!isExtensionAllowed) return `'${extension}' files are not allowed. Allowed extensions are: ${allowedExtensions.join(', ')}`;
        }

        return '';
    };
};

export const fileSizeValidator = (maxSizeInBytes: number) => {
    return (value: File[] | null) => {
        if (!value || value.length === 0) return '';
        for (let index = 0; index < value.length; index++) {
            const file = value[index];
            if (!file) return '';

            const exceedsAllowedSize = file.size > maxSizeInBytes;
            if (exceedsAllowedSize) return `The selected file exceeds the maximum allowed size of ${(maxSizeInBytes / (1024 * 1024)).toFixed(1)} MB`;
        }

        return '';
    };
};

export const emailValidator = (value: string | undefined | null) => {
    if (!value || value.length === 0) return '';

    if (!isEmail(value)) return 'Invalid email address';

    return '';
};

export const urlValidator = (fieldName: string) => (value: string | undefined | null) => {
    if (!value || value.length === 0) return '';

    if (!isUrl(value, { require_protocol: true })) return `${fieldName} should be a valid URL`;

    return '';
};

export const postalCodeValidator = (fieldName: string, locale: PostalCodeLocale) => (value: string | undefined | null) => {
    if (!value || value.length === 0) return '';

    if (!isPostalCode(value, locale)) return `Invalid ${fieldName}`;
};

export const phoneValidator = (phone: string, fieldName: string) =>
    import('libphonenumber-js').then(phoneNumberModule => {
        if (!phone || phone.length === 0) return '';

        const parsedPhone = phoneNumberModule.parsePhoneNumberFromString(phone);
        if (!parsedPhone?.isValid()) return `${fieldName} should be a valid phone number starting with a country code`;

        return '';
    });

export const dateInThePastValidator = (filedName: string) => (value: Date | undefined | null) => {
    if (!value) return '';

    if (value > new Date()) return `${filedName} should be in the past`;

    return '';
};

export const dateInTheFutureValidator = (filedName: string) => (value: Date | undefined | null) => {
    if (!value) return '';

    if (value < new Date()) return `${filedName} should be in the future`;

    return '';
};

export const numberInRangeValidator = (fieldName: string, min?: number, max?: number) => (value: number | undefined | null) => {
    if (!value) return '';

    if (typeof min === 'number' && value < min) return `${fieldName} should be greater or equal to ${min}`;
    if (typeof max === 'number' && value > max) return `${fieldName} should be less than or equal to ${max}`;

    return '';
};

const getFileExtension = (fileName: string | undefined) => {
    if (!fileName) return '';
    const extensionIndex = fileName.lastIndexOf('.');
    if (extensionIndex === -1) return '';

    return fileName.substring(extensionIndex);
};

const ideaDescriptionLengthValidator = maxLengthValidator('Description', 500);
export const defaultValidators = {
    firstNameValidators: [requiredValidator('First name'), maxLengthValidator('First name', 50)],
    lastNameValidators: [requiredValidator('Last name'), maxLengthValidator('Last name', 50)],
    avatarValidators: [fileExtensionValidator(...appConfig.avatars.allowedExtensions), fileSizeValidator(appConfig.avatars.maxSize)],
    logoValidators: [fileExtensionValidator(...appConfig.logos.allowedExtensions), fileSizeValidator(appConfig.logos.maxSize)],
    ideaTitleValidators: [requiredValidator('Name'), maxLengthValidator('Name', 150)],
    ideaDescriptionValidators: ideaDescriptionLengthValidator,
    ideaDescriptionExtendedValidators: [requiredValidator('Description'), ideaDescriptionLengthValidator],
    feedbackDescriptionValidators: [requiredValidator('Description'), maxLengthValidator('Description', 1000)],
    feedbackScreenshotValidators: [fileExtensionValidator(...appConfig.feedback.screenshot.allowedExtensions), fileSizeValidator(appConfig.avatars.maxSize)],
    itemTextValidators: [requiredValidator('Item should not be empty', false), maxLengthValidator('Item', appConfig.canvas.item.maxLength)],
    emailValidators: [requiredValidator('Email'), emailValidator],
    passwordValidators: [requiredValidator('Password')],
    hintingPasswordValidators: [requiredValidator(passwordRequirementsMessage, false)],
    jobTitleValidators: [requiredValidator('Job title'), maxLengthValidator('Job title', 50)],
    companyNameValidators: [requiredValidator('Company name'), maxLengthValidator('Company name', 50)],
    linkedInValidators: [urlValidator('LinkedIn profile')],
    facebookValidators: [urlValidator('LinkedIn profile')],
    phoneNumberValidators: [requiredValidator('Phone number'), maxLengthValidator('Phone number', 50)],
    countryValidators: [requiredValidator('Country')],
    cityValidators: [requiredValidator('City'), maxLengthValidator('Company name', 50)],
    addressValidators: [requiredValidator('Address'), maxLengthValidator('Phone number', 350)]
};
