import { Activity, ActivityType } from '../../services/activitiesService';
import { ReachOutType } from '../../services/events';
import { reachOutLabel } from '../events/eventsDialogs';

export function getActivityTitle(activity: Activity) {
    switch (activity.type) {
        case ActivityType.Note:
            return `Note about ${activity.note.contact.firstName}`;
        case ActivityType.ReachOut:
            const reachOut = activity.reachOut;
            const isPast = reachOut.date < new Date();
            return `${isPast ? 'Reached' : 'Reach'} out to ${reachOut.contact.firstName}${
                reachOut.type !== ReachOutType.Other ? ` via ${reachOutLabel[reachOut.type]}` : ''
            }`;
        case ActivityType.Meeting:
            return `Meeting with ${activity.meeting.contact.firstName}`;
        case ActivityType.ResearchReachOut:
            const researchReachOut = activity.researchReachOut;
            return `${researchReachOut.sent ? 'Invited' : 'Invite'} ${researchReachOut.contact.firstName} for ${researchReachOut.research.title}`;
    }
}
