import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { Fragment, ReactElement, ReactNode } from 'react';
import { BoxItem, BoxType } from '../../services/canvasService';
import { getOrdinalSuffix } from '../../services/common/common.string';
import {
    CustomerProblemAlternativeSolutionHypothesis,
    CustomerProblemAlternativeSolutionSatisfactionHypothesis,
    CustomerProblemPainLevelHypothesis
} from '../../services/hypothesesService';
import { CanvasItemView } from '../canvas/canvasItemView';
import { InterviewItemGroupTitle } from '../interview/interviewItem';

export type AlternativeSolutionHypothesesGroup<
    TAlternativeSolutionHypothesis = CustomerProblemAlternativeSolutionHypothesis,
    TAlternativeSolutionSatisfactionHypothesis = CustomerProblemAlternativeSolutionSatisfactionHypothesis
> = {
    alternativeSolutionId: number;
    alternativeSolution?: BoxItem;
    alternativeSolutionHypotheses: TAlternativeSolutionHypothesis[];
    alternativeSolutionSatisfactionHypotheses: TAlternativeSolutionSatisfactionHypothesis[];
};

export type HypothesesByProblemAndTypeGroup<
    TPainLevelHypothesis = CustomerProblemPainLevelHypothesis,
    TAlternativeSolutionHypothesis = CustomerProblemAlternativeSolutionHypothesis,
    TAlternativeSolutionSatisfactionHypothesis = CustomerProblemAlternativeSolutionSatisfactionHypothesis
> = {
    customerProblemId: number;
    customerProblem?: BoxItem;
    painLevelHypotheses: TPainLevelHypothesis[];
    alternativeSolutionHypothesesGroups: AlternativeSolutionHypothesesGroup<TAlternativeSolutionHypothesis, TAlternativeSolutionSatisfactionHypothesis>[];
};
export function HypothesesByProblemAndTypeList<
    TPainLevelHypothesis = CustomerProblemPainLevelHypothesis,
    TAlternativeSolutionHypothesis = CustomerProblemAlternativeSolutionHypothesis,
    TAlternativeSolutionSatisfactionHypothesis = CustomerProblemAlternativeSolutionSatisfactionHypothesis
>({
    groups,
    renderPainLevelHypothesis,
    renderAlternativeSolutionHypothesis
}: {
    groups: HypothesesByProblemAndTypeGroup<TPainLevelHypothesis, TAlternativeSolutionHypothesis, TAlternativeSolutionSatisfactionHypothesis>[];
    renderPainLevelHypothesis: (hypothesis: TPainLevelHypothesis) => ReactElement;
    renderAlternativeSolutionHypothesis: (
        hypothesis: TAlternativeSolutionHypothesis | TAlternativeSolutionSatisfactionHypothesis,
        group: AlternativeSolutionHypothesesGroup<TAlternativeSolutionHypothesis, TAlternativeSolutionSatisfactionHypothesis>
    ) => ReactElement;
}) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6 k-icp-component-border">
            {groups.map((group, groupIndex) => (
                <Fragment key={group.customerProblemId}>
                    {groupIndex > 0 && <div className="k-separator" />}
                    <CustomerProblemHypothesesList problemOrdinal={groupIndex + 1} problem={group.customerProblem}>
                        {group.painLevelHypotheses.length > 0 && (
                            <HypothesesList title={`Pain-level hypothes${group.painLevelHypotheses.length === 1 ? 'i' : 'e'}s`}>
                                {group.painLevelHypotheses.map(hypothesis => renderPainLevelHypothesis(hypothesis))}
                            </HypothesesList>
                        )}

                        {group.alternativeSolutionHypothesesGroups.map(alternativeSolutionHypothesesGroup => (
                            <HypothesesList
                                key={alternativeSolutionHypothesesGroup.alternativeSolutionId}
                                title="Alternative solution hypotheses"
                                subTitle={
                                    <>
                                        Alternative solution:{' '}
                                        {alternativeSolutionHypothesesGroup.alternativeSolution ? (
                                            alternativeSolutionHypothesesGroup.alternativeSolution.content
                                        ) : (
                                            <Skeleton shape="text" className="!k-display-inline-block" style={{ width: 200 }} />
                                        )}
                                    </>
                                }
                            >
                                {alternativeSolutionHypothesesGroup.alternativeSolutionHypotheses.map(hypothesis =>
                                    renderAlternativeSolutionHypothesis(hypothesis, alternativeSolutionHypothesesGroup)
                                )}
                                {alternativeSolutionHypothesesGroup.alternativeSolutionSatisfactionHypotheses.map(hypothesis =>
                                    renderAlternativeSolutionHypothesis(hypothesis, alternativeSolutionHypothesesGroup)
                                )}
                            </HypothesesList>
                        ))}
                    </CustomerProblemHypothesesList>
                </Fragment>
            ))}
        </StackLayout>
    );
}

function CustomerProblemHypothesesList({ problemOrdinal, problem, children }: { problemOrdinal: number; problem?: BoxItem; children?: ReactNode }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6">
            <div>
                <InterviewItemGroupTitle className="k-mb-2 k-display-block">
                    Hypotheses for the {problemOrdinal}
                    {getOrdinalSuffix(problemOrdinal)} Customer Problem
                </InterviewItemGroupTitle>
                {problem ? (
                    <CanvasItemView box={BoxType.Problem} itemId={problem.id} className="!k-mb-0 k-w-fit" />
                ) : (
                    <Skeleton shape="rectangle" style={{ width: 300, height: 32 }} />
                )}
            </div>
            {children}
        </StackLayout>
    );
}

function HypothesesList({ title, subTitle, children }: { title: string; subTitle?: ReactNode; children?: ReactNode }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            <div>
                <strong>{title}</strong>
                {subTitle && <div className="k-fs-sm">{subTitle}</div>}
            </div>
            {children}
        </StackLayout>
    );
}
