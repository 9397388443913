import { useParams } from 'react-router-dom';
import { NotFoundException } from '../../services/httpServiceBase';
import {
    CustomerProblemAlternativeSolutionHypothesis,
    CustomerProblemAlternativeSolutionSatisfactionHypothesis,
    CustomerProblemPainLevelHypothesis,
    HypothesisGroup,
    HypothesisLikelihood,
    HypothesisType
} from '../../services/hypothesesService';

export const hypothesisGroupLabelMap: Record<HypothesisGroup, string> = {
    [HypothesisGroup.CustomerProblem]: 'Customers-Problems',
    [HypothesisGroup.Solution]: 'Solution',
    [HypothesisGroup.GoToMarket]: 'Go-to-market',
    [HypothesisGroup.FeasibilityAndViability]: 'Feasibility & Viability'
};

export const hypothesisGroupUrlPathMap: Record<HypothesisGroup, string> = {
    [HypothesisGroup.CustomerProblem]: 'customers-problems',
    [HypothesisGroup.Solution]: 'solution',
    [HypothesisGroup.GoToMarket]: 'gtm',
    [HypothesisGroup.FeasibilityAndViability]: 'feasibility'
};

export const hypothesisTypeLabelMap: Record<HypothesisType, string> = {
    [HypothesisType.PainLevel]: 'Customer Problem pain-level',
    [HypothesisType.AlternativeSolutionUsage]: 'Customer Problem alternative solution',
    [HypothesisType.AlternativeSolutionSatisfaction]: 'Customer Problem alternative solution satisfaction'
};

export const hypothesisLikelihoodLabelMap: Record<HypothesisLikelihood, string> = {
    [HypothesisLikelihood.VeryLikely]: 'Very likely',
    [HypothesisLikelihood.Likely]: 'Likely',
    [HypothesisLikelihood.Neutral]: 'Neutral',
    [HypothesisLikelihood.Unlikely]: 'Unlikely',
    [HypothesisLikelihood.VeryUnlikely]: 'Very unlikely'
};

//In order to preserve the order we need to map an array
export const likelihoodDropDownValues = [
    HypothesisLikelihood.VeryLikely,
    HypothesisLikelihood.Likely,
    HypothesisLikelihood.Neutral,
    HypothesisLikelihood.Unlikely,
    HypothesisLikelihood.VeryUnlikely
].map(l => {
    return { text: hypothesisLikelihoodLabelMap[l], value: l };
});

export const hypothesisValidators: Record<HypothesisType, (value: any) => string | undefined> = {
    [HypothesisType.PainLevel]: function(value: Partial<CustomerProblemPainLevelHypothesis>): string | undefined {
        if ([value.customerProblemId, value.customerSegmentId, value.percentage, value.painLevel].includes(undefined)) return 'Hypothesis is incomplete';
    },
    [HypothesisType.AlternativeSolutionUsage]: function(value: Partial<CustomerProblemAlternativeSolutionHypothesis>): string | undefined {
        if ([value.customerProblemId, value.customerSegmentId, value.percentage, value.alternativeSolutionId].includes(undefined))
            return 'Hypothesis is incomplete';
    },
    [HypothesisType.AlternativeSolutionSatisfaction]: function(value: Partial<CustomerProblemAlternativeSolutionSatisfactionHypothesis>): string | undefined {
        if ([value.customerProblemId, value.customerSegmentId, value.percentage, value.alternativeSolutionId, value.satisfactionLevel].includes(undefined))
            return 'Hypothesis is incomplete';
    }
};

export function useHypothesisParams() {
    const { ideaId, hypothesisGroup: hypothesisGroupUrlPath, hypothesisId: hypothesisIdParam } = useParams();
    if (!ideaId || !hypothesisIdParam) throw new NotFoundException();

    const hypothesisGroup = Object.keys(hypothesisGroupUrlPathMap).find(function(path): path is HypothesisGroup {
        return hypothesisGroupUrlPathMap[path as keyof typeof hypothesisGroupUrlPathMap] === hypothesisGroupUrlPath;
    });
    if (!hypothesisGroup) throw new NotFoundException();
    const hypothesisId = parseInt(hypothesisIdParam);

    return { ideaId, hypothesisGroup, hypothesisId };
}
