import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import { TaskEditorProps } from '.';
import { useTaskManageContactsInResearch } from '../../../hooks/researchHooks';
import { ReactComponent as RemoveIcon } from '../../../icons/x.svg';
import emptyResearchContactsIllustrationUrl from '../../../images/empty-contacts-illustration-small.svg';
import { combineClassNames } from '../../../services/common';
import { ResearchContactsEditorParams } from '../../../services/journeyService';
import { ResearchContact } from '../../../services/researchService';
import { PersonSimpleView } from '../../contacts/view';
import { ResearchContactPicker } from '../../research/researchContactPicker';
import LoadingIndicator from '../../ui/loadingIndicator';
import { EditorErrorsList } from './shared/editorErrorsList';
import { EditorMainArea } from './shared/editorMainArea';

export function ResearchContactsEditor(props: TaskEditorProps<ResearchContactsEditorParams>) {
    const { confirmRemoveDialog, addContactToResearch, removeContactFromResearch } = useTaskManageContactsInResearch(props);
    const researchContacts = props.taskData.researchContacts?.[props.params.researchId];
    const showErrors = props.isEditing && !!props.errors?.length;

    return (
        <EditorMainArea hasError={showErrors}>
            {researchContacts ? (
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-5 k-icp-component-border">
                    <ResearchContactsEditorSection title="Search for a contact or create a new one">
                        {/* Show picker only after the step is show since otherwise the width of the popup of the autocomplete is 0 */}
                        {!props.isHidden && (
                            <ResearchContactPicker
                                ideaId={props.ideaId}
                                researchId={props.params.researchId}
                                customerSegmentId={props.params.customerSegmentId}
                                disabled={!props.isEditing}
                                onSelected={selectedContact => addContactToResearch(selectedContact.id)}
                                valid={!showErrors}
                                showAddButton
                            />
                        )}
                    </ResearchContactsEditorSection>
                    <div className="k-separator" />
                    <ResearchContactsEditorSection
                        title="Selected contacts"
                        additionalText={researchContacts.length ? `Count: ${researchContacts.length}` : undefined}
                    >
                        {researchContacts.length ? (
                            <ResearchContactsList
                                contacts={researchContacts}
                                onRemove={
                                    props.isEditing
                                        ? contact => removeContactFromResearch(contact.contact.id, `${contact.contact.firstName} ${contact.contact.lastName}`)
                                        : undefined
                                }
                            />
                        ) : (
                            <NoResearchContactsView />
                        )}
                    </ResearchContactsEditorSection>
                    {confirmRemoveDialog}
                </StackLayout>
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}
            <EditorErrorsList isEditing={props.isEditing} errors={props.errors} />
        </EditorMainArea>
    );
}

function ResearchContactsEditorSection({ title, additionalText, children }: { title: string; additionalText?: string; children?: ReactNode }) {
    return (
        <div>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-justify-content-between k-gap-2 k-mb-2">
                <span className="k-fs-lg k-font-medium">{title}</span>
                {additionalText && <span className="k-fs-sm">{additionalText}</span>}
            </StackLayout>
            {children}
        </div>
    );
}

function ResearchContactsEditorPanel({ children, className }: { children?: ReactNode; className?: string }) {
    return <div className={combineClassNames('k-content k-rounded', className)}>{children}</div>;
}

function NoResearchContactsView() {
    return (
        <ResearchContactsEditorPanel className="k-text-center k-pt-10 k-pb-8 k-px-4">
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-3">
                <img src={emptyResearchContactsIllustrationUrl} alt="No schedules" width="64" height="64" />
                <span>No selected contacts yet</span>
            </StackLayout>
        </ResearchContactsEditorPanel>
    );
}

function ResearchContactsList({ contacts, onRemove }: { contacts?: ResearchContact[]; onRemove?: (contact: ResearchContact) => void }) {
    if (!contacts || !contacts.length) return null;

    return (
        <ResearchContactsEditorPanel>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
                {contacts.map((contact, contactIndex) => (
                    <PersonSimpleView
                        key={contact.contact.id}
                        person={contact.contact}
                        className={combineClassNames('k-p-2 k-pl-4', contactIndex ? 'k-icp-bordered-top k-icp-component-border' : undefined)}
                        additionalContent={
                            <Button
                                size="large"
                                fillMode="flat"
                                className="k-icp-svg-icon-button"
                                disabled={!onRemove}
                                onClick={onRemove && (() => onRemove(contact))}
                            >
                                <RemoveIcon className="k-icp-icon" />
                            </Button>
                        }
                        renderAsLink={true}
                        showJobTitle={true}
                    />
                ))}
            </StackLayout>
        </ResearchContactsEditorPanel>
    );
}
