import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { TextArea } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ValidatedInput, defaultValidators, requiredValidator } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { Account } from '../../services/accountsService';
import { getPersonFullName } from '../../services/common';
import { Idea, ideasService } from '../../services/ideasService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setIdea as setGlobalIdea } from '../../state/idea/ideaSlice';

const startupOwnerValidators = [requiredValidator('Owner')];
export default function StartupEditPage() {
    const { ideaId } = useParams();
    const [idea, setIdea] = useState<Idea | null>();
    const currentUser = useAppSelector(s => s.user);
    const [associatedAccounts, setAssociatedAccounts] = useState<Account[] | undefined>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!ideaId) return;

        (async () => {
            const ideaToEdit = await ideasService.get(ideaId);

            if (ideaToEdit) {
                await ideasService.getAssociatedAccounts(ideaToEdit.uniqueId).then(setAssociatedAccounts);
            }

            setIdea(ideaToEdit);
        })();
    }, [ideaId]);

    const onSubmitIdea = async (data: { [name: string]: any }) => {
        if (!ideaId) return;

        const updatedIdea = await ideasService.partialUpdate(ideaId, {
            title: data.title,
            description: data.description,
            accountUniqueId: data.startupOwner.uniqueId
        });
        setIdea(updatedIdea);
        dispatch(setGlobalIdea(updatedIdea));

        navigate('..');
    };

    const associatedAccountsDropdownData = associatedAccounts?.map(acc => ({
        text: getPersonFullName(acc.owner.firstName, acc.owner.lastName) + (currentUser && currentUser.userId === acc.owner.userId ? ' (You)' : ''),
        uniqueId: acc.uniqueId
    }));
    
    const isLoading = !idea || !associatedAccountsDropdownData;
    return (
        <>
            <H1 className="heading-row">Edit startup info</H1>
            <div className="page-content-middle">
                {!isLoading ? (
                    <Form
                        initialValues={{ ...idea, startupOwner: associatedAccountsDropdownData?.filter(acc => acc.uniqueId === idea.account.uniqueId)[0] }}
                        ignoreModified={true}
                        onSubmit={onSubmitIdea}
                        render={formRenderProps => (
                            <FormElement>
                                <StackLayout className="k-gap-6" orientation="vertical">
                                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6 k-px-8 k-pb-8">
                                        <Field
                                            name="title"
                                            label="Name"
                                            component={ValidatedInput}
                                            maxLength={150}
                                            validator={defaultValidators.ideaTitleValidators}
                                        />
                                        <Field
                                            name="startupOwner"
                                            label="Owner"
                                            component={ValidatedInput}
                                            inputType={DropDownList}
                                            validator={startupOwnerValidators}
                                            data={associatedAccountsDropdownData}
                                            textField="text"
                                            dataItemKey="uniqueId"
                                            disabled={associatedAccountsDropdownData.length <= 1 || idea.account.owner.userId !== currentUser?.userId}
                                        />
                                        <Field
                                            label="Description"
                                            name="description"
                                            component={ValidatedInput}
                                            validator={defaultValidators.ideaDescriptionValidators}
                                            inputType={TextArea}
                                            maxLengthCount={500}
                                            rows={3}
                                        />
                                    </StackLayout>
                                    <StackLayout className="k-gap-6" align={{ horizontal: 'center' }}>
                                        <Button type="submit" themeColor="primary" size="large" disabled={!formRenderProps.allowSubmit}>
                                            Update startup info
                                        </Button>
                                        <Button type="button" fillMode="flat" size="large" onClick={() => navigate('..')}>
                                            Cancel
                                        </Button>
                                    </StackLayout>
                                </StackLayout>
                            </FormElement>
                        )}
                    />
                ) : (
                    <div className="k-text-center">
                        <LoadingIndicator size="big" />
                    </div>
                )}
            </div>
        </>
    );
}
