import { hypothesisGroupLabelMap } from '../../components/hypotheses/common';
import { EmptyHypothesesView } from '../../components/hypotheses/emptyHypothesesView';
import { HypothesisGroup } from '../../services/hypothesesService';

export function GoToMarketHypothesesPage() {
    return (
        <EmptyHypothesesView
            hypothesesGroupName={hypothesisGroupLabelMap[HypothesisGroup.GoToMarket]}
            assumptionsDescriptions="your planned approach to introducing and selling to your target customers"
        />
    );
}
