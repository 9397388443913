import { Button } from '@progress/kendo-react-buttons';
import { Avatar, StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { H1, P } from '../../components/ui/typography';
import dreamIllustration from '../../images/dream-illustration.svg';
import dreamSelectedIllustration from '../../images/dream-selected-illustration.svg';
import iterateIllustration from '../../images/iterate-illustration.svg';
import iterateSelectedIllustration from '../../images/iterate-selected-illustration.svg';
import teamIllustration from '../../images/team-illustration.svg';
import teamSelectedIllustration from '../../images/team-selected-illustration.svg';
import veskoAvatar from '../../images/vesko.png';
import { ReactComponent as Path1Image } from '../../images/welcome-path-1.svg';
import { ReactComponent as Path2Image } from '../../images/welcome-path-2.svg';
import { ReactComponent as Path3Image } from '../../images/welcome-path-3.svg';
import { ReactComponent as Path4Image } from '../../images/welcome-path-4.svg';
import { ReactComponent as Path5Image } from '../../images/welcome-path-5.svg';
import { ReactComponent as Path6Image } from '../../images/welcome-path-6.svg';
import { googleTagManager } from '../../scripts/googleTagManager';
import {
    cleanUpRevealRoads,
    disableRoadsAutoLayout,
    enableRoadsAutoLayout,
    positionAndScaleRoads,
    revealOnAnswerCommitted,
    revealScreenInitialState
} from '../../scripts/welcomePageRoadsAnimation';
import { UserFlag, usersService } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentUser } from '../../state/user/userSlice';

const commitmentQuestions = [
    {
        key: 1,
        text: 'Creating a successful startup is full of exciting and big challenges and I am motivated to overcome them.',
        confirmation: "I'm in!",
        illustration: dreamIllustration,
        illustrationSelected: dreamSelectedIllustration
    },
    {
        key: 2,
        text: 'My success requires continuously challenging my assumptions, validating them and staying open-minded.',
        confirmation: "Let's do it!",
        illustration: iterateIllustration,
        illustrationSelected: iterateSelectedIllustration
    },
    {
        key: 3,
        text: "I know I'm good and while I will continue to develop myself, I will be on the lookout for the right teammates to support each other.",
        confirmation: "I'm ready!",
        illustration: teamIllustration,
        illustrationSelected: teamSelectedIllustration
    }
];

export const WelcomePage = () => {
    const currentUser = useAppSelector(s => s.user);
    const [answers, setAnswers] = useState(() => {
        const initialAnswers: Record<number, boolean> = {};
        commitmentQuestions.forEach(q => (initialAnswers[q.key] = false));

        return initialAnswers;
    });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser) return;
        if (currentUser.flags.includes(UserFlag.CommittedEntrepreneur)) navigate('/');
    }, [currentUser, navigate]);

    useEffect(() => {
        positionAndScaleRoads();
        enableRoadsAutoLayout();
        revealScreenInitialState();

        return () => {
            disableRoadsAutoLayout();
            cleanUpRevealRoads();
        };
    }, []);

    useEffect(() => {
        const keys = Object.keys(answers);
        for (let key = keys.length; key > 0; key--) {
            if (answers[key]) {
                revealOnAnswerCommitted(key);
                break;
            }
        }
    }, [answers]);

    const commitmentBottomRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!commitmentQuestions.find(q => answers[q.key] === true)) {
            setTimeout(() => {
                commitmentBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 2200);
        } else {
            commitmentBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [answers]);

    const onCommitment = async () => {
        if (!currentUser || currentUser.flags.includes(UserFlag.CommittedEntrepreneur)) return;

        const updatedUser = await usersService.partiallyUpdateCurrent({ flags: [...currentUser.flags, UserFlag.CommittedEntrepreneur] });
        dispatch(setCurrentUser(updatedUser));
        googleTagManager.reportPassedWelcomeScreenEvent();
    };

    return (
        <div className="main-column page-content-section">
            <div className="roads-layout">
                <StackLayout align={{ horizontal: 'center', vertical: 'top' }} orientation="vertical" className="page-content-middle page-content--xl k-gap-14">
                    <H1 className="!k-mb-4 path-stop">Welcome on board!</H1>
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-6">
                        <StackLayout
                            id="ceo-avatar"
                            orientation="vertical"
                            align={{ horizontal: 'center', vertical: 'middle' }}
                            className="path-stop"
                            style={{ opacity: 0 }}
                        >
                            <Avatar rounded="full" type="image" themeColor="base" border={true} className="k-avatar-xl k-icp-avatar-simple-bg-0 k-mb-2">
                                <img src={veskoAvatar} alt="Vesko - Founder &amp; CEO" />
                            </Avatar>
                            <strong className="k-fs-lg">Vesko</strong>
                            <span className="k-fs-sm">Founder &amp; CEO</span>
                            <span className="k-fs-sm">icanpreneur</span>
                        </StackLayout>
                        <div
                            id="ceo-message"
                            className="k-popover !k-rounded-lg k-pos-relative k-icp-popover-with-bg k-icp-popover-link-alt !k-z-0"
                            style={{ opacity: 0 }}
                        >
                            <div className="k-popover-callout k-callout-w -zi-1" style={{ marginTop: -36 }}></div>
                            <div className="k-popover-body formatted-content-area">
                                <P>Hey {currentUser?.firstName},</P>
                                <P>
                                    My name is Vesko and I'm extremely happy to welcome you to our platform. Before we get down to work, from myself and on
                                    behalf of our team at <strong>icanpreneur</strong>, we wish you a fulfilling journey!
                                </P>
                                <P>
                                    No one can promise you that becoming a successful entrepreneur will be easy because honestly chances are it won't be.
                                    However, what we can promise you is that icanpreneur <strong>will guide you</strong> and <strong>help you focus</strong> on
                                    what's most important in any step of the way, while being part of icanpreneur will{' '}
                                    <strong>ensure you are never alone</strong>.
                                </P>
                                <P>You just need to show up regularly, be committed to your goals, put the needed deliberate effort and never ever give up!</P>
                                <P>Godspeed!</P>
                            </div>
                        </div>
                    </StackLayout>
                    <H1 id="ready-to-commit-heading" className="path-stop" style={{ opacity: '0' }}>
                        Ready to commit?
                    </H1>
                    <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-14">
                        <StackLayout
                            orientation="vertical"
                            align={{ horizontal: 'start', vertical: 'top' }}
                            className="k-gap-9 k-align-self-stretch commitment-question-collection"
                        >
                            {commitmentQuestions.map(
                                (question, index) =>
                                    (question.key === 1 || answers[question.key - 1] === true) && (
                                        <StackLayout
                                            id={`commitment-question-${question.key}`}
                                            key={question.key}
                                            orientation="vertical"
                                            className={`k-gap-3 k-py-6 k-px-6 path-stop commitment-question ${
                                                index % 2 === 0 ? '!k-rounded-br-0' : '!k-rounded-bl-0 k-align-self-end'
                                            }`}
                                            style={{ opacity: 0 }}
                                        >
                                            <StackLayout
                                                orientation="horizontal"
                                                align={{ horizontal: 'start', vertical: 'middle' }}
                                                className="k-gap-6 k-align-self-stretch"
                                            >
                                                <img
                                                    src={answers[question.key] ? question.illustrationSelected : question.illustration}
                                                    alt=""
                                                    width="86"
                                                    height="86"
                                                />
                                                <P className="k-text-left">{question.text}</P>
                                            </StackLayout>
                                            <CommitmentInput
                                                id={`answer-${question.key}`}
                                                value={answers[question.key]}
                                                confirmation={question.confirmation}
                                                onChange={v => {
                                                    setAnswers(answers => ({ ...answers, [question.key]: v }));
                                                }}
                                            />
                                            <span className="k-icp-font-weight-medium commitment-question-index ">
                                                {commitmentQuestions.length - question.key + 1}
                                            </span>
                                        </StackLayout>
                                    )
                            )}
                        </StackLayout>

                        {!commitmentQuestions.find(q => answers[q.key] === false) && (
                            <Button
                                id="start-my-journey-button"
                                themeColor="primary"
                                size="large"
                                className="k-mt-11 path-stop"
                                style={{ opacity: 0 }}
                                onClick={onCommitment}
                            >
                                Start my Journey
                            </Button>
                        )}
                    </StackLayout>

                    <div ref={commitmentBottomRef} />
                </StackLayout>

                <Path1Image id="road-path-initial-1" className="welcome-page-path" />
                <Path2Image id="road-path-initial-2" className="welcome-page-path" />
                <Path3Image id="road-path-initial-3" className="welcome-page-path" />
                <Path4Image id="road-path-answer-1" className="welcome-page-path" />
                <Path5Image id="road-path-answer-2" className="welcome-page-path" />
                <Path6Image id="road-path-answer-3" className="welcome-page-path" />
            </div>
        </div>
    );
};

export const CommitmentInput = ({
    id,
    value,
    confirmation,
    onChange
}: {
    id: string;
    value: boolean | undefined;
    confirmation: string;
    onChange: (value: boolean) => void;
}) => {
    const commitSelected = value === true;
    return (
        <>
            <div className="k-text-center">
                <Button
                    themeColor="primary"
                    togglable={true}
                    size="medium"
                    selected={commitSelected}
                    disabled={!!commitSelected}
                    onClick={() => onChange(!commitSelected)}
                >
                    {confirmation}
                </Button>
            </div>
        </>
    );
};
