import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, createContext, forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAsRef } from '../../hooks/commonHooks';
import { ConfirmDialogConfig, useConfirmDialog } from '../../hooks/dialogHooks';
import { useSoleToggle } from '../../hooks/toggleHooks';
import { ReactComponent as GuidanceIcon } from '../../icons/book.svg';
import { ReactComponent as PhasesIcon } from '../../icons/phase.svg';
import executedInterviewIllustration from '../../images/interview/execution-illustration.svg';
import hypothesesEvaluatedInterviewIllustration from '../../images/interview/hypotheses-illustration.svg';
import insightsCapturedInterviewIllustration from '../../images/interview/insights-illustration.svg';
import reviewedInterviewIllustration from '../../images/interview/review-illustration.svg';
import { googleTagManager } from '../../scripts/googleTagManager';
import { Interview, InterviewStage, interviewsService } from '../../services/interviewsService';
import { RealTimeUpdateInterviewEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import { InterviewModalData, ModalView, showModal } from '../../state/ui/modalSlice';
import { DialogPreTitlePopoverAction } from '../common/dialogPreTitlePopoverAction';
import { InterviewExecuteAndReviewContent } from './entries/interviewExecution';
import { InterviewHypothesesContent } from './hypotheses/interviewHypotheses';
import { InterviewInsightsContent } from './insights/interviewInsights';
import { InterviewContentLoader } from './interviewModalLayout';
import { InterviewPhasesBreadcrumb, InterviewPhasesBreadcrumbProps } from './interviewPhasesBreadcrumb';
import { InterviewStageGuidance } from './interviewStage';
import { InterviewSummaryContent } from './summary/interviewSummary';

export function InterviewModal({
    ideaId,
    interviewId,
    show,
    onClose,
    initiallyShowGuidance,
    viewAs
}: {
    ideaId: string;
    interviewId: number;
    show?: boolean;
    onClose?: () => void;
    initiallyShowGuidance?: boolean;
    viewAs?: InterviewStage;
}) {
    const [interview, setInterview] = useState<Interview>();
    const { element: leaveDialogElement, show: showLeaveDialog } = useConfirmDialog();
    const dispatch = useAppDispatch();
    const closeModalRef = useAsRef(closeModal);
    const currentViewStage = viewAs ?? interview?.stage;
    const currentUserRole = useAppSelector(s => s.idea.role);
    const isReadOnly =
        (interview !== undefined && viewAs !== undefined && interview.stage !== viewAs) ||
        (currentUserRole !== undefined && currentUserRole === UserRole.Viewer);
    const [titleBarContent, setTitleBarContent] = useState<ReactNode>();
    const interviewTitleContextValue = useMemo<InterviewTitleContextValue>(
        () => ({
            content: titleBarContent,
            setContent: setTitleBarContent
        }),
        [titleBarContent]
    );
    const contentComponentRef = useRef<InterviewContentHandle>(null);
    const interviewOperationsContextValue = useMemo<InterviewOperationsContextValue>(
        () => ({
            async updateStage(stage) {
                const updatedInterview = await interviewsService.updateInterviewStage(ideaId, interviewId, stage);
                setInterview(updatedInterview);
                return updatedInterview;
            }
        }),
        [ideaId, interviewId]
    );

    useEffect(() => {
        async function loadInterview() {
            const interview = await interviewsService.getInterview(ideaId, interviewId);
            if (!interview.initialized) {
                await interviewsService.initializeInterview(ideaId, interviewId);
            }
            setInterview(interview);
        }

        loadInterview();

        function onInterviewUpdated(e: RealTimeUpdateInterviewEventData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;

            loadInterview();
        }

        async function onInterviewDeleted(e: RealTimeUpdateInterviewEventData) {
            if (e.ideaId !== ideaId || e.interviewId !== interviewId) return;

            await closeModalRef.current();
            dispatch(addNotification({ content: 'The interview was deleted' }));
        }

        realTimeUpdatesEventHub.addEventListener('interview', 'update', onInterviewUpdated);
        realTimeUpdatesEventHub.addEventListener('interview', 'delete', onInterviewDeleted);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview', 'update', onInterviewUpdated);
            realTimeUpdatesEventHub.removeEventListener('interview', 'delete', onInterviewDeleted);
        };
    }, [closeModalRef, dispatch, ideaId, interviewId]);

    async function closeModal() {
        await contentComponentRef.current?.onClose?.();
        onClose?.();
    }

    async function onMainAction() {
        const completionResult = await contentComponentRef.current?.onComplete?.();
        if (completionResult === false) return;

        if (!interview || interview.stage === InterviewStage.Complete) {
            await closeModal();
            return;
        }

        const nextInterviewStage = getNextInterviewStage(interview.stage);
        await interviewsService.updateInterviewStage(ideaId, interviewId, nextInterviewStage);
        googleTagManager.reportInterviewStageChanged(nextInterviewStage);
        await closeModal();
        const openInterviewData = getOpenInterviewModalData(nextInterviewStage, ideaId, interviewId);
        if (openInterviewData)
            dispatch(
                showModal({
                    view: ModalView.OpenInterviewModal,
                    data: openInterviewData
                })
            );
    }

    async function closeModalWithConfirmation() {
        if (!interview || isReadOnly) {
            await closeModal();
            return;
        }

        const confirmationData = getInterviewConfirmationData(interview.stage, closeModal);
        if (!confirmationData) {
            await closeModal();
            return;
        }

        showLeaveDialog(confirmationData);
    }

    if (!show) return null;

    return (
        <InterviewTitleContext.Provider value={interviewTitleContextValue}>
            <InterviewOperationsContext.Provider value={interviewOperationsContextValue}>
                <Dialog
                    className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-pretitle-actions"
                    title={
                        <InterviewTitleBar
                            ideaId={ideaId}
                            interview={interview}
                            onMainAction={onMainAction}
                            initiallyShowGuidance={initiallyShowGuidance}
                            view={currentViewStage}
                            hideButton={isReadOnly}
                        />
                    }
                    onClose={closeModalWithConfirmation}
                >
                    {currentViewStage ? (
                        <InterviewContent
                            ref={contentComponentRef}
                            ideaId={ideaId}
                            interviewId={interviewId}
                            interview={interview}
                            view={currentViewStage}
                            isReadOnly={isReadOnly}
                        />
                    ) : (
                        <InterviewContentLoader />
                    )}
                </Dialog>
                {leaveDialogElement}
            </InterviewOperationsContext.Provider>
        </InterviewTitleContext.Provider>
    );
}

type InterviewOperationsContextValue = {
    updateStage(stage: InterviewStage): Promise<Interview>;
};

const InterviewOperationsContext = createContext<InterviewOperationsContextValue>({
    updateStage() {
        throw new Error('No InterviewOperationsContext found');
    }
});

export function useInterviewOperationsContext() {
    return useContext(InterviewOperationsContext);
}

function getInterviewConfirmationData(currentStage: InterviewStage, confirmCallback: Function): ConfirmDialogConfig | undefined {
    switch (currentStage) {
        case InterviewStage.NotStarted:
            return undefined;
        case InterviewStage.Started:
            return {
                title: 'Leave interview',
                content: 'Do you want to leave the active interview? You can always come back.',
                confirmButtonText: 'Leave interview',
                confirmButtonThemeColor: 'primary',
                cancelButtonText: 'Back to interview',
                callback: confirmCallback,
                buttonsLayout: 'center'
            };
        case InterviewStage.PendingReview:
            return {
                title: 'Leave review',
                content: 'Do you want to leave the active review? You can always come back.',
                confirmButtonText: 'Leave review',
                confirmButtonThemeColor: 'primary',
                cancelButtonText: 'Back to review',
                callback: confirmCallback,
                buttonsLayout: 'center'
            };
        case InterviewStage.PendingInsightCapture:
            return {
                title: 'Leave insights',
                content: 'Do you want to leave insight discovery? You can always come back.',
                confirmButtonText: 'Leave insights',
                confirmButtonThemeColor: 'primary',
                cancelButtonText: 'Back to insights',
                callback: confirmCallback,
                buttonsLayout: 'center'
            };
        case InterviewStage.PendingHypothesesEvaluation:
            return {
                title: 'Leave hypotheses',
                content: 'Do you want to leave hypotheses evaluation? You can always come back.',
                confirmButtonText: 'Leave hypotheses',
                confirmButtonThemeColor: 'primary',
                cancelButtonText: 'Back to hypotheses',
                callback: confirmCallback,
                buttonsLayout: 'center'
            };
        case InterviewStage.Complete:
            return undefined;
    }
}

function getOpenInterviewModalData(stage: InterviewStage, ideaId: string, interviewId: number): InterviewModalData | undefined {
    switch (stage) {
        case InterviewStage.NotStarted:
        case InterviewStage.Started:
            return undefined;
        case InterviewStage.PendingReview:
            return {
                ideaId: ideaId,
                interviewId: interviewId,
                graphic: {
                    url: executedInterviewIllustration,
                    width: 86,
                    height: 86,
                    description: 'Executed interview'
                },
                heading: 'Good job!',
                description:
                    'Now that you are done conducting the interview, you can review what you have. Look through the answers received and what questions got left out, if any.',
                mainButtonText: 'Start the review now',
                cancelButtonText: "I'll do the review later"
            };
        case InterviewStage.PendingInsightCapture:
            return {
                ideaId: ideaId,
                interviewId: interviewId,
                graphic: {
                    url: reviewedInterviewIllustration,
                    width: 86,
                    height: 86,
                    description: 'Reviewed interview'
                },
                heading: 'Well done!',
                description: 'Now that you are ready with the review, the next step is capturing insights.',
                mainButtonText: 'Capture insights now',
                cancelButtonText: "I'll do the insights later"
            };
        case InterviewStage.PendingHypothesesEvaluation:
            return {
                ideaId: ideaId,
                interviewId: interviewId,
                graphic: {
                    url: insightsCapturedInterviewIllustration,
                    width: 86,
                    height: 86,
                    description: 'Interview insights'
                },
                heading: 'Nice!',
                description: 'With insights captured for this interview, you can check on your hypotheses and evaluate them.',
                mainButtonText: 'Evaluate hypotheses now',
                cancelButtonText: "I'll evaluate hypotheses later"
            };
        case InterviewStage.Complete:
            return {
                ideaId: ideaId,
                interviewId: interviewId,
                graphic: {
                    url: hypothesesEvaluatedInterviewIllustration,
                    width: 86,
                    height: 86,
                    description: 'Interview hypotheses'
                },
                heading: 'You rock!',
                description: 'Now that you evaluated all applicable hypotheses for this interview, you are done. You can check on everything in the summary.',
                mainButtonText: 'View interview summary',
                cancelButtonText: "I'll do it later"
            };
    }
}

function getNextInterviewStage(stage: InterviewStage) {
    switch (stage) {
        case InterviewStage.NotStarted:
        case InterviewStage.Started:
            return InterviewStage.PendingReview;
        case InterviewStage.PendingReview:
            return InterviewStage.PendingInsightCapture;
        case InterviewStage.PendingInsightCapture:
            return InterviewStage.PendingHypothesesEvaluation;
        case InterviewStage.PendingHypothesesEvaluation:
            return InterviewStage.Complete;
    }

    throw new Error(`No interview stage after: ${stage}`);
}

function getInterviewTitle(interview: Interview, stage: InterviewStage) {
    const commonTitle = `Interview with ${interview.contact.firstName} ${interview.contact.lastName}`;
    if (stage === InterviewStage.NotStarted || stage === InterviewStage.Started) return commonTitle;
    if (stage === InterviewStage.PendingReview) return `Reviewing: ${commonTitle}`;
    if (stage === InterviewStage.PendingInsightCapture) return `Insights: ${commonTitle}`;
    if (stage === InterviewStage.PendingHypothesesEvaluation) return `Hypotheses: ${commonTitle}`;
    if (stage === InterviewStage.Complete) return `Summary: ${commonTitle}`;

    throw new Error(`Unknown interview stage: ${stage}`);
}

function getInterviewMainButtonText(interview: Interview): string | null {
    if (interview.stage === InterviewStage.NotStarted || interview.stage === InterviewStage.Started) return 'Finish interview';
    if (interview.stage === InterviewStage.PendingReview) return 'Done with the review';
    if (interview.stage === InterviewStage.PendingInsightCapture) return 'Done with the insights';
    if (interview.stage === InterviewStage.PendingHypothesesEvaluation) return `Done with hypotheses`;

    return null;
}

type InterviewTitleContextValue = { content?: ReactNode; setContent: (content: ReactNode | undefined) => void };
const InterviewTitleContext = createContext<InterviewTitleContextValue>({
    setContent() {
        throw new Error('No interview title context');
    }
});

export function useInterviewTitleContext() {
    return useContext(InterviewTitleContext);
}

function InterviewTitleBar({
    ideaId,
    interview,
    onMainAction,
    initiallyShowGuidance,
    view,
    hideButton
}: {
    ideaId: string;
    interview?: Interview;
    onMainAction?: () => void;
    initiallyShowGuidance?: boolean;
    view?: InterviewStage;
    hideButton?: boolean;
}) {
    const stage = view ?? interview?.stage;
    const modalTitle = interview && stage && getInterviewTitle(interview, stage);
    const buttonText = interview && getInterviewMainButtonText(interview);
    const showButton = !hideButton && (!view || !interview || view === interview.stage);
    const interviewTitleContext = useContext(InterviewTitleContext);
    const buttonElement = showButton ? (
        buttonText === undefined ? (
            <Skeleton shape="rectangle" style={{ width: 127, height: 32 }} />
        ) : buttonText ? (
            <Button type="button" fillMode="outline" themeColor="secondary" onClick={onMainAction}>
                {buttonText}
            </Button>
        ) : (
            undefined
        )
    ) : (
        undefined
    );

    return (
        <>
            <div className="k-icp-pretitle-actions">
                <InterviewGuidance openInitially={initiallyShowGuidance}>
                    {interview && <InterviewStageGuidance stage={interview.stage} className="k-fs-sm k-vstack k-gap-2" />}
                </InterviewGuidance>
                {interview && stage && stage !== InterviewStage.Complete && (
                    <InterviewPhases ideaId={ideaId} interviewId={interview.id} currentInterviewStage={interview.stage} />
                )}
            </div>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-flex-1 k-mr-2">
                <div className="k-flex-1 k-text-ellipsis">{modalTitle ? <span>{modalTitle}</span> : <Skeleton shape="text" style={{ width: 300 }} />}</div>
                {interviewTitleContext.content}
                <StackLayout align={{ horizontal: 'end', vertical: 'middle' }} className="k-flex-1">
                    {buttonElement}
                </StackLayout>
            </StackLayout>
        </>
    );
}

function InterviewGuidance({ children, openInitially }: { children?: ReactNode; openInitially?: boolean }) {
    const [showGuidance, toggleShowGuidance] = useSoleToggle(false);

    const initiallyOpenedRef = useRef(false);
    useEffect(() => {
        if (!children || !openInitially || initiallyOpenedRef.current) return;

        initiallyOpenedRef.current = true;
        toggleShowGuidance();
    }, [children, openInitially, toggleShowGuidance]);

    return (
        <DialogPreTitlePopoverAction isOpen={showGuidance} toggleIsOpen={toggleShowGuidance} icon={GuidanceIcon} title="Guidance">
            {children}
        </DialogPreTitlePopoverAction>
    );
}

function InterviewPhases(props: InterviewPhasesBreadcrumbProps) {
    const [showPhases, toggleShowPhases] = useSoleToggle(false);

    return (
        <DialogPreTitlePopoverAction isOpen={showPhases} toggleIsOpen={toggleShowPhases} icon={PhasesIcon} title="Phases">
            <InterviewPhasesBreadcrumb
                {...props}
                onPhaseClick={() => {
                    toggleShowPhases();
                    props.onPhaseClick?.();
                }}
            />
        </DialogPreTitlePopoverAction>
    );
}

export type InterviewContentHandle = { onClose?: () => Promise<void> | void; onComplete?: () => Promise<boolean> | void };

const InterviewContent = forwardRef<
    InterviewContentHandle,
    { ideaId: string; interviewId: number; view?: InterviewStage; interview?: Interview; isReadOnly?: boolean }
>(function InterviewContent({ ideaId, interviewId, view, interview, isReadOnly }, ref) {
    if (!view) return <InterviewContentLoader />;

    switch (view) {
        case InterviewStage.NotStarted:
        case InterviewStage.Started:
        case InterviewStage.PendingReview:
            return (
                <InterviewExecuteAndReviewContent
                    ref={ref}
                    ideaId={ideaId}
                    interviewId={interviewId}
                    interview={interview}
                    validateEntries={view === InterviewStage.PendingReview}
                    isReadOnly={isReadOnly}
                />
            );
        case InterviewStage.PendingInsightCapture:
            return <InterviewInsightsContent ref={ref} ideaId={ideaId} interviewId={interviewId} interview={interview} isReadOnly={isReadOnly} />;
        case InterviewStage.PendingHypothesesEvaluation:
            return <InterviewHypothesesContent ref={ref} ideaId={ideaId} interviewId={interviewId} interview={interview} isReadOnly={isReadOnly} />;
        case InterviewStage.Complete:
            return <InterviewSummaryContent ideaId={ideaId} interviewId={interviewId} interview={interview} />;
    }
});
