import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequireCanvasBoxItemsInZone } from '../../components/canvas/canvasItemsZone';
import { useCountryFor } from '../../components/common/countries';
import { PersonEditor, defaultDropDownItem, useAdvancedPersonValidation } from '../../components/contacts/edit';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { useStickyFooter } from '../../hooks/commonHooks';
import { BoxType } from '../../services/canvasService';
import { ContactTag, FullPerson, PersonEditData, contactsService } from '../../services/contactsService';
import { RealTimeUpdatePersonEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

export function EditPersonPage() {
    const { ideaId, personId: personIdString } = useParams();
    const personId = parseInt(personIdString ?? '');
    const [person, setPerson] = useState<FullPerson>();
    const [initialCountry, countriesList] = useCountryFor(person);
    const customerSegments = useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);
    const advancedPersonValidation = useAdvancedPersonValidation();

    const navigate = useNavigate();

    useEffect(() => {
        if (!personId) return;
        contactsService.getPersonById(ideaId!, personId).then(setPerson);
    }, [personId, ideaId]);

    const dispatch = useAppDispatch();
    useEffect(() => {
        const handlePersonDelete = (e: RealTimeUpdatePersonEventData) => {
            if (e.ideaId !== ideaId || e.personId !== personId) return;

            dispatch(addNotification({ content: 'The person was deleted' }));
            navigate('..');
        };

        realTimeUpdatesEventHub.addEventListener('contact', 'personDelete', handlePersonDelete);

        return () => realTimeUpdatesEventHub.removeEventListener('contact', 'personDelete', handlePersonDelete);
    }, [dispatch, ideaId, navigate, personId]);

    const isLoaded = typeof person !== 'undefined' && typeof initialCountry !== 'undefined';
    const footerRef = useStickyFooter(isLoaded);

    return (
        <>
            <H1 className="!k-mb-6 !k-px-6">Edit contact</H1>
            {isLoaded ? (
                <Form
                    onSubmit={async (data: Record<string, any>) => {
                        if (!(await advancedPersonValidation.validate(data.emailAddress, data.phoneNumber))) return;

                        const updateData: PersonEditData = {
                            firstName: data.firstName,
                            lastName: data.lastName,
                            emailAddress: data.emailAddress || null,
                            phoneNumber: data.phoneNumber || null,
                            sex: data.sex === defaultDropDownItem ? undefined : data.sex,
                            birthDate: data.birthDate,
                            maritalStatus: data.maritalStatus === defaultDropDownItem ? undefined : data.maritalStatus,

                            address: data.address || null,
                            city: data.city || null,
                            countryCode: data.country?.code,
                            facebook: data.facebook || null,
                            linkedIn: data.linkedIn || null,
                            twitter: data.twitter || null,
                            picture: data.picture,

                            referredByContactId: data.referredByContact ? data.referredByContact.id : undefined,
                            jobTitle: data.jobTitle || null,
                            companyId: data.company ? data.company.id : undefined,

                            workPhoneNumber: data.workPhoneNumber || null,
                            otherAddress: data.otherAddress || null,
                            secondaryEmailAddress: data.secondaryEmailAddress || null,

                            educationLevel: data.educationLevel === defaultDropDownItem ? undefined : data.educationLevel,
                            educationField: data.educationField || null,

                            customerSegmentIds: data.customerSegmentIds,
                            tagIds: (data.tags as ContactTag[] | undefined)?.map(t => t.id)
                        };

                        await contactsService.updatePerson(ideaId!, person.id, updateData);
                        navigate('./..');
                    }}
                    initialValues={{ ...person, country: initialCountry }}
                    ignoreModified={true}
                    render={formRenderProps => (
                        <FormElement noValidate={true}>
                            <PersonEditor
                                ideaId={ideaId!}
                                personId={person.id}
                                countriesList={countriesList}
                                customerSegments={customerSegments}
                                className="k-px-6"
                                reachOutFieldsErrorMessage={advancedPersonValidation.reachOutFieldsErrorMessage}
                                phoneNumberErrorMessage={advancedPersonValidation.phoneNumberErrorMessage}
                                onReachOutFieldChange={advancedPersonValidation.onReachOutFieldChange}
                            />
                            <StackLayout
                                ref={r => (footerRef.current = r?.element ?? null)}
                                align={{ horizontal: 'center', vertical: 'top' }}
                                className="k-mt-11 k-gap-4 k-px-6 k-py-3 sticky-footer"
                            >
                                <Button themeColor="primary" type="submit" size="large" disabled={!formRenderProps.allowSubmit}>
                                    Save changes
                                </Button>
                                <Button type="reset" size="large" onClick={() => navigate('./..')}>
                                    Discard
                                </Button>
                            </StackLayout>
                        </FormElement>
                    )}
                />
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}
        </>
    );
}
