import { InterviewStage } from './interviewsService';

class InterviewStageGuidanceContentProvider {
    getGuidance(stage: InterviewStage): string[] | string {
        switch (stage) {
            case InterviewStage.NotStarted:
            case InterviewStage.Started:
                return [
                    'Think of the script as a reminder and let the conversation flow naturally.',
                    'Aim to capture as much of what interviewee says as possible.',
                    "Avoid interpretations and focus on capturing the interviewee's words verbatim.",
                    'Keep in mind that you can always add new questions during the interview.',
                    "Don't bother whether you capture everything in the right place as you can later fix and review.",
                    'Having typos while capturing is OK and you can fix them in the review.',
                    "Stay open-minded as your goal is to discover things that you don't know upfront."
                ];
            case InterviewStage.PendingReview:
                return [
                    'Ensure the interviewee answers are captured under the right questions.',
                    'Add additional questions if there are answers outside the script.',
                    'Fix any typos or grammar that might lead to misunderstanding.',
                    'Slight edits for improved clarity of the verbatim quotations are OK as long as meaning is kept.',
                    'Annotate for clarity when verbatim lacks contextual detail.'
                ];
            case InterviewStage.PendingInsightCapture:
                return [
                    'Remember, insights reflect both validations and learnings.',
                    'Go through each question and capture relevant insights.',
                    'Add supporting verbatim quotes to each insight.',
                    'Reuse already discovered insights from previous interviews and supplement with new quotes.',
                    'Capture as many high-quality quotes as possible to ensure credibility.'
                ];
            case InterviewStage.PendingHypothesesEvaluation:
                return [
                    'Remember, evaluating a hypothesis is done by collecting a data point from each interviewee.',
                    'Go through each hypothesis and evaluate its respective data point.',
                    'Add supporting verbatim quotes on which you base your conclusions.',
                    'Be mindful of your biases to ensure maximum objectivity.'
                ];
            case InterviewStage.Complete:
                return 'View a summary of the interview, showcasing the coverage of questions, captured insights, and evaluated hypotheses. Find details about the interviewee and options to review the complete interview, insights, and hypothesis results.';
        }
    }
}

export const interviewStageGuidanceContentProvider = new InterviewStageGuidanceContentProvider();
