import { useCallback, useEffect, useRef, useState } from 'react';
import { BillingInfo, PaymentMethod, PricingData, StripeSetupIntent, stripeBillingService } from '../services/stripeBillingService';

export const usePaymentData = () => {
    const [pricingData, setPricingData] = useState<PricingData>();
    const [billingInfo, setBillingInfo] = useState<BillingInfo>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>();

    useEffect(() => {
        Promise.all([
            stripeBillingService.getPricing(),
            stripeBillingService.getCurrentBillingInfo(),
            stripeBillingService.getCurrentDefaultPaymentMethod()
        ]).then(([pricingData, billingInfo, paymentMethod]) => {
            setPricingData(pricingData);
            setBillingInfo(billingInfo);
            setPaymentMethod(paymentMethod);
        });
    }, []);

    return { pricingData, billingInfo, paymentMethod };
};

export const useBillingInfo = () => {
    const [billingInfo, setBillingInfo] = useState<BillingInfo>();

    useEffect(() => {
        stripeBillingService.getCurrentBillingInfo().then(billingInfo => {
            setBillingInfo(billingInfo);
        });
    }, []);

    const updateBillingInfo = async (billingInfo: BillingInfo) => {
        const updatedInfo = await stripeBillingService.updateBillingInfo(billingInfo);
        setBillingInfo(updatedInfo);
    };

    return { billingInfo, updateBillingInfo };
};

export const usePaymentSetupIntent = (loadIntent?: boolean) => {
    const [paymentSetupIntent, setPaymentSetupIntent] = useState<StripeSetupIntent | undefined>();
    const setupIntentRequestInProgress = useRef(false);
    const requestPaymentIntent = useCallback(async () => {
        if (setupIntentRequestInProgress.current) return;
        setupIntentRequestInProgress.current = true;
        try {
            const paymentSetupIntent = await stripeBillingService.createPaymentSetupIntent();
            setPaymentSetupIntent(paymentSetupIntent);
        } finally {
            setupIntentRequestInProgress.current = false;
        }
    }, []);

    useEffect(() => {
        if (!loadIntent) return;
        requestPaymentIntent();
    }, [loadIntent, requestPaymentIntent]);

    const resetIntent = useCallback(async () => {
        setPaymentSetupIntent(undefined);
        await requestPaymentIntent();
    }, [requestPaymentIntent]);

    const cancelIntent = useCallback(async () => {
        setPaymentSetupIntent(undefined);

        if (paymentSetupIntent) {
            try {
                await stripeBillingService.cancelPaymentSetupIntent(paymentSetupIntent.id);
            } catch (e) {
                console.error(e);
            }
        }
    }, [paymentSetupIntent]);

    return [paymentSetupIntent, resetIntent, cancelIntent] as const;
}
