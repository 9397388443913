import { useEffect, useRef } from 'react';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { useReturnUrl } from '../../hooks/routerHooks';
import { stripeBillingService } from '../../services/stripeBillingService';

export function BillingPortalPage() {
    const returnUrl = useReturnUrl(`${window.location.origin}/`);
    const requestInProgressRef = useRef(false);

    useEffect(() => {
        if (requestInProgressRef.current) return;

        requestInProgressRef.current = true;
        stripeBillingService
            .getBillingPortalUrl(returnUrl)
            .then(portalUrl => {
                window.location.replace(portalUrl);
            })
            .finally(() => (requestInProgressRef.current = false));
    }, [returnUrl]);

    return <LoadingIndicator size="big" className="k-centered" />;
}
