import { ErrorWithOperationDisplayName } from "./common";
import { HttpServiceBase } from "./httpServiceBase";
import { ReducedUser } from "./usersService";

export interface Account {
    uniqueId: string;
    owner: ReducedUser;
}

class AccountsService extends HttpServiceBase {
    constructor() {
        super('/api/accounts');
    }

    @ErrorWithOperationDisplayName('Get account by id')
    get(accountId: string): Promise<Account> {
        return this.performRequest<Account>({ path: `/${accountId}` });
    }

    @ErrorWithOperationDisplayName('Get associated accounts')
    getAssociatedAccounts(): Promise<Account[]> {
        return this.performRequest<Account[]>({ path: `/mine` });
    }
}

export const accountsService = new AccountsService();