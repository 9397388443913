import { TaxIdItem } from 'stripe-tax-utils';
import { SubscriptionType } from '../../services/authenticationService';
import { PriceDiscount, TaxId, TaxIdType } from '../../services/stripeBillingService';
import { maxLengthValidator, postalCodeValidator, regExValidator, requiredValidator } from '../ui/inputs';

type PostalCodeSettings = { label: string; validators: ((value: any) => string | undefined)[] };
export const CountriesPostalCodesSettings: Record<string, PostalCodeSettings> = {
    US: {
        label: 'ZIP',
        validators: [requiredValidator('ZIP'), postalCodeValidator('ZIP', 'US')]
    },
    GB: {
        label: 'Postal code',
        validators: [requiredValidator('Postal code'), postalCodeValidator('Postal code', 'GB')]
    },
    CA: {
        label: 'Postal code',
        validators: [requiredValidator('Postal code'), postalCodeValidator('Postal code', 'CA')]
    }
};
const seatsValidators = [requiredValidator('Seats')];
const customerTypeValidators = [requiredValidator('Customer type')];
const companyNameValidators = [requiredValidator('Company name'), maxLengthValidator('Company name', 101)];
const nameValidators = [requiredValidator('Name'), maxLengthValidator('Name', 101)];
const uicValidators = [requiredValidator('UIC'), regExValidator(/^[0-9]{9}$/, 'UIC is invalid')];
export const UICCountryCodes = new Set(['BG']);

export const AllowedTaxIdTypes = [TaxIdType.EUVat];

export const BillingInfoValidators = {
    seatsValidators,
    customerTypeValidators,
    companyNameValidators,
    nameValidators,
    uicValidators
};

export const collectTaxIds = (countryCode: string, dataTaxIds?: TaxId[], uic?: string, applicableTaxTypes?: TaxIdItem['type'][]): TaxId[] => {
    const taxIds: TaxId[] = [];
    if (UICCountryCodes.has(countryCode) && uic) taxIds.push({ type: TaxIdType.BGUic, value: uic });
    if (applicableTaxTypes && dataTaxIds) {
        const applicableTaxIds = dataTaxIds.filter(t => applicableTaxTypes.includes(t.type as any) && t.value);
        taxIds.push(...applicableTaxIds);
    }

    return taxIds;
};

export function getCurrencySymbol(currencyCode: string) {
    if (currencyCode === 'eur') return '€';
    if (currencyCode === 'usd') return '$';

    throw new Error('Unsupported currency: ' + currencyCode);
}

export function stringifyPriceAmount(priceAmount: number) {
    return Number.isInteger(priceAmount) ? priceAmount.toFixed() : priceAmount.toFixed(2);
}

export function getDiscountedPriceAmount(priceAmount: number, discount?: PriceDiscount | null) {
    if (!discount) return priceAmount;

    if (discount.percentOff) return ((100 - discount.percentOff) * priceAmount) / 100;
    if (discount.amountOff) return Math.max(0, priceAmount - discount.amountOff);

    return priceAmount;
}

export function getPriceAmountForSubscriptionType(
    initialPriceAmount: number,
    initialSubscriptionType: SubscriptionType,
    targetSubscriptionType: SubscriptionType
) {
    if (initialSubscriptionType === targetSubscriptionType) return initialPriceAmount;

    if (targetSubscriptionType === SubscriptionType.Annual) return initialPriceAmount * 12;

    return initialPriceAmount / 12;
}

export type VolumeDiscountDefinition = {
    minSeats: number;
    maxSeats?: number;
    discountPercentage: number;
};

export const volumeDiscounts: VolumeDiscountDefinition[] = [
    { minSeats: 2, maxSeats: 5, discountPercentage: 5 },
    { minSeats: 6, maxSeats: 10, discountPercentage: 10 },
    { minSeats: 11, discountPercentage: 15 }
];

export function getApplicableVolumeDiscount(seatsCount?: number) {
    if (seatsCount === undefined) return undefined;

    return volumeDiscounts.find(
        volumeDiscount => volumeDiscount.minSeats <= seatsCount && (volumeDiscount.maxSeats === undefined || volumeDiscount.maxSeats >= seatsCount)
    );
}

export function getVolumeDiscountedPriceAmount(priceAmount: number, volumeDiscount?: VolumeDiscountDefinition) {
    if (!volumeDiscount) return priceAmount;

    return ((100 - volumeDiscount.discountPercentage) * priceAmount) / 100;
}
