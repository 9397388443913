import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import TopNav from '../../components/topNav/topNav';
import { H1, P } from '../../components/ui/typography';
import { authenticationService } from '../../services/authenticationService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { resetCurrentUser } from '../../state/user/userSlice';

export default function LogoutPage() {
    const isAuthenticated = useAppSelector(s => s.user) !== null;
    const dispatch = useAppDispatch();
    const isLoggedOutRef = useRef(false);

    useEffect(() => {
        if (!isAuthenticated || isLoggedOutRef.current) return;
        isLoggedOutRef.current = true;

        dispatch(resetCurrentUser());
        authenticationService.logout();
    }, [dispatch, isAuthenticated]);

    return (
        <div className="page">
            <TopNav />
            <div className="page-content page-content-section hex-top-right-bg">
                <div className="page-content-middle k-text-center">
                    <H1 className="!k-mt-18 !k-mb-20">You are now logged out</H1>
                    <P className="!k-mb-8">Forgot to do something?</P>
                    <Link to="/" className="k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md">
                        Log in again
                    </Link>
                </div>
            </div>
        </div>
    );
}
