import { useLayoutEffect, useRef, useState } from 'react';

export function HtmlWithShowMoreByMarker({ children, className }: { children?: string; className?: string }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!children || !wrapperRef.current) return;

        function createToggleElement(text: string) {
            const toggleElement = document.createElement('span');
            toggleElement.className = 'k-button-link-secondary k-cursor-pointer';
            toggleElement.innerText = text;
            toggleElement.onclick = function() {
                setIsExpanded(e => !e);
            };

            return toggleElement;
        }

        function truncate(node: Node): boolean {
            let truncateMarkerFound = false;
            for (let childNodeIndex = 0; childNodeIndex < node.childNodes.length; childNodeIndex++) {
                const childNode = node.childNodes[childNodeIndex];
                if (truncateMarkerFound) {
                    childNode.remove();
                    childNodeIndex--;
                    continue;
                }

                if (childNode.nodeType === Node.COMMENT_NODE && childNode.nodeValue && childNode.nodeValue === '[truncate]') {
                    truncateMarkerFound = true;

                    childNode.replaceWith('... ', createToggleElement('Show more'));
                    childNodeIndex++;
                } else truncateMarkerFound = truncate(childNode);
            }

            return truncateMarkerFound;
        }

        function appendCollapseElement(node: Node) {
            let lastNodeWithoutChildren = node;
            while (lastNodeWithoutChildren.childNodes.length) {
                lastNodeWithoutChildren = lastNodeWithoutChildren.lastChild!;
            }

            lastNodeWithoutChildren.parentNode?.append(' ', createToggleElement('Show less'));
        }

        function clearNode(node: Node) {
            while (node.firstChild) {
                node.removeChild(node.lastChild!);
            }
        }

        function transferChildren(sourceNode: Node, targetNode: Node) {
            clearNode(targetNode);
            while (sourceNode.firstChild) {
                targetNode.appendChild(sourceNode.firstChild);
            }
        }

        const wrapper = document.createElement('div');
        wrapper.innerHTML = children;

        if (isExpanded) {
            appendCollapseElement(wrapper);
            transferChildren(wrapper, wrapperRef.current);
        } else {
            const truncated = truncate(wrapper);
            if (truncated) transferChildren(wrapper, wrapperRef.current);
            else wrapperRef.current.innerHTML = children;
        }
    }, [children, isExpanded]);

    if (!children) return null;

    return <div ref={wrapperRef} className={className} />;
}
