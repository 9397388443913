import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Record<string, boolean> = {};

export const soleToggleSlice = createSlice({
    name: 'soleToggle',
    initialState,
    reducers: {
        registerToggle: (state, action: PayloadAction<{ id: string; state: boolean }>) => {
            if (typeof state[action.payload.id] === 'undefined') state[action.payload.id] = action.payload.state;
        },
        unregisterToggle: (state, action: PayloadAction<string>) => {
            delete state[action.payload];
        },
        alternateToggle: (state, action: PayloadAction<string>) => {
            for (const toggleId of Object.keys(state)) {
                if (!Object.prototype.hasOwnProperty.call(state, toggleId)) continue;
                const toggleState = state[toggleId];
                if (toggleId === action.payload) {
                    state[toggleId] = !toggleState;
                } else if (toggleState) {
                    state[toggleId] = false;
                }
            }
        },
        resetAllToggles: state => {
            for (const toggleId of Object.keys(state)) {
                if (!Object.prototype.hasOwnProperty.call(state, toggleId)) continue;
                const toggleState = state[toggleId];
                if (toggleState) {
                    state[toggleId] = false;
                }
            }
        },
    },
});

export const { registerToggle, unregisterToggle, alternateToggle, resetAllToggles } = soleToggleSlice.actions;

export default soleToggleSlice.reducer;
