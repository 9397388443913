import { realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { store } from '../store';
import { loadMembersActivityForIdea } from './ideaMembersSlice';

realTimeUpdatesEventHub.addEventListener('idea', 'focusedChanged', e => {
    const currentIdeaId = store.getState().ideaMembers.ideaId;
    if (!currentIdeaId || currentIdeaId !== e.ideaId) return;

    store.dispatch(loadMembersActivityForIdea(e.ideaId));
});
