import { ErrorWithOperationDisplayName } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { ReducedResearch } from './researchService';
import { ReducedUser } from './usersService';

export type TimetableTime = {
    id: string;
    hour: number;
    minute: number;
};
export type TimetableTimeValue = Omit<TimetableTime, 'id'>;

export type TimetableEntry = {
    dayOfWeek: number;
    startTimes: TimetableTime[];
};

export type TimetableDay = {
    id: string;
    date: number;
    month: number;
    year: number;
};
export type TimetableDayValue = Omit<TimetableDay, 'id'>;

export type SpecialTimetableEntry = {
    date: TimetableDay;
    startTimes: TimetableTime[];
};

export type Timetable = {
    entries: TimetableEntry[];
    specialEntries: SpecialTimetableEntry[];
};

export type ScheduleData = {
    title: string;
    description?: string;
    locationOptions: LocationOption[];
    durationMinutes: number;
    bufferBeforeMinutes?: number;
    bufferAfterMinutes?: number;
    allowSameDayBooking?: boolean;
    timeZone: string;
    startTime: Date;
    endTime: Date;
    timetable: Timetable;
};

export type ResearchScheduleData = Pick<ScheduleData, 'locationOptions' | 'timeZone' | 'timetable'> & { userId: string };

export type FullSchedule = ScheduleData & {
    id: number;
    user: ReducedUser;
    code: string;
    relatedResearch: ReducedResearch[];
};

export type Schedule = Omit<FullSchedule, 'timetable'>;

export enum LocationOptionType {
    InPerson = 'InPerson',
    PhoneCall = 'PhoneCall',
    Online = 'Online',
    Other = 'Other'
}

export type LocationOption = {
    type: LocationOptionType;
    details: string;
};

export enum SchedulesSortBy {
    Recent = 'Recent',
    Alphabetical = 'Alphabetical'
}

export type Schedules<TSchedule = Schedule> = { schedules: TSchedule[]; totalCount: number };

class SchedulesService extends HttpServiceBase {
    constructor() {
        super('/api/scheduling');
    }

    private ensureDateFields<TItem extends Schedule | FullSchedule>(schedule: TItem): TItem {
        dateTimeService.ensureDateField(schedule, 'startTime');
        dateTimeService.ensureDateField(schedule, 'endTime');

        return schedule;
    }

    @ErrorWithOperationDisplayName('Create schedule')
    createSchedule(ideaId: string, data: ScheduleData): Promise<FullSchedule> {
        return this.performRequest<FullSchedule>({
            path: `/${ideaId}/schedules`,
            method: RequestMethod.POST,
            body: data
        }).then(this.ensureDateFields);
    }

    @ErrorWithOperationDisplayName('Create schedule for user')
    createScheduleForUser(ideaId: string, userId: string, data: ScheduleData): Promise<FullSchedule> {
        return this.performRequest<FullSchedule>({
            path: `/${ideaId}/schedules`,
            method: RequestMethod.POST,
            body: {
                ...data,
                userId
            }
        }).then(this.ensureDateFields);
    }

    @ErrorWithOperationDisplayName('Update schedule')
    updateSchedule(ideaId: string, scheduleId: number, data: ScheduleData): Promise<FullSchedule> {
        return this.performRequest<FullSchedule>({
            path: `/${ideaId}/schedules/${scheduleId}`,
            method: RequestMethod.PUT,
            body: data
        }).then(this.ensureDateFields);
    }

    @ErrorWithOperationDisplayName('Partially update schedule')
    partiallyUpdateSchedule(ideaId: string, scheduleId: number, data: Partial<ScheduleData>): Promise<FullSchedule> {
        return this.performRequest<FullSchedule>({
            path: `/${ideaId}/schedules/${scheduleId}`,
            method: RequestMethod.PATCH,
            body: data
        }).then(this.ensureDateFields);
    }

    @ErrorWithOperationDisplayName('Get schedule')
    getSchedule(ideaId: string, scheduleId: number): Promise<FullSchedule> {
        return this.performRequest<FullSchedule>({
            path: `/${ideaId}/schedules/${scheduleId}`
        }).then(this.ensureDateFields);
    }

    @ErrorWithOperationDisplayName('Get schedules')
    getSchedules(ideaId: string, sortBy?: SchedulesSortBy, skip?: number, take?: number): Promise<Schedules> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'orderBy', this.getScheduleOrderby(sortBy));
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());

        return this.performRequest<Schedules>({
            path: `/${ideaId}/schedules`,
            queryParams
        }).then(response => {
            response.schedules.forEach(schedule => this.ensureDateFields(schedule));
            return response;
        });
    }

    @ErrorWithOperationDisplayName('Get research schedules')
    getResearchSchedules(ideaId: string, researchId: number): Promise<FullSchedule[]> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'orderBy', 'CreatedOn');
        this.addQueryParamIfPresent(queryParams, 'researchId', researchId.toString());
        this.addQueryParamIfPresent(queryParams, 'includeTimetable', 'true');

        return this.performRequest<Schedules<FullSchedule>>({
            path: `/${ideaId}/schedules`,
            queryParams
        }).then(response => {
            response.schedules.reverse();
            response.schedules.forEach(schedule => this.ensureDateFields(schedule));
            return response.schedules;
        });
    }

    @ErrorWithOperationDisplayName('Get research schedules')
    getUserScheduleInResearch(ideaId: string, userId: string, researchId: number): Promise<Schedule | undefined> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'userId', userId.toString());
        this.addQueryParamIfPresent(queryParams, 'researchId', researchId.toString());
        this.addQueryParamIfPresent(queryParams, 'take', '1');

        return this.performRequest<Schedules>({
            path: `/${ideaId}/schedules`,
            queryParams
        }).then(response => {
            if (response.schedules.length) {
                const userSchedule = response.schedules[0];
                this.ensureDateFields(userSchedule);

                return userSchedule;
            }

            return undefined;
        });
    }

    @ErrorWithOperationDisplayName('Delete schedule')
    deleteSchedule(ideaId: string, scheduleId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/schedules/${scheduleId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore schedule')
    restoreSchedule(ideaId: string, scheduleId: number): Promise<FullSchedule> {
        return this.performRequest({
            path: `/${ideaId}/schedules/${scheduleId}/restore`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Create schedule for research')
    createScheduleForResearch(ideaId: string, researchId: number, scheduleData: ResearchScheduleData): Promise<FullSchedule> {
        return this.performRequest({
            ignoreBaseUrl: true,
            path: `/api/research/${ideaId}/problem-validation/${researchId}/schedules`,
            method: RequestMethod.POST,
            body: scheduleData
        });
    }

    @ErrorWithOperationDisplayName('Get schedule data for research')
    getScheduleDataForResearch(ideaId: string, researchId: number): Promise<Omit<ScheduleData, 'timetable'>> {
        return this.performRequest<Omit<ScheduleData, 'timetable'>>({
            ignoreBaseUrl: true,
            path: `/api/research/${ideaId}/problem-validation/${researchId}/interview-schedule-settings`
        }).then(data => {
            dateTimeService.ensureDateField(data, 'startTime');
            dateTimeService.ensureDateField(data, 'endTime');

            return data;
        });
    }

    private getScheduleOrderby(sortBy?: SchedulesSortBy) {
        if (!sortBy) return undefined;
        if (sortBy === SchedulesSortBy.Recent) return 'UpdatedOn';
        if (sortBy === SchedulesSortBy.Alphabetical) return 'Title';

        return undefined;
    }

    stringifyTimetableTime(time: TimetableTimeValue) {
        const hour = time.hour === 0 || time.hour === 24 ? 12 : time.hour > 12 ? time.hour % 12 : time.hour;
        const suffix = time.hour >= 12 ? 'PM' : 'AM';

        return `${hour.toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${time.minute.toLocaleString(undefined, { minimumIntegerDigits: 2 })} ${suffix}`;
    }

    timetableDateToDate(value: TimetableDayValue): Date {
        return new Date(value.year, value.month, value.date, 0, 0, 0, 0);
    }

    getTimetableDateWeekday(value: TimetableDayValue): number {
        const date = this.timetableDateToDate(value);

        return date.getDay();
    }

    timetableTimeToDate(value: TimetableTimeValue): Date {
        // Hardcode date because not all hours are valid for all days. For example due to daylight saving we may be skipping 1 hour for the particular day.
        return new Date(2023, 8, 5, value.hour, value.minute, 0, 0);
    }

    stringifyTimetableDate(day: TimetableDayValue) {
        const date = new Date(day.year, day.month, day.date);

        return dateTimeService.stringifyToDay(date, true);
    }

    timetableDatesAreEqual(first: TimetableDayValue, second: TimetableDayValue) {
        return first === second || (first.year === second.year && first.month === second.month && first.date === second.date);
    }

    timetableTimesAreEqual(first: TimetableTimeValue, second: TimetableTimeValue) {
        return first === second || (first.hour === second.hour && first.minute === second.minute);
    }

    findNextTimetableSlotStartTime(slotLength: number, currentStartTimes?: TimetableTimeValue[]): TimetableTimeValue | undefined {
        if (!currentStartTimes || !currentStartTimes.length) return { hour: 9, minute: 0 };
        const sortedStartTimes = currentStartTimes.sort((a, b) => this.subtractTimetableTimes(a, b));
        for (let rangeCounter = 0; rangeCounter <= sortedStartTimes.length; rangeCounter++) {
            const rangeIndex = rangeCounter === 0 ? sortedStartTimes.length : rangeCounter - 1; // Start from last range, then the first one, second one, etc
            const isFirstRange = rangeIndex === 0;
            const rangeStart: TimetableTimeValue | undefined = isFirstRange
                ? { hour: 0, minute: 0 }
                : this.tryAddMinutesToTimetableTime(sortedStartTimes[rangeIndex - 1], slotLength);
            if (!rangeStart) continue;
            const isLastRange = rangeIndex === sortedStartTimes.length;
            const rangeEnd: TimetableTimeValue = isLastRange ? { hour: 24, minute: 0 } : sortedStartTimes[rangeIndex];
            if (this.subtractTimetableTimes(rangeEnd, rangeStart) >= slotLength) return rangeStart;
        }

        return undefined;
    }

    tryAddMinutesToTimetableTime<TTime extends TimetableTimeValue>(time: TTime, minutesToAdd: number): TTime | undefined {
        const totalMinutes = time.minute + minutesToAdd;
        const hoursToAdd = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes > 59 ? totalMinutes % 60 : totalMinutes;
        const hours = time.hour + hoursToAdd;
        if (hours > 24 || (hours === 24 && minutes !== 0)) return undefined;

        return { ...time, hour: hours, minute: minutes };
    }

    subtractTimetableTimes(first: TimetableTimeValue, second: TimetableTimeValue): number {
        return (first.hour - second.hour) * 60 + (first.minute - second.minute);
    }
}

export const schedulesService = new SchedulesService();
