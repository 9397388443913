import { Outlet } from 'react-router-dom';
import SidePanel from '../../components/sidebar/sidePanel';
import StartupTitleNavEditor from '../../components/topNav/startupTitleNavEditor';
import TopNav from '../../components/topNav/topNav';
import UserNavMenu from '../../components/topNav/userNavMenu';

export default function PlatformLayout() {
    return (
        <div className="page">
            <TopNav center={<StartupTitleNavEditor />} right={<UserNavMenu />} />
            <div className="page-content page-content-columns">
                <Outlet />
                <SidePanel />
            </div>
        </div>
    );
}
