import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import canvasReducer from './canvas/canvasSlice';
import ideaMembersReducer from './idea/ideaMembersSlice';
import ideaReducer from './idea/ideaSlice';
import infoTipsReducer from './idea/infoTipsSlice';
import recentTasksReducer from './idea/recentTasksSlice';
import platformNotificationsReducer from './notifications/platformNotificationsSlice';
import modalReducer from './ui/modalSlice';
import sidePanelReducer from './ui/sidePanelSlice';
import soleToggleReducer from './ui/soleToggleSlice';
import userReducer from './user/userSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        idea: ideaReducer,
        canvas: canvasReducer,
        ideaMembers: ideaMembersReducer,
        platformNotifications: platformNotificationsReducer,
        soleToggle: soleToggleReducer,
        sidePanel: sidePanelReducer,
        modal: modalReducer,
        recentTasks: recentTasksReducer,
        infoTips: infoTipsReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
