import { StackLayout } from '@progress/kendo-react-layout';
import { CSSProperties, Fragment } from 'react';
import { combineClassNames } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { SpecialTimetableEntry, TimetableEntry, TimetableTime, schedulesService } from '../../services/schedulesService';
import { WeekDaysList } from '../ui/weekDaysList';

export function WeeklySlotsOverview({ entries }: { entries: TimetableEntry[] }) {
    const startTimesByWeekDay = entries.reduce<Partial<Record<number, TimetableTime[]>>>((slotsByWeekDay, timetableEntry) => {
        slotsByWeekDay[timetableEntry.dayOfWeek] = timetableEntry.startTimes;
        return slotsByWeekDay;
    }, {});

    return (
        <StackLayout className="k-gap-2">
            <WeekDaysList>
                {weekDay => {
                    const startTimesInWeekDay = startTimesByWeekDay[weekDay];
                    const hasSlotsInWeekDay = startTimesInWeekDay && startTimesInWeekDay.length;

                    return (
                        <StackLayout key={weekDay} orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-3">
                            <div key="header" className={combineClassNames('k-text-center', hasSlotsInWeekDay ? 'k-font-weight-semibold' : undefined)}>
                                {dateTimeService.getWeekDayName(weekDay)}
                            </div>
                            {hasSlotsInWeekDay ? (
                                startTimesInWeekDay.map((startTime, startTimeIndex) => (
                                    <TimetableSlotView key={startTimeIndex}>{schedulesService.stringifyTimetableTime(startTime)}</TimetableSlotView>
                                ))
                            ) : (
                                <TimetableSlotView key="emptySlot" />
                            )}
                        </StackLayout>
                    );
                }}
            </WeekDaysList>
        </StackLayout>
    );
}

const slotMinWidth = 104;
export function DaySlotsOverview({ days }: { days: SpecialTimetableEntry[] }) {
    if (!days.length) return null;

    return (
        <div className="k-d-grid day-slots-grid k-gap-x-3 k-gap-y-2 k-align-items-center">
            {days.map(day => (
                <Fragment key={`${day.date.date}_${day.date.month}_${day.date.year}`}>
                    <span>{schedulesService.stringifyTimetableDate(day.date)}</span>
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-flex-wrap">
                        {day.startTimes.length ? (
                            day.startTimes.map((startTime, startTimeIndex) => (
                                <TimetableSlotView key={startTimeIndex} style={{ minWidth: slotMinWidth }}>
                                    {schedulesService.stringifyTimetableTime(startTime)}
                                </TimetableSlotView>
                            ))
                        ) : (
                            <TimetableSlotView style={{ minWidth: slotMinWidth }} />
                        )}
                    </StackLayout>
                </Fragment>
            ))}
        </div>
    );
}

export function TimetableSlotView({ children, style }: { children?: string; style?: CSSProperties }) {
    return (
        <div
            className={combineClassNames('k-px-3 k-py-1 k-text-center k-rounded-full k-fs-sm', children ? 'k-icp-bg-secondary-4' : 'k-icp-panel-base')}
            style={style}
        >
            {children || 'Unavailable'}
        </div>
    );
}
