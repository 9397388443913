import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Skeleton } from '@progress/kendo-react-indicators';
import { RadioButtonProps, RadioGroup } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactElement, ReactNode, Suspense, lazy, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    AllowedTaxIdTypes,
    BillingInfoValidators,
    CountriesPostalCodesSettings,
    UICCountryCodes,
    collectTaxIds,
    getDiscountedPriceAmount,
    stringifyPriceAmount
} from '../../components/billing/billingDataUtils';
import { PaymentCardView } from '../../components/billing/paymentCardView';
import { PromoCodeInput, PromoDiscount } from '../../components/billing/promoCodeInput';
import { SeatsInput } from '../../components/billing/seatsInput';
import { StripeCardPickerHandle } from '../../components/billing/stripe';
import { DiscountsInputLayout, SubscriptionInfoBox, SubscriptionInfoZone, useSubscriptionInfo } from '../../components/billing/subscriptionInfo';
import { SubscriptionOption, SubscriptionTypePicker } from '../../components/billing/subscriptionTypePicker';
import { TaxIdsFormPickerHandle } from '../../components/billing/taxIdsPicker';
import { CountriesComboBox, useCountries } from '../../components/common/countries';
import { FormFieldProps, ValidatedInput, ValidatedInputHorizontalLayout, defaultValidators } from '../../components/ui/inputs';
import { H2 } from '../../components/ui/typography';
import { appConfig } from '../../config';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { useReturnUrl } from '../../hooks/routerHooks';
import { usePaymentData, usePaymentSetupIntent } from '../../hooks/subscriptionHooks';
import { useRefreshAuthentication } from '../../hooks/userHooks';
import { ReactComponent as StripeLogo } from '../../images/stripe-logo.svg';
import { googleTagManager } from '../../scripts/googleTagManager';
import { SubscriptionType } from '../../services/authenticationService';
import { combineClassNames } from '../../services/common';
import { executeWithServerErrorInToast } from '../../services/common/common.errors';
import { Country } from '../../services/countriesService';
import { dateTimeService } from '../../services/dateTimeService';
import { BillingInfo, CustomerType, NewSubscriptionData, PaymentMethod, TaxId, TaxIdType, stripeBillingService } from '../../services/stripeBillingService';
import { LicenseType, User } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
const TaxIdsFormPickerLazy = lazy(() => import('../../components/billing/taxIdsPicker'));
const StripeCardPicker = lazy(() => import('../../components/billing/stripe').then(m => ({ default: m.StripeCardPicker })));
const StripeZone = lazy(() => import('../../components/billing/stripe').then(m => ({ default: m.StripeZone })));

enum SubscribeView {
    ExpiredTrial = 1,
    ExpiredPaid = 2,
    ActiveTrial = 3
}

type SubscribeViewData = { type: SubscribeView; licenseExpires: Date };

type SubscribeFormData = {
    subscriptionType: SubscriptionType;
    seats?: number;
    promo?: PromoDiscount;
    country: Country;
    city: string;
    address: string;
    postalCode?: string;
    customerType: CustomerType;
    name: string;
    uic?: string;
    taxIds?: TaxId[];
};

const { seatsValidators, customerTypeValidators, companyNameValidators, nameValidators, uicValidators } = BillingInfoValidators;

export function SubscribePage() {
    const [view, setView] = useState<SubscribeViewData>();
    const navigate = useNavigate();
    const refreshAuthenticationAndNavigate = useRefreshAuthentication();
    const returnUrl = useReturnUrl();
    const countriesList = useCountries();
    const currentUser = useAppSelector(s => s.user);
    const taxIdsPickerRef = useRef<TaxIdsFormPickerHandle>(null);
    const cardPickerRef = useRef<StripeCardPickerHandle>(null);
    const { pricingData, billingInfo, paymentMethod } = usePaymentData();
    const [editPaymentMethod, setEditPaymentMethod] = useState(false);
    const [paymentSetupIntent, resetPaymentSetupIntent, cancelPaymentSetupIntent] = usePaymentSetupIntent(paymentMethod === null || editPaymentMethod);

    const currentLicense = currentUser?.license;
    useEffect(() => {
        if (!currentLicense) return;

        const currentLicenseExpirationData = new Date(currentLicense.expires);
        const isLicenseActive = currentLicenseExpirationData >= new Date();
        if (isLicenseActive) {
            if (currentLicense.type === LicenseType.Full) navigate(returnUrl);
            else setView({ type: SubscribeView.ActiveTrial, licenseExpires: currentLicenseExpirationData });
        } else {
            if (currentLicense.type === LicenseType.Trial) setView({ type: SubscribeView.ExpiredTrial, licenseExpires: currentLicenseExpirationData });
            else setView({ type: SubscribeView.ExpiredPaid, licenseExpires: currentLicenseExpirationData });
        }
    }, [currentLicense, navigate, returnUrl]);

    async function startSubscription(formData: Record<string, any>) {
        const data = formData as SubscribeFormData;
        const countryCode = data.country.code;

        const subscriptionData: NewSubscriptionData = {
            subscriptionType: data.subscriptionType,
            promotionCode: data.promo?.code,
            billingInfo: {
                customerType: data.customerType,
                name: data.name,
                country: countryCode,
                city: data.city,
                address: data.address,
                postalCode: countryCode in CountriesPostalCodesSettings ? data.postalCode : undefined,
                taxIds:
                    data.customerType === CustomerType.Company
                        ? collectTaxIds(data.country.code, data.taxIds, data.uic, taxIdsPickerRef.current?.applicableTaxTypes())
                        : []
            }
        };

        const cardSetupConfirmed = await cardPickerRef.current?.confirmSetup(countryCode);
        if (cardSetupConfirmed === false) return;

        if (paymentSetupIntent) {
            subscriptionData.paymentMethod = {
                setupIntentId: paymentSetupIntent.id
            };
        }

        const newSubscription = await executeWithServerErrorInToast(
            () => stripeBillingService.startSubscription(subscriptionData), // TODO: add response DTO and parse response
            409,
            message => {
                if (message.startsWith('Cannot cannot start new subscription for user with status'))
                    return 'Confirm your email address before starting a subscription!';

                return message;
            }
        );

        await refreshAuthenticationAndNavigate(returnUrl, true);
        const subscriptionPrice = getDiscountedPriceAmount(newSubscription.price.amount, newSubscription.discount);
        googleTagManager.reportSubscriptionStartedEvent(
            subscriptionPrice,
            subscriptionData.subscriptionType,
            subscriptionData.billingInfo!.customerType,
            data.seats!
        );
    }
    const [startSubscriptionDisabled, startSubscriptionActionCreator] = useSingleClickButton<
        Parameters<typeof startSubscription>,
        ReturnType<typeof startSubscription>
    >();

    const isLoading = !view || !pricingData || !billingInfo || paymentMethod === undefined || !currentUser || !countriesList;
    const initialData = isLoading ? undefined : getInitialSubscriptionData(billingInfo, currentUser, countriesList);

    return (
        <div className="page-content-section hex-top-right-bg">
            <div className="page-content-middle k-text-center k-mt-6">
                <H2 className="!k-mb-4">
                    {isLoading ? <Skeleton shape="text" style={{ width: 200, display: 'inline-block' }} /> : subscribeTitleMap[view.type]}
                </H2>

                <div className="k-mb-10">
                    {isLoading ? (
                        <>
                            <Skeleton shape="text" style={{ display: 'inline-block', width: '100%' }} />
                            <Skeleton shape="text" style={{ display: 'inline-block', width: '60%' }} />
                        </>
                    ) : (
                        getSubscribeDescription(view)
                    )}
                </div>

                <Form
                    key={isLoading ? 'loading' : 'loaded'}
                    ignoreModified={true}
                    initialValues={initialData}
                    onSubmit={startSubscriptionActionCreator(startSubscription)}
                    render={formRenderProps => {
                        const subscriptionType: SubscriptionType | undefined = formRenderProps.valueGetter('subscriptionType');
                        const seats: number | undefined = formRenderProps.valueGetter('seats');
                        const promoDiscount: PromoDiscount | undefined = formRenderProps.valueGetter('promo');
                        const selectedCountry: Country | undefined = formRenderProps.valueGetter('country');
                        const postalCodeSettings = selectedCountry ? CountriesPostalCodesSettings[selectedCountry.code] : undefined;
                        const customerType: CustomerType | undefined = formRenderProps.valueGetter('customerType');
                        return (
                            <FormElement noValidate={true}>
                                <SubscriptionInfoZone pricing={pricingData} discount={promoDiscount} subscriptionType={subscriptionType} seatsCount={seats}>
                                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8 ">
                                        {appConfig.enableMultiseats ? (
                                            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-4">
                                                <Field
                                                    name="subscriptionType"
                                                    component={ValidatedInput}
                                                    inputType={SubscriptionTypePicker}
                                                    pricing={pricingData}
                                                />
                                                <SubscriptionInfoBox
                                                    bottomContent={
                                                        <Field
                                                            name="promo"
                                                            component={ValidatedInput}
                                                            inputType={PromoCodeInput}
                                                            wrapperClass="k-align-self-stretch -px-1.5"
                                                            size="small"
                                                            addPromoCodeButtonText="Apply promo code"
                                                            showClearButton
                                                            disabled={isLoading}
                                                        />
                                                    }
                                                >
                                                    <Field
                                                        name="seats"
                                                        component={ValidatedInput}
                                                        inputType={SeatsInput}
                                                        validator={seatsValidators}
                                                        placeholder="Seats"
                                                        min={1}
                                                        label="Seats:"
                                                        labelClassName="!k-mb-0"
                                                        layout={DiscountsInputLayout}
                                                        wrapperClass="k-flex-1 k-mb-4"
                                                        disabled={isLoading}
                                                    />
                                                </SubscriptionInfoBox>
                                            </StackLayout>
                                        ) : isLoading ? (
                                            <div className="-w1 k-mx-auto">
                                                <Skeleton shape="text" style={{ display: 'inline-block', width: 260 }} className="k-mb-4" />
                                                <Skeleton shape="rectangle" style={{ height: 110 }} className="k-mb-2" />
                                                <Skeleton shape="rectangle" style={{ display: 'inline-block', width: 164, height: 32 }} />
                                            </div>
                                        ) : (
                                            <div className="-w1 k-mx-auto">
                                                <div className="k-mb-2">
                                                    <Field
                                                        name="subscriptionType"
                                                        component={ValidatedInput}
                                                        inputType={SubscriptionTypePicker}
                                                        pricing={pricingData}
                                                        className="k-mb-4"
                                                    />

                                                    {subscriptionType && (
                                                        <SubscriptionOption
                                                            pricing={pricingData}
                                                            subscriptionType={subscriptionType}
                                                            discount={promoDiscount}
                                                        />
                                                    )}
                                                </div>

                                                <Field name="promo" component={ValidatedInput} inputType={PromoCodeInput} />
                                            </div>
                                        )}

                                        <Suspense fallback={<PaymentCardLoader />}>
                                            {paymentMethod === undefined ? (
                                                <PaymentCardLoader />
                                            ) : paymentMethod && !editPaymentMethod ? (
                                                <PaymentCardViewPanel paymentMethod={paymentMethod} onEdit={() => setEditPaymentMethod(true)} />
                                            ) : paymentSetupIntent ? (
                                                <StripeZone clientSecret={paymentSetupIntent.clientSecret}>
                                                    <StripeCardPicker
                                                        ref={cardPickerRef}
                                                        render={(children, isIntentInUnexpectedState, errorMessage) => (
                                                            <SubscribeFormPanel
                                                                errorMessage={
                                                                    isIntentInUnexpectedState
                                                                        ? 'Your payment method confirmation session has expired.'
                                                                        : errorMessage
                                                                }
                                                                outline={!!paymentMethod}
                                                                action={
                                                                    isIntentInUnexpectedState ? (
                                                                        <Button type="button" fillMode="flat" onClick={resetPaymentSetupIntent}>
                                                                            Reset session
                                                                        </Button>
                                                                    ) : paymentMethod ? (
                                                                        <Button
                                                                            type="button"
                                                                            fillMode="flat"
                                                                            onClick={() => {
                                                                                cancelPaymentSetupIntent();
                                                                                setEditPaymentMethod(false);
                                                                            }}
                                                                        >
                                                                            Discard changes
                                                                        </Button>
                                                                    ) : (
                                                                        undefined
                                                                    )
                                                                }
                                                            >
                                                                {children}
                                                            </SubscribeFormPanel>
                                                        )}
                                                    />
                                                </StripeZone>
                                            ) : (
                                                <PaymentCardLoader />
                                            )}
                                        </Suspense>

                                        <SubscribeFormPanel>
                                            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                                                <Field
                                                    name="country"
                                                    component={ValidatedInput}
                                                    inputType={CountriesComboBox}
                                                    label="Country"
                                                    validator={defaultValidators.countryValidators}
                                                    data={countriesList}
                                                    required={true}
                                                    wrapperClass="k-flex-1"
                                                    disabled={isLoading}
                                                />
                                                <Field
                                                    name="city"
                                                    component={ValidatedInput}
                                                    label="City"
                                                    validator={defaultValidators.cityValidators}
                                                    maxLength={50}
                                                    wrapperClass="k-flex-1"
                                                    disabled={isLoading}
                                                />
                                            </StackLayout>
                                            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                                                <Field
                                                    name="address"
                                                    component={ValidatedInput}
                                                    label="Address"
                                                    validator={defaultValidators.addressValidators}
                                                    wrapperClass="k-flex-1"
                                                    disabled={isLoading}
                                                />

                                                {postalCodeSettings && (
                                                    <Field
                                                        name="postalCode"
                                                        component={ValidatedInput}
                                                        label={postalCodeSettings.label}
                                                        validator={postalCodeSettings.validators}
                                                        wrapperClass="k-flex-1"
                                                        disabled={isLoading}
                                                    />
                                                )}
                                            </StackLayout>
                                        </SubscribeFormPanel>

                                        <SubscribeFormPanel>
                                            <Field
                                                name="customerType"
                                                component={ValidatedInput}
                                                layout={ValidatedInputHorizontalLayout}
                                                label="Bill to:"
                                                validator={customerTypeValidators}
                                                inputType={CustomerTypePicker}
                                                wrapperClass="k-icp-horizontal-input-compact"
                                                labelClassName="k-mr-8"
                                                disabled={isLoading}
                                            />
                                            {customerType && (
                                                <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                                                    <Field
                                                        name="name"
                                                        component={ValidatedInput}
                                                        label={customerType === CustomerType.Company ? 'Company name' : 'Name'}
                                                        validator={customerType === CustomerType.Company ? companyNameValidators : nameValidators}
                                                        maxLength={101}
                                                        wrapperClass="k-flex-1"
                                                    />
                                                    {customerType === CustomerType.Company && selectedCountry && UICCountryCodes.has(selectedCountry.code) && (
                                                        <Field
                                                            name="uic"
                                                            component={ValidatedInput}
                                                            label="UIC"
                                                            validator={uicValidators}
                                                            wrapperClass="k-flex-1"
                                                        />
                                                    )}
                                                </StackLayout>
                                            )}
                                            {customerType === CustomerType.Company && (
                                                <Suspense>
                                                    <TaxIdsFormPickerLazy
                                                        ref={taxIdsPickerRef}
                                                        fieldName="taxIds"
                                                        countryCode={selectedCountry?.code}
                                                        disabled={isLoading}
                                                        taxIdsTypeFilter={AllowedTaxIdTypes}
                                                    />
                                                </Suspense>
                                            )}
                                        </SubscribeFormPanel>

                                        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-4">
                                            <Button
                                                type="submit"
                                                themeColor="primary"
                                                size="large"
                                                disabled={isLoading || !formRenderProps.allowSubmit || startSubscriptionDisabled}
                                            >
                                                {isLoading ? (
                                                    <Skeleton shape="text" style={{ width: 145 }} />
                                                ) : appConfig.enableMultiseats ? (
                                                    <SubscribeButtonContent />
                                                ) : (
                                                    'Start subscription'
                                                )}
                                            </Button>

                                            <Button
                                                type="reset"
                                                fillMode="flat"
                                                size="small"
                                                disabled={isLoading || startSubscriptionDisabled}
                                                onClick={() => {
                                                    cancelPaymentSetupIntent();
                                                    navigate(returnUrl);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </StackLayout>

                                        <StackLayout
                                            orientation="vertical"
                                            align={{ horizontal: 'stretch', vertical: 'top' }}
                                            className="k-gap-2 k-fs-sm k-icp-bordered-top k-icp-component-border k-pt-2"
                                        >
                                            <div>
                                                By proceeding you agree to our{' '}
                                                <a className="k-button-link-secondary" href="/legal/privacy-policy" target="_blank" rel="noreferrer">
                                                    Privacy Policy
                                                </a>{' '}
                                                and{' '}
                                                <a className="k-button-link-secondary" href="/legal/terms-of-use" target="_blank" rel="noreferrer">
                                                    Terms of Use
                                                </a>
                                                .
                                            </div>
                                            <div>
                                                By confirming your subscription, you allow us to charge you for this payment and future payments in accordance
                                                with our terms. You can always cancel your subscription.
                                            </div>
                                            <div>
                                                Payment powered by{' '}
                                                <a href="https://stripe.com/" target="_blank" rel="noreferrer">
                                                    <StripeLogo width="39" height="16" className="k-text-base -vam" />
                                                </a>
                                            </div>
                                        </StackLayout>
                                    </StackLayout>
                                </SubscriptionInfoZone>
                            </FormElement>
                        );
                    }}
                />
            </div>
        </div>
    );
}

const subscribeTitleMap: Record<SubscribeView, string> = {
    [SubscribeView.ExpiredTrial]: 'Trial expired',
    [SubscribeView.ExpiredPaid]: 'Subscription required',
    [SubscribeView.ActiveTrial]: 'Icanpreneur subscription'
};

function getSubscribeDescription(viewData: SubscribeViewData) {
    switch (viewData.type) {
        case SubscribeView.ExpiredTrial:
            return 'Your free trial has expired. To continue using Icanpreneur, you need a subscription. Do you want to start one?';
        case SubscribeView.ExpiredPaid:
            return 'No active subscription associated with your account was found. Do you want to start a new one?';
        case SubscribeView.ActiveTrial:
            return `You are currently using a trial version of Icanpreneur, expiring ${dateTimeService.stringifyToDay(
                viewData.licenseExpires
            )}. If you are done with your evaluation, you can start a paid subscription.`;
    }
}

function getInitialSubscriptionData(billingInfo: BillingInfo, currentUser: User, countries: Country[]): Partial<SubscribeFormData> {
    const subscriptionData: Partial<SubscribeFormData> = {};
    const countryCode = billingInfo.country || currentUser.countryCode;
    const country = countryCode ? countries.find(c => c.code === countryCode) : undefined;

    subscriptionData.subscriptionType = SubscriptionType.Monthly;
    subscriptionData.seats = 1;
    subscriptionData.country = country;
    subscriptionData.city = (billingInfo.city || currentUser.city) ?? undefined;
    subscriptionData.address = billingInfo.address ?? undefined;
    subscriptionData.postalCode = billingInfo.postalCode ?? undefined;
    subscriptionData.customerType = billingInfo.customerType ?? CustomerType.Individual;
    subscriptionData.name =
        billingInfo.name ||
        (currentUser.firstName || currentUser.lastName
            ? `${currentUser.firstName ?? ''}${currentUser.firstName && currentUser.lastName ? ' ' : ''}${currentUser.lastName ?? ''}`
            : undefined);

    for (const taxId of billingInfo.taxIds) {
        if (taxId.type === TaxIdType.BGUic) {
            subscriptionData.uic = taxId.value;
            continue;
        }

        if (!subscriptionData.taxIds) subscriptionData.taxIds = [];
        subscriptionData.taxIds.push(taxId);
    }

    return subscriptionData;
}

const customerTypeOptions: Pick<RadioButtonProps, 'label' | 'value'>[] = [
    {
        label: 'Individual',
        value: CustomerType.Individual
    },
    {
        label: 'Company',
        value: CustomerType.Company
    }
];

function CustomerTypePicker({ id, value, onChange, valid, disabled }: FormFieldProps<CustomerType>) {
    return <RadioGroup name={id} data={customerTypeOptions} value={value} onChange={onChange} valid={valid} disabled={disabled} layout="horizontal" />;
}

function SubscribeFormPanel({
    children,
    outline,
    errorMessage,
    action
}: {
    children?: ReactNode;
    outline?: boolean;
    errorMessage?: string;
    action?: ReactElement;
}) {
    return (
        <div>
            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'stretch', vertical: 'top' }}
                className={combineClassNames(
                    'k-icp-panel k-px-8 k-pt-6 k-pb-8 k-gap-6',
                    errorMessage ? 'k-invalid' : undefined,
                    outline ? 'k-icp-border-base-50' : undefined
                )}
            >
                {children}
            </StackLayout>
            {(errorMessage || action) && (
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-justify-content-between k-mt-1">
                    <span className="k-text-error">{errorMessage}</span>
                    {action}
                </StackLayout>
            )}
        </div>
    );
}

function PaymentCardViewPanel({ paymentMethod, onEdit }: { paymentMethod: PaymentMethod; onEdit: () => void }) {
    const expired = stripeBillingService.isDateExpired(paymentMethod.expiresMonth, paymentMethod.expiresYear);

    return (
        <SubscribeFormPanel
            action={
                <Button type="button" fillMode="flat" onClick={onEdit}>
                    Update payment method
                </Button>
            }
            errorMessage={expired ? 'Your payment method has expired.' : undefined}
        >
            <PaymentCardView
                label="Payment method"
                className="k-text-left"
                last4digits={paymentMethod.last4digits}
                brand={paymentMethod.cardBrand}
                expiresMonth={paymentMethod.expiresMonth}
                expiresYear={paymentMethod.expiresYear}
            />
        </SubscribeFormPanel>
    );
}

function PaymentCardLoader() {
    return (
        <SubscribeFormPanel>
            <Skeleton shape="rectangle" style={{ width: '100%', height: 32 }} />
            <StackLayout className="k-gap-2">
                <Skeleton shape="rectangle" style={{ height: 32 }} />
                <Skeleton shape="rectangle" style={{ height: 32 }} />
            </StackLayout>
        </SubscribeFormPanel>
    );
}

function SubscribeButtonContent() {
    const { currentPriceCurrencySymbol, totalPriceAmount } = useSubscriptionInfo();

    return (
        <>
            Subscribe
            {currentPriceCurrencySymbol && totalPriceAmount !== undefined && ` and pay ${currentPriceCurrencySymbol}${stringifyPriceAmount(totalPriceAmount)}`}
        </>
    );
}
