import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { To } from 'react-router-dom';
import { useGlobalCanvas } from '../../../../hooks/canvasHooks';
import { BoxType, canvasService } from '../../../../services/canvasService';
import { WithNotNullable } from '../../../../services/common/common.types';
import { JourneyTaskRelatedItem, SecondaryBox } from '../../../../services/journeyService';
import CanvasBox, { TaskRelatedEmptyItemsView } from '../../../canvas/box';
import LoadingIndicator from '../../../ui/loadingIndicator';

export function RelatedCanvasBoxes({
    boxes,
    navigate,
    showItemsOrRelatedItemsTo,
    pointToBoxOnNavigate
}: {
    boxes: SecondaryBox[];
    navigate: (to: To) => void;
    showItemsOrRelatedItemsTo?: JourneyTaskRelatedItem[];
    pointToBoxOnNavigate?: BoxType;
}) {
    const { canvas, relatableItems } = useGlobalCanvas();

    const viewBoxesInCanvas = () => {
        const queryParameters = new URLSearchParams();
        if (pointToBoxOnNavigate) {
            queryParameters.append('canvas-target-box', pointToBoxOnNavigate);
            boxes.forEach(b => queryParameters.append('canvas-source-box', b.type));
        } else
            boxes
                .map(box => canvasService.getBoxMetadata(box.type)?.highlight)
                .forEach(boxHighlight => {
                    if (!boxHighlight) return;
                    queryParameters.append('highlight', boxHighlight);
                });

        const queryString = queryParameters.toString();
        navigate(`../canvas${queryString ? `?${queryString}` : ''}`);
    };

    return (
        <>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-mb-3">
                <span className="k-text-uppercase k-fs-sm">Related canvas boxes</span>
                <div className="k-flex-1 full-horizontal-separator"></div>
                <Button fillMode="flat" themeColor="secondary" size="small" onClick={viewBoxesInCanvas}>
                    View in Canvas
                </Button>
            </StackLayout>
            {canvas.boxes ? (
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1">
                    {boxes
                        .map(box => ({ box: canvas.boxes?.find(b => b.type === box.type), relatedTask: box.relatedTask }))
                        .filter((b): b is WithNotNullable<typeof b, 'box'> => b.box !== undefined)
                        .map(secondaryBoxDefinition => {
                            const relatedIdsFilter = showItemsOrRelatedItemsTo?.filter(i => i.box !== secondaryBoxDefinition.box.type).map(i => i.itemId);
                            const itemsIdsFilter = showItemsOrRelatedItemsTo?.filter(i => i.box === secondaryBoxDefinition.box.type).map(i => i.itemId);

                            return (
                                <CanvasBox
                                    key={secondaryBoxDefinition.box.type}
                                    box={secondaryBoxDefinition.box}
                                    relatableItems={relatableItems}
                                    relatedIdsFilter={relatedIdsFilter && relatedIdsFilter.length ? relatedIdsFilter : undefined}
                                    itemsIdsFilter={itemsIdsFilter && itemsIdsFilter.length ? itemsIdsFilter : undefined}
                                    className="canvas-box-solo canvas-box-related"
                                    hideMemberActivity={true}
                                    emptyView={
                                        secondaryBoxDefinition.relatedTask
                                            ? metadata => (
                                                  <TaskRelatedEmptyItemsView
                                                      boxTitle={metadata.title}
                                                      relatedTask={secondaryBoxDefinition.relatedTask!}
                                                      baseUrl="../journey/tasks/"
                                                  />
                                              )
                                            : undefined
                                    }
                                />
                            );
                        })}
                </StackLayout>
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}
        </>
    );
}
