class DomService {
    focusTextArea(textArea: HTMLTextAreaElement) {
        const selectionEnd = textArea.value.length;
        textArea.setSelectionRange(selectionEnd, selectionEnd);
        textArea.focus();
    }

    textAreaAddContentOnCurrentCaretPosition(textArea: HTMLTextAreaElement, content: string) {
        const selectionStart = textArea.selectionStart;
        const selectionEnd = textArea.selectionEnd;
        textArea.value = textArea.value.slice(0, selectionStart) + content + textArea.value.slice(selectionEnd);
        textArea.setSelectionRange(selectionStart + content.length, selectionStart + content.length);
    }

    getViewPortRect(): DOMRect {
        const viewPortWidth = window.innerWidth || document.documentElement.clientWidth;
        const viewPortHeight = window.innerHeight || document.documentElement.clientHeight;

        return new DOMRect(0, 0, viewPortWidth, viewPortHeight);
    }

    isPartOfElementInViewPort(element: HTMLElement) {
        const elementBoundingRect = element.getBoundingClientRect();
        const { width: viewPortWidth, height: viewPortHeight } = this.getViewPortRect();

        const intersectionWidth = Math.min(elementBoundingRect.x + elementBoundingRect.width, viewPortWidth) - Math.max(elementBoundingRect.x, 0);
        const intersectionHeight = Math.min(elementBoundingRect.y + elementBoundingRect.height, viewPortHeight) - Math.max(elementBoundingRect.y, 0);

        return intersectionWidth > 0 && intersectionHeight > 0;
    }

    private areNestedRects(parentRect: DOMRect, childRect: DOMRect) {
        return (
            childRect.top >= parentRect.top && childRect.left >= parentRect.left && childRect.bottom <= parentRect.bottom && childRect.right <= parentRect.right
        );
    }

    private isBoundingRectInViewPort(rect: DOMRect) {
        const viewPortRect = this.getViewPortRect();

        return this.areNestedRects(viewPortRect, rect);
    }

    isEntireElementInViewPort(element: HTMLElement) {
        const elementBoundingRect = element.getBoundingClientRect();
        return this.isBoundingRectInViewPort(elementBoundingRect);
    }

    scrollIntoViewIfNeeded(element: HTMLElement, centerOnScroll = true, behavior: ScrollBehavior = 'smooth') {
        if (this.isEntireElementInViewPort(element)) return;

        if (centerOnScroll) {
            element.scrollIntoView({
                behavior: behavior,
                block: 'center',
                inline: 'center'
            });
        } else {
            element.scrollIntoView({
                behavior: behavior,
                block: 'nearest',
                inline: 'nearest'
            });
        }
    }

    verticallyScrollToPosition(
        scrollingContainer: HTMLElement,
        calculateDesiredTopPosition: () => number | undefined,
        cancellation?: ScrollCancellationSource,
        tolerance = 5
    ): Promise<void> {
        const initialScrollTopPosition = calculateDesiredTopPosition();
        if (initialScrollTopPosition === undefined) return Promise.resolve();

        const firstScrollTopPosition = this.normalizeVerticalScrollValue(initialScrollTopPosition, scrollingContainer);
        let previousDesiredScrollTopPosition = firstScrollTopPosition;
        scrollingContainer.scrollTo({ top: previousDesiredScrollTopPosition, behavior: 'smooth' });

        const checkInterval = 80;
        const maxScrollDuration = 5000;
        let numberOfChecks = Math.ceil(maxScrollDuration / checkInterval);

        return new Promise<void>(resolve => {
            const checkPositionIntervalId = setInterval(() => {
                const desiredScrollTopPosition = this.normalizeVerticalScrollValue(
                    calculateDesiredTopPosition() ?? previousDesiredScrollTopPosition,
                    scrollingContainer
                );
                const currentScrollTopPosition = scrollingContainer.scrollTop;
                if (cancellation?.cancel || numberOfChecks < 0 || Math.abs(desiredScrollTopPosition - currentScrollTopPosition) <= tolerance) {
                    clearInterval(checkPositionIntervalId);
                    resolve();
                    return;
                }

                if (Math.abs(desiredScrollTopPosition - previousDesiredScrollTopPosition) > tolerance) {
                    previousDesiredScrollTopPosition = desiredScrollTopPosition;
                    // the desired scroll position has changes so initiate the scrolling again
                    scrollingContainer.scrollTo({ top: desiredScrollTopPosition, behavior: 'smooth' });
                }

                --numberOfChecks;
            }, checkInterval);
        });
    }

    private normalizeVerticalScrollValue(verticalScrollValue: number, scrollingContainer: HTMLElement): number {
        if (verticalScrollValue < 0) return 0;

        const maxScrollValue = this.getMaxScrollTop(scrollingContainer);
        if (verticalScrollValue > maxScrollValue) return maxScrollValue;

        return verticalScrollValue;
    }

    getRelativeOffset(element: HTMLElement, parent: Element | null = null) {
        let top = 0,
            left = 0;
        let currentElement: HTMLElement | null = element;
        do {
            top += currentElement.offsetTop;
            left += currentElement.offsetLeft;
        } while (currentElement.offsetParent !== parent && (currentElement = currentElement.offsetParent as HTMLElement));

        return { top, left };
    }

    // The difference with the scrollIntoViewIfNeeded is that this method does not call the native scrollIntoView. The issue with the native method is that in chrome when smooth scrolling is on, you cannot scroll multiple containers simultaneously.
    // Details: https://stackoverflow.com/q/49318497
    // Additionally this method handles layout shifts while scrolling
    scrollVerticallyIntoViewIfNeeded(element: HTMLElement, scrollingContainer?: HTMLElement, cancellation?: ScrollCancellationSource) {
        scrollingContainer = scrollingContainer ?? this.findScrollingContainer(element);
        if (!scrollingContainer) return;

        this.verticallyScrollToPosition(
            scrollingContainer,
            () => {
                if (!scrollingContainer || !scrollingContainer.contains(element)) return undefined;

                const scrollingContainerBoundingRect = scrollingContainer.getBoundingClientRect();
                const elementBoundingRect = element.getBoundingClientRect();
                if (this.areNestedRects(scrollingContainerBoundingRect, elementBoundingRect)) return;

                const scrollingContainerCenterY = scrollingContainerBoundingRect.top + scrollingContainerBoundingRect.height / 2;
                return scrollingContainer.scrollTop - scrollingContainerCenterY + elementBoundingRect.top + elementBoundingRect.height / 2;
            },
            cancellation
        );
    }

    hasVerticalScroll(element: HTMLElement) {
        if (element.scrollHeight <= element.clientHeight) return false;

        const computedOverflowY = window.getComputedStyle(element).overflowY;

        return computedOverflowY === 'auto' || computedOverflowY === 'scroll';
    }

    private getMaxScrollTop(element: HTMLElement) {
        return element.scrollHeight - element.clientHeight;
    }

    findScrollingContainer(element: HTMLElement) {
        let parent = element.parentElement;
        while (parent) {
            if (this.hasVerticalScroll(parent)) return parent;
            parent = parent.parentElement;
        }

        return undefined;
    }
}

export type ScrollCancellationSource = { cancel?: boolean };

export const domService = new DomService();
