import { DateInput, DateInputProps, DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { DateInputHandle } from '@progress/kendo-react-dateinputs/dist/npm/dateinput/DateInput';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useRef } from 'react';
import { combineClassNames } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';

export function KeyboardNavigatableDateInput(props: DateInputProps) {
    const dateInputRef = useRef<DateInputHandle>(null);

    useEffect(() => {
        if (!dateInputRef.current) return;

        const dateInputDomElement = dateInputRef.current.element;
        if (!dateInputDomElement) return;

        function tryHandleNavigation(e: KeyboardEvent) {
            if (!dateInputRef.current || (e.key !== 'Tab' && e.key !== 'Enter')) return;
            const dateInputPropertyAccessor: any = dateInputRef.current; // Hack: Use as 'any' to be able to access private properties;

            if (e.shiftKey) {
                if (dateInputPropertyAccessor.selection.start > 0) {
                    dateInputPropertyAccessor.switchDateSegment(-1);
                    e.preventDefault();
                }
            } else if (dateInputPropertyAccessor.selection.end < dateInputPropertyAccessor.currentFormat.length) {
                dateInputPropertyAccessor.switchDateSegment(1);
                e.preventDefault();
            }
        }

        dateInputDomElement.addEventListener('keydown', tryHandleNavigation);

        return () => dateInputDomElement.removeEventListener('keydown', tryHandleNavigation);
    }, []);

    return <DateInput ref={dateInputRef} {...props} />;
}

export function DateAndTimePicker({
    value,
    onChange,
    valid,
    disabled,
    className
}: {
    value?: Date | null;
    onChange?: (e: { value: Date | null }) => void;
    valid?: boolean;
    disabled?: boolean;
    className?: string;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className={combineClassNames('k-gap-3', className)}>
            <DatePicker
                width={130}
                dateInput={KeyboardNavigatableDateInput}
                value={value}
                onChange={
                    onChange
                        ? e => {
                              if (!e.value) onChange(e);
                              else {
                                  const timePart = value ?? new Date();
                                  onChange({
                                      value: new Date(
                                          e.value.getFullYear(),
                                          e.value.getMonth(),
                                          e.value.getDate(),
                                          timePart.getHours(),
                                          timePart.getMinutes(),
                                          timePart.getSeconds(),
                                          timePart.getMilliseconds()
                                      )
                                  });
                              }
                          }
                        : undefined
                }
                valid={valid}
                disabled={disabled}
                format="MM/dd/yyyy"
                formatPlaceholder="formatPattern"
            />
            <TimePicker
                width={110}
                value={value}
                onChange={onChange}
                valid={valid}
                disabled={disabled}
                steps={{ minute: 15 }}
                dateInput={KeyboardNavigatableDateInput}
                format="hh:mm a"
                formatPlaceholder="formatPattern"
            />
        </StackLayout>
    );
}

export function DateRangeView({ start, end, isAllDay }: { start: Date; end: Date; isAllDay?: boolean }) {
    const isWithinOneDay = dateTimeService.isSameDay(start, end);
    if (isAllDay) {
        return (
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                <span>{dateTimeService.stringifyToDay(start)}</span>
                {!isWithinOneDay && (
                    <>
                        {' '}
                        - <span>{dateTimeService.stringifyToDay(end)}</span>
                    </>
                )}
            </StackLayout>
        );
    }

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            <span>{dateTimeService.stringifyToDay(start)}</span>
            <span className="dot-separator" />
            <span>{dateTimeService.stringifyToTime(start)}</span> -{' '}
            {!isWithinOneDay && (
                <>
                    <span>{dateTimeService.stringifyToDay(end)}</span>
                    <span className="dot-separator" />
                </>
            )}
            <span>{dateTimeService.stringifyToTime(end)}</span>
        </StackLayout>
    );
}

export function DateAndTimeView({ date }: { date: Date }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            <span>{dateTimeService.stringifyToDay(date)}</span>
            <span className="dot-separator" />
            <span>{dateTimeService.stringifyToTime(date)}</span>
        </StackLayout>
    );
}
