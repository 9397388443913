import { addRequestHeader } from './common';

export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
    HEAD = 'HEAD'
}

export interface PerformRequestOptions {
    path: string;
    method?: RequestMethod;
    body?: any;
    ignoreBaseUrl?: boolean;
    queryParams?: Record<string, string> | URLSearchParams;
    headers?: Record<string, string>;
}

export type PagedResponse<prop extends string, TItem> = {
    [p in prop]: Array<TItem>;
} & { totalCount: number };

export interface JsonString {
    text: string;
}

export class HttpException {
    constructor(public readonly status: number, public readonly statusText: string, public readonly response: string) {}

    parseResponse<T>(): T {
        return JSON.parse(this.response) as T;
    }
}

export class NotFoundException {}

export type ErrorResponse = { statusCode: number; message: string; error: string };
export type ErrorsResponse = { statusCode: number; message: string[]; error: string };

type RequestInterceptor = (url: string, request: RequestInit) => void;
type ResponseInterceptor = (url: string, response: Response) => void;
export type RequestAuthenticator = {
    authenticateRequest: (request: RequestInit) => void;
    refreshAuthentication: () => Promise<boolean>;
};
export abstract class HttpServiceBase {
    private static requestInterceptors: RequestInterceptor[] = [];
    private static responseInterceptors: ResponseInterceptor[] = [];

    public static authenticator: RequestAuthenticator | undefined = undefined;

    constructor(private baseUrl: string) {}

    public static registerRequestInterceptor(interceptor: RequestInterceptor) {
        HttpServiceBase.requestInterceptors.push(interceptor);
    }

    public static registerResponseInterceptor(interceptor: ResponseInterceptor) {
        HttpServiceBase.responseInterceptors.push(interceptor);
    }

    public static unregisterResponseInterceptor(interceptor: ResponseInterceptor) {
        const interceptorIndex = HttpServiceBase.responseInterceptors.indexOf(interceptor);
        if (interceptorIndex !== -1) HttpServiceBase.responseInterceptors.splice(interceptorIndex, 1);
    }

    protected async performRequest<TResult>(options: PerformRequestOptions): Promise<TResult> {
        const response = await this.performRequestWithoutParsingResponse(options);

        const responseContentType = response.headers.get('content-type');
        if (responseContentType && responseContentType.includes('application/json')) {
            const data = await response.json();
            return data as TResult;
        }

        return (null as any) as TResult;
    }

    protected async performRequestWithoutParsingResponse(options: PerformRequestOptions, allowReauthorize = true): Promise<Response> {
        options = {
            method: RequestMethod.GET,
            body: undefined,
            ignoreBaseUrl: false,
            ...options
        };

        const request: RequestInit = {
            method: options.method,
            headers: {
                Accept: 'application/json',
                ...options.headers
            }
        };

        HttpServiceBase.authenticator?.authenticateRequest(request);

        if (options.body) {
            if (options.body instanceof FormData) {
                request.body = options.body;
            } else {
                addRequestHeader(request, 'Content-Type', 'application/json');
                request.body = JSON.stringify(options.body);
            }
        }

        let requestUrl: string = (options.ignoreBaseUrl ? '' : this.baseUrl) + options.path;
        requestUrl = this.appendQuery(requestUrl, options.queryParams);
        this.executeRequestInterceptors(requestUrl, request);
        const response = await fetch(requestUrl, request);
        this.executeResponseInterceptors(requestUrl, response);

        if (!response.ok) {
            if (HttpServiceBase.authenticator && response.status === 401 && allowReauthorize) {
                const requestReAuthenticated = await HttpServiceBase.authenticator.refreshAuthentication();
                if (requestReAuthenticated) {
                    return this.performRequestWithoutParsingResponse(options, false);
                }
            }

            let errorResponse = 'empty';
            try {
                errorResponse = await response.text();
            } finally {
                throw new HttpException(response.status, response.statusText, errorResponse);
            }
        }

        return response;
    }

    protected addQueryParamIfPresent(queryParams: Record<string, string> | URLSearchParams, recordName: string, value: string | undefined) {
        if (typeof value !== 'undefined') {
            if (queryParams instanceof URLSearchParams) queryParams.append(recordName, value);
            else queryParams[recordName] = value;
        }
    }

    private appendQuery(requestUrl: string, queryParams: Record<string, string> | URLSearchParams | undefined): string {
        if (!queryParams) return requestUrl;

        const params = new URLSearchParams(queryParams);
        const queryString = params.toString();
        if (!queryString) return requestUrl;

        const separator = requestUrl.includes('?') ? '&' : '?';

        return `${requestUrl}${separator}${queryString}`;
    }

    private executeRequestInterceptors(url: string, request: RequestInit) {
        HttpServiceBase.requestInterceptors.forEach(i => i(url, request));
    }

    private executeResponseInterceptors(url: string, response: Response) {
        HttpServiceBase.responseInterceptors.forEach(i => i(url, response));
    }
}
