import { Skeleton } from '@progress/kendo-react-indicators';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BillingPortalLink } from '../../components/billing/billingPortalLink';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H2 } from '../../components/ui/typography';
import { useReturnUrl } from '../../hooks/routerHooks';
import { useRefreshAuthentication } from '../../hooks/userHooks';
import noLicenseImageUrl from '../../images/no-license.svg';
import { pathWithReturnUrl } from '../../services/common';
import { LicenseStatus, stripeBillingService } from '../../services/stripeBillingService';

const errorViewTitleMap: Record<LicenseStatus.NeedsPayment | LicenseStatus.ContactSupport, string> = {
    [LicenseStatus.ContactSupport]: 'Subscription required',
    [LicenseStatus.NeedsPayment]: 'Pending bill'
};

const errorViewDescriptionMap: Record<LicenseStatus.NeedsPayment | LicenseStatus.ContactSupport, ReactNode> = {
    [LicenseStatus.ContactSupport]: (
        <>
            There is an issue with your account. Please get in touch at{' '}
            <a href="mailto:support@icanpreneur.com" className="k-button-link-secondary">
                support@icanpreneur.com
            </a>{' '}
            to restart your subscription.
        </>
    ),
    [LicenseStatus.NeedsPayment]: 'It looks like you have a pending payment. Go to our Stripe customer portal and update your payment information if necessary.'
};

export function SubscriptionErrorPage() {
    const returnUrl = useReturnUrl();
    const isRenderedRef = useRef(false);
    const navigate = useNavigate();
    const [errorView, setErrorView] = useState<LicenseStatus.NeedsPayment | LicenseStatus.ContactSupport>();

    const refreshAuthentication = useRefreshAuthentication();
    const refreshLicensingDataAndNavigate = useCallback(
        async (navigateToUrl: string) => {
            await stripeBillingService.pullLicenseDataFromStripe();
            await refreshAuthentication(navigateToUrl, true);
        },
        [refreshAuthentication]
    );

    useEffect(() => {
        if (isRenderedRef.current) return;
        isRenderedRef.current = true;

        (async () => {
            const billingValidation = await stripeBillingService.validateBilling();
            if (billingValidation.licenseStatus === LicenseStatus.OK) {
                refreshAuthentication(returnUrl);
                return;
            }

            if (billingValidation.licenseStatus === LicenseStatus.NeedsSync) {
                refreshLicensingDataAndNavigate(returnUrl);
                return;
            }

            if (billingValidation.licenseStatus === LicenseStatus.NeedsSubscription) {
                navigate(pathWithReturnUrl('/account/subscribe', returnUrl));
                return;
            }

            setErrorView(billingValidation.licenseStatus);
        })();
    }, [navigate, refreshAuthentication, refreshLicensingDataAndNavigate, returnUrl]);

    const isLoading = errorView === undefined;

    return (
        <div className="page-content-section k-mt-5">
            <div className="page-content-middle k-text-center">
                {isLoading ? (
                    <div style={{ lineHeight: '349px' }}>
                        <LoadingIndicator size="big" />
                    </div>
                ) : (
                    <img src={noLicenseImageUrl} alt="Subscription error" width="560" height="349" className="k-mb-8 responsive-image" />
                )}
                <H2 className="!k-mb-4">
                    {isLoading ? <Skeleton shape="text" style={{ width: 200, display: 'inline-block' }} /> : errorViewTitleMap[errorView]}
                </H2>
                <div className="k-fs-lg k-mb-7">
                    {isLoading ? (
                        <>
                            <Skeleton shape="text" style={{ display: 'inline-block', width: '100%' }} />
                            <Skeleton shape="text" style={{ display: 'inline-block', width: '60%' }} />
                        </>
                    ) : (
                        errorViewDescriptionMap[errorView]
                    )}
                </div>

                {isLoading ? (
                    <Skeleton shape="rectangle" style={{ display: 'inline-block', width: 150, height: 32 }} />
                ) : errorView === LicenseStatus.NeedsPayment ? (
                    <BillingPortalLink className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md">
                        Go to Stripe customer portal
                    </BillingPortalLink>
                ) : errorView === LicenseStatus.ContactSupport ? (
                    <div>
                        <a
                            href="mailto:support@icanpreneur.com"
                            className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md"
                        >
                            Contact us
                        </a>
                    </div>
                ) : (
                    undefined
                )}
            </div>
        </div>
    );
}
