import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { InterviewModal } from '../../components/interview/interviewModal';
import { tryParseEnum } from '../../services/common';
import { NotFoundException } from '../../services/httpServiceBase';
import { InterviewStage } from '../../services/interviewsService';

export function InterviewModalPage() {
    const { ideaId, interviewId, initiallyShowGuidance, view } = useInterviewParams();
    const navigate = useNavigate();

    return (
        <InterviewModal
            ideaId={ideaId}
            interviewId={interviewId}
            show={true}
            onClose={() => (window.history.length > 1 ? navigate(-1) : navigate('/'))}
            initiallyShowGuidance={initiallyShowGuidance}
            viewAs={view}
        />
    );
}

function useInterviewParams() {
    const { ideaId, interviewId: interviewIdParam } = useParams();
    const [queryParams] = useSearchParams();
    if (!ideaId || !interviewIdParam) throw new NotFoundException();
    const interviewId = parseInt(interviewIdParam);
    const initiallyShowGuidance = queryParams.get('showGuidance') === 'true';

    const viewParam = queryParams.get('view');
    const view = viewParam ? tryParseEnum(viewParam, InterviewStage) : undefined;

    return { ideaId, interviewId, initiallyShowGuidance, view };
}
