import { ReactElement, useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useReturnUrl } from '../../hooks/routerHooks';
import { authenticationTokenStore } from '../../services/authenticationService';
import { HttpException } from '../../services/httpServiceBase';
import { usersService } from '../../services/usersService';
import LoadingIndicator from '../ui/loadingIndicator';

export function AnonymousUserZone({ children }: { children?: ReactElement | null }) {
    const returnUrl = useReturnUrl();
    const checkInProgress = useRef(false);
    const [isAnonymous, setIsAnonymous] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = authenticationTokenStore.getAuthToken();
        if (!accessToken) {
            setIsAnonymous(true);
            return;
        }

        if (checkInProgress.current) return;
        checkInProgress.current = true;

        usersService
            .getCurrent()
            .then(() => {
                navigate(returnUrl);
            })
            .catch(e => {
                if (e instanceof HttpException && e.status === 401) {
                    setIsAnonymous(true);

                    return;
                }

                return Promise.reject(e);
            })
            .finally(() => (checkInProgress.current = false));
    }, [navigate, returnUrl]);

    if (isAnonymous) return children === undefined ? <Outlet /> : children;

    return <LoadingIndicator size="big" className="!k-pos-absolute k-centered" />;
}
