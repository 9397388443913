import { useEffect, useState } from 'react';
import { ExpiredResetPasswordView, InvalidResetPasswordView, ResetPasswordForm } from '../../components/auth/resetPassword';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { useQueryParam } from '../../hooks/routerHooks';
import { authenticationService, CodeStatus } from '../../services/authenticationService';

export function ResetPasswordPage() {
    const [resetPasswordCodeStatus, setResetPasswordCodeStatus] = useState<CodeStatus>();
    const resetPasswordCode = useQueryParam('secret');

    useEffect(() => {
        if (!resetPasswordCode) setResetPasswordCodeStatus(CodeStatus.MissingOrUsed);
        let cancelled = false;
        (async function() {
            const codeStatus = await authenticationService.getResetPasswordCodeStatus(resetPasswordCode!);
            if (cancelled) return;

            setResetPasswordCodeStatus(codeStatus);
        })();

        return () => {
            cancelled = true;
        };
    }, [resetPasswordCode]);

    return (
        <>
            {typeof resetPasswordCodeStatus === 'undefined' ? (
                <LoadingIndicator size="big" />
            ) : resetPasswordCodeStatus === CodeStatus.Valid ? (
                <ResetPasswordForm />
            ) : resetPasswordCodeStatus === CodeStatus.Expired ? (
                <ExpiredResetPasswordView />
            ) : (
                <InvalidResetPasswordView />
            )}
        </>
    );
}
