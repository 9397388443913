import { ErrorWithOperationDisplayName } from './common';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { ReducedResearch } from './researchService';

export enum HypothesisType {
    PainLevel = 'PainLevel',
    AlternativeSolutionUsage = 'AlternativeSolutionUsage',
    AlternativeSolutionSatisfaction = 'AlternativeSolutionSatisfaction'
}

export enum HypothesisGroup {
    CustomerProblem = 'CustomerProblem',
    Solution = 'Solution',
    GoToMarket = 'GoToMarket',
    FeasibilityAndViability = 'FeasibilityAndViability'
}

export enum HypothesisLikelihood {
    VeryLikely = 'VeryLikely',
    Likely = 'Likely',
    Neutral = 'Neutral',
    Unlikely = 'Unlikely',
    VeryUnlikely = 'VeryUnlikely'
}

export enum PainLevel {
    NoPain = 'NoPain',
    Mild = 'Mild',
    Moderate = 'Moderate',
    Severe = 'Severe'
}

export enum SatisfactionLevel {
    Satisfied = 'Satisfied',
    Dissatisfied = 'Dissatisfied',
    Neutral = 'Neutral'
}

export enum HypothesisSortOrder {
    CreatedOnAsc = 'CreatedOnAsc',
    CreatedOnDesc = 'CreatedOnDesc',
    UpdatedOnAsc = 'UpdatedOnAsc',
    UpdatedOnDesc = 'UpdatedOnDesc'
}

export type HypothesisBase<Type extends HypothesisType> = {
    id: number;
    type: Type;
    group: HypothesisGroup;
    customerSegmentId: number;
    customerProblemId: number;
    likelihood?: HypothesisLikelihood | null;
    research?: ReducedResearch[];
};

export type CustomerProblemHypothesis<Type extends HypothesisType> = HypothesisBase<Type> & {
    percentage: number;
    interviewResearchCount?: number;
};

export type CustomerProblemPainLevelHypothesis = CustomerProblemHypothesis<HypothesisType.PainLevel> & {
    painLevel: PainLevel;
};

type CustomerProblemAlternativeSolutionBaseHypothesis<Type extends HypothesisType> = CustomerProblemHypothesis<Type> & {
    alternativeSolutionId: number;
};

export type CustomerProblemAlternativeSolutionHypothesis = CustomerProblemAlternativeSolutionBaseHypothesis<HypothesisType.AlternativeSolutionUsage>;

export type CustomerProblemAlternativeSolutionSatisfactionHypothesis = CustomerProblemAlternativeSolutionBaseHypothesis<
    HypothesisType.AlternativeSolutionSatisfaction
> & {
    satisfactionLevel: SatisfactionLevel;
};

export type AlternativeSolutionHypothesis = CustomerProblemAlternativeSolutionHypothesis | CustomerProblemAlternativeSolutionSatisfactionHypothesis;
export type Hypothesis = CustomerProblemPainLevelHypothesis | AlternativeSolutionHypothesis;
export type Hypotheses = { hypotheses: Hypothesis[]; totalCount: number };

type HypothesisCreateDataBase = {
    customerSegmentId: number;
    customerProblemId: number;
    percentage: number;
};

type HypothesisEditDataBase = {
    percentage: number;
    likelihood: HypothesisLikelihood | null;
    researchIds: number[];
};

export type CustomerProblemPainLevelHypothesisCreateData = HypothesisCreateDataBase & {
    painLevel: PainLevel;
};

export type CustomerProblemPainLevelHypothesisEditData = HypothesisEditDataBase & {
    painLevel: PainLevel;
};

export type CustomerProblemAlternativeSolutionHypothesisCreateData = HypothesisCreateDataBase & {
    alternativeSolutionId: number;
};

export type CustomerProblemAlternativeSolutionHypothesisEditData = HypothesisEditDataBase & { alternativeSolutionId: number };

export type CustomerProblemAlternativeSolutionSatisfactionHypothesisCreateData = CustomerProblemAlternativeSolutionHypothesisCreateData & {
    satisfactionLevel: SatisfactionLevel;
};

export type CustomerProblemAlternativeSolutionSatisfactionHypothesisEditData = CustomerProblemAlternativeSolutionHypothesisEditData & {
    satisfactionLevel: SatisfactionLevel;
};

class HypothesesService extends HttpServiceBase {
    constructor() {
        super('/api/hypotheses');
    }

    @ErrorWithOperationDisplayName('Get hypotheses')
    getHypotheses(
        ideaId: string,
        group: HypothesisGroup,
        skip?: number,
        order?: HypothesisSortOrder,
        take?: number,
        afterId?: number,
        researchId?: number
    ): Promise<Hypotheses> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'order', order?.toString());
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());
        this.addQueryParamIfPresent(queryParams, 'afterId', afterId?.toString());
        this.addQueryParamIfPresent(queryParams, 'researchId', researchId?.toString());

        return this.performRequest({
            path: `/${ideaId}/${group}`,
            queryParams
        });
    }

    @ErrorWithOperationDisplayName('Get all hypotheses')
    async getAllHypotheses(ideaId: string, group: HypothesisGroup, researchId?: number): Promise<Hypothesis[]> {
        const allHypotheses: Hypothesis[] = [];
        let afterId: number | undefined;
        const take = 1000;
        while (true) {
            const currentPageHypotheses = await this.getHypotheses(ideaId, group, undefined, HypothesisSortOrder.CreatedOnAsc, take, afterId, researchId);
            allHypotheses.push(...currentPageHypotheses.hypotheses);
            if (currentPageHypotheses.hypotheses.length < take) break;
            afterId = currentPageHypotheses.hypotheses[currentPageHypotheses.hypotheses.length - 1].id;
        }

        return allHypotheses;
    }

    @ErrorWithOperationDisplayName('Create customer problem pain level hypothesis')
    createCustomerProblemPainLevelHypothesis(ideaId: string, data: CustomerProblemPainLevelHypothesisCreateData): Promise<CustomerProblemPainLevelHypothesis> {
        return this.performRequest({
            path: `/${ideaId}/${HypothesisGroup.CustomerProblem}/${HypothesisType.PainLevel}`,
            method: RequestMethod.POST,
            body: data
        });
    }

    @ErrorWithOperationDisplayName('Edit customer problem pain level hypothesis')
    partiallyUpdateCustomerProblemPainLevelHypothesis(
        ideaId: string,
        hypothesisId: number,
        data: Partial<CustomerProblemPainLevelHypothesisEditData>
    ): Promise<CustomerProblemPainLevelHypothesis> {
        return this.performRequest({
            path: `/${ideaId}/${HypothesisGroup.CustomerProblem}/${HypothesisType.PainLevel}/${hypothesisId}`,
            method: RequestMethod.PATCH,
            body: data
        });
    }

    @ErrorWithOperationDisplayName('Create customer problem alternative solution hypothesis')
    createCustomerProblemAlternativeSolutionHypothesis(
        ideaId: string,
        data: CustomerProblemAlternativeSolutionHypothesisCreateData
    ): Promise<CustomerProblemAlternativeSolutionHypothesis> {
        return this.performRequest({
            path: `/${ideaId}/${HypothesisGroup.CustomerProblem}/${HypothesisType.AlternativeSolutionUsage}`,
            method: RequestMethod.POST,
            body: data
        });
    }

    @ErrorWithOperationDisplayName('Edit customer problem alternative solution hypothesis')
    partiallyUpdateCustomerProblemAlternativeSolutionHypothesis(
        ideaId: string,
        hypothesisId: number,
        data: Partial<CustomerProblemAlternativeSolutionHypothesisEditData>
    ): Promise<CustomerProblemAlternativeSolutionHypothesis> {
        return this.performRequest({
            path: `/${ideaId}/${HypothesisGroup.CustomerProblem}/${HypothesisType.AlternativeSolutionUsage}/${hypothesisId}`,
            method: RequestMethod.PATCH,
            body: data
        });
    }

    @ErrorWithOperationDisplayName('Create customer problem alternative solution satisfaction hypothesis')
    createCustomerProblemAlternativeSolutionSatisfactionHypothesis(
        ideaId: string,
        data: CustomerProblemAlternativeSolutionSatisfactionHypothesisCreateData
    ): Promise<CustomerProblemAlternativeSolutionSatisfactionHypothesis> {
        return this.performRequest({
            path: `/${ideaId}/${HypothesisGroup.CustomerProblem}/${HypothesisType.AlternativeSolutionSatisfaction}`,
            method: RequestMethod.POST,
            body: data
        });
    }

    @ErrorWithOperationDisplayName('Edit customer problem alternative solution satisfaction hypothesis')
    partiallyUpdateCustomerProblemAlternativeSolutionSatisfactionHypothesis(
        ideaId: string,
        hypothesisId: number,
        data: Partial<CustomerProblemAlternativeSolutionSatisfactionHypothesisEditData>
    ): Promise<CustomerProblemAlternativeSolutionSatisfactionHypothesis> {
        return this.performRequest({
            path: `/${ideaId}/${HypothesisGroup.CustomerProblem}/${HypothesisType.AlternativeSolutionSatisfaction}/${hypothesisId}`,
            method: RequestMethod.PATCH,
            body: data
        });
    }

    @ErrorWithOperationDisplayName('Get hypothesis')
    getHypothesis(ideaId: string, hypothesisGroup: HypothesisGroup, hypothesisId: number): Promise<Hypothesis> {
        return this.performRequest({
            path: `/${ideaId}/${hypothesisGroup}/${hypothesisId}`
        });
    }

    @ErrorWithOperationDisplayName('Delete hypothesis')
    deleteHypothesis(ideaId: string, hypothesisGroup: HypothesisGroup, hypothesisId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/${hypothesisGroup}/${hypothesisId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore hypothesis')
    restoreHypothesis(ideaId: string, hypothesisGroup: HypothesisGroup, hypothesisId: number): Promise<Hypothesis> {
        return this.performRequest({
            path: `/${ideaId}/${hypothesisGroup}/${hypothesisId}/restore`,
            method: RequestMethod.POST
        });
    }
}

export const hypothesesService = new HypothesesService();
