import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popover } from '@progress/kendo-react-tooltip';
import { ReactNode, useRef } from 'react';
import { useFittingPopoverCallout } from '../../hooks/commonHooks';

export function DialogPreTitlePopoverAction({
    isOpen,
    toggleIsOpen,
    icon: Icon,
    title,
    children
}: {
    isOpen?: boolean;
    toggleIsOpen?: () => void;
    icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    title?: string;
    children?: ReactNode;
}) {
    const buttonRef = useRef<Button>(null);
    const [popoverId, onPopoverPosition] = useFittingPopoverCallout('horizontal', buttonRef.current?.element);

    return (
        <div onClick={!isOpen ? e => e.stopPropagation() : undefined}>
            <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-icp-component-border">
                <Button
                    ref={buttonRef}
                    fillMode="flat"
                    themeColor="secondary"
                    size="large"
                    className="k-icp-svg-icon-button k-icp-pretitle-button !k-rounded-0"
                    disabled={!children}
                    onClick={() => toggleIsOpen?.()}
                    togglable
                    selected={isOpen}
                >
                    {Icon && <Icon className="k-icp-icon" />}
                </Button>
                <div className="k-separator" />
                <Popover
                    id={popoverId}
                    show={isOpen}
                    anchor={buttonRef.current?.element}
                    appendTo={document.body}
                    collision={{ horizontal: 'fit', vertical: 'flip' }}
                    position="bottom"
                    className="k-icp-pretitle-action-popover k-icp-popover-with-bg k-icp-popover-secondary-8"
                    margin={{ horizontal: 0, vertical: 2 }}
                    onPosition={onPopoverPosition}
                >
                    <div onClick={e => e.stopPropagation()}>
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3 k-justify-content-between k-mb-4 k-mr--2">
                            <strong className="k-fs-lg">{title}</strong>
                            <Button type="button" fillMode="flat" icon="close" onClick={() => toggleIsOpen?.()} />
                        </StackLayout>
                        {children}
                    </div>
                </Popover>
            </StackLayout>
        </div>
    );
}
