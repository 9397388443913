import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BoundDropDownButton } from '../../components/common/boundDropDownButton';
import { ScheduledItemDialog } from '../../components/events/scheduledItemDialog';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { TextMarker } from '../../components/ui/textMarker';
import { H1 } from '../../components/ui/typography';
import { useIsOnPage } from '../../hooks/routerHooks';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { appEventHub } from '../../services/appEvents';
import { ScheduleItemType } from '../../services/eventsService';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { TabHeader, TabbedLayout } from '../layouts/tabbedLayout';

const tabs: TabHeader[] = [
    {
        title: 'Events',
        to: ''
    },
    {
        title: 'Schedules',
        to: 'schedules'
    }
];

export function CalendarPage() {
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEdit = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const navigate = useNavigate();
    const { ideaId } = useParams();
    const [createScheduledItemData, setCreateScheduledData] = useState<{ type: ScheduleItemType; additionalProps?: Partial<Record<string, any>> }>();
    const isOnEventsPage = useIsOnPage(tabs[0].to, true);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-minh100">
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8 heading-row">
                <TextMarker text="Early Preview">
                    <H1>Calendar</H1>
                </TextMarker>
                {canEdit && (
                    <>
                        <div className="vertical-separator" />
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                            <BoundDropDownButton
                                text={<SvgIconButtonContent icon={PlusIcon}>New event</SvgIconButtonContent>}
                                popupSettings={{
                                    popupClass: 'new-event-dropdown-button-menu'
                                }}
                                themeColor="primary"
                                size="large"
                                items={[
                                    {
                                        text: 'Meeting',
                                        action() {
                                            setCreateScheduledData({ type: ScheduleItemType.Meeting });
                                        }
                                    },
                                    {
                                        text: 'Interview',
                                        action() {
                                            setCreateScheduledData({ type: ScheduleItemType.Meeting, additionalProps: { initialResearchId: null } });
                                        }
                                    },
                                    {
                                        text: 'Reach out',
                                        action() {
                                            setCreateScheduledData({ type: ScheduleItemType.ReachOut });
                                        }
                                    }
                                ]}
                            />

                            <Button size="large" onClick={() => navigate(`${tabs[1].to}/create`)}>
                                <SvgIconButtonContent icon={PlusIcon}>New schedule</SvgIconButtonContent>
                            </Button>
                        </StackLayout>
                        {createScheduledItemData && ideaId && (
                            <ScheduledItemDialog
                                ideaId={ideaId}
                                type={createScheduledItemData.type}
                                onSubmit={item => {
                                    setCreateScheduledData(undefined);
                                    appEventHub.trigger('scheduledItem', 'created', { item });
                                    if (!isOnEventsPage) navigate(tabs[0].to);
                                }}
                                onCancel={() => setCreateScheduledData(undefined)}
                                newItemAdditionalProps={createScheduledItemData.additionalProps}
                            />
                        )}
                    </>
                )}
            </StackLayout>

            <TabbedLayout headers={tabs} />
        </StackLayout>
    );
}
