import { useEffect, useMemo, useState } from 'react';
import { Insight, InsightCatalog, InsightContext, InsightContextProperty, InsightContextPropertySource, insightsService } from '../services/insightsService';
import { InterviewType } from '../services/interviewsService';

export function useInsightsCatalogContextFromInsights(contextDefinition?: InsightContext, insights?: Insight[]) {
    const insightsContextProperties = useMemo(() => {
        if (!insights || !contextDefinition) return undefined;

        const insightContextProperties = contextDefinition.properties.filter(p => p.source === InsightContextPropertySource.Insight);
        if (!insightContextProperties.length) return undefined;

        function resolveInsightProperty(property: InsightContextProperty) {
            const allValues: Set<unknown> | undefined = property.array ? new Set() : undefined;
            for (const insight of insights!) {
                const insightParameterBinding = insight.contextBindings.find(cb => cb.contextProperty === property.name);
                if (!insightParameterBinding) continue;
                const insightPropertyValue = insight.parameterValues.find(paramValue => paramValue.id === insightParameterBinding.insightParameterId);
                if (!insightPropertyValue) continue;
                if (allValues) allValues.add(insightPropertyValue.value);
                else return insightPropertyValue.value;
            }

            return allValues ? Array.from(allValues) : allValues;
        }

        const propertiesFromInsights: Record<string, unknown> = {};
        for (const contextProperty of insightContextProperties) {
            propertiesFromInsights[contextProperty.name] = resolveInsightProperty(contextProperty);
        }

        return propertiesFromInsights;
    }, [contextDefinition, insights]);

    return insightsContextProperties;
}

export function useMergedInsightsContext(contexts: (Record<string, unknown> | undefined)[]) {
    const mergedContext = useMemo(() => {
        return contexts.reduce((mergedContext, contextToMerge) => {
            if (!mergedContext) return contextToMerge;
            if (!contextToMerge) return mergedContext;

            return { ...mergedContext, ...contextToMerge };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, contexts);

    return mergedContext;
}

export function useInterviewInsightsCatalog(interviewType: InterviewType) {
    const [catalog, setCatalog] = useState<InsightCatalog>();

    useEffect(() => {
        insightsService.getCatalogByInterviewType(interviewType).then(setCatalog);
    }, [interviewType]);

    return catalog;
}
