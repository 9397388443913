import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BoundDropDownButton } from '../../components/common/boundDropDownButton';
import { hypothesisGroupLabelMap, hypothesisGroupUrlPathMap, hypothesisTypeLabelMap, useHypothesisParams } from '../../components/hypotheses/common';
import { HypothesisEditMode, HypothesisEditor } from '../../components/hypotheses/hypothesisEditor';
import { HypothesisLikelihoodChip } from '../../components/hypotheses/hypothesisLikelihoodChip';
import { HypothesisResearchScale } from '../../components/hypotheses/hypothesisResearchScale';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { H2, H3, P } from '../../components/ui/typography';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { ReactComponent as ResearchIcon } from '../../icons/interview.svg';
import { combineClassNames } from '../../services/common';
import { Hypothesis, hypothesesService } from '../../services/hypothesesService';
import { RealTimeUpdateHypothesisEventData, RealTimeUpdateResearchEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { researchService } from '../../services/researchService';
import { UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

export function ViewHypothesisPage() {
    const { ideaId, hypothesisGroup, hypothesisId } = useHypothesisParams();

    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEdit = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const [hypothesis, setHypothesis] = useState<Hypothesis>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const loadHypothesis = useCallback(() => {
        hypothesesService.getHypothesis(ideaId, hypothesisGroup, hypothesisId).then(setHypothesis);
    }, [hypothesisGroup, hypothesisId, ideaId]);

    useEffect(() => {
        loadHypothesis();
    }, [loadHypothesis]);

    const hypothesisListPath = hypothesis ? `../${hypothesisGroupUrlPathMap[hypothesis.group]}` : '..';
    const hypothesisRef = useRef(hypothesis);
    hypothesisRef.current = hypothesis;
    useEffect(() => {
        function reloadCurrentHypothesisIfNeeded(e: RealTimeUpdateHypothesisEventData) {
            if (e.ideaId !== ideaId || e.hypothesisId !== hypothesisId) return;

            loadHypothesis();
        }

        function handleHypothesisDeleted(e: RealTimeUpdateHypothesisEventData) {
            if (e.ideaId !== ideaId || e.hypothesisId !== hypothesisId) return;

            dispatch(addNotification({ content: 'The hypothesis was deleted' }));
            navigate(hypothesisListPath);
        }

        async function handleResearchUpdated(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId) return;
            const loadedHypothesis = hypothesisRef.current;
            if (!loadedHypothesis || !loadedHypothesis.research || !loadedHypothesis.research.some(r => r.id === e.researchId)) return;

            const updatedResearch = await researchService.getProblemValidationResearch(ideaId, e.researchId);

            setHypothesis(h => {
                if (!h || !h.research || !h.research.some(r => r.id === e.researchId)) return h;
                return { ...h, research: h.research.map(r => (r.id === e.researchId ? updatedResearch : r)) };
            });
        }

        function handleResearchDeleted(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId) return;
            setHypothesis(h => {
                if (!h || !h.research || !h.research.some(r => r.id === e.researchId)) return h;
                return { ...h, research: h.research.filter(r => r.id !== e.researchId) };
            });
        }

        async function handleResearchRestored(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId) return;

            const restoredResearch = await researchService.getProblemValidationResearch(ideaId, e.researchId);
            if (!restoredResearch.hypothesisIds || !restoredResearch.hypothesisIds.some(hId => hId === hypothesisId)) return;

            setHypothesis(h => {
                if (!h) return h;
                if (!h.research) return { ...h, research: [restoredResearch] };
                return { ...h, research: [...h.research, restoredResearch] };
            });
        }

        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisUpdate', reloadCurrentHypothesisIfNeeded);
        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisDelete', handleHypothesisDeleted);
        realTimeUpdatesEventHub.addEventListener('research', 'update', handleResearchUpdated);
        realTimeUpdatesEventHub.addEventListener('research', 'delete', handleResearchDeleted);
        realTimeUpdatesEventHub.addEventListener('research', 'restore', handleResearchRestored);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisUpdate', reloadCurrentHypothesisIfNeeded);
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisDelete', handleHypothesisDeleted);
            realTimeUpdatesEventHub.removeEventListener('research', 'update', handleResearchUpdated);
            realTimeUpdatesEventHub.removeEventListener('research', 'delete', handleResearchDeleted);
            realTimeUpdatesEventHub.removeEventListener('research', 'restore', handleResearchRestored);
        };
    }, [dispatch, hypothesisId, hypothesisListPath, ideaId, loadHypothesis, navigate]);

    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();
    function deleteHypothesis() {
        if (!hypothesis || !ideaId) return;

        showConfirmDialog({
            title: 'Delete hypothesis',
            content: 'Are you sure you want to delete this hypothesis?',
            confirmCheckboxText: 'I acknowledge that this cannot be undone',
            confirmButtonText: 'Delete hypothesis',
            callback: async () => {
                await hypothesesService.deleteHypothesis(ideaId, hypothesis.group, hypothesis.id);
                navigate(hypothesisListPath);
            }
        });
    }

    if (!hypothesis) return <LoadingIndicator size="big" className="!k-pos-absolute k-centered" />;

    return (
        <>
            <header className="k-mb-8">
                <Link to={hypothesisListPath} className="k-button k-button-link k-fs-sm k-button-link-base k-font-weight-semibold k-mt-2">
                    <span className="k-icon k-i-arrow-chevron-left"></span> All hypotheses
                </Link>
            </header>
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-10">
                    <HypothesisHeader hypothesis={hypothesis} canEdit={canEdit} onDelete={deleteHypothesis} />
                    <HypothesisEditor value={hypothesis} editMode={HypothesisEditMode.View} bordered={true} />
                    <HypothesisDetails hypothesis={hypothesis} />
                </StackLayout>
                <StackLayout
                    orientation="vertical"
                    align={{ horizontal: 'stretch', vertical: 'top' }}
                    className="-w2 k-gap-2 k-icp-panel-base k-rounded-lg !k-p-4"
                >
                    <H3>Success scale</H3>
                    <P>Use this scale to estimate how likely your idea is to succeed in the market.</P>
                    <HypothesisResearchScale
                        hypothesisId={hypothesisId}
                        hypothesisGroup={hypothesisGroup}
                        ideaId={ideaId}
                        researchIdsInContext={hypothesis.research?.map(r => r.id)}
                        className="k-mt-4"
                    />
                </StackLayout>
            </StackLayout>
            {confirmDialog}
        </>
    );
}

function HypothesisHeader({ hypothesis, canEdit, onDelete }: { hypothesis: Hypothesis; canEdit: boolean; onDelete: () => void }) {
    return (
        <div>
            <H2 className="!k-mb-3">{hypothesisGroupLabelMap[hypothesis.group]} Hypothesis</H2>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                <Link
                    to="./../edit"
                    className={combineClassNames(
                        'k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md',
                        canEdit ? undefined : 'k-disabled'
                    )}
                >
                    <SvgIconButtonContent icon={EditIcon}>Edit hypothesis</SvgIconButtonContent>
                </Link>
                <BoundDropDownButton
                    icon="arrow-60-down"
                    buttonClass="k-flex-row-reverse"
                    text="More actions"
                    items={[
                        {
                            text: 'Delete hypothesis',
                            action: onDelete,
                            separated: true,
                            danger: true,
                            disabled: !canEdit
                        }
                    ]}
                />
            </StackLayout>
        </div>
    );
}

function HypothesisDetails({ hypothesis }: { hypothesis: Hypothesis }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            <H3>Details</H3>
            <HypothesisDetail label="Hypothesis type:">
                <span>{hypothesisTypeLabelMap[hypothesis.type]}</span>
            </HypothesisDetail>
            <HypothesisDetail label="Related research:">
                {hypothesis.research && hypothesis.research.length ? (
                    <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1">
                        {hypothesis.research.map(research => (
                            <StackLayout key={research.id} align={{ horizontal: 'end', vertical: 'middle' }} className="k-gap-1">
                                <ResearchIcon className="k-icp-icon k-icp-icon-size-4" />
                                <Link to={`../../research/${research.id}`} className="k-button-link-secondary">
                                    {research.title}
                                </Link>
                            </StackLayout>
                        ))}
                    </StackLayout>
                ) : (
                    undefined
                )}
            </HypothesisDetail>
            <HypothesisDetail label="Likelihood:">
                <HypothesisLikelihoodChip likelihood={hypothesis.likelihood} />
            </HypothesisDetail>
        </StackLayout>
    );
}

function HypothesisDetail({ label, children }: { label: string; children?: ReactNode }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
            <span className="k-icp-subtle-text">{label}</span>
            {children ?? <span className="k-icp-subtle-text">Not available</span>}
        </StackLayout>
    );
}
