import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useAsRef } from '../../../hooks/commonHooks';
import { ReactComponent as PositiveVerdictIcon } from '../../../icons/check-circle-closed.svg';
import { ReactComponent as UnknownVerdictIcon } from '../../../icons/minus-circle.svg';
import { ReactComponent as NegativeVerdictIcon } from '../../../icons/x-circle.svg';
import { combineClassNames } from '../../../services/common';
import { AsyncOperationsTracker } from '../../../services/common/common.async';
import { ReducedPerson } from '../../../services/contactsService';
import { ScrollCancellationSource, domService } from '../../../services/domService';
import { Hypothesis, HypothesisType } from '../../../services/hypothesesService';
import { HypothesisVerdict } from '../../../services/interviewsService';
import { InterviewHypothesisQuote } from '../../../services/researchService';
import { ValidationScope, ValidationScopeHandle, ValidationUnit, ValidationUnitHandle } from '../../common/validation';
import { HypothesesByProblemAndTypeList } from '../../hypotheses/hypothesesByProblemAndTypeList';
import { PersonalizedHypothesisView } from '../../hypotheses/hypothesisView';
import { requiredValidator } from '../../ui/inputs';
import LoadingIndicator from '../../ui/loadingIndicator';
import { InterviewItemEditorView, InterviewItemEditorViewActionsBar, InterviewItemEditorViewButton, InterviewItemEditorViewHandle } from '../interviewItem';
import { InterviewItemQuotesEditList } from '../interviewItemQuotes';
import { CustomerProblemEvaluatedHypothesesGroup, EvaluatedHypothesis, findHypothesis } from './interviewHypotheses';

export type InterviewHypothesesEditListHandle = { commit: () => Promise<boolean> };
export const InterviewHypothesesEditList = forwardRef<
    InterviewHypothesesEditListHandle,
    {
        ideaId: string;
        interviewee?: ReducedPerson;
        groups?: CustomerProblemEvaluatedHypothesesGroup[];
        onVerdictChange?: (hypothesisId: number, verdict: HypothesisVerdict | null, isAutomatic?: boolean) => Promise<void>;
        selectedHypothesisId?: number;
        onHypothesisSelect?: (hypothesisId: number) => void;
        quotes?: InterviewHypothesisQuote[];
        currentInterviewId?: number;
        onCreateQuote?: (hypothesisId: number, entryId: number, startIndex: number, endIndex: number) => Promise<void>;
        onUpdateQuote?: (quoteId: number, startIndex: number, endIndex: number) => Promise<void>;
        onDeleteQuote?: (quoteId: number) => Promise<void>;
        onQuoteClick?: (quoteId: number) => void;
        selectedQuoteId?: number;
        isReadOnly?: boolean;
    }
>(function InterviewHypothesesEditList(
    {
        ideaId,
        interviewee,
        groups,
        onVerdictChange,
        selectedHypothesisId,
        onHypothesisSelect,
        quotes,
        currentInterviewId,
        onCreateQuote,
        onUpdateQuote,
        onDeleteQuote,
        onQuoteClick,
        selectedQuoteId,
        isReadOnly
    },
    ref
) {
    const editorsMapRef = useRef<Partial<Record<number, HypothesisEvaluationEditorHandle>>>({});
    const operationsTracker = useRef(new AsyncOperationsTracker());
    const hypothesesValidationScopeRef = useRef<ValidationScopeHandle>(null);

    function findFirstInvalidHypothesis() {
        if (!groups) return undefined;

        return findHypothesis(groups, h => !editorsMapRef.current[h.hypothesis.id]?.isValid);
    }

    const findFirstInvalidHypothesisRef = useAsRef(findFirstInvalidHypothesis);
    const onHypothesisSelectRef = useAsRef(onHypothesisSelect);
    useImperativeHandle(
        ref,
        () => ({
            async commit() {
                await operationsTracker.current.await();

                if (hypothesesValidationScopeRef.current) {
                    hypothesesValidationScopeRef.current.validate();

                    if (!hypothesesValidationScopeRef.current.isValid) {
                        const firstInvalidHypothesis = findFirstInvalidHypothesisRef.current();
                        if (firstInvalidHypothesis) onHypothesisSelectRef.current?.(firstInvalidHypothesis.hypothesis.id);
                    }

                    return hypothesesValidationScopeRef.current.isValid;
                }

                return true;
            }
        }),
        [findFirstInvalidHypothesisRef, onHypothesisSelectRef]
    );

    const hasGroups = groups !== undefined;
    useEffect(() => {
        if (!hasGroups || selectedHypothesisId === undefined) return;

        const selectedEntryEditorRef = editorsMapRef.current[selectedHypothesisId];
        if (!selectedEntryEditorRef || !selectedEntryEditorRef.element) return;

        const scrollCancelation: ScrollCancellationSource = {};
        domService.scrollVerticallyIntoViewIfNeeded(selectedEntryEditorRef.element, undefined, scrollCancelation);

        return () => {
            scrollCancelation.cancel = true;
        };
    }, [hasGroups, selectedHypothesisId]);

    if (!groups || !interviewee) return <LoadingIndicator size="big" className="k-display-block -block-center" />;

    function updateEditorRef(ref: HypothesisEvaluationEditorHandle | null, hypothesisId: number) {
        if (ref) editorsMapRef.current[hypothesisId] = ref;
        else delete editorsMapRef.current[hypothesisId];
    }

    const triggerOnVerdictChange =
        onVerdictChange &&
        function triggerOnVerdictChange(hypothesisId: number, verdict: HypothesisVerdict | null, childHypothesesIds?: number[]): Promise<void> {
            if ((verdict === HypothesisVerdict.False || verdict === HypothesisVerdict.Unknown) && childHypothesesIds && childHypothesesIds.length) {
                return operationsTracker.current
                    .track(
                        Promise.all([
                            onVerdictChange(hypothesisId, verdict),
                            childHypothesesIds.map(childHypothesisId => onVerdictChange(childHypothesisId, HypothesisVerdict.Unknown, true))
                        ])
                    )
                    .then();
            }

            return operationsTracker.current.track(onVerdictChange(hypothesisId, verdict));
        };

    const triggerOnCreateQuote =
        onCreateQuote &&
        function triggerOnCreateQuote(hypothesisId: number, entryId: number, startIndex: number, endIndex: number): Promise<void> {
            return operationsTracker.current.track(onCreateQuote(hypothesisId, entryId, startIndex, endIndex));
        };

    const triggerOnUpdateQuote =
        onUpdateQuote &&
        function triggerOnUpdateQuote(quoteId: number, startIndex: number, endIndex: number): Promise<void> {
            return operationsTracker.current.track(onUpdateQuote(quoteId, startIndex, endIndex));
        };

    const triggerOnDeleteQuote =
        onDeleteQuote &&
        function triggerOnUpdateQuote(quoteId: number): Promise<void> {
            return operationsTracker.current.track(onDeleteQuote(quoteId));
        };

    function renderHypothesisEvaluationEditor(
        evaluatedHypothesis: EvaluatedHypothesis<Hypothesis>,
        parentHypotheses?: EvaluatedHypothesis<Hypothesis>[],
        childHypotheses?: EvaluatedHypothesis<Hypothesis>[]
    ) {
        const hypothesisId = evaluatedHypothesis.hypothesis.id;
        const disabled =
            evaluatedHypothesis.verdict !== undefined &&
            parentHypotheses !== undefined &&
            parentHypotheses.some(h => h.verdict === HypothesisVerdict.False || h.verdict === HypothesisVerdict.Unknown);

        return (
            <HypothesisEvaluationEditor
                ref={r => updateEditorRef(r, hypothesisId)}
                ideaId={ideaId}
                key={hypothesisId}
                person={interviewee!}
                hypothesis={evaluatedHypothesis.hypothesis}
                verdict={evaluatedHypothesis.verdict}
                onVerdictChange={
                    triggerOnVerdictChange &&
                    (v =>
                        triggerOnVerdictChange(
                            hypothesisId,
                            v,
                            childHypotheses?.map(h => h.hypothesis.id)
                        ))
                }
                selected={hypothesisId === selectedHypothesisId}
                quotes={quotes?.filter(q => q.hypothesisId === hypothesisId)}
                currentInterviewId={currentInterviewId}
                onCreateQuote={triggerOnCreateQuote && ((entryId, startIndex, endIndex) => triggerOnCreateQuote(hypothesisId, entryId, startIndex, endIndex))}
                onUpdateQuote={triggerOnUpdateQuote}
                onDeleteQuote={triggerOnDeleteQuote}
                onQuoteAction={onHypothesisSelect && (() => onHypothesisSelect(hypothesisId))}
                onQuoteClick={onQuoteClick}
                selectedQuoteId={selectedQuoteId}
                disabled={disabled}
                readonly={isReadOnly}
            />
        );
    }

    return (
        <ValidationScope ref={hypothesesValidationScopeRef}>
            <HypothesesByProblemAndTypeList
                groups={groups}
                renderPainLevelHypothesis={hypothesis => renderHypothesisEvaluationEditor(hypothesis)}
                renderAlternativeSolutionHypothesis={(hypothesis, alternativeSolutionHypothesesGroup) =>
                    hypothesis.hypothesis.type === HypothesisType.AlternativeSolutionUsage
                        ? renderHypothesisEvaluationEditor(hypothesis, undefined, alternativeSolutionHypothesesGroup.alternativeSolutionSatisfactionHypotheses)
                        : renderHypothesisEvaluationEditor(hypothesis, alternativeSolutionHypothesesGroup.alternativeSolutionHypotheses)
                }
            />
        </ValidationScope>
    );
});

const hypothesisVerdictValue = requiredValidator('Hypothesis should be evaluated', false);
type HypothesisEvaluationEditorHandle = InterviewItemEditorViewHandle & { isValid: boolean };
const HypothesisEvaluationEditor = forwardRef<
    HypothesisEvaluationEditorHandle,
    {
        ideaId: string;
        person: ReducedPerson;
        hypothesis: Hypothesis;
        verdict?: HypothesisVerdict;
        onVerdictChange?: (verdict: HypothesisVerdict | null) => Promise<void>;
        selected?: boolean;
        quotes?: InterviewHypothesisQuote[];
        currentInterviewId?: number;
        onQuoteAction?: () => void;
        onCreateQuote?: (entryId: number, startIndex: number, endIndex: number) => Promise<void>;
        onUpdateQuote?: (quoteId: number, startIndex: number, endIndex: number) => Promise<void>;
        onDeleteQuote?: (quoteId: number) => void;
        onQuoteClick?: (quoteId: number) => void;
        selectedQuoteId?: number;
        disabled?: boolean;
        readonly?: boolean;
    }
>(function HypothesisEvaluationEditor(
    {
        ideaId,
        person,
        hypothesis,
        verdict,
        onVerdictChange,
        selected,
        quotes,
        currentInterviewId,
        onQuoteAction,
        onCreateQuote,
        onUpdateQuote,
        onDeleteQuote,
        onQuoteClick,
        selectedQuoteId,
        disabled,
        readonly
    },
    ref
) {
    const [overriddenVerdict, setOverriddenVerdict] = useState<{ value: HypothesisVerdict | undefined }>();
    const [insertingQuote, setInsertingQuote] = useState(false);
    const [editQuoteId, setEditQuoteId] = useState<number>();

    const validationUnitRef = useRef<ValidationUnitHandle>(null);
    const editorViewRef = useRef<InterviewItemEditorViewHandle>(null);
    useImperativeHandle(
        ref,
        () => ({
            get element() {
                if (!editorViewRef.current) return null;
                return editorViewRef.current.element;
            },
            get isValid() {
                return validationUnitRef.current?.isValid ?? true;
            }
        }),
        []
    );

    function triggerOnVerdictChange(verdict: HypothesisVerdict | null) {
        setOverriddenVerdict({ value: verdict ?? undefined });
        if (onVerdictChange) onVerdictChange(verdict).finally(() => setOverriddenVerdict(undefined));
    }

    function stopEditingQuote(quoteId: number) {
        setEditQuoteId(id => (id === quoteId ? undefined : id));
    }

    if (!selected && insertingQuote) setInsertingQuote(false);

    const verdictValue = overriddenVerdict ? overriddenVerdict.value : verdict;
    return (
        <ValidationUnit ref={validationUnitRef} name={`${hypothesis.id}_verdict`} value={verdictValue} validator={hypothesisVerdictValue}>
            {(errorMessage, onValidationVerdictChange) => (
                <HypothesisEvaluationEditorView
                    ref={editorViewRef}
                    person={person}
                    hypothesis={hypothesis}
                    verdict={verdictValue}
                    onVerdictChange={v => {
                        onValidationVerdictChange(v ?? undefined, true);
                        if (v && v !== HypothesisVerdict.Unknown && quotes && !quotes.length) setInsertingQuote(true);
                        return triggerOnVerdictChange(v);
                    }}
                    selected={selected}
                    errorMessage={errorMessage}
                    disabled={disabled}
                    hideActions={readonly}
                >
                    {(quotes && quotes.length > 0) || (verdict !== undefined && verdict !== HypothesisVerdict.Unknown && !disabled && !readonly) ? (
                        <>
                            {!readonly && <div className="k-separator" />}
                            <InterviewItemQuotesEditList
                                ideaId={ideaId}
                                quotes={quotes}
                                currentInterviewId={currentInterviewId}
                                addingQuote={insertingQuote}
                                onAddQuote={() => {
                                    setEditQuoteId(undefined);
                                    setInsertingQuote(true);
                                    onQuoteAction?.();
                                }}
                                editQuoteId={editQuoteId}
                                onEditQuote={quoteId => {
                                    setInsertingQuote(false);
                                    setEditQuoteId(quoteId);
                                    onQuoteAction?.();
                                }}
                                onCancelAddingQuote={() => setInsertingQuote(false)}
                                onCancelEditingQuote={stopEditingQuote}
                                onDeleteQuote={quoteId => {
                                    onDeleteQuote?.(quoteId);
                                    onQuoteAction?.();
                                }}
                                onCreateQuote={
                                    onCreateQuote &&
                                    ((entryId: number, startIndex: number, endIndex: number) =>
                                        onCreateQuote(entryId, startIndex, endIndex).finally(() => setInsertingQuote(false)))
                                }
                                onUpdateQuote={
                                    onUpdateQuote &&
                                    ((quoteId: number, startIndex: number, endIndex: number) =>
                                        onUpdateQuote(quoteId, startIndex, endIndex).finally(() => stopEditingQuote(quoteId)))
                                }
                                onQuoteClick={onQuoteClick}
                                selectedQuoteId={selectedQuoteId}
                                disabled={disabled || readonly}
                            />
                        </>
                    ) : (
                        undefined
                    )}
                    {readonly && verdict && (
                        <StackLayout align={{ horizontal: 'end', vertical: 'middle' }} className="k-gap-1">
                            <span className="k-fs-sm">Marked as:</span>
                            <HypothesisVerdictChip verdict={verdict} />
                        </StackLayout>
                    )}
                </HypothesisEvaluationEditorView>
            )}
        </ValidationUnit>
    );
});

const hypothesisVerdictClassNameMap: Record<HypothesisVerdict, string> = {
    [HypothesisVerdict.True]: 'k-border-success',
    [HypothesisVerdict.False]: 'k-border-error',
    [HypothesisVerdict.Unknown]: 'k-icp-border-base-50'
};
export function HypothesisVerdictChip({ verdict }: { verdict: HypothesisVerdict }) {
    return (
        <span className={combineClassNames('k-px-2 k-rounded k-fs-sm k-border k-border-solid k-fs-sm', hypothesisVerdictClassNameMap[verdict])}>{verdict}</span>
    );
}

const HypothesisEvaluationEditorView = forwardRef<
    InterviewItemEditorViewHandle,
    {
        person: ReducedPerson;
        hypothesis: Hypothesis;
        verdict?: HypothesisVerdict;
        onVerdictChange?: (verdict: HypothesisVerdict | null) => void;
        selected?: boolean;
        errorMessage?: string;
        children?: ReactNode;
        disabled?: boolean;
        hideActions?: boolean;
    }
>(function HypothesisEvaluationEditorView({ person, hypothesis, verdict, onVerdictChange, selected, errorMessage, children, disabled, hideActions }, ref) {
    function toggleVerdict(newVerdict: HypothesisVerdict) {
        if (!onVerdictChange) return;

        onVerdictChange(newVerdict === verdict ? null : newVerdict);
    }

    return (
        <InterviewItemEditorView ref={ref} selected={selected} errorMessage={errorMessage}>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-3 k-p-4 k-icp-component-border">
                <PersonalizedHypothesisView person={person} hypothesis={hypothesis} bordered />
                {!hideActions && (
                    <InterviewItemEditorViewActionsBar
                        actions={[
                            <InterviewItemEditorViewButton
                                icon={PositiveVerdictIcon}
                                themeColor="success"
                                selected={verdict === HypothesisVerdict.True}
                                onClick={() => toggleVerdict(HypothesisVerdict.True)}
                                disabled={disabled}
                            >
                                Mark as true
                            </InterviewItemEditorViewButton>,
                            <InterviewItemEditorViewButton
                                icon={NegativeVerdictIcon}
                                themeColor="error"
                                selected={verdict === HypothesisVerdict.False}
                                onClick={() => toggleVerdict(HypothesisVerdict.False)}
                                disabled={disabled}
                            >
                                Mark as false
                            </InterviewItemEditorViewButton>,
                            <InterviewItemEditorViewButton
                                icon={UnknownVerdictIcon}
                                selected={verdict === HypothesisVerdict.Unknown}
                                onClick={() => toggleVerdict(HypothesisVerdict.Unknown)}
                                disabled={disabled}
                            >
                                Mark as unknown
                            </InterviewItemEditorViewButton>
                        ]}
                    />
                )}
                {children}
            </StackLayout>
        </InterviewItemEditorView>
    );
});
