import { StackLayout } from '@progress/kendo-react-layout';
import { ReducedUserViewModel } from '../../services/usersService';
import UserAvatar from './userAvatar';

export function UserSimpleView({ user, isCurrent, gap = 4 }: { user: ReducedUserViewModel; isCurrent?: boolean; gap?: number }) {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className={`k-gap-${gap}`}>
            <UserAvatar picture={user.picture} initials={user.initials} colorIndex={user.colorIndex} />

            <strong>
                {user.firstName} {user.lastName}
                {isCurrent && ' (You)'}
            </strong>
        </StackLayout>
    );
}
