import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Checkbox, TextArea } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ComponentType, Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, resolvePath } from 'react-router-dom';
import { useInProgressOperationsTracker } from '../../hooks/commonHooks';
import { ConfirmDialog } from '../../hooks/dialogHooks';
import { useProblemValidationResearch } from '../../hooks/researchHooks';
import { useLazyHtmlParser } from '../../hooks/useLazyHtmlParser';
import { ReactComponent as AgendaIcon } from '../../icons/agenda.svg';
import { ReactComponent as BookIcon } from '../../icons/book.svg';
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';
import { ReactComponent as ConfirmInviteReceived } from '../../icons/check-circle.svg';
import { ReactComponent as ContactCardIcon } from '../../icons/contact-card.svg';
import { ReactComponent as InterviewIcon, ReactComponent as ResearchIcon } from '../../icons/interview.svg';
import { ReactComponent as IVAGeneratedIcon } from '../../icons/IVA-generated.svg';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';
import { ReactComponent as LinkedInCheckIcon } from '../../icons/linkedin-check.svg';
import { ReactComponent as LinkedInIcon } from '../../icons/linkedin.svg';
import { ReactComponent as EmailCheckIcon } from '../../icons/mail-check.svg';
import { ReactComponent as EmailIcon } from '../../icons/mail.svg';
import { ReactComponent as LocationIcon } from '../../icons/map-pin-1.svg';
import { ReactComponent as MessageIcon } from '../../icons/message-circle.svg';
import { ReactComponent as PhoneCheckIcon } from '../../icons/phone-check.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as AddIcon } from '../../icons/plus.svg';
import { ReactComponent as SendCheckIcon } from '../../icons/send-alt-check.svg';
import { ReactComponent as SendIcon } from '../../icons/send-alt.svg';
import { ReactComponent as ConnectionTypeIcon } from '../../icons/user-connect.svg';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import { MeetingDataForResearch, meetingsService, notesService, reachOutsService } from '../../services/activitiesService';
import { executeWithServerErrorInToast, resolveAbsoluteUrl } from '../../services/common';
import { ReducedPerson } from '../../services/contactsService';
import { dateTimeService } from '../../services/dateTimeService';
import {
    Meeting,
    MeetingData,
    Note,
    NoteData,
    ReachOut,
    ReachOutConnectionType,
    ReachOutData,
    ReachOutType,
    ResearchReachOut,
    ResearchReachOutData
} from '../../services/events';
import { InterviewStage } from '../../services/interviewsService';
import { ProblemValidationResearch, ReducedResearch, ResearchReachOutTemplate, researchService } from '../../services/researchService';
import { Schedule, schedulesService } from '../../services/schedulesService';
import { ReducedUser, User, usersService } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { canvasItemsZoneDependent } from '../canvas/canvasItemsZone';
import { DateAndTimePicker, KeyboardNavigatableDateInput } from '../common/date';
import { PersonPicker } from '../contacts/contactPicker';
import { PersonSimpleView } from '../contacts/view';
import { InterviewMainActionButton } from '../interview/interviewMainActionButton';
import { interviewStageGuidanceRender } from '../interview/interviewStage';
import { ResearchPicker } from '../research/researchPicker';
import { ContentSectionWithCopy } from '../ui/contentSectionWithCopy';
import { Icon } from '../ui/icon';
import {
    ButtonGroupSelector,
    ButtonGroupSelectorOption,
    ValidatedInput,
    ValidatedInputCheckboxLayout,
    ValidatedInputHorizontalLayout,
    dateInTheFutureValidator,
    defaultValidators,
    maxLengthValidator,
    requiredValidator
} from '../ui/inputs';
import LoadingIndicator from '../ui/loadingIndicator';
import { SvgIconButtonContent } from '../ui/svgIconButtonContent';
import { TextWithShowMoreByLines } from '../ui/textWithShowMoreByLines';
import { InviteContentData, problemValidationInviteContentProvider } from './problemValidationInviteContentProvider';

export const reachOutLabel: Record<ReachOutType, string> = {
    [ReachOutType.Email]: 'email',
    [ReachOutType.Phone]: 'phone',
    [ReachOutType.LinkedIn]: 'LinkedIn',
    [ReachOutType.Other]: 'other'
};
export const reachOutIcon: Record<ReachOutType, ComponentType<React.SVGProps<SVGSVGElement>>> = {
    [ReachOutType.Email]: EmailIcon,
    [ReachOutType.Phone]: PhoneIcon,
    [ReachOutType.LinkedIn]: LinkedInIcon,
    [ReachOutType.Other]: SendIcon
};
export const completedReachOutIcon: Record<ReachOutType, ComponentType<React.SVGProps<SVGSVGElement>>> = {
    [ReachOutType.Email]: EmailCheckIcon,
    [ReachOutType.Phone]: PhoneCheckIcon,
    [ReachOutType.LinkedIn]: LinkedInCheckIcon,
    [ReachOutType.Other]: SendCheckIcon
};

const personValidators = requiredValidator('Contact');
function EventDialog<TEditData, TEvent>({
    title,
    onCancel,
    onSaved,
    person,
    ideaId,
    initialData,
    loadingInitialData,
    isEditing,
    editData,
    save,
    createButtonText,
    children,
    onPersonChange,
    readOnly,
    saveDisabled,
    width,
    topContent
}: {
    title: string;
    isEditing: boolean;
    editData?: TEditData;
    save: (personId: number, data: TEditData) => Promise<TEvent>;
    onCancel?: () => void;
    onSaved?: (event: TEvent) => void | Promise<void>;
    ideaId: string;
    person?: ReducedPerson;
    itemId?: number;
    initialData?: Partial<TEditData>;
    loadingInitialData?: boolean;
    createButtonText: string;
    children?: ReactNode | ((formRenderProps: FormRenderProps) => ReactNode);
    onPersonChange?: (person: ReducedPerson | null | undefined, formRenderProps: FormRenderProps) => void;
    readOnly?: boolean;
    saveDisabled?: boolean;
    width?: number;
    topContent?: ReactNode;
}) {
    const [saveInProgress, toTrackableSaveOperation] = useInProgressOperationsTracker();

    return (
        <Dialog title={title} minWidth={640} width={width} className="k-icp-dialog-no-padding k-icp-dialog-with-form" onClose={onCancel}>
            {person && (
                <div className="k-p-4 k-icp-panel-base k-border-b k-border-b-solid k-icp-component-border">
                    <PersonSimpleView person={person} showJobTitle={false} />
                </div>
            )}
            {(!isEditing && !loadingInitialData) || editData ? (
                <Form
                    initialValues={isEditing ? editData : initialData}
                    onSubmit={
                        saveDisabled
                            ? undefined
                            : toTrackableSaveOperation(async (data: Record<string, any>) => {
                                  const pickedPerson = data.person as ReducedPerson | undefined;
                                  const personId = pickedPerson ? pickedPerson.id : person?.id;
                                  if (!personId) throw new Error('Missing person');
                                  const savedEventData = await save(personId, data as TEditData);
                                  await onSaved?.(savedEventData);
                              })
                    }
                    ignoreModified={true}
                    render={formRenderProps => (
                        <FormElement className="k-icp-component-border">
                            <div className="k-window-content !k-p-0">
                                {topContent && (
                                    <StackLayout
                                        orientation="vertical"
                                        align={{ horizontal: 'start', vertical: 'top' }}
                                        className="k-gap-4 k-p-4 k-border-b k-border-b-solid k-icp-component-border"
                                    >
                                        {topContent}
                                    </StackLayout>
                                )}
                                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4 k-p-4">
                                    {!person && (
                                        <EventDialogFieldSection icon={ContactCardIcon}>
                                            <Field
                                                name="person"
                                                component={ValidatedInput}
                                                inputType={PersonPicker}
                                                ideaId={ideaId}
                                                placeholder="Select contact..."
                                                wrapperClass="k-flex-1"
                                                onChange={
                                                    onPersonChange
                                                        ? e => {
                                                              const selectedPerson: ReducedPerson | undefined | null = e.value;
                                                              onPersonChange(selectedPerson, formRenderProps);
                                                          }
                                                        : undefined
                                                }
                                                validator={personValidators}
                                            />
                                        </EventDialogFieldSection>
                                    )}
                                    {typeof children === 'function' ? children(formRenderProps) : children}
                                </StackLayout>
                            </div>

                            {!readOnly && (
                                <DialogActionsBar layout="center">
                                    <Button disabled={!formRenderProps.allowSubmit || saveDisabled || saveInProgress} type="submit" themeColor="primary">
                                        {isEditing ? 'Save changes' : createButtonText}
                                    </Button>
                                    <Button type="reset" onClick={onCancel}>
                                        {isEditing ? 'Discard' : 'Cancel'}
                                    </Button>
                                </DialogActionsBar>
                            )}
                        </FormElement>
                    )}
                />
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center k-m-6" />
            )}
        </Dialog>
    );
}

function EventDialogFieldSection({
    kendoIconName,
    icon,
    children
}: {
    kendoIconName?: string;
    icon?: ComponentType<React.SVGProps<SVGSVGElement>>;
    children?: ReactNode;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="-w100 k-gap-7">
            <Icon kendoIconName={kendoIconName} icon={icon} className="k-mt-2 k-flex-shrink-0" />
            {children}
        </StackLayout>
    );
}

function EventDialogUserSection({ user, label }: { user: ReducedUser; label?: string }) {
    return (
        <EventDialogFieldSection icon={UserIcon}>
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2 -mt-1.5">
                {label && <span className="k-icp-subtle-text">{label}</span>}
                <span>
                    {user.firstName} {user.lastName}
                </span>
            </StackLayout>
        </EventDialogFieldSection>
    );
}

function InputLikePad({ children }: { children?: ReactNode }) {
    return (
        <div className="k-input k-input-md k-border-transparent k-rounded-md k-icp-panel-base">
            <div className="k-input-inner">{children}</div>
        </div>
    );
}

export type EventDialogProps<TEvent, TData, TKey = number, TInitialData extends Partial<TData> = Partial<TData>> = {
    onCancel?: () => void;
    onSaved?: (item: TEvent) => void | Promise<void>;
    ideaId: string;
    person?: ReducedPerson;
    itemKey?: TKey;
    initialData?: TInitialData;
};

const eventDateValidators = [requiredValidator('Date')];
const noteTextValidators = [requiredValidator('Text'), maxLengthValidator('Text', 10000)];
export function NoteEventDialog({ onCancel, onSaved, ideaId, person, itemKey, initialData }: EventDialogProps<Note, NoteData>) {
    const [noteToEdit, setNoteToEdit] = useState<Note>();

    const personId = person?.id;
    const isEditing = typeof itemKey !== 'undefined' && typeof personId !== 'undefined';
    useEffect(() => {
        if (!isEditing) return;

        notesService.getActivity(ideaId, personId, itemKey).then(setNoteToEdit);
    }, [ideaId, isEditing, itemKey, personId]);

    return (
        <EventDialog<NoteData, Note>
            title={isEditing ? 'Edit note' : 'Add note'}
            createButtonText="Save note"
            onCancel={onCancel}
            onSaved={onSaved}
            isEditing={isEditing}
            ideaId={ideaId}
            editData={noteToEdit}
            person={person}
            save={(personId, data) => {
                return isEditing ? notesService.updateActivity(ideaId, personId, itemKey, data) : notesService.createActivity(ideaId, personId, data);
            }}
            initialData={{ date: new Date(), ...initialData }}
        >
            <EventDialogFieldSection icon={CalendarIcon}>
                <Field name="date" component={ValidatedInput} inputType={DateAndTimePicker} validator={eventDateValidators} />
            </EventDialogFieldSection>
            <EventDialogFieldSection kendoIconName="file-txt">
                <Field
                    name="text"
                    component={ValidatedInput}
                    maxLengthCount={10000}
                    validator={noteTextValidators}
                    inputType={TextArea}
                    rows={9}
                    placeholder="Add text..."
                    wrapperClass="k-flex-1"
                />
            </EventDialogFieldSection>
            {isEditing && noteToEdit && <EventDialogUserSection user={noteToEdit.user} label="Assigned to:" />}
        </EventDialog>
    );
}

const reachOutTopicValidators = [requiredValidator('Topic'), maxLengthValidator('Topic', 50)];
const reachOutDetailsValidators = [maxLengthValidator('Details', 1000)];
export const reachOutTypeValidators = [requiredValidator('Type')];
const inviteReceivedValidators = [requiredValidator('Invite received')];
export function ReachOutEventDialog({ onCancel, onSaved, ideaId, person, itemKey, initialData }: EventDialogProps<ReachOut, ReachOutData>) {
    const [reachOutToEdit, setReachOutToEdit] = useState<ReachOut>();

    const personId = person?.id;
    const isEditing = typeof itemKey !== 'undefined' && typeof personId !== 'undefined';
    useEffect(() => {
        if (!isEditing) return;

        reachOutsService.getActivity(ideaId, personId, itemKey).then(setReachOutToEdit);
    }, [ideaId, isEditing, itemKey, personId]);

    return (
        <EventDialog<ReachOutData, ReachOut>
            title={isEditing ? 'Edit reach out' : 'Add reach out'}
            createButtonText="Save activity"
            onCancel={onCancel}
            onSaved={onSaved}
            isEditing={isEditing}
            ideaId={ideaId}
            editData={reachOutToEdit}
            person={person}
            save={(personId, data) => {
                return isEditing ? reachOutsService.updateActivity(ideaId, personId, itemKey, data) : reachOutsService.createActivity(ideaId, personId, data);
            }}
            initialData={{ date: new Date(), ...initialData }}
        >
            <EventDialogFieldSection icon={CalendarIcon}>
                <Field name="date" component={ValidatedInput} inputType={DateAndTimePicker} validator={eventDateValidators} />
            </EventDialogFieldSection>
            <EventDialogFieldSection icon={MessageIcon}>
                <Field
                    name="type"
                    component={ValidatedInput}
                    inputType={ButtonGroupSelector}
                    validator={reachOutTypeValidators}
                    options={reachOutButtonGroupSelectorOptions}
                />
            </EventDialogFieldSection>
            <EventDialogFieldSection icon={AgendaIcon}>
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-flex-1">
                    <Field name="topic" component={ValidatedInput} maxLength={50} validator={reachOutTopicValidators} placeholder="Add topic..." />
                    <Field
                        name="details"
                        component={ValidatedInput}
                        maxLengthCount={1000}
                        validator={reachOutDetailsValidators}
                        inputType={TextArea}
                        rows={6}
                        placeholder="Add details..."
                    />
                </StackLayout>
            </EventDialogFieldSection>
            {isEditing && reachOutToEdit && <EventDialogUserSection user={reachOutToEdit.user} label="Assigned to:" />}
        </EventDialog>
    );
}

const meetingMeetingNotesValidators = [maxLengthValidator('Meeting notes', 1000)];
const meetingLocationValidators = [maxLengthValidator('Location', 2048)];
const meetingStartTimeValidators = [requiredValidator('Start time')];
const meetingEndTimeValidators = [
    requiredValidator('End time'),
    (value: Date | null | undefined, valueGetter: (name: string) => any) => {
        if (!value) return;

        const startTime = valueGetter('startTime');
        if (!startTime) return;

        const isAllDay: boolean | undefined = valueGetter('allDay');

        if (!isAllDay) return value < startTime ? 'End time should not be before start time' : undefined;

        const startDay = new Date(startTime);
        startDay.setHours(0, 0, 0, 0);
        const endDay = new Date(value);
        endDay.setHours(0, 0, 0, 0);

        return endDay < startDay ? 'End day should not be before start day' : undefined;
    }
];
const meetingTitleValidators = [requiredValidator('Title'), maxLengthValidator('Title', 300)];
const meetingDescriptionValidators = [maxLengthValidator('Description', 1000)];
const researchValidators = [requiredValidator('Research')];
type MeetingDialogEditData = MeetingData & { research?: ReducedResearch | null };
export function MeetingEventDialog({
    onCancel,
    onSaved,
    ideaId,
    person,
    itemKey,
    initialData,
    initialResearchId
}: EventDialogProps<Meeting, MeetingData> & { initialResearchId?: number | null }) {
    const [meetingToEdit, setMeetingToEdit] = useState<Meeting>();

    const personId = person?.id;
    const isEditing = typeof itemKey !== 'undefined' && typeof personId !== 'undefined';

    useEffect(() => {
        if (!isEditing) return;

        meetingsService.getMeeting(ideaId, itemKey).then(setMeetingToEdit);
    }, [ideaId, isEditing, itemKey, personId]);

    const showResearch = isEditing ? !!meetingToEdit?.research : initialResearchId !== undefined;
    const shouldLoadInitialData = !isEditing && typeof initialResearchId === 'number';

    const initialResearch = useProblemValidationResearch(ideaId, shouldLoadInitialData ? initialResearchId : undefined);
    const relatedResearch = isEditing ? meetingToEdit?.research : initialResearch;

    const [initialDataForResearch, setInitialDataForResearch] = useState<MeetingDataForResearch>();
    useEffect(() => {
        if (!shouldLoadInitialData) return;
        meetingsService.getMeetingDataForResearch(ideaId, initialResearchId).then(setInitialDataForResearch);
    }, [ideaId, initialResearchId, shouldLoadInitialData]);

    const initialDataLoaded = !!initialResearch && !!initialDataForResearch;

    function getInitialData(): Partial<MeetingDialogEditData> | undefined {
        if (isEditing) return undefined;

        if (shouldLoadInitialData && !initialDataLoaded) return undefined;

        const defaultStartTime = new Date();
        dateTimeService.addHours(defaultStartTime, 1);
        defaultStartTime.setMinutes(0, 0, 0);
        const defaultEndTime = new Date(defaultStartTime);
        if (initialDataForResearch) dateTimeService.addMinutes(defaultEndTime, initialDataForResearch.durationMinutes);
        else dateTimeService.addHours(defaultEndTime, 1);

        if (initialDataForResearch && !prefilledResearchDataRef.current)
            prefilledResearchDataRef.current = {
                title: initialDataForResearch.title,
                description: initialDataForResearch.description,
                endTime: defaultEndTime
            };

        const combinedInitialData: Partial<MeetingDialogEditData> = {
            startTime: defaultStartTime,
            endTime: defaultEndTime,
            toEmailAddress: person?.emailAddress,
            title: initialDataForResearch?.title,
            description: initialDataForResearch?.description,
            ...initialData
        };

        return combinedInitialData;
    }

    const [isFetchingDataForUpdatedResearch, toTrackableFetchingDataForUpdatedResearch] = useInProgressOperationsTracker();
    const fetchDataForUpdatedResearch = toTrackableFetchingDataForUpdatedResearch((researchId: number) =>
        meetingsService.getMeetingDataForResearch(ideaId, researchId)
    );
    const prefilledResearchDataRef = useRef<{ title?: string; description?: string; endTime?: Date }>();

    return (
        <EventDialog<MeetingDialogEditData, Meeting>
            title={isEditing ? 'Edit meeting' : 'Add meeting'}
            width={640}
            createButtonText="Save meeting"
            onCancel={onCancel}
            onSaved={onSaved}
            isEditing={isEditing}
            ideaId={ideaId}
            editData={meetingToEdit}
            person={person}
            save={(personId, data) => {
                const meetingData: MeetingData = { ...data, contactId: personId };

                return executeWithServerErrorInToast(
                    () =>
                        isEditing
                            ? meetingsService.updateMeeting(ideaId, itemKey, meetingData)
                            : meetingsService.createMeeting(ideaId, meetingData, data.research?.id ?? initialResearchId ?? undefined),
                    409
                );
            }}
            loadingInitialData={shouldLoadInitialData && !initialDataLoaded}
            initialData={getInitialData()}
            onPersonChange={(newPerson, formRenderProps: FormRenderProps) => {
                if (!newPerson) return;
                formRenderProps.onChange('toEmailAddress', { value: newPerson.emailAddress ?? '' });
            }}
            topContent={
                meetingToEdit?.interview ? (
                    <EventDialogFieldSection icon={BookIcon}>
                        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                            <TextWithShowMoreByLines lines={3}>{interviewStageGuidanceRender(meetingToEdit.interview.stage)}</TextWithShowMoreByLines>
                            <InterviewMainActionButton
                                ideaId={ideaId!}
                                interviewId={meetingToEdit.interview.id}
                                interviewStage={meetingToEdit.interview.stage}
                            />
                        </StackLayout>
                    </EventDialogFieldSection>
                ) : (
                    undefined
                )
            }
            saveDisabled={isFetchingDataForUpdatedResearch}
        >
            {(formRenderProps: FormRenderProps) => {
                const lastStartTimeValue: Date | null | undefined = formRenderProps.valueGetter('startTime');
                const isAllDay: boolean | undefined = formRenderProps.valueGetter('allDay');
                const isEmailValid = !formRenderProps.errors.toEmailAddress;

                function onStartTimeChange(e: { value: Date | null | undefined }) {
                    const startTime = e.value;
                    if (!startTime || !lastStartTimeValue) return;

                    const endTime: Date | null | undefined = formRenderProps.valueGetter('endTime');
                    if (!endTime) return;
                    const previousDuration = endTime.getTime() - lastStartTimeValue.getTime();
                    if (previousDuration < 0) return;

                    const newEndTime = new Date(startTime.getTime() + previousDuration);
                    formRenderProps.onChange('endTime', { value: newEndTime });
                }

                async function onResearchChange({ value: updatedResearch }: { value: ReducedResearch | null | undefined }) {
                    if (!updatedResearch) return;

                    const currentTitle: string = formRenderProps.valueGetter('title');
                    const currentDescription: string = formRenderProps.valueGetter('description');
                    const currentEndTime: Date | null = formRenderProps.valueGetter('endTime');
                    const isAllDay: boolean | undefined = formRenderProps.valueGetter('allDay');

                    const canPrefillTitle = !currentTitle || currentTitle === prefilledResearchDataRef.current?.title;
                    const canPrefillDescription = !prefilledResearchDataRef.current || prefilledResearchDataRef.current.description === currentDescription;
                    const canPrefillEndDate = !isAllDay && (!currentEndTime || prefilledResearchDataRef.current?.endTime === currentEndTime);

                    if (!canPrefillTitle && !canPrefillDescription && !canPrefillEndDate) return;

                    const meetingDataForResearch = await fetchDataForUpdatedResearch(updatedResearch.id);

                    if (!prefilledResearchDataRef.current) prefilledResearchDataRef.current = {};
                    if (canPrefillTitle) {
                        formRenderProps.onChange('title', { value: meetingDataForResearch.title });
                        prefilledResearchDataRef.current.title = meetingDataForResearch.title;
                    }
                    if (canPrefillDescription) {
                        formRenderProps.onChange('description', { value: meetingDataForResearch.description });
                        prefilledResearchDataRef.current.description = meetingDataForResearch.description;
                    }
                    if (canPrefillEndDate) {
                        const currentStartTime: Date | null | undefined = formRenderProps.valueGetter('startTime');
                        if (currentStartTime) {
                            const endTime = new Date(currentStartTime);
                            dateTimeService.addMinutes(endTime, meetingDataForResearch.durationMinutes);
                            formRenderProps.onChange('endTime', { value: endTime });
                            prefilledResearchDataRef.current.endTime = endTime;
                        }
                    }
                }

                return (
                    <>
                        <EventDialogFieldSection icon={EmailIcon}>
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-flex-1">
                                <Field
                                    name="toEmailAddress"
                                    component={ValidatedInput}
                                    type="email"
                                    validator={defaultValidators.emailValidators}
                                    maxLength={320}
                                    placeholder="Add email..."
                                />
                                <Field
                                    name="notifyAttendees"
                                    component={ValidatedInput}
                                    inputType={Checkbox}
                                    label="Send invitation to attendee"
                                    layout={ValidatedInputCheckboxLayout}
                                    labelClassName="!k-mb-0"
                                    disabled={!isEmailValid}
                                />
                            </StackLayout>
                        </EventDialogFieldSection>
                        {showResearch && (
                            <EventDialogFieldSection icon={InterviewIcon}>
                                {relatedResearch ? (
                                    <div className="-mt-1.5">
                                        <span className="k-icp-subtle-text">Research:</span> {relatedResearch.title}
                                    </div>
                                ) : (
                                    <Field
                                        name="research"
                                        component={ValidatedInput}
                                        inputType={ResearchPicker}
                                        validator={researchValidators}
                                        ideaId={ideaId}
                                        wrapperClass="k-flex-1"
                                        onChange={onResearchChange}
                                    />
                                )}
                            </EventDialogFieldSection>
                        )}
                        <EventDialogFieldSection icon={AgendaIcon}>
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-flex-1">
                                <Field
                                    name="title"
                                    component={ValidatedInput}
                                    maxLength={300}
                                    validator={meetingTitleValidators}
                                    placeholder="Add title..."
                                    disabled={isFetchingDataForUpdatedResearch}
                                />
                                <Field
                                    name="description"
                                    component={ValidatedInput}
                                    maxLength={1000}
                                    validator={meetingDescriptionValidators}
                                    inputType={TextArea}
                                    rows={3}
                                    placeholder="Add description..."
                                    disabled={isFetchingDataForUpdatedResearch}
                                />
                            </StackLayout>
                        </EventDialogFieldSection>
                        <EventDialogFieldSection icon={CalendarIcon}>
                            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                                <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                                    {isAllDay ? (
                                        <Field
                                            name="startTime"
                                            component={ValidatedInput}
                                            layout={ValidatedInputHorizontalLayout}
                                            label="Start:"
                                            inputType={DatePicker}
                                            dateInput={KeyboardNavigatableDateInput}
                                            validator={meetingStartTimeValidators}
                                            wrapperClass="k-gap-3 k-icp-horizontal-input-compact"
                                            labelClassName="meeting-slot-time-label"
                                            width={252}
                                            onChange={onStartTimeChange}
                                        />
                                    ) : (
                                        <Field
                                            name="startTime"
                                            component={ValidatedInput}
                                            layout={ValidatedInputHorizontalLayout}
                                            label="Start:"
                                            inputType={DateAndTimePicker}
                                            validator={meetingStartTimeValidators}
                                            wrapperClass="k-gap-3 k-icp-horizontal-input-compact"
                                            labelClassName="meeting-slot-time-label"
                                            onChange={onStartTimeChange}
                                        />
                                    )}

                                    <Field
                                        name="allDay"
                                        component={ValidatedInput}
                                        inputType={Checkbox}
                                        label="All day"
                                        layout={ValidatedInputCheckboxLayout}
                                        labelClassName="!k-mb-0"
                                        wrapperClass="k-mt-1"
                                    />
                                </StackLayout>

                                {isAllDay ? (
                                    <Field
                                        name="endTime"
                                        component={ValidatedInput}
                                        layout={ValidatedInputHorizontalLayout}
                                        label="End:"
                                        inputType={DatePicker}
                                        dateInput={KeyboardNavigatableDateInput}
                                        validator={meetingEndTimeValidators}
                                        wrapperClass="k-gap-3 k-icp-horizontal-input-compact"
                                        labelClassName="meeting-slot-time-label"
                                        width={252}
                                    />
                                ) : (
                                    <Field
                                        name="endTime"
                                        component={ValidatedInput}
                                        layout={ValidatedInputHorizontalLayout}
                                        label="End:"
                                        inputType={DateAndTimePicker}
                                        validator={meetingEndTimeValidators}
                                        wrapperClass="k-gap-3 k-icp-horizontal-input-compact"
                                        labelClassName="meeting-slot-time-label"
                                        disabled={isFetchingDataForUpdatedResearch}
                                    />
                                )}
                            </StackLayout>
                        </EventDialogFieldSection>
                        <EventDialogFieldSection icon={LocationIcon}>
                            <Field
                                name="location"
                                component={ValidatedInput}
                                maxLength={2048}
                                validator={meetingLocationValidators}
                                placeholder="Add location or details for online meeting"
                                wrapperClass="k-flex-1"
                            />
                        </EventDialogFieldSection>
                        <EventDialogFieldSection kendoIconName="file-txt">
                            <Field
                                name="meetingNotes"
                                component={ValidatedInput}
                                maxLength={1000}
                                validator={meetingMeetingNotesValidators}
                                inputType={TextArea}
                                rows={3}
                                placeholder="Add meeting notes..."
                                wrapperClass="k-flex-1"
                            />
                        </EventDialogFieldSection>
                        {isEditing && meetingToEdit && <EventDialogUserSection user={meetingToEdit.user} label="Host:" />}
                    </>
                );
            }}
        </EventDialog>
    );
}

export const researchReachOutConnectionTypeValidators = [requiredValidator('Connection type')];
const researchReachOutEditDateValidators = eventDateValidators;
export const researchReachOutCreateDateValidators = [...researchReachOutEditDateValidators, dateInTheFutureValidator('Date')];
export const ResearchReachOutEventDialog = canvasItemsZoneDependent(function({
    onCancel,
    onSaved,
    ideaId,
    person,
    itemKey,
    initialData,
    executingNowInitialValue
}: EventDialogProps<
    ResearchReachOut,
    ResearchReachOutData,
    { researchId: number; reachOutId: number },
    Partial<ResearchReachOutData> & { researchId: number }
> & { executingNowInitialValue?: boolean }) {
    const personId = person?.id;
    const isEditing = typeof itemKey !== 'undefined' && typeof personId !== 'undefined';

    const [researchReachOut, setResearchReachOut] = useState<ResearchReachOut>();
    const [executingNow, setExecutingNow] = useState(isEditing ? executingNowInitialValue ?? false : true);
    const [schedule, setSchedule] = useState<Schedule>();
    const [research, setResearch] = useState<ProblemValidationResearch>();
    const [researchUser, setResearchUser] = useState<User>();
    const [reachOutTemplates, setReachOutTemplates] = useState<ResearchReachOutTemplate[] | undefined>();
    const isReadOnly = researchReachOut && researchReachOut.sent;
    const researchId = isEditing ? itemKey.researchId : initialData?.researchId;
    const htmlParseFunction = useLazyHtmlParser();

    const fetchReachOutInviteContent = useCallback(async (ideaId: string, researchId: number) => {
        const content = await researchService.getResearchReachOutTemplates(ideaId, researchId);
        setReachOutTemplates(content);
    }, []);

    useEffect(() => {
        if (!ideaId || !researchId) return;

        fetchReachOutInviteContent(ideaId, researchId);
    }, [fetchReachOutInviteContent, ideaId, researchId]);

    useEffect(() => {
        if (!isEditing) return;

        researchService.getProblemValidationResearchReachOut(ideaId, itemKey.researchId, itemKey.reachOutId).then(researchReachOut => {
            if (researchReachOut.sent) setExecutingNow(true);

            setResearchReachOut(researchReachOut);
        });
    }, [ideaId, isEditing, itemKey?.researchId, itemKey?.reachOutId, personId]);

    const currentUser = useAppSelector(u => u.user);
    let loadResearchUser = false;
    let scheduleUserId: string | undefined;
    let scheduleUser: User | undefined | null;
    if (isEditing) {
        if (researchReachOut) {
            if (researchReachOut.user) {
                scheduleUserId = researchReachOut.user.userId;
                scheduleUser = researchUser;
                loadResearchUser = true;
            } else {
                scheduleUserId = currentUser?.userId;
                scheduleUser = currentUser;
            }
        }
    } else {
        scheduleUserId = currentUser?.userId;
        scheduleUser = currentUser;
    }

    const [loadingSchedule, setLoadingSchedule] = useState(false);
    useEffect(() => {
        if (researchId === undefined || scheduleUserId === undefined) return;

        setLoadingSchedule(true);
        schedulesService
            .getUserScheduleInResearch(ideaId, scheduleUserId, researchId)
            .then(setSchedule)
            .finally(() => setLoadingSchedule(false));
    }, [ideaId, researchId, scheduleUserId]);

    useEffect(() => {
        if (researchId === undefined) return;

        researchService.getProblemValidationResearch(ideaId, researchId).then(setResearch);
    }, [ideaId, researchId, scheduleUserId]);

    useEffect(() => {
        if (!loadResearchUser || scheduleUserId === undefined) return;
        usersService.getUser(scheduleUserId).then(setResearchUser);
    }, [loadResearchUser, scheduleUserId]);

    const cannotExecuteNow = (!isEditing || (researchReachOut && !researchReachOut.sent)) && !schedule;
    const bookingAbsoluteUrl = useBookingPageUrl(schedule?.code);

    return (
        <EventDialog<ResearchReachOutData, ResearchReachOut>
            width={640}
            title={isReadOnly ? 'View invite' : isEditing ? 'Edit invite' : 'Add invite'}
            createButtonText="Save invite"
            onCancel={onCancel}
            onSaved={onSaved}
            isEditing={isEditing}
            ideaId={ideaId}
            editData={researchReachOut && (executingNowInitialValue ? { ...researchReachOut, date: new Date() } : researchReachOut)}
            person={person}
            save={(personId, data) => {
                if (executingNow) data.sent = true;

                if (isEditing) return researchService.partiallyUpdateProblemValidationResearchReachOut(ideaId, itemKey.researchId, itemKey.reachOutId, data);

                if (!initialData) throw new Error('Initial data is required when creating research reach out');

                return researchService.createProblemValidationResearchReachOut(ideaId, initialData.researchId, personId, data);
            }}
            initialData={{ date: new Date(), ...initialData }}
            readOnly={isReadOnly}
            saveDisabled={executingNow && cannotExecuteNow}
        >
            {formRenderProps => {
                const inviteDate: Date | null = formRenderProps.valueGetter('date');
                const isOverdue = !isReadOnly && inviteDate && inviteDate < new Date();
                const selectedReachOutType: ReachOutType | undefined = formRenderProps.valueGetter('reachOutType');
                const selectedConnectionType: ReachOutConnectionType | undefined = formRenderProps.valueGetter('connectionType');
                const currentPerson: ReducedPerson | null | undefined = person ?? formRenderProps.valueGetter('person');

                const selectedReachOutInviteTemplate =
                    selectedReachOutType &&
                    selectedConnectionType &&
                    reachOutTemplates &&
                    reachOutTemplates.find(content => content.reachOutType === selectedReachOutType && content.connectionType === selectedConnectionType);

                const inviteContent =
                    selectedReachOutInviteTemplate && currentPerson && scheduleUser && schedule && bookingAbsoluteUrl
                        ? problemValidationInviteContentProvider.getInviteData(selectedReachOutInviteTemplate, {
                              inviteeFirstName: currentPerson.firstName,
                              inviteeEmail: currentPerson.emailAddress,
                              hostFirstName: scheduleUser.firstName,
                              hostLastName: scheduleUser.lastName,
                              hostPhoneNumber: scheduleUser.phoneNumber,
                              meetingDuration: schedule.durationMinutes,
                              bookingPageUrl: bookingAbsoluteUrl
                          })
                        : undefined;

                return (
                    <>
                        <EventDialogFieldSection icon={ResearchIcon}>
                            {research ? (
                                <div className="k-align-self-end">
                                    <span className="k-icp-subtle-text">Interview batch:</span> {research.title}
                                </div>
                            ) : (
                                <Skeleton className="k-align-self-end" style={{ width: 200 }} />
                            )}
                        </EventDialogFieldSection>
                        <EventDialogFieldSection icon={CalendarIcon}>
                            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-flex-1 k-gap-3 k-justify-content-between">
                                {executingNow ? (
                                    <div className="-mt-1.5">
                                        <span className="k-icp-subtle-text">Invited on:</span>{' '}
                                        {dateTimeService.stringifyToDateAndTime(inviteDate ?? new Date(), true)}
                                    </div>
                                ) : (
                                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                                        <Field
                                            name="date"
                                            component={ValidatedInput}
                                            inputType={DateAndTimePicker}
                                            validator={isEditing ? researchReachOutEditDateValidators : researchReachOutCreateDateValidators}
                                        />
                                        {isOverdue && <span className="k-text-uppercase k-text-error k-fs-sm">Overdue</span>}
                                    </StackLayout>
                                )}

                                {!isReadOnly &&
                                    (executingNow ? (
                                        <Button
                                            type="button"
                                            size="small"
                                            themeColor="secondary"
                                            fillMode="link"
                                            onClick={() => {
                                                if (researchReachOut) formRenderProps.onChange('date', { value: researchReachOut.date });
                                                setExecutingNow(false);
                                            }}
                                            className="k-my-1"
                                        >
                                            Add reminder for later
                                        </Button>
                                    ) : (
                                        <Button
                                            type="button"
                                            size="small"
                                            themeColor="secondary"
                                            fillMode="link"
                                            onClick={() => {
                                                formRenderProps.onChange('date', { value: new Date() });
                                                setExecutingNow(true);
                                            }}
                                            className="k-my-1"
                                        >
                                            Execute now
                                        </Button>
                                    ))}
                            </StackLayout>
                        </EventDialogFieldSection>
                        {executingNow &&
                            (cannotExecuteNow ? (
                                loadingSchedule ? (
                                    <LoadingIndicator size="big" className="k-align-self-center" />
                                ) : (
                                    <div className="k-align-self-stretch k-mx--4 k-mb--4 k-p-8 k-text-center k-icp-bordered-top k-icp-component-border k-icp-bg-warning-8-solid">
                                        <div className="k-mb-4">You can invite the contact after creating a personal schedule.</div>
                                        {researchId !== undefined && (
                                            <Link
                                                className="k-button k-button-md k-button-outline k-button-outline-base k-rounded-md"
                                                to={`/startups/${ideaId}/calendar/schedules/create?researchId=${researchId}`}
                                            >
                                                <SvgIconButtonContent icon={AddIcon}>Add schedule</SvgIconButtonContent>
                                            </Link>
                                        )}
                                    </div>
                                )
                            ) : (
                                <>
                                    <EventDialogFieldSection icon={ConnectionTypeIcon}>
                                        <Field
                                            name="connectionType"
                                            component={ValidatedInput}
                                            inputType={ButtonGroupSelector}
                                            validator={researchReachOutConnectionTypeValidators}
                                            readOnly={isReadOnly}
                                            options={connectionTypeButtonGroupSelectorOptions}
                                        />
                                    </EventDialogFieldSection>
                                    <EventDialogFieldSection icon={MessageIcon}>
                                        <Field
                                            name="reachOutType"
                                            component={ValidatedInput}
                                            inputType={ButtonGroupSelector}
                                            validator={reachOutTypeValidators}
                                            readOnly={isReadOnly}
                                            options={reachOutButtonGroupSelectorOptions}
                                        />
                                    </EventDialogFieldSection>
                                    <EventDialogFieldSection icon={BookIcon}>
                                        {selectedReachOutType && selectedConnectionType ? (
                                            inviteContent && htmlParseFunction ? (
                                                <InviteContentView inviteContent={inviteContent} htmlParseFunction={htmlParseFunction} />
                                            ) : (
                                                <Skeleton shape="rectangle" style={{ width: '100%', height: 90 }} />
                                            )
                                        ) : (
                                            <ContentSectionWithCopy>
                                                <span className="k-icp-placeholder-text k-align-self-center">
                                                    Guidance will be presented after your connection with the contact and means of reaching out are picked.
                                                </span>
                                            </ContentSectionWithCopy>
                                        )}
                                    </EventDialogFieldSection>
                                    {bookingAbsoluteUrl && (
                                        <EventDialogFieldSection icon={LinkIcon}>
                                            <ContentSectionWithCopy textToCopy={bookingAbsoluteUrl} textToCopyDescription="Booking page link">
                                                <a
                                                    href={bookingAbsoluteUrl}
                                                    className="k-button-link-secondary k-align-self-center"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Open booking page
                                                </a>
                                            </ContentSectionWithCopy>
                                        </EventDialogFieldSection>
                                    )}
                                    {!isReadOnly && (
                                        <>
                                            <div className="k-separator k-icp-component-border" />
                                            <EventDialogFieldSection icon={ConfirmInviteReceived}>
                                                <Field
                                                    name="inviteReceived"
                                                    component={ValidatedInput}
                                                    inputType={Checkbox}
                                                    validator={inviteReceivedValidators}
                                                    label="Confirm Contact has received an invite link"
                                                    hideErrorMessage
                                                    hint="This action does not send out any email. It will log an activity in your Calendar."
                                                    layout={ValidatedInputCheckboxLayout}
                                                    labelClassName="!k-mb-0"
                                                    wrapperClass="k-mt-1"
                                                />
                                            </EventDialogFieldSection>
                                        </>
                                    )}
                                </>
                            ))}
                        {isEditing && researchReachOut?.user && <EventDialogUserSection user={researchReachOut.user} label="Assigned to:" />}
                    </>
                );
            }}
        </EventDialog>
    );
});

export function InviteContentView({ inviteContent, htmlParseFunction }: { inviteContent: InviteContentData; htmlParseFunction: (html: string) => any }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4 k-flex-1">
            {inviteContent.sections.map(inviteContentSection => (
                <Fragment key={inviteContentSection.key}>
                    {inviteContentSection.title && (
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                            <div className="k-flex-1 full-horizontal-separator" />
                            <span className="k-fs-sm k-text-uppercase">{inviteContentSection.title}</span>
                            <div className="k-flex-1 full-horizontal-separator" />
                        </StackLayout>
                    )}
                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                        {inviteContentSection.descriptionHtml && <div>{htmlParseFunction(inviteContentSection.descriptionHtml)}</div>}
                        {inviteContentSection.details.map(inviteContentSectionDetail => (
                            <div key={inviteContentSectionDetail.key}>
                                <StackLayout align={{ horizontal: 'start' }} className="k-justify-content-between">
                                    <div className="k-fs-sm k-mb-1 k-icp-subtle-text">{inviteContentSectionDetail.label}</div>
                                    {inviteContentSectionDetail.isIVAGenerated && (
                                        <Tooltip
                                            content={props => <div className="k-fs-xs k-text-center" dangerouslySetInnerHTML={{ __html: props.title }} />}
                                            anchorElement="target"
                                            position="bottom"
                                            parentTitle={true}
                                            openDelay={0}
                                        >
                                            <div title="Content generated by IVA:<br>Icanpreneur Virtual Assistant" className="k-mr-10 k-icp-tooltip-trigger">
                                                <StackLayout align={{ horizontal: 'start', vertical: 'top' }} gap={5}>
                                                    <IVAGeneratedIcon />
                                                    <span className="k-pt-0.5 k-fs-xs k-text-secondary">IVA generated</span>
                                                </StackLayout>
                                            </div>
                                        </Tooltip>
                                    )}
                                </StackLayout>
                                <ContentSectionWithCopy
                                    textToCopyDescription={inviteContentSectionDetail.label}
                                    textToCopy={inviteContentSectionDetail.content.plainText}
                                    richTextToCopy={inviteContentSectionDetail.content.html}
                                >
                                    <InputLikePad>
                                        <TextWithShowMoreByLines lines={5}>
                                            {inviteContentSectionDetail.content.styled ? (
                                                <>{htmlParseFunction(inviteContentSectionDetail.content.styled)}</>
                                            ) : (
                                                inviteContentSectionDetail.content.plainText
                                            )}
                                        </TextWithShowMoreByLines>
                                    </InputLikePad>
                                </ContentSectionWithCopy>
                            </div>
                        ))}
                    </StackLayout>
                </Fragment>
            ))}
        </StackLayout>
    );
}

export function useBookingPageUrl(bookingCode?: string) {
    return useMemo(() => {
        if (!bookingCode) return undefined;
        const absoluteBookingPagePath = resolvePath(`/bookings/${bookingCode}`).pathname;
        const bookingAbsoluteUrl = resolveAbsoluteUrl(absoluteBookingPagePath);
        return bookingAbsoluteUrl;
    }, [bookingCode]);
}

export const reachOutButtonGroupSelectorOptions = Object.values(ReachOutType).map<ButtonGroupSelectorOption<ReachOutType>>(r => ({
    text: r,
    value: r,
    icon: reachOutIcon[r]
}));

export const connectionTypeButtonGroupSelectorOptions = Object.values(ReachOutConnectionType).map<ButtonGroupSelectorOption<ReachOutConnectionType>>(ct => ({
    text: ct,
    value: ct
}));

export function DeleteMeetingDialog({ meeting, onDelete, onCancel }: { meeting: Meeting; onDelete?: () => void; onCancel?: () => void }) {
    const [confirmed, setConfirmed] = useState<boolean>();
    const hasInterview = !!meeting.interview;
    const canDeleteMeeting = !hasInterview || meeting.interview!.stage === InterviewStage.NotStarted;

    return (
        <ConfirmDialog
            config={{
                title: 'Delete meeting',
                content: canDeleteMeeting ? (
                    <>
                        Are you sure you want to delete <strong>“{meeting.title}”</strong> meeting with {meeting.contact.firstName} {meeting.contact.lastName}?
                    </>
                ) : (
                    'This meeting cannot be deleted since it is associated with an ongoing interview.'
                ),
                confirmCheckboxText:
                    canDeleteMeeting && hasInterview ? 'I acknowledge that I want to delete the meeting and the associated interview' : undefined,
                confirmButtonText: 'Delete meeting'
            }}
            confirmed={canDeleteMeeting && (!hasInterview || confirmed)}
            onConfirmation={setConfirmed}
            onClose={onCancel}
            onConfirm={onDelete}
        />
    );
}
