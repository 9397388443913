import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RecentTask = { sequence: string; tag: string; variation: string | undefined; index: number };
export type IdeaRecentTask = { ideaId: string; task: RecentTask };
export type RecentTasks = Record<string, RecentTask | undefined>;
export type RecentTasksState = { suppressed: boolean; tasks: RecentTasks };

const recentTasksStorageKey = 'icpRecentTasks';
const defaultRecentTasksValue: RecentTasks = {};
function readRecentTasksFromStorage() {
    const serializedRecentTasks = sessionStorage.getItem(recentTasksStorageKey);
    if (!serializedRecentTasks) return defaultRecentTasksValue;

    const recentTasks: RecentTasks = JSON.parse(serializedRecentTasks);

    return recentTasks;
}

function storeRecentTasksInStorage(recentTasks: RecentTasks | undefined) {
    if (!recentTasks || Object.keys(recentTasks).length === 0) sessionStorage.removeItem(recentTasksStorageKey);
    else sessionStorage.setItem(recentTasksStorageKey, JSON.stringify(recentTasks));
}

const initialValue: RecentTasksState = {
    suppressed: false,
    tasks: readRecentTasksFromStorage()
};

export const recentTasksSlice = createSlice({
    name: 'recentTasks',
    initialState: initialValue,
    reducers: {
        setRecentTask(state, action: PayloadAction<IdeaRecentTask>) {
            state.tasks[action.payload.ideaId] = action.payload.task;

            storeRecentTasksInStorage(state.tasks);
        },
        cleanRecentTask(state, action: PayloadAction<string>) {
            delete state.tasks[action.payload];
            storeRecentTasksInStorage(state.tasks);
        },
        setRecentTasksSuppression(state, action: PayloadAction<boolean>) {
            state.suppressed = action.payload;
        }
    }
});

export const { setRecentTask, cleanRecentTask, setRecentTasksSuppression } = recentTasksSlice.actions;

export default recentTasksSlice.reducer;
