import { ComponentProps, useState } from 'react';
import { getActivityTitle } from '.';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { Activity, ActivityType, activitiesService } from '../../services/activitiesService';
import { ReducedPerson } from '../../services/contactsService';
import { DeleteMeetingDialog, MeetingEventDialog, NoteEventDialog, ReachOutEventDialog, ResearchReachOutEventDialog } from '../events/eventsDialogs';

export const manageActivityDialogComponentMap = {
    [ActivityType.Note]: NoteEventDialog,
    [ActivityType.ReachOut]: ReachOutEventDialog,
    [ActivityType.Meeting]: MeetingEventDialog,
    [ActivityType.ResearchReachOut]: ResearchReachOutEventDialog
};

export type ManageActivityDialogData<TActivity extends ActivityType> = {
    Component: typeof manageActivityDialogComponentMap[TActivity];
    props: ComponentProps<typeof manageActivityDialogComponentMap[TActivity]>;
};

export type ManageActivityDialogsData = {
    [TActivity in ActivityType]: ManageActivityDialogData<TActivity>;
}[ActivityType];

export type ShowActivityDialogProps<TActivity extends ActivityType> = Omit<Partial<ManageActivityDialogData<TActivity>['props']>, 'ideaId'>;
export function useManageActivityDialog(ideaId?: string, onSave?: () => void, person?: ReducedPerson) {
    const [activityDialogData, setActivityDialogData] = useState<ManageActivityDialogsData | undefined>();

    function showActivityDialog<TActivity extends ActivityType>(activityType: TActivity, props?: ShowActivityDialogProps<TActivity>) {
        if (!ideaId) return;
        const CreateActivityComponentType = manageActivityDialogComponentMap[activityType];
        if (!CreateActivityComponentType) return;

        const activityDialogData: ManageActivityDialogData<TActivity> = {
            Component: manageActivityDialogComponentMap[activityType],
            props: {
                ideaId: ideaId,
                person: person,
                ...props,
                onCancel: () => {
                    props?.onCancel?.();
                    setActivityDialogData(undefined);
                },
                onSaved: async (item: any) => {
                    onSave?.();
                    await props?.onSaved?.(item);
                    setActivityDialogData(undefined);
                }
            } as ManageActivityDialogData<TActivity>['props']
        };

        setActivityDialogData(activityDialogData as ManageActivityDialogsData);
    }

    return [activityDialogData && <activityDialogData.Component {...(activityDialogData.props as any)} />, showActivityDialog] as const;
}

export type ActivityDeletionData = { personId: number; activity: Activity };
export function useDeleteActivityDialog(
    ideaId?: string,
    onActivityDeleted?: (data: ActivityDeletionData) => void,
    controlledActivityToDelete?: ActivityDeletionData,
    onDeleteActivity?: (personId: number, activity: Activity) => void,
    onCancelDeletingActivity?: () => void
) {
    const [internalActivityToDelete, setInternalActivityToDelete] = useState<ActivityDeletionData>();
    // activityToDelete holds the activity data for the activity that we need to show custom delete dialog for. If custom dialog is not supported the dialog is directly shown with showConfirmDialog
    const activityToDelete = controlledActivityToDelete ?? internalActivityToDelete;
    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();

    async function onDeleteActivityConfirmed(data: ActivityDeletionData) {
        if (ideaId === undefined) return;
        const activity = data.activity;
        const underlyingItem = activitiesService.getUnderlyingItem(activity);
        await activitiesService.deleteActivity(
            ideaId,
            activity.type === ActivityType.ResearchReachOut ? activity.researchReachOut.research.id : data.personId,
            activity.type,
            underlyingItem.id
        );

        setInternalActivityToDelete(undefined);
        onActivityDeleted?.(data);
    }

    function deleteActivity(personId: number, activity: Activity) {
        if (activity.type === ActivityType.Meeting) {
            if (onDeleteActivity) onDeleteActivity(personId, activity);
            else setInternalActivityToDelete({ personId, activity });
        } else
            showConfirmDialog({
                title: 'Delete activity',
                content: (
                    <>
                        Are you sure you want to delete <strong>“{getActivityTitle(activity)}”</strong> activity?
                    </>
                ),
                confirmButtonText: 'Delete activity',
                callback: () => onDeleteActivityConfirmed({ personId, activity })
            });
    }

    let deleteActivityDialog = confirmDialog;
    if (activityToDelete) {
        if (activityToDelete.activity.type === ActivityType.Meeting)
            deleteActivityDialog = (
                <DeleteMeetingDialog
                    meeting={activityToDelete.activity.meeting}
                    onDelete={() => onDeleteActivityConfirmed(activityToDelete)}
                    onCancel={() => {
                        setInternalActivityToDelete(undefined);
                        onCancelDeletingActivity?.();
                    }}
                />
            );
        else throw new Error('Unsupported custom delete dialog for activity of type: ' + activityToDelete.activity.type);
    }

    return [deleteActivity, deleteActivityDialog] as const;
}
