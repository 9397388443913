import { Input, RadioButton } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useRef } from 'react';
import { LocationOption, LocationOptionType } from '../../services/schedulesService';

const sortedLocationTypes: LocationOptionType[] = [
    LocationOptionType.InPerson,
    LocationOptionType.Other,
    LocationOptionType.Online,
    LocationOptionType.PhoneCall
];

export function EventLocationSelector({
    availableLocations,
    value,
    onChange,
    valid,
    disabled,
    isMobile
}: {
    availableLocations?: LocationOption[];
    value?: LocationOption;
    onChange?: (e: { value: LocationOption | undefined }) => void;
    valid?: boolean;
    disabled?: boolean;
    isMobile?: boolean;
}) {
    if (!availableLocations || !availableLocations.length) return;

    const isOnlyOtherLocationAvailable = availableLocations.length === 1 && availableLocations[0].type === LocationOptionType.Other;
    if (isOnlyOtherLocationAvailable) {
        const otherLocation = availableLocations[0];
        return (
            <Input
                value={value ? value.details : otherLocation.details}
                onChange={e => onChange?.({ value: { type: LocationOptionType.Other, details: e.value } })}
                disabled={disabled}
                valid={valid}
                placeholder="Type a location..."
            />
        );
    }

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-3">
            {sortedLocationTypes.map(type => (
                <LocationRadioButton
                    key={type}
                    type={type}
                    availableLocations={availableLocations}
                    groupValue={value}
                    onChange={onChange}
                    disabled={disabled}
                    valid={valid}
                    isMobile={!!isMobile}
                />
            ))}
        </StackLayout>
    );
}

function LocationRadioButton({
    type,
    availableLocations,
    groupValue,
    onChange,
    disabled,
    valid,
    isMobile
}: {
    type: LocationOptionType;
    availableLocations?: LocationOption[];
    groupValue?: LocationOption;
    onChange?: (e: { value: LocationOption | undefined }) => void;
    valid?: boolean;
    disabled?: boolean;
    isMobile: boolean;
}) {
    const lastKnownFreeInput = useRef<string>();

    if (!availableLocations || !availableLocations.length) return null;
    const locationData = availableLocations.find(al => al.type === type);
    if (!locationData) return null;

    function getLocationDescription() {
        if (!locationData) return undefined;

        if (type === LocationOptionType.InPerson) return `In person at ${locationData.details}`;
        if (type === LocationOptionType.Other) return 'In person at other location';

        return locationData.details || locationData.type;
    }

    const isLocationSelected = typeof groupValue !== 'undefined' && type === groupValue.type;
    const allowFreeInput = type === LocationOptionType.Other;
    if (allowFreeInput && isLocationSelected) lastKnownFreeInput.current = groupValue.details;
    const showLinkToMap = type === LocationOptionType.InPerson;
    const radioButtonElement = (
        <div>
            <RadioButton
                checked={isLocationSelected}
                label={getLocationDescription()}
                onChange={() =>
                    onChange?.({ value: { type: type, details: allowFreeInput ? lastKnownFreeInput.current || locationData.details : locationData.details } })
                }
                disabled={disabled}
                className="k-icp-radio-margin-lg"
                valid={valid}
            />
            {showLinkToMap && (
                <div className="k-ml-8 k-mt-1 k-fs-sm">
                    <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(locationData.details)}`}
                        target="_blank"
                        rel="noreferrer"
                        className="k-button-link-secondary"
                    >
                        View on map
                    </a>
                </div>
            )}
        </div>
    );

    if (allowFreeInput) {
        return (
            <StackLayout
                align={{ horizontal: 'start', vertical: 'middle' }}
                orientation={isMobile ? 'vertical' : 'horizontal'}
                className={`${isMobile ? 'k-gap-2' : 'k-gap-4'}`}
            >
                {radioButtonElement}
                <StackLayout className={`${isMobile ? 'k-pl-8 ' : ''}k-flex-1 k-w-full`}>
                    <Input
                        disabled={!isLocationSelected || disabled}
                        valid={valid}
                        value={isLocationSelected ? groupValue.details : lastKnownFreeInput.current || locationData.details}
                        onChange={e =>
                            onChange?.({
                                value: { type: type, details: e.value }
                            })
                        }
                        placeholder="Type a location..."
                    />
                </StackLayout>
            </StackLayout>
        );
    }

    return radioButtonElement;
}
