import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar, DialogCloseEvent } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { RadioButton } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSingleClickButton } from '../../../hooks/commonHooks';
import { ReactComponent as InsightQuestionIcon } from '../../../images/insight-question-illustration.svg';
import noInsightsImagesUrl from '../../../images/no-insights-illustration.svg';
import { immutableAddOrUpdate } from '../../../services/common/common.immutable';
import {
    Insight,
    InsightCatalogTemplate,
    InsightParameterValue,
    InsightPropertyType,
    InsightTemplateParameter,
    insightsService
} from '../../../services/insightsService';
import { useAppDispatch } from '../../../state/hooks';
import { ValidationScope, ValidationScopeHandle } from '../../common/validation';
import { InsightEditor, InsightEditorProps, saveCanvasInsightParameterInstantValues } from '../../insights/insightEditor';
import { SubmitFormOnEnter } from '../../ui/form';
import { FormFieldProps, ValidatedInput, requiredValidator } from '../../ui/inputs';
import LoadingIndicator from '../../ui/loadingIndicator';
import { ForkedInsightFilter } from './insightsCommon';

const templateTagValidator = requiredValidator('Select insight type', false);
export function AddInsightModal({
    question,
    templates,
    onClose,
    onSave,
    context,
    forkValue
}: {
    question: string;
    templates: InsightCatalogTemplate[];
    onClose?: () => void;
    onSave?: (templateTag: string, parametersValues: InsightParameterValue[]) => Promise<void>;
    context?: Record<string, unknown>;
    forkValue?: unknown;
}) {
    const dispatch = useAppDispatch();
    const formRef = useRef<Form>(null);
    const [saveDisabled, saveCallbackCreator] = useSingleClickButton();

    function resolveInitialValues(): Record<string, unknown> | undefined {
        const initialValues: Record<string, unknown> = {};
        for (const template of templates) {
            const templateValues: InsightParameterValue[] = [];
            for (const templateParameter of template.parameters) {
                if (!templateParameter.binding) continue;
                if ('readFromContext' in templateParameter.binding) {
                    if (templateParameter.binding.readFromContext && context) {
                        const parameterValueFromContext = context[templateParameter.binding.propertyName];
                        if (parameterValueFromContext) templateValues.push({ id: templateParameter.id, value: parameterValueFromContext });
                    }
                } else if ('readFromFork' in templateParameter.binding) {
                    if (templateParameter.binding.readFromFork && forkValue) {
                        templateValues.push({ id: templateParameter.id, value: forkValue });
                    }
                }
            }

            if (templateValues.length) initialValues[`insight_template_${template.tag}`] = templateValues;
        }

        return initialValues;
    }

    const initialValues = resolveInitialValues();

    return (
        <Dialog
            title="Add new insight"
            width={640}
            className="k-icp-dialog-no-padding k-icp-dialog-with-form"
            onClose={e => {
                preventCloseOfParentDialog(e);
                onClose?.();
            }}
        >
            <Form
                ref={formRef}
                onSubmit={saveCallbackCreator(async (data: Record<string, any>) => {
                    const selectedTemplateTag: string = data['templateTag'];
                    const templateParametersValuesKey = `insight_template_${selectedTemplateTag}`;
                    const selectedTemplate = templates.find(t => t.tag === selectedTemplateTag);
                    if (!selectedTemplate) throw new Error('Template not found: ' + selectedTemplateTag);
                    let templateParametersValues: InsightParameterValue[] = data[`insight_template_${selectedTemplateTag}`];
                    const updatedTemplateParametersValues = await saveCanvasInsightParameterInstantValues(
                        dispatch,
                        false,
                        selectedTemplate.parameters,
                        templateParametersValues
                    );
                    if (updatedTemplateParametersValues !== templateParametersValues && formRef.current)
                        formRef.current.onChange(templateParametersValuesKey, { value: updatedTemplateParametersValues });
                    await onSave?.(selectedTemplateTag, updatedTemplateParametersValues);

                    onClose?.();
                })}
                ignoreModified={true}
                initialValues={initialValues}
                render={formRenderProps => (
                    <FormElement className="k-icp-component-border">
                        <div className="k-p-4 k-icp-panel-base k-border-b k-border-b-solid k-icp-component-border">
                            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                                <InsightQuestionIcon className="k-shrink-0" />
                                <strong className="k-mt-thin">{question}</strong>
                            </StackLayout>
                        </div>
                        <SubmitFormOnEnter className="k-window-content">
                            <Field
                                name="templateTag"
                                label="Use the statement that best answers the question and complete it by filling in the blanks."
                                labelClassName="!k-mb-4 k-mt-2"
                                component={ValidatedInput}
                                validator={templateTagValidator}
                                inputType={InsightTemplatePicker}
                                templates={templates}
                            />
                        </SubmitFormOnEnter>
                        <DialogActionsBar layout="center">
                            <Button type="submit" themeColor="primary" disabled={saveDisabled || !formRenderProps.allowSubmit}>
                                Save insight
                            </Button>
                            <Button type="button" onClick={onClose}>
                                Cancel
                            </Button>
                        </DialogActionsBar>
                    </FormElement>
                )}
            />
        </Dialog>
    );
}

function preventCloseOfParentDialog(e: DialogCloseEvent) {
    if (e.syntheticEvent.type === 'keydown') e.syntheticEvent.stopPropagation();
}

function InsightTemplatePicker({ templates, disabled, valid, onChange, value }: FormFieldProps<string> & { templates: InsightCatalogTemplate[] }) {
    const selectedTemplateValidationScopeRef = useRef<ValidationScopeHandle>(null);
    const selectedInsightValidator = useCallback(function() {
        if (!selectedTemplateValidationScopeRef.current) return undefined;
        if (!selectedTemplateValidationScopeRef.current.isValid) return 'Invalid insight';
        return undefined;
    }, []);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {templates.map(template => {
                const isSelected = template.tag === value;

                const canEditTemplate = !disabled && isSelected;
                const editorField = (
                    <Field
                        name={`insight_template_${template.tag}`}
                        component={ValidatedInput}
                        validator={canEditTemplate ? selectedInsightValidator : undefined}
                        inputType={InsightEditorFormField}
                        content={template.content}
                        disabled={disabled}
                        parameters={template.parameters}
                        inEdit={isSelected}
                        wrapperClass="k-flex-1"
                        onClick={isSelected || !onChange ? undefined : () => onChange({ value: template.tag })}
                    />
                );

                const editor = canEditTemplate ? <ValidationScope ref={selectedTemplateValidationScopeRef}>{editorField}</ValidationScope> : editorField;

                return (
                    <StackLayout key={template.tag} align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                        <RadioButton disabled={disabled} checked={isSelected} onChange={onChange} value={template.tag} valid={valid} />
                        {editor}
                    </StackLayout>
                );
            })}
        </StackLayout>
    );
}

function InsightEditorFormField({
    content,
    disabled,
    onChange,
    parameters,
    inEdit,
    valid,
    value,
    onClick
}: FormFieldProps<InsightParameterValue[]> & { parameters: InsightTemplateParameter[] } & Pick<InsightEditorProps, 'content' | 'inEdit' | 'onClick'>) {
    return (
        <InsightEditor
            content={content}
            disabled={disabled}
            parameters={parameters}
            inEdit={inEdit}
            invalid={!valid}
            parametersValues={value}
            onParameterChange={
                onChange &&
                ((parameterId, parameterValue) =>
                    onChange({ value: immutableAddOrUpdate(value, { id: parameterId, value: parameterValue }, p => p.id === parameterId) }))
            }
            onClick={onClick}
            hideActions
            hideEditControls
        />
    );
}

const selectedInsightValidator = requiredValidator('Select insight', false);
export function PickInsightModal({
    ideaId,
    customerSegmentId,
    jobToBeDoneId,
    onClose,
    onSelectInsight,
    ignoredInterviewId,
    catalogId,
    sectionTag,
    questionTag,
    forkFilter
}: {
    ideaId: string;
    customerSegmentId: number;
    jobToBeDoneId: number;
    onClose?: () => void;
    onSelectInsight?: (insightId: number) => Promise<void>;
    ignoredInterviewId?: number;
    catalogId?: string;
    sectionTag?: string;
    questionTag?: string;
    forkFilter?: ForkedInsightFilter;
}) {
    const [insights, setInsights] = useState<Insight[]>();
    const [selectDisabled, selectCallbackCreator] = useSingleClickButton<
        Parameters<NonNullable<typeof onSelectInsight>>,
        ReturnType<NonNullable<typeof onSelectInsight>>
    >();
    const onSelectInsightCallback = onSelectInsight && selectCallbackCreator(onSelectInsight);
    const isLoading = !insights;

    useEffect(() => {
        let alternativeSolutionId: number | undefined;
        if (forkFilter) {
            if (forkFilter.propertyType !== InsightPropertyType.AlternativeSolution || typeof forkFilter.value !== 'number')
                throw new Error('Unsupported fork filter');
            alternativeSolutionId = forkFilter.value;
        }

        insightsService
            .getAllInsights(
                ideaId,
                undefined,
                ignoredInterviewId,
                undefined,
                customerSegmentId,
                jobToBeDoneId,
                alternativeSolutionId,
                catalogId,
                sectionTag,
                questionTag
            )
            .then(setInsights);
    }, [catalogId, customerSegmentId, forkFilter, ideaId, ignoredInterviewId, jobToBeDoneId, questionTag, sectionTag]);

    return (
        <Dialog
            title="Select existing insight"
            width={640}
            className="k-icp-dialog-no-padding k-icp-dialog-with-form"
            onClose={e => {
                preventCloseOfParentDialog(e);
                onClose?.();
            }}
        >
            <Form
                onSubmit={async (data: Record<string, any>) => {
                    if (isLoading) return;
                    if (!insights.length) {
                        onClose?.();
                        return;
                    }

                    const selectedInsightId: number = data['insightId'];
                    await onSelectInsightCallback?.(selectedInsightId);
                    onClose?.();
                }}
                ignoreModified={true}
                render={formRenderProps => (
                    <FormElement className="k-icp-component-border">
                        <SubmitFormOnEnter className="k-window-content">
                            {isLoading ? (
                                <LoadingIndicator size="big" className="k-display-block -block-center" />
                            ) : insights.length ? (
                                <Field
                                    name="insightId"
                                    label="Pick from a list of insights for the same question, Job-to-be-Done and Customer segment:"
                                    labelClassName="!k-mb-4"
                                    component={ValidatedInput}
                                    validator={selectedInsightValidator}
                                    inputType={InsightPicker}
                                    insights={insights}
                                />
                            ) : (
                                <EmptyInsightsView />
                            )}
                        </SubmitFormOnEnter>
                        {!isLoading && (
                            <DialogActionsBar layout="center">
                                <Button type="submit" themeColor="primary" disabled={selectDisabled || !formRenderProps.allowSubmit || !insights.length}>
                                    Select insight
                                </Button>
                                <Button type="button" onClick={onClose}>
                                    Cancel
                                </Button>
                            </DialogActionsBar>
                        )}
                    </FormElement>
                )}
            />
        </Dialog>
    );
}

function EmptyInsightsView() {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-3 k-my-20">
            <img src={noInsightsImagesUrl} width="64" height="65" alt="No insights" />
            <span>No existing insights yet</span>
        </StackLayout>
    );
}

function InsightPicker({ insights, disabled, valid, onChange, value }: FormFieldProps<number> & { insights: Insight[] }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
            {insights.map(insight => {
                const isSelected = insight.id === value;

                return (
                    <StackLayout key={insight.id} align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                        <RadioButton disabled={disabled} checked={isSelected} onChange={onChange} value={insight.id} valid={valid} />
                        <InsightEditor
                            content={insight.template.content}
                            parameters={insight.template.parameters}
                            parametersValues={insight.parameterValues}
                            inEdit={false}
                            readonly
                            onClick={onChange && (() => onChange({ value: insight.id }))}
                        />
                    </StackLayout>
                );
            })}
        </StackLayout>
    );
}
