import { ErrorWithOperationDisplayName } from './common';
import { ReducedPerson } from './contactsService';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, PagedResponse, RequestMethod } from './httpServiceBase';
import { ReducedUser } from './usersService';

export enum InterviewType {
    ProblemDiscoveryInterview = 'ProblemDiscoveryInterview',
    Other = 'Other'
}

export enum InterviewStage {
    NotStarted = 'NotStarted',
    Started = 'Started',
    PendingReview = 'PendingReview',
    PendingInsightCapture = 'InsightCapture',
    PendingHypothesesEvaluation = 'PendingHypothesesEvaluation',
    Complete = 'Complete'
}

export type ReducedInterview = {
    id: number;
    interviewType: InterviewType;
    stage: InterviewStage;
};

export type Interview = ReducedInterview & {
    user: ReducedUser;
    contact: ReducedPerson;
    researchId: number;
    meetingId?: number | null;
    timerId?: number | null;
    date: Date;
    initialized: boolean;
};

export type InterviewQuestion = {
    content?: string | null;
    revision?: string | null;
    labelText?: string | null;
    labelColor?: string | null;
    tips: string[];
    custom: boolean;
    revised?: boolean;
};

export type InterviewAnswer = {
    content?: string | null;
    revision?: string | null;
    revised?: boolean;
};

export enum InterviewEntryStatus {
    Covered = 'Covered',
    NotCovered = 'NotCovered',
    Irrelevant = 'Irrelevant'
}

export type InterviewEntry = {
    id: number;
    order: number;
    date?: Date | null;
    question: InterviewQuestion;
    answer: InterviewAnswer;
    status?: InterviewEntryStatus | null;
    hidden?: boolean;
};

export type InterviewSection = {
    id: number;
    title: string;
    order: number;
    entries: InterviewEntry[];
    targetDurationInMinutes: number;
};

export enum HypothesisVerdict {
    True = 'True',
    False = 'False',
    Unknown = 'Unknown'
}

export type InterviewHypothesisVerdict = {
    interviewId: number;
    hypothesisId: number;
    verdict: HypothesisVerdict;
};

export type Interviews = PagedResponse<'interviews', Interview>;

class InterviewsService extends HttpServiceBase {
    constructor() {
        super('/api/interviews');
    }

    private static ensureInterviewDateFields(entry: Interview): Interview {
        dateTimeService.ensureDateField(entry, 'date');

        return entry;
    }

    private static ensureInterviewEntryDateFields(entry: InterviewEntry): InterviewEntry {
        dateTimeService.ensureDateField(entry, 'date');

        return entry;
    }

    @ErrorWithOperationDisplayName('Get interviews')
    getInterviews(ideaId: string, skip?: number, take?: number, afterId?: number, researchId?: number): Promise<Interviews> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());
        this.addQueryParamIfPresent(queryParams, 'afterId', afterId?.toString());
        this.addQueryParamIfPresent(queryParams, 'researchId', researchId?.toString());

        return this.performRequest<Interviews>({
            path: `/${ideaId}/instances`,
            queryParams
        }).then(response => {
            response.interviews.forEach(i => InterviewsService.ensureInterviewDateFields);

            return response;
        });
    }

    @ErrorWithOperationDisplayName('Get interviews')
    async getAllInterviews(ideaId: string, researchId?: number): Promise<Interview[]> {
        const allInterviews: Interview[] = [];
        let afterId: number | undefined;
        while (true) {
            const currentPageInterviews = await this.getInterviews(ideaId, undefined, undefined, afterId, researchId);
            allInterviews.push(...currentPageInterviews.interviews);
            if (!currentPageInterviews.interviews.length || allInterviews.length === currentPageInterviews.totalCount) return allInterviews;
            afterId = currentPageInterviews.interviews.at(-1)!.id;
        }
    }

    @ErrorWithOperationDisplayName('Get interview')
    getInterview(ideaId: string, interviewId: number): Promise<Interview> {
        return this.performRequest<Interview>({
            path: `/${ideaId}/instances/${interviewId}`
        }).then(InterviewsService.ensureInterviewDateFields);
    }

    @ErrorWithOperationDisplayName('Initialize interview')
    initializeInterview(ideaId: string, interviewId: number): Promise<Interview> {
        return this.performRequest<Interview>({
            path: `/${ideaId}/instances/${interviewId}/initialize`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Update interview stage')
    updateInterviewStage(ideaId: string, interviewId: number, newStage: InterviewStage): Promise<Interview> {
        return this.performRequest<Interview>({
            path: `/${ideaId}/instances/${interviewId}/stage`,
            method: RequestMethod.PUT,
            body: {
                stage: newStage
            }
        }).then(InterviewsService.ensureInterviewDateFields);
    }

    @ErrorWithOperationDisplayName('Create interview entry')
    createInterviewEntry(ideaId: string, interviewId: number, sectionId: number, afterEntryId?: number): Promise<InterviewEntry> {
        return this.performRequest<InterviewEntry>({
            path: `/${ideaId}/instances/${interviewId}/entries`,
            method: RequestMethod.POST,
            body:
                afterEntryId === undefined
                    ? {
                          sectionId,
                          atPosition: 0
                      }
                    : { sectionId, afterId: afterEntryId }
        }).then(InterviewsService.ensureInterviewEntryDateFields);
    }

    @ErrorWithOperationDisplayName('Delete interview entry')
    async deleteInterviewEntry(ideaId: string, interviewId: number, entryId: number): Promise<void> {
        await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/instances/${interviewId}/entries/${entryId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore interview entry')
    restoreInterviewEntry(ideaId: string, interviewId: number, entryId: number): Promise<InterviewEntry> {
        return this.performRequest<InterviewEntry>({
            path: `/${ideaId}/instances/${interviewId}/entries/${entryId}/restore`,
            method: RequestMethod.POST
        }).then(InterviewsService.ensureInterviewEntryDateFields);
    }

    @ErrorWithOperationDisplayName('Hide interview entry')
    hideInterviewEntry(ideaId: string, interviewId: number, entryId: number): Promise<InterviewEntry> {
        return this.performRequest<InterviewEntry>({
            path: `/${ideaId}/instances/${interviewId}/entries/${entryId}/hide`,
            method: RequestMethod.POST
        }).then(InterviewsService.ensureInterviewEntryDateFields);
    }

    @ErrorWithOperationDisplayName('Unhide interview entry')
    unhideInterviewEntry(ideaId: string, interviewId: number, entryId: number): Promise<InterviewEntry> {
        return this.performRequest<InterviewEntry>({
            path: `/${ideaId}/instances/${interviewId}/entries/${entryId}/unhide`,
            method: RequestMethod.POST
        }).then(InterviewsService.ensureInterviewEntryDateFields);
    }

    @ErrorWithOperationDisplayName('Update interview entry question')
    updateInterviewEntryQuestion(ideaId: string, interviewId: number, entryId: number, question: string): Promise<InterviewEntry> {
        return this.performRequest<InterviewEntry>({
            path: `/${ideaId}/instances/${interviewId}/entries/${entryId}/question`,
            method: RequestMethod.PUT,
            body: {
                content: question
            }
        }).then(InterviewsService.ensureInterviewEntryDateFields);
    }

    @ErrorWithOperationDisplayName('Update interview entry question revision')
    updateInterviewEntryQuestionRevision(ideaId: string, interviewId: number, entryId: number, question: string): Promise<InterviewEntry> {
        return this.performRequest<InterviewEntry>({
            path: `/${ideaId}/instances/${interviewId}/entries/${entryId}/question/revision`,
            method: RequestMethod.PUT,
            body: {
                content: question
            }
        }).then(InterviewsService.ensureInterviewEntryDateFields);
    }

    @ErrorWithOperationDisplayName('Update interview entry answer')
    updateInterviewEntryAnswer(ideaId: string, interviewId: number, entryId: number, answer: string): Promise<InterviewEntry> {
        return this.performRequest<InterviewEntry>({
            path: `/${ideaId}/instances/${interviewId}/entries/${entryId}/answer`,
            method: RequestMethod.PUT,
            body: {
                content: answer
            }
        }).then(InterviewsService.ensureInterviewEntryDateFields);
    }

    @ErrorWithOperationDisplayName('Update interview entry answer revision')
    updateInterviewEntryAnswerRevision(ideaId: string, interviewId: number, entryId: number, answer: string): Promise<InterviewEntry> {
        return this.performRequest<InterviewEntry>({
            path: `/${ideaId}/instances/${interviewId}/entries/${entryId}/answer/revision`,
            method: RequestMethod.PUT,
            body: {
                content: answer
            }
        }).then(InterviewsService.ensureInterviewEntryDateFields);
    }

    @ErrorWithOperationDisplayName('Update interview entry status')
    updateInterviewEntryStatus(ideaId: string, interviewId: number, entryId: number, status: InterviewEntryStatus | null): Promise<InterviewEntry> {
        return this.performRequest<InterviewEntry>({
            path: `/${ideaId}/instances/${interviewId}/entries/${entryId}/status`,
            method: RequestMethod.PUT,
            body: {
                status
            }
        }).then(InterviewsService.ensureInterviewEntryDateFields);
    }

    @ErrorWithOperationDisplayName('Get interview entry')
    getInterviewEntry(ideaId: string, interviewId: number, entryId: number): Promise<InterviewEntry> {
        return this.performRequest<InterviewEntry>({
            path: `/${ideaId}/instances/${interviewId}/entries/${entryId}`
        }).then(InterviewsService.ensureInterviewEntryDateFields);
    }

    @ErrorWithOperationDisplayName('Get interview sections')
    async getInterviewSections(ideaId: string, interviewId: number): Promise<InterviewSection[]> {
        const interviewSections = await this.performRequest<InterviewSection[]>({
            path: `/${ideaId}/instances/${interviewId}/sections`
        });

        dateTimeService.ensureDateType(entry => entry, 'date', ...interviewSections.flatMap(s => s.entries));

        return interviewSections;
    }

    @ErrorWithOperationDisplayName('Get interview hypotheses verdicts')
    getInterviewHypothesesVerdicts(ideaId: string, interviewId: number): Promise<InterviewHypothesisVerdict[]> {
        return this.performRequest({
            path: `/${ideaId}/instances/${interviewId}/hypotheses-verdicts`
        });
    }

    @ErrorWithOperationDisplayName('Get interview hypotheses verdict')
    getInterviewHypothesesVerdict(ideaId: string, interviewId: number, hypothesisId: number): Promise<InterviewHypothesisVerdict> {
        return this.performRequest({
            path: `/${ideaId}/instances/${interviewId}/hypotheses-verdicts/${hypothesisId}`
        });
    }

    @ErrorWithOperationDisplayName('Set interview hypotheses verdict')
    updateInterviewHypothesesVerdict(
        ideaId: string,
        interviewId: number,
        hypothesisId: number,
        verdict: HypothesisVerdict
    ): Promise<InterviewHypothesisVerdict> {
        return this.performRequest({
            path: `/${ideaId}/instances/${interviewId}/hypotheses-verdicts/${hypothesisId}`,
            method: RequestMethod.PUT,
            body: {
                verdict
            }
        });
    }

    @ErrorWithOperationDisplayName('Reset interview hypotheses verdict')
    resetInterviewHypothesesVerdict(ideaId: string, interviewId: number, hypothesisId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/instances/${interviewId}/hypotheses-verdicts/${hypothesisId}`,
            method: RequestMethod.DELETE
        });
    }
}

export const interviewsService = new InterviewsService();
