import { Skeleton } from '@progress/kendo-react-indicators';
import { Suspense, lazy, useState } from 'react';

const TruncateMarkup = lazy(() => import('react-truncate-markup'));

export function TextWithShowMoreByLines({ children, lines, className }: { children?: React.ReactNode; lines?: number; className?: string }) {
    const [isExpanded, setIsExpanded] = useState(false);

    if (isExpanded)
        return (
            <div className={className}>
                {children} <ToggleEllipsisButton onClick={() => setIsExpanded(false)}>Show less</ToggleEllipsisButton>
            </div>
        );

    return (
        <Suspense fallback={<Skeleton shape="text" className="-w100" />}>
            <TruncateMarkup tokenize="words" lines={lines} ellipsis={<ShowMoreEllipsis onClick={() => setIsExpanded(true)}  />}>
                <div className={className}>{children}</div>
            </TruncateMarkup>
        </Suspense>
    );
}

function ShowMoreEllipsis({ onClick }: { onClick?: () => void }) {
    return (
        <span>
            ... <ToggleEllipsisButton onClick={onClick}>Show more</ToggleEllipsisButton>
        </span>
    );
}

function ToggleEllipsisButton({ children, onClick }: { children?: string; onClick?: () => void }) {
    return (
        <span onClick={onClick} className="k-button-link-secondary k-cursor-pointer">
            {children}
        </span>
    );
}
