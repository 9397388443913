export class JourneyPageWelcomeAnimator {
    private readonly initialAnimationContainer!: HTMLElement;
    private readonly journeyStartNode: HTMLElement | null;
    private readonly journeyPathWrapper: HTMLElement | null = null;

    private classesCleanup: [Element, string][] = [];

    constructor(private readonly scrollContainer: HTMLElement, private readonly initialAnimationDuration: number) {
        const initialAnimationContainer = scrollContainer.querySelector<HTMLElement>('.journey-welcome-animation-container');
        if (initialAnimationContainer) this.initialAnimationContainer = initialAnimationContainer;

        this.journeyStartNode = scrollContainer.querySelector<HTMLElement>('.journey-start-node');
        this.journeyPathWrapper = scrollContainer.querySelector<HTMLElement>('.journey-path-wrapper');
    }

    begin() {
        if (!this.initialAnimationContainer) return;

        this.collapseInitialAnimation();
    }

    private collapseInitialAnimation() {
        setTimeout(() => {
            if (this.journeyStartNode) this.initialAnimationContainer.style.top = this.journeyStartNode.offsetTop + 'px';

            this.continueAfterTransition(this.initialAnimationContainer, this.showStartNode);
            this.addClass(this.initialAnimationContainer, 'journey-welcome-animation-collapsed');
            if (this.scrollContainer.clientHeight < this.scrollContainer.scrollHeight) {
                this.scrollContainer.scrollTo({
                    top: this.scrollContainer.scrollHeight - this.scrollContainer.clientHeight,
                    behavior: 'smooth'
                });
            }
        }, this.initialAnimationDuration);
    }

    private showStartNode() {
        this.continueAfterTransition(this.initialAnimationContainer, this.showJourneyPath);
        this.addClass(this.initialAnimationContainer, 'journey-welcome-animation-hidden');
        if (this.journeyStartNode) this.addClass(this.journeyStartNode, 'journey-start-node-shown');
    }

    private showJourneyPath() {
        if (this.journeyPathWrapper) {
            this.continueAfterTransition(this.journeyPathWrapper, this.showJourneyNodes);
            this.addClass(this.journeyPathWrapper, 'journey-path-wrapper-shown');
        } else this.showJourneyNodes();
    }

    private showJourneyNodes() {
        const journeyNodes = Array.prototype.slice.call<HTMLCollectionOf<Element>, [], HTMLElement[]>(
            this.scrollContainer.getElementsByClassName('journey-node')
        );
        journeyNodes.sort((n1, n2) => n1.offsetTop - n2.offsetTop);
        this.showJourneyNode(journeyNodes, journeyNodes.length - 1);
    }

    private showJourneyNode(nodes: HTMLElement[], index: number) {
        if (index === -1) {
            this.showEverything();
            return;
        }

        const nodeToShow = nodes[index];
        this.continueAfterTransition(nodeToShow, () => this.showJourneyNode(nodes, index - 1));
        this.addClass(nodeToShow, 'journey-node-shown');
    }

    private showEverything() {
        this.continueAfterTransition(this.scrollContainer, this.cleanUp);
        this.addClass(this.scrollContainer, 'journey-animating-completed');
    }

    private cleanUp() {
        this.classesCleanup.forEach(([e, c]) => e.classList.remove(c));
        this.scrollContainer.classList.remove('journey-animating');
        this.initialAnimationContainer.style.display = 'none';

        this.classesCleanup = [];
    }

    private continueAfterTransition(transitionedElement: HTMLElement, continuation: () => void) {
        transitionedElement.addEventListener('transitionend', () => window.requestAnimationFrame(() => continuation.call(this)), { once: true, passive: true });
    }

    private addClass(element: Element, className: string) {
        this.classesCleanup.push([element, className]);
        element.classList.add(className);
    }
}
