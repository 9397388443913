import { StackLayout } from '@progress/kendo-react-layout';
import { useNavigate } from 'react-router-dom';
import { CountryAndCityView } from '../../components/common/countries';
import { AvatarInput } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import Panel, { PanelLink } from '../../components/ui/panel';
import { H1 } from '../../components/ui/typography';
import { useProfile } from '../../hooks/profileHooks';

export default function ViewProfilePage() {
    const navigate = useNavigate();

    const { currentProfile, updateProfile } = useProfile();

    const updateAvatar = async ({ value }: { value?: string | null }) => {
        updateProfile({ picture: value ? value : null });
    };

    return (
        <div className="page-content-section">
            <H1 className="heading-row">My profile</H1>
            <div className="page-content-middle">
                {currentProfile ? (
                    <StackLayout orientation="vertical" className="k-gap-6">
                        <div className="k-text-center">
                            <AvatarInput
                                name="avatar"
                                onChange={updateAvatar}
                                value={currentProfile.picture}
                                initials={currentProfile.initials}
                                colorIndex={currentProfile.colorIndex}
                                className="k-d-flex k-flex-col k-align-items-center"
                            />
                        </div>
                        <Panel title="Personal info" editBtnSize="small" editBtnText="Edit" onEdit={() => navigate('edit')}>
                            <div className="k-icp-panel-section k-align-items-center">
                                <div className="k-icp-panel-section-header k-label">Name</div>
                                <div className="k-flex-grow -minw0">
                                    <span className="k-input k-input-md k-button-clear !k-py-1">{`${currentProfile.firstName} ${currentProfile.lastName}`}</span>
                                </div>
                            </div>
                            <div className="k-icp-panel-section k-align-items-center">
                                <div className="k-icp-panel-section-header k-label">Email</div>
                                <div className="k-flex-grow -minw0">
                                    <span className="k-input k-input-md k-button-clear !k-py-1">{currentProfile.emailAddress}</span>
                                </div>
                            </div>
                            {currentProfile.phoneNumber && (
                                <div className="k-icp-panel-section k-align-items-center">
                                    <div className="k-icp-panel-section-header k-label">Phone number</div>
                                    <div className="k-flex-grow -minw0">
                                        <span className="k-input k-input-md k-button-clear !k-py-1">{currentProfile.phoneNumber}</span>
                                    </div>
                                </div>
                            )}
                            {(currentProfile.countryCode || currentProfile.city) && (
                                <div className="k-icp-panel-section k-align-items-center">
                                    <div className="k-icp-panel-section-header k-label">Country, City</div>
                                    <div className="k-flex-grow -minw0">
                                        <span className="k-input k-input-md k-button-clear !k-py-1">
                                            <CountryAndCityView city={currentProfile.city} countryCode={currentProfile.countryCode} />
                                        </span>
                                    </div>
                                </div>
                            )}
                            {currentProfile.jobTitle && (
                                <div className="k-icp-panel-section k-align-items-center">
                                    <div className="k-icp-panel-section-header k-label">Job title</div>
                                    <div className="k-flex-grow -minw0">
                                        <span className="k-input k-input-md k-button-clear !k-py-1">{currentProfile.jobTitle}</span>
                                    </div>
                                </div>
                            )}
                            {currentProfile.companyName && (
                                <div className="k-icp-panel-section k-align-items-center">
                                    <div className="k-icp-panel-section-header k-label">Company</div>
                                    <div className="k-flex-grow -minw0">
                                        <span className="k-input k-input-md k-button-clear !k-py-1">{currentProfile.companyName}</span>
                                    </div>
                                </div>
                            )}
                        </Panel>
                        <Panel title="Password" editBtnSize="small" editBtnText="Change" onEdit={() => navigate('change-password')}>
                            <div className="k-icp-panel-section k-align-items-center">
                                <div className="k-icp-panel-section-header k-label">Password</div>
                                <div className="k-flex-grow -minw0">
                                    <span className="k-input k-input-md k-button-clear !k-py-1">****************</span>
                                </div>
                            </div>
                        </Panel>
                        <Panel title="Social" editBtnSize="small" editBtnText="Edit" onEdit={() => navigate('edit-social')}>
                            <div className="k-icp-panel-section k-align-items-center">
                                <div className="k-icp-panel-section-header k-label">LinkedIn</div>
                                <div className="k-flex-grow -minw0">
                                    <span className="k-input k-input-md k-button-clear !k-py-1">
                                        {currentProfile.linkedInProfileUrl ? (
                                            <a href={currentProfile.linkedInProfileUrl} target="_blank" rel="noreferrer">
                                                {currentProfile.linkedInProfileUrl}
                                            </a>
                                        ) : (
                                            <span className="k-icp-subtle-text k-icp-ghost">Add details...</span>
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="k-icp-panel-section k-align-items-center">
                                <div className="k-icp-panel-section-header k-label">Facebook</div>
                                <div className="k-flex-grow -minw0">
                                    <span className="k-input k-input-md k-button-clear !k-py-1">
                                        {currentProfile.facebookProfileUrl ? (
                                            <a href={currentProfile.facebookProfileUrl} target="_blank" rel="noreferrer">
                                                {currentProfile.facebookProfileUrl}
                                            </a>
                                        ) : (
                                            <span className="k-icp-subtle-text k-icp-ghost">Add details...</span>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel title="Account">
                            <PanelLink to="subscription" text="My subscription" />
                            <PanelLink to="delete" text="Delete account" />
                            <PanelLink to="about" text="About Icanpreneur Platform" />
                        </Panel>
                    </StackLayout>
                ) : (
                    <div className="k-text-center">
                        <LoadingIndicator size="big" />
                    </div>
                )}
            </div>
        </div>
    );
}
