import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, ReactNode } from 'react';
import { combineClassNames } from '../../services/common';

export function SvgIconButtonContent({
    icon: Icon,
    children,
    gap = 1,
    className
}: {
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    children?: ReactNode;
    gap?: number;
    className?: string;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className={combineClassNames(`k-icp-svg-icon-button-content k-gap-${gap}`, className)}>
            <Icon className="k-icp-icon" />
            {children}
        </StackLayout>
    );
}
