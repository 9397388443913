import { Button } from '@progress/kendo-react-buttons';
import { Card, CardBody, CardHeader, StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, resolvePath, useParams } from 'react-router-dom';
import { ListSortOptions } from '../../components/common/listPageComponents';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { Pager } from '../../components/ui/pager';
import { H2 } from '../../components/ui/typography';
import UserAvatar from '../../components/user/userAvatar';
import { useCopyToClipboard } from '../../hooks/commonHooks';
import emptySchedulesIllustrationUrl from '../../images/empty-schedules-illustration.svg';
import { resolveAbsoluteUrl } from '../../services/common';
import { NotFoundException } from '../../services/httpServiceBase';
import { SchedulesListPreferences, listPreferencesService } from '../../services/listPreferencesService';
import { RealTimeUpdateIdeaEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { Schedule, Schedules, SchedulesSortBy, schedulesService } from '../../services/schedulesService';
import { generateInitials, getPreferredColorIndex } from '../../services/usersService';
import { useTabbedLayoutContext } from '../layouts/tabbedLayout';

export function SchedulesPage() {
    const { ideaId } = useParams();
    if (!ideaId) throw new NotFoundException();
    const { setHeaderContent } = useTabbedLayoutContext();
    const [listPreferences, setListPreferences] = useState<SchedulesListPreferences>(() => listPreferencesService.getSchedulesListPreferences());
    const [skip, setSkip] = useState(0);
    const [schedules, setSchedules] = useState<Schedules>();

    const updateListPreferences = useCallback(
        function(updatedListPreferences: Partial<SchedulesListPreferences>) {
            const newListPreferences = { ...listPreferences, ...updatedListPreferences };
            listPreferencesService.saveSchedulesListPreferences(newListPreferences);
            setListPreferences(newListPreferences);
        },
        [listPreferences]
    );

    useEffect(() => {
        setHeaderContent(
            <ListSortOptions
                value={listPreferences.sortBy}
                onChange={sortBy => updateListPreferences({ sortBy })}
                options={[SchedulesSortBy.Recent, SchedulesSortBy.Alphabetical]}
                defaultValue={SchedulesSortBy.Recent}
            />
        );

        return () => setHeaderContent(undefined);
    }, [listPreferences.sortBy, setHeaderContent, updateListPreferences]);

    const loadSchedules = useCallback(() => schedulesService.getSchedules(ideaId, listPreferences.sortBy, skip, listPreferences.pageSize).then(setSchedules), [
        ideaId,
        listPreferences.pageSize,
        listPreferences.sortBy,
        skip
    ]);

    useEffect(() => {
        loadSchedules();
    }, [loadSchedules]);

    const loadSchedulesRef = useRef(loadSchedules);
    useEffect(() => {
        function reloadCurrentPage(e: RealTimeUpdateIdeaEventData) {
            if (e.ideaId !== ideaId) return;
            loadSchedulesRef.current();
        }

        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleAdd', reloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleDelete', reloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleRestore', reloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleUpdate', reloadCurrentPage);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleAdd', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleDelete', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleRestore', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleUpdate', reloadCurrentPage);
        };
    }, [ideaId]);

    if (!schedules) return <LoadingIndicator className="k-d-block -block-center" size="big" />;
    if (!schedules.schedules.length) return <EmptySchedulesView />;

    return (
        <>
            <div className="k-d-grid cards-grid k-align-items-start k-gap-4">
                {schedules.schedules.map(schedule => (
                    <Link key={schedule.id} className="k-link" to={schedule.id.toString()}>
                        <ScheduleCard schedule={schedule} />
                    </Link>
                ))}
            </div>
            <Pager
                total={schedules.totalCount}
                skip={skip}
                take={listPreferences.pageSize}
                onPageChange={(skip, take) => {
                    setSkip(skip);
                    updateListPreferences({ pageSize: take });
                }}
            />
        </>
    );
}

function EmptySchedulesView() {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1 k-gap-8">
            <img src={emptySchedulesIllustrationUrl} width="400" height="185" alt="Empty schedules list" />
            <div className="page-content-middle -w2 k-text-center">
                <H2 className="!k-mb-4">No Schedules found</H2>
                <div className="k-fs-lg">Create a schedule with your availability and let others book meetings at their preferred time.</div>
            </div>
        </StackLayout>
    );
}

function ScheduleCard({ schedule }: { schedule: Schedule }) {
    const bookingPagePath = `/bookings/${schedule.code}`;
    const copyToClipboard = useCopyToClipboard();

    return (
        <Card className="k-icp-interactive-card">
            <CardHeader className="!k-border-bottom-0 !k-pb-0">
                <div className="k-mb-1 max-lines-2">
                    <strong className="k-fs-lg">{schedule.title}</strong>
                </div>
                <div className="k-fs-sm k-icp-subtle-text">
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                        <span>{schedule.durationMinutes} min</span>
                        <div className="dot-separator" />
                        <span>{schedule.locationOptions.length} locations</span>
                    </StackLayout>
                </div>
            </CardHeader>
            <CardBody>
                <StackLayout align={{ horizontal: 'start', vertical: 'bottom' }} className="k-justify-content-between">
                    <UserAvatar
                        initials={generateInitials(schedule.user, 2)}
                        colorIndex={getPreferredColorIndex(schedule.user)}
                        picture={schedule.user.picture}
                    />

                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                        <Button
                            size="small"
                            fillMode="link"
                            themeColor="secondary"
                            onClick={e => {
                                e.preventDefault();
                                const absoluteBookingPagePath = resolvePath(bookingPagePath).pathname;
                                window.open(absoluteBookingPagePath, '_blank');
                            }}
                        >
                            Open booking page
                        </Button>
                        <div className="k-separator" />
                        <Button
                            size="small"
                            fillMode="link"
                            themeColor="secondary"
                            onClick={e => {
                                e.preventDefault();
                                const absoluteBookingPagePath = resolvePath(bookingPagePath).pathname;
                                const absoluteBookingPageUrl = resolveAbsoluteUrl(absoluteBookingPagePath);
                                copyToClipboard(absoluteBookingPageUrl, 'Booking page link');
                            }}
                        >
                            Copy link
                        </Button>
                    </StackLayout>
                </StackLayout>
            </CardBody>
        </Card>
    );
}
