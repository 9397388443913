import { combineClassNames } from '../../../services/common';

export function InterviewScriptEntryLabelView({ children, color, className }: { children: string; color?: string | null; className?: string }) {
    return (
        <div
            className={combineClassNames(
                'k-mb-thin k-rounded-b-sm k-w-max k-icp-text-smaller k-px-1.5 -py-0.75',
                color === 'green' ? 'k-icp-bg-success-8' : color === 'red' ? 'k-icp-bg-error-4' : 'k-icp-bg-secondary-4',
                className
            )}
        >
            {children}
        </div>
    );
}
