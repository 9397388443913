import { ComponentType } from 'react';
import { ReactComponent as InterviewStagesIllustration } from '../../images/interview-stages-illustration.svg';
import { GraphicData, combineClassNames } from '../../services/common';

type InlinedGraphicData = ComponentType<React.SVGProps<SVGSVGElement>>;

const inlinedGraphics: Partial<Record<string, InlinedGraphicData>> = {
    'interview-stages': InterviewStagesIllustration
};
const referencedGraphics: Partial<Record<string, GraphicData>> = {};

export function TaskGraphic({ name, className }: { name: string; className?: string }) {
    const cssClass = combineClassNames('responsive-image', className);

    const InlineGraphic = inlinedGraphics[name];
    if (InlineGraphic) return <InlineGraphic className={cssClass} />;

    const referencedGraphic = referencedGraphics[name];
    if (referencedGraphic)
        return (
            <img
                className={cssClass}
                src={referencedGraphic.url}
                width={referencedGraphic.width}
                height={referencedGraphic.height}
                alt={referencedGraphic.description}
                loading="lazy"
            />
        );

    return null;
}
