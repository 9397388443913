import { Button } from '@progress/kendo-react-buttons';
import { StackLayout, StackLayoutHandle } from '@progress/kendo-react-layout';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAsRef } from '../../hooks/commonHooks';
import { ReactComponent as RightArrowIcon } from '../../icons/arrow-right.svg';
import { ReactComponent as EditQuoteIcon } from '../../icons/edit-3.svg';
import { ReactComponent as ExternalLinkIcon } from '../../icons/external-link.svg';
import { ReactComponent as AddQuoteIcon } from '../../icons/plus-circle.svg';
import { ReactComponent as QuoteIcon } from '../../icons/quote-2.svg';
import { ReactComponent as DeleteQuoteIcon } from '../../icons/unlink-2.svg';
import { combineClassNames } from '../../services/common';
import { ScrollCancellationSource, domService } from '../../services/domService';
import { InterviewEntry, InterviewStage } from '../../services/interviewsService';
import { InterviewQuote, InterviewQuoteType } from '../../services/researchService';
import { InlineEditor, InlineEditorComponentHandle, InlineEditorComponentProps, InlineEditorHandle } from '../common/inlineEditor';
import { SelectionRangeTextBox, SelectionRangeTextBoxProps } from '../common/selectionRangeTextBox';
import LoadingIndicator from '../ui/loadingIndicator';
import { SvgIconButtonContent } from '../ui/svgIconButtonContent';
import { useInterviewEntrySelectionContext } from './entries/interviewEntries';
import { OpenInterviewButton, resolveInterviewLink } from './interviewMainActionButton';

export enum QuotesPresentation {
    FlatList = 1,
    InterviewsGroups = 2
}

export function InterviewItemQuotesEditList({
    ideaId,
    quotes,
    addingQuote,
    currentInterviewId,
    editQuoteId,
    onAddQuote,
    onEditQuote,
    onDeleteQuote,
    onCancelAddingQuote,
    onCancelEditingQuote,
    onUpdateQuote,
    onCreateQuote,
    onQuoteClick,
    selectedQuoteId,
    disabled,
    scrollToSelectedQuote
}: {
    ideaId: string;
    quotes?: InterviewQuote[];
    addingQuote?: boolean;
    currentInterviewId?: number;
    editQuoteId?: number;
    onAddQuote?: () => void;
    onEditQuote?: (quoteId: number) => void;
    onDeleteQuote?: (quoteId: number) => void;
    onCancelAddingQuote?: () => void;
    onCancelEditingQuote?: (quoteId: number) => void;
    onUpdateQuote?: (quoteId: number, startIndex: number, endIndex: number) => Promise<void>;
    onCreateQuote?: (entryId: number, startIndex: number, endIndex: number) => Promise<void>;
    onQuoteClick?: (quoteId: number) => void;
    selectedQuoteId?: number;
    disabled?: boolean;
    scrollToSelectedQuote?: boolean;
}) {
    const quoteElementsRefs = useRef<Partial<Record<number, HTMLElement>>>({});
    const hasQuotes = quotes && quotes.length > 0;

    const shouldScrollToSelectedQuote = scrollToSelectedQuote && hasQuotes && selectedQuoteId !== undefined;
    useEffect(() => {
        if (!shouldScrollToSelectedQuote) return;
        const quoteElementToScrollTo = quoteElementsRefs.current[selectedQuoteId];
        if (!quoteElementToScrollTo) return;

        const scrollCancelation: ScrollCancellationSource = {};
        domService.scrollVerticallyIntoViewIfNeeded(quoteElementToScrollTo, undefined, scrollCancelation);

        return () => {
            scrollCancelation.cancel = true;
        };
    }, [selectedQuoteId, shouldScrollToSelectedQuote]);

    if (!hasQuotes && disabled) return null;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-3">
            {hasQuotes && (
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-3">
                    {quotes.map(quote =>
                        quote.id === editQuoteId ? (
                            <InterviewQuoteEditor
                                key={quote.id}
                                quote={quote}
                                onCancel={onCancelEditingQuote && (() => onCancelEditingQuote(quote.id))}
                                onSelectionChange={onUpdateQuote && ((startIndex, endIndex) => onUpdateQuote(quote.id, startIndex, endIndex))}
                            />
                        ) : (
                            <InterviewQuoteWrapper
                                ref={r => (r ? (quoteElementsRefs.current[quote.id] = r) : delete quoteElementsRefs.current[quote.id])}
                                key={quote.id}
                                ideaId={ideaId}
                                quote={quote}
                                currentInterviewId={currentInterviewId}
                                onEdit={onEditQuote && (() => onEditQuote(quote.id))}
                                onDelete={onDeleteQuote && (() => onDeleteQuote(quote.id))}
                                onClick={onQuoteClick && (() => onQuoteClick(quote.id))}
                                selected={selectedQuoteId === quote.id}
                                hideActions={disabled}
                            />
                        )
                    )}
                </StackLayout>
            )}
            {addingQuote ? (
                <InterviewQuoteEditor onCancel={onCancelAddingQuote} onQuoteAdded={onCreateQuote} />
            ) : (
                currentInterviewId !== undefined &&
                !disabled && (
                    <Button type="button" fillMode="flat" themeColor="secondary" size="small" className="k-align-self-start" onClick={onAddQuote}>
                        <SvgIconButtonContent icon={AddQuoteIcon}>Add quote</SvgIconButtonContent>
                    </Button>
                )
            )}
        </StackLayout>
    );
}

const InterviewQuoteWrapper = forwardRef<
    HTMLElement | null | undefined,
    {
        ideaId: string;
        quote: InterviewQuote;
        currentInterviewId?: number;
        onEdit?: () => void;
        onDelete?: () => void;
        onClick?: () => void;
        selected?: boolean;
        hideActions?: boolean;
    }
>(function InterviewQuoteWrapper({ ideaId, quote, currentInterviewId, onEdit, onDelete, onClick, selected, hideActions }, ref) {
    const quoteText = getInterviewQuoteText(quote);
    if (!quoteText) return null;

    const isQuoteForCurrentInterview = quote.interviewId === currentInterviewId;
    const quoteLink = isQuoteForCurrentInterview ? undefined : resolveInterviewQuoteLink(ideaId, quote);

    return (
        <InterviewQuoteLayout ref={ref}>
            <InterviewQuoteView
                author={`${quote.interviewContact.firstName} ${quote.interviewContact.lastName}`}
                link={hideActions ? undefined : quoteLink}
                actions={
                    isQuoteForCurrentInterview && !hideActions ? (
                        <>
                            <Button type="button" size="small" fillMode="flat" className="interview-quote-action k-icp-svg-icon-button" onClick={onEdit}>
                                <EditQuoteIcon className="k-icp-icon" />
                            </Button>
                            <Button type="button" size="small" fillMode="flat" className="interview-quote-action k-icp-svg-icon-button" onClick={onDelete}>
                                <DeleteQuoteIcon className="k-icp-icon" />
                            </Button>
                        </>
                    ) : (
                        undefined
                    )
                }
                onClick={onClick}
                selected={selected}
            >
                {quoteText}
            </InterviewQuoteView>
        </InterviewQuoteLayout>
    );
});

const InterviewQuoteLayout = forwardRef<HTMLElement | null | undefined, { children?: ReactNode }>(function InterviewQuoteLayout({ children }, ref) {
    const stackLayoutRef = useRef<StackLayoutHandle>(null);
    useImperativeHandle<HTMLElement | null | undefined, HTMLElement | null | undefined>(ref, () => stackLayoutRef.current?.element, []);

    return (
        <StackLayout ref={stackLayoutRef} orientation="horizontal" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            <QuoteIcon className="k-icp-icon k-icp-icon-size-4 k-mt-3 k-flex-shrink-0" />
            {children}
        </StackLayout>
    );
});

type InterviewQuoteViewProps = { link?: string; children: string; author: string; actions?: ReactNode; onClick?: () => void; selected?: boolean };
function InterviewQuoteView({ link, children, author, actions, onClick, selected }: InterviewQuoteViewProps) {
    return (
        <InterviewQuoteContainer link={link} className="interview-quote" onClick={onClick} selected={selected}>
            <div className="k-pt-2 k-pb-3 k-px-4 k-w-full">
                <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-justify-content-between">
                    <div className="k-font-size-sm k-icp-subtle-text">{author}</div>
                    <div onClick={e => e.stopPropagation()}>
                        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                            {link && <ExternalLinkIcon className="interview-quote-action k-p-1" />}
                            {actions}
                        </StackLayout>
                    </div>
                </StackLayout>
                <div>“{children}”</div>
            </div>
        </InterviewQuoteContainer>
    );
}
function InterviewQuoteContainer({
    link,
    children,
    className,
    onClick,
    selected
}: {
    link?: string;
    children?: ReactNode;
    className?: string;
    onClick?: () => void;
    selected?: boolean;
}) {
    const wrapperClassName = combineClassNames('k-input k-input-solid k-rounded-md', className, selected ? '!k-border-secondary' : undefined);

    if (link)
        return (
            <Link to={link} className={wrapperClassName} onClick={onClick}>
                {children}
            </Link>
        );

    return (
        <div className={combineClassNames(wrapperClassName, onClick ? 'k-cursor-pointer' : undefined)} tabIndex={0} onClick={onClick}>
            {children}
        </div>
    );
}

function resolveInterviewLinkParamsForQuote(quote: InterviewQuote) {
    const view =
        quote.type === InterviewQuoteType.Hypothesis
            ? InterviewStage.PendingHypothesesEvaluation
            : quote.type === InterviewQuoteType.Insight
            ? InterviewStage.PendingInsightCapture
            : undefined;

    return {
        view,
        params: {
            quoteId: quote.id.toString()
        }
    };
}

function resolveInterviewQuoteLink(ideaId: string, quote: InterviewQuote) {
    const quoteParams = resolveInterviewLinkParamsForQuote(quote);

    return resolveInterviewLink(ideaId, quote.interviewId, quoteParams.view, undefined, quoteParams.params);
}

function getInterviewQuoteText(interviewQuote: InterviewQuote): string | null {
    const interviewAnswer = interviewQuote.entry.answer;
    const answerStr = interviewAnswer.revised ? interviewAnswer.revision : interviewAnswer.content;

    if (!answerStr) return null;
    return answerStr?.substring(interviewQuote.fromPosition, interviewQuote.toPosition);
}

function InterviewQuoteEditor({
    quote,
    onCancel,
    onSelectionChange,
    onQuoteAdded
}: {
    quote?: InterviewQuote;
    onCancel?: () => void;
    onSelectionChange?: (startIndex: number, endIndex: number) => Promise<void>;
    onQuoteAdded?: (entryId: number, startIndex: number, endIndex: number) => Promise<void>;
}) {
    const inlineEditorRef = useRef<InlineEditorHandle>(null);
    const [updatedRangeSelection, setUpdatedRangeSelection] = useState<InlineEditorSelectionRangeTextBoxValue>();
    const [pickedEntry, setPickedEntry] = useState<InterviewEntry>();

    const entry = quote?.entry ?? pickedEntry;
    const quoteContent = entry ? (entry.answer.revised ? entry.answer.revision : entry.answer.content) : undefined;
    const quoteRangeSelection: InlineEditorSelectionRangeTextBoxValue | undefined = quote
        ? { selectionStart: quote.fromPosition, selectionEnd: quote.toPosition }
        : undefined;
    const rangeSelection = updatedRangeSelection ?? quoteRangeSelection;

    const interviewEntrySelectionContext = useInterviewEntrySelectionContext();
    const isPickingEntry = !entry;
    const interviewEntrySelectionContextRef = useAsRef(interviewEntrySelectionContext);
    useEffect(() => {
        const interviewEntrySelectionContext = interviewEntrySelectionContextRef.current;
        if (!isPickingEntry || !interviewEntrySelectionContext) return;

        function selectedEntryHandler(entry: InterviewEntry) {
            setPickedEntry(entry);
            const answerContent = entry.answer.revised ? entry.answer.revision : entry.answer.content;
            setUpdatedRangeSelection({ selectionStart: 0, selectionEnd: answerContent?.length ?? 0 });

            if (inlineEditorRef.current?.editor) {
                inlineEditorRef.current.editor.focus?.();
                if (inlineEditorRef.current.editor.element) domService.scrollVerticallyIntoViewIfNeeded(inlineEditorRef.current.editor.element);
            }
        }

        interviewEntrySelectionContext.setOnEntrySelectedHandler(selectedEntryHandler);

        return () => interviewEntrySelectionContext.clearOnEntrySelectedHandler(selectedEntryHandler);
    }, [interviewEntrySelectionContextRef, isPickingEntry]);

    return (
        <InterviewQuoteLayout>
            <InlineEditor
                ref={inlineEditorRef}
                editor={InlineEditorSelectionRangeTextBox}
                editorSettings={
                    quoteContent
                        ? {
                              quoteContent: quoteContent
                          }
                        : undefined
                }
                additionalEditControls={
                    <span className="k-fs-sm k-icp-subtle-text">
                        {quoteContent ? 'Refine the selection by dragging the handles...' : 'Pick an answer from the interview...'}
                    </span>
                }
                isEditing={true}
                onCancel={onCancel}
                // When picking entry ignore save events
                onSave={
                    isPickingEntry
                        ? undefined
                        : () => {
                              if (!rangeSelection) {
                                  onCancel?.();
                                  return;
                              }

                              if (
                                  quoteRangeSelection &&
                                  quoteRangeSelection.selectionStart === rangeSelection.selectionStart &&
                                  quoteRangeSelection.selectionEnd === rangeSelection.selectionEnd
                              ) {
                                  onCancel?.();
                                  return;
                              }

                              if (quote) {
                                  return onSelectionChange?.(rangeSelection.selectionStart, rangeSelection.selectionEnd);
                              } else if (pickedEntry) {
                                  return onQuoteAdded?.(pickedEntry.id, rangeSelection.selectionStart, rangeSelection.selectionEnd);
                              }
                          }
                }
                autoFocus
                value={rangeSelection}
                onEditorChange={e => setUpdatedRangeSelection(e.value)}
                editClassName="k-flex-1"
            />
        </InterviewQuoteLayout>
    );
}

type InlineEditorSelectionRangeTextBoxValue = Pick<SelectionRangeTextBoxProps, 'selectionStart' | 'selectionEnd'>;
type InlineEditorSelectionRangeTextBoxSettings = { quoteContent?: string };
export const InlineEditorSelectionRangeTextBox = forwardRef<
    InlineEditorComponentHandle,
    InlineEditorComponentProps<InlineEditorSelectionRangeTextBoxValue, InlineEditorSelectionRangeTextBoxSettings>
>(function(props, ref) {
    const containerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(
        ref,
        () => ({
            focus() {
                containerRef.current?.focus();
            },
            get element() {
                return containerRef.current;
            }
        }),
        []
    );

    return (
        <div ref={containerRef} className={combineClassNames('k-panel k-icp-shadow-sm k-px-4 k-py-3', props.disabled ? 'k-disabled' : undefined)} tabIndex={0}>
            {props.settings?.quoteContent && props.value ? (
                <SelectionRangeTextBox
                    text={props.settings.quoteContent}
                    selectionStart={props.value.selectionStart}
                    selectionEnd={props.value.selectionEnd}
                    onSelectionChange={(selectionStart, selectionEnd) => props.onChange?.({ value: { selectionStart, selectionEnd } })}
                />
            ) : (
                <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-2 k-icp-subtle-text">
                    <span>Scroll to the supporting answer and click on it</span>
                    <RightArrowIcon className="k-icp-icon k-icp-icon-size-4" />
                </StackLayout>
            )}
        </div>
    );
});

const collapseQuotesCount = 1;
export function InterviewExpandableQuotesList({
    ideaId,
    quotes,
    header,
    onQuoteClick,
    className,
    selectedQuoteId
}: {
    ideaId: string;
    quotes: InterviewQuote[];
    header?: ReactNode;
    onQuoteClick?: (quoteId: number, entryId: number) => void;
    className?: string;
    selectedQuoteId?: number;
}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const canExpand = quotes.length > collapseQuotesCount;
    const quotesToRender = canExpand && !isExpanded ? [...quotes].slice(0, collapseQuotesCount) : quotes;

    let previousQuoteInterviewId: number | undefined = undefined;

    return (
        <div className={className}>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-thin">
                {quotesToRender.map((quote, quoteIndex) => {
                    const isFirstQuote = quoteIndex === 0;
                    if (previousQuoteInterviewId !== undefined && previousQuoteInterviewId !== quote.interviewId)
                        throw new Error('This component is meant to show quotes from the same interview!');

                    const quoteLinkParams = resolveInterviewLinkParamsForQuote(quote);

                    previousQuoteInterviewId = quote.interviewId;
                    return (
                        <div
                            key={quote.id}
                            className={combineClassNames(
                                'k-input-solid k-border k-border-solid k-rounded-md k-px-4 k-pt-2 k-pb-3',
                                onQuoteClick ? 'k-cursor-pointer' : undefined,
                                selectedQuoteId === quote.id ? '!k-border-secondary' : undefined
                            )}
                            onClick={onQuoteClick && (() => onQuoteClick(quote.id, quote.entry.id))}
                        >
                            {isFirstQuote && (
                                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                                    <span className="k-fs-sm k-icp-subtle-text k-flex-1">
                                        {quote.interviewContact.firstName} {quote.interviewContact.lastName}
                                    </span>
                                    {header}
                                    <OpenInterviewButton
                                        ideaId={ideaId}
                                        interviewId={quote.interviewId}
                                        onClick={e => e.stopPropagation()}
                                        size="small"
                                        fillMode="flat"
                                        className="k-icp-svg-icon-button"
                                        view={quoteLinkParams.view}
                                        additionalParameters={quoteLinkParams.params}
                                    >
                                        <ExternalLinkIcon className="k-icp-icon" />
                                    </OpenInterviewButton>
                                </StackLayout>
                            )}
                            <div>“{getInterviewQuoteText(quote)}”</div>
                        </div>
                    );
                })}
            </StackLayout>
            {canExpand &&
                (isExpanded ? (
                    <Button type="button" size="small" themeColor="secondary" fillMode="link" onClick={() => setIsExpanded(false)} className="!k-min-w-0">
                        Hide
                    </Button>
                ) : (
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                        <span className="k-fs-sm">{quotes.length - collapseQuotesCount} more quotes from this interview</span>
                        <Button type="button" size="small" themeColor="secondary" fillMode="link" onClick={() => setIsExpanded(true)} className="!k-min-w-0">
                            Show all
                        </Button>
                    </StackLayout>
                ))}
        </div>
    );
}

export function InterviewItemQuotesGroupedByInterview<TQuote extends InterviewQuote = InterviewQuote>({
    ideaId,
    quotes,
    resolveHeader,
    onSelectedQuote,
    selectedQuoteId
}: {
    ideaId: string;
    quotes?: TQuote[];
    resolveHeader?: (interviewId: number) => ReactNode;
    onSelectedQuote?: (interviewId: number, quoteId: number, entryId: number) => void;
    selectedQuoteId?: number;
}) {
    if (!quotes) return <LoadingIndicator size="big" className="k-display-block -block-center" />;

    const quotesByInterviewMap = new Map<number, TQuote[]>();
    quotes.forEach(q => {
        let interviewQuotes = quotesByInterviewMap.get(q.interviewId);
        if (!interviewQuotes) {
            interviewQuotes = [];
            quotesByInterviewMap.set(q.interviewId, interviewQuotes);
        }

        interviewQuotes.push(q);
    });

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {Array.from(quotesByInterviewMap.values()).map(interviewQuotes => {
                const firstQuote = interviewQuotes[0];
                const { interviewId } = firstQuote;

                return (
                    <StackLayout key={interviewId} align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                        <QuoteIcon className="k-icp-icon k-icp-icon-size-4 k-mt-3" />
                        <InterviewExpandableQuotesList
                            ideaId={ideaId}
                            quotes={interviewQuotes}
                            header={resolveHeader?.(interviewId)}
                            className="k-flex-1"
                            onQuoteClick={onSelectedQuote && ((quoteId, entryId) => onSelectedQuote(interviewId, quoteId, entryId))}
                            selectedQuoteId={selectedQuoteId}
                        />
                    </StackLayout>
                );
            })}
        </StackLayout>
    );
}
