import { Meeting, MeetingData, ReachOut, ReachOutData, ResearchReachOut, ResearchReachOutData } from '../../services/events';
import { ScheduleItemType, ScheduledItem } from '../../services/eventsService';
import { EventDialogProps, MeetingEventDialog, ReachOutEventDialog, ResearchReachOutEventDialog } from './eventsDialogs';

export function ScheduledItemDialog({
    ideaId,
    type,
    initialData,
    scheduledItem,
    newItemAdditionalProps,
    onSubmit,
    onCancel
}: {
    ideaId: string;
    type?: ScheduleItemType;
    initialData?: { start?: Date; end?: Date };
    scheduledItem?: ScheduledItem;
    newItemAdditionalProps?: Partial<Record<string, any>>;
    onSubmit?: (item: ScheduledItem) => void;
    onCancel?: () => void;
}) {
    const scheduledItemType = scheduledItem ? scheduledItem.data.type : type;
    if (!scheduledItemType) throw new Error('Provide scheduledItem or type');

    function getDialogParams():
        | EventDialogProps<ReachOut, ReachOutData>
        | (EventDialogProps<Meeting, MeetingData> & { initialResearchId?: number | null })
        | EventDialogProps<
              ResearchReachOut,
              ResearchReachOutData,
              { researchId: number; reachOutId: number },
              Partial<ResearchReachOutData> & { researchId: number }
          > {
        if (scheduledItem) {
            switch (scheduledItem.data.type) {
                case ScheduleItemType.ReachOut:
                    return {
                        ideaId: ideaId,
                        itemKey: scheduledItem.data.reachOut.id,
                        person: scheduledItem.data.reachOut.contact,
                        onSaved(reachOut: ReachOut) {
                            const updatedScheduledItem = new ScheduledItem({ type: ScheduleItemType.ReachOut, reachOut: reachOut });
                            onSubmit?.(updatedScheduledItem);
                        },
                        onCancel
                    };
                case ScheduleItemType.Meeting:
                    return {
                        ideaId: ideaId,
                        itemKey: scheduledItem.data.meeting.id,
                        person: scheduledItem.data.meeting.contact,
                        onSaved(meeting: Meeting) {
                            const updatedScheduledItem = new ScheduledItem({ type: ScheduleItemType.Meeting, meeting: meeting });
                            onSubmit?.(updatedScheduledItem);
                        },
                        onCancel
                    };
                case ScheduleItemType.OpenSlot:
                    throw new Error('Slots cannot be edited');
                case ScheduleItemType.ResearchReachOut:
                    return {
                        ideaId: ideaId,
                        itemKey: {
                            researchId: scheduledItem.data.researchReachOut.research.id,
                            reachOutId: scheduledItem.data.researchReachOut.id
                        },
                        person: scheduledItem.data.researchReachOut.contact,
                        onSaved(reachOut: ResearchReachOut) {
                            const updatedScheduledItem = new ScheduledItem({ type: ScheduleItemType.ResearchReachOut, researchReachOut: reachOut });
                            onSubmit?.(updatedScheduledItem);
                        },
                        onCancel
                    };
            }
        } else if (scheduledItemType) {
            switch (scheduledItemType) {
                case ScheduleItemType.ReachOut:
                    return {
                        ideaId: ideaId,
                        initialData: initialData
                            ? {
                                  date: initialData.start
                              }
                            : undefined,
                        onSaved(reachOut: ReachOut) {
                            const newScheduledItem = new ScheduledItem({ type: ScheduleItemType.ReachOut, reachOut: reachOut });
                            onSubmit?.(newScheduledItem);
                        },
                        onCancel
                    };
                case ScheduleItemType.Meeting:
                    return {
                        ideaId: ideaId,
                        initialData: initialData
                            ? {
                                  startTime: initialData.start,
                                  endTime: initialData.end
                              }
                            : undefined,
                        onSaved(meeting: Meeting) {
                            const newScheduledItem = new ScheduledItem({ type: ScheduleItemType.Meeting, meeting: meeting });
                            onSubmit?.(newScheduledItem);
                        },
                        onCancel,
                        initialResearchId: newItemAdditionalProps?.['initialResearchId']
                    };
                case ScheduleItemType.OpenSlot:
                    throw new Error('Slots cannot be created');
                case ScheduleItemType.ResearchReachOut:
                    throw new Error('Invites cannot be created');
            }
        } else throw new Error('Unexpected SchedulerEventForm state');
    }

    const ScheduledItemDialogComponent = getScheduledItemDialogForType(scheduledItemType);

    return <ScheduledItemDialogComponent {...(getDialogParams() as any)} />;
}

function getScheduledItemDialogForType(scheduledItemType: ScheduleItemType) {
    switch (scheduledItemType) {
        case ScheduleItemType.ReachOut:
            return ReachOutEventDialog;
        case ScheduleItemType.Meeting:
            return MeetingEventDialog;
        case ScheduleItemType.OpenSlot:
            throw new Error('Slot dialog is not supported');
        case ScheduleItemType.ResearchReachOut:
            return ResearchReachOutEventDialog;
    }
}
