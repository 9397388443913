import { Button } from '@progress/kendo-react-buttons';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as RightArrowIcon } from '../../icons/arrow-right.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { combineClassNames } from '../../services/common';
import { H2 } from './typography';

export default function Panel({
    title,
    onEdit,
    children,
    footer,
    editBtnText,
    editBtnSize,
    invalid,
    warning,
    customAction
}: {
    title?: ReactNode;
    onEdit?: Function;
    children: ReactNode;
    footer?: ReactNode;
    invalid?: boolean;
    warning?: boolean;
    editBtnSize?: 'small' | 'medium' | 'large';
    editBtnText?: string;
    customAction?: ReactNode;
}) {
    return (
        <div className={combineClassNames('k-icp-panel', invalid ? 'k-invalid' : warning ? 'k-warning' : undefined)}>
            {title && (
                <div className="k-icp-panel-section k-icp-panel-separated-section k-icp-panel-header-section">
                    <H2 className="k-icp-font-weight-medium">{title}</H2>
                    {customAction
                        ? customAction
                        : onEdit && (
                              <Button size={editBtnSize} fillMode="flat" className={editBtnText ? "!k-min-w-0" : 'k-icp-svg-icon-button'} onClick={() => onEdit()}>
                                  {editBtnText ? editBtnText : <EditIcon className="k-icp-icon" />}
                              </Button>
                          )}
                </div>
            )}
            {children}
            {footer && <div className="k-icp-panel-section k-icp-panel-footer-section">{footer}</div>}
        </div>
    );
}

export const PanelLink = ({ to, text, standardLink = false }: { to: string; text: string; standardLink?: boolean }) => {
    return (
        <LinkComponent to={to} standardLink={standardLink}>
            <span className="k-input k-input-md k-button-clear !k-py-1">{text}</span>
            <RightArrowIcon className="k-icp-icon k-icp-icon-size-4" />
        </LinkComponent>
    );
};

const LinkComponent = ({ children, to, standardLink }: { children: React.ReactNode; to: string; standardLink: boolean }) =>
    standardLink ? (
        <a
            href={to}
            target="_blank"
            rel="noreferrer"
            className="k-icp-panel-section k-align-items-center k-justify-content-between k-icp-panel-separated-section"
        >
            {children}
        </a>
    ) : (
        <Link to={to} className="k-icp-panel-section k-align-items-center k-justify-content-between k-icp-panel-separated-section">
            {children}
        </Link>
    );
