import { StackLayout } from '@progress/kendo-react-layout';
import Panel, { PanelLink } from '../../components/ui/panel';
import { H1 } from '../../components/ui/typography';
import { appConfig } from '../../config';
import rocketVersionIllustrationUrl from '../../images/version-rocket-illustration.svg';

export const AboutPage = () => {
    return (
        <div className="page-content-section">
            <H1 className="heading-row">About Icanpreneur Platform</H1>
            <StackLayout className="page-content-middle k-gap-6 k-text-center" orientation="vertical">
                <img src={rocketVersionIllustrationUrl} width="202" height="201" alt="About page" className="k-align-self-center responsive-image" />
                <span>Version {appConfig.appVersion}</span>
                <Panel>
                    <PanelLink to="/legal/terms-of-use" text="Terms of Use" standardLink />
                    <PanelLink to="/legal/privacy-policy" text="Privacy Policy" standardLink />
                    <PanelLink to="/legal/cookie-policy" text="Cookie Policy" standardLink />
                </Panel>
                <span className="k-icp-subtle-text k-fs-sm">Copyright © {new Date().getFullYear()}, icanpreneur. All Rights Reserved.</span>
            </StackLayout>
        </div>
    );
};
