import { ComponentType, createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BoxItem, BoxType, canvasService } from '../../services/canvasService';
import { RealTimeUpdateCanvasItemEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';

export type CanvasItemsContextValue = { itemsPerBox?: Partial<Record<BoxType, BoxItem[]>>; requireBoxItems: (box: BoxType) => void };
const defaultCanvasItemsContextValue: CanvasItemsContextValue = {
    requireBoxItems() {
        throw new Error('Use in CanvasItemsZone or EnsureCanvasItemsZone or wrap the component type with canvasItemsZoneDependent');
    }
};
const CanvasItemsContext = createContext<CanvasItemsContextValue>(defaultCanvasItemsContextValue);

export function CanvasItemsZone({ children }: { children?: ReactNode }) {
    const { ideaId } = useParams();
    const requestsInProgress = useRef<Partial<Record<BoxType, boolean>>>({});

    const setBoxItems = useCallback((box: BoxType, items: BoxItem[]) => setItemsPerBox(i => ({ ...i, [box]: items })), []);

    const [itemsPerBox, setItemsPerBox] = useState<Partial<Record<BoxType, BoxItem[]>>>({});
    const canvasBoxContextValue = useMemo<CanvasItemsContextValue>(
        () => ({
            itemsPerBox: itemsPerBox,
            requireBoxItems(box: BoxType) {
                if (itemsPerBox[box] || !ideaId || requestsInProgress.current[box]) return;

                requestsInProgress.current[box] = true;
                canvasService
                    .getBoxItems(ideaId, box)
                    .then(itemsInbox => setBoxItems(box, itemsInbox))
                    .finally(() => (requestsInProgress.current[box] = false));
            }
        }),
        [itemsPerBox, ideaId, setBoxItems]
    );

    useEffect(() => {
        if (!ideaId && itemsPerBox) setItemsPerBox({});
    }, [ideaId, itemsPerBox]);

    useEffect(() => {
        if (!ideaId) return;

        const refreshItems = (e: RealTimeUpdateCanvasItemEventData) => {
            if (!itemsPerBox[e.box]) return;
            canvasService.getBoxItems(ideaId, e.box).then(itemsInbox => setBoxItems(e.box, itemsInbox));
        };

        const removeItem = (e: RealTimeUpdateCanvasItemEventData) => {
            if (!itemsPerBox[e.box]) return;
            setItemsPerBox(itemsPerBox => {
                if (!itemsPerBox[e.box]) return itemsPerBox;

                return { ...itemsPerBox, [e.box]: itemsPerBox[e.box]!.filter(i => i.id !== e.itemId) };
            });
        };

        realTimeUpdatesEventHub.addEventListener('idea', 'itemAdd', refreshItems);
        realTimeUpdatesEventHub.addEventListener('idea', 'itemRestore', refreshItems);
        realTimeUpdatesEventHub.addEventListener('idea', 'itemUpdate', refreshItems);
        realTimeUpdatesEventHub.addEventListener('idea', 'itemDelete', removeItem);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('idea', 'itemAdd', refreshItems);
            realTimeUpdatesEventHub.removeEventListener('idea', 'itemRestore', refreshItems);
            realTimeUpdatesEventHub.removeEventListener('idea', 'itemUpdate', refreshItems);
            realTimeUpdatesEventHub.removeEventListener('idea', 'itemDelete', removeItem);
        };
    }, [itemsPerBox, ideaId, setBoxItems]);

    return <CanvasItemsContext.Provider value={canvasBoxContextValue}>{children}</CanvasItemsContext.Provider>;
}

export function EnsureCanvasItemsZone({ children }: { children?: ReactElement<any, any> | null }) {
    const canvasItemsInZone = useCanvasItemsInZone();
    if (canvasItemsInZone === defaultCanvasItemsContextValue) {
        return <CanvasItemsZone>{children}</CanvasItemsZone>;
    }

    return children ?? null;
}

export function canvasItemsZoneDependent<TParams>(CanvasItemsZoneDependentComponent: ComponentType<TParams>): ComponentType<TParams & JSX.IntrinsicAttributes> {
    return function(params: TParams & JSX.IntrinsicAttributes) {
        return (
            <EnsureCanvasItemsZone>
                <CanvasItemsZoneDependentComponent {...params} />
            </EnsureCanvasItemsZone>
        );
    };
}

export function useCanvasItemsInZone() {
    return useContext(CanvasItemsContext);
}

export function useCanvasBoxItemsInZone(box: BoxType) {
    const { itemsPerBox } = useCanvasItemsInZone();
    const itemsInBox = itemsPerBox?.[box];

    return itemsInBox;
}

export function useRequireCanvasBoxItemsInZone(box?: BoxType) {
    const { itemsPerBox, requireBoxItems } = useCanvasItemsInZone();
    const requireBoxItemsRef = useRef(requireBoxItems);
    requireBoxItemsRef.current = requireBoxItems;

    useEffect(() => {
        if (!box) return;

        requireBoxItemsRef.current(box);
    }, [box]);

    if (!box) return undefined;

    return itemsPerBox?.[box];
}
