import { useMemo, useState } from 'react';
import { InsightsFromCatalogEditor } from '../../components/interview/insights/insightsCatalog';
import { QuotesPresentation } from '../../components/interview/interviewItemQuotes';
import { InterviewEmptyColumnView } from '../../components/interview/interviewModalLayout';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { useInsightsCatalogContextFromInsights, useInterviewInsightsCatalog, useMergedInsightsContext } from '../../hooks/insightsHooks';
import { useInsightsCatalogContextFromResearch, useResearchInsights, useResearchInterviewQuotes } from '../../hooks/researchHooks';
import { useResearchParams } from '../../hooks/routerHooks';
import noInsightsImagesUrl from '../../images/no-insights-illustration.svg';
import { InterviewType } from '../../services/interviewsService';
import { InterviewQuoteType, ProblemValidationResearch } from '../../services/researchService';
import { useTabbedLayoutData } from '../layouts/tabbedLayout';
import { ResearchInterviewLayout } from './researchDetailsPage';

export function ResearchInsightsPage() {
    const { ideaId, researchId } = useResearchParams();
    const researchInsights = useResearchInsights(ideaId, researchId);
    const insightsCatalog = useInterviewInsightsCatalog(InterviewType.ProblemDiscoveryInterview);
    const quotes = useResearchInterviewQuotes(ideaId, researchId, InterviewQuoteType.Insight);
    const research = useTabbedLayoutData<ProblemValidationResearch | null | undefined>();
    const [researchContextProperties] = useInsightsCatalogContextFromResearch(ideaId, insightsCatalog?.context, research);
    const insightsContextProperties = useInsightsCatalogContextFromInsights(insightsCatalog?.context, researchInsights);
    const insightsCatalogContext = useMergedInsightsContext([insightsContextProperties, researchContextProperties]);

    const [selectedQuoteId, setSelectedQuoteId] = useState<number>();
    const selectedQuote = useMemo(() => (quotes && selectedQuoteId !== undefined ? quotes.find(q => q.id === selectedQuoteId) : undefined), [
        quotes,
        selectedQuoteId
    ]);

    if (!researchInsights) return <LoadingIndicator size="big" className="k-display-block -block-center" />;
    if (!researchInsights.length)
        return (
            <InterviewEmptyColumnView image={{ url: noInsightsImagesUrl, width: 64, height: 65, description: 'No insights in research' }}>
                No insights uncovered by research.
            </InterviewEmptyColumnView>
        );

    return (
        <ResearchInterviewLayout
            ideaId={ideaId}
            interviewId={selectedQuote?.interviewId}
            selectedEntryId={selectedQuote?.entry.id}
            interviewee={selectedQuote?.interviewContact}
        >
            <InsightsFromCatalogEditor
                ideaId={ideaId}
                catalog={insightsCatalog}
                context={insightsCatalogContext}
                insights={researchInsights}
                quotes={quotes}
                onQuoteClick={setSelectedQuoteId}
                selectedQuoteId={selectedQuoteId}
                readonly
                hideEmptyLayers
                quotesPresentation={QuotesPresentation.InterviewsGroups}
            />
        </ResearchInterviewLayout>
    );
}
