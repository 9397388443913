import { useEffect, useRef } from 'react';
import { BoxType, canvasService } from '../../../services/canvasService';
import { Hypothesis, HypothesisGroup, hypothesesService } from '../../../services/hypothesesService';
import { PlatformArea } from '../../../services/journeyService';
import {
    RealTimeUpdateCanvasItemEventData,
    RealTimeUpdateHypothesisEventData,
    RealTimeUpdateHypothesisUpdateEventData,
    RealTimeUpdateResearchEventData,
    realTimeUpdatesEventHub
} from '../../../services/realTimeUpdatesService';
import { TaskData, TaskLoadExtension, TaskRenderExtension, taskLoadExtensions, taskRenderExtensions } from './common';

declare module './common' {
    interface TaskData {
        researchHypotheses?: Partial<Record<number, Hypothesis[]>>;
    }
}

class ResearchCustomerProblemHypothesesTaskLoadExtension extends TaskLoadExtension {
    private areListenersAttached = false;
    private hypothesesCustomerSegmentsIds?: Set<number>;
    private hypothesesCustomerProblemsIds?: Set<number>;

    constructor(ideaId: string, loadTaskData: (updatePositionFromMarker: boolean) => Promise<void>) {
        super(ideaId, loadTaskData);

        this.onCanvasItemAddOrRestore = this.onCanvasItemAddOrRestore.bind(this);
        this.onCanvasItemDelete = this.onCanvasItemDelete.bind(this);
        this.onCanvasItemUpdate = this.onCanvasItemUpdate.bind(this);
    }

    protected isApplicableForTask(): boolean {
        if (!this.latestTaskData || !this.latestTaskData.body) return false;
        const task = this.latestTaskData.task;
        if (task.platformArea !== PlatformArea.Hypotheses) return false;
        const taskBody = this.latestTaskData.body;
        let customerSegmentsIds: Set<number> | undefined = undefined;
        let customerProblemsIds: Set<number> | undefined = undefined;
        for (const taskPage of taskBody.pages) {
            if (!taskPage.editor || (taskPage.editor.type !== 'problem-hypotheses' && taskPage.editor.type !== 'hypotheses-likelihood')) continue;
            if (!customerSegmentsIds) customerSegmentsIds = new Set();
            if (!customerProblemsIds) customerProblemsIds = new Set();

            customerSegmentsIds.add(taskPage.editor.params.customerSegmentId);
            customerProblemsIds.add(taskPage.editor.params.customerProblemId);
        }

        if (!customerSegmentsIds || !customerProblemsIds) {
            return false;
        }

        this.hypothesesCustomerSegmentsIds = customerSegmentsIds;
        this.hypothesesCustomerProblemsIds = customerProblemsIds;

        return true;
    }

    protected onApplicableTaskDataChanged(): void {
        if (this.areListenersAttached) return;

        realTimeUpdatesEventHub.addEventListener('idea', 'itemAdd', this.onCanvasItemAddOrRestore);
        realTimeUpdatesEventHub.addEventListener('idea', 'itemDelete', this.onCanvasItemDelete);
        realTimeUpdatesEventHub.addEventListener('idea', 'itemRestore', this.onCanvasItemAddOrRestore);
        realTimeUpdatesEventHub.addEventListener('idea', 'itemUpdate', this.onCanvasItemUpdate);
        this.areListenersAttached = true;
    }

    protected cleanup(): void {
        this.hypothesesCustomerSegmentsIds = undefined;
        this.hypothesesCustomerProblemsIds = undefined;
        if (!this.areListenersAttached) return;

        realTimeUpdatesEventHub.removeEventListener('idea', 'itemAdd', this.onCanvasItemAddOrRestore);
        realTimeUpdatesEventHub.removeEventListener('idea', 'itemDelete', this.onCanvasItemDelete);
        realTimeUpdatesEventHub.removeEventListener('idea', 'itemRestore', this.onCanvasItemAddOrRestore);
        realTimeUpdatesEventHub.removeEventListener('idea', 'itemUpdate', this.onCanvasItemUpdate);
        this.areListenersAttached = false;
    }

    private async onCanvasItemAddOrRestore(e: RealTimeUpdateCanvasItemEventData) {
        if (e.ideaId !== this.ideaId || e.box !== BoxType.Problem || !this.hypothesesCustomerSegmentsIds) return;

        const problemItem = await canvasService.getItem(e.ideaId, e.box, e.itemId);
        if (!problemItem.relatedItemIds || !problemItem.relatedItemIds.length || !this.hypothesesCustomerSegmentsIds) return;
        if (!problemItem.relatedItemIds.some(problemRelatedItemId => this.hypothesesCustomerSegmentsIds!.has(problemRelatedItemId))) return;

        this.loadTaskData(false);
    }

    private onCanvasItemDelete(e: RealTimeUpdateCanvasItemEventData) {
        if (e.ideaId !== this.ideaId || e.box !== BoxType.Problem || !this.hypothesesCustomerProblemsIds || !this.hypothesesCustomerProblemsIds.has(e.itemId))
            return;

        this.loadTaskData(false);
    }

    private async onCanvasItemUpdate(e: RealTimeUpdateCanvasItemEventData) {
        if (e.ideaId !== this.ideaId || e.box !== BoxType.Problem || !this.hypothesesCustomerSegmentsIds || !this.hypothesesCustomerProblemsIds) return;

        const problemItem = await canvasService.getItem(e.ideaId, e.box, e.itemId);
        if (!this.hypothesesCustomerSegmentsIds || !this.hypothesesCustomerProblemsIds) return;

        const isRelatedToKnownSegment = problemItem.relatedItemIds
            ? problemItem.relatedItemIds.some(problemRelatedItemId => this.hypothesesCustomerSegmentsIds!.has(problemRelatedItemId))
            : false;
        const isKnownProblem = this.hypothesesCustomerProblemsIds.has(problemItem.id);

        const wasUnrelatedFromKnownSegment = isKnownProblem && !isRelatedToKnownSegment;
        const wasRelatedToKnownSegment = !isKnownProblem && isRelatedToKnownSegment;
        if (!wasUnrelatedFromKnownSegment && !wasRelatedToKnownSegment) return;

        this.loadTaskData(false);
    }
}

taskLoadExtensions.push(ResearchCustomerProblemHypothesesTaskLoadExtension);

const useResearchCustomerProblemHypotheses: TaskRenderExtension = (ideaId, task, taskBody, taskData, setTaskData) => {
    let researchIds: undefined | number[] = undefined;
    let isHypothesesTask = false;
    const loadedResearchHypothesesRef = useRef<Partial<Record<number, Hypothesis[]>> | undefined>();
    if (task && taskBody && task.platformArea === PlatformArea.Hypotheses) {
        isHypothesesTask = true;
        for (const taskPage of taskBody.pages) {
            if (!taskPage.editor || (taskPage.editor.type !== 'problem-hypotheses' && taskPage.editor.type !== 'hypotheses-likelihood')) continue;
            if (!researchIds) researchIds = [];
            if (researchIds.includes(taskPage.editor.params.researchId)) continue;
            researchIds.push(taskPage.editor.params.researchId);
        }
        if (researchIds && researchIds.length) researchIds.sort();
        loadedResearchHypothesesRef.current = taskData.researchHypotheses;
    }

    const researchIdsRef = useRef(researchIds);
    researchIdsRef.current = researchIds;
    const researchIdsKey = researchIds ? researchIds.join('_') : '';
    useEffect(() => {
        const researchIds = researchIdsRef.current;
        if (!researchIds || !researchIds.length) return;

        (async () => {
            const researchHypotheses = await Promise.all(
                researchIds.map(researchId =>
                    hypothesesService.getAllHypotheses(ideaId, HypothesisGroup.CustomerProblem, researchId).then(hypotheses => ({ researchId, hypotheses }))
                )
            );
            const researchHypothesesMap = researchHypotheses.reduce<Record<number, Hypothesis[]>>((researchHypothesesMap, researchHypotheses) => {
                researchHypothesesMap[researchHypotheses.researchId] = researchHypotheses.hypotheses;

                return researchHypothesesMap;
            }, {});
            setTaskData(taskData => ({ ...taskData, researchHypotheses: researchHypothesesMap }));
        })();

        async function onResearchHypothesesUpdated(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId || !researchIds?.includes(e.researchId)) return;

            const researchHypotheses = await hypothesesService.getAllHypotheses(ideaId, HypothesisGroup.CustomerProblem, e.researchId);
            setTaskData(taskData => ({
                ...taskData,
                researchHypotheses: taskData.researchHypotheses
                    ? { ...taskData.researchHypotheses, [e.researchId]: researchHypotheses }
                    : { [e.researchId]: researchHypotheses }
            }));
        }

        realTimeUpdatesEventHub.addEventListener('research', 'hypothesesUpdated', onResearchHypothesesUpdated);

        return () => realTimeUpdatesEventHub.removeEventListener('research', 'hypothesesUpdated', onResearchHypothesesUpdated);
    }, [ideaId, setTaskData, researchIdsKey]);

    useEffect(() => {
        if (!isHypothesesTask) return;

        function processResearchHypothesesIncludingHypothesisId(
            loadedResearchHypotheses: Partial<Record<number, Hypothesis[]>>,
            hypothesisId: number,
            action: (researchId: number, researchHypotheses: Hypothesis[]) => boolean,
            missingAction?: (researchId: number, researchHypotheses: Hypothesis[]) => boolean
        ) {
            for (const researchIdProp in loadedResearchHypotheses) {
                if (!Object.prototype.hasOwnProperty.call(loadedResearchHypotheses, researchIdProp)) continue;
                const researchHypotheses = loadedResearchHypotheses[researchIdProp];
                if (!researchHypotheses) continue;
                const researchId = typeof researchIdProp === 'number' ? researchIdProp : parseInt(researchIdProp);
                if (researchHypotheses.some(h => h.id === hypothesisId)) {
                    if (action(researchId, researchHypotheses)) return;
                } else {
                    if (missingAction && missingAction(researchId, researchHypotheses)) return;
                }
            }
        }

        function isHypothesisLoaded(hypothesisId: number) {
            const loadedResearchHypotheses = loadedResearchHypothesesRef.current;
            if (!loadedResearchHypotheses) return false;
            let result = false;
            processResearchHypothesesIncludingHypothesisId(loadedResearchHypotheses, hypothesisId, () => {
                result = true;
                return true;
            });

            return result;
        }

        async function onHypothesisDataUpdated(e: RealTimeUpdateHypothesisEventData) {
            if (!isHypothesisLoaded(e.hypothesisId)) return;

            const updatedHypothesis = await hypothesesService.getHypothesis(ideaId, e.hypothesisGroup, e.hypothesisId);

            setTaskData(taskData => {
                const loadedResearchHypotheses = taskData.researchHypotheses;
                if (!loadedResearchHypotheses) return taskData;

                let updatedTaskData: TaskData | undefined;
                processResearchHypothesesIncludingHypothesisId(loadedResearchHypotheses, e.hypothesisId, (researchId, researchHypotheses) => {
                    updatedTaskData = updatedTaskData ?? { ...taskData };
                    updatedTaskData.researchHypotheses = {
                        ...updatedTaskData.researchHypotheses,
                        [researchId]: researchHypotheses.map(h => (h.id === e.hypothesisId ? updatedHypothesis : h))
                    };
                    return false;
                });

                return updatedTaskData ?? taskData;
            });
        }

        async function onHypothesisResearchUpdated(e: RealTimeUpdateHypothesisEventData) {
            const updatedHypothesis = await hypothesesService.getHypothesis(ideaId, e.hypothesisGroup, e.hypothesisId);
            setTaskData(taskData => {
                let updatedTaskData: TaskData | undefined;

                const loadedResearchHypotheses = taskData.researchHypotheses;
                if (!loadedResearchHypotheses) return taskData;
                processResearchHypothesesIncludingHypothesisId(
                    loadedResearchHypotheses,
                    e.hypothesisId,
                    (researchId, researchHypotheses) => {
                        // Update or remove hypothesis in research
                        const isHypothesisStillInResearch =
                            updatedHypothesis.research !== undefined && updatedHypothesis.research.some(r => r.id === researchId);
                        updatedTaskData = updatedTaskData ?? { ...taskData };
                        updatedTaskData.researchHypotheses = {
                            ...updatedTaskData.researchHypotheses,
                            [researchId]: isHypothesisStillInResearch
                                ? researchHypotheses.map(h => (h.id === e.hypothesisId ? updatedHypothesis : h))
                                : researchHypotheses.filter(h => h.id !== e.hypothesisId)
                        };
                        return false;
                    },
                    (researchId, researchHypotheses) => {
                        // Add hypothesis to research
                        if (updatedHypothesis.research && updatedHypothesis.research.some(r => r.id === researchId)) {
                            updatedTaskData = updatedTaskData ?? { ...taskData };
                            updatedTaskData.researchHypotheses = {
                                ...updatedTaskData.researchHypotheses,
                                [researchId]: [...researchHypotheses, updatedHypothesis]
                            };
                        }

                        return false;
                    }
                );

                return updatedTaskData ?? taskData;
            });
        }

        async function onHypothesisUpdated(e: RealTimeUpdateHypothesisUpdateEventData) {
            if (e.ideaId !== ideaId) return;
            if (e.researchUpdated) await onHypothesisResearchUpdated(e);
            else await onHypothesisDataUpdated(e);
        }

        async function onHypothesisDeleted(e: RealTimeUpdateHypothesisEventData) {
            if (e.ideaId !== ideaId) return;

            setTaskData(taskData => {
                const loadedResearchHypotheses = taskData.researchHypotheses;
                if (!loadedResearchHypotheses) return taskData;

                let updatedTaskData: TaskData | undefined;
                processResearchHypothesesIncludingHypothesisId(loadedResearchHypotheses, e.hypothesisId, (researchId, researchHypotheses) => {
                    updatedTaskData = updatedTaskData ?? { ...taskData };
                    updatedTaskData.researchHypotheses = {
                        ...updatedTaskData.researchHypotheses,
                        [researchId]: researchHypotheses.filter(h => h.id !== e.hypothesisId)
                    };
                    return false;
                });

                return updatedTaskData ?? taskData;
            });
        }

        async function onHypothesisRestore(e: RealTimeUpdateHypothesisEventData) {
            if (e.ideaId !== ideaId) return;
            const restoredHypothesis = await hypothesesService.getHypothesis(ideaId, e.hypothesisGroup, e.hypothesisId);
            const restoredHypothesisResearch = restoredHypothesis.research;
            if (!restoredHypothesisResearch || !restoredHypothesisResearch.length) return;
            setTaskData(taskData => {
                const loadedResearchHypotheses = taskData.researchHypotheses;
                if (!loadedResearchHypotheses) return taskData;

                let updatedTaskData: TaskData | undefined;
                for (const hypothesisResearch of restoredHypothesisResearch) {
                    const loadedHypothesesForResearch = loadedResearchHypotheses[hypothesisResearch.id];
                    if (!loadedHypothesesForResearch) continue;
                    updatedTaskData = updatedTaskData ?? { ...taskData };
                    updatedTaskData.researchHypotheses = {
                        ...updatedTaskData.researchHypotheses,
                        [hypothesisResearch.id]: [...loadedHypothesesForResearch.filter(h => h.id !== e.hypothesisId), restoredHypothesis]
                    };
                }

                return updatedTaskData ?? taskData;
            });
        }

        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisUpdate', onHypothesisUpdated);
        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisDelete', onHypothesisDeleted);
        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisRestore', onHypothesisRestore);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisUpdate', onHypothesisUpdated);
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisDelete', onHypothesisDeleted);
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisRestore', onHypothesisRestore);
        };
    }, [ideaId, isHypothesesTask, setTaskData]);
};

taskRenderExtensions.push(useResearchCustomerProblemHypotheses);
