import { hypothesisGroupLabelMap } from '../../components/hypotheses/common';
import { EmptyHypothesesView } from '../../components/hypotheses/emptyHypothesesView';
import { HypothesisGroup } from '../../services/hypothesesService';

export function FeasibilityAndViabilityHypothesesPage() {
    return (
        <EmptyHypothesesView
            hypothesesGroupName={hypothesisGroupLabelMap[HypothesisGroup.FeasibilityAndViability]}
            assumptionsDescriptions="the ability to execute your idea and its potential for success in the market"
        />
    );
}
