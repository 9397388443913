import { createContext, useContext, useEffect, useState } from 'react';
import { ConnectionFocus, RealTimeUpdatesPipe } from '../services/realTimeUpdatesService';
import { useAppSelector } from '../state/hooks';

type RealTimeUpdatesContextValue = RealTimeUpdatesPipe | undefined;

const RealTimeUpdatesContext = createContext<RealTimeUpdatesContextValue>(undefined);

export const useRealTimeUpdates = () => {
    const isAuthenticated = useAppSelector(s => s.user) !== null;
    const [realTimeUpdatesPipe, setRealTimeUpdatesPipe] = useState<RealTimeUpdatesContextValue>();

    useEffect(() => {
        if (!isAuthenticated) return;

        const realTimeUpdatesPipe = new RealTimeUpdatesPipe();
        realTimeUpdatesPipe.open();
        realTimeUpdatesPipe.focus(ConnectionFocus.NoIdeas);
        setRealTimeUpdatesPipe(realTimeUpdatesPipe);

        return () => {
            realTimeUpdatesPipe.close();
            setRealTimeUpdatesPipe(undefined);
        };
    }, [isAuthenticated]);

    return {
        ContextProvider: RealTimeUpdatesContext.Provider,
        contextValue: realTimeUpdatesPipe
    };
};

export const useRealTimeUpdatesPipe = () => useContext(RealTimeUpdatesContext);

export const useRealTimeUpdatesFocus = (focus: string) => {
    const realTimeUpdatesPipe = useRealTimeUpdatesPipe();
    useEffect(() => {
        if (!realTimeUpdatesPipe) return;

        const currentRealTimeUpdatesPipe = realTimeUpdatesPipe;
        currentRealTimeUpdatesPipe.focus(focus);

        return () => {
            currentRealTimeUpdatesPipe.focus(ConnectionFocus.NoIdeas);
        };
    }, [realTimeUpdatesPipe, focus]);
};
