import { useGlobalCanvas } from '../../../hooks/canvasHooks';
import { CanvasTaskDetail, PlatformArea } from '../../../services/journeyService';
import { RealTimeUpdateCanvasItemEventData, realTimeUpdatesEventHub } from '../../../services/realTimeUpdatesService';
import { TaskLoadExtension, TaskRenderExtension, taskLoadExtensions, taskRenderExtensions } from './common';

class CanvasTaskLoadExtension extends TaskLoadExtension {
    private areListenersAttached = false;

    constructor(ideaId: string, loadTaskData: (updatePositionFromMarker: boolean) => Promise<void>) {
        super(ideaId, loadTaskData);

        this.onCanvasItemChange = this.onCanvasItemChange.bind(this);
    }

    protected isApplicableForTask(): boolean {
        if (!this.latestTaskData) return false;
        const task = this.latestTaskData.task;

        return task.platformArea === PlatformArea.Canvas && 'boxDependencies' in task.areaDetail && task.areaDetail.boxDependencies.length > 0;
    }

    protected onApplicableTaskDataChanged(): void {
        if (this.areListenersAttached) return;

        realTimeUpdatesEventHub.addEventListener('idea', 'itemAdd', this.onCanvasItemChange);
        realTimeUpdatesEventHub.addEventListener('idea', 'itemDelete', this.onCanvasItemChange);
        realTimeUpdatesEventHub.addEventListener('idea', 'itemRestore', this.onCanvasItemChange);
        this.areListenersAttached = true;
    }

    protected cleanup(): void {
        if (!this.areListenersAttached) return;

        realTimeUpdatesEventHub.removeEventListener('idea', 'itemAdd', this.onCanvasItemChange);
        realTimeUpdatesEventHub.removeEventListener('idea', 'itemDelete', this.onCanvasItemChange);
        realTimeUpdatesEventHub.removeEventListener('idea', 'itemRestore', this.onCanvasItemChange);
        this.areListenersAttached = false;
    }

    private onCanvasItemChange(e: RealTimeUpdateCanvasItemEventData) {
        if (e.ideaId !== this.ideaId || !this.latestTaskData || !(this.latestTaskData.task.areaDetail as CanvasTaskDetail).boxDependencies.includes(e.box))
            return;

        this.loadTaskData(false);
    }
}

taskLoadExtensions.push(CanvasTaskLoadExtension);

const useCanvasTask: TaskRenderExtension = (ideaId, task) =>
    useGlobalCanvas(task ? ideaId : undefined, task === undefined || task.platformArea !== PlatformArea.Canvas);
taskRenderExtensions.push(useCanvasTask);
