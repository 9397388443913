export const composeFunctions = <T extends (...args: any) => any>(firstFunction?: T, secondFunction?: T): T | undefined => {
    if (!firstFunction && !secondFunction) return undefined;
    if (!firstFunction) return secondFunction;
    if (!secondFunction) return firstFunction;

    return ((...args: Parameters<T>) => {
        firstFunction.apply(this, args);
        return secondFunction.apply(this, args);
    }) as T;
};

export const debounce = <T extends (...args: any) => any>(callback: T, delay: number): ((...args: Parameters<T>) => void) => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    return function(...args: Parameters<T>) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            callback(...args);
        }, delay);
    };
};
