import { ErrorWithOperationDisplayName } from './common';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';

class PlatformService extends HttpServiceBase {
    constructor() {
        super('/api/platform');
    }

    @ErrorWithOperationDisplayName('Invite user to the platform')
    inviteNewUser(emailAddress: string): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: '/invite-to-platform',
            method: RequestMethod.POST,
            body: { emailAddress }
        });
    }
}

export const platformService = new PlatformService();
