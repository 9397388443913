import { StackLayout } from '@progress/kendo-react-layout';
import { Fragment, ReactElement, createContext, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useAsRef } from '../../../hooks/commonHooks';
import { ReactComponent as CoveredEntryIcon } from '../../../icons/check-circle-closed.svg';
import { ReactComponent as MarkAsIrrelevantActionIcon } from '../../../icons/irrelevant-circle.svg';
import { ReactComponent as NotAskedEntryIcon } from '../../../icons/minus-circle.svg';
import { ReactComponent as IrrelevantEntryIcon } from '../../../icons/x-circle.svg';
import emptyInterviewConversationIllustrationUrl from '../../../images/empty-interview-conversation-illustration.svg';
import { AsyncOperationsQueue, AsyncOperationsTracker, combineClassNames, countWhere, isMacOs } from '../../../services/common';
import { ReducedPerson } from '../../../services/contactsService';
import { ScrollCancellationSource, domService } from '../../../services/domService';
import { InterviewEntry, InterviewEntryStatus, InterviewSection } from '../../../services/interviewsService';
import { ValidationScope, ValidationScopeHandle, ValidationSubScope, ValidationSubScopeHandle, ValidationUnit } from '../../common/validation';
import { ReactComponent as ClockIcon } from '../../../icons/clock.svg';
import { requiredValidator } from '../../ui/inputs';
import LoadingIndicator from '../../ui/loadingIndicator';
import {
    InterviewEntryEditorView,
    InterviewEntryEditorViewHandle,
    InterviewEntryEditorViewProps,
    InterviewEntryInsert,
    customQuestionTips
} from '../entries/interviewEntryEditorView';
import {
    InterviewItemCard,
    InterviewItemCardIconData,
    InterviewItemEditorViewButton,
    InterviewItemGroup,
    InterviewItemGroupHeader,
    InterviewItemGroupTitle,
    InterviewItemGroupsList,
    useInterviewItemCardsList
} from '../interviewItem';
import { InterviewEmptyColumnView } from '../interviewModalLayout';
import { InterviewMessageBox, InterviewMessageBoxHandle } from './interviewMessageLog';
import { InterviewScriptEntryLabelView } from './interviewScriptEntryLabelView';

export function InterviewSectionsList({
    interviewSections,
    selectedEntryId,
    onEntrySelect
}: {
    interviewSections: InterviewSection[];
    selectedEntryId?: number;
    onEntrySelect?: (entryId: number) => void;
}) {
    const setEntryCardRef = useInterviewItemCardsList(true, selectedEntryId);

    return (
        <InterviewItemGroupsList>
            {interviewSections.map(interviewSection => {
                const visibleEntriesInSection = interviewSection.entries.filter(e => !e.hidden);
                const entriesWithStatusCount = countWhere(visibleEntriesInSection, e => e.status);

                return (
                    <InterviewItemGroup
                        key={interviewSection.id}
                        header={
                            <InterviewItemGroupHeader
                                key="header"
                                entriesWithStatusCount={entriesWithStatusCount}
                                totalEntriesCount={visibleEntriesInSection.length}
                            >
                                {interviewSection.title}
                            </InterviewItemGroupHeader>
                        }
                    >
                        {visibleEntriesInSection.map(entry => {
                            let entryTitle = entry.question.revised ? entry.question.revision : entry.question.content;
                            if (!entryTitle && entry.question.custom) entryTitle = entry.answer.revised ? entry.answer.revision : entry.answer.content;

                            if (!entryTitle) return null;

                            const iconData = resolveInterviewEntryCardIconData(entry.status);

                            return (
                                <InterviewItemCard
                                    key={entry.id}
                                    ref={r => setEntryCardRef(r, entry.id)}
                                    icon={iconData?.icon}
                                    iconClassName={iconData?.className}
                                    selected={entry.id === selectedEntryId}
                                    complete={!!entry.status}
                                    onClick={onEntrySelect && (() => onEntrySelect(entry.id))}
                                >
                                    {entryTitle}
                                </InterviewItemCard>
                            );
                        })}
                    </InterviewItemGroup>
                );
            })}
        </InterviewItemGroupsList>
    );
}

function resolveInterviewEntryCardIconData(status: InterviewEntryStatus | null | undefined): InterviewItemCardIconData | undefined {
    if (!status)
        return {
            className: 'k-text-secondary'
        };

    switch (status) {
        case InterviewEntryStatus.Covered:
            return {
                icon: CoveredEntryIcon,
                className: 'k-text-success'
            };
        case InterviewEntryStatus.NotCovered:
            return {
                icon: NotAskedEntryIcon
            };
        case InterviewEntryStatus.Irrelevant:
            return {
                icon: IrrelevantEntryIcon,
                className: 'k-text-warning'
            };
    }
}

type OverriddenInterviewEntryData = {
    question?: InterviewEntryOverriddenValue<string | null | undefined>;
    answer?: InterviewEntryOverriddenValue<string | null | undefined>;
    status?: InterviewEntryOverriddenValue<InterviewEntryStatus | null | undefined>;
    deleted?: boolean;
};
type InterviewEntryData = { question?: string | null; answer?: string | null; status?: InterviewEntryStatus | null };
type InterviewEntryOverriddenValue<TValue> = { value: TValue };
export type InterviewSectionsEntriesEditListHandle = { commit: () => Promise<boolean>; awaitPendingOperations: () => Promise<unknown> };
export type InterviewSectionsEntriesEditListProps = {
    interviewSections: InterviewSection[];
    selectedEntryId?: number;
    onEntrySelect?: (entryId: number) => void;
    onInsertAfter?: (sectionId: number, previousEntryId: number | undefined) => Promise<void>;
    onDelete?: (sectionId: number, entryId: number, explicit?: boolean) => Promise<void>;
    onEntryFocus?: (sectionId: number, entryId: number) => void;
    onQuestionChange?: (sectionId: number, entryId: number, question: string) => Promise<void>;
    onAnswerChange?: (sectionId: number, entryId: number, question: string) => Promise<void>;
    onStatusChange?: (sectionId: number, entryId: number, newStatus: InterviewEntryStatus | null) => Promise<void>;
    questionPlaceholder?: string;
    answerPlaceholder?: string;
    allowMarkAsNotAsked?: boolean;
    validateEntries?: boolean;
    isReadOnly?: boolean;
};
export const InterviewSectionsEntriesEditList = forwardRef<InterviewSectionsEntriesEditListHandle, InterviewSectionsEntriesEditListProps>(
    function InterviewSectionsEntriesEditList(
        {
            interviewSections,
            selectedEntryId,
            onEntrySelect,
            onInsertAfter,
            onDelete,
            onEntryFocus,
            onQuestionChange,
            onAnswerChange,
            onStatusChange,
            questionPlaceholder,
            answerPlaceholder,
            allowMarkAsNotAsked,
            validateEntries,
            isReadOnly
        },
        ref
    ) {
        const editorsMapRef = useRef<Map<number, InterviewEntryEditorHandle>>(new Map());
        const editorsOperationsQueuesRef = useRef<Partial<Record<number, AsyncOperationsQueue>>>({});
        const [overriddenEntries, setOverriddenEntries] = useState<Partial<Record<number, OverriddenInterviewEntryData>>>({});
        const operationsTracker = useRef(new AsyncOperationsTracker());
        const entriesValidationScopeRef = useRef<ValidationScopeHandle>(null);

        const onEntrySelectRef = useAsRef(onEntrySelect);
        useImperativeHandle(
            ref,
            () => ({
                async commit() {
                    await operationsTracker.current.await();
                    if (entriesValidationScopeRef.current) {
                        entriesValidationScopeRef.current.validate();

                        if (!entriesValidationScopeRef.current.isValid) {
                            const firstInvalidEntry = Array.from(editorsMapRef.current.entries()).find(([, editorRef]) => !editorRef.isValid);
                            if (firstInvalidEntry) onEntrySelectRef.current?.(firstInvalidEntry[0]);
                        }

                        return entriesValidationScopeRef.current.isValid;
                    }

                    return true;
                },
                awaitPendingOperations() {
                    return operationsTracker.current.await();
                }
            }),
            [onEntrySelectRef]
        );

        useEffect(() => {
            if (selectedEntryId === undefined) return;

            const selectedEntryEditorRef = editorsMapRef.current.get(selectedEntryId);
            if (!selectedEntryEditorRef || !selectedEntryEditorRef.element) return;

            const scrollCancelation: ScrollCancellationSource = {};
            domService.scrollVerticallyIntoViewIfNeeded(selectedEntryEditorRef.element, undefined, scrollCancelation);

            return () => {
                scrollCancelation.cancel = true;
            };
        }, [selectedEntryId]);

        function queueOperation<TResult>(entryId: number, operation: () => Promise<TResult>): Promise<TResult> {
            let queue = editorsOperationsQueuesRef.current[entryId];
            if (!queue) queue = editorsOperationsQueuesRef.current[entryId] = new AsyncOperationsQueue();

            return queue.execute(operation);
        }

        function overrideEntryField<TFieldName extends keyof OverriddenInterviewEntryData>(
            entryId: number,
            fieldName: TFieldName,
            value: OverriddenInterviewEntryData[TFieldName]
        ) {
            setOverriddenEntries(oe => {
                const entryOverride = oe[entryId];

                return {
                    ...oe,
                    [entryId]: {
                        ...entryOverride,
                        [fieldName]: value
                    }
                };
            });
        }

        function clearOverriddenEntryFiled<TFieldName extends keyof OverriddenInterviewEntryData>(entryId: number, fieldName: TFieldName) {
            setOverriddenEntries(oe => {
                const entryOverride = oe[entryId];
                if (!entryOverride || !(fieldName in entryOverride)) return oe;

                const updatedEntryOverride = { ...entryOverride };
                delete updatedEntryOverride[fieldName];

                if (Object.keys(updatedEntryOverride).length)
                    return {
                        ...oe,
                        [entryId]: updatedEntryOverride
                    };

                const updatedOverriddenEntries = { ...oe };
                delete updatedOverriddenEntries[entryId];

                return updatedOverriddenEntries;
            });
        }

        const onDeleteRef = useAsRef(onDelete);
        async function triggerOnDelete(sectionId: number, entryId: number, explicit?: boolean) {
            overrideEntryField(entryId, 'deleted', true);
            await queueOperation(entryId, () => operationsTracker.current.track(onDeleteRef.current?.(sectionId, entryId, explicit) ?? Promise.resolve()));
            clearOverriddenEntryFiled(entryId, 'deleted');
        }

        const onStatusChangeRef = useAsRef(onStatusChange);
        async function triggerOnStatusChange(sectionId: number, entryId: number, newStatus: InterviewEntryStatus | null) {
            overrideEntryField(entryId, 'status', { value: newStatus });
            await queueOperation(entryId, () =>
                operationsTracker.current.track(onStatusChangeRef.current?.(sectionId, entryId, newStatus) ?? Promise.resolve())
            );
            clearOverriddenEntryFiled(entryId, 'status');
        }

        const onQuestionChangeRef = useAsRef(onQuestionChange);
        async function triggerOnQuestionChange(sectionId: number, entryId: number, question: string, isValid: boolean) {
            overrideEntryField(entryId, 'question', { value: question });
            if (!isValid) return;

            await queueOperation(entryId, () =>
                operationsTracker.current.track(onQuestionChangeRef.current?.(sectionId, entryId, question) ?? Promise.resolve())
            );
            clearOverriddenEntryFiled(entryId, 'question');
        }

        const onAnswerChangeRef = useAsRef(onAnswerChange);
        async function triggerOnAnswerChange(sectionId: number, entryId: number, answer: string, isValid: boolean) {
            overrideEntryField(entryId, 'answer', { value: answer });
            if (!isValid) return;
            await queueOperation(entryId, () => operationsTracker.current.track(onAnswerChangeRef.current?.(sectionId, entryId, answer) ?? Promise.resolve()));
            clearOverriddenEntryFiled(entryId, 'answer');
        }

        function getEntryData(entry: InterviewEntry): InterviewEntryData | undefined {
            const entryOverride = overriddenEntries[entry.id];
            if (entryOverride && entryOverride.deleted) return undefined;

            return {
                answer: entryOverride?.answer ? entryOverride.answer.value : entry.answer.revised ? entry.answer.revision : entry.answer.content,
                question: entryOverride?.question ? entryOverride.question.value : entry.question.revised ? entry.question.revision : entry.question.content,
                status: entryOverride?.status ? entryOverride.status.value : entry.status
            };
        }

        function selectNextEntryWithNoStatus(sectionId: number, entryId: number) {
            const curSectionIdx = interviewSections.findIndex(s => s.id === sectionId);
            const curSection = interviewSections[curSectionIdx];
            const curIdx = curSection.entries.findIndex(e => e.id === entryId);
            const hasEmptyEntries = interviewSections.some(section => section.entries.some(e => !e.hidden && e.status === null && e.id !== entryId));

            // get next entry id in the same section if exists or search in the next section
            const nextEntry = curSection.entries.find((e, idx) => !e.hidden && (!hasEmptyEntries || e.status === null) && idx > curIdx);
            if (nextEntry) {
                onEntrySelect?.(nextEntry.id);
                return;
            }

            if (!hasEmptyEntries && curSectionIdx === interviewSections.length - 1) return;

            let idx = (curSectionIdx + 1) % interviewSections.length;
            //prevent potentially infinite loop due to some hidden bug just in case
            let attempt = 0;
            while (attempt <= interviewSections.length && (hasEmptyEntries || idx < interviewSections.length)) {
                const curSection = interviewSections[idx];
                const nextEntry = curSection.entries.find(e => !e.hidden && (!hasEmptyEntries || e.status === null));
                if (nextEntry) {
                    onEntrySelect?.(nextEntry.id);
                    return;
                }

                idx = (idx + 1) % interviewSections.length;
            }
        }

        return (
            <ValidationScope ref={entriesValidationScopeRef}>
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                    {interviewSections.map(interviewSection => (
                        <StackLayout key={interviewSection.id} orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1">
                            <InterviewItemSectionHeader title={interviewSection.title} sectionTime={interviewSection.targetDurationInMinutes} />
                            <InterviewEntryInsert
                                onInsert={onInsertAfter && (() => operationsTracker.current.track(onInsertAfter(interviewSection.id, undefined)))}
                                hidden={isReadOnly}
                            />
                            {interviewSection.entries
                                .filter(e => !e.hidden)
                                .map(entry => {
                                    const entryData = getEntryData(entry);
                                    if (!entryData) return null;

                                    return (
                                        <Fragment key={entry.id}>
                                            <InterviewEntryEditor
                                                ref={r => {
                                                    if (r) editorsMapRef.current.set(entry.id, r);
                                                    else editorsMapRef.current.delete(entry.id);
                                                }}
                                                entry={entry}
                                                entryData={entryData}
                                                selected={entry.id === selectedEntryId}
                                                questionPlaceholder={questionPlaceholder}
                                                answerPlaceholder={answerPlaceholder}
                                                allowMarkAsNotAsked={allowMarkAsNotAsked}
                                                onStatusChange={(status, isShortcut) => {
                                                    //Not awaiting intentionally, not to be laggy
                                                    triggerOnStatusChange(interviewSection.id, entry.id, status);
                                                    if (isShortcut) {
                                                        selectNextEntryWithNoStatus(interviewSection.id, entry.id);
                                                    }
                                                }}
                                                onQuestionChange={
                                                    entry.question.custom
                                                        ? (question, isValid) => triggerOnQuestionChange(interviewSection.id, entry.id, question, isValid)
                                                        : undefined
                                                }
                                                onAnswerChange={(answer, isValid) => triggerOnAnswerChange(interviewSection.id, entry.id, answer, isValid)}
                                                onDelete={
                                                    entry.question.custom ? explicit => triggerOnDelete(interviewSection.id, entry.id, explicit) : undefined
                                                }
                                                onFocus={onEntryFocus && (() => onEntryFocus(interviewSection.id, entry.id))}
                                                validate={validateEntries}
                                                readonly={isReadOnly}
                                            />
                                            <InterviewEntryInsert
                                                onInsert={
                                                    onInsertAfter && (() => operationsTracker.current.track(onInsertAfter(interviewSection.id, entry.id)))
                                                }
                                                hidden={isReadOnly}
                                            />
                                        </Fragment>
                                    );
                                })}
                        </StackLayout>
                    ))}
                </StackLayout>
            </ValidationScope>
        );
    }
);

function InterviewItemSectionHeader({ title, sectionTime }: { title: string; sectionTime: number }) {
    return (
        <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-2">
            <strong className="k-fs-lg">{title}</strong>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1.5">
                <ClockIcon className="k-icp-icon k-icp-icon-size-4" />
                <span className="k-fs-sm">{sectionTime} min</span>
            </StackLayout>
        </StackLayout>
    );
}

type InterviewEntryEditorProps = {
    entry: InterviewEntry;
    entryData: InterviewEntryData;
    allowMarkAsNotAsked?: boolean;
    onStatusChange?: (status: InterviewEntryStatus | null, isShortcut?: boolean) => void;
    validate?: boolean;
    onDelete?: (explicit: boolean) => void;
} & Pick<
    InterviewEntryEditorViewProps,
    'questionPlaceholder' | 'answerPlaceholder' | 'selected' | 'onQuestionChange' | 'onAnswerChange' | 'onFocus' | 'readonly'
>;
type InterviewEntryEditorHandle = InterviewEntryEditorViewHandle & { isValid: boolean };
const entryStatusValidator = requiredValidator('Status');
const InterviewEntryEditor = forwardRef<InterviewEntryEditorHandle, InterviewEntryEditorProps>(function InterviewEntryEditor(
    {
        entry,
        entryData,
        allowMarkAsNotAsked,
        questionPlaceholder,
        answerPlaceholder,
        selected,
        onStatusChange,
        onQuestionChange,
        onAnswerChange,
        onDelete,
        onFocus,
        validate,
        readonly
    },
    ref
) {
    const editorViewRef = useRef<InterviewEntryEditorViewHandle>(null);
    const validationEntryScopeRef = useRef<ValidationSubScopeHandle>(null);
    const validationOnStatusChangeRef = useRef<
        (value: InterviewEntryStatus | null | undefined, suppressEnforceShowErrorMessage?: boolean | undefined) => boolean
    >();

    if (!validate && validationOnStatusChangeRef.current) validationOnStatusChangeRef.current = undefined;

    useImperativeHandle(
        ref,
        () => ({
            get answerEditor() {
                if (!editorViewRef.current) return null;
                return editorViewRef.current.answerEditor;
            },
            get element() {
                if (!editorViewRef.current) return null;
                return editorViewRef.current.element;
            },
            get questionEditor() {
                if (!editorViewRef.current) return null;
                return editorViewRef.current.questionEditor;
            },
            get isValid() {
                return validationEntryScopeRef.current?.isValid ?? true;
            }
        }),
        []
    );

    const handleStatusChange = useCallback(
        (status: InterviewEntryStatus | null, isShortcut?: boolean) => {
            if (validationOnStatusChangeRef.current) validationOnStatusChangeRef.current(status, true);
            if (onStatusChange) onStatusChange(status, isShortcut);
        },
        [onStatusChange]
    );

    useEffect(() => {
        if (!selected) return;

        const handleKeyDown = (e: KeyboardEvent) => {
            if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'Enter') {
                handleStatusChange(entryData.status === InterviewEntryStatus.Irrelevant ? null : InterviewEntryStatus.Irrelevant, true);
            } else if (allowMarkAsNotAsked && (e.ctrlKey || e.metaKey) && e.altKey && e.key === 'Enter') {
                handleStatusChange(entryData.status === InterviewEntryStatus.NotCovered ? null : InterviewEntryStatus.NotCovered, true);
            } else if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
                handleStatusChange(entryData.status === InterviewEntryStatus.Covered ? null : InterviewEntryStatus.Covered, true);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [allowMarkAsNotAsked, entryData.status, handleStatusChange, selected]);

    const entryActionElements: ReactElement[] | undefined =
        entry.question.custom || readonly
            ? undefined
            : [
                  <InterviewItemEditorViewButton
                      icon={CoveredEntryIcon}
                      themeColor="success"
                      shortcutIconsTypes={selected ? (isMacOs ? ['cmd', 'return'] : ['ctrl', 'enter']) : undefined}
                      selected={entryData.status === InterviewEntryStatus.Covered}
                      onClick={() => handleStatusChange(entryData.status === InterviewEntryStatus.Covered ? null : InterviewEntryStatus.Covered)}
                  >
                      {interviewEntryStatusLabelMap[InterviewEntryStatus.Covered].toString()}
                  </InterviewItemEditorViewButton>,
                  <InterviewItemEditorViewButton
                      icon={MarkAsIrrelevantActionIcon}
                      themeColor="warning"
                      shortcutIconsTypes={selected ? (isMacOs ? ['cmd', 'shift', 'return'] : ['ctrl', 'shift', 'enter']) : undefined}
                      selected={entryData.status === InterviewEntryStatus.Irrelevant}
                      onClick={() => handleStatusChange(entryData.status === InterviewEntryStatus.Irrelevant ? null : InterviewEntryStatus.Irrelevant)}
                  >
                      {interviewEntryStatusLabelMap[InterviewEntryStatus.Irrelevant].toString()}
                  </InterviewItemEditorViewButton>
              ];

    if (entryActionElements && allowMarkAsNotAsked)
        entryActionElements.push(
            <InterviewItemEditorViewButton
                icon={NotAskedEntryIcon}
                selected={entryData.status === InterviewEntryStatus.NotCovered}
                shortcutIconsTypes={selected ? (isMacOs ? ['cmd', 'option', 'return'] : ['ctrl', 'alt', 'enter']) : undefined}
                onClick={() => handleStatusChange(entryData.status === InterviewEntryStatus.NotCovered ? null : InterviewEntryStatus.NotCovered)}
            >
                {interviewEntryStatusLabelMap[InterviewEntryStatus.NotCovered].toString()}
            </InterviewItemEditorViewButton>
        );

    function onBlur() {
        if (readonly) return;

        const editorView = editorViewRef.current;
        if (!editorView) return;
        if (entry.question.custom) {
            if (!editorView.answerEditor?.value && !editorView.questionEditor?.value) {
                onDelete?.(false);
                return;
            }
        }

        if (!entryData.status && (entry.question.custom || editorView.answerEditor?.value)) {
            handleStatusChange(InterviewEntryStatus.Covered);
        }
    }

    const editorViewProps: InterviewEntryEditorViewProps & React.RefAttributes<InterviewEntryEditorViewHandle> = {
        ref: editorViewRef,
        validationPrefix: `entry_${entry.id}`,
        tips: entry.question.tips.length ? entry.question.tips : entry.question.custom ? customQuestionTips : undefined,
        question: entryData.question,
        questionPlaceholder: questionPlaceholder,
        onQuestionChange: onQuestionChange,
        canEditQuestion: entry.question.custom,
        answer: entryData.answer,
        onAnswerChange: onAnswerChange,
        answerPlaceholder: answerPlaceholder,
        selected: selected,
        canDelete: entry.question.custom,
        onDelete: onDelete && (() => onDelete(true)),
        labelText: entry.question.labelText,
        labelColor: entry.question.labelColor,
        actionElements: entryActionElements,
        onBlur: onBlur,
        onFocus: onFocus,
        readonly: readonly
    };

    let editorViewElement: ReactElement;

    if (validate && !readonly)
        editorViewElement = (
            <ValidationUnit name={entry.id.toString()} value={entryData.status} validator={entryStatusValidator}>
                {(errorMessage, onStatusChange) => {
                    validationOnStatusChangeRef.current = onStatusChange;
                    return <InterviewEntryEditorView {...editorViewProps} errorMessage={errorMessage} />;
                }}
            </ValidationUnit>
        );
    else
        editorViewElement = (
            <InterviewEntryEditorView {...editorViewProps}>
                {readonly && entry.status ? (
                    <StackLayout align={{ horizontal: 'end', vertical: 'middle' }} className="k-gap-1 k-py-thin">
                        <span className="k-fs-sm">Marked as:</span>
                        <InterviewEntryStatusChip status={entry.status} />
                    </StackLayout>
                ) : (
                    undefined
                )}
            </InterviewEntryEditorView>
        );

    return <ValidationSubScope ref={validationEntryScopeRef}>{editorViewElement}</ValidationSubScope>;
});

const interviewEntryStatusClassNameMap: Record<InterviewEntryStatus, string> = {
    [InterviewEntryStatus.Covered]: 'k-border-success',
    [InterviewEntryStatus.Irrelevant]: 'k-border-warning',
    [InterviewEntryStatus.NotCovered]: 'k-icp-border-base-50'
};
const interviewEntryStatusLabelMap: Record<InterviewEntryStatus, string> = {
    [InterviewEntryStatus.Covered]: 'Covered',
    [InterviewEntryStatus.Irrelevant]: 'Not relevant',
    [InterviewEntryStatus.NotCovered]: 'Not asked'
};
function InterviewEntryStatusChip({ status }: { status: InterviewEntryStatus }) {
    return (
        <span className={combineClassNames('k-px-2 k-rounded k-fs-sm k-border k-border-solid k-fs-sm', interviewEntryStatusClassNameMap[status])}>
            {interviewEntryStatusLabelMap[status]}
        </span>
    );
}

export function InterviewEntriesCounter({ interviewSections }: { interviewSections: InterviewSection[] }) {
    const visibleEntries = interviewSections.flatMap(s => s.entries.filter(e => !e.hidden));
    const entriesCountWithStatus = countWhere(visibleEntries, e => e.status);
    const totalEntriesCount = visibleEntries.length;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
            <span className="k-mr-1 k-icp-subtle-text">Counter:</span>
            <strong>
                {entriesCountWithStatus}/{totalEntriesCount}
            </strong>
            <span className="k-icp-subtle-text">questions</span>
        </StackLayout>
    );
}

export function InterviewSectionsConversationList({
    interviewSections,
    interviewee,
    onEntryAnswerClick,
    selectedEntryId
}: {
    interviewSections?: InterviewSection[];
    interviewee?: ReducedPerson;
    onEntryAnswerClick?: (entry: InterviewEntry) => void;
    selectedEntryId?: number;
}) {
    const answersRefs = useRef<Partial<Record<number, InterviewMessageBoxHandle>>>({});
    const isSelectedEntryPresent =
        selectedEntryId === undefined || !interviewSections ? undefined : interviewSections.some(s => s.entries.some(e => e.id === selectedEntryId));

    useEffect(() => {
        if (selectedEntryId === undefined || !isSelectedEntryPresent) return;

        const answerRef = answersRefs.current[selectedEntryId];
        if (!answerRef || !answerRef.element) return;

        const scrollCancelation: ScrollCancellationSource = {};
        domService.scrollVerticallyIntoViewIfNeeded(answerRef.element, undefined, scrollCancelation);

        return () => {
            scrollCancelation.cancel = true;
        };
    }, [isSelectedEntryPresent, selectedEntryId]);

    if (!interviewSections) return <LoadingIndicator size="big" className="k-display-block -block-center" />;

    let renderedSections = false;
    const sectionsList = interviewSections.map((section, sectionIndex) => {
        let renderedEntries = false;

        const entriesList = section.entries
            .filter(e => !e.hidden)
            .map(entry => {
                const question = entry.question.revised ? entry.question.revision : entry.question.content;
                const answer = entry.answer.revised ? entry.answer.revision : entry.answer.content;

                if (entry.status !== InterviewEntryStatus.Covered && !answer) return null;
                renderedEntries = true;

                return (
                    <div key={entry.id}>
                        {entry.question.labelText && (
                            <InterviewScriptEntryLabelView color={entry.question.labelColor} className="k-mb-1">
                                {entry.question.labelText}
                            </InterviewScriptEntryLabelView>
                        )}
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                            {question && <div>{question}</div>}
                            <InterviewMessageBox
                                ref={r => (r ? (answersRefs.current[entry.id] = r) : delete answersRefs.current[entry.id])}
                                author={interviewee}
                                avatarDistance={2}
                                simpleBubble
                                onMessageClick={onEntryAnswerClick && (() => onEntryAnswerClick(entry))}
                                selected={selectedEntryId === entry.id}
                            >
                                {answer}
                            </InterviewMessageBox>
                        </StackLayout>
                    </div>
                );
            });

        if (renderedEntries) {
            renderedSections = true;
            return (
                <Fragment key={section.id}>
                    {sectionIndex !== 0 && <div className="k-separator" />}
                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6">
                        <InterviewItemGroupTitle>{section.title}</InterviewItemGroupTitle>
                        {entriesList}
                    </StackLayout>
                </Fragment>
            );
        }

        return null;
    });

    if (renderedSections)
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4 k-icp-component-border">
                {sectionsList}
            </StackLayout>
        );

    return (
        <InterviewEmptyColumnView image={{ url: emptyInterviewConversationIllustrationUrl, width: 64, height: 64, description: 'Empty conversation' }}>
            No captured conversation
        </InterviewEmptyColumnView>
    );
}

export type InterviewEntrySelectionContextValue = {
    onEntrySelected?: (entry: InterviewEntry) => void;
    setOnEntrySelectedHandler(handler: (entry: InterviewEntry) => void): void;
    clearOnEntrySelectedHandler(handler: (entry: InterviewEntry) => void): void;
};
export const InterviewEntrySelectionContext = createContext<InterviewEntrySelectionContextValue | undefined>(undefined);

export function useInterviewEntrySelectionContext() {
    const interviewEntrySelectionContext = useContext(InterviewEntrySelectionContext);

    return interviewEntrySelectionContext;
}

export function useInterviewEntrySelectionContextValue() {
    const [onEntrySelectedHandler, setOnEntrySelectedHandler] = useState<{ value: (entry: InterviewEntry) => void }>();

    const value: InterviewEntrySelectionContextValue = useMemo(
        () => ({
            onEntrySelected: onEntrySelectedHandler?.value,
            setOnEntrySelectedHandler(handler) {
                setOnEntrySelectedHandler(currentHandler => (!currentHandler || currentHandler.value !== handler ? { value: handler } : currentHandler));
            },
            clearOnEntrySelectedHandler(handler: (entry: InterviewEntry) => void) {
                setOnEntrySelectedHandler(currentHandler => (currentHandler && currentHandler.value === handler ? undefined : currentHandler));
            }
        }),
        [onEntrySelectedHandler]
    );

    return value;
}
