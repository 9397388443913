import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appConfig } from '../../config';
import { BoxItem, BoxType, Canvas, CanvasBox, canvasService } from '../../services/canvasService';
import { processWithGlobalErrorHandler } from '../../services/common';
import { addNotification, NotificationData } from '../notifications/platformNotificationsSlice';
import { AppDispatch, RootState } from '../store';

export interface BoxItemState extends BoxItem {
    isEditing: boolean;
    editContent?: string;
}

export interface CanvasBoxState extends CanvasBox {
    isLocked: boolean;
    items: BoxItemState[];
}

export interface CanvasState {
    ideaId?: string;
    boxes?: CanvasBoxState[];
    loadingIdeaId?: string;
}

const initialState: CanvasState = {};

export const loadCanvasByIdeaId = createAsyncThunk<Canvas, string, { state: RootState }>(
    'canvas/load',
    processWithGlobalErrorHandler(async (ideaId: string) => {
        const loadedIdea = await canvasService.getCanvas(ideaId);

        return loadedIdea;
    }),
    {
        condition: (ideaId: string, { getState }) => {
            const canvasState = getState().canvas;
            if (canvasState.loadingIdeaId === ideaId || canvasState.ideaId === ideaId) return false;
        }
    }
);

export const deleteItem = createAsyncThunk<{ boxType: BoxType; itemId: number } | undefined, { boxType: BoxType; itemId: number }, { state: RootState }>(
    'canvas/item/delete',
    processWithGlobalErrorHandler(async ({ boxType, itemId }, { getState }) => {
        const ideaId = getState().canvas.ideaId;
        if (!ideaId) return;

        await canvasService.deleteItem(ideaId, boxType, itemId);

        return { boxType, itemId };
    })
);

export const reorderItem = createAsyncThunk<void, { boxType: BoxType; itemId: number; newIndex: number }, { dispatch: AppDispatch; state: RootState }>(
    'canvas/item/reorder',
    processWithGlobalErrorHandler(async (args: { boxType: BoxType; itemId: number; newIndex: number }, { dispatch, getState }) => {
        dispatch(canvasSlice.actions.moveItem(args));
        const canvasState = getState().canvas;
        const prevItemId = args.newIndex > 0 ? getState().canvas.boxes?.find(b => b.type === args.boxType)?.items[args.newIndex - 1].id : undefined;

        if (canvasState.ideaId) await canvasService.reorderItem(canvasState.ideaId, args.boxType, args.itemId, prevItemId);
    })
);

export const saveItem = createAsyncThunk<
    { boxType: BoxType; itemId: number; savedItem: BoxItem } | undefined,
    { boxType: BoxType; itemId?: number; content: string; relatedItemIds?: number[]; colorCode?: string; editTruncatedCallback?: () => void },
    { dispatch: AppDispatch; state: RootState }
>(
    'canvas/item/save',
    processWithGlobalErrorHandler(async ({ boxType, itemId, content, relatedItemIds, colorCode, editTruncatedCallback }, { dispatch, getState }) => {
        const state = getState();
        const ideaId = state.canvas.ideaId;
        if (!ideaId || !state.canvas.boxes) return;

        const boxToUpdate = state.canvas.boxes.find(b => b.type === boxType);
        if (!boxToUpdate) return;

        let truncatedContent: string | undefined;
        if (content.length > appConfig.canvas.item.maxLength) {
            truncatedContent = content;
            content = content.substring(0, appConfig.canvas.item.maxLength);
        }

        let savedItem: BoxItem;
        if (itemId) {
            savedItem = await canvasService.updateItem(ideaId, boxType, itemId, content, relatedItemIds, colorCode);
        } else {
            savedItem = await canvasService.createItem(ideaId, boxType, content, relatedItemIds, colorCode);
        }

        if (truncatedContent)
            dispatch(
                addNotification(
                    {
                        content: `Item truncated to ${appConfig.canvas.item.maxLength} characters.`,
                        actionText: editTruncatedCallback ? 'Edit' : undefined,
                        type: 'warning'
                    },
                    editTruncatedCallback
                        ? () => {
                              const currentIdeaId = getState().canvas.ideaId;
                              if (currentIdeaId !== ideaId) return;
                              dispatch(
                                  canvasSlice.actions.setEditingItem({
                                      boxType: boxType,
                                      itemId: savedItem.id,
                                      isEditing: true,
                                      editContent: truncatedContent
                                  })
                              );
                              editTruncatedCallback();
                          }
                        : undefined,
                    undefined,
                    true
                )
            );

        return { boxType, itemId: savedItem.id, savedItem };
    })
);

export const refreshItem = (boxType: BoxType, itemId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (!state.canvas.ideaId) return;

    const freshItem = await canvasService.getItem(state.canvas.ideaId, boxType, itemId);
    dispatch(canvasSlice.actions.addOrUpdateItem({ boxType: boxType, item: freshItem }));
};

export const refreshBox = (boxType: BoxType) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (!state.canvas.ideaId) return;

    const freshItemsInBox = await canvasService.getBoxItems(state.canvas.ideaId, boxType);
    dispatch(canvasSlice.actions.refreshItemsInBox({ boxType: boxType, freshItems: freshItemsInBox }));
};

export const refreshCanvas = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (!state.canvas.ideaId) return;

    const freshCanvas = await canvasService.getCanvas(state.canvas.ideaId);
    dispatch(canvasSlice.actions.refreshCanvas({ canvas: freshCanvas }));
};

export const canvasSlice = createSlice({
    name: 'canvas',
    initialState,
    reducers: {
        clearCanvas: () => {
            return initialState;
        },
        addOrUpdateItem: (state, action: PayloadAction<{ boxType: BoxType; item: BoxItem }>) => {
            if (!state.boxes) return;
            const boxToUpdate = state.boxes.find(b => b.type === action.payload.boxType);
            if (!boxToUpdate) return;
            const itemToUpdateIndex = boxToUpdate.items.findIndex(i => i.id === action.payload.item.id);
            if (itemToUpdateIndex === -1) {
                boxToUpdate.items.push({ ...action.payload.item, isEditing: false });
            } else {
                const existingItem = boxToUpdate.items[itemToUpdateIndex];
                boxToUpdate.items[itemToUpdateIndex] = { ...existingItem, ...action.payload.item, isEditing: false, editContent: undefined };
            }
        },
        setEditingItem: (state, action: PayloadAction<{ boxType: BoxType; itemId: number; isEditing: boolean; editContent?: string }>) => {
            if (!state.boxes) return;
            const boxToUpdate = state.boxes.find(b => b.type === action.payload.boxType);
            if (!boxToUpdate) return;
            boxToUpdate.items.forEach(i => {
                if (i.id === action.payload.itemId) {
                    if (i.isEditing !== action.payload.isEditing) {
                        i.isEditing = action.payload.isEditing;
                        i.editContent = action.payload.editContent;
                    }
                } else {
                    if (action.payload.isEditing && (i.isEditing || i.editContent)) {
                        i.isEditing = false;
                        i.editContent = undefined;
                    }
                }
            });
        },
        removeItem: (state, action: PayloadAction<{ boxType: BoxType; itemId: number }>) => {
            const boxToUpdate = state.boxes?.find(b => b.type === action.payload.boxType);
            if (!boxToUpdate) return;
            boxToUpdate.items = boxToUpdate.items.filter(i => i.id !== action.payload.itemId);
        },
        restoreItem: (state, action: PayloadAction<{ boxType: BoxType; boxItem: BoxItem; index: number }>) => {
            const box = state.boxes?.find(b => b.type === action.payload.boxType);
            if (!box) return;
            box.items.splice(action.payload.index, 0, { ...action.payload.boxItem, isEditing: false });
        },
        moveItem: (state, action: PayloadAction<{ boxType: BoxType; itemId: number; newIndex: number }>) => {
            const box = state.boxes?.find(b => b.type === action.payload.boxType);
            if (!box) return;
            const oldIndex = box.items.findIndex(i => i.id === action.payload.itemId);
            if (oldIndex === -1) return;
            const [itemToMove] = box.items.splice(oldIndex, 1);
            box.items.splice(action.payload.newIndex, 0, itemToMove);
        },
        refreshItemsInBox: (state, action: PayloadAction<{ boxType: BoxType; freshItems: BoxItem[] }>) => {
            const box = state.boxes?.find(b => b.type === action.payload.boxType);
            if (!box) return;

            const refreshedItems: BoxItemState[] = [];
            for (const freshItem of action.payload.freshItems) {
                const existingItem = box.items.find(i => i.id === freshItem.id);
                if (existingItem && existingItem.isEditing) refreshedItems.push(existingItem);
                else refreshedItems.push({ ...freshItem, isEditing: false });
            }

            box.items = refreshedItems;
        },
        refreshCanvas: (state, action: PayloadAction<{ canvas: Canvas }>) => {
            if (!state.boxes) return;

            action.payload.canvas.boxes.forEach(b =>
                canvasSlice.caseReducers.refreshItemsInBox(state, canvasSlice.actions.refreshItemsInBox({ boxType: b.type, freshItems: b.items }))
            );
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadCanvasByIdeaId.pending, (state, action) => {
                if (state.ideaId && state.ideaId !== action.meta.arg) {
                    state = initialState;
                }
                state.loadingIdeaId = action.meta.arg;
            })
            .addCase(loadCanvasByIdeaId.fulfilled, (state, action) => {
                state.loadingIdeaId = undefined;
                state.ideaId = action.meta.arg;
                state.boxes = action.payload.boxes.map(b => {
                    return {
                        isLocked: false,
                        type: b.type,
                        items: b.items.map(i => {
                            return {
                                ...i,
                                isEditing: false
                            };
                        })
                    };
                });
            })
            .addCase(loadCanvasByIdeaId.rejected, (state, action) => {
                state.loadingIdeaId = undefined;
            })
            .addCase(deleteItem.fulfilled, (state, action: PayloadAction<{ boxType: BoxType; itemId: number } | undefined>) => {
                if (!action.payload || !state.boxes) return;

                canvasSlice.caseReducers.removeItem(state, canvasSlice.actions.removeItem(action.payload));
            })
            .addCase(saveItem.fulfilled, (state, action: PayloadAction<{ boxType: BoxType; itemId: number; savedItem: BoxItem } | undefined>) => {
                if (!action.payload || !state.boxes) return;

                const boxToUpdate = state.boxes.find(b => b.type === action.payload?.boxType);
                if (!boxToUpdate) return;

                let itemUpdated = false;
                const newBoxItemState: BoxItemState = { ...action.payload.savedItem, isEditing: false };
                boxToUpdate.items = boxToUpdate.items.map(i => {
                    if (i.id === action.payload?.itemId) {
                        itemUpdated = true;
                        return newBoxItemState;
                    }

                    return i;
                });

                if (!itemUpdated) boxToUpdate.items.push(newBoxItemState);
            });
    }
});

export const { setEditingItem, clearCanvas, removeItem } = canvasSlice.actions;

export const deleteItemWithUndo = (boxType: BoxType, itemId: number, notificationData: NotificationData, onRestored?: () => void) => async (
    dispatch: AppDispatch,
    getState: () => RootState
) => {
    const state = getState();
    const box = state.canvas.boxes?.find(b => b.type === boxType);
    if (!box) return;
    const itemToRemoveIndex = box.items.findIndex(i => i.id === itemId);
    if (itemToRemoveIndex === -1) return;

    await dispatch(deleteItem({ boxType, itemId })).unwrap();
    const ideaId = state.canvas.ideaId;
    if (!ideaId) return;

    dispatch(
        addNotification(notificationData, async () => {
            const restoredItem = await canvasService.restoreItem(ideaId, boxType, itemId);
            const currentIdeaId = getState().canvas.ideaId;
            if (currentIdeaId && currentIdeaId === ideaId)
                dispatch(canvasSlice.actions.restoreItem({ boxType, boxItem: restoredItem, index: itemToRemoveIndex }));

            onRestored?.();
        })
    );
};

export default canvasSlice.reducer;
