import {
    Sortable,
    SortableItemUIProps,
    SortableOnDragEndEvent,
    SortableOnDragOverEvent,
    SortableOnDragStartEvent,
    SortableOnNavigateEvent
} from '@progress/kendo-react-sortable';
import React, { useEffect, useState } from 'react';
import { combineClassNames, composeFunctions } from '../../../services/common';
import { InterviewQuestionEditor, InterviewQuestionEditorEditingProps } from './interviewScriptEditor';

export type InterviewQuestionListItem = Omit<
    InterviewQuestionEditorEditingProps,
    'elementAttributes' | 'forwardElementRef' | 'isDragView' | 'isPlaceholder'
> & {
    id: number;
};
let dragCuePositionCompensations: Record<number, { x: number; y: number }> = {};

export function InterviewScriptQuestionsSortableList({
    items,
    disableDrag,
    onItemReordered,
    onItemDragStarted,
    onItemDragEnded
}: {
    items: InterviewQuestionListItem[];
    onItemReordered: (id: number, newIndex: number) => Promise<void>;
    onItemDragStarted?: (id: number, index: number) => void;
    onItemDragEnded?: () => void;
    disableDrag?: boolean;
    dropAreaClueElement?: Element | null;
}) {
    const [reorderedItems, setReorderedItems] = useState<InterviewQuestionListItem[]>();

    useEffect(() => {
        dragCuePositionCompensations = {};

        return () => {
            dragCuePositionCompensations = {};
        };
    }, []);

    const onItemDragStart = (e: SortableOnDragStartEvent) => {
        const draggedItem = items[e.prevIndex];

        if (!e.element.closest('[data-drag-handle="true"]') || disableDrag) {
            e.preventDefault();
            return;
        }

        setReorderedItems(items);
        onItemDragStarted?.(draggedItem.entry.id, e.prevIndex);
    };

    const onReorder = (e: SortableOnDragOverEvent) => {
        setReorderedItems(e.newState as InterviewQuestionListItem[]);
    };

    const onReorderWithKeyboard = (e: SortableOnNavigateEvent) => {
        setReorderedItems(e.newState as InterviewQuestionListItem[]);
        onReorderComplete(e.nextIndex, e.newState as InterviewQuestionListItem[]);
    };

    const onItemDragEnd = (e: SortableOnDragEndEvent) => {
        onReorderComplete(e.nextIndex, e.newState as InterviewQuestionListItem[]);
    };

    const onReorderComplete = (itemNewIndex: number, newOrder: InterviewQuestionListItem[]) => {
        if (itemNewIndex >= 0) {
            const reorderedItem = newOrder[itemNewIndex];
            if (reorderedItem) onItemReordered(reorderedItem.entry.id, itemNewIndex);
        }

        setReorderedItems(undefined);
        onItemDragEnded?.();
    };

    return (
        <Sortable
            idField="id"
            data={reorderedItems || items}
            itemUI={InterviewQuestionSortableItem}
            onDragStart={onItemDragStart}
            onDragOver={onReorder}
            onNavigate={onReorderWithKeyboard}
            onDragEnd={onItemDragEnd}
            navigation={false}
        />
    );
}

function InterviewQuestionSortableItem(props: SortableItemUIProps) {
    const { dataItem, forwardRef, attributes, style, isDisabled, isDragged, isDragCue } = props;
    const boxItem = dataItem as InterviewQuestionListItem;

    const classes = ['draggable-item'];
    if (isDragCue) {
        classes.push('draggable-item-cue');
        const cueCompensation = dragCuePositionCompensations[boxItem.entry.id];
        if (cueCompensation) {
            style.transform = `translate(-${cueCompensation.x + 10}px, -${cueCompensation.y + 10}px) rotate(1deg)`;
            style.transformOrigin = `${cueCompensation.x}px ${cueCompensation.y}px`;
        }
    } else if (isDragged) {
        classes.push('draggable-item-placeholder');
    }

    const onMouseDown =
        !isDisabled && !isDragged
            ? (e: React.MouseEvent<HTMLDivElement>) => {
                  const mouseX = e.pageX;
                  const mouseY = e.pageY;
                  const pressedElementRect = e.currentTarget.getBoundingClientRect();
                  const elementX = pressedElementRect.left;
                  const elementY = pressedElementRect.top;

                  dragCuePositionCompensations[boxItem.entry.id] = { x: mouseX - elementX, y: mouseY - elementY };
              }
            : undefined;

    const elementAttributes: React.HtmlHTMLAttributes<HTMLDivElement> = { ...attributes };
    elementAttributes.style = { ...style, cursor: undefined };
    elementAttributes.onMouseDown = composeFunctions(onMouseDown, attributes.onMouseDown);
    elementAttributes.className = combineClassNames(attributes.className, classes.join(' '), 'k-mb-2 k-outline-none');

    return (
        <InterviewQuestionEditor
            {...dataItem}
            isDragView={isDragged && isDragCue}
            isPlaceholder={isDragged && !isDragCue}
            elementAttributes={elementAttributes}
            forwardElementRef={forwardRef}
        />
    );
}
