import { Button } from '@progress/kendo-react-buttons';
import { ZIndexContext } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { ReactElement, useEffect, useRef } from 'react';
import { useForceRender } from '../../hooks/commonHooks';
import { ReactComponent as CloseIcon } from '../../icons/x.svg';
import { Term, termsService } from '../../services/termsService';
import { HtmlWithShowMoreByMarker } from '../ui/htmlWithShowMoreByMarker';

type TermNode = { key: string; term: Term; dfnElement: HTMLElement; isOpen: boolean; subTerms?: TermNode[] };
const openDefinitionClassName = 'k-icp-selected';
const definitionPopupContentClassName = 'definition-popup-content';
const loadingDefinitionClassName = 'definition-term-loading';

function findTermNode(condition: (termNode: TermNode) => boolean, termNodes?: TermNode[]): TermNode | undefined {
    if (!termNodes) return undefined;

    for (const termNode of termNodes) {
        if (condition(termNode)) return termNode;
        const foundTermNode = findTermNode(condition, termNode.subTerms);
        if (foundTermNode) return foundTermNode;
    }

    return undefined;
}

function findTermNodeByDefinitionElement(definitionElement: HTMLElement, termNodes?: TermNode[]): TermNode | undefined {
    return findTermNode(tn => tn.dfnElement === definitionElement, termNodes);
}

function findTermNodeByKey(termNodeKey: string, termNodes?: TermNode[]): TermNode | undefined {
    return findTermNode(tn => tn.key === termNodeKey, termNodes);
}

function closeTermNode(termNode: TermNode) {
    if (termNode.subTerms)
        for (const subTermNode of termNode.subTerms) {
            closeTermNode(subTermNode);
        }

    termNode.isOpen = false;
}

function removeTermNodeByKey(termNodeKey: string, termNodes?: TermNode[]): boolean {
    if (!termNodes) return false;

    for (let i = 0; i < termNodes.length; i++) {
        const termNode = termNodes[i];
        if (termNode.key === termNodeKey) {
            termNode.dfnElement.classList.remove(openDefinitionClassName);
            termNodes.splice(i, 1);
            return true;
        }

        const nodeRemoved = removeTermNodeByKey(termNodeKey, termNode.subTerms);
        if (nodeRemoved) return true;
    }

    return false;
}

function traverseTermNodes(termNodes?: TermNode[], preChildrenAction?: (termNode: TermNode) => void, postChildrenAction?: (termNode: TermNode) => void): void {
    if (!termNodes) return;

    for (const termNode of termNodes) {
        preChildrenAction?.(termNode);
        if (termNode.subTerms) traverseTermNodes(termNode.subTerms, preChildrenAction, postChildrenAction);
        postChildrenAction?.(termNode);
    }
}

export const GlossaryTermsListener = ({ termZoneWrapper }: { termZoneWrapper?: GlobalEventHandlers }) => {
    const termNodesRef = useRef<TermNode[]>([]);
    const termsCounterRef = useRef(0);

    const forceRender = useForceRender();

    if (!termZoneWrapper) termZoneWrapper = window;

    useEffect(() => {
        if (!termZoneWrapper) return;

        const dismissTerm = (termNode: TermNode) => {
            if (termNode.isOpen) closeTermNode(termNode);
            else removeTermNodeByKey(termNode.key, termNodesRef.current);
        };

        const dismissTerms = (termNodes: TermNode[]): boolean => {
            if (!termNodes.length) return false;
            termNodes.forEach(tn => dismissTerm(tn));

            return true;
        };

        const getCurrentLevelTerms = (clickedDefinitionPopup: HTMLElement | null): TermNode[] => {
            if (!clickedDefinitionPopup) return termNodesRef.current;
            const parentTermNodeKey = clickedDefinitionPopup.dataset.termNodeKey;
            if (!parentTermNodeKey) return termNodesRef.current;
            const parentTermNode = findTermNodeByKey(parentTermNodeKey, termNodesRef.current);
            if (!parentTermNode) return termNodesRef.current;

            if (!parentTermNode.subTerms) parentTermNode.subTerms = [];

            return parentTermNode.subTerms;
        };

        const tryHandleDefinitionClick = async (e: MouseEvent): Promise<boolean> => {
            const clickedElement = e.target as HTMLElement;
            if (!clickedElement) {
                return dismissTerms(termNodesRef.current);
            }

            if (clickedElement.classList.contains(loadingDefinitionClassName)) return false;

            const parentTermDefinitionPopup = clickedElement.closest<HTMLElement>(`.${definitionPopupContentClassName}`);
            const currentLevelTemNodes = getCurrentLevelTerms(parentTermDefinitionPopup);
            const termId = clickedElement.dataset['termId'];
            if (clickedElement.tagName !== 'DFN' || !termId) {
                return dismissTerms(currentLevelTemNodes);
            }

            const existingTermNode = findTermNodeByDefinitionElement(clickedElement, termNodesRef.current);
            if (existingTermNode) {
                dismissTerm(existingTermNode);
            } else {
                dismissTerms(currentLevelTemNodes);

                clickedElement.classList.add(openDefinitionClassName);
                clickedElement.classList.add(loadingDefinitionClassName);
                try {
                    const loadedTerm = await termsService.getTerm(termId);
                    currentLevelTemNodes.push({
                        key: (termsCounterRef.current++).toString(),
                        isOpen: true,
                        dfnElement: clickedElement,
                        term: loadedTerm
                    });
                } finally {
                    clickedElement.classList.remove(loadingDefinitionClassName);
                }
            }

            return true;
        };

        const onClick = async (e: MouseEvent) => {
            const definitionClickHandled = await tryHandleDefinitionClick(e);
            if (definitionClickHandled) forceRender();
        };

        termZoneWrapper.addEventListener('click', onClick, true);

        return () => {
            termZoneWrapper?.removeEventListener('click', onClick, true);
        };
    }, [forceRender, termZoneWrapper]);

    const onPopupClosed = (termNode: TermNode) => {
        removeTermNodeByKey(termNode.key, termNodesRef.current);
        forceRender();
    };

    const onClose = (termNode: TermNode) => {
        closeTermNode(termNode);
        forceRender();
    };

    const mainPageElement = document.getElementsByTagName('main')[0];
    const termNodeUIelements: ReactElement[] = [];
    traverseTermNodes(termNodesRef.current, termNode => {
        termNodeUIelements.push(
            <Popup
                key={termNode.key}
                show={termNode.isOpen}
                anchor={termNode.dfnElement}
                popupClass="definition-popup !k-px-3 !k-py-2"
                onClose={() => onPopupClosed(termNode)}
                appendTo={mainPageElement}
            >
                <div className={definitionPopupContentClassName} data-term-node-key={termNode.key}>
                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1">
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1 k-justify-content-between">
                            <strong>{termNode.term.term}</strong>
                            <Button fillMode="flat" size="small" className="k-icp-svg-icon-button" onClick={() => onClose(termNode)}>
                                <CloseIcon className="k-icp-icon" />
                            </Button>
                        </StackLayout>
                        <HtmlWithShowMoreByMarker className="formatted-content-area">{termNode.term.description}</HtmlWithShowMoreByMarker>
                    </StackLayout>
                </div>
            </Popup>
        );
    });

    return <ZIndexContext.Provider value={500000}>{termNodeUIelements}</ZIndexContext.Provider>;
};
