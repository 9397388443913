import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import { GraphicData, combineClassNames } from '../../services/common';
import LoadingIndicator from '../ui/loadingIndicator';

export function InterviewContentLoader() {
    return <LoadingIndicator size="big" className="!k-pos-absolute k-centered" />;
}

function InterviewLayout({ children }: { children?: ReactNode }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-icp-panel-base -h100">
            {children}
        </StackLayout>
    );
}

export function InterviewMainColumn({ children, className }: { children?: ReactNode; className?: string }) {
    return <div className={combineClassNames('k-p-6 k-content k-icp-shadow-md k-overflow-auto k-scroll-smooth', className)}>{children}</div>;
}

export function InterviewSecondaryColumn({ children, className }: { children?: ReactNode; className?: string }) {
    return <div className={combineClassNames('k-p-4 k-overflow-auto k-scroll-smooth', className)}>{children}</div>;
}

export function InterviewSecondaryColumnTitle({ children }: { children: string }) {
    return <div className="k-fs-sm k-text-uppercase k-text-center k-mb-2 k-icp-placeholder-text">{children}</div>;
}

export function InterviewTwoColumnLayout({ sideContent, children }: { sideContent?: ReactNode; children?: ReactNode }) {
    return (
        <InterviewLayout>
            <InterviewMainColumn className="k-flex-basis-2/3">{children}</InterviewMainColumn>
            <InterviewSecondaryColumn className="k-flex-basis-1/3">{sideContent}</InterviewSecondaryColumn>
        </InterviewLayout>
    );
}

export function InterviewThreeColumnLayout({
    leftContent,
    children,
    rightContent
}: {
    leftContent?: ReactNode;
    children?: ReactNode;
    rightContent?: ReactNode;
}) {
    return (
        <InterviewLayout>
            <InterviewSecondaryColumn className="k-flex-basis-1/4">{leftContent}</InterviewSecondaryColumn>
            <InterviewMainColumn className="k-flex-basis-1/2">{children}</InterviewMainColumn>
            <InterviewSecondaryColumn className="k-flex-basis-1/4">{rightContent}</InterviewSecondaryColumn>
        </InterviewLayout>
    );
}

export function InterviewEmptyColumnView({ image, children }: { image: GraphicData; children: string }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-6 k-mx-2 -h100">
            <img src={image.url} width={image.width} height={image.height} alt={image.description} />
            <div className="k-text-center">{children}</div>
        </StackLayout>
    );
}
