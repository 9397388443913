import { Chip } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactElement, ReactNode } from 'react';
import { Link, To, useParams } from 'react-router-dom';
import emptyContactsIllustrationUrl from '../../images/empty-contacts-illustration.svg';
import noResultsIllustrationUrl from '../../images/no-results-illustration.svg';
import { combineClassNames, generateInitials, getPreferredColorIndex } from '../../services/common';
import { Company, Contact, ContactTag, Person, ReducedPerson } from '../../services/contactsService';
import { H2, H3, P } from '../ui/typography';
import UserAvatar from '../user/userAvatar';

export function ContactInfoPanel({ title, children }: { title: string; children?: ReactNode }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            <H3>{title}</H3>
            {children}
        </StackLayout>
    );
}

export function ContactInfoPanelRow({ title, children }: { title: string; children?: ReactElement | string }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <span className="k-icp-subtle-text">{title}:</span>
            {children || <span className="k-icp-subtle-text">Not available</span>}
        </StackLayout>
    );
}

export function ContactSocialInfo({ contact }: { contact: Contact }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            {contact.linkedIn && (
                <a
                    href={contact.linkedIn}
                    className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
                    target="_blank"
                    rel="noreferrer"
                >
                    <span role="presentation" className="k-button-icon k-icon k-i-linkedin" />
                </a>
            )}
            {contact.twitter && (
                <a
                    href={contact.twitter}
                    className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
                    target="_blank"
                    rel="noreferrer"
                >
                    <span role="presentation" className="k-button-icon k-icon k-i-twitter" />
                </a>
            )}
            {contact.facebook && (
                <a
                    href={contact.facebook}
                    className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
                    target="_blank"
                    rel="noreferrer"
                >
                    <span role="presentation" className="k-button-icon k-icon k-i-facebook" />
                </a>
            )}
        </StackLayout>
    );
}

export function ContactSidePanel({
    title,
    children,
    className,
    renderHeader
}: {
    title?: string;
    children?: ReactNode;
    className?: string;
    renderHeader?: (titleElement: ReactElement) => ReactNode;
}) {
    const titleElement = title ? <H3>{title}</H3> : undefined;

    return (
        <div className={combineClassNames('k-icp-panel-base k-rounded !k-p-4', className)}>
            {titleElement && <div className="k-mb-2">{renderHeader ? renderHeader(titleElement) : titleElement}</div>}
            {children}
        </div>
    );
}

export function EmptyContactsView({
    filtered,
    contactTypeName,
    contactsTypeName
}: {
    filtered: boolean;
    contactTypeName: 'contact' | 'company';
    contactsTypeName: 'contacts' | 'companies';
}) {
    return (
        <StackLayout className="k-flex-1" orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }}>
            {filtered ? (
                <>
                    <img
                        src={noResultsIllustrationUrl}
                        width="560"
                        height="188"
                        alt={`No ${contactsTypeName} found`}
                        className="responsive-image k-display-block k-mb-8"
                    />
                    <H2 className="!k-mb-4">No {contactsTypeName} found</H2>
                    <P className="!k-fs-lg">Adjust your search criteria or add a new {contactTypeName}.</P>
                </>
            ) : (
                <>
                    <img src={emptyContactsIllustrationUrl} width="396" height="252" alt="Empty contacts" className="responsive-image k-display-block k-mb-6" />
                    <P className="page-content-middle page-content-middle--small k-text-center !k-fs-lg">
                        Add Contacts to explore, discover and validate your Customer Segments.
                    </P>
                </>
            )}
        </StackLayout>
    );
}

export function PersonSimpleView({
    person,
    showJobTitle = true,
    renderAsLink,
    className,
    additionalContent
}: {
    person: Person | ReducedPerson;
    showJobTitle?: boolean;
    renderAsLink?: boolean;
    className?: string;
    additionalContent?: ReactNode;
}) {
    const { ideaId } = useParams();
    const renderLinkToPerson = renderAsLink && !!ideaId;

    const personView = (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
            <UserAvatar
                picture={person.picture}
                initials={generateInitials(2, person.firstName, person.lastName)}
                colorIndex={getPreferredColorIndex(person.id)}
            />

            <div>
                <strong className={combineClassNames('k-fs-lg', renderLinkToPerson ? 'k-icp-avatar-interactive-group-link' : undefined)}>{person.name}</strong>
                {showJobTitle && (person as Person).jobTitle && <div className="k-fs-sm k-icp-subtle-text">{(person as Person).jobTitle}</div>}
            </div>
        </StackLayout>
    );

    return (
        <StackLayout
            orientation="horizontal"
            align={{ horizontal: 'start', vertical: 'middle' }}
            className={combineClassNames('k-gap-4 k-justify-content-between', className)}
        >
            {renderLinkToPerson ? (
                <Link className="k-icp-avatar-interactive-group" to={`/startups/${ideaId}/contacts/people/${person.id}`}>
                    {personView}
                </Link>
            ) : (
                personView
            )}

            {additionalContent}
        </StackLayout>
    );
}

export function CompanySimpleView({ company }: { company: Company }) {
    const companyNameParts = company.name.split(' ');

    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
            <UserAvatar
                picture={company.picture}
                initials={generateInitials(2, companyNameParts[0], companyNameParts[1])}
                colorIndex={getPreferredColorIndex(company.id)}
            />

            <strong className="k-fs-lg">{company.name}</strong>
        </StackLayout>
    );
}

export function ContactTagsList({ tags }: { tags?: ContactTag[] }) {
    if (!tags?.length) return <span className="k-icp-subtle-text">No assigned tags</span>;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1 k-flex-wrap">
            {tags.map(t => (
                <Chip key={t.id} text={t.name} className="k-icp-chip-solid-base-not-interactive" />
            ))}
        </StackLayout>
    );
}

export function JobTitleAndCompanyView({
    jobTitle,
    company,
    getCompanyLink
}: {
    jobTitle?: string;
    company?: Company;
    getCompanyLink?: (company: Company) => To;
}) {
    const companyLinkElement = company ? (
        getCompanyLink ? (
            <Link to={getCompanyLink(company)} className="k-button-link-secondary">
                {company.name}
            </Link>
        ) : (
            <span>{company.name}</span>
        )
    ) : (
        undefined
    );

    const jobTitleElement = jobTitle ? <span>{jobTitle}</span> : undefined;

    if (jobTitleElement && companyLinkElement)
        return (
            <span>
                {jobTitleElement}, {companyLinkElement}
            </span>
        );
    if (jobTitleElement) return jobTitleElement;
    if (companyLinkElement) return companyLinkElement;

    return null;
}
