import { GridCell, GridCellProps } from '@progress/kendo-react-grid';
import { ComponentType, ReactElement, cloneElement } from 'react';

export type CellTemplateProps<TData> = {
    dataItem: TData;
    cellProps: GridCellProps;
};

export function createCellTemplateFromComponent<TData>(component: ComponentType<CellTemplateProps<TData>>) {
    return TemplatedGridCell.bind<undefined, ComponentType<CellTemplateProps<TData>>, [GridCellProps], JSX.Element>(undefined, component);
}

function TemplatedGridCell<TData>(CellTemplate: ComponentType<CellTemplateProps<TData>>, props: GridCellProps) {
    function renderTemplate(defaultCell: ReactElement | null, props: GridCellProps) {
        if (!defaultCell) return defaultCell;

        return cloneElement(defaultCell, undefined, <CellTemplate dataItem={props.dataItem} cellProps={props} />);
    }

    return <GridCell {...props} field={props.field ?? ''} render={renderTemplate} />;
}
