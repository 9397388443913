import { ReactElement } from 'react';

export function WeekDaysList({ firstDayOfWeek = 1, children }: { firstDayOfWeek?: number; children?: (weekDay: number) => ReactElement }) {
    if (!children) return null;

    const weekDaysElements: ReactElement[] = [];
    const weekDayStart = firstDayOfWeek < 7 ? firstDayOfWeek : firstDayOfWeek % 7;
    const weekDayEnd = weekDayStart + 6;
    for (let weekDay = weekDayStart; weekDay <= weekDayEnd; weekDay++) {
        const weekDayIndex = weekDay % 7;
        weekDaysElements.push(children(weekDayIndex));
    }

    return <>{weekDaysElements}</>;
}
