import { ZIndexContext } from '@progress/kendo-react-common';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/logo.svg';

export default function TopNav({ center, right, suppressLogoLink }: { center?: ReactNode; right?: ReactNode; suppressLogoLink?: boolean }) {
    const logo = <Logo className="top-nav-logo-image" />;
    return (
        <ZIndexContext.Provider value={150}>
            <div className="top-nav">
                <div className="top-nav-section">
                    {suppressLogoLink ? (
                        logo
                    ) : (
                        <Link to="/" className="top-nav-logo">
                            {logo}
                        </Link>
                    )}
                </div>

                <div className="top-nav-section top-nav-section--main">{center}</div>

                <Tooltip anchorElement="target" position="bottom" openDelay={0}>
                    <div className="top-nav-section">{right}</div>
                </Tooltip>
            </div>
        </ZIndexContext.Provider>
    );
}
