import { kendoThemeMaps } from '@progress/kendo-react-common';
import { List, ListContainer } from '@progress/kendo-react-dropdowns';
import { ListProps } from '@progress/kendo-react-dropdowns/dist/npm/common/List';
import { Skeleton } from '@progress/kendo-react-indicators';
import React, { ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { combineClassNames } from '../../services/common';
import { PlaintTextContentEditable, PlaintTextContentEditableHandle } from './plainTextContentEditable';

export function ConstantTextToken({ children, plainText }: { children?: ReactNode; plainText?: boolean }) {
    if (!children) return null;

    return <span className={plainText ? undefined : 'k-rounded-sm k-px-thin k-icp-bg-dark-6'}>{children}</span>;
}

export function TextTokenLoader({ width }: { width: number }) {
    return <Skeleton shape="text" className="!k-d-inline-block" style={{ width: width }} />;
}

export type TextTokenWrapperHandle = { token?: TextTokenHandle | null };
export type TextTokenWrapperProps = {
    isLoading?: boolean;
    loaderWidth?: number;
    forceEdit?: boolean;
    forceView?: boolean;
    plainText?: boolean;
} & TextTokenProps;
export const TextTokenWrapper = forwardRef<TextTokenWrapperHandle, TextTokenWrapperProps>(function TextTokenWrapper(
    { isLoading, loaderWidth, forceEdit, forceView, plainText, ...textTokenProps },
    ref
) {
    const tokenRef = useRef<TextTokenHandle>(null);
    useImperativeHandle(
        ref,
        () => {
            return {
                get token() {
                    return tokenRef.current;
                }
            };
        },
        []
    );

    if (isLoading) {
        if (!loaderWidth) throw new Error('loaderWidth is required when isLoading is true');
        return <TextTokenLoader width={loaderWidth} />;
    }
    if ((!textTokenProps.children || forceEdit) && !forceView) return <TextToken ref={tokenRef} {...textTokenProps} />;

    return <ConstantTextToken plainText={plainText}>{textTokenProps.children}</ConstantTextToken>;
});

export type TextTokenPredefinedItem = {
    text: string;
    value: any;
};

export type TextTokenHandle = {
    focus(): void;
};
type TextTokenValidator = (value: string) => boolean;
type TextTokenProps = {
    children?: string;
    readonly?: boolean;
    placeholder?: string;
    className?: string;
    onChange?: (e: { value: string }) => void | string;
    predefinedValues?: TextTokenPredefinedItem[];
    predefinedValuesOnly?: boolean;
    onPredefinedItemSelected?: (item: TextTokenPredefinedItem) => void;
    emptyPredefinedValuesText?: string;
    predefinedItemsSize?: 'small' | 'medium' | 'large';
    predefinedValuesHint?: string;
    predefinedItemRender?: ListProps['itemRender'];
    togglePredefinedItemsOnClick?: boolean;
    validator?: TextTokenValidator;
    invalid?: boolean;
};
export const TextToken = forwardRef<TextTokenHandle, TextTokenProps>(function TextToken(
    {
        children,
        readonly,
        placeholder,
        className,
        onChange,
        predefinedValues,
        predefinedValuesOnly,
        onPredefinedItemSelected,
        emptyPredefinedValuesText,
        predefinedItemsSize = 'small',
        predefinedValuesHint,
        predefinedItemRender,
        togglePredefinedItemsOnClick = true,
        validator,
        invalid
    },
    ref
) {
    const classNames = ['text-token'];
    if (className) classNames.push(className);
    if (children) classNames.push('text-token-with-value');
    else classNames.push('text-token-no-value');
    if (!readonly) classNames.push('text-token-editable');
    if (!readonly && predefinedValuesOnly) classNames.push('text-token-editable-predefined');

    const renderPredefinedValuesPicker = !readonly && !!predefinedValues;
    const renderPredefinedValuesToggle = !!predefinedValues && !children;
    if (renderPredefinedValuesToggle) classNames.push('text-token-drop-down');
    const [isValid, setIsValid] = useState(true);
    if ((!isValid || invalid) && !readonly) classNames.push('k-invalid');

    const isFreeTextInput = !readonly && !predefinedValuesOnly;
    const suppressTogglePredefinedValuesPicker = useRef(false);
    const [showPredefinedValuesPicker, setShowPredefinedValuesPicker] = useState(false);

    useImperativeHandle(
        ref,
        () => ({
            focus() {
                if (readonly) return;
                if (isFreeTextInput) freeTextEditorRef.current?.focus();
                else elementRef.current?.focus();
            }
        }),
        [isFreeTextInput, readonly]
    );

    useEffect(() => {
        if (!showPredefinedValuesPicker) return;

        function onWindowClick(e: MouseEvent) {
            const currentElement = elementRef.current;
            if (!currentElement) return;

            // The click is inside the current element or the focus is within the current element
            if ((e.target && currentElement.contains(e.target as Node)) || currentElement.contains(document.activeElement)) return;

            setShowPredefinedValuesPicker(false);
        }

        window.addEventListener('click', onWindowClick);

        return () => {
            window.removeEventListener('click', onWindowClick);
        };
    }, [showPredefinedValuesPicker]);

    const applyValidation = useCallback(
        (value: string) => {
            if (!validator || readonly) setIsValid(true);
            else setIsValid(validator(value));
        },
        [readonly, validator]
    );

    useEffect(() => {
        if (!isFreeTextInput || !freeTextEditorRef.current) return;

        const currentFreeTextValue = freeTextEditorRef.current.value;
        if ((!children && !currentFreeTextValue) || children === currentFreeTextValue) return;
        freeTextEditorRef.current.value = children ?? '';
    }, [isFreeTextInput, children]);

    useEffect(() => {
        const currentValue = isFreeTextInput ? freeTextEditorRef.current?.value : children;
        applyValidation(currentValue ?? '');
    }, [applyValidation, children, isFreeTextInput]);

    const dropDownIcon = renderPredefinedValuesToggle ? (
        <>
            &#8288;{/*No break (Word-Joiner) character */}
            <span className={combineClassNames('k-icon !k-d-inline k-i-arrow-60-down k-ml-thin', readonly ? 'k-disabled' : 'k-cursor-pointer')} />
        </>
    ) : (
        undefined
    );

    function handlePredefinedItemSelected(predefinedItem: TextTokenPredefinedItem) {
        if (isFreeTextInput && freeTextEditorRef.current) freeTextEditorRef.current.value = predefinedItem.text;
        applyValidation(predefinedItem.text);
        onPredefinedItemSelected?.(predefinedItem);
    }

    function applyFreeTextValue() {
        const freeTextValue = freeTextEditorRef.current?.value ?? '';
        if ((!freeTextValue && !children) || freeTextValue === children) return;

        applyValidation(freeTextValue);
        const updatedValue = onChange?.({ value: freeTextValue });
        if (typeof updatedValue === 'string' && updatedValue !== freeTextValue && freeTextEditorRef.current) freeTextEditorRef.current.value = updatedValue;
    }

    const elementRef = useRef<HTMLSpanElement>(null);
    const freeTextEditorRef = useRef<PlaintTextContentEditableHandle>(null);

    return (
        <span
            ref={elementRef}
            className={classNames.join(' ')}
            onClick={() => {
                if (!renderPredefinedValuesPicker || suppressTogglePredefinedValuesPicker.current) return;
                if (!togglePredefinedItemsOnClick && showPredefinedValuesPicker) return;
                setShowPredefinedValuesPicker(s => !s);
            }}
            onKeyDown={
                renderPredefinedValuesPicker
                    ? e => {
                          if (!renderPredefinedValuesPicker || !predefinedValues.length) return;
                          if (e.key === 'Enter' || e.key === 'Escape') {
                              setShowPredefinedValuesPicker(false);
                              return;
                          }

                          if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return;
                          e.preventDefault();

                          const currentValue = isFreeTextInput ? freeTextEditorRef.current?.value : children;
                          if (!currentValue) {
                              handlePredefinedItemSelected(predefinedValues[0]);
                              return;
                          }
                          const selectedPredefinedItemIndex = predefinedValues.findIndex(v => v.text === currentValue);
                          if (selectedPredefinedItemIndex === -1) {
                              handlePredefinedItemSelected(predefinedValues[0]);
                              return;
                          }

                          let newPredefinedItemIndex = (selectedPredefinedItemIndex + (e.key === 'ArrowDown' ? 1 : -1)) % predefinedValues.length;
                          if (newPredefinedItemIndex < 0) newPredefinedItemIndex = predefinedValues.length - 1 + selectedPredefinedItemIndex;
                          handlePredefinedItemSelected(predefinedValues[newPredefinedItemIndex]);
                      }
                    : undefined
            }
            tabIndex={!readonly && predefinedValuesOnly ? 0 : undefined}
            onFocus={() => {
                if (!renderPredefinedValuesPicker) return;
                suppressTogglePredefinedValuesPicker.current = true;
                setShowPredefinedValuesPicker(true);
            }}
            onBlur={() => {
                if (!renderPredefinedValuesPicker) return;
                suppressTogglePredefinedValuesPicker.current = true;
                setShowPredefinedValuesPicker(false);
            }}
        >
            {isFreeTextInput ? (
                <>
                    <PlaintTextContentEditable
                        ref={freeTextEditorRef}
                        onBlur={applyFreeTextValue}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                e.stopPropagation();
                                freeTextEditorRef.current?.blur();
                            } else if (e.key === 'Escape') {
                                if (freeTextEditorRef.current) {
                                    e.stopPropagation();
                                    const valueToRestore = children ?? '';
                                    freeTextEditorRef.current.value = valueToRestore;
                                    applyValidation(valueToRestore);
                                    freeTextEditorRef.current.blur();
                                }
                            }
                        }}
                        onChange={validator ? e => applyValidation(e.value) : undefined}
                        placeholder={children ? children : placeholder}
                        initialValue={children}
                    />
                    {!children && dropDownIcon}
                </>
            ) : (
                children ?? (
                    <>
                        <span className="k-icp-subtle-text k-icp-ghost">{placeholder}</span>
                        {dropDownIcon}
                    </>
                )
            )}

            {renderPredefinedValuesPicker && (
                <ListContainer
                    popupSettings={{
                        show: showPredefinedValuesPicker,
                        anchor: elementRef.current,
                        className: 'k-list-container',
                        style: { minWidth: elementRef.current ? Math.min(elementRef.current.offsetWidth, 275) : undefined, maxWidth: 275 },
                        onOpen() {
                            suppressTogglePredefinedValuesPicker.current = false;
                        },
                        onClose() {
                            suppressTogglePredefinedValuesPicker.current = false;
                        }
                    }}
                >
                    <div className={`k-list k-list-${kendoThemeMaps.sizeMap[predefinedItemsSize]}`}>
                        {predefinedValuesHint && (
                            <div className="k-icp-text-sm k-icp-subtle-text k-list-item k-no-click k-icp-component-border !k-border-b !k-border-b-solid ">
                                {predefinedValuesHint}
                            </div>
                        )}
                        <List
                            data={predefinedValues}
                            onClick={index => handlePredefinedItemSelected(predefinedValues[index])}
                            show={showPredefinedValuesPicker}
                            textField="text"
                            valueField="value"
                            value={children ? predefinedValues.find(v => v.text === children) : undefined}
                            wrapperCssClass="k-list-content"
                            onMouseDown={e => {
                                e.preventDefault();
                            }}
                            noDataRender={
                                emptyPredefinedValuesText
                                    ? e => React.cloneElement(e, undefined, <div className="k-px-1 k-text-uppercase">{emptyPredefinedValuesText}</div>)
                                    : undefined
                            }
                            itemRender={predefinedItemRender}
                        />
                    </div>
                </ListContainer>
            )}
        </span>
    );
});

export const requiredTextTokenValidator: TextTokenValidator = (value: string) => {
    return !!value;
};

export type TextTokensEditorLayoutProps = {
    inEdit?: boolean;
    bordered?: boolean;
    children: ReactNode;
    className?: string;
    contentClassName?: string;
    compactLines?: boolean;
    invalid?: boolean;
    onClick?: () => void;
};
export const TextTokensEditorLayout = forwardRef<HTMLDivElement, TextTokensEditorLayoutProps>(function TextTokensEditorLayout(
    { inEdit, bordered, children, className, contentClassName, compactLines, invalid, onClick },
    ref
) {
    const elementClassName = combineClassNames(compactLines ? undefined : 'text-tokens-editor', contentClassName);
    if (!bordered)
        return (
            <div ref={ref} className={combineClassNames(elementClassName, className)} onClick={onClick}>
                {children}
            </div>
        );

    if (inEdit)
        return (
            <div ref={ref} className={combineClassNames('k-input k-input-solid k-rounded-md', invalid ? 'k-invalid' : undefined, className)} onClick={onClick}>
                <div className={combineClassNames(elementClassName, 'k-input-inner')}>{children}</div>
            </div>
        );

    return (
        <div
            ref={ref}
            className={combineClassNames(
                `${elementClassName} k-px-2 k-py-1 k-rounded k-border-solid k-border ${invalid ? 'k-border-error' : 'k-icp-component-border'}`,
                className
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
});
