import { ReachOutConnectionType, ReachOutType } from '../../services/events';
import { EmailMessageReachOutTemplate, MessageReachOutTemplate, PhoneCallReachOutTemplate, ResearchReachOutTemplate } from '../../services/researchService';

export type InviteContentSectionDetail = {
    key: string;
    label: string;
    isIVAGenerated?: boolean;
    content: {
        html: string;
        styled: string;
        plainText: string;
    };
};

export type InviteContentSection = {
    key: string;
    title?: string;
    descriptionHtml?: string;
    details: InviteContentSectionDetail[];
};

export type InviteContentData = {
    sections: InviteContentSection[];
};

export type InviteeParameters = {
    inviteeFirstName: string;
    inviteeEmail?: string;
    hostFirstName: string;
    hostLastName: string;
    meetingDuration: number;
    bookingPageUrl: string;
    hostPhoneNumber?: string | null;
};

export type InviteContentVariations = {
    html: string;
    plainText: string;
    styled: string;
};

class ProblemValidationInviteContentProvider {
    getInviteData(inviteContent: ResearchReachOutTemplate, inviteeParams: InviteeParameters): InviteContentData {
        const replaceParams: Map<string, string> = new Map([
            ['hostFirstName', inviteeParams.hostFirstName],
            ['hostLastName', inviteeParams.hostLastName],
            ['inviteeFirstName', inviteeParams.inviteeFirstName],
            ['bookingUrl', inviteeParams.bookingPageUrl],
            ['meetingDuration', inviteeParams.meetingDuration.toString()],
            ['hostPhoneNumber', inviteeParams.hostPhoneNumber ? inviteeParams.hostPhoneNumber : '']
        ]);

        if (inviteContent.reachOutType === ReachOutType.Phone) {
            return this.getPhoneInviteData(inviteContent, inviteeParams, replaceParams);
        }

        return this.getDefaultInviteData(inviteContent, inviteeParams, replaceParams);
    }

    private getDefaultInviteData(
        inviteContent: MessageReachOutTemplate | EmailMessageReachOutTemplate,
        inviteeParams: InviteeParameters,
        replaceParams: Map<string, string>
    ): InviteContentData {
        const preferredServiceText =
            inviteContent.reachOutType === ReachOutType.Email
                ? 'your preferred email service'
                : inviteContent.reachOutType === ReachOutType.LinkedIn
                ? 'LinkedIn'
                : 'your preferred service';

        const description = this.styleHtml(
            `Copy the script below in your reach out.${
                inviteContent.connectionType === ReachOutConnectionType.Referred
                    ? ` Ensure all {{placeholders}} are appropriately adjusted before you use ${preferredServiceText} to send it out.`
                    : ''
            }`
        );

        const contentSection: InviteContentSection = {
            key: 'default',
            descriptionHtml: description,
            details: []
        };

        if (inviteContent.reachOutType === ReachOutType.Email && inviteeParams.inviteeEmail)
            contentSection.details.push({
                key: 'to',
                label: 'To email',
                content: this.adjustHtmlContent(inviteeParams.inviteeEmail)
            });

        if (inviteContent.reachOutType === ReachOutType.Email)
            contentSection.details.push({
                key: 'subject',
                label: 'Subject',
                content: this.adjustHtmlContent(inviteContent.subject)
            });

        const replacedParamsMessage = this.replacePlaceholders(inviteContent.message, replaceParams);
        contentSection.details.push({
            key: 'message',
            label: 'Message',
            isIVAGenerated: true,
            content: this.adjustHtmlContent(replacedParamsMessage)
        });

        return {
            sections: [contentSection]
        };
    }

    private getPhoneInviteData(
        phoneReachOutTemplate: PhoneCallReachOutTemplate,
        inviteeParams: InviteeParameters,
        replaceParams: Map<string, string>
    ): InviteContentData {
        const followUpDetails: InviteContentSectionDetail[] = [];
        if (inviteeParams.inviteeEmail) {
            followUpDetails.push({
                key: 'to',
                label: 'To email',
                content: this.adjustHtmlContent(inviteeParams.inviteeEmail)
            });
        }

        followUpDetails.push({
            key: 'subject',
            label: 'Subject',
            content: this.adjustHtmlContent(phoneReachOutTemplate.followUpSubject)
        });

        const replacedParamsHtml = this.replacePlaceholders(phoneReachOutTemplate.followUpMessage, replaceParams);
        followUpDetails.push({
            key: 'message',
            label: 'Message',
            isIVAGenerated: true,
            content: this.adjustHtmlContent(replacedParamsHtml)
        });

        const replacedParamsScript = this.replacePlaceholders(phoneReachOutTemplate.callScript, replaceParams);

        return {
            sections: [
                {
                    key: 'call',
                    title: 'Step 1: Phone call',
                    descriptionHtml: 'Use the script below during the call with this Contact. Practice it at least once before calling.',
                    details: [
                        {
                            key: 'script',
                            label: 'Script',
                            isIVAGenerated: true,
                            content: this.adjustHtmlContent(replacedParamsScript)
                        }
                    ]
                },
                {
                    key: 'followUp',
                    title: 'Step 2: Follow up email',
                    descriptionHtml: this.styleHtml('If the Contact agrees, follow up with the script below containing your availability.'),
                    details: followUpDetails
                }
            ]
        };
    }

    private adjustHtmlContent(htmlContent: string): { html: string; styled: string; plainText: string } {
        return {
            html: htmlContent,
            styled: this.styleHtml(htmlContent),
            plainText: this.convertHtmlToPlainText(htmlContent)
        };
    }

    //A naive implementation of generating different content variations.
    //For more complex cases we can consider using some proper HTML parser
    private styleHtml(htmlContent: string): string {
        const regex = /{{[^}]*}}/g;
        const styledContent = htmlContent.replace(regex, match => `<span class="k-rounded-sm k-icp-bg-primary-16">${match}</span>`);

        return styledContent.replace(/<a\b/g, '<a class="k-button-link-secondary"');
    }

    private replacePlaceholders(text: string, values: Map<string, string>): string {
        const regex = /\$\$(\w+)\$\$/g;

        return text.replace(regex, (match, placeholder) => {
            if (values.has(placeholder)) {
                return values.get(placeholder)!;
            }
            return match;
        });
    }

    private convertHtmlToPlainText(htmlContent: string): string {
        // Replace links in the specified format
        htmlContent = htmlContent.replace(/<a\s+href="([^"]*)"[^>]*>(.*?)<\/a>/gi, (match, url, text) => `${text} (${url})`);

        // Replace <br>, <br/>, and </br> tags with newlines
        htmlContent = htmlContent.replace(/<br\s*\/?>/gi, '\n');

        // Replace paragraph closing tags with double newlines
        htmlContent = htmlContent.replace(/<\/p>/gi, '\n\n');

        // Remove all remaining HTML tags
        let plainText = htmlContent.replace(/<\/?[^>]+(>|$)/g, '');

        // Replace common HTML entities
        const htmlEntities: { [key: string]: string } = {
            '&nbsp;': ' ',
            '&amp;': '&',
            '&lt;': '<',
            '&gt;': '>',
            '&quot;': '"',
            '&#39;': "'",
            '&apos;': "'",
            '&cent;': '¢',
            '&pound;': '£',
            '&yen;': '¥',
            '&euro;': '€',
            '&copy;': '©',
            '&reg;': '®'
        };

        for (const [entity, char] of Object.entries(htmlEntities)) {
            plainText = plainText.replace(new RegExp(entity, 'g'), char);
        }

        // Replace multiple consecutive newlines with a single newline
        plainText = plainText.replace(/\n{3,}/g, '\n\n');

        // Replace multiple consecutive spaces with a single space
        plainText = plainText.replace(/[ \t]+/g, ' ');

        // Trim leading and trailing whitespace
        plainText = plainText.trim();

        return plainText;
    }
}

export const problemValidationInviteContentProvider = new ProblemValidationInviteContentProvider();
