import { debounce } from '../services/common';
import { domService } from '../services/domService';

type StickDirection = 'top' | 'bottom';

export class StickyElementObserver {
    private stickyHeaderObserver?: IntersectionObserver;

    constructor(
        protected readonly navElement: HTMLElement,
        protected readonly scrollContainerElement: HTMLElement,
        private readonly stuckClass?: string,
        private readonly direction: StickDirection = 'top'
    ) {}

    public init() {
        this.stickyHeaderObserver = this.initStickyHeaderObserver();
    }

    private initStickyHeaderObserver(): IntersectionObserver {
        const stickyMarginValue = this.getBoundingMarginStickyBaseValue() - 1; // Subtract 1 in order to allow intersection which will trigger the IntersectionObserver
        const rootMargin =
            this.direction === 'top' ? `${stickyMarginValue}px 0px 0px 0px` : this.direction === 'bottom' ? `0px 0px ${stickyMarginValue}px 0px` : undefined;

        const headerObserver = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting || entry.intersectionRatio === 1) {
                    this.onUnstuck();
                } else {
                    this.onStuck();
                }
            },
            { threshold: 1, root: this.scrollContainerElement, rootMargin: rootMargin }
        );

        headerObserver.observe(this.navElement);

        return headerObserver;
    }

    private getBoundingMarginStickyBaseValue() {
        if (!('safari' in window)) return 0;

        // Only Safari is taking into account the top/bottom values of the sticky element when calculating the intersection
        // Most probably at some point the algorithm in Safari will be unified with the algorithm in the other browsers (because the other browsers switched from the behavior in Safari to the new one) so this method will have to be removed then
        const navComputedStyle = window.getComputedStyle(this.navElement);
        const stickDistance = navComputedStyle[this.direction];
        if (!stickDistance || !stickDistance.endsWith('px')) throw new Error(`Sticky element ${this.direction} value should be set in pixels`);

        return -parseInt(stickDistance);
    }

    private onStuck() {
        if (!this.stuckClass) return;

        this.navElement.classList.add(this.stuckClass);
    }

    private onUnstuck() {
        if (!this.stuckClass) return;

        this.navElement.classList.remove(this.stuckClass);
    }

    destroy() {
        this.stickyHeaderObserver?.disconnect();
    }
}

abstract class StickyScrollingNav extends StickyElementObserver {
    private readonly anchorScrollOffset = 8;

    private readonly onContainerScrollDelegate = debounce(this.onContainerScroll.bind(this), 100);

    private lastScrolledToElement: HTMLElement | undefined;

    constructor(navElement: HTMLElement, scrollContainerElement: HTMLElement, trackStickiness: boolean, stuckClass?: string) {
        super(navElement, scrollContainerElement, stuckClass);
        if (trackStickiness) super.init();
        this.scrollContainerElement.addEventListener('scroll', this.onContainerScrollDelegate);
    }

    private onContainerScroll() {
        this.highlightNavItem();
    }

    private highlightNavItem() {
        let bestScrollToTargets: HTMLElement[] = [];
        let bestScrollToDistance = Number.MAX_SAFE_INTEGER;

        const scrollToTargets = this.getScrollToTargets();
        for (let scrollToTargetIndex = 0; scrollToTargetIndex < scrollToTargets.length; scrollToTargetIndex++) {
            const scrollToTarget = scrollToTargets[scrollToTargetIndex];

            const scrollToTargetOffsetTop = this.calculateElementScrollOffset(scrollToTarget);
            const scrollToTargetDistance = this.scrollContainerElement.scrollTop - scrollToTargetOffsetTop;

            if (scrollToTargetDistance >= 0) {
                if (scrollToTargetDistance < bestScrollToDistance) {
                    bestScrollToTargets = [scrollToTarget];
                    bestScrollToDistance = scrollToTargetDistance;
                } else if (scrollToTargetDistance === bestScrollToDistance) {
                    bestScrollToTargets.push(scrollToTarget);
                }
            }
        }

        let bestScrollToTarget: HTMLElement | undefined = undefined;
        if (bestScrollToTargets.length === 1) bestScrollToTarget = bestScrollToTargets[0];
        else if (bestScrollToTargets.length > 1) {
            if (this.lastScrolledToElement && bestScrollToTargets.includes(this.lastScrolledToElement)) bestScrollToTarget = this.lastScrolledToElement;
            else bestScrollToTarget = bestScrollToTargets[0];
        }

        this.highlightNavItemsPointingTo(bestScrollToTarget);
    }

    protected abstract getScrollToTargets(): HTMLElement[];
    protected abstract highlightNavItemsPointingTo(scrollToTarget: HTMLElement | undefined): void;

    private calculateElementScrollOffset(element: HTMLElement) {
        const scrollTop = element.offsetTop - this.getScrollToElementOffset(element) - this.anchorScrollOffset;
        const maxScrollTop = this.scrollContainerElement.scrollHeight - this.scrollContainerElement.clientHeight;
        return scrollTop > maxScrollTop ? maxScrollTop : scrollTop;
    }

    protected abstract getScrollToElementOffset(element: HTMLElement): number;

    protected scrollToElement(element: HTMLElement): void {
        this.lastScrolledToElement = element;
        domService.verticallyScrollToPosition(this.scrollContainerElement, () => this.calculateElementScrollOffset(element));
    }

    destroy() {
        super.destroy();
        this.scrollContainerElement.removeEventListener('scroll', this.onContainerScrollDelegate);
    }
}

export class StickyHeaderObserver extends StickyScrollingNav {
    private readonly onHeaderLinkClickDelegate = this.onHeaderLinkClick.bind(this);

    private readonly headerLinks?: HTMLAnchorElement[];
    private readonly anchorTargets?: HTMLElement[];
    private readonly anchorTargetToHeaderLinksMap?: Map<HTMLElement, HTMLElement[]>;

    constructor(
        headerElement: HTMLElement,
        scrollContainerElement: HTMLElement,
        stuckClass?: string,
        private readonly headerLinkClassName?: string,
        private readonly selectedHeaderLinkClassName?: string
    ) {
        super(headerElement, scrollContainerElement, true, stuckClass);

        if (this.headerLinkClassName) {
            this.headerLinks = Array.prototype.slice.call(this.navElement.getElementsByClassName(this.headerLinkClassName));
            [this.anchorTargets, this.anchorTargetToHeaderLinksMap] = this.buildAnchorTargetsData();
            this.registerHeaderLinkClickHandler();
        }
    }

    private buildAnchorTargetsData(): [HTMLElement[], Map<HTMLElement, HTMLElement[]>] {
        if (!this.headerLinks) return [[], new Map()];

        const anchorTargets: HTMLElement[] = [];
        const anchorTargetToHeaderLinksMap = new Map<HTMLElement, HTMLElement[]>();

        for (const headerLink of this.headerLinks) {
            const anchorTarget = this.getAnchorTarget(headerLink);
            if (!anchorTarget) continue;

            anchorTargets.push(anchorTarget);

            const linksPointingToAnchorTarget = anchorTargetToHeaderLinksMap.get(anchorTarget);
            if (linksPointingToAnchorTarget) linksPointingToAnchorTarget.push(headerLink);
            else anchorTargetToHeaderLinksMap.set(anchorTarget, [headerLink]);
        }

        return [anchorTargets, anchorTargetToHeaderLinksMap];
    }

    private registerHeaderLinkClickHandler() {
        this.headerLinks?.forEach(l => l.addEventListener('click', this.onHeaderLinkClickDelegate));
    }

    private unregisterHeaderLinkClickHandler() {
        this.headerLinks?.forEach(l => l.removeEventListener('click', this.onHeaderLinkClickDelegate));
    }

    private onHeaderLinkClick(e: MouseEvent) {
        const clickedLink = e.currentTarget as HTMLAnchorElement;
        const scrollToElement = this.getAnchorTarget(clickedLink);
        if (!scrollToElement) return;

        e.preventDefault();

        if (this.selectedHeaderLinkClassName && this.headerLinks) {
            const selectedClass = this.selectedHeaderLinkClassName;
            this.headerLinks.forEach(l => (l === clickedLink ? l.classList.add(selectedClass) : l.classList.remove(selectedClass)));
        }

        this.scrollToElement(scrollToElement);
    }

    protected getScrollToTargets(): HTMLElement[] {
        return this.anchorTargets || [];
    }

    protected highlightNavItemsPointingTo(scrollToTarget: HTMLElement | undefined) {
        if (!this.anchorTargetToHeaderLinksMap) return;

        const headerLinksPointingToAnchorTarget = scrollToTarget ? this.anchorTargetToHeaderLinksMap.get(scrollToTarget) : [];

        if (this.selectedHeaderLinkClassName && this.headerLinks) {
            const selectedClass = this.selectedHeaderLinkClassName;
            this.headerLinks.forEach(l =>
                headerLinksPointingToAnchorTarget?.includes(l) ? l.classList.add(selectedClass) : l.classList.remove(selectedClass)
            );
        }
    }

    protected getScrollToElementOffset(element: HTMLElement): number {
        return this.navElement.offsetHeight;
    }

    private getAnchorTarget(anchorElement: HTMLAnchorElement) {
        const scrollToSelector = anchorElement.getAttribute('href');
        if (!scrollToSelector || !scrollToSelector.startsWith('#')) return;

        const anchorTarget = this.scrollContainerElement.querySelector<HTMLElement>(scrollToSelector);
        if (!anchorTarget) return;

        return anchorTarget;
    }

    destroy() {
        super.destroy();
        this.unregisterHeaderLinkClickHandler();
    }
}

export class StickySideNavObserver extends StickyScrollingNav {
    private onWindowResizeDelegate = debounce(this.onWindowResize.bind(this), 100);

    constructor(
        sidebar: HTMLElement,
        scrollContainerElement: HTMLElement,
        private readonly scrollTargets: HTMLElement[],
        private readonly onScrolledToElement: (element: HTMLElement | undefined) => void,
        private readonly headerElement?: HTMLElement | null
    ) {
        super(sidebar, scrollContainerElement, false);

        if (this.headerElement) {
            this.updateTopOffset();
            window.addEventListener('resize', this.onWindowResizeDelegate);
        }
    }

    private updateTopOffset() {
        if (!this.headerElement) return;

        this.navElement.style.top = this.headerElement.offsetHeight + 'px';
    }

    private onWindowResize() {
        this.updateTopOffset();
    }

    protected getScrollToTargets(): HTMLElement[] {
        return this.scrollTargets;
    }

    protected highlightNavItemsPointingTo(scrollToTarget: HTMLElement | undefined): void {
        this.onScrolledToElement(scrollToTarget);
    }

    protected getScrollToElementOffset(element: HTMLElement): number {
        return this.headerElement?.offsetHeight ?? 0;
    }

    public scrollToTarget(element: HTMLElement) {
        if (this.scrollTargets.includes(element)) this.scrollToElement(element);
    }

    destroy(): void {
        super.destroy();
        window.removeEventListener('resize', this.onWindowResizeDelegate);
    }
}
