import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ScheduleEditor, ScheduleEditorHandle } from '../../components/events/scheduleEditor';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { useStickyFooter } from '../../hooks/commonHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { dateTimeService } from '../../services/dateTimeService';
import { ProblemValidationResearch, researchService } from '../../services/researchService';
import { FullSchedule, ScheduleData, Timetable, schedulesService } from '../../services/schedulesService';
import { ReducedUser } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

export function CreateSchedulePage() {
    const { ideaId } = useIdeaParams();
    const [queryParams] = useSearchParams();
    const researchIdParam = queryParams.get('researchId');
    const researchId = researchIdParam ? parseInt(researchIdParam) : undefined;
    const footerRef = useStickyFooter();
    const navigate = useNavigate();
    const currentUser = useAppSelector(s => s.user);
    const scheduleEditorRef = useRef<ScheduleEditorHandle>(null);
    const [initialDataForResearch, setInitialDataForResearch] = useState<Omit<ScheduleData, 'timetable'>>();
    const [research, setResearch] = useState<ProblemValidationResearch>();

    useEffect(() => {
        if (researchId === undefined) return;
        schedulesService.getScheduleDataForResearch(ideaId, researchId).then(setInitialDataForResearch);
        researchService.getProblemValidationResearch(ideaId, researchId).then(setResearch);
    }, [ideaId, researchId]);

    const isLoading = !currentUser || (researchId !== undefined && !initialDataForResearch);

    return (
        <>
            <H1 className="!k-mb-6 !k-px-6">Add schedule</H1>
            {isLoading ? (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            ) : (
                <Form
                    onSubmitClick={() => {
                        scheduleEditorRef.current?.validate();
                    }}
                    onSubmit={async (data: Record<string, any>) => {
                        const timetableData: Partial<Timetable> | undefined = data.timetable;
                        const timetable: Timetable = timetableData
                            ? { entries: timetableData.entries ?? [], specialEntries: timetableData.specialEntries ?? [] }
                            : { entries: [], specialEntries: [] };

                        const host: ReducedUser = data.user;
                        let newSchedule: FullSchedule;

                        if (researchId === undefined) {
                            const newScheduleData: ScheduleData = {
                                title: data.title,
                                startTime: data.scheduleRange.start,
                                endTime: data.scheduleRange.end,
                                durationMinutes: data.durationMinutes,
                                bufferBeforeMinutes: data.bufferBeforeMinutes ?? 0,
                                bufferAfterMinutes: data.bufferAfterMinutes ?? 0,
                                allowSameDayBooking: data.allowSameDayBooking ?? false,
                                timeZone: data.timeZone,
                                description: data.description,
                                locationOptions: data.locationOptions,
                                timetable: timetable
                            };
                            newSchedule = await schedulesService.createScheduleForUser(ideaId, host.userId, newScheduleData);
                        } else {
                            newSchedule = await schedulesService.createScheduleForResearch(ideaId, researchId, {
                                userId: host.userId,
                                timeZone: data.timeZone,
                                locationOptions: data.locationOptions,
                                timetable: timetable
                            });
                        }
                        navigate(`./../${newSchedule.id}`);
                    }}
                    initialValues={{
                        durationMinutes: 45,
                        user: currentUser,
                        timeZone: dateTimeService.getCurrentTimeZone(),
                        ...initialDataForResearch,
                        scheduleRange: initialDataForResearch ? { start: initialDataForResearch.startTime, end: initialDataForResearch.endTime } : undefined
                    }}
                    ignoreModified={true}
                    render={formRenderProps => (
                        <FormElement noValidate={true}>
                            <div className="k-px-6">
                                <ScheduleEditor
                                    ref={scheduleEditorRef}
                                    ideaId={ideaId}
                                    className="page-content-middle"
                                    formRenderProps={formRenderProps}
                                    creatingForResearch={research}
                                />
                            </div>
                            <StackLayout
                                ref={r => (footerRef.current = r?.element ?? null)}
                                align={{ horizontal: 'center', vertical: 'top' }}
                                className="k-mt-11 k-gap-4 k-px-6 k-py-3 sticky-footer"
                            >
                                <Button themeColor="primary" type="submit" size="large" disabled={!formRenderProps.allowSubmit}>
                                    Save schedule
                                </Button>
                                <Button type="reset" size="large" onClick={() => navigate('./..')}>
                                    Cancel
                                </Button>
                            </StackLayout>
                        </FormElement>
                    )}
                />
            )}
        </>
    );
}
