import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Error as ErrorComponent } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { CSSProperties, ReactElement, ReactNode, useMemo, useRef, useState } from 'react';
import { useSoleToggle } from '../../hooks/toggleHooks';

import { flushSync } from 'react-dom';
import { ReactComponent as AddIcon } from '../../icons/plus.svg';
import { ReactComponent as DeleteIcon } from '../../icons/trash-2.svg';
import { ReactComponent as RemoveIcon } from '../../icons/x-circle.svg';
import { combineClassNames, getRandomId } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import {
    SpecialTimetableEntry,
    TimetableDay,
    TimetableDayValue,
    TimetableEntry,
    TimetableTime,
    TimetableTimeValue,
    schedulesService
} from '../../services/schedulesService';
import { KeyboardNavigatableDateInput } from '../common/date';
import { ValidationSubScope, ValidationSubScopeHandle, ValidationUnit } from '../common/validation';
import { ChangeOnBlurTimePicker, requiredValidator } from '../ui/inputs';
import { SvgIconButtonContent } from '../ui/svgIconButtonContent';
import { WeekDaysList } from '../ui/weekDaysList';

type EditableTimetableTime = TimetableTime | (Pick<TimetableTime, 'id'> & { delete?: boolean });
export function WeeklySlotsEditor({
    slotLength,
    value,
    onChange,
    disabled,
    triggerOnChangeIfInvalid
}: {
    slotLength: number;
    value?: TimetableEntry[];
    disabled?: boolean;
    onChange?: (e: { value: TimetableEntry[] }) => Promise<unknown>;
    triggerOnChangeIfInvalid?: boolean;
}) {
    const [uncommittedChanges, setUncommittedChanges] = useState<Partial<Record<number, EditableTimetableTime[]>>>({});
    const weekDaysValidationSubScopesRef = useRef<Partial<Record<number, ValidationSubScopeHandle>>>({});

    function getWeekDaySlots(weekDay: number): EditableTimetableTime[] | undefined {
        const savedStartTimes = value?.find(e => e.dayOfWeek === weekDay)?.startTimes;
        const uncommittedSlots = uncommittedChanges[weekDay];

        if (!uncommittedSlots || !uncommittedSlots.length) return savedStartTimes;

        const mergedSlots: EditableTimetableTime[] = savedStartTimes ? [...savedStartTimes] : [];
        const insertingSlots: EditableTimetableTime[] = [];
        for (const uncommittedSlot of uncommittedSlots) {
            const isSlotDeleted = isDeletedSlot(uncommittedSlot);
            const existingSlotIndex = mergedSlots.findIndex(s => s.id === uncommittedSlot.id);
            if (existingSlotIndex !== -1) {
                if (isSlotDeleted) mergedSlots.splice(existingSlotIndex, 1);
                else mergedSlots[existingSlotIndex] = uncommittedSlot;
            } else if (!isSlotDeleted) insertingSlots.push(uncommittedSlot);
        }

        if (insertingSlots.length) mergedSlots.push(...insertingSlots);
        return mergedSlots;
    }

    function clearWeekDayStartTimeUncommittedChange(weekDay: number, id: string) {
        setUncommittedChanges(uncommittedChanges => ({
            ...uncommittedChanges,
            [weekDay]: uncommittedChanges[weekDay]?.filter(s => s.id !== id)
        }));
    }

    function clearWeekDayStartTimeUncommittedChanges(weekDay: number) {
        setUncommittedChanges(uncommittedChanges => {
            const updatedUncommittedChanges = { ...uncommittedChanges };
            delete updatedUncommittedChanges[weekDay];
            return updatedUncommittedChanges;
        });
    }

    function isWeekDayValid(weekDay: number) {
        const weekDayValidationSubScope = weekDaysValidationSubScopesRef.current[weekDay];
        if (!weekDayValidationSubScope) return true;

        return weekDayValidationSubScope.isValid;
    }

    function getUpdatedValue(weekDay: number, startTimeOverride?: EditableTimetableTime): TimetableEntry[] {
        const updatedValue = value ? [...value] : [];
        const weekDayValueIndex = updatedValue.findIndex(e => e.dayOfWeek === weekDay);
        if (weekDayValueIndex === -1)
            updatedValue.push({
                dayOfWeek: weekDay,
                startTimes: mergeStartTimes([], uncommittedChanges[weekDay], startTimeOverride)
            });
        else {
            const updatedWeekDayValue = { ...updatedValue[weekDayValueIndex] };
            updatedValue[weekDayValueIndex] = updatedWeekDayValue;
            updatedWeekDayValue.startTimes = mergeStartTimes(updatedWeekDayValue.startTimes, uncommittedChanges[weekDay], startTimeOverride);
        }

        return updatedValue;
    }

    return (
        <WeekDaysList>
            {weekDay => {
                const weekDaySlots = getWeekDaySlots(weekDay);
                return (
                    <ValidationSubScope
                        key={weekDay}
                        ref={r => {
                            if (r) weekDaysValidationSubScopesRef.current[weekDay] = r;
                            else delete weekDaysValidationSubScopesRef.current[weekDay];
                        }}
                    >
                        <WeekDaySlotsEditor
                            key={weekDay}
                            weekDay={weekDay}
                            slotLength={slotLength}
                            slots={weekDaySlots}
                            disabled={disabled}
                            onAddSlot={async () => {
                                const currentStatTimes = weekDaySlots?.map(s => getSlotStartTime(s)).filter((st): st is TimetableTime => st !== undefined);
                                const newSlot: EditableTimetableTime = {
                                    ...schedulesService.findNextTimetableSlotStartTime(slotLength, currentStatTimes),
                                    id: generateSlotId(weekDaySlots)
                                };
                                flushSync(() => {
                                    setUncommittedChanges(uncommittedChanges => ({
                                        ...uncommittedChanges,
                                        [weekDay]: [...(uncommittedChanges[weekDay] ?? []), newSlot]
                                    }));
                                });

                                if (!isWeekDayValid(weekDay)) {
                                    if (triggerOnChangeIfInvalid) await onChange?.({ value: value ?? [] });
                                    return;
                                }

                                const updatedValue = getUpdatedValue(weekDay, newSlot);
                                await onChange?.({ value: updatedValue });

                                clearWeekDayStartTimeUncommittedChanges(weekDay);
                            }}
                            onDelete={async () => {
                                const entryToDelete = value?.find(e => e.dayOfWeek === weekDay);
                                const hasStartTimes = entryToDelete && entryToDelete.startTimes.length;
                                flushSync(() => {
                                    if (hasStartTimes)
                                        setUncommittedChanges(uncommittedChanges => ({
                                            ...uncommittedChanges,
                                            [weekDay]: entryToDelete.startTimes.map(st => ({ id: st.id, delete: true }))
                                        }));
                                    else clearWeekDayStartTimeUncommittedChanges(weekDay);
                                });

                                await onChange?.({ value: value?.filter(e => e.dayOfWeek !== weekDay) ?? [] });

                                if (hasStartTimes) clearWeekDayStartTimeUncommittedChanges(weekDay);
                            }}
                            onDeleteSlot={async id => {
                                const deleteSlotChange: EditableTimetableTime = { id, delete: true };
                                flushSync(() => {
                                    setUncommittedChanges(uncommittedChanges => {
                                        const weekDayUncommittedChanges = uncommittedChanges[weekDay];
                                        const updatedWeekDayUncommittedChanges = weekDayUncommittedChanges ? [...weekDayUncommittedChanges] : [];
                                        const slotIndex = updatedWeekDayUncommittedChanges.findIndex(s => s.id === id);
                                        if (slotIndex === -1) updatedWeekDayUncommittedChanges.push(deleteSlotChange);
                                        else updatedWeekDayUncommittedChanges[slotIndex] = deleteSlotChange;

                                        return {
                                            ...uncommittedChanges,
                                            [weekDay]: updatedWeekDayUncommittedChanges
                                        };
                                    });
                                });

                                if (!isWeekDayValid(weekDay)) {
                                    if (triggerOnChangeIfInvalid) await onChange?.({ value: value ?? [] });
                                    return;
                                }

                                const updatedValue = getUpdatedValue(weekDay, deleteSlotChange);
                                await onChange?.({ value: updatedValue });

                                clearWeekDayStartTimeUncommittedChange(weekDay, id);
                            }}
                            onCopyTo={async copyToWeekDays => {
                                if (!value || !copyToWeekDays.length) return;

                                const entitiesToCopy = value.filter(e => e.dayOfWeek === weekDay);
                                const updatedValue = value.filter(e => !copyToWeekDays.includes(e.dayOfWeek));

                                if (entitiesToCopy.length)
                                    for (const copyToWeekDay of copyToWeekDays) {
                                        updatedValue.push(...entitiesToCopy.map(e => ({ ...e, dayOfWeek: copyToWeekDay })));
                                    }

                                await onChange?.({ value: updatedValue });

                                setUncommittedChanges(uncommittedChanges => {
                                    let updatedUncommittedChanges: typeof uncommittedChanges | undefined;
                                    for (const copyToWeekDay of copyToWeekDays) {
                                        const uncommittedChangesForWeekDay = uncommittedChanges[weekDay];
                                        if (uncommittedChangesForWeekDay && uncommittedChangesForWeekDay.length) {
                                            updatedUncommittedChanges = updatedUncommittedChanges ?? { ...uncommittedChanges };
                                            delete updatedUncommittedChanges[copyToWeekDay];
                                        }
                                    }

                                    return updatedUncommittedChanges ?? uncommittedChanges;
                                });
                            }}
                            onChangeSlot={async (id, timeValue) => {
                                setUncommittedChanges(uncommittedChanges => {
                                    const weekDayUncommittedChanges = uncommittedChanges[weekDay];
                                    let slotUpdated = false;
                                    const updatedWeekDayUncommittedChanges = weekDayUncommittedChanges
                                        ? weekDayUncommittedChanges.map(slot => {
                                              if (slot.id === id) {
                                                  slotUpdated = true;
                                                  return updateSlot(slot, timeValue);
                                              }
                                              return slot;
                                          })
                                        : [];
                                    if (!slotUpdated) updatedWeekDayUncommittedChanges.push(updateSlot({ id: id }, timeValue));

                                    return { ...uncommittedChanges, [weekDay]: updatedWeekDayUncommittedChanges };
                                });

                                if (!isWeekDayValid(weekDay)) {
                                    if (triggerOnChangeIfInvalid) await onChange?.({ value: value ?? [] });
                                    return;
                                }

                                const updatedValue = getUpdatedValue(weekDay, updateSlot({ id: id }, timeValue));
                                await onChange?.({ value: updatedValue });

                                clearWeekDayStartTimeUncommittedChange(weekDay, id);
                            }}
                        />
                    </ValidationSubScope>
                );
            }}
        </WeekDaysList>
    );
}

function WeekDaySlotsEditor({
    weekDay,
    slotLength,
    slots,
    disabled,
    onDelete,
    onAddSlot,
    onCopyTo,
    onDeleteSlot,
    onChangeSlot
}: {
    weekDay: number;
    slotLength: number;
    slots?: EditableTimetableTime[];
    disabled?: boolean;
    onDelete?: () => void;
    onAddSlot?: () => void;
    onCopyTo?: (weekDays: number[]) => void;
    onDeleteSlot?: (id: string) => void;
    onChangeSlot?: (id: string, value: TimetableTime | undefined) => void;
}) {
    const hasSlots = slots && slots.length > 0;

    return (
        <SlotsEditorRow>
            <div style={{ width: 140 }} className={combineClassNames('k-icp-text-input-like', hasSlots ? 'k-font-weight-semibold' : undefined)}>
                {dateTimeService.getWeekDayName(weekDay)}
            </div>
            <div className="k-flex-1">
                <SlotsListEditor
                    validationPrefix={weekDay.toString()}
                    slotLength={slotLength}
                    slots={slots}
                    onDelete={onDeleteSlot}
                    onChange={onChangeSlot}
                    disabled={disabled}
                />
            </div>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-mt-1 k-gap-2">
                <Button size="small" fillMode="flat" icon="plus" type="button" disabled={disabled} onClick={onAddSlot} />
                <WeekDayCopyButton weekDay={weekDay} disabled={disabled} onCopy={onCopyTo} />
                <Button size="small" fillMode="flat" type="button" disabled={disabled || !hasSlots} className="k-icp-svg-icon-button" onClick={onDelete}>
                    <DeleteIcon className="k-icp-icon" />
                </Button>
            </StackLayout>
        </SlotsEditorRow>
    );
}

function SlotsListEditor({
    validationPrefix,
    slotLength,
    slots,
    disabled,
    onDelete,
    onChange
}: {
    validationPrefix: string;
    slotLength: number;
    slots?: EditableTimetableTime[];
    disabled?: boolean;
    onDelete?: (id: string) => void;
    onChange?: (id: string, value: TimetableTime | undefined) => void;
}) {
    const overlappingSlotsRef = useRef<Set<string>>();

    if (!slots || !slots.length) return <span className="k-display-inline-block k-icp-text-input-like k-icp-subtle-text">Unavailable</span>;

    return (
        <ValidationUnit
            name={`${validationPrefix}_overlapping`}
            value={slots}
            validator={slotsToValidate => {
                overlappingSlotsRef.current = getOverlappingSlots(slotsToValidate, slotLength);

                return overlappingSlotsRef.current.size ? 'Slots cannot overlap' : undefined;
            }}
        >
            {(overlappingSlotsError, overlappingValidationOnChange) => {
                const triggerOverlappingValidationOnChange = (id: string, updatedSlotStartTime: EditableTimetableTime | undefined) => {
                    return overlappingValidationOnChange(
                        updatedSlotStartTime ? slots.map(s => (s.id === id ? updatedSlotStartTime : s)) : slots.filter(s => s.id !== id)
                    );
                };

                return (
                    <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                        {slots.map(slot => {
                            const startTime = getSlotStartTime(slot);
                            const endTime = startTime ? schedulesService.tryAddMinutesToTimetableTime(startTime, slotLength) : undefined;

                            return (
                                <StackLayout key={slot.id} align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                                    <ValidationUnit
                                        name={`${validationPrefix}_${slot.id}`}
                                        value={startTime}
                                        validator={value => {
                                            if (!value) return 'Start time is required';
                                            const newEndTime = schedulesService.tryAddMinutesToTimetableTime(value, slotLength);
                                            if (!newEndTime) return 'Invalid start time';
                                        }}
                                    >
                                        {(errorMessage, validationOnChange, reset) => (
                                            <>
                                                <TimetableTimeEditor
                                                    label="Start:"
                                                    value={startTime}
                                                    disabled={disabled}
                                                    errorMessage={
                                                        errorMessage || (overlappingSlotsRef.current?.has(slot.id) ? overlappingSlotsError : undefined)
                                                    }
                                                    onChange={e => {
                                                        const updatedSlot = updateSlot(slot, e.value);
                                                        const updatedSlotValue = getSlotStartTime(updatedSlot);
                                                        validationOnChange(updatedSlotValue);
                                                        triggerOverlappingValidationOnChange(slot.id, updatedSlot);
                                                        onChange?.(slot.id, updatedSlotValue);
                                                    }}
                                                />
                                                <div className="k-fs-sm k-text-center" style={{ marginTop: 7, width: 16 }}>
                                                    &gt;
                                                </div>
                                                <TimetableTimeView label="End:" time={endTime} style={{ marginTop: 5.5 }} />
                                                <Button
                                                    type="button"
                                                    size="small"
                                                    fillMode="flat"
                                                    className="k-icp-svg-icon-button k-mt-1"
                                                    onClick={() => {
                                                        reset();
                                                        triggerOverlappingValidationOnChange(slot.id, undefined);
                                                        onDelete?.(slot.id);
                                                    }}
                                                    disabled={disabled}
                                                >
                                                    <RemoveIcon className="k-icp-icon" />
                                                </Button>
                                            </>
                                        )}
                                    </ValidationUnit>
                                </StackLayout>
                            );
                        })}
                    </StackLayout>
                );
            }}
        </ValidationUnit>
    );
}

function WeekDayCopyButton({ weekDay, onCopy, disabled }: { weekDay: number; onCopy?: (weekDays: number[]) => void; disabled?: boolean }) {
    const [copyToDays, setCopyToDays] = useState<number[]>([]);

    return (
        <SlotsCopyButton
            copyEditor={
                <WeekDaysList>
                    {copyToWeekDay => (
                        <div key={copyToWeekDay} className="k-px-2 k-py-1">
                            <Checkbox
                                label={dateTimeService.getWeekDayName(copyToWeekDay)}
                                onFocusCapture={e => e.preventDefault()}
                                onFocus={e => e.syntheticEvent.preventDefault()}
                                size="small"
                                checked={copyToWeekDay === weekDay || copyToDays.includes(copyToWeekDay)}
                                disabled={copyToWeekDay === weekDay}
                                onChange={e => {
                                    if (e.value) setCopyToDays(d => [...d, copyToWeekDay]);
                                    else setCopyToDays(d => d.filter(wd => wd !== copyToWeekDay));
                                }}
                            />
                        </div>
                    )}
                </WeekDaysList>
            }
            onCopy={() => {
                if (!copyToDays.length) return;
                onCopy?.(copyToDays);
            }}
            disabled={disabled}
        />
    );
}

function TimetableTimeEditor({
    id,
    value,
    onChange,
    disabled,
    errorMessage,
    placeholder,
    label
}: {
    id?: string;
    value?: TimetableTimeValue;
    onChange?: (e: { value: TimetableTimeValue | undefined }) => void;
    disabled?: boolean;
    errorMessage?: string;
    placeholder?: string;
    label?: string;
}) {
    const timePickerValue = useMemo<Date | null>(() => {
        if (value?.hour === undefined || value.minute === undefined) return null;

        const date = schedulesService.timetableTimeToDate({ hour: value.hour, minute: value.minute });

        return date;
    }, [value?.hour, value?.minute]);

    const hasError = !!errorMessage;

    return (
        <div style={{ width: 160 }}>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                {label && (
                    <label className={combineClassNames('k-fs-sm', hasError ? 'k-text-error' : 'k-icp-subtle-text')} htmlFor={id}>
                        {label}
                    </label>
                )}
                <ChangeOnBlurTimePicker
                    id={id}
                    value={timePickerValue}
                    onChange={onChange ? e => onChange({ value: e.value ? { hour: e.value.getHours(), minute: e.value.getMinutes() } : undefined }) : undefined}
                    disabled={disabled}
                    valid={!hasError}
                    format="hh:mm a"
                    formatPlaceholder="formatPattern"
                    placeholder={placeholder}
                />
            </StackLayout>
            {errorMessage && <ErrorComponent>{errorMessage}</ErrorComponent>}
        </div>
    );
}

function TimetableTimeView({ label, time, style }: { label?: string; time?: TimetableTime; style?: React.CSSProperties }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1" style={{ width: 92, ...style }}>
            {label && <span className="k-fs-sm k-icp-subtle-text">{label}</span>}
            {time ? <span>{schedulesService.stringifyTimetableTime(time)}</span> : <span className="k-icp-placeholder-text">N/A</span>}
        </StackLayout>
    );
}

type SpecialTimetableEntryListItem = { id: string; date: TimetableDayValue | undefined; startTimes: EditableTimetableTime[] };
type DeletedTimeTableDayValue = { delete: true };
export function DaysSlotsEditor({
    slotLength,
    value,
    disabled,
    onChange,
    triggerOnChangeIfInvalid,
    defaultStartTimesByWeekDay
}: {
    slotLength: number;
    value?: SpecialTimetableEntry[];
    disabled?: boolean;
    onChange?: (e: { value: SpecialTimetableEntry[] }) => Promise<unknown>;
    triggerOnChangeIfInvalid?: boolean;
    defaultStartTimesByWeekDay?: Partial<Record<number, TimetableTimeValue[]>>;
}) {
    const [uncommittedSlots, setUncommittedSlots] = useState<Partial<Record<string, EditableTimetableTime[]>>>({});
    const [uncommittedDates, setUncommittedDates] = useState<Partial<Record<string, TimetableDayValue | undefined | DeletedTimeTableDayValue>>>({});

    const entriesValidationSubScopesRef = useRef<Partial<Record<string, ValidationSubScopeHandle>>>({});
    const duplicateDateEntriesRef = useRef<Set<string>>();

    function getCurrentEntries(): SpecialTimetableEntryListItem[] {
        let entries: SpecialTimetableEntryListItem[] | undefined =
            value?.map<SpecialTimetableEntryListItem>(e => ({
                id: e.date.id,
                date: e.date,
                startTimes: e.startTimes
            })) ?? [];
        for (const dateId in uncommittedSlots) {
            if (!Object.prototype.hasOwnProperty.call(uncommittedSlots, dateId)) continue;
            const dateUncommittedSlots = uncommittedSlots[dateId];
            if (!dateUncommittedSlots || !dateUncommittedSlots.length) continue;

            const dateEntryIndex = entries.findIndex(e => e.id === dateId);
            if (dateEntryIndex === -1) entries.push({ id: dateId, startTimes: dateUncommittedSlots.filter(s => !isDeletedSlot(s)), date: undefined });
            else {
                const dateEntry = entries[dateEntryIndex];
                const mergedDateSlots = [...dateEntry.startTimes];
                for (const dateUncommittedSlot of dateUncommittedSlots) {
                    const isSlotDeleted = isDeletedSlot(dateUncommittedSlot);
                    const existingSlotIndex = mergedDateSlots.findIndex(s => s.id === dateUncommittedSlot.id);
                    if (isSlotDeleted) {
                        if (existingSlotIndex !== -1) mergedDateSlots.splice(existingSlotIndex, 1);
                    } else {
                        if (existingSlotIndex === -1) mergedDateSlots.push(dateUncommittedSlot);
                        else mergedDateSlots[existingSlotIndex] = dateUncommittedSlot;
                    }
                }
                entries[dateEntryIndex] = {
                    ...dateEntry,
                    startTimes: mergedDateSlots
                };
            }
        }

        for (const dateId in uncommittedDates) {
            if (!Object.prototype.hasOwnProperty.call(uncommittedDates, dateId)) continue;
            const uncommittedDate = uncommittedDates[dateId];
            const dateEntryIndex = entries.findIndex(e => e.id === dateId);
            const isDateDeleted = isDeleteDateChange(uncommittedDate);
            if (isDateDeleted) {
                if (dateEntryIndex !== -1) entries.splice(dateEntryIndex, 1);
            } else {
                if (dateEntryIndex === -1) entries.push({ id: dateId, startTimes: [], date: uncommittedDate });
                else {
                    const dateEntry = entries[dateEntryIndex];
                    entries[dateEntryIndex] = {
                        ...dateEntry,
                        date: uncommittedDate
                    };
                }
            }
        }

        return entries;
    }

    function isEntryValid(id: string) {
        if (duplicateDateEntriesRef.current?.has(id)) return false;

        const entryValidationSubScope = entriesValidationSubScopesRef.current[id];
        if (!entryValidationSubScope) return true;

        return entryValidationSubScope.isValid;
    }

    const entries = getCurrentEntries();

    function getFullUpdatedValue(
        overriddenEntryId: string,
        dateOverride?: TimetableDayValue | DeletedTimeTableDayValue,
        startTimeOverride?: EditableTimetableTime
    ): SpecialTimetableEntry[] {
        const updatedValue = value ? [...value] : [];
        for (const entry of entries) {
            const isEntryOverridden = entry.id === overriddenEntryId;
            getUpdatedValue(entry.id, isEntryOverridden ? dateOverride : undefined, isEntryOverridden ? startTimeOverride : undefined, updatedValue);
        }

        return updatedValue;
    }

    function getUpdatedValue(
        id: string,
        dateOverride?: TimetableDayValue | DeletedTimeTableDayValue,
        startTimeOverride?: EditableTimetableTime,
        valueOverride?: SpecialTimetableEntry[]
    ): SpecialTimetableEntry[] {
        const updatedValue = valueOverride ?? (value ? [...value] : []);
        const existingEntryIndex = updatedValue.findIndex(e => e.date.id === id);
        const updatedDate = dateOverride ?? uncommittedDates[id];
        const uncommittedDateSlots = uncommittedSlots[id];
        const isDateDeleted = isDeleteDateChange(updatedDate);

        if (isDateDeleted) {
            if (existingEntryIndex !== -1) updatedValue.splice(existingEntryIndex, 1);
        } else {
            if (existingEntryIndex === -1) {
                if (updatedDate) {
                    const startTimes = mergeStartTimes([], uncommittedDateSlots, startTimeOverride);
                    if (!startTimes.length && defaultStartTimesByWeekDay) {
                        const weekDay = schedulesService.getTimetableDateWeekday(updatedDate);
                        const defaultStartTimes = defaultStartTimesByWeekDay[weekDay];
                        if (defaultStartTimes && defaultStartTimes.length)
                            startTimes.push(
                                ...defaultStartTimes.reduce<TimetableTime[]>((targetStartTimes, startTime) => {
                                    targetStartTimes.push({ ...startTime, id: generateSlotId(targetStartTimes) });
                                    return targetStartTimes;
                                }, [])
                            );
                    }
                    updatedValue.push({ date: { id: id, ...updatedDate }, startTimes: startTimes });
                }
            } else {
                const existingEntry = updatedValue[existingEntryIndex];
                const startTimes = mergeStartTimes(existingEntry.startTimes, uncommittedDateSlots, startTimeOverride);
                updatedValue[existingEntryIndex] = {
                    date: updatedDate ? { id: id, ...updatedDate } : existingEntry.date,
                    startTimes: startTimes
                };
            }
        }

        return updatedValue;
    }

    function clearUncommittedChanges(id: string) {
        setUncommittedDates(uncommittedDates => {
            if (!(id in uncommittedDates)) return uncommittedDates;
            const updatedUncommittedDates = { ...uncommittedDates };
            delete updatedUncommittedDates[id];
            return updatedUncommittedDates;
        });

        setUncommittedSlots(uncommittedSlots => {
            if (!(id in uncommittedSlots)) return uncommittedSlots;
            const updatedUncommittedSlots = { ...uncommittedSlots };
            delete updatedUncommittedSlots[id];
            return updatedUncommittedSlots;
        });
    }

    return (
        <>
            <ValidationUnit
                name="duplicateDates"
                value={entries}
                validator={value => {
                    duplicateDateEntriesRef.current = getDuplicateDays(value);
                    return duplicateDateEntriesRef.current?.size ? 'Duplicate dates' : undefined;
                }}
            >
                {(duplicateDaysErrorMessage, onDuplicateDatesValidationChange) => {
                    function triggerDuplicateDatesValidation(id: string, newDate: TimetableDayValue | undefined, remove?: boolean) {
                        if (remove) onDuplicateDatesValidationChange(entries.filter(v => v.id !== id));
                        else onDuplicateDatesValidationChange(entries.map(v => (v.id === id ? { ...v, date: newDate } : v)));
                    }

                    return (
                        <>
                            {entries?.map(entry => (
                                <ValidationSubScope
                                    key={entry.id}
                                    ref={r => {
                                        if (r) entriesValidationSubScopesRef.current[entry.id] = r;
                                        else delete entriesValidationSubScopesRef.current[entry.id];
                                    }}
                                >
                                    <DaySlotsEditor
                                        slotLength={slotLength}
                                        uniqueId={entry.id}
                                        day={entry.date}
                                        slots={entry.startTimes}
                                        disabled={disabled}
                                        dayErrorMessage={duplicateDateEntriesRef.current?.has(entry.id) ? duplicateDaysErrorMessage : undefined}
                                        onDayChange={async newDay => {
                                            triggerDuplicateDatesValidation(entry.id, newDay);
                                            setUncommittedDates(uncommittedDates => ({ ...uncommittedDates, [entry.id]: newDay }));
                                            const isValid = isEntryValid(entry.id);
                                            if (!isValid) {
                                                if (triggerOnChangeIfInvalid) await onChange?.({ value: value ?? [] });
                                                return;
                                            }

                                            const updatedValue = getUpdatedValue(entry.id, newDay);
                                            await onChange?.({ value: updatedValue });
                                            clearUncommittedChanges(entry.id);
                                        }}
                                        onDelete={async () => {
                                            const deleteDateChange: DeletedTimeTableDayValue = { delete: true };
                                            flushSync(() => {
                                                setUncommittedDates(uncommittedDates => ({ ...uncommittedDates, [entry.id]: deleteDateChange }));
                                            });

                                            const hasInvalidEntry = entries.some(e => (e.id === entry.id ? false : !isEntryValid(e.id)));
                                            if (hasInvalidEntry) {
                                                if (triggerOnChangeIfInvalid) await onChange?.({ value: value ?? [] });
                                                return;
                                            }

                                            const updatedValue = getFullUpdatedValue(entry.id, deleteDateChange);
                                            await onChange?.({ value: updatedValue });
                                            clearUncommittedChanges(entry.id);
                                        }}
                                        onAddSlot={async () => {
                                            const currentStatTimes = entry.startTimes
                                                ?.map(s => getSlotStartTime(s))
                                                .filter((st): st is TimetableTime => st !== undefined);
                                            const newSlot: EditableTimetableTime = {
                                                ...schedulesService.findNextTimetableSlotStartTime(slotLength, currentStatTimes),
                                                id: generateSlotId(entry.startTimes)
                                            };

                                            flushSync(() => {
                                                setUncommittedSlots(uncommittedSlots => ({
                                                    ...uncommittedSlots,
                                                    [entry.id]: uncommittedSlots[entry.id] ? [...uncommittedSlots[entry.id]!, newSlot] : [newSlot]
                                                }));
                                            });

                                            const isValid = isEntryValid(entry.id);
                                            if (!isValid) {
                                                if (triggerOnChangeIfInvalid) await onChange?.({ value: value ?? [] });
                                                return;
                                            }

                                            const updatedValue = getUpdatedValue(entry.id, undefined, newSlot);
                                            await onChange?.({ value: updatedValue });
                                            clearUncommittedChanges(entry.id);
                                        }}
                                        onDeleteSlot={async id => {
                                            const deletedSlot: EditableTimetableTime = { id: id, delete: true };
                                            flushSync(() => {
                                                setUncommittedSlots(uncommittedSlots => {
                                                    const dayUncommittedSlots = uncommittedSlots[entry.id];
                                                    if (!dayUncommittedSlots)
                                                        return {
                                                            ...uncommittedSlots,
                                                            [entry.id]: [deletedSlot]
                                                        };

                                                    let isSlotUpdated = false;
                                                    const updatedDayUncommittedSlots = dayUncommittedSlots.map(s => {
                                                        if (s.id !== id) return s;
                                                        isSlotUpdated = true;

                                                        return deletedSlot;
                                                    });

                                                    if (!isSlotUpdated) updatedDayUncommittedSlots.push(deletedSlot);
                                                    return {
                                                        ...uncommittedSlots,
                                                        [entry.id]: updatedDayUncommittedSlots
                                                    };
                                                });
                                            });

                                            const isValid = isEntryValid(entry.id);
                                            if (!isValid) {
                                                if (triggerOnChangeIfInvalid) await onChange?.({ value: value ?? [] });
                                                return;
                                            }

                                            const updatedValue = getUpdatedValue(entry.id, undefined, deletedSlot);
                                            await onChange?.({ value: updatedValue });
                                            clearUncommittedChanges(entry.id);
                                        }}
                                        onCopyTo={async copyToDate => {
                                            if (!value) return;
                                            const sourceStartTimes = value.find(e => e.date.id === entry.id)?.startTimes;
                                            const targetIndex = value.findIndex(e => schedulesService.timetableDatesAreEqual(e.date, copyToDate));
                                            const targetStartTimes =
                                                sourceStartTimes?.reduce<TimetableTime[]>((targetStartTimes, startTime) => {
                                                    targetStartTimes.push({ ...startTime, id: generateSlotId(targetStartTimes) });
                                                    return targetStartTimes;
                                                }, []) ?? [];
                                            let updatedValue: SpecialTimetableEntry[];
                                            let existingTargetDate: TimetableDay | undefined;
                                            if (targetIndex === -1)
                                                updatedValue = [
                                                    ...value,
                                                    { date: { ...copyToDate, id: generateDateId(entries) }, startTimes: targetStartTimes }
                                                ];
                                            else {
                                                existingTargetDate = value[targetIndex].date;
                                                updatedValue = [...value];
                                                updatedValue[targetIndex] = {
                                                    ...updatedValue[targetIndex],
                                                    startTimes: targetStartTimes
                                                };
                                            }

                                            if (existingTargetDate)
                                                flushSync(() => {
                                                    clearUncommittedChanges(existingTargetDate!.id);
                                                });

                                            await onChange?.({ value: updatedValue });
                                        }}
                                        onChangeSlot={async (id, startTime) => {
                                            setUncommittedSlots(uncommittedSlots => {
                                                const dayUncommittedSlots = uncommittedSlots[entry.id];
                                                if (!dayUncommittedSlots)
                                                    return {
                                                        ...uncommittedSlots,
                                                        [entry.id]: [updateSlot({ id: id }, startTime)]
                                                    };

                                                let isSlotUpdated = false;
                                                const updatedDayUncommittedSlots = dayUncommittedSlots.map(s => {
                                                    if (s.id !== id) return s;
                                                    isSlotUpdated = true;

                                                    return updateSlot(s, startTime);
                                                });

                                                if (!isSlotUpdated) updatedDayUncommittedSlots.push(updateSlot({ id: id }, startTime));
                                                return {
                                                    ...uncommittedSlots,
                                                    [entry.id]: updatedDayUncommittedSlots
                                                };
                                            });
                                            const isValid = isEntryValid(entry.id);
                                            if (!isValid) {
                                                if (triggerOnChangeIfInvalid) await onChange?.({ value: value ?? [] });
                                                return;
                                            }

                                            const updatedValue = getUpdatedValue(entry.id, undefined, updateSlot({ id: id }, startTime));
                                            await onChange?.({ value: updatedValue });
                                            clearUncommittedChanges(entry.id);
                                        }}
                                    />
                                </ValidationSubScope>
                            ))}
                        </>
                    );
                }}
            </ValidationUnit>
            <SlotsEditorRow key="newSlotRow">
                <Button
                    size="small"
                    fillMode="flat"
                    disabled={disabled}
                    type="button"
                    onClick={() => {
                        setUncommittedDates(uncommittedDates => ({ ...uncommittedDates, [generateDateId(entries)]: undefined }));
                    }}
                >
                    <SvgIconButtonContent icon={AddIcon}>Add special day</SvgIconButtonContent>
                </Button>
            </SlotsEditorRow>
        </>
    );
}

function getOverlappingSlots(slots: EditableTimetableTime[], slotLength: number) {
    const overlappingSlots = new Set<string>();
    for (let i = 0; i < slots.length; i++) {
        const firstSlot = slots[i];
        const firstSlotStartTime = getSlotStartTime(firstSlot);
        if (!firstSlotStartTime) continue;

        for (let j = i + 1; j < slots.length; j++) {
            const secondSlot = slots[j];
            const secondSlotStartTime = getSlotStartTime(secondSlot);
            if (!secondSlotStartTime) continue;

            const durationBetweenStartTimes = schedulesService.subtractTimetableTimes(firstSlotStartTime, secondSlotStartTime);
            if (Math.abs(durationBetweenStartTimes) < slotLength) {
                overlappingSlots.add(firstSlot.id);
                overlappingSlots.add(secondSlot.id);
            }
        }
    }

    return overlappingSlots;
}

function getSlotStartTime(slotValue: EditableTimetableTime): TimetableTime | undefined {
    if ('hour' in slotValue) return slotValue;

    return undefined;
}

function isDeletedSlot(slotValue: EditableTimetableTime): boolean {
    return 'delete' in slotValue && !!slotValue.delete;
}

function isDeleteDateChange(value: TimetableDayValue | undefined | DeletedTimeTableDayValue): value is DeletedTimeTableDayValue {
    return value !== undefined && 'delete' in value;
}

function updateSlot(slot: EditableTimetableTime, value: TimetableTime | TimetableTimeValue | undefined): EditableTimetableTime {
    if (!value) return { id: slot.id };
    if ('id' in value) return value;
    return { id: slot.id, ...value };
}

function generateSlotId(existingSlots?: EditableTimetableTime[]) {
    return getRandomId(
        Number.MAX_SAFE_INTEGER,
        existingSlots?.map(s => s.id)
    );
}

function generateDateId(existingDates?: SpecialTimetableEntryListItem[]) {
    return getRandomId(
        Number.MAX_SAFE_INTEGER,
        existingDates?.map(s => s.id)
    );
}

function addOrUpdateStartTime(startTimes: TimetableTime[], newStartTime: TimetableTime) {
    const existingStartTimeIndex = startTimes.findIndex(st => st.id === newStartTime.id);
    if (existingStartTimeIndex === -1) startTimes.push(newStartTime);
    else startTimes[existingStartTimeIndex] = newStartTime;
}

function deleteStartTime(startTimes: TimetableTime[], id: string) {
    const existingStartTimeIndex = startTimes.findIndex(st => st.id === id);
    if (existingStartTimeIndex !== -1) startTimes.splice(existingStartTimeIndex, 1);
}

function mergeStartTimes(
    sourceStartTimes: TimetableTime[],
    uncommittedStartTimes: EditableTimetableTime[] | undefined,
    startTimeOverride?: EditableTimetableTime
): TimetableTime[] {
    const mergedStartTimes = [...sourceStartTimes];
    const startTimeOverrideValue = startTimeOverride ? getSlotStartTime(startTimeOverride) : undefined;
    const isStartTimeOverrideDeleted = startTimeOverride ? isDeletedSlot(startTimeOverride) : false;
    let overrideApplied = false;
    if (uncommittedStartTimes)
        for (const uncommittedStartTime of uncommittedStartTimes) {
            let uncommittedStartTimeValue: TimetableTime | undefined;
            let isDeleted = false;
            if (startTimeOverride && uncommittedStartTime.id === startTimeOverride.id) {
                overrideApplied = true;
                uncommittedStartTimeValue = startTimeOverrideValue;
                isDeleted = isStartTimeOverrideDeleted;
            } else {
                uncommittedStartTimeValue = getSlotStartTime(uncommittedStartTime);
                isDeleted = isDeletedSlot(uncommittedStartTime);
            }

            if (isDeleted) deleteStartTime(mergedStartTimes, uncommittedStartTime.id);
            else {
                if (!uncommittedStartTimeValue) continue;
                addOrUpdateStartTime(mergedStartTimes, uncommittedStartTimeValue);
            }
        }

    if (!overrideApplied) {
        if (isStartTimeOverrideDeleted) deleteStartTime(mergedStartTimes, startTimeOverride!.id);
        else if (startTimeOverrideValue) addOrUpdateStartTime(mergedStartTimes, startTimeOverrideValue);
    }

    return mergedStartTimes;
}

function getDuplicateDays(
    value: {
        id: string;
        date: TimetableDayValue | undefined;
    }[]
): Set<string> | undefined {
    if (!value) return undefined;

    const duplicateDays = new Set<string>();
    for (let i = 0; i < value.length; i++) {
        const firstDay = value[i];
        if (!firstDay.date) continue;

        for (let j = i + 1; j < value.length; j++) {
            const secondDay = value[j];
            if (!secondDay.date) continue;
            if (schedulesService.timetableDatesAreEqual(firstDay.date, secondDay.date)) {
                duplicateDays.add(firstDay.id);
                duplicateDays.add(secondDay.id);
            }
        }
    }

    return duplicateDays;
}

const timeTableDayValidator = requiredValidator('Date');
export function DaySlotsEditor({
    day,
    slots,
    slotLength,
    disabled,
    uniqueId,
    onDayChange,
    onDelete,
    onCopyTo,
    dayErrorMessage,
    onAddSlot,
    onDeleteSlot,
    onChangeSlot
}: {
    day?: TimetableDayValue;
    slots?: EditableTimetableTime[];
    slotLength: number;
    disabled?: boolean;
    uniqueId: string;
    dayErrorMessage?: string;
    onDayChange?: (day: TimetableDayValue | undefined) => void;
    onDelete?: () => void;
    onCopyTo?: (date: TimetableDayValue) => void;
    onAddSlot?: () => void;
    onDeleteSlot?: (id: string) => void;
    onChangeSlot?: (id: string, startTime: TimetableTime | undefined) => void;
}) {
    return (
        <SlotsEditorRow>
            <ValidationUnit name={`${uniqueId}_day`} value={day} validator={timeTableDayValidator}>
                {(errorMessage, validationOnChange, resetValidation) => (
                    <>
                        <TimetableDayEditor
                            value={day}
                            label="Date:"
                            disabled={disabled}
                            errorMessage={errorMessage || dayErrorMessage}
                            style={{ width: 175 }}
                            id={uniqueId}
                            onChange={e => {
                                validationOnChange(e.value);
                                onDayChange?.(e.value);
                            }}
                        />
                        <div className="k-flex-1">
                            <SlotsListEditor
                                validationPrefix={uniqueId}
                                slotLength={slotLength}
                                slots={slots}
                                disabled={disabled}
                                onDelete={slotId => {
                                    if (slots && slots.length === 1) resetValidation();
                                    onDeleteSlot?.(slotId);
                                }}
                                onChange={onChangeSlot}
                            />
                        </div>
                    </>
                )}
            </ValidationUnit>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-mt-1 k-gap-2">
                <Button size="small" fillMode="flat" icon="plus" type="button" disabled={disabled} onClick={onAddSlot} />
                <DaySlotsCopyButton disabled={disabled} onCopy={onCopyTo} forbiddenDays={day ? [day] : undefined} />
                <Button size="small" fillMode="flat" type="button" disabled={disabled} className="k-icp-svg-icon-button" onClick={onDelete}>
                    <DeleteIcon className="k-icp-icon" />
                </Button>
            </StackLayout>
        </SlotsEditorRow>
    );
}

export function SlotsEditorRow({ children }: { children?: ReactNode }) {
    return (
        <StackLayout
            align={{
                horizontal: 'start',
                vertical: 'top'
            }}
            className="k-gap-4 k-py-2 k-icp-component-border k-icp-bordered-top"
        >
            {children}
        </StackLayout>
    );
}

function TimetableDayEditor({
    id,
    value,
    onChange,
    disabled,
    errorMessage,
    placeholder,
    label,
    style
}: {
    id?: string;
    value?: TimetableDayValue;
    onChange?: (e: { value: TimetableDayValue | undefined }) => void;
    disabled?: boolean;
    errorMessage?: string;
    placeholder?: string;
    label?: string;
    style?: CSSProperties;
}) {
    const datePickerValue = useMemo<Date | null>(() => {
        if (value?.date === undefined) return null;

        const date = new Date();
        date.setHours(0, 0, 0);
        date.setFullYear(value.year, value.month, value.date);
        return date;
    }, [value?.date, value?.month, value?.year]);

    const hasError = !!errorMessage;

    return (
        <div style={style}>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                {label && (
                    <label className={combineClassNames('k-fs-sm', hasError ? 'k-text-error' : 'k-icp-subtle-text')} htmlFor={id}>
                        {label}
                    </label>
                )}
                <DatePicker
                    id={id}
                    dateInput={KeyboardNavigatableDateInput}
                    value={datePickerValue}
                    onChange={
                        onChange
                            ? e =>
                                  onChange({ value: e.value ? { year: e.value.getFullYear(), month: e.value.getMonth(), date: e.value.getDate() } : undefined })
                            : undefined
                    }
                    disabled={disabled}
                    valid={!hasError}
                    format="MM/dd/yyyy"
                    formatPlaceholder="formatPattern"
                    placeholder={placeholder}
                />
            </StackLayout>
            {errorMessage && <ErrorComponent>{errorMessage}</ErrorComponent>}
        </div>
    );
}

function DaySlotsCopyButton({
    forbiddenDays,
    disabled,
    onCopy
}: {
    forbiddenDays?: TimetableDayValue[];
    disabled?: boolean;
    onCopy?: (date: TimetableDayValue) => void;
}) {
    const [copyToDay, setCopyToDay] = useState<TimetableDayValue>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(getErrorMessage(copyToDay));
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    function getErrorMessage(day: TimetableDayValue | undefined) {
        if (!day) return 'Date is required';
        if (forbiddenDays && forbiddenDays.some(forbiddenDay => schedulesService.timetableDatesAreEqual(day, forbiddenDay))) return 'Date is not allowed';
        return undefined;
    }

    return (
        <SlotsCopyButton
            copyEditor={
                <div className="k-px-2 k-py-1">
                    <TimetableDayEditor
                        value={copyToDay}
                        onChange={e => {
                            setCopyToDay(e.value);
                            setErrorMessage(getErrorMessage(e.value));
                        }}
                        errorMessage={showErrorMessage ? errorMessage : undefined}
                        disabled={disabled}
                    />
                </div>
            }
            onCopy={() => {
                if (errorMessage) {
                    if (!showErrorMessage) setShowErrorMessage(true);
                    return false;
                }

                onCopy?.(copyToDay!);
                setCopyToDay(undefined);
            }}
            disabled={disabled}
        />
    );
}

function SlotsCopyButton({ disabled, copyEditor, onCopy }: { disabled?: boolean; copyEditor?: ReactElement; onCopy?: () => boolean | void }) {
    const copyButtonRef = useRef<Button>(null);
    const [showPopup, toggleShowPopup] = useSoleToggle();

    return (
        <>
            <Button
                ref={copyButtonRef}
                type="button"
                size="small"
                fillMode="flat"
                icon="copy"
                onClick={e => {
                    e.stopPropagation();
                    toggleShowPopup();
                }}
                togglable={true}
                selected={showPopup}
                disabled={disabled}
            />
            <Popup show={showPopup} anchor={copyButtonRef.current?.element} style={{ minWidth: 128 }}>
                <div onClick={e => e.stopPropagation()}>
                    <div className="k-fs-sm k-px-2 k-pt-2 k-pb-1 k-icp-subtle-text">Copy slots to...</div>
                    {copyEditor}
                    <div className="k-icp-component-border k-icp-bordered-top k-p-2 k-text-center">
                        <Button
                            type="button"
                            size="small"
                            fillMode="outline"
                            themeColor="secondary"
                            onClick={() => {
                                const isValid = onCopy?.();
                                if (isValid === false) return;
                                toggleShowPopup();
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </Popup>
        </>
    );
}
