import { realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { store } from '../store';
import { login, refreshLicense } from './userSlice';

realTimeUpdatesEventHub.addEventListener('user', 'profileUpdate', () => {
    store.dispatch(login());
});

realTimeUpdatesEventHub.addEventListener('user', 'activate', () => {
    store.dispatch(login());
});

realTimeUpdatesEventHub.addEventListener('user', 'licenseUpdated', () => {
    store.dispatch(refreshLicense());
});
