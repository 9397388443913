import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { googleTagManager } from '../../scripts/googleTagManager';
import { processWithGlobalErrorHandler } from '../../services/common';
import { generateInitials, getPreferredColorIndex, LicenseData, User, usersService } from '../../services/usersService';

interface CurrentUserState extends User {
    initials: string;
    colorIndex: number;
}

type UserState = CurrentUserState | null;
const initialState: UserState = null;

export const login = createAsyncThunk<User, void>(
    'user/login',
    processWithGlobalErrorHandler(async () => {
        const currentUserData = await usersService.getCurrent();

        return currentUserData;
    })
);

export const refreshLicense = createAsyncThunk<LicenseData>(
    'user/license',
    processWithGlobalErrorHandler(() => usersService.getCurrentLicense())
);

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState as UserState,
    reducers: {
        resetCurrentUser: () => {
            googleTagManager.setUserData();

            return initialState;
        },
        setCurrentUser: (state, action: PayloadAction<User>) => {
            const initials = generateInitials(action.payload, 2);
            const colorIndex = getPreferredColorIndex(action.payload);

            googleTagManager.setUserData(action.payload, action.payload.license);

            return { ...action.payload, initials: initials, colorIndex: colorIndex };
        }
    },
    extraReducers: builder => {
        builder.addCase(login.fulfilled, (state, action: PayloadAction<User>) => {
            return userSlice.caseReducers.setCurrentUser(state, action);
        });

        builder.addCase(refreshLicense.fulfilled, (state, action) => {
            if (!state) return state;

            googleTagManager.setUserData(state, action.payload);

            return {
                ...state,
                license: action.payload
            };
        });
    }
});

export const { setCurrentUser, resetCurrentUser } = userSlice.actions;

export default userSlice.reducer;
