import { forwardRef } from 'react';
import { TaskEditorProps, TaskEditorRef } from '.';
import { JourneyTaskEditor } from '../../../services/journeyService';
import { CanvasBoxEditor } from './canvasBoxEditor';
import { CustomerProblemHypothesesEditor } from './customerProblemHypothesesEditor';
import { HypothesisLikelihoodEditor } from './hypothesisLikelihoodEditor';
import { InterviewScriptEditor } from './interviewScriptEditor';
import { JobToBeDoneEditor } from './jobToBeDoneEditor';
import { ResearchContactsEditor } from './researchContactsEditor';
import { ResearchDateRangeEditor } from './researchDateRangeEditor';
import { ResearchMeetingInvitesEditor } from './researchMeetingInvitesEditor';
import { ResearchMeetingsExecutionEditor } from './researchMeetingsExecutionEditor';
import { ResearchSchedulesEditor } from './researchSchedulesEditor';

export const TaskEditor = forwardRef<TaskEditorRef, Omit<TaskEditorProps<unknown>, 'params'> & { editor: JourneyTaskEditor }>(function(
    { editor, ...props },
    ref
) {
    const editorType = editor.type;
    if (editorType === 'canvas-box') return <CanvasBoxEditor {...props} params={editor.params} />;
    if (editorType === 'problem-hypotheses') return <CustomerProblemHypothesesEditor ref={ref} {...props} params={editor.params} />;
    if (editorType === 'job-to-be-done') return <JobToBeDoneEditor {...props} params={editor.params} />;
    if (editorType === 'interview-script') return <InterviewScriptEditor ref={ref} {...props} params={editor.params} />;
    if (editorType === 'research-start-end-date') return <ResearchDateRangeEditor ref={ref} {...props} params={editor.params} />;
    if (editorType === 'research-meeting-proposals') return <ResearchSchedulesEditor ref={ref} {...props} params={editor.params} />;
    if (editorType === 'research-contacts') return <ResearchContactsEditor {...props} params={editor.params} />;
    if (editorType === 'research-meeting-invites') return <ResearchMeetingInvitesEditor ref={ref} {...props} params={editor.params} />;
    if (editorType === 'research-interviews') return <ResearchMeetingsExecutionEditor {...props} params={editor.params} />;
    if (editorType === 'hypotheses-likelihood') return <HypothesisLikelihoodEditor ref={ref} {...props} params={editor.params} />;

    throw new Error('Unknown editor type: ' + editorType);
});
