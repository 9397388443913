import { ErrorWithOperationDisplayName } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { ReducedUser } from './usersService';

export interface NotificationAction {
    command: string;
    params: Record<string, any>;
}

export enum NotificationEventType {
    InviteIssued = 'InviteIssued',
    InviteAccepted = 'InviteAccepted',
    InviteDeclined = 'InviteDeclined',
    IdeaDeleted = 'IdeaDeleted',
    IdeaLeft = 'IdeaLeft',
    MembershipChanged = 'MembershipChanged',
    MembershipRevoked = 'MembershipRevoked'
}

interface NotificationEvent {
    type: NotificationEventType;
    data: Record<string, any>;
}

export interface Notification {
    id: number;
    initiator: ReducedUser;
    read: boolean;
    event: NotificationEvent;
    actions: NotificationAction[];
    timestamp: Date;
}

interface NotSeenResponse {
    result: boolean;
}

class NotificationsService extends HttpServiceBase {
    private static readonly NOTIFICATION_MIN_HEIGHT = 56;

    constructor() {
        super('/api/notifications');
    }

    private calculateNumberOfNotificationsToGet() {
        const viewPortHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) || 1440;
        const maxNotificationsVisibleInViewPort = Math.ceil(viewPortHeight / NotificationsService.NOTIFICATION_MIN_HEIGHT);

        return maxNotificationsVisibleInViewPort + 3;
    }

    @ErrorWithOperationDisplayName('Get notifications')
    async get(afterId?: number) {
        const params: Record<string, string> = {
            take: this.calculateNumberOfNotificationsToGet().toString()
        };

        if (afterId) params.afterId = afterId.toString();

        const notifications = await this.performRequest<Notification[]>({
            path: '/mine',
            method: RequestMethod.GET,
            queryParams: params
        });

        dateTimeService.ensureDateType(n => n, 'timestamp', ...notifications);

        return notifications;
    }

    @ErrorWithOperationDisplayName('Get all pending notifications')
    async getPending(take?: number) {
        const takeParam = take ? { take: take.toString() } : undefined;
        const notifications = await this.performRequest<Notification[]>({
            path: '/mine',
            method: RequestMethod.GET,
            queryParams: {
                pendingPopup: "true",
                ...takeParam
            }
        });

        dateTimeService.ensureDateType(n => n, 'timestamp', ...notifications);

        return notifications;
    }

    @ErrorWithOperationDisplayName('Mark notifications as read')
    async markAsRead(...ids: number[]) {
        const queryParams = this.getIdsQueryParams(ids);

        return this.performRequestWithoutParsingResponse({
            path: '/mine/read',
            method: RequestMethod.POST,
            queryParams: queryParams
        });
    }


    @ErrorWithOperationDisplayName('Set notification shown')
    markAsShown(...ids: number[]) {
        const queryParams = this.getIdsQueryParams(ids);

        return this.performRequestWithoutParsingResponse({
            path: '/mine/shown',
            method: RequestMethod.POST,
            queryParams: queryParams
        });
    }


    @ErrorWithOperationDisplayName('Get unread notifications count')
    getUnreadCount() {
        return this.performRequest<number>({
            path: '/mine/unreadCount'
        });
    }

    @ErrorWithOperationDisplayName('Check for new notifications')
    async hasNotSeen() {
        const response = await this.performRequest<NotSeenResponse>({
            path: '/mine/not-seen/any',
            method: RequestMethod.GET
        });

        return response.result;
    }

    @ErrorWithOperationDisplayName('Set last seen notification')
    setLastSeenNotification(id: number) {
        return this.performRequestWithoutParsingResponse({
            path: '/mine/seen',
            method: RequestMethod.PUT,
            body: {
                notification_id: id
            }
        });
    }

    private getIdsQueryParams = (ids: number[]) => {
        let queryParams: URLSearchParams | undefined = undefined;
        if (ids && ids.length) {
            queryParams = new URLSearchParams();

            ids.forEach(id => queryParams?.append('id', id.toString()));
        }

        return queryParams;
    }

}

export const notificationsService = new NotificationsService();
