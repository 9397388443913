import { StackLayout } from '@progress/kendo-react-layout';
import React, { CSSProperties, ComponentType, ReactNode } from 'react';
import { getActivityTitle } from '.';
import { useCanvasBoxItemWithFallback } from '../../hooks/canvasHooks';
import { ReactComponent as ResearchReachOutIcon } from '../../icons/bell.svg';
import { ReactComponent as CompletedResearchReachOutIcon } from '../../icons/check-circle-closed.svg';
import { ReactComponent as InterviewIcon } from '../../icons/interview.svg';
import { ActivityOf, ActivityType } from '../../services/activitiesService';
import { BoxType } from '../../services/canvasService';
import { colorHexToRGB, combineClassNames } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { ReducedUser } from '../../services/usersService';
import { BoundDropDownButton, DropDownButtonItem } from '../common/boundDropDownButton';
import { completedReachOutIcon, reachOutIcon } from '../events/eventsDialogs';
import { Icon } from '../ui/icon';

function ActivityCard({
    title,
    date,
    metadata,
    user,
    children,
    kendoIconName,
    icon,
    overdue,
    onClick,
    suppressHour,
    onEdit,
    onDelete,
    className,
    editActionText,
    colorHex
}: {
    title: string;
    date: Date;
    metadata?: string[];
    user?: ReducedUser;
    children?: ReactNode;
    kendoIconName?: string;
    icon?: ComponentType<React.SVGProps<SVGSVGElement>>;
    overdue?: boolean;
    onClick?: () => void;
    suppressHour?: boolean;
    onEdit?: () => void;
    onDelete?: () => void;
    className?: string;
    editActionText?: string;
    colorHex?: string;
}) {
    const fullMetadata: string[] = [dateTimeService.stringifyToDay(date)];
    if (!suppressHour) fullMetadata.push(dateTimeService.stringifyToTime(date));

    if (metadata) fullMetadata.push(...metadata);
    if (user) fullMetadata.push(`${user.firstName} ${user.lastName}`);

    const actions: DropDownButtonItem[] = [];
    if (onEdit) actions.push({ text: editActionText || 'Edit', action: onEdit });
    if (onDelete) actions.push({ text: 'Delete', danger: true, separated: true, action: onDelete });

    return (
        <div onClick={onClick} className={className}>
            <StackLayout
                className={combineClassNames('activity activity-card k-panel k-p-1 k-pr-2 k-gap-2', actions.length ? 'activity-card-with-actions' : undefined)}
                style={colorHex ? ({ '--activity-color': colorHexToRGB(colorHex).join(', ') } as CSSProperties) : undefined}
                align={{ horizontal: 'start', vertical: 'top' }}
            >
                <div className="activity-marker k-align-self-stretch k-flex-shrink-0 k-rounded-sm" />
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1 k-flex-1">
                    <div>
                        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1 k-justify-content-between">
                            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1 k-mt-hair">
                                <Icon
                                    kendoIconName={kendoIconName}
                                    icon={icon}
                                    className={combineClassNames('k-mt-thin k-flex-shrink-0', overdue ? 'k-text-error' : undefined)}
                                />
                                <strong>{title}</strong>
                            </StackLayout>
                            {actions.length > 0 && (
                                <BoundDropDownButton
                                    fillMode="flat"
                                    size="small"
                                    icon="more-horizontal"
                                    className="activity-card-actions-drop-down-button"
                                    buttonClass="activity-card-actions-button"
                                    popupSettings={{
                                        anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                                        popupAlign: { horizontal: 'right', vertical: 'top' }
                                    }}
                                    items={actions}
                                    onOpen={e => e.syntheticEvent.stopPropagation()}
                                    onClose={e => e.syntheticEvent.stopPropagation()}
                                />
                            )}
                        </StackLayout>
                        <StackLayout className="k-icp-subtle-text k-gap-2 k-fs-sm" align={{ horizontal: 'start', vertical: 'middle' }}>
                            {fullMetadata.map((metadataText, index) => (
                                <React.Fragment key={index}>
                                    <span>{metadataText}</span>
                                    {index !== fullMetadata.length - 1 ? <span className="dot-separator" /> : undefined}
                                </React.Fragment>
                            ))}
                            {overdue && (
                                <React.Fragment key="overdue">
                                    <span className="dot-separator" />
                                    <span className="k-text-uppercase k-text-error">Overdue</span>
                                </React.Fragment>
                            )}
                        </StackLayout>
                    </div>
                    {children}
                </StackLayout>
            </StackLayout>
        </div>
    );
}
export type ActivityCardProps<TActivity extends ActivityType> = {
    activity: ActivityOf<TActivity>;
    onEdit?: () => void;
    onDelete?: () => void;
    className?: string;
};
export function NoteCard({ activity, onEdit, onDelete, className }: ActivityCardProps<ActivityType.Note>) {
    const note = activity.note;
    const title = getActivityTitle(activity);
    return (
        <ActivityCard
            title={title}
            date={note.date}
            user={note.user}
            kendoIconName="file-txt"
            onClick={onEdit}
            onEdit={onEdit}
            onDelete={onDelete}
            className={className}
        >
            <div className="max-lines-3">{note.text}</div>
        </ActivityCard>
    );
}

export function ReachOutCard({ activity, onEdit, onDelete, className }: ActivityCardProps<ActivityType.ReachOut>) {
    const reachOut = activity.reachOut;
    const isPast = reachOut.date < new Date();
    const title = getActivityTitle(activity);
    return (
        <ActivityCard
            title={title}
            date={reachOut.date}
            user={reachOut.user}
            icon={isPast ? completedReachOutIcon[reachOut.type] : reachOutIcon[reachOut.type]}
            onClick={onEdit}
            onEdit={onEdit}
            onDelete={onDelete}
            className={className}
        />
    );
}

export function MeetingCard({ activity, onEdit, onDelete, className }: ActivityCardProps<ActivityType.Meeting>) {
    const meeting = activity.meeting;
    function getDurationString(start: Date, end: Date, allDay?: boolean) {
        if (allDay) {
            const durationInDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
            if (durationInDays === 1) return 'all day';
            return `${durationInDays} days`;
        }

        const durationInMinutes = Math.floor((end.getTime() - start.getTime()) / (1000 * 60));
        return dateTimeService.stringifyMinutesToHours(durationInMinutes);
    }

    const durationText = getDurationString(meeting.startTime, meeting.endTime, meeting.allDay);
    const title = getActivityTitle(activity);
    const meetingResearchCustomerSegment = useCanvasBoxItemWithFallback(BoxType.CustomerSegments, meeting.research?.customerSegmentId);
    return (
        <ActivityCard
            title={title}
            date={meeting.startTime}
            user={meeting.user}
            icon={InterviewIcon}
            onClick={onEdit}
            metadata={[durationText]}
            suppressHour={meeting.allDay}
            onEdit={onEdit}
            onDelete={onDelete}
            className={className}
            colorHex={meetingResearchCustomerSegment?.colorCode}
        />
    );
}

export function ResearchReachOutCard({ activity, onEdit, onDelete, className }: ActivityCardProps<ActivityType.ResearchReachOut>) {
    const researchReachOut = activity.researchReachOut;
    const title = getActivityTitle(activity);
    const isOverdue = !researchReachOut.sent && researchReachOut.date < new Date();
    const researchReachOutCustomerSegment = useCanvasBoxItemWithFallback(BoxType.CustomerSegments, researchReachOut.research.customerSegmentId);
    return (
        <ActivityCard
            title={title}
            date={researchReachOut.date}
            user={researchReachOut.user ?? undefined}
            icon={researchReachOut.sent ? CompletedResearchReachOutIcon : ResearchReachOutIcon}
            onClick={onEdit}
            onEdit={onEdit}
            onDelete={onDelete}
            overdue={isOverdue}
            className={className}
            editActionText={researchReachOut.sent ? 'View' : undefined}
            colorHex={researchReachOutCustomerSegment?.colorCode}
        />
    );
}

export function getActivityCardForType<TActivity extends ActivityType>(activityType: TActivity): ComponentType<ActivityCardProps<TActivity>> | undefined {
    if (activityType === ActivityType.Note) return NoteCard as any;
    if (activityType === ActivityType.ReachOut) return ReachOutCard as any;
    if (activityType === ActivityType.Meeting) return MeetingCard as any;
    if (activityType === ActivityType.ResearchReachOut) return ResearchReachOutCard as any;

    return undefined;
}
