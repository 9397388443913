import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormProps } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { TaskEditorProps, TaskEditorRef } from '.';
import { useSingleClickButton } from '../../../hooks/commonHooks';
import { useResearchSchedules, useTaskManageContactsInResearch, useTaskManageResearchReachOuts } from '../../../hooks/researchHooks';
import { ReactComponent as AddIcon } from '../../../icons/plus.svg';
import interviewExistsIllustrationUrl from '../../../images/interview-exists-illustration.svg';
import { combineClassNames, toRecord } from '../../../services/common';
import { ReducedPerson } from '../../../services/contactsService';
import { ReachOutConnectionType, ReachOutType, ResearchReachOutData } from '../../../services/events';
import { ResearchEditorParams } from '../../../services/journeyService';
import { ResearchContactStage, ResearchReachOutTemplate, researchContactStageOrder, researchService } from '../../../services/researchService';
import { User } from '../../../services/usersService';
import { useAppSelector } from '../../../state/hooks';
import { ResearchReachOutCard } from '../../activities/activityCards';
import { useDeleteActivityDialog } from '../../activities/activityDialogs';
import { DateAndTimePicker } from '../../common/date';
import {
    InviteContentView,
    ResearchReachOutEventDialog,
    connectionTypeButtonGroupSelectorOptions,
    reachOutButtonGroupSelectorOptions,
    reachOutTypeValidators,
    researchReachOutConnectionTypeValidators,
    researchReachOutCreateDateValidators,
    useBookingPageUrl
} from '../../events/eventsDialogs';
import { problemValidationInviteContentProvider } from '../../events/problemValidationInviteContentProvider';
import { ResearchContactRelatedUser, ResearchContactTogglePanel, ResearchContactTogglePanelHandle } from '../../research/researchContactTogglePanel';
import { ButtonGroupSelector, ButtonGroupSelectorOption, ValidatedInput } from '../../ui/inputs';
import LoadingIndicator from '../../ui/loadingIndicator';
import { SvgIconButtonContent } from '../../ui/svgIconButtonContent';
import { EditorErrorsList } from './shared/editorErrorsList';
import { EditorMainArea } from './shared/editorMainArea';
import { useLazyHtmlParser } from '../../../hooks/useLazyHtmlParser';

const invitedStageIndex = researchContactStageOrder[ResearchContactStage.Invited];
export const ResearchMeetingInvitesEditor = forwardRef<TaskEditorRef, TaskEditorProps<ResearchEditorParams>>(function ResearchMeetingInvitesEditor(props, ref) {
    const researchContacts = props.taskData.researchContacts?.[props.params.researchId];
    const showErrors = props.isEditing && !!props.errors?.length;
    const research = props.taskData.problemValidationResearch?.[props.params.researchId];
    const researchContactsElementsRef = useRef<Record<number, ResearchContactTogglePanelHandle>>({});
    const [researchContactsExpandedState, setResearchContactsExpandedState] = useState<Partial<Record<number, boolean>>>({});
    const [researchContactsWithError, setResearchContactsWithError] = useState<Partial<Record<number, boolean>>>();
    const [researchSchedules] = useResearchSchedules(props.ideaId, props.params.researchId);
    const [reachOutTemplates, setReachOutTemplates] = useState<ResearchReachOutTemplate[] | undefined>();
    const htmlParseFunction = useLazyHtmlParser();

    const fetchReachOutInviteContent = useCallback(async (ideaId: string, researchId: number) => {
        const content = await researchService.getResearchReachOutTemplates(ideaId, researchId);
        setReachOutTemplates(content);
    }, []);

    useEffect(() => {
        if (!props.ideaId || !props.params.researchId) return;

        fetchReachOutInviteContent(props.ideaId, props.params.researchId);
    }, [fetchReachOutInviteContent, props.ideaId, props.params.researchId]);

    const researchContactsRef = useRef(researchContacts);
    researchContactsRef.current = researchContacts;
    useImperativeHandle(
        ref,
        () => ({
            commit() {
                const notInvitedResearchContactIds = researchContactsRef.current
                    ?.filter(rc => rc.stage === ResearchContactStage.NotInvited)
                    .map(rc => rc.contact.id);
                if (!notInvitedResearchContactIds || !notInvitedResearchContactIds.length) {
                    setResearchContactsWithError(undefined);
                    setResearchContactsExpandedState({});

                    return;
                }

                setResearchContactsWithError(
                    toRecord(
                        notInvitedResearchContactIds,
                        notInvitedResearchContactId => notInvitedResearchContactId,
                        () => true
                    )
                );

                setResearchContactsExpandedState(
                    toRecord(
                        notInvitedResearchContactIds,
                        notInvitedResearchContactId => notInvitedResearchContactId,
                        () => true
                    )
                );
            }
        }),
        []
    );

    function updateResearchContactExpandedState(contactId: number, requiredState?: boolean) {
        setResearchContactsExpandedState(s => ({ ...s, [contactId]: requiredState !== undefined ? requiredState : !s[contactId] }));
    }

    const autoExpandFirstPendingWorkContact = useRef<boolean | number>(true);
    useEffect(() => {
        if (!researchContacts || !researchContacts.length || autoExpandFirstPendingWorkContact.current === false) return;

        const contactIdToIgnore = typeof autoExpandFirstPendingWorkContact.current === 'number' ? autoExpandFirstPendingWorkContact.current : undefined;
        autoExpandFirstPendingWorkContact.current = false;
        const firstPendingWorkContact = researchContacts?.find(
            c => researchContactStageOrder[c.stage] < invitedStageIndex && c.contact.id !== contactIdToIgnore
        );
        if (!firstPendingWorkContact) return;
        updateResearchContactExpandedState(firstPendingWorkContact.contact.id, true);
    }, [researchContacts]);

    const currentUser = useAppSelector(u => u.user);
    const currentUserId = currentUser?.userId;
    const schedule = props.isEditing
        ? researchSchedules && currentUserId !== undefined
            ? researchSchedules.find(s => s.user.userId === currentUserId) ?? null
            : undefined
        : undefined;
    const bookingPageUrl = useBookingPageUrl(schedule?.code);

    const [viewContactIdInDialog, setViewContactIdInDialog] = useState<number>();
    const researchContactToViewInDialog = viewContactIdInDialog === undefined ? undefined : researchContacts?.find(c => c.contact.id === viewContactIdInDialog);
    const { confirmRemoveDialog, removeContactFromResearch } = useTaskManageContactsInResearch(props);
    const { createReachOutOperation, refreshResearchContactOperation } = useTaskManageResearchReachOuts(props);
    const [deleteActivity, confirmDeleteActivityElement] = useDeleteActivityDialog(props.ideaId, ({ personId }) => refreshResearchContactOperation(personId));

    function onResearchContactStageChange(contactId: number) {
        updateResearchContactExpandedState(contactId, false);
        autoExpandFirstPendingWorkContact.current = contactId;
    }

    const isLoading = !researchContacts || !research || (props.isEditing && schedule === undefined) || !reachOutTemplates || !htmlParseFunction;
    return (
        <EditorMainArea hasError={showErrors}>
            {isLoading ? (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            ) : (
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                    {props.isEditing && schedule === null && (
                        <div className="k-p-4 k-text-center">
                            <div className="k-mb-4">Cannot invite contacts because you don't have personal schedule.</div>
                            <Link
                                className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md"
                                to={`/startups/${props.ideaId}/calendar/schedules/create?researchId=${props.params.researchId}`}
                            >
                                <SvgIconButtonContent icon={AddIcon}>Add schedule</SvgIconButtonContent>
                            </Link>
                        </div>
                    )}
                    {researchContacts.map(researchContact => {
                        const contactId = researchContact.contact.id;
                        const isInvited = researchContactStageOrder[researchContact.stage] >= invitedStageIndex;
                        const relatedUser = researchContact.reachOutActivity?.researchReachOut.user;
                        const relatedSchedule = relatedUser ? researchSchedules?.find(s => s.user.userId === relatedUser.userId) : undefined;

                        return (
                            <ResearchContactTogglePanel
                                ref={r => {
                                    if (r) researchContactsElementsRef.current[contactId] = r;
                                    else delete researchContactsElementsRef.current[contactId];
                                }}
                                key={contactId}
                                researchContact={researchContact}
                                isExpanded={researchContactsExpandedState[contactId]}
                                onToggle={() => updateResearchContactExpandedState(contactId)}
                                stageOverride={isInvited ? ResearchContactStage.Invited : undefined}
                                disabled={props.isEditing && !schedule}
                                invalid={!isInvited && researchContactsWithError?.[contactId]}
                                onDelete={
                                    props.isEditing
                                        ? () =>
                                              removeContactFromResearch(
                                                  contactId,
                                                  `${researchContact.contact.firstName} ${researchContact.contact.lastName}`
                                              ).then(removed => removed && (autoExpandFirstPendingWorkContact.current = true))
                                        : undefined
                                }
                                headerAdditionalContent={
                                    relatedUser && <ResearchContactRelatedUser ideaId={props.ideaId} user={relatedUser} scheduleId={relatedSchedule?.id} />
                                }
                            >
                                {researchContact.reachOutActivity ? (
                                    <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                                        <div style={{ maxWidth: 240 }}>This activity was created in your Calendar:</div>
                                        <ResearchReachOutCard
                                            activity={researchContact.reachOutActivity}
                                            className="k-flex-1"
                                            onEdit={
                                                props.isEditing || researchContact.reachOutActivity.researchReachOut.sent
                                                    ? () => setViewContactIdInDialog(researchContact.contact.id)
                                                    : undefined
                                            }
                                            onDelete={
                                                props.isEditing
                                                    ? () => deleteActivity(researchContact.contact.id, researchContact.reachOutActivity!)
                                                    : undefined
                                            }
                                        />
                                    </StackLayout>
                                ) : researchContact.meetingActivity ? (
                                    <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-6">
                                        <img src={interviewExistsIllustrationUrl} alt="Interview already exists" width="49" height="48" />
                                        <div>
                                            No need to reach out to contact, as a meeting has already been scheduled.
                                            <br />
                                            The meeting is visible in Calendar and Contact Activities.
                                        </div>
                                    </StackLayout>
                                ) : (
                                    <CreateResearchMeetingInviteForm
                                        disabled={!props.isEditing}
                                        currentUser={currentUser ?? undefined}
                                        reachOutTemplates={reachOutTemplates!}
                                        invitee={researchContact.contact}
                                        scheduleDuration={schedule?.durationMinutes}
                                        bookingPageUrl={bookingPageUrl}
                                        htmlParseFunction={htmlParseFunction}
                                        onSave={async data => {
                                            await createReachOutOperation(researchContact.contact.id, data);
                                            onResearchContactStageChange(contactId);
                                        }}
                                    />
                                )}
                            </ResearchContactTogglePanel>
                        );
                    })}
                    {researchContactToViewInDialog && researchContactToViewInDialog.reachOutActivity && (
                        <ResearchReachOutEventDialog
                            ideaId={props.ideaId}
                            itemKey={{ researchId: props.params.researchId, reachOutId: researchContactToViewInDialog.reachOutActivity.researchReachOut.id }}
                            person={researchContactToViewInDialog.contact}
                            onCancel={() => setViewContactIdInDialog(undefined)}
                            onSaved={async () => {
                                const contactId = researchContactToViewInDialog.contact.id;
                                const freshResearchContact = await refreshResearchContactOperation(contactId);
                                setViewContactIdInDialog(undefined);

                                if (researchContactToViewInDialog.stage !== freshResearchContact.stage) {
                                    onResearchContactStageChange(contactId);
                                }
                            }}
                        />
                    )}
                    {confirmRemoveDialog}
                    {confirmDeleteActivityElement}
                </StackLayout>
            )}

            <EditorErrorsList isEditing={props.isEditing} errors={props.errors} />
        </EditorMainArea>
    );
});

const createInviteNotOrLaterOptions: ButtonGroupSelectorOption<boolean>[] = [
    {
        text: 'Now',
        value: true
    },
    {
        text: 'Later',
        value: false
    }
];
function CreateResearchMeetingInviteForm({
    invitee,
    currentUser,
    scheduleDuration,
    reachOutTemplates,
    bookingPageUrl,
    disabled,
    htmlParseFunction,
    onSave
}: {
    currentUser?: User;
    invitee: ReducedPerson;
    scheduleDuration?: number;
    bookingPageUrl?: string;
    reachOutTemplates: ResearchReachOutTemplate[];
    disabled?: boolean;
    htmlParseFunction: (html: string) => any;
    onSave?: (data: ResearchReachOutData) => Promise<unknown>;
}) {
    const [executingNow, setExecutingNow] = useState<boolean>();
    const [saveDisabled, saveCallbackCreator] = useSingleClickButton<Parameters<NonNullable<FormProps['onSubmit']>>, Promise<unknown>>();

    return (
        <Form
            onSubmit={
                disabled
                    ? undefined
                    : onSave &&
                      saveCallbackCreator(async data => {
                          const reachOutData: ResearchReachOutData = executingNow
                              ? {
                                    date: new Date(),
                                    connectionType: data.connectionType,
                                    reachOutType: data.reachOutType,
                                    sent: true
                                }
                              : {
                                    date: data.date
                                };

                          await onSave(reachOutData);
                      })
            }
            ignoreModified={true}
            render={formRenderProps => {
                const selectedReachOutType: ReachOutType | undefined = formRenderProps.valueGetter('reachOutType');
                const selectedConnectionType: ReachOutConnectionType | undefined = formRenderProps.valueGetter('connectionType');

                const reachOutTypeAndConnectionTypeSelected = Boolean(selectedReachOutType && selectedConnectionType);

                const selectedReachOutInviteContent = reachOutTypeAndConnectionTypeSelected
                    ? reachOutTemplates.find(content => content.reachOutType === selectedReachOutType && content.connectionType === selectedConnectionType)
                    : undefined;

                const inviteContent =
                    selectedReachOutInviteContent && currentUser && scheduleDuration && bookingPageUrl
                        ? problemValidationInviteContentProvider.getInviteData(selectedReachOutInviteContent, {
                              inviteeFirstName: invitee.firstName,
                              inviteeEmail: invitee.emailAddress,
                              hostFirstName: currentUser.firstName,
                              hostLastName: currentUser.lastName,
                              hostPhoneNumber: currentUser.phoneNumber,
                              meetingDuration: scheduleDuration,
                              bookingPageUrl: bookingPageUrl
                          })
                        : undefined;

                return (
                    <FormElement>
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4 k-icp-component-border">
                            <CreateResearchMeetingInviteFormRow label="When would you like to reach out this contact?" disabled={disabled}>
                                <ButtonGroupSelector
                                    disabled={disabled}
                                    value={executingNow}
                                    onChange={e => setExecutingNow(e.value)}
                                    options={createInviteNotOrLaterOptions}
                                />
                            </CreateResearchMeetingInviteFormRow>
                            {executingNow ? (
                                <>
                                    <div className="k-separator" />
                                    <CreateResearchMeetingInviteFormRow label="What is your connection with the contact?" disabled={disabled}>
                                        <Field
                                            name="connectionType"
                                            component={ValidatedInput}
                                            inputType={ButtonGroupSelector}
                                            validator={researchReachOutConnectionTypeValidators}
                                            options={connectionTypeButtonGroupSelectorOptions}
                                        />
                                    </CreateResearchMeetingInviteFormRow>
                                    <div className="k-separator" />
                                    <CreateResearchMeetingInviteFormRow label="How would you like to reach out this contact?" disabled={disabled}>
                                        <Field
                                            name="reachOutType"
                                            component={ValidatedInput}
                                            inputType={ButtonGroupSelector}
                                            validator={reachOutTypeValidators}
                                            options={reachOutButtonGroupSelectorOptions}
                                        />
                                    </CreateResearchMeetingInviteFormRow>
                                    <div className="k-separator" />
                                    <CreateResearchMeetingInviteFormRow
                                        label="Follow the guidance when reaching out to the contact"
                                        disabled={disabled || !reachOutTypeAndConnectionTypeSelected}
                                    >
                                        {reachOutTypeAndConnectionTypeSelected ? (
                                            inviteContent && <InviteContentView inviteContent={inviteContent} htmlParseFunction={htmlParseFunction} />
                                        ) : (
                                            <div className="k-icp-subtle-text">
                                                Guidance will be presented after your connection with the contact and means of reaching out are picked.
                                            </div>
                                        )}
                                    </CreateResearchMeetingInviteFormRow>
                                    <div className="k-separator" />
                                    <CreateResearchMeetingInviteFormRow
                                        label="Contact received an invite link?"
                                        disabled={disabled || !reachOutTypeAndConnectionTypeSelected}
                                    >
                                        <Button
                                            disabled={!formRenderProps.allowSubmit || saveDisabled || disabled || !reachOutTypeAndConnectionTypeSelected}
                                            type="submit"
                                        >
                                            Confirm
                                        </Button>
                                        <div className="k-fs-sm k-icp-subtle-text k-mt-2">
                                            This action does not send out any email. It will log an activity in your Calendar.
                                        </div>
                                    </CreateResearchMeetingInviteFormRow>
                                </>
                            ) : executingNow === false ? (
                                <>
                                    <div className="k-separator" />
                                    <CreateResearchMeetingInviteFormRow label="When do you plan to reach out?" disabled={disabled}>
                                        <Field
                                            name="date"
                                            component={ValidatedInput}
                                            inputType={DateAndTimePicker}
                                            validator={researchReachOutCreateDateValidators}
                                        />
                                    </CreateResearchMeetingInviteFormRow>
                                    <div className="k-separator" />
                                    <CreateResearchMeetingInviteFormRow label="Create a reminder in your calendar" disabled={disabled}>
                                        <Button disabled={!formRenderProps.allowSubmit || saveDisabled || disabled} type="submit">
                                            Add reminder
                                        </Button>
                                        <div className="k-fs-sm k-icp-subtle-text k-mt-2">This action will create an activity in your Calendar</div>
                                    </CreateResearchMeetingInviteFormRow>
                                </>
                            ) : (
                                undefined
                            )}
                        </StackLayout>
                    </FormElement>
                );
            }}
        />
    );
}

function CreateResearchMeetingInviteFormRow({ label, children, disabled }: { label: string; children?: ReactNode; disabled?: boolean }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className={combineClassNames('k-gap-4', disabled ? 'k-disabled' : undefined)}>
            <div className="k-flex-shrink-0" style={{ width: 240 }}>
                {label}
            </div>
            <div className="k-flex-1">{children}</div>
        </StackLayout>
    );
}
