import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StartupInviteMemberModal } from '../../components/startup/startupInviteMember';
import { StartupMembershipDetailedList } from '../../components/startup/startupMembershipList';
import { LogoInput } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import Panel from '../../components/ui/panel';
import { H1, P } from '../../components/ui/typography';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { ReactComponent as InviteIcon } from '../../icons/user-add.svg';
import { getPersonFullName } from '../../services/common';
import { Idea, ideasService } from '../../services/ideasService';
import { realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { UserMembershipEntry, UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setIdea as setGlobalIdea } from '../../state/idea/ideaSlice';

export default function StartupSettingsPage() {
    const { ideaId } = useParams();
    const [idea, setIdea] = useState<Idea | null>();
    const currentUser = useAppSelector(s => s.user);
    const currentUserId = currentUser?.userId;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState({
        loaded: false,
        isAdministrator: false,
        canInviteMembers: false,
        canDelete: false
    });
    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();

    const membershipListRef = useRef<any>();

    const [showInviteMembersPopup, setShowInviteMembersPopup] = useState(false);
    const inviteMembersButtonRef = useRef<Button>(null);
    const location = useLocation();

    const loadCurrentIdea = useCallback(async () => {
        if (!ideaId) return;

        const ideaToView = await ideasService.get(ideaId);

        setIdea(ideaToView);
    }, [ideaId]);

    useEffect(() => {
        loadCurrentIdea();
    }, [loadCurrentIdea]);

    const onMembersLoaded = useCallback(
        (members: UserMembershipEntry[]) => {
            const currentUserMembership = members.find(m => m.user.userId === currentUserId);
            if (currentUserMembership) {
                setPermissions({
                    loaded: true,
                    isAdministrator: currentUserMembership.role === UserRole.Administrator,
                    canInviteMembers: currentUserMembership.role === UserRole.Administrator || currentUserMembership.role === UserRole.Editor,
                    canDelete: currentUserMembership.role === UserRole.Administrator
                });
            }
        },
        [currentUserId]
    );

    useEffect(() => {
        if (permissions.loaded && location.hash === '#members') {
            document.getElementById('members')?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [permissions.loaded, location.hash]);

    const updateLogo = async ({ value }: { value?: string | null }) => {
        if (!ideaId) return;

        const updatedIdea = await ideasService.partialUpdate(ideaId, { logo: value || null });
        setIdea(updatedIdea);
        dispatch(setGlobalIdea(updatedIdea));
    };

    useEffect(() => {
        realTimeUpdatesEventHub.addEventListener('idea', 'updated', loadCurrentIdea);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('idea', 'updated', loadCurrentIdea);
        };
    }, [loadCurrentIdea]);

    const deleteIdea = async () => {
        await ideasService.delete(ideaId!);
        navigate('/');
    };

    const deleteIdeaWithConfirmation = () => {
        showConfirmDialog({
            title: 'Delete startup',
            content: (
                <>
                    Are you sure you want to delete the <strong>“{idea!.title}”</strong> startup?
                </>
            ),
            confirmCheckboxText: 'I acknowledge that this cannot be undone',
            confirmButtonText: 'Delete startup',
            callback: () => deleteIdea()
        });
    };

    const leaveIdea = async () => {
        await ideasService.leave(ideaId!);
        navigate('/');
    };

    const leaveIdeaWithConfirmation = () => {
        showConfirmDialog({
            title: 'Leave startup',
            content: (
                <>
                    Are you sure you want to leave <strong>“{idea!.title}”</strong> startup?
                </>
            ),
            confirmButtonText: 'Leave startup',
            callback: () => leaveIdea()
        });
    };

    return (
        <>
            <H1 className="heading-row">Settings</H1>
            <div className="page-content-middle">
                {idea ? (
                    <StackLayout orientation="vertical" className="k-gap-6">
                        <div className="k-text-center">
                            <LogoInput
                                name="logo"
                                onChange={updateLogo}
                                value={idea.logo}
                                ideaId={idea.uniqueId}
                                className="k-d-flex k-flex-col k-align-items-center"
                                canEdit={permissions.isAdministrator}
                            />
                        </div>
                        <Panel title="Startup info" onEdit={permissions.isAdministrator ? () => navigate('edit') : undefined}>
                            <PanelRow name="Name" value={idea.title} />
                            <PanelRow
                                name="Owner"
                                value={
                                    getPersonFullName(idea.account.owner.firstName, idea.account.owner.lastName) +
                                    (currentUser && currentUser.userId === idea.account.owner.userId ? ' (You)' : '')
                                }
                            />
                            <PanelRow name="Description" value={idea.description || 'Your startup idea needs a description'} />
                        </Panel>
                        <Panel
                            title={<span id="members">Members</span>}
                            footer={
                                permissions.canInviteMembers ? (
                                    <>
                                        {showInviteMembersPopup && (
                                            <StartupInviteMemberModal
                                                ideaId={idea.uniqueId}
                                                ideaTitle={idea.title}
                                                onClose={() => setShowInviteMembersPopup(false)}
                                                onInvited={() => {
                                                    membershipListRef.current?.refresh();
                                                }}
                                                showManageLink={false}
                                            />
                                        )}
                                        <div>
                                            <Button
                                                ref={inviteMembersButtonRef}
                                                fillMode="flat"
                                                themeColor="secondary"
                                                onClick={() => setShowInviteMembersPopup(s => !s)}
                                            >
                                                <StackLayout className="k-gap-1" align={{ vertical: 'middle' }}>
                                                    <InviteIcon className="k-icp-icon k-icp-icon-size-4" />
                                                    Invite
                                                </StackLayout>
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    undefined
                                )
                            }
                        >
                            <StartupMembershipDetailedList
                                ref={membershipListRef}
                                currentUserId={currentUserId}
                                ideaId={ideaId as string}
                                edit={true}
                                ideaTitle={idea.title}
                                onMembersLoaded={onMembersLoaded}
                            />
                        </Panel>

                        {permissions.loaded && (
                            <Panel title={<span className="k-text-error">{permissions.canDelete ? 'Delete startup' : 'Leave startup'}</span>}>
                                {permissions.canDelete ? (
                                    <>
                                        <div className="k-icp-panel-section k-flex-col formatted-content-area">
                                            <P>This action will permanently delete the startup for you and all other members.</P>
                                            <P>
                                                <strong>Deleted startups cannot be restored!</strong>
                                            </P>
                                        </div>
                                        <div className="k-icp-panel-section !k-pb-4">
                                            <Button onClick={deleteIdeaWithConfirmation}>
                                                <span className="k-text-error">Delete startup</span>
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="k-icp-panel-section">
                                            If you leave the startup, you won't be able to rejoin unless you receive a new invite.
                                        </div>
                                        <div className="k-icp-panel-section !k-pb-4">
                                            <Button onClick={leaveIdeaWithConfirmation}>
                                                <span className="k-text-error">Leave startup</span>
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </Panel>
                        )}
                    </StackLayout>
                ) : (
                    <div className="k-text-center">
                        <LoadingIndicator size="big" />
                    </div>
                )}
            </div>
            {confirmDialog}
        </>
    );
}

const PanelRow = ({ name, value }: { name: string; value: string }) => (
    <div className="k-icp-panel-section">
        <div className="k-icp-panel-section-header">
            <div className="k-label !k-py-1">{name}</div>
        </div>
        <div className="k-flex-grow">
            <span className="k-input k-input-md k-button-clear !k-py-1">{value}</span>
        </div>
    </div>
);
