import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ForgottenPasswordForm, ForgottenPasswordRequested } from '../../components/auth/forgottenPassword';
import { H1 } from '../../components/ui/typography';
import { useReturnUrl } from '../../hooks/routerHooks';
import { pathWithReturnUrl } from '../../services/common';

export function ForgottenPasswordPage() {
    const returnUrl = useReturnUrl();
    const [forgottenPasswordRequestedForEmail, setForgottenPasswordRequestedForEmail] = useState<string>();

    if (forgottenPasswordRequestedForEmail) return <ForgottenPasswordRequested emailAddress={forgottenPasswordRequestedForEmail} />;

    return (
        <>
            <H1>Forgot your password?</H1>
            <div className="auth-page-content-section">
                <ForgottenPasswordForm onSubmit={email => setForgottenPasswordRequestedForEmail(email)} />
            </div>
            <div className="auth-page-content-section k-pt-4 k-icp-bordered-top k-icp-component-border k-text-center">
                <Link
                    to={pathWithReturnUrl('/login', returnUrl)}
                    className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md"
                >
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                        <span className="k-icon k-i-arrow-chevron-left"></span> Back to Login
                    </StackLayout>
                </Link>
            </div>
        </>
    );
}
