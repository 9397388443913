import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { pathWithReturnUrl } from '../../services/common';

export function BillingPortalLink({ className, children }: { className?: string; children?: ReactNode }) {
    // call this to trigger rerender of the component when the location changes
    useLocation();
    return (
        <Link className={className} to={pathWithReturnUrl('/account/billing-portal', window.location.href)}>
            {children}
        </Link>
    );
}
