import { DropDownButton } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useNavigate } from 'react-router-dom';
import { TextMarker } from '../../components/ui/textMarker';
import { H1 } from '../../components/ui/typography';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { TabbedLayout, TabHeader } from '../layouts/tabbedLayout';

const tabs: TabHeader[] = [
    {
        title: 'People',
        to: ''
    },
    {
        title: 'Companies',
        to: 'companies'
    }
];

const createItems: string[] = ['Person', 'Company'];

export function ContactsPage() {
    const navigate = useNavigate();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canCreateContacts = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-minh100">
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8 heading-row">
                <TextMarker text="Early Preview">
                    <H1>Contacts</H1>
                </TextMarker>
                {canCreateContacts && (
                    <>
                        <div className="vertical-separator" />
                        <DropDownButton
                            text={
                                <StackLayout className="k-gap-1">
                                    <PlusIcon className="k-icp-icon" />
                                    New contact
                                </StackLayout>
                            }
                            popupSettings={{
                                popupClass: 'new-contact-dropdown-button-menu'
                            }}
                            items={createItems}
                            themeColor="primary"
                            size="large"
                            onItemClick={e => {
                                if (e.itemIndex === 0) navigate('people/create');
                                else if (e.itemIndex === 1) navigate('companies/create');
                            }}
                        />
                    </>
                )}
            </StackLayout>

            <TabbedLayout headers={tabs} />
        </StackLayout>
    );
}
