import { ErrorWithOperationDisplayName } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { InterviewType } from './interviewsService';

export type InterviewScriptEntryLabel = {
    text: string;
    color: string;
};

export type InterviewScriptEntry = {
    id: number;
    label?: InterviewScriptEntryLabel;
    hidden: boolean;
    content: string;
    tips: InterviewScriptTip[];
};

export type InterviewScriptSection = {
    id: number;
    title: string;
    hidden: boolean;
    durationInMinutes: number;
    entries: InterviewScriptEntry[];
};

export type InterviewScriptTip = {
    id: number;
    content: string;
};

export type InterviewScriptTemplate = {
    id: string;
    // TODO: Revise this - template types for the section and everything under it are now separate from proper instance types
    // (At the time of writing the comment, the only difference is that ids are numbers rather than strings.)
    sections: InterviewScriptSection[];
};

export type InterviewScript = {
    id: number;
    interviewType: InterviewType;
    durationInMinutes: number;
    ready: boolean;
    error: boolean;
    createdOn: Date;
    sections: InterviewScriptSection[];
};

export type InterviewScriptParameter = {
    name: 'job-to-be-done';
    value: string;
};

class InterviewScriptsService extends HttpServiceBase {
    constructor() {
        super('/api/interviews');
    }

    @ErrorWithOperationDisplayName('Get interview script template')
    getInterviewScriptTemplate(id: string): Promise<InterviewScriptTemplate> {
        return this.performRequest({
            path: `/templates/${id}`
        });
    }

    @ErrorWithOperationDisplayName('Get interview script')
    async getInterviewScript(ideaId: string, interviewScriptId: number): Promise<InterviewScript> {
        const interviewScript = await this.performRequest<InterviewScript>({
            path: `/${ideaId}/scripts/${interviewScriptId}`
        });

        dateTimeService.ensureDateType(i => i, 'createdOn', interviewScript);
        return interviewScript;
    }

    @ErrorWithOperationDisplayName('Get interview script section')
    getSection(ideaId: string, interviewScriptId: number, sectionId: number): Promise<InterviewScriptSection> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}`
        });
    }

    @ErrorWithOperationDisplayName('Create interview script section')
    addSection(ideaId: string, interviewScriptId: number, title: string, durationInMinutes: number, afterId?: number): Promise<InterviewScriptSection> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections`,
            method: RequestMethod.POST,
            body: {
                title: title,
                durationInMinutes: durationInMinutes,
                insertAfter: afterId
            }
        });
    }

    @ErrorWithOperationDisplayName('Update interview script section')
    updateSection(ideaId: string, interviewScriptId: number, sectionId: number, title: string, durationInMinutes: number): Promise<InterviewScriptSection> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}`,
            method: RequestMethod.PUT,
            body: {
                title: title,
                durationInMinutes: durationInMinutes
            }
        });
    }

    @ErrorWithOperationDisplayName('Delete interview script section')
    deleteSection(ideaId: string, interviewScriptId: number, sectionId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Hide interview script section')
    hideSection(ideaId: string, interviewScriptId: number, sectionId: number): Promise<InterviewScriptSection> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/hide`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Unhide interview script section')
    unhideSection(ideaId: string, interviewScriptId: number, sectionId: number): Promise<InterviewScriptSection> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/unhide`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Reset interview script section')
    resetSection(ideaId: string, interviewScriptId: number, sectionId: number): Promise<InterviewScriptSection> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/reset`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Undo resetting of interview script section')
    undoResetSection(ideaId: string, interviewScriptId: number, sectionId: number): Promise<InterviewScriptSection> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/undo-reset`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Restore interview script section')
    restoreSection(ideaId: string, interviewScriptId: number, sectionId: number): Promise<InterviewScriptSection> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/restore`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Reorder interview script section')
    reorderSection(ideaId: string, interviewScriptId: number, sectionId: number, afterId?: number): Promise<void> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/reorder`,
            method: RequestMethod.POST,
            body: {
                afterId: afterId
            }
        });
    }

    @ErrorWithOperationDisplayName('Get interview script question')
    getEntry(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number): Promise<InterviewScriptEntry> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}`,
            method: RequestMethod.GET
        });
    }

    @ErrorWithOperationDisplayName('Add interview script question')
    addEntry(ideaId: string, interviewScriptId: number, sectionId: number, content: string): Promise<InterviewScriptEntry> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries`,
            method: RequestMethod.POST,
            body: {
                text: content
            }
        });
    }

    @ErrorWithOperationDisplayName('Update interview script question')
    updateEntry(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number, content: string | null): Promise<InterviewScriptEntry> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}`,
            method: RequestMethod.PUT,
            body: {
                text: content
            }
        });
    }

    @ErrorWithOperationDisplayName('Delete interview script question')
    deleteEntry(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Hide interview script question')
    hideEntry(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number): Promise<InterviewScriptEntry> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/hide`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Unhide interview script question')
    unhideEntry(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number): Promise<InterviewScriptEntry> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/unhide`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Reset interview script question')
    resetEntry(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number): Promise<InterviewScriptEntry> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/reset`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Undo resetting interview script question')
    undoResetEntry(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number): Promise<InterviewScriptEntry> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/undo-reset`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Reorder interview script question')
    reorderEntry(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number, afterId?: number): Promise<void> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/reorder`,
            method: RequestMethod.POST,
            body: {
                afterId: afterId
            }
        });
    }

    @ErrorWithOperationDisplayName('Restore interview script question')
    restoreEntry(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number): Promise<InterviewScriptEntry> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/restore`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Get interview script tip')
    getTip(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number, tipId: number): Promise<InterviewScriptTip> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/tips/${tipId}`,
            method: RequestMethod.GET
        });
    }

    @ErrorWithOperationDisplayName('Add interview script tip')
    addTip(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number, content: string): Promise<InterviewScriptTip> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/tips`,
            method: RequestMethod.POST,
            body: {
                text: content
            }
        });
    }

    @ErrorWithOperationDisplayName('Update interview script tip')
    updateTip(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number, tipId: number, content: string): Promise<InterviewScriptTip> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/tips/${tipId}`,
            method: RequestMethod.PUT,
            body: {
                text: content
            }
        });
    }

    @ErrorWithOperationDisplayName('Delete interview script tip')
    deleteTip(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number, tipId: number): Promise<InterviewScriptTip> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/tips/${tipId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore interview script tip')
    restoreTip(ideaId: string, interviewScriptId: number, sectionId: number, entryId: number, tipId: number): Promise<InterviewScriptTip> {
        return this.performRequest({
            path: `/${ideaId}/scripts/${interviewScriptId}/sections/${sectionId}/entries/${entryId}/tips/${tipId}/restore`,
            method: RequestMethod.POST
        });
    }
}

export const interviewScriptsService = new InterviewScriptsService();
