export * from './common.async';
export * from './common.collections';
export * from './common.errors';
export * from './common.functionOperations';
export * from './common.immutable';
export * from './common.types';
export * from './common.url';

export function combineClassNames(...classNames: (string | undefined)[]) {
    let result: undefined | string = undefined;
    for (let classNameIndex = 0; classNameIndex < classNames.length; classNameIndex++) {
        const className = classNames[classNameIndex];
        if (className) result = result ? result + ' ' + className : className;
    }

    return result;
}

export const generateInitials = (desiredLength: number, firstName?: string, lastName?: string) => {
    let initials = '';
    const firstPartEndIndex = desiredLength / 2;
    if (firstName) {
        for (let i = 0; i < firstPartEndIndex; i++) {
            if (i < firstName.length) initials += firstName[i];
        }
    }

    if (lastName) {
        const secondPartLength = desiredLength - initials.length;
        for (let i = 0; i < secondPartLength; i++) {
            if (i < lastName.length) initials += lastName[i];
        }
    }

    return initials.toUpperCase();
};

export const getPersonFullName = (firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) return '';

    return `${firstName ?? ''} ${lastName ?? ''}`.trim();
};

export const getPreferredColorIndex = (uniqueId: string | number) => {
    const numberOfConfiguredColors = 14;

    if (typeof uniqueId === 'number') {
        return (uniqueId % numberOfConfiguredColors) + 1;
    }

    let sum = 0;
    for (let i = 0; i < uniqueId.length; i++) {
        sum += uniqueId.charCodeAt(i);
    }

    return (sum % numberOfConfiguredColors) + 1;
};

export function getRandomId(upperBoundExclusive: number, forbidden?: string[]): string {
    const maxNumberOfIterations = 100;
    for (let iteration = 0; iteration < maxNumberOfIterations; iteration++) {
        const randomNumber = Math.floor(Math.random() * upperBoundExclusive).toString();
        if (!forbidden || !forbidden.includes(randomNumber)) return randomNumber;
    }

    throw new Error(`Failed to generate random id in ${maxNumberOfIterations} iterations.`);
}

export function colorHexToRGB(hex: string): [number, number, number] {
    if (!hex.length) throw new Error('Invalid hex');
    if (hex[0] === '#') hex = hex.slice(1);

    if (hex.length !== 6) throw new Error(`Unsupported hex: ${hex}`);

    return [parseInt(hex.substring(0, 2), 16), parseInt(hex.substring(2, 4), 16), parseInt(hex.substring(4), 16)];
}

export function tryParseEnum<TEnum>(valueToParse: string | number, enumType: Record<string | number, TEnum | undefined>): TEnum | undefined {
    const foundEnumEntry = Object.entries(enumType).find(([_, enumValue]) => enumValue === valueToParse);
    if (!foundEnumEntry) return undefined;

    return enumType[foundEnumEntry[0]];
}

export function isEmptyObject(object: object) {
    return Object.keys(object).length === 0;
}

const macOsRegexes = [/(mac os x) ?([\w. ]*)/i, /(macintosh|mac_powerpc\b)(?!.+haiku)/i];
function checkIsMacOs() {
    if ('userAgentData' in navigator) {
        return (navigator.userAgentData as any).platform === 'macOS';
    } else if (navigator.platform.indexOf('Mac') !== -1 || macOsRegexes.some(regex => regex.test(navigator.userAgent))) {
        return true;
    }
    return false;
}

export const isMacOs = checkIsMacOs();
