import { useEffect, useState } from 'react';
import { DeletionRelatedEntriesNotice, RelatedEntryData } from '../../components/common/deletionRelatedEntriesNotice';
import { useAsRef } from '../../hooks/commonHooks';
import { contactsService } from '../../services/contactsService';

export function ContactDeletionRelatedEntriesNotice({ ideaId, personId, onReady }: { ideaId: string; personId: number; onReady?: () => void }) {
    const [relatedEntriesData, setRelatedEntriesData] = useState<RelatedEntryData[]>();
    const onReadyRef = useAsRef(onReady);

    useEffect(() => {
        contactsService
            .getPersonStats(ideaId, personId)
            .then(stats => {
                setRelatedEntriesData([
                    {
                        count: stats.noteCount,
                        entryName: 'note'
                    },
                    {
                        count: stats.meetingCount,
                        entryName: 'meeting'
                    },
                    {
                        count: stats.reachOutCount,
                        entryName: 'reach out'
                    },
                    {
                        count: stats.interviewCount,
                        entryName: 'interview'
                    },
                    {
                        count: stats.researchReachOutCount,
                        entryName: 'interview invite'
                    },
                    {
                        count: stats.researchCount,
                        entryName: 'research',
                        pluralEntryName: 'research',
                        isParent: true
                    }
                ]);
            })
            .finally(() => onReadyRef.current?.());
    }, [ideaId, onReadyRef, personId]);

    return <DeletionRelatedEntriesNotice entryName="Contact" relationsData={relatedEntriesData} />;
}
