import { Checkbox, CheckboxChangeEvent, CheckboxProps, Input } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useRef } from 'react';
import { combineClassNames } from '../../services/common';
import { LocationOption, LocationOptionType } from '../../services/schedulesService';
import { ChangeOnBlurInput } from '../ui/inputs';

const onlineLocationValue: LocationOption = { type: LocationOptionType.Online, details: 'Online' };
const phoneCallLocationValue: LocationOption = { type: LocationOptionType.PhoneCall, details: 'Phone call' };
const otherLocationValue: LocationOption = { type: LocationOptionType.Other, details: '' };

export function EventLocationsConfigurator({
    value,
    onChange,
    valid,
    disabled,
    changeInPersonLocationOnBlur
}: {
    value?: LocationOption[];
    onChange: (e: { value: LocationOption[] | undefined }) => void;
    valid?: boolean;
    disabled?: boolean;
    changeInPersonLocationOnBlur?: boolean;
}) {
    const lastInPersonLocationValueRef = useRef<string>('');
    function findLocationValue(type: LocationOptionType) {
        if (!value || !value.length) return undefined;

        return value.find(l => l.type === type);
    }

    function isLocationSet(type: LocationOptionType) {
        return typeof findLocationValue(type) !== 'undefined';
    }

    function ensureLocationIsPresent(location: LocationOption, replace?: boolean) {
        if (!replace && findLocationValue(location.type)) return;

        onChange?.({ value: value ? [...(replace ? value.filter(l => l.type !== location.type) : value), location] : [location] });
    }

    function ensureLocationIsNotPresent(locationType: LocationOptionType) {
        if (!value || !findLocationValue(locationType)) return;

        const newValue = value.filter(l => l.type !== locationType);
        onChange?.({ value: newValue.length ? newValue : undefined });
    }

    function onLocationCheckboxChange(e: CheckboxChangeEvent, location: LocationOption, replace?: boolean) {
        if (e.value) ensureLocationIsPresent(location, replace);
        else ensureLocationIsNotPresent(location.type);
    }

    const inPersonLocation = findLocationValue(LocationOptionType.InPerson);
    const isInPersonLocationPresent = typeof inPersonLocation !== 'undefined';
    if (isInPersonLocationPresent) lastInPersonLocationValueRef.current = inPersonLocation.details;

    const InPersonInput = changeInPersonLocationOnBlur ? ChangeOnBlurInput : Input;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-3">
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                <AutoBoldCheckbox
                    label="In person"
                    checked={isInPersonLocationPresent}
                    onChange={e =>
                        onLocationCheckboxChange(
                            e,
                            {
                                type: LocationOptionType.InPerson,
                                details: e.value ? (isInPersonLocationPresent ? inPersonLocation.details : lastInPersonLocationValueRef.current) : ''
                            },
                            true
                        )
                    }
                    valid={valid}
                    disabled={disabled}
                    className="in-person-location-option"
                />
                <InPersonInput
                    placeholder="Add location..."
                    valid={valid}
                    value={isInPersonLocationPresent ? inPersonLocation.details : lastInPersonLocationValueRef.current}
                    disabled={disabled || !isInPersonLocationPresent}
                    className="k-flex-1"
                    onChange={e => ensureLocationIsPresent({ type: LocationOptionType.InPerson, details: e.value }, true)}
                />
            </StackLayout>
            <AutoBoldCheckbox
                label="Online"
                checked={isLocationSet(LocationOptionType.Online)}
                onChange={e => onLocationCheckboxChange(e, onlineLocationValue)}
                valid={valid}
                disabled={disabled}
            />
            <AutoBoldCheckbox
                label="Phone call"
                checked={isLocationSet(LocationOptionType.PhoneCall)}
                onChange={e => onLocationCheckboxChange(e, phoneCallLocationValue)}
                valid={valid}
                disabled={disabled}
            />
            <AutoBoldCheckbox
                label="Allow other locations specified by attendee"
                checked={isLocationSet(LocationOptionType.Other)}
                onChange={e => onLocationCheckboxChange(e, otherLocationValue)}
                valid={valid}
                disabled={disabled}
            />
        </StackLayout>
    );
}

function AutoBoldCheckbox(props: CheckboxProps) {
    return <Checkbox {...props} className={props.checked ? combineClassNames(props.className, 'k-font-semibold') : props.className} />;
}
