import { ButtonProps } from '@progress/kendo-react-buttons';
import { Error as ErrorComponent } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, Fragment, ReactElement, ReactNode, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useWindowDimensions } from '../../hooks/commonHooks';
import { ReactComponent as DefaultItemCardIcon } from '../../icons/pause-circle.svg';
import { ReactComponent as AltIcon } from '../../icons/shortcuts/alt.svg';
import { ReactComponent as CommandIcon } from '../../icons/shortcuts/cmd.svg';
import { ReactComponent as CTRLIcon } from '../../icons/shortcuts/ctrl.svg';
import { ReactComponent as EnterIcon } from '../../icons/shortcuts/enter.svg';
import { ReactComponent as OptionIcon } from '../../icons/shortcuts/option.svg';
import { ReactComponent as ReturnIcon } from '../../icons/shortcuts/return.svg';
import { ReactComponent as ShiftIcon } from '../../icons/shortcuts/shift.svg';
import { ReactComponent as DeleteIcon } from '../../icons/trash-2.svg';
import { combineClassNames } from '../../services/common';
import { ScrollCancellationSource, domService } from '../../services/domService';
import { DivButton } from '../common/DivButton';
import { SvgIconButtonContent } from '../ui/svgIconButtonContent';

export type InterviewItemCardProps = {
    children: ReactNode;
    icon?: ComponentType<React.SVGProps<SVGSVGElement>>;
    iconClassName?: string;
    selected?: boolean;
    onClick?: () => void;
    complete?: boolean;
};

export type InterviewItemCardHandle = {
    element: HTMLElement | null;
};

export type InterviewItemCardIconData = { icon?: ComponentType<React.SVGProps<SVGSVGElement>>; className?: string };

export const InterviewItemCard = forwardRef<InterviewItemCardHandle, InterviewItemCardProps>(function InterviewItemCard(
    { children, icon, iconClassName, selected, onClick, complete },
    ref
) {
    const elementRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
        ref,
        () => ({
            get element() {
                return elementRef.current;
            }
        }),
        []
    );

    const Icon = icon ?? DefaultItemCardIcon;

    return (
        <div
            ref={elementRef}
            className={combineClassNames(
                'interview-entry-card k-panel k-p-2 k-cursor-pointer',
                complete ? 'k-bg-transparent' : undefined,
                selected ? '!k-border-secondary k-icp-shadow-md' : undefined
            )}
            onClick={onClick}
        >
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                <Icon className={combineClassNames('k-icp-icon k-icp-icon-size-4', iconClassName)} />
                <div className={combineClassNames('k-fs-sm k-flex-1 max-lines-3', complete ? 'k-text-line-through' : undefined)}>{children}</div>
            </StackLayout>
        </div>
    );
});

export function InterviewItemGroupTitle({
    children,
    displayStyle,
    className
}: {
    children?: ReactNode;
    displayStyle?: 'primary' | 'secondary';
    className?: string;
}) {
    if (!displayStyle || displayStyle === 'primary') return <strong className={combineClassNames('k-fs-lg', className)}>{children}</strong>;

    return <strong className={combineClassNames('k-fs-sm k-text-secondary', className)}>{children}</strong>;
}

export function InterviewItemGroupHeader({
    children,
    entriesWithStatusCount,
    totalEntriesCount
}: {
    children: ReactNode;
    entriesWithStatusCount: number;
    totalEntriesCount: number;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2 k-justify-content-between k-px-2">
            <InterviewItemGroupTitle displayStyle="secondary">{children}</InterviewItemGroupTitle>
            <span className="k-fs-sm">
                {entriesWithStatusCount}/{totalEntriesCount}
            </span>
        </StackLayout>
    );
}

export function InterviewItemGroupsList({ children }: { children?: ReactNode }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
            {children}
        </StackLayout>
    );
}

export function InterviewItemGroup({ header, children }: { header: ReactElement; children: ReactNode }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {header}
            {children}
        </StackLayout>
    );
}

export function useInterviewItemCardsList<TKey extends string | number | symbol>(rendered: boolean, selectedItemId?: TKey) {
    const cardsRef = useRef<Partial<Record<TKey, InterviewItemCardHandle>>>({});

    useEffect(() => {
        if (!rendered || selectedItemId === undefined) return;

        const entryCardRef = cardsRef.current[selectedItemId];
        if (!entryCardRef || !entryCardRef.element) return;

        const scrollCancelation: ScrollCancellationSource = {};
        domService.scrollVerticallyIntoViewIfNeeded(entryCardRef.element, undefined, scrollCancelation);

        return () => {
            scrollCancelation.cancel = true;
        };
    }, [rendered, selectedItemId]);

    return function setCardRef(ref: InterviewItemCardHandle | null, itemId: TKey) {
        if (ref) cardsRef.current[itemId] = ref;
        else delete cardsRef.current[itemId];
    };
}

export type InterviewItemEditorViewHandle = { element: HTMLElement | null };
export type InterviewItemEditorViewProps = {
    selected?: boolean;
    canDelete?: boolean;
    onDelete?: () => void;
    onBlur?: () => void;
    onFocus?: () => void;
    errorMessage?: string;
    children?: ReactNode;
    disabled?: boolean;
};
export const InterviewItemEditorView = forwardRef<InterviewItemEditorViewHandle, InterviewItemEditorViewProps>(function InterviewItemEditorView(
    { selected, canDelete, onDelete, onBlur, onFocus, errorMessage, children, disabled },
    ref
) {
    const wrapperElementRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
        ref,
        () => ({
            get element() {
                return wrapperElementRef.current;
            }
        }),
        []
    );

    function onWrapperElementBlur(e: React.FocusEvent<HTMLDivElement, Element>) {
        if (e.relatedTarget != null && e.currentTarget.contains(e.relatedTarget)) {
            return;
        }

        onBlur?.();
    }

    return (
        <div ref={wrapperElementRef} onBlur={onWrapperElementBlur} onFocus={onFocus}>
            <div
                className={combineClassNames(
                    'k-pos-relative k-border-solid k-border-2 k-rounded-lg',
                    errorMessage ? 'k-border-error' : selected ? 'k-border-secondary k-icp-shadow-md' : 'k-icp-component-border',
                    disabled ? 'k-disabled' : undefined
                )}
            >
                {children}
                {canDelete && (
                    <DivButton
                        size="small"
                        className="k-icp-svg-icon-button !k-pos-absolute k-pos-top-end interview-entry-editor-delete-btn"
                        themeColor={errorMessage ? 'error' : selected ? 'secondary' : undefined}
                        onClick={onDelete}
                    >
                        <DeleteIcon className="k-icp-icon" />
                    </DivButton>
                )}
            </div>
            {errorMessage && <ErrorComponent>{errorMessage}</ErrorComponent>}
        </div>
    );
});

export function InterviewItemEditorViewActionsBar({ actions, isResponsive }: { actions?: ReactElement[]; isResponsive?: boolean }) {
    const { width } = useWindowDimensions();
    const breakpoint = actions && actions.length >= 3 ? 1118 : undefined;
    const isVerticalView = isResponsive && breakpoint && width < breakpoint;

    return (
        <StackLayout
            orientation={isVerticalView ? 'vertical' : 'horizontal'}
            align={{ horizontal: 'center', vertical: 'middle' }}
            className="k-gap-2 k-icp-component-border"
        >
            {actions?.map((actionElement, actionElementIndex) => (
                <Fragment key={actionElementIndex}>
                    {!isVerticalView && actionElementIndex > 0 && <div className="k-separator" />}
                    {actionElement}
                </Fragment>
            ))}
        </StackLayout>
    );
}

export type ShortcutIconType = 'cmd' | 'ctrl' | 'shift' | 'enter' | 'return' | 'option' | 'alt';

const shortcutIconMap: Record<ShortcutIconType, ComponentType<React.SVGProps<SVGSVGElement>>> = {
    cmd: CommandIcon,
    ctrl: CTRLIcon,
    shift: ShiftIcon,
    enter: EnterIcon,
    return: ReturnIcon,
    option: OptionIcon,
    alt: AltIcon
};

export function InterviewItemEditorViewButton({
    children,
    icon,
    selected,
    themeColor,
    onClick,
    disabled,
    shortcutIconsTypes
}: {
    children: string;
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    selected?: boolean;
    themeColor?: ButtonProps['themeColor'];
    onClick?: () => void;
    disabled?: boolean;
    shortcutIconsTypes?: ShortcutIconType[];
}) {
    return (
        <DivButton
            size="small"
            fillMode="flat"
            themeColor={themeColor}
            className="k-icp-button-flat-alt"
            selected={selected}
            onClick={onClick}
            disabled={disabled}
        >
            <SvgIconButtonContent icon={icon}>
                {children}
                {shortcutIconsTypes && (
                    <StackLayout orientation="horizontal" align={{ vertical: 'middle' }} className="k-gap-hair k-pl-thin">
                        {shortcutIconsTypes.map(iconType => {
                            const ShortcutIcon = shortcutIconMap[iconType];
                            return <ShortcutIcon key={iconType} width={16} height={16} />;
                        })}
                    </StackLayout>
                )}
            </SvgIconButtonContent>
        </DivButton>
    );
}
