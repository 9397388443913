import { Fragment, ReactNode, createElement } from 'react';

const templateParameterRegex = /(\$\$.+?\$\$)/;
export function ServerTemplate({ children, evaluateParameter }: { children: string; evaluateParameter: (parameterName: string) => ReactNode }) {
    const templateParts = children.split(templateParameterRegex);
    const evaluatedTemplate = templateParts.map(part => {
        if (part.startsWith('$$') && part.endsWith('$$')) return evaluateParameter(part.substring(2, part.length - 2));

        return part;
    });

    return createElement(Fragment, undefined, ...evaluatedTemplate);
}
