import { combineClassNames } from '../../services/common';
import { ResearchContactStage } from '../../services/researchService';

export type ResearchContactStagePresentation = {
    label: string;
    className: string;
};

export const researchContactStagesPresentation: Record<ResearchContactStage, ResearchContactStagePresentation> = {
    [ResearchContactStage.NotInvited]: {
        label: 'Not invited',
        className: 'k-icp-bg-error-4'
    },
    [ResearchContactStage.InvitePending]: {
        label: 'In progress',
        className: 'k-icp-bg-warning-8'
    },
    [ResearchContactStage.Invited]: {
        label: 'Invited',
        className: 'k-icp-bg-success-8'
    },
    [ResearchContactStage.NotInterviewed]: {
        label: 'Not started',
        className: 'k-icp-bg-error-4'
    },
    [ResearchContactStage.InterviewPending]: {
        label: 'In progress',
        className: 'k-icp-bg-warning-8'
    },
    [ResearchContactStage.Interviewed]: {
        label: 'Interview conducted',
        className: 'k-icp-bg-warning-8'
    },
    [ResearchContactStage.Reviewed]: {
        label: 'Notes reviewed',
        className: 'k-icp-bg-warning-8'
    },
    [ResearchContactStage.InsightsCaptured]: {
        label: 'Insights captured',
        className: 'k-icp-bg-warning-8'
    },
    [ResearchContactStage.Complete]: {
        label: 'Completed',
        className: 'k-icp-bg-success-8'
    }
};

export function ResearchContactStageView({ stage, presentation }: { stage: ResearchContactStage; presentation?: ResearchContactStagePresentation }) {
    const stagePresentation = presentation ?? researchContactStagesPresentation[stage];

    return <ResearchLabel className={stagePresentation.className}>{stagePresentation.label}</ResearchLabel>;
}

export function ResearchLabel({ children, className }: { children: string; className?: string }) {
    return <div className={combineClassNames('k-fs-sm k-px-2 -py-0.75 k-rounded', className)}>{children}</div>;
}
