import { Button } from '@progress/kendo-react-buttons';
import { Error as ErrorComponent } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { Fragment, ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { TaskEditorProps, TaskEditorRef, useTaskCommitOperation } from '.';
import { useResearchSchedulesEditorDataSource } from '../../../hooks/researchHooks';
import { ReactComponent as AddIcon } from '../../../icons/plus.svg';
import { ReactComponent as FaceIcon } from '../../../icons/smile.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/trash-2.svg';
import emptySchedulesIllustrationUrl from '../../../images/empty-schedules-illustration-small.svg';
import { combineClassNames } from '../../../services/common';
import { dateTimeService } from '../../../services/dateTimeService';
import { domService } from '../../../services/domService';
import { ResearchEditorParams } from '../../../services/journeyService';
import { FullSchedule, LocationOption, ResearchScheduleData, ScheduleData, TimetableEntry, schedulesService } from '../../../services/schedulesService';
import { ReducedUser, UserRole, buildUserViewModel } from '../../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { addNotification } from '../../../state/notifications/platformNotificationsSlice';
import { TimeZonePicker } from '../../common/timeZonePicker';
import {
    ValidationScope,
    ValidationScopeHandle,
    ValidationSubScope,
    ValidationSubScopeHandle,
    ValidationUnit,
    ValidationUnitValidator,
    composeValidators
} from '../../common/validation';
import { EventLocationsConfigurator } from '../../events/eventLocationsConfigurator';
import { locationValidators } from '../../events/scheduleEditor';
import { WeeklySlotsEditor } from '../../events/slotsEditors';
import { StartupMemberPicker } from '../../startup/startupMemberPicker';
import { requiredValidator } from '../../ui/inputs';
import LoadingIndicator from '../../ui/loadingIndicator';
import { SvgIconButtonContent } from '../../ui/svgIconButtonContent';
import { UserSimpleView } from '../../user/userSimpleView';
import { EditorErrorsList } from './shared/editorErrorsList';
import { EditorMainArea } from './shared/editorMainArea';

export const ResearchSchedulesEditor = forwardRef<TaskEditorRef, TaskEditorProps<ResearchEditorParams>>(function(props, ref) {
    const validationScopeRef = useRef<ValidationScopeHandle>(null);
    const scheduleEditorsMap = useMemo<Map<string | number, ResearchScheduleEditorHandle>>(() => new Map(), []);
    const { scrollToFirstInvalidEditor, scrollToScheduleEditorWithKey } = useResearchSchedulesEditorScrolling(scheduleEditorsMap);

    useImperativeHandle(
        ref,
        () => ({
            commit() {
                if (!validationScopeRef.current) return;

                validationScopeRef.current.validate();
                if (validationScopeRef.current.isValid) return true;
                scrollToFirstInvalidEditor();

                return {
                    isValid: false,
                    preventScroll: true
                };
            }
        }),
        [scrollToFirstInvalidEditor]
    );

    const currentUserId = useAppSelector(s => s.user?.userId);
    const dispatch = useAppDispatch();

    const dataSource = useResearchSchedulesEditorDataSource(props.ideaId, props.params.researchId);
    const createCommitOperation = useTaskCommitOperation(props);
    const deleteScheduleOperation = createCommitOperation(schedulesService.deleteSchedule.bind(schedulesService));
    const partiallyUpdateScheduleOperation = createCommitOperation(schedulesService.partiallyUpdateSchedule.bind(schedulesService));
    const createScheduleForResearchOperation = createCommitOperation(schedulesService.createScheduleForResearch.bind(schedulesService));

    function registerScheduleEditor(editorRef: ResearchScheduleEditorHandle | null, editorKey: string | number): void {
        if (editorRef) scheduleEditorsMap.set(editorKey, editorRef);
        else scheduleEditorsMap.delete(editorKey);
    }

    const research = props.taskData.problemValidationResearch?.[props.params.researchId];
    const showErrors = props.isEditing && !!props.errors?.length;
    const isLoading = !research || !dataSource.schedules;
    const currentUserHasSchedule =
        currentUserId &&
        ((dataSource.schedules && dataSource.schedules.some(s => s.user.userId === currentUserId)) ||
            dataSource.hostsWithPendingSchedule.some(h => h.userId === currentUserId));
    const researchHasDates = Boolean(research && research.startDate && research.endDate);
    const allSchedules: (FullSchedule | ReducedUser)[] = isLoading
        ? dataSource.hostsWithPendingSchedule
        : [...dataSource.schedules, ...dataSource.hostsWithPendingSchedule];
    return (
        <EditorMainArea hasError={showErrors}>
            {isLoading ? (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            ) : (
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-5 k-icp-component-border">
                    <ValidationScope ref={validationScopeRef}>
                        <ValidationUnit
                            name="createdSchedules"
                            value={dataSource.schedules}
                            validator={schedules => (schedules.length ? undefined : 'Add at least one schedule')}
                        >
                            {(schedulesErrorMessage, onSchedulesChange) => {
                                function updateSchedules(schedules: FullSchedule[]) {
                                    onSchedulesChange(schedules);
                                    dataSource.setSchedules(schedules);
                                }

                                return (
                                    <>
                                        <SchedulesEditorSection title="Interview hosts">
                                            <PickScheduleHostForm
                                                ideaId={props.ideaId}
                                                disabled={!props.isEditing || !researchHasDates}
                                                errorMessage={
                                                    researchHasDates
                                                        ? dataSource.hostsWithPendingSchedule.length
                                                            ? undefined
                                                            : schedulesErrorMessage
                                                        : 'Set interviewing period to enable schedules creation'
                                                }
                                                forbiddenHostIds={allSchedules.map(schedule =>
                                                    isFullSchedule(schedule) ? schedule.user.userId : schedule.userId
                                                )}
                                                onSelected={host => {
                                                    dataSource.addPendingSchedule(host);
                                                    scrollToScheduleEditorWithKey(host.userId, true);
                                                }}
                                            />
                                        </SchedulesEditorSection>
                                        <div className="k-separator" />
                                        <SchedulesEditorSection title="Schedules">
                                            {allSchedules.length ? (
                                                <StackLayout
                                                    orientation="vertical"
                                                    align={{ horizontal: 'stretch', vertical: 'top' }}
                                                    className="k-gap-5 k-icp-component-border"
                                                >
                                                    {allSchedules.map((scheduleOrHost, scheduleIndex) => {
                                                        const isSchedule = isFullSchedule(scheduleOrHost);
                                                        const schedule: FullSchedule | undefined = isSchedule ? scheduleOrHost : undefined;
                                                        const host: ReducedUser | undefined = isSchedule ? undefined : scheduleOrHost;

                                                        const key = isSchedule ? scheduleOrHost.user.userId : scheduleOrHost.userId;

                                                        return (
                                                            <Fragment key={key}>
                                                                {scheduleIndex !== 0 && <div className="k-separator" />}
                                                                {schedule ? (
                                                                    <ResearchScheduleEditor
                                                                        ref={editorRef => registerScheduleEditor(editorRef, schedule.id)}
                                                                        schedule={schedule}
                                                                        disabled={!props.isEditing}
                                                                        onDelete={async () => {
                                                                            await deleteScheduleOperation(props.ideaId, schedule.id);
                                                                            updateSchedules(dataSource.schedules!.filter(s => s.id !== schedule.id));
                                                                            dispatch(
                                                                                addNotification(
                                                                                    { content: 'Schedule deleted.', actionText: 'Undo' },
                                                                                    async () => {
                                                                                        await schedulesService.restoreSchedule(props.ideaId, schedule.id);
                                                                                        await dataSource.loadResearchSchedules();
                                                                                    }
                                                                                )
                                                                            );
                                                                        }}
                                                                        onUpdate={async data => {
                                                                            const updatedSchedule = await partiallyUpdateScheduleOperation(
                                                                                props.ideaId,
                                                                                schedule.id,
                                                                                data
                                                                            );
                                                                            updateSchedules(
                                                                                dataSource.schedules!.map(s =>
                                                                                    s.id === updatedSchedule.id ? updatedSchedule : s
                                                                                )
                                                                            );
                                                                        }}
                                                                    />
                                                                ) : host ? (
                                                                    <ResearchScheduleEditor
                                                                        ref={editorRef => registerScheduleEditor(editorRef, host.userId)}
                                                                        defaultHost={host}
                                                                        disabled={!props.isEditing}
                                                                        onDelete={() => dataSource.removePendingSchedule(host.userId)}
                                                                        onCreate={async data => {
                                                                            const createdSchedule = await createScheduleForResearchOperation(
                                                                                props.ideaId,
                                                                                props.params.researchId,
                                                                                data
                                                                            );
                                                                            dataSource.removePendingSchedule(host.userId);
                                                                            updateSchedules([...dataSource.schedules!, createdSchedule]);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    undefined
                                                                )}
                                                            </Fragment>
                                                        );
                                                    })}
                                                    {!currentUserHasSchedule && (
                                                        <>
                                                            <div className="k-separator" />
                                                            <CurrentUserScheduleWarning />
                                                        </>
                                                    )}
                                                </StackLayout>
                                            ) : (
                                                <NoSchedulesView />
                                            )}
                                        </SchedulesEditorSection>
                                    </>
                                );
                            }}
                        </ValidationUnit>
                    </ValidationScope>
                </StackLayout>
            )}
            <EditorErrorsList isEditing={props.isEditing} errors={props.errors} />
        </EditorMainArea>
    );
});

function isFullSchedule(schedule: FullSchedule | ReducedUser): schedule is FullSchedule {
    return 'user' in schedule;
}

function useResearchSchedulesEditorScrolling(scheduleEditorsMap: Map<string | number, ResearchScheduleEditorHandle>) {
    const [pendingScrollToScheduleEditor, setPendingScrollToScheduleEditor] = useState<string | number>();
    const scrollToScheduleEditor = useCallback(function(editor: ResearchScheduleEditorHandle) {
        setPendingScrollToScheduleEditor(undefined);
        if (editor.element) domService.scrollIntoViewIfNeeded(editor.element);
    }, []);
    const scrollToScheduleEditorWithKey = useCallback(
        function(editorKey: string | number, allowDeferredScroll: boolean) {
            const editorToScrollTo = scheduleEditorsMap.get(editorKey);
            if (!editorToScrollTo) {
                if (allowDeferredScroll) setPendingScrollToScheduleEditor(editorKey);
            } else scrollToScheduleEditor(editorToScrollTo);
        },
        [scheduleEditorsMap, scrollToScheduleEditor]
    );
    const scrollToFirstInvalidEditor = useCallback(
        function() {
            const firstInvalidEditor = Array.from(scheduleEditorsMap.values()).find(e => !e.isValid);
            if (!firstInvalidEditor) return;
            scrollToScheduleEditor(firstInvalidEditor);
        },
        [scheduleEditorsMap, scrollToScheduleEditor]
    );

    useEffect(() => {
        if (!pendingScrollToScheduleEditor) return;
        scrollToScheduleEditorWithKey(pendingScrollToScheduleEditor, false);
    }, [pendingScrollToScheduleEditor, scrollToScheduleEditorWithKey]);

    return {
        scrollToFirstInvalidEditor,
        scrollToScheduleEditorWithKey
    };
}

function SchedulesEditorSection({ title, children }: { title: string; children?: ReactNode }) {
    return (
        <div>
            <div className="k-fs-lg k-font-medium k-mb-2">{title}</div>
            {children}
        </div>
    );
}

const SchedulePanel = forwardRef<HTMLDivElement, { children?: ReactNode; className?: string }>(function({ children, className }, ref) {
    return (
        <div ref={ref} className={combineClassNames('k-content k-rounded', className)}>
            {children}
        </div>
    );
});

function NoSchedulesView() {
    return (
        <SchedulePanel className="k-text-center k-pt-10 k-pb-8 k-px-4">
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-3">
                <img src={emptySchedulesIllustrationUrl} alt="No schedules" width="64" height="64" />
                <span>No schedules yet</span>
            </StackLayout>
        </SchedulePanel>
    );
}

function CurrentUserScheduleWarning() {
    return (
        <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-2">
            <FaceIcon className="k-icp-icon k-icp-icon-size-4" />
            <span>You have no schedule. Make sure to add one if you will be conducting interviews yourself.</span>
        </StackLayout>
    );
}

function PickScheduleHostForm({
    ideaId,
    disabled,
    onSelected,
    forbiddenHostIds,
    errorMessage
}: {
    ideaId: string;
    disabled?: boolean;
    onSelected?: (host: ReducedUser) => void;
    forbiddenHostIds?: string[];
    errorMessage?: string;
}) {
    const [selectedUser, setSelectedUser] = useState<ReducedUser | null>();
    const [validationError, setValidationError] = useState<string>();

    const errorMessageToShow = validationError || errorMessage;
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            <div className="k-flex-1">
                <StartupMemberPicker
                    ideaId={ideaId}
                    disabled={disabled}
                    placeholder="Select team member..."
                    excludedUserIds={forbiddenHostIds}
                    roles={[UserRole.Editor, UserRole.Administrator]}
                    className="k-picker-solid"
                    valid={!validationError}
                    value={selectedUser}
                    onChange={e => {
                        setValidationError(undefined);
                        setSelectedUser(e.value);
                    }}
                />
                {errorMessageToShow && <ErrorComponent>{errorMessageToShow}</ErrorComponent>}
            </div>

            <Button
                disabled={disabled || !!validationError}
                onClick={() => {
                    if (!selectedUser) setValidationError('Host is required');
                    else {
                        onSelected?.(selectedUser);
                        setSelectedUser(null);
                    }
                }}
            >
                <SvgIconButtonContent icon={AddIcon}>Add schedule</SvgIconButtonContent>
            </Button>
        </StackLayout>
    );
}

type ResearchScheduleEditorProps = {
    onDelete?: () => void;
    disabled?: boolean;
    onUpdate?: (data: Partial<ScheduleData>) => Promise<void>;
    onCreate?: (data: ResearchScheduleData) => Promise<void>;
} & ({ defaultHost: ReducedUser } | { schedule: FullSchedule });
type ResearchScheduleEditorHandle = {
    readonly element: HTMLElement | null;
    readonly isValid: boolean;
};

const defaultSlotLength = 60;
const locationValidator = composeValidators(locationValidators);
const timeZoneValidator = requiredValidator('Timezone');
const timetableEntriesValidator: ValidationUnitValidator<TimetableEntry[] | undefined> = function(entries) {
    if (!entries || !entries.some(e => e.startTimes.length > 0)) return 'Specify at least one interview slot';

    return undefined;
};
const ResearchScheduleEditor = forwardRef<ResearchScheduleEditorHandle, ResearchScheduleEditorProps>(function(props, ref) {
    const wrapperElementRef = useRef<HTMLDivElement>(null);
    const validationSubScopeRef = useRef<ValidationSubScopeHandle>(null);
    const currentUserId = useAppSelector(s => s.user?.userId);
    const [saveInProgress, setSaveInProgress] = useState(false);

    useImperativeHandle(
        ref,
        () => ({
            get element() {
                return wrapperElementRef.current;
            },
            get isValid() {
                if (validationSubScopeRef.current) return validationSubScopeRef.current.isValid;
                return true;
            }
        }),
        []
    );

    const hasSchedule = 'schedule' in props;
    const [locationOverride, setLocationOverride] = useState<{ value: LocationOption[] | undefined }>();
    const [timeZoneOverride, setTimeZoneOverride] = useState<{ value: string | undefined } | undefined>(() =>
        hasSchedule ? undefined : { value: dateTimeService.getCurrentTimeZone() }
    );
    const [timetableEntriesOverride, setTimetableEntriesOverride] = useState<TimetableEntry[] | undefined>();

    const host = hasSchedule ? props.schedule.user : props.defaultHost;
    const uniqueId = hasSchedule ? props.schedule.id : props.defaultHost.userId;
    const locationValue = locationOverride ? locationOverride.value : hasSchedule ? props.schedule.locationOptions : undefined;
    const timeZoneValue = timeZoneOverride ? timeZoneOverride.value : hasSchedule ? props.schedule.timeZone : undefined;
    const timetableEntriesValue = timetableEntriesOverride ? timetableEntriesOverride : hasSchedule ? props.schedule.timetable.entries : undefined;

    async function performSaveOperation(action: () => Promise<unknown> | unknown) {
        const saveResult = action();
        if (saveResult && saveResult instanceof Promise) {
            setSaveInProgress(true);
            try {
                await saveResult;
            } finally {
                setSaveInProgress(false);
            }
        }
    }

    async function updateSchedule(data: Partial<ScheduleData>) {
        await performSaveOperation(() => props.onUpdate?.(data));
    }

    async function tryCreateSchedule(data: Partial<Omit<ResearchScheduleData, 'userId'>>): Promise<void> {
        if (validationSubScopeRef.current && !validationSubScopeRef.current.isValid) return;

        const partialCreateData: Partial<ResearchScheduleData> = {
            userId: host.userId,
            locationOptions: locationOverride?.value,
            timeZone: timeZoneOverride?.value,
            timetable: timetableEntriesOverride ? { entries: timetableEntriesOverride, specialEntries: [] } : undefined
        };
        const createData = { ...partialCreateData, ...data } as ResearchScheduleData;
        await performSaveOperation(() => props.onCreate?.(createData));

        setLocationOverride(undefined);
        setTimeZoneOverride(undefined);
        setTimetableEntriesOverride(undefined);
    }

    const slotLength = hasSchedule
        ? props.schedule.durationMinutes + (props.schedule.bufferAfterMinutes ?? 0) + (props.schedule.bufferBeforeMinutes ?? 0)
        : defaultSlotLength;
    const disabled = props.disabled || saveInProgress;

    return (
        <SchedulePanel ref={wrapperElementRef} className={combineClassNames('k-px-4 k-pt-2 k-pb-4', `gg-${uniqueId}`)}>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-mb-2 k-gap-4 k-justify-content-between">
                <UserSimpleView user={buildUserViewModel(host)} gap={2} isCurrent={currentUserId === host.userId} />
                <Button fillMode="flat" onClick={props.onDelete} disabled={disabled}>
                    <SvgIconButtonContent icon={DeleteIcon}>Delete schedule</SvgIconButtonContent>
                </Button>
            </StackLayout>
            <ValidationSubScope ref={validationSubScopeRef}>
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4 k-ml-12 k-icp-component-border">
                    <ResearchScheduleEditorSection title="How / where will the interview be conducted?">
                        <ValidationUnit name={`${uniqueId}_location`} value={locationValue} validator={locationValidator}>
                            {(locationErrorMessage, locationOnChange) => (
                                <>
                                    <EventLocationsConfigurator
                                        onChange={async e => {
                                            setLocationOverride({ value: e.value });
                                            const isLocationValid = locationOnChange(e.value, !hasSchedule);
                                            if (!isLocationValid) return;

                                            if (hasSchedule) {
                                                await updateSchedule({ locationOptions: e.value });
                                                setLocationOverride(undefined);
                                            } else await tryCreateSchedule({ locationOptions: e.value });
                                        }}
                                        disabled={disabled}
                                        valid={!locationErrorMessage}
                                        value={locationValue}
                                        changeInPersonLocationOnBlur={true}
                                    />
                                    {locationErrorMessage && <ErrorComponent>{locationErrorMessage}</ErrorComponent>}
                                </>
                            )}
                        </ValidationUnit>
                    </ResearchScheduleEditorSection>
                    <ResearchScheduleEditorSection title="Define meetings days and start time">
                        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4 k-justify-content-between k-mb-2">
                            <span className="k-fs-sm k-icp-subtle-text">
                                Duration of the interview slots is {dateTimeService.stringifyMinutesToHours(slotLength)}
                            </span>
                            <ValidationUnit name={`${uniqueId}_timeZone`} value={timeZoneValue} validator={timeZoneValidator}>
                                {(timeZoneErrorMessage, timeZoneOnChange) => (
                                    <div style={{ width: 260 }}>
                                        <TimeZonePicker
                                            onChange={async e => {
                                                setTimeZoneOverride({ value: e.value });
                                                const isTimeZoneValid = timeZoneOnChange(e.value);
                                                if (!isTimeZoneValid) return;

                                                if (hasSchedule) {
                                                    await updateSchedule({ timeZone: e.value });
                                                    setTimeZoneOverride(undefined);
                                                } else await tryCreateSchedule({ timeZone: e.value });
                                            }}
                                            disabled={disabled}
                                            valid={!timeZoneErrorMessage}
                                            value={timeZoneValue}
                                            placeholder="Select timezone..."
                                            size="small"
                                            className="k-picker-solid"
                                        />
                                        {timeZoneErrorMessage && <ErrorComponent>{timeZoneErrorMessage}</ErrorComponent>}
                                    </div>
                                )}
                            </ValidationUnit>
                        </StackLayout>
                        <ValidationUnit name={`${uniqueId}_timetableEntries`} value={timetableEntriesValue} validator={timetableEntriesValidator}>
                            {(timeTableEntriesErrorMessage, timeTableEntriesOnChange) => (
                                <>
                                    <WeeklySlotsEditor
                                        onChange={async e => {
                                            setTimetableEntriesOverride(e.value);
                                            const areTimetableEntriesValid = timeTableEntriesOnChange(e.value, !hasSchedule);
                                            if (!areTimetableEntriesValid) return;

                                            if (hasSchedule) {
                                                await updateSchedule({
                                                    timetable: {
                                                        ...props.schedule.timetable,
                                                        entries: e.value
                                                    }
                                                });
                                                setTimetableEntriesOverride(undefined);
                                            } else
                                                await tryCreateSchedule({
                                                    timetable: {
                                                        entries: e.value,
                                                        specialEntries: []
                                                    }
                                                });
                                        }}
                                        slotLength={slotLength}
                                        disabled={disabled}
                                        value={timetableEntriesValue}
                                    />
                                    {timeTableEntriesErrorMessage && <ErrorComponent>{timeTableEntriesErrorMessage}</ErrorComponent>}
                                </>
                            )}
                        </ValidationUnit>
                    </ResearchScheduleEditorSection>
                </StackLayout>
            </ValidationSubScope>
        </SchedulePanel>
    );
});

function ResearchScheduleEditorSection({ title, children }: { title: string; children?: ReactNode }) {
    return (
        <>
            <div className="k-separator" />
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                <div style={{ width: 200 }}>{title}</div>
                <div className="k-flex-1">{children}</div>
            </StackLayout>
        </>
    );
}
