import { ZIndexContext } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AssumptionsIcon } from '../../icons/assumption.svg';
import { ReactComponent as GlossaryIcon } from '../../icons/book-open.svg';
import { ReactComponent as CanvasIcon } from '../../icons/canvas.svg';
import { ReactComponent as ContactsIcon } from '../../icons/contacts.svg';
import { ReactComponent as JourneyIcon } from '../../icons/map.svg';
import { ReactComponent as CalendarIcon } from '../../icons/schedule.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as TestsIcon } from '../../icons/test.svg';
import { JourneyTaskNavigationHighlight } from '../../services/journeyService';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { InfoTip } from '../idea/infoTip';

export default function Sidebar() {
    const currentRole = useAppSelector(s => s.idea.role);
    const journeyLinkElementRef = useRef<HTMLAnchorElement>(null);

    return (
        <ZIndexContext.Provider value={150}>
            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'center', vertical: 'top' }}
                className="side-nav k-justify-content-between k-gap-4 k-pt-6 k-pb-4 k-px-3"
            >
                <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-4">
                    <Tooltip anchorElement="target" position="right" openDelay={0}>
                        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
                            <NavLink
                                ref={journeyLinkElementRef}
                                to="./journey"
                                title="Journey"
                                className="k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button k-icp-tooltip-trigger"
                            >
                                <JourneyIcon className="k-icp-icon" />
                            </NavLink>
                            <InfoTip name={JourneyTaskNavigationHighlight.TaskUnlockedNotice} anchor={journeyLinkElementRef.current}>
                                You have unlocked a new task
                            </InfoTip>
                            <NavLink
                                to="./canvas"
                                title="Canvas"
                                className="k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button k-icp-tooltip-trigger"
                            >
                                <CanvasIcon className="k-icp-icon" />
                            </NavLink>
                            <NavLink
                                to="./hypotheses"
                                title="Hypotheses"
                                className="k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button k-icp-tooltip-trigger"
                            >
                                <AssumptionsIcon className="k-icp-icon" />
                            </NavLink>
                            <NavLink
                                to="./research"
                                title="Research"
                                className="k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button k-icp-tooltip-trigger"
                            >
                                <TestsIcon className="k-icp-icon" />
                            </NavLink>
                            <NavLink
                                to="./calendar"
                                title="Calendar"
                                className="k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button k-icp-tooltip-trigger"
                            >
                                <CalendarIcon className="k-icp-icon" />
                            </NavLink>
                            <NavLink
                                to="./contacts"
                                title="Contacts"
                                className="k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button k-icp-tooltip-trigger"
                            >
                                <ContactsIcon className="k-icp-icon" />
                            </NavLink>
                        </StackLayout>
                    </Tooltip>

                    <div className="full-horizontal-separator"></div>

                    <Tooltip anchorElement="target" position="right" openDelay={0}>
                        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
                            <NavLink
                                to="./glossary"
                                title="Glossary"
                                className="k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button k-icp-tooltip-trigger"
                            >
                                <GlossaryIcon className="k-icp-icon" />
                            </NavLink>
                            {currentRole && currentRole !== UserRole.Viewer && (
                                <NavLink
                                    to="./settings"
                                    title="Settings"
                                    className="k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button k-icp-tooltip-trigger"
                                >
                                    <SettingsIcon className="k-icp-icon" />
                                </NavLink>
                            )}
                        </StackLayout>
                    </Tooltip>
                </StackLayout>
            </StackLayout>
        </ZIndexContext.Provider>
    );
}
