import { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useSearchParams } from 'react-router-dom';
import { BoxType } from '../../services/canvasService';

export function CanvasDependenciesFromQueryStringVisualization({ canvasElementRef }: { canvasElementRef: React.RefObject<HTMLElement> }) {
    const [queryParams] = useSearchParams();
    const [targetBox, setTargetBox] = useState<BoxType>();
    const [sourceBoxes, setSourceBoxes] = useState<BoxType[]>();

    useEffect(() => {
        const targetBoxString = queryParams.get('canvas-target-box');
        if (targetBoxString) setTargetBox(BoxType[targetBoxString as keyof typeof BoxType]);

        const sourceBoxesStrings = queryParams.getAll('canvas-source-box');
        const sourceBoxes = sourceBoxesStrings.map(s => BoxType[s as keyof typeof BoxType]).filter(s => s);
        if (sourceBoxes.length) setSourceBoxes(sourceBoxes);
    }, [queryParams]);

    if (!targetBox || !sourceBoxes?.length) return null;

    return <CanvasDependenciesVisualization canvasElementRef={canvasElementRef} dependentBox={targetBox} dependencyBoxes={sourceBoxes} />;
}

const closeDelay = 2000;
const boxWithOverlayClassName = 'canvas-box-overlaid';
const boxWithCollapsedOverlayClassName = 'canvas-box-overlaid-collapsed';
const boxWithHiddenOverlayClassName = 'canvas-box-overlaid-hidden';
export function CanvasDependenciesVisualization({
    canvasElementRef,
    dependentBox,
    dependencyBoxes
}: {
    canvasElementRef: React.RefObject<HTMLElement>;
    dependentBox: BoxType;
    dependencyBoxes: BoxType[];
}) {
    const [dependencyPathsDefinitions, setDependencyPathsDefinitions] = useState<string[]>();
    const arrowEndAnimationElementRef = useRef<SVGAnimateElement>(null);
    const svgElementRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (!canvasElementRef.current) return;

        const dependentBoxElement = getBoxElement(canvasElementRef.current, dependentBox);
        if (!dependentBoxElement) return;

        const dependencyBoxesElements = dependencyBoxes.map(b => getBoxElement(canvasElementRef.current!, b)).filter(b => !!b) as HTMLElement[];
        if (!dependencyBoxesElements.length) return;

        const abortController = new AbortController();

        const generateDependencyPathsDefinitions = (): Promise<void> => {
            const dependentBoxCenter = getElementCenter(dependentBoxElement);
            const dependencyBoxesCenters = dependencyBoxesElements.map(getElementCenter);
            const preferredPathEndSide = getDominantVerticalSide(dependentBoxCenter, dependencyBoxesCenters);
            const pathsDefinitions = dependencyBoxesCenters.map(c => generatePathDefinition(c, dependentBoxCenter, preferredPathEndSide));
            flushSync(() => {
                setDependencyPathsDefinitions(pathsDefinitions);
            });

            if (!arrowEndAnimationElementRef.current) return Promise.resolve();

            return new Promise((resolve, reject) => {
                arrowEndAnimationElementRef.current!.addEventListener(
                    'endEvent',
                    () => {
                        resolve();
                    },
                    { once: true, signal: abortController.signal }
                );

                abortController.signal.addEventListener('abort', reject);
            });
        };

        const animateBoxOverlays = (): Promise<unknown> => {
            const allCanvasBoxes = canvasElementRef.current!.querySelectorAll('.canvas-box');
            allCanvasBoxes.forEach(b => {
                if (b === dependentBoxElement) return;

                b.classList.add(boxWithOverlayClassName);
            });

            return new Promise((resolveAll, rejectAll) => {
                window.requestAnimationFrame(() => {
                    const collapseAnimationPromises = dependencyBoxesElements.map(e =>
                        e.classList.contains(boxWithCollapsedOverlayClassName)
                            ? Promise.resolve()
                            : new Promise<void>(r => {
                                  e.addEventListener('transitionend', () => r(), { once: true, signal: abortController.signal });
                                  e.classList.add(boxWithCollapsedOverlayClassName);
                              })
                    );

                    Promise.all(collapseAnimationPromises).then(resolveAll);
                });

                abortController.signal.addEventListener('abort', rejectAll);
            });
        };

        animateBoxOverlays()
            .then(generateDependencyPathsDefinitions)
            .then(
                () =>
                    new Promise<void>((resolve, reject) => {
                        setTimeout(resolve, closeDelay);
                        abortController.signal.addEventListener('abort', reject);
                    })
            )
            .then(() => {
                if (canvasElementRef.current) {
                    canvasElementRef.current.querySelectorAll(`.${boxWithOverlayClassName}`).forEach(e => {
                        e.addEventListener(
                            'transitionend',
                            () => {
                                e.classList.remove(boxWithOverlayClassName);
                                e.classList.remove(boxWithCollapsedOverlayClassName);
                                e.classList.remove(boxWithHiddenOverlayClassName);
                            },
                            { signal: abortController.signal, once: true }
                        );
                        e.classList.add(boxWithHiddenOverlayClassName);
                    });
                }

                if (svgElementRef.current) {
                    svgElementRef.current.addEventListener('transitionend', () => setDependencyPathsDefinitions(undefined), {
                        once: true,
                        signal: abortController.signal
                    });
                    svgElementRef.current.classList.add('canvas-dependencies-visualization-hidden');
                }
            })
            .catch((e: any) => {
                // Handle abort events from abort controller
                if (!(e instanceof Event && e.type === 'abort')) return Promise.reject(e);
            });

        return () => abortController.abort();
    }, [canvasElementRef, dependencyBoxes, dependentBox]);

    if (!dependencyPathsDefinitions?.length) return null;

    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="canvas-dependencies-visualization" ref={svgElementRef}>
            <defs>
                <marker id="circle" refX="2.5" refY="2.5" markerUnits="strokeWidth" markerWidth="5" markerHeight="5" orient="auto">
                    <circle r="2.5" cx="2.5" cy="2.5" fill="#1D1D1B">
                        <animate id="markerStartAnimation" attributeName="r" values="0;2.5" dur="300ms" begin="0" />
                    </circle>
                </marker>
                <marker id="arrow-head" refX="11" refY="8" markerUnits="strokeWidth" markerWidth="12" markerHeight="16" orient="auto">
                    <path d="M0 0l12 8 l-12 8 Z" fill="#E0CC00" opacity="0">
                        <animate attributeName="opacity" values="0;1" begin="arrowPathAnimation.end" dur="100ms" fill="freeze" />
                    </path>
                    <animate
                        ref={arrowEndAnimationElementRef}
                        attributeName="refX"
                        values="40;0;15;5;11"
                        dur="800ms"
                        begin="arrowPathAnimation.end"
                        calcMode="spline"
                        keySplines="0 0 .59 1; 0 0 .59 1; 0 0 .59 1; 0 0 .59 1"
                    />
                </marker>
            </defs>

            {dependencyPathsDefinitions.map((d, i) => (
                <path
                    key={i}
                    strokeWidth="1"
                    stroke="#1D1D1B"
                    d={d}
                    markerStart="url(#circle)"
                    markerEnd="url(#arrow-head)"
                    ref={r => r && updatePathDashForAnimation(r)}
                >
                    <animate
                        id={i === 0 ? 'arrowPathAnimation' : undefined}
                        attributeName="stroke-dashoffset"
                        dur="1.2s"
                        begin="markerStartAnimation.end"
                        fill="freeze"
                    />
                </path>
            ))}
        </svg>
    );
}

type Point = { x: number; y: number };
enum VerticalSide {
    Top,
    Bottom
}

enum HorizontalSide {
    Left,
    Right
}

enum Direction {
    Horizontal,
    Vertical
}

function getBoxElement(canvasElement: HTMLElement, box: BoxType): HTMLElement | null {
    return canvasElement.querySelector<HTMLElement>(`.canvas-box-${box.toLowerCase()}`);
}

function getElementCenter(element: HTMLElement): Point {
    return { x: element.offsetLeft + element.offsetWidth / 2, y: element.offsetTop + element.offsetHeight / 2 };
}

function getDominantVerticalSide(mainPoint: Point, otherPoints: Point[]): VerticalSide {
    let pointsOnTop = 0,
        pointsOnBottom = 0;
    otherPoints.forEach(p => {
        if (p.y < mainPoint.y) ++pointsOnTop;
        else if (p.y > mainPoint.y) ++pointsOnBottom;
    });

    return pointsOnTop > pointsOnBottom ? VerticalSide.Top : VerticalSide.Bottom;
}

function updatePathDashForAnimation(pathElement: SVGPathElement): void {
    const pathLength = pathElement.getTotalLength();
    const pathLengthString = pathLength.toString();
    pathElement.setAttribute('stroke-dasharray', pathLengthString);
    pathElement.setAttribute('stroke-dashoffset', pathLengthString);

    if (pathElement.children.length !== 1 || !(pathElement.children[0] instanceof SVGAnimateElement))
        throw new Error('The only child of the path should be animate element');
    const animateElement = pathElement.children[0];
    animateElement.setAttribute('values', `${pathLength};0`);
}

const turnRadius = 40;
const equalXOffset = 20;
function generatePathDefinition(from: Point, to: Point, preferredPathEndSide: VerticalSide): string {
    const currentPoint = { ...from };
    let definition = `M${currentPoint.x} ${currentPoint.y}`;
    if (currentPoint.y === to.y) {
        definition += applyPathDefinition(
            currentPoint,
            generateVerticalLineDefinition(preferredPathEndSide === VerticalSide.Bottom ? equalXOffset : -equalXOffset)
        );
        definition += applyPathDefinition(
            currentPoint,
            generateArcDefinition(turnRadius, preferredPathEndSide, getHorizontalSide(currentPoint.x, to.x), Direction.Horizontal)
        );
    }

    if (currentPoint.x !== to.x) {
        definition += applyPathDefinition(currentPoint, generateHorizontalLineDefinition(to.x - currentPoint.x, turnRadius));
    }

    if (currentPoint.x !== to.x && currentPoint.y !== to.y) {
        definition += applyPathDefinition(
            currentPoint,
            generateArcDefinition(turnRadius, getVerticalSide(currentPoint.y, to.y), getHorizontalSide(currentPoint.x, to.x), Direction.Vertical)
        );
    }

    if (currentPoint.y !== to.y) {
        definition += applyPathDefinition(currentPoint, generateVerticalLineDefinition(to.y - currentPoint.y));
    }

    return definition;
}

type PathDefinitionResult = { dx: number; dy: number; definition: string };
function applyPathDefinition(point: Point, definition: PathDefinitionResult): string {
    point.x += definition.dx;
    point.y += definition.dy;

    return definition.definition;
}

function generateArcDefinition(radius: number, verticalSide: VerticalSide, horizontalSide: HorizontalSide, endDirection: Direction): PathDefinitionResult {
    const xChangeDirection = horizontalSide === HorizontalSide.Left ? -1 : 1;
    const yChangeDirection = verticalSide === VerticalSide.Top ? -1 : 1;
    const dx = radius * xChangeDirection;
    const dy = radius * yChangeDirection;
    const sweep = xChangeDirection * yChangeDirection * (endDirection === Direction.Horizontal ? -1 : 1);
    const definition = `a${radius} ${radius} 0 0 ${sweep === -1 ? 0 : sweep} ${dx} ${dy}`;

    return { dx, dy, definition };
}

function generateVerticalLineDefinition(length: number): PathDefinitionResult {
    return { dx: 0, dy: length, definition: `v${length}` };
}

function generateHorizontalLineDefinition(length: number, lengthAdjustment: number): PathDefinitionResult {
    const adjustedLength = length - (length < 0 ? -lengthAdjustment : lengthAdjustment);

    return { dx: adjustedLength, dy: 0, definition: `h${adjustedLength}` };
}

function getHorizontalSide(sourceX: number, targetX: number): HorizontalSide {
    return targetX > sourceX ? HorizontalSide.Right : HorizontalSide.Left;
}

function getVerticalSide(sourceY: number, targetY: number): VerticalSide {
    return targetY < sourceY ? VerticalSide.Top : VerticalSide.Bottom;
}
