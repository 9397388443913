import { Button } from '@progress/kendo-react-buttons';
import { Input, InputHandle, TextArea, TextAreaHandle } from '@progress/kendo-react-inputs';
import { Error as ErrorComponent, Hint } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useRef, useState } from 'react';
import { JourneyPhaseForkedTimelineDrawer, NodeLocation, TimelineNode, TimelineNodeType } from '../../scripts/journeyPhaseForkedTimelineDrawer';
import { debounce } from '../../services/common';

const defaultNodes: TimelineNode[] = [
    {
        type: TimelineNodeType.Start,
        isVisitable: false
    },
    {
        type: TimelineNodeType.Default,
        isVisitable: true
    },
    {
        type: TimelineNodeType.Default,
        isVisitable: true
    },
    {
        groupKey: 1,
        variationsTypes: [TimelineNodeType.Default, TimelineNodeType.Default, TimelineNodeType.Default]
    },
    {
        groupKey: 1,
        variationsTypes: [TimelineNodeType.Default, TimelineNodeType.Default, TimelineNodeType.Disabled]
    },
    {
        groupKey: 1,
        variationsTypes: [TimelineNodeType.Default, TimelineNodeType.Default, TimelineNodeType.Disabled]
    },
    {
        groupKey: 2,
        variationsTypes: [TimelineNodeType.Disabled, TimelineNodeType.Default, TimelineNodeType.Disabled, TimelineNodeType.Default, TimelineNodeType.Disabled]
    },
    {
        type: TimelineNodeType.Default,
        isVisitable: false
    },
    {
        groupKey: 3,
        variationsTypes: [TimelineNodeType.Default, TimelineNodeType.Default]
    },
    {
        groupKey: 3,
        variationsTypes: [TimelineNodeType.Disabled, TimelineNodeType.Default]
    },
    {
        type: TimelineNodeType.Default,
        isVisitable: true
    },
    {
        type: TimelineNodeType.Default,
        isVisitable: true
    },
    {
        type: TimelineNodeType.Default,
        isVisitable: false
    }
];

export function TestJourneyPage() {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [nodes, setNodes] = useState(defaultNodes);
    const [currentNodeLocation, setCurrentNodeLocation] = useState<NodeLocation | undefined>({ nodeIndex: 8, variationIndex: 0 });
    const [hiddenZones, setHiddenZones] = useState<number[] | undefined>([11]);
    const [error, setError] = useState<string>();
    const currentLocationInputRef = useRef<InputHandle>(null);
    const hiddenZonesInputRef = useRef<InputHandle>(null);
    const nodesInputRef = useRef<TextAreaHandle>(null);

    useEffect(() => {
        if (!canvasRef.current) return;
        setError(undefined);

        const timelineDrawer = new JourneyPhaseForkedTimelineDrawer(canvasRef.current, nodes, currentNodeLocation, hiddenZones);
        function drawTimeline() {
            try {
                timelineDrawer.draw();
            } catch (e) {
                setError(e instanceof Error ? e.stack : 'Unknown error');
            }
        }
        drawTimeline();

        const drawTimelineDebounced = debounce(drawTimeline, 100);
        window.addEventListener('resize', drawTimelineDebounced);

        return () => {
            window.removeEventListener('resize', drawTimelineDebounced);
        };
    }, [currentNodeLocation, hiddenZones, nodes]);

    return (
        <div className="page-content-middle journey-phase">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6">
                <canvas ref={canvasRef} />
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                    <div>
                        <Input ref={currentLocationInputRef} defaultValue={currentNodeLocation === undefined ? '' : JSON.stringify(currentNodeLocation)} />
                        <Hint>Current location: empty, number or {'{"nodeIndex":<number>,"variationIndex":<number>}'}</Hint>
                    </div>
                    <div>
                        <Input ref={hiddenZonesInputRef} defaultValue={hiddenZones === undefined ? '' : JSON.stringify(hiddenZones)} />
                        <Hint>Fog(s): empty or array of numbers</Hint>
                    </div>
                    <div>
                        <TextArea ref={nodesInputRef} defaultValue={JSON.stringify(nodes, undefined, 4)} rows={20} />
                        <Hint>Types: Default, Start or Disabled. Set isVisitable: false for milestones</Hint>
                    </div>
                    <div className="k-mb-6">
                        <Button
                            type="button"
                            size="large"
                            themeColor="primary"
                            className="-block-center !k-d-flex"
                            onClick={e => {
                                e.preventDefault();
                                try {
                                    setCurrentNodeLocation(
                                        currentLocationInputRef.current?.element?.value ? JSON.parse(currentLocationInputRef.current.element.value) : undefined
                                    );
                                    setHiddenZones(
                                        hiddenZonesInputRef.current?.element?.value ? JSON.parse(hiddenZonesInputRef.current.element.value) : undefined
                                    );
                                    if (nodesInputRef.current?.value) setNodes(JSON.parse(nodesInputRef.current?.value));
                                } catch (e) {
                                    setError(e instanceof Error ? e.stack : 'Unknown error');
                                }
                            }}
                        >
                            Render
                        </Button>
                        {error && <ErrorComponent>{error}</ErrorComponent>}
                    </div>
                </StackLayout>
            </StackLayout>
        </div>
    );
}
