import { appConfig } from '../config';
import { ErrorWithOperationDisplayName } from './common';
import { HttpServiceBase, JsonString, RequestMethod } from './httpServiceBase';
import { JourneyTaskNavigationHighlight } from './journeyService';

export enum BoxType {
    Problem = 'Problem',
    Solution = 'Solution',
    KeyMetrics = 'KeyMetrics',
    UniqueValueProposition = 'UniqueValueProposition',
    UnfairAdvantage = 'UnfairAdvantage',
    Channel = 'Channel',
    CustomerSegments = 'CustomerSegments',
    CostStructure = 'CostStructure',
    RevenueStreams = 'RevenueStreams',
    AlternativeSolutions = 'AlternativeSolutions',
    JobsToBeDone = 'JobsToBeDone'
}

export interface BoxItem {
    id: number;
    content: string;
    colorCode?: string;
    relatedItemIds?: number[];
}

export interface CanvasBox {
    type: BoxType;
    items: BoxItem[];
}

export interface Canvas {
    boxes: CanvasBox[];
}

export interface BoxMetadata {
    title: string;
    itemName: string;
    itemShorthand: string;
    index: number;
    highlight?: JourneyTaskNavigationHighlight;
    allowColoring?: boolean;
    hide?: boolean;
}

export type ItemStats = {
    itemId: number;
    contactCount: number;
    hypothesisCount: number;
    insightCount: number;
    researchCount: number;
};

class CanvasService extends HttpServiceBase {
    constructor() {
        super('/api/canvases');
    }

    @ErrorWithOperationDisplayName('Get canvas')
    getCanvas(ideaId: string): Promise<Canvas> {
        return this.performRequest<Canvas>({
            path: `/${ideaId}`
        }).then(c => {
            c.boxes.sort((b1, b2) => appConfig.canvas.boxes[b1.type].index - appConfig.canvas.boxes[b2.type].index);
            return c;
        });
    }

    @ErrorWithOperationDisplayName('Get item')
    getItem(ideaId: string, boxType: BoxType, itemId: number): Promise<BoxItem> {
        return this.performRequest<BoxItem>({
            path: `/${ideaId}/boxes/${boxType}/items/${itemId}`
        });
    }

    @ErrorWithOperationDisplayName('Update item')
    updateItem(ideaId: string, boxType: BoxType, itemId: number, content: string, relatedItemIds?: number[], colorCode?: string): Promise<BoxItem> {
        return this.performRequest<BoxItem>({
            path: `/${ideaId}/boxes/${boxType}/items/${itemId}`,
            method: RequestMethod.PUT,
            body: { content: content, colorCode, relatedItemIds }
        });
    }

    @ErrorWithOperationDisplayName('Create item')
    createItem(ideaId: string, boxType: BoxType, content: string, relatedItemIds?: number[], colorCode?: string): Promise<BoxItem> {
        return this.performRequest<BoxItem>({
            path: `/${ideaId}/boxes/${boxType}/items`,
            method: RequestMethod.POST,
            body: { content, colorCode, relatedItemIds }
        });
    }

    @ErrorWithOperationDisplayName('Delete item')
    deleteItem(ideaId: string, boxType: BoxType, itemId: number): Promise<any> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/boxes/${boxType}/items/${itemId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore item')
    restoreItem(ideaId: string, boxType: BoxType, itemId: number): Promise<BoxItem> {
        return this.performRequest({
            path: `/${ideaId}/boxes/${boxType}/items/${itemId}/restore`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Reorder item')
    reorderItem(ideaId: string, boxType: BoxType, itemId: number, afterItemId?: number) {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/boxes/${boxType}/items/${itemId}/reorder`,
            method: RequestMethod.POST,
            body: {
                afterId: afterItemId
            }
        });
    }

    @ErrorWithOperationDisplayName('Get brainstorming notes')
    async getBoxNotes(ideaId: string, boxType: BoxType): Promise<string> {
        const boxNotesResponse = await this.performRequest<JsonString>({
            path: `/${ideaId}/boxes/${boxType}/notes`
        });

        return boxNotesResponse.text;
    }

    @ErrorWithOperationDisplayName('Update brainstorming notes')
    async updateBoxNotes(ideaId: string, boxType: BoxType, notes: string): Promise<string> {
        const boxNotesResponse = await this.performRequest<JsonString>({
            path: `/${ideaId}/boxes/${boxType}/notes`,
            method: RequestMethod.PUT,
            body: { text: notes }
        });

        return boxNotesResponse.text;
    }

    getBoxMetadata(boxType: BoxType) {
        const boxMetadata: BoxMetadata = appConfig.canvas.boxes[boxType];

        return boxMetadata;
    }

    @ErrorWithOperationDisplayName('Get box items')
    getBoxItems(ideaId: string, boxType: BoxType) {
        return this.performRequest<BoxItem[]>({
            path: `/${ideaId}/boxes/${boxType}/items`
        });
    }

    @ErrorWithOperationDisplayName('Get items stats')
    getItemsStats(ideaId: string, boxType?: BoxType) {
        return this.performRequest<ItemStats[]>({
            path: `/${ideaId}${boxType ? `/boxes/${boxType}` : ''}/itemStats`
        });
    }

    @ErrorWithOperationDisplayName('Get item stats')
    getItemStats(ideaId: string, boxType: BoxType, itemId: number) {
        return this.performRequest<ItemStats>({
            path: `/${ideaId}/boxes/${boxType}/items/${itemId}/itemStats`
        });
    }

    getLeastUsedColor(items: BoxItem[]) {
        if (!items.length) return appConfig.canvas.orderedPaletteColors[0];
        const colorStats: Record<string, number> = {};
        items.forEach(i => {
            if (!i.colorCode) return;
            if (colorStats[i.colorCode]) ++colorStats[i.colorCode];
            else colorStats[i.colorCode] = 1;
        });

        let minOccurrenceColorIndex = 0;
        let minOccurrence = items.length + 1;
        appConfig.canvas.orderedPaletteColors.forEach((color, index) => {
            const currentColorOccurrences = colorStats[color] ?? 0;
            if (currentColorOccurrences < minOccurrence) {
                minOccurrence = currentColorOccurrences;
                minOccurrenceColorIndex = index;
            }
        });

        return appConfig.canvas.orderedPaletteColors[minOccurrenceColorIndex];
    }
}

export const canvasService = new CanvasService();
