import { useMemo } from 'react';
import {
    ForkedInsightGroup,
    ForkedInsightQuestion,
    ForkingInsightGroup,
    ForkingInsightSection,
    Insight,
    InsightCatalog,
    InsightCatalogTemplate,
    InsightContext,
    InsightContextProperty,
    InsightCoverageEntry,
    InsightForkedItem,
    InsightForkingParent,
    InsightGroup,
    InsightParameterValue,
    InsightPropertyType,
    InsightQuestion,
    InsightSection,
    InsightType
} from '../../../services/insightsService';

export type ExpandedInsightsCatalog = {
    sections: (ExpandedInsightsCatalogSection | ExpandedInsightsCatalogForkedSection)[];
};

export type ExpandedInsightsCatalogSection = {
    definition: InsightSection;
    groups: (ExpandedInsightsGroup | ExpandedInsightsForkedGroup)[];
};

export type ExpandedInsightsForkingData = {
    propertyName: string;
    value: unknown;
};

export type ExpandedForkedItem = { forkingData: ExpandedInsightsForkingData };

export type ExpandedInsightsCatalogForkedSection = ExpandedForkedItem & {
    definition: ForkingInsightSection;
    groups: (ExpandedInsightsGroup | ExpandedInsightsForkedGroup)[];
};

export type ExpandedInsightsGroup = {
    definition: InsightGroup;
    questions: InsightQuestion[];
};

export type ExpandedInsightsForkedGroup = ExpandedForkedItem & {
    definition: ForkingInsightGroup;
    questions: InsightQuestion[];
};

export function isForkingSection(section: InsightSection | ForkingInsightSection): section is ForkingInsightSection {
    return 'contextProperties' in section;
}

export function isForkingGroup(group: InsightGroup | ForkedInsightGroup | ForkingInsightGroup): group is ForkingInsightGroup {
    return 'contextProperties' in group;
}

export function isForkedGroup(group: InsightGroup | ForkedInsightGroup | ForkingInsightGroup): group is ForkedInsightGroup {
    return 'condition' in group;
}

export function isForkedQuestion(question: InsightQuestion | ForkedInsightQuestion): question is ForkedInsightQuestion {
    return 'condition' in question;
}

function getForkingParentValuesFromContext(parent: InsightForkingParent, context: Record<string, unknown>): ExpandedInsightsForkingData[] {
    const values: ExpandedInsightsForkingData[] = [];
    for (const forkingPropertyName of parent.contextProperties) {
        const forkValue = context[forkingPropertyName];
        if (!forkValue) continue;

        if (forkValue instanceof Array)
            values.push(
                ...forkValue.map<ExpandedInsightsForkingData>(value => ({ propertyName: forkingPropertyName, value }))
            );
        else values.push({ propertyName: forkingPropertyName, value: forkValue });
    }

    return values;
}

export function expandCatalog(catalog: InsightCatalog, context: Record<string, unknown>): ExpandedInsightsCatalog {
    const expandedCatalog: ExpandedInsightsCatalog = { sections: [] };

    for (const section of catalog.sections) {
        const isForking = isForkingSection(section);
        if (isForking) {
            const forkingSectionValues = getForkingParentValuesFromContext(section, context);
            if (forkingSectionValues.length)
                expandedCatalog.sections.push(...forkingSectionValues.map(forkValue => expandSection(section, context, forkValue)));
            else if (section.contextProperties.length) {
                const impliedForkingData: ExpandedInsightsForkingData = { propertyName: section.contextProperties[0], value: undefined };
                expandedCatalog.sections.push(expandSection(section, context, impliedForkingData));
            }
        } else expandedCatalog.sections.push(expandSection(section, context));
    }

    return expandedCatalog;
}

function expandSection(
    section: InsightSection | ForkingInsightSection,
    context: Record<string, unknown>,
    forkingData?: ExpandedInsightsForkingData
): ExpandedInsightsCatalogSection | ExpandedInsightsCatalogForkedSection {
    const expandedSection: ExpandedInsightsCatalogSection | ExpandedInsightsCatalogForkedSection = forkingData
        ? { definition: section, groups: [], forkingData }
        : { definition: section, groups: [] };
    for (const group of section.groups) {
        if (isForkingGroup(group)) {
            const forkingGroupValues = getForkingParentValuesFromContext(group, context);
            if (forkingGroupValues.length) expandedSection.groups.push(...forkingGroupValues.map(forkValue => expandGroup(group, forkValue)));
            else if (group.contextProperties.length)
                expandedSection.groups.push(expandGroup(group, { propertyName: group.contextProperties[0], value: undefined }));
        } else if (isForkedGroup(group)) {
            if (!shouldShowForkedItem(group, forkingData)) continue;

            expandedSection.groups.push(expandGroup(group));
        } else expandedSection.groups.push(expandGroup(group));
    }

    return expandedSection;
}

function expandGroup(
    group: InsightGroup | ForkedInsightGroup | ForkingInsightGroup,
    forkingData?: ExpandedInsightsForkingData
): ExpandedInsightsGroup | ExpandedInsightsForkedGroup {
    const expandedGroup: ExpandedInsightsGroup | ExpandedInsightsForkedGroup = forkingData
        ? { definition: group, questions: [], forkingData }
        : { definition: group, questions: [] };

    for (const question of group.questions) {
        if (isForkedQuestion(question) && !shouldShowForkedItem(question, forkingData)) continue;

        expandedGroup.questions.push({ ...question });
    }

    return expandedGroup;
}

function shouldShowForkedItem(item: InsightForkedItem, forkingData?: ExpandedInsightsForkingData) {
    if (!forkingData) return false;

    return item.condition.contextProperty === forkingData.propertyName;
}

export type InsightsByOrigin = {
    [sectionTag: string]:
        | {
              [questionTag: string]: Insight[] | undefined;
          }
        | undefined;
};

export function groupInsightsByOrigin(insights: Insight[]): InsightsByOrigin {
    const groupedInsights: InsightsByOrigin = {};
    for (const insight of insights) {
        const sectionGroup = groupedInsights[insight.origin.section] ?? (groupedInsights[insight.origin.section] = {});
        const insightsInQuestionGroup = sectionGroup[insight.origin.question] ?? (sectionGroup[insight.origin.question] = []);
        insightsInQuestionGroup.push(insight);
    }

    return groupedInsights;
}

export type ForkedInsightFilter = {
    propertyType: InsightPropertyType;
    value: unknown;
};
export function getInsightsForQuestion(insightsByOrigin: InsightsByOrigin, sectionTag: string, questionTag: string, forkFilter?: ForkedInsightFilter) {
    const insightsForQuestion = insightsByOrigin[sectionTag]?.[questionTag];
    if (!insightsForQuestion) return undefined;

    if (!forkFilter) return insightsForQuestion;

    return filterInsightsForFork(insightsForQuestion, forkFilter);
}

export function filterInsightsForFork(insights: Insight[], forkFilter: ForkedInsightFilter) {
    if (forkFilter.propertyType !== InsightPropertyType.AlternativeSolution || typeof forkFilter.value !== 'number')
        throw new Error('Unsupported insights fork filter');

    return insights.filter(insight => insight.type === InsightType.AlternativeSolution && insight.alternativeSolutionId === forkFilter.value);
}

export function findTemplateInCatalog(catalog: InsightCatalog, sectionTag: string, questionTag: string, templateTag: string) {
    const section = catalog.sections.find(s => s.tag === sectionTag);
    if (!section) return undefined;

    for (const group of section.groups) {
        const question = group.questions.find(q => q.tag === questionTag);
        if (!question) continue;

        const template = question.insightTemplates.find(t => t.tag === templateTag);
        if (template) return [section, group, question, template] as const;
    }

    return undefined;
}

function isForkingByPropertyType(
    insightsContextDefinition: InsightContext,
    section: InsightSection | ForkingInsightSection,
    group: InsightGroup | ForkedInsightGroup | ForkingInsightGroup,
    propertyType: InsightPropertyType
) {
    const propertiesNamesInContextOfType = insightsContextDefinition.properties.filter(p => p.type === propertyType).map(p => p.name);
    if (isForkingGroup(group)) {
        const groupIsForkedByPropertyType = group.contextProperties.some(p => propertiesNamesInContextOfType.includes(p));
        if (groupIsForkedByPropertyType) return true;
    }

    if (isForkingSection(section)) {
        const sectionIsForkedByPropertyType = section.contextProperties.some(p => propertiesNamesInContextOfType.includes(p));
        if (sectionIsForkedByPropertyType) return true;
    }

    return false;
}

export function resolveNewInsightAlternativeSolutionId(
    insightsCatalog: InsightCatalog,
    section: InsightSection | ForkingInsightSection,
    group: InsightGroup | ForkingInsightGroup | ForkedInsightGroup,
    forkValue: unknown,
    template: InsightCatalogTemplate,
    parametersValues: InsightParameterValue[]
): number {
    if (isForkingByPropertyType(insightsCatalog.context, section, group, InsightPropertyType.AlternativeSolution)) {
        if (forkValue === undefined) throw new Error('Fork value not provided');
        if (typeof forkValue !== 'number') throw new Error('Fork value for alternative solution must be a number');
        return forkValue;
    }

    const alternativeSolutionParameter = template.parameters.find(p => p.type === InsightPropertyType.AlternativeSolution);
    if (!alternativeSolutionParameter) throw new Error('Parameter of type AlternativeSolution is required when not in fork by alternative solution');
    const alternativeSolutionValue = parametersValues.find(parameterValue => parameterValue.id === alternativeSolutionParameter.id);
    if (!alternativeSolutionValue) throw new Error('Missing alternative parameter with id: ' + alternativeSolutionParameter.id);
    if (alternativeSolutionValue.value === undefined) throw new Error('Alternative solution parameter should has value');
    if (typeof alternativeSolutionValue.value !== 'number') throw new Error('AlternativeSolution parameter value should be a number');

    return alternativeSolutionValue.value;
}

type InsightsCoverageByOrigin = {
    [sectionTag: string]:
        | {
              [questionTag: string]:
                  | InsightCoverageEntry
                  | {
                        [branchValue: string]: InsightCoverageEntry | undefined;
                    }
                  | undefined;
          }
        | undefined;
};
export function groupInsightsCoverageByOrigin(insightsCoverage: InsightCoverageEntry[]): InsightsCoverageByOrigin {
    const insightsCoverageByOrigin: InsightsCoverageByOrigin = {};
    for (const insightCoverage of insightsCoverage) {
        const sectionGroup = insightsCoverageByOrigin[insightCoverage.section] ?? (insightsCoverageByOrigin[insightCoverage.section] = {});
        if (insightCoverage.branch) {
            const questionGroup = sectionGroup[insightCoverage.question];
            if (questionGroup && !isFlatInsightsCoverage(questionGroup)) questionGroup[insightCoverage.branch] = insightCoverage;
            else sectionGroup[insightCoverage.question] = { [insightCoverage.branch]: insightCoverage };
        } else sectionGroup[insightCoverage.question] = insightCoverage;
    }

    return insightsCoverageByOrigin;
}

export function getInsightsCoverage(
    insightsCoverageByOrigin: InsightsCoverageByOrigin,
    sectionTag: string,
    questionTag: string,
    forkValue?: unknown
): InsightCoverageEntry | undefined {
    const insightsCoverage = insightsCoverageByOrigin[sectionTag]?.[questionTag];
    if (!insightsCoverage) return undefined;
    const isFlat = isFlatInsightsCoverage(insightsCoverage);

    if (forkValue) {
        if (isFlat) return undefined;

        const branchKey = getInsightsCoverageBranchFromForkValue(forkValue);
        if (!branchKey) return undefined;
        return insightsCoverage[branchKey];
    }

    if (isFlat) return insightsCoverage;

    return undefined;
}

function isFlatInsightsCoverage(coverage: NonNullable<NonNullable<InsightsCoverageByOrigin['']>['']>): coverage is InsightCoverageEntry {
    return 'id' in coverage;
}

export function getInsightsCoverageBranchFromForkValue(forkValue: unknown): string | null {
    if (forkValue === undefined || forkValue === null) return null;
    if (typeof forkValue === 'string') return forkValue;

    return String(forkValue);
}

export function getForkingValueKey(value: unknown, property?: InsightContextProperty): React.Key | undefined {
    if (!property) return undefined;

    if (!value) return property.name;

    const valueType = typeof value;
    if (valueType === 'number' || valueType === 'string') return `${property.name}_${value}`;

    throw new Error('Unsupported forking value');
}

export function isDisabledForkedItem(item: ExpandedForkedItem, context?: Record<string, unknown>) {
    if (!context || item.forkingData.value) return false;

    if (!(item.forkingData.propertyName in context)) return true;

    const contextPropertyValue = context[item.forkingData.propertyName];

    return contextPropertyValue instanceof Array && !contextPropertyValue.length;
}

export function useExpandedInsightsCatalog(catalog?: InsightCatalog, context?: Record<string, unknown>) {
    const expandedCatalog = useMemo(() => {
        if (!catalog || !context) return undefined;

        return expandCatalog(catalog, context);
    }, [catalog, context]);

    return expandedCatalog;
}
