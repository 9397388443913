import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GraphicData } from '../../services/common';

export enum ModalView {
    FeedbackSubmitted = 1,
    OpenInterviewModal = 2
}

export type ModalData = {
    graphic: GraphicData;
    heading: string;
    description: string;
    mainButtonText: string;
    cancelButtonText: string;
};

export type InterviewModalData = ModalData & { ideaId: string; interviewId: number };

type ModalViewDataTypeMap = {
    [ModalView.FeedbackSubmitted]: number;
    [ModalView.OpenInterviewModal]: InterviewModalData;
};

type ModalState =
    | null
    | {
          [TView in ModalView]: { view: TView; data: ModalViewDataTypeMap[TView] };
      }[ModalView];

const initialState: ModalState = null;

export const modalSlice = createSlice({
    name: 'modal',
    initialState: initialState as ModalState,
    reducers: {
        hideModal: () => {
            return null;
        },
        showModal: (state: ModalState, action: PayloadAction<ModalState>) => {
            return action.payload;
        }
    }
});

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
