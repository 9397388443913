import { useCallback, useEffect, useId, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { appConfig } from '../config';
import { StickyElementObserver } from '../scripts/stickyElements';
import { useAppDispatch } from '../state/hooks';
import { addNotification } from '../state/notifications/platformNotificationsSlice';
import { useStartupLayout } from './startupHooks';

export function useForceRender() {
    const [, setForceRenderCounter] = useState(0);

    return useCallback(() => setForceRenderCounter(c => c + 1), []);
}

export function useScheduledReRender(refreshInterval?: number) {
    const reRender = useForceRender();

    useEffect(() => {
        if (refreshInterval === undefined) return;

        const intervalId = setInterval(reRender, refreshInterval);

        return () => clearInterval(intervalId);
    }, [reRender, refreshInterval]);
}

export function useReRenderOn(date?: Date) {
    const reRender = useForceRender();
    const reRenderOnInMilliseconds = date ? date.getTime() : 0;
    useEffect(() => {
        if (!reRenderOnInMilliseconds) return;
        const currentTimeInMilliseconds = Date.now();
        const reRenderInMilliseconds = reRenderOnInMilliseconds - currentTimeInMilliseconds;
        if (reRenderInMilliseconds < 0) return;
        const timeoutId = setTimeout(reRender, reRenderInMilliseconds);

        return () => clearTimeout(timeoutId);
    }, [reRender, reRenderOnInMilliseconds]);
}

export function useCacheBust() {
    return useMemo(() => new Date().getTime(), []);
}

export function useSingleClickButton<TCallbackArgs extends Array<any>, TCallbackResult>(): [
    boolean,
    (callback: (...args: TCallbackArgs) => TCallbackResult) => (...args: TCallbackArgs) => TCallbackResult
] {
    const [disabled, setDisabled] = useState(false);

    function singleClickCallbackCreator(callback: (...args: TCallbackArgs) => TCallbackResult): (...args: TCallbackArgs) => TCallbackResult {
        return (...args: TCallbackArgs) => {
            const result = callback(...args);
            if (result instanceof Promise) {
                setDisabled(true);
                return (result.finally(() => setDisabled(false)) as unknown) as TCallbackResult;
            }

            return result;
        };
    }

    return [disabled, singleClickCallbackCreator];
}

export function useInProgressOperationsTracker() {
    const [isInProgress, setIsInProgress] = useState<boolean>();
    const operationsInProgressRefCount = useRef<number>(0);

    const toTrackableOperation = useCallback(function toTrackableOperation<TActionArgs extends Array<any>, TResult>(
        action: (...args: TActionArgs) => Promise<TResult>
    ): (...args: TActionArgs) => Promise<TResult> {
        return function(...args: TActionArgs): Promise<TResult> {
            operationsInProgressRefCount.current++;
            setIsInProgress(true);
            return action(...args).finally(() => {
                operationsInProgressRefCount.current--;
                if (!operationsInProgressRefCount.current) setIsInProgress(false);
            });
        };
    },
    []);

    return [isInProgress, toTrackableOperation] as const;
}

export function useStickyFooter<TElement extends HTMLElement>(isLoaded?: boolean) {
    const footerRef = useRef<TElement | null>(null);
    const { pageContainerRef, setPageContainerClassName } = useStartupLayout();
    useLayoutEffect(() => {
        if (isLoaded === false) return; // Explicitly check for false since is the variable is not passed (is undefined) we do not wait for load

        setPageContainerClassName('k-px-0 k-pb-3');

        let footerElementObserver: StickyElementObserver | undefined;
        if (footerRef.current && pageContainerRef.current) {
            footerElementObserver = new StickyElementObserver(footerRef.current, pageContainerRef.current, 'sticky-footer-stuck', 'bottom');
            footerElementObserver.init();
        }

        return () => {
            setPageContainerClassName(undefined);
            if (footerElementObserver) footerElementObserver.destroy();
        };
    }, [pageContainerRef, setPageContainerClassName, isLoaded]);

    return footerRef;
}

export function useCopyToClipboard() {
    const dispatch = useAppDispatch();

    return async function(textToCopy: string, textDescription: string, richTextToCopy?: string) {
        try {
            if (richTextToCopy) {
                const richTextBlob = new Blob([richTextToCopy], { type: 'text/html' });
                const plaintTextBlob = new Blob([textToCopy], { type: 'text/plain' });
                const data = [
                    new ClipboardItem({
                        [plaintTextBlob.type]: plaintTextBlob,
                        [richTextBlob.type]: richTextBlob
                    })
                ];

                await navigator.clipboard.write(data);
            } else await navigator.clipboard.writeText(textToCopy);
            dispatch(addNotification({ content: `${textDescription} copied to clipboard!` }));
        } catch {
            dispatch(addNotification({ content: `Failed to copy ${textDescription}`, type: 'error' }));
        }
    };
}

function getWindowDimensions(type: 'screen' | 'inner') {
    if (typeof window === 'undefined') return { width: 0, height: 0 };

    const width = type === 'screen' ? window.screen.width : window.innerWidth;
    const height = type === 'screen' ? window.screen.height : window.innerHeight;
    return {
        width,
        height
    };
}

export function useWindowDimensions(type: 'screen' | 'inner' = 'inner') {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(type));

    useEffect(() => {
        if (typeof window === 'undefined') return;

        function handleResize() {
            setWindowDimensions(getWindowDimensions(type));
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [type]);

    return windowDimensions;
}

export enum ResponsiveGroup {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
    xxl = 'xxl'
}

export function useResponsiveLayout() {
    const getResponsiveGroup = useCallback(({ width }: { width: number; height: number }) => {
        if (width < appConfig.breakpoints.xs) return ResponsiveGroup.xs;
        if (width < appConfig.breakpoints.sm) return ResponsiveGroup.sm;
        if (width < appConfig.breakpoints.md) return ResponsiveGroup.md;
        if (width < appConfig.breakpoints.lg) return ResponsiveGroup.lg;
        if (width < appConfig.breakpoints.xl) return ResponsiveGroup.xl;
        return ResponsiveGroup.xxl;
    }, []);

    const [windowDimensions, setWindowDimensions] = useState<ResponsiveGroup>(getResponsiveGroup(getWindowDimensions('inner')));

    useLayoutEffect(() => {
        if (typeof window === 'undefined') return;

        function handleResize() {
            setWindowDimensions(getResponsiveGroup(getWindowDimensions('inner')));
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [getResponsiveGroup]);

    return windowDimensions;
}

export function useAsRef<TValue>(value: TValue) {
    const valueRef = useRef(value);
    valueRef.current = value;

    return valueRef;
}

export function useFittingPopoverCallout(
    fittingDirection: 'horizontal' | 'vertical',
    popoverAnchorElement: Element | null | undefined,
    positionAdjustment = 0
) {
    // The kendo popover component does not expose its elements thought the provided ref, so we use the element id as a workaround
    const fittingPopoverId = `fittingCalloutPopover-${useId()}`;

    function popoverPositioningHandler() {
        if (!popoverAnchorElement) return;

        const popoverWrapperElement = document.getElementById(fittingPopoverId);
        if (!popoverWrapperElement) return;

        const popoverElement = popoverWrapperElement.querySelector(':scope > .k-popover');
        if (!popoverElement) return;

        const calloutElement = popoverElement.querySelector<HTMLElement>(':scope > .k-popover-callout');
        if (!calloutElement) return;

        const anchorBoundingRect = popoverAnchorElement.getBoundingClientRect();
        const popoverBoundingRect = popoverElement.getBoundingClientRect();

        const isHorizontal = fittingDirection === 'horizontal';
        const anchorPosition = isHorizontal ? anchorBoundingRect.left + anchorBoundingRect.width / 2 : anchorBoundingRect.top + anchorBoundingRect.height / 2;
        const popoverBasePosition = isHorizontal ? popoverBoundingRect.left : popoverBoundingRect.top;
        const calloutDesiredPosition = anchorPosition - popoverBasePosition + positionAdjustment;
        const calloutDesiredPositionStyle = `${calloutDesiredPosition}px`;
        const calloutPositionStyleProp = isHorizontal ? 'left' : 'top';
        if (calloutElement.style[calloutPositionStyleProp] !== calloutDesiredPositionStyle)
            calloutElement.style[calloutPositionStyleProp] = calloutDesiredPositionStyle;
    }

    return [fittingPopoverId, popoverPositioningHandler] as const;
}

export function usePreviousValue<TValue>(value: TValue) {
    const [current, setCurrent] = useState(value);
    const [previous, setPrevious] = useState<TValue>();

    if (value !== current) {
        setPrevious(current);
        setCurrent(value);
    }

    return previous;
}
