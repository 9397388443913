import { Field, Form } from '@progress/kendo-react-form';
import { TextArea } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { AuthLocationState } from '.';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { AuthForm, AuthFormSubmitButton } from '../../pages/layouts/authLayout';
import { CreateUserData, authenticationService } from '../../services/authenticationService';
import { isEmptyObject } from '../../services/common';
import { capitalizeFirstLetter, getFirstWord, separateJoinedWords } from '../../services/common/common.string';
import { ErrorResponse, ErrorsResponse, HttpException } from '../../services/httpServiceBase';
import { useInvisibleReCaptcha } from '../common/invisibleReCaptcha';
import { EmailField, OptionalFieldLabel as OptionalInputLabelType, PasswordField, ValidatedInput, defaultValidators, maxLengthValidator } from '../ui/inputs';

export function RegistrationForm({ onRegistered, locationStateParams }: { onRegistered?: () => void; locationStateParams: AuthLocationState }) {
    const { email, hasInvitation, activationCode } = locationStateParams;
    const [submitDisabled, submitActionCreator] = useSingleClickButton<
        Parameters<typeof onSubmitRegistrationForm>,
        ReturnType<typeof onSubmitRegistrationForm>
    >();

    const getReCaptchaToken = useInvisibleReCaptcha();
    const [fieldsErrors, setFieldsErrors] = useState<Partial<Record<RegistrationFormFieldName, string>>>();
    if (!email) return null;

    async function onSubmitRegistrationForm(data: Record<string, string>) {
        if (!email) return;

        const reCaptchaToken = await getReCaptchaToken?.();

        const newUserData: CreateUserData = {
            emailAddress: email,
            password: data.password,
            firstName: data.firstName,
            lastName: data.lastName,
            expectation: data.expectation,
            confirmationCode: activationCode
        };

        try {
            await authenticationService.registerUser(newUserData, reCaptchaToken);
            onRegistered?.();
        } catch (error) {
            const fieldsErrors = tryHandleRegistrationError(error);
            setFieldsErrors(fieldsErrors);
            if (!fieldsErrors) throw error;
        }
    }

    function clearError(fieldName: RegistrationFormFieldName) {
        setFieldsErrors(errors => {
            if (!errors || !(fieldName in errors)) return errors;
            const clonedErrors = { ...errors };
            delete clonedErrors[fieldName];

            if (isEmptyObject(clonedErrors)) return undefined;

            return clonedErrors;
        });
    }

    return (
        <Form
            onSubmit={submitActionCreator(onSubmitRegistrationForm)}
            ignoreModified={true}
            initialValues={{ emailAddress: email }}
            render={formRenderProps => (
                <AuthForm
                    button={<AuthFormSubmitButton disabled={!formRenderProps.allowSubmit || submitDisabled}>Start a free 14-day trial</AuthFormSubmitButton>}
                    footer={
                        <span className="k-fs-sm">
                            By proceeding you agree to our{' '}
                            <a className="k-button-link-secondary" href="/legal/privacy-policy" target="_blank" rel="noreferrer">
                                Privacy Policy
                            </a>{' '}
                            and{' '}
                            <a className="k-button-link-secondary" href="/legal/terms-of-use" target="_blank" rel="noreferrer">
                                Terms of Use
                            </a>
                            .
                        </span>
                    }
                >
                    <EmailField showBackToLogin={!hasInvitation} errorMessage={fieldsErrors?.emailAddress} readOnly className="k-disabled" />

                    <StackLayout className="k-gap-2">
                        <Field
                            name="firstName"
                            component={ValidatedInput}
                            label="First name"
                            autoComplete="given-name"
                            validator={defaultValidators.firstNameValidators}
                            maxLength={50}
                            errorMessage={fieldsErrors?.firstName}
                            onChange={() => clearError('firstName')}
                        />
                        <Field
                            name="lastName"
                            component={ValidatedInput}
                            label="Last name"
                            autoComplete="family-name"
                            validator={defaultValidators.lastNameValidators}
                            maxLength={50}
                            errorMessage={fieldsErrors?.lastName}
                            onChange={() => clearError('lastName')}
                        />
                    </StackLayout>
                    <input name="email" type="email" autoComplete="email" className="k-d-none" value={email} />
                    <PasswordField autoComplete="new-password" showHint={true} errorMessage={fieldsErrors?.password} onChange={() => clearError('password')} />
                    <Field
                        name="expectation"
                        component={ValidatedInput}
                        label="What you are looking forward to get done with Icanpreneur?"
                        labelType={OptionalInputLabelType}
                        inputType={TextArea}
                        maxLength={1000}
                        placeholder="We'd love to know what you're trying to accomplish..."
                        validator={maxLengthValidator('Description', 1000)}
                        onChange={() => clearError('expectation')}
                        rows={3}
                        errorMessage={fieldsErrors?.expectation}
                    />
                </AuthForm>
            )}
        />
    );
}

const registrationFormFieldNames = ['emailAddress', 'password', 'firstName', 'lastName', 'expectation'] as const;
type RegistrationFormFieldName = typeof registrationFormFieldNames[number];
function isRegistrationFormFieldName(fieldName: string): fieldName is RegistrationFormFieldName {
    return registrationFormFieldNames.includes(fieldName as any);
}

function tryHandleRegistrationError(error: unknown): Partial<Record<RegistrationFormFieldName, string>> | undefined {
    if (!(error instanceof HttpException)) return undefined;

    if (error.status === 409) {
        const errorResponse = error.parseResponse<ErrorResponse>();
        if (errorResponse.message === 'User already exists.') return { emailAddress: 'User with this email already exists' };
        return undefined;
    } else if (error.status === 400) {
        const errorsData = error.parseResponse<ErrorsResponse>();
        if (!(errorsData.message instanceof Array) || errorsData.message.length === 0) return undefined;

        let handledErrorData: Partial<Record<RegistrationFormFieldName, string>> | undefined = undefined;
        errorsData.message.forEach(errorMessage => {
            const fieldWithError = getFirstWord(errorMessage);
            if (!fieldWithError || !isRegistrationFormFieldName(fieldWithError)) return;
            const fieldErrorMessage = errorMessage.replace(fieldWithError, capitalizeFirstLetter(separateJoinedWords(fieldWithError)) as string);
            if (!handledErrorData) handledErrorData = { [fieldWithError]: fieldErrorMessage };
            else if (!handledErrorData[fieldWithError]) handledErrorData[fieldWithError] = fieldErrorMessage;
        });

        if (handledErrorData) return handledErrorData;
    }

    return undefined;
}
