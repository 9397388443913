import { Switch } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { SubscriptionType } from '../../services/authenticationService';
import { combineClassNames } from '../../services/common';
import { PriceDiscount, PricingData } from '../../services/stripeBillingService';
import { getCurrencySymbol, getDiscountedPriceAmount, getPriceAmountForSubscriptionType, stringifyPriceAmount } from './billingDataUtils';

export function SubscriptionTypePicker({
    value,
    onChange,
    pricing,
    className
}: {
    value?: SubscriptionType;
    onChange?: (e: { value: SubscriptionType }) => void;
    pricing?: PricingData;
    className?: string;
}) {
    const annualDiscountPercent = pricing && Math.round(100 - (pricing.annualPrice.amount * 100) / (pricing.monthlyPrice.amount * 12));

    return (
        <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className={combineClassNames('k-gap-2', className)}>
            <strong className="k-cursor-pointer" onClick={() => onChange?.({ value: SubscriptionType.Monthly })}>
                Pay monthly
            </strong>
            <Switch
                checked={value === SubscriptionType.Annual}
                onChange={e => onChange?.({ value: e.value ? SubscriptionType.Annual : SubscriptionType.Monthly })}
                offLabel=""
                onLabel=""
                size="small"
            />
            <strong className="k-cursor-pointer" onClick={() => onChange?.({ value: SubscriptionType.Annual })}>
                Pay yearly
            </strong>
            <span
                className={combineClassNames(
                    'k-icp-text-smaller k-text-success k-rounded-full k-py-hair k-px-1 k-border k-border-solid k-border-success',
                    value !== SubscriptionType.Annual ? 'k-icp-ghost' : undefined
                )}
            >
                Save {annualDiscountPercent}%
            </span>
        </StackLayout>
    );
}

export function SubscriptionOption({
    pricing,
    discount,
    subscriptionType
}: {
    pricing: PricingData;
    discount?: PriceDiscount;
    subscriptionType: SubscriptionType;
}) {
    const price = subscriptionType === SubscriptionType.Monthly ? pricing.monthlyPrice : pricing.annualPrice;
    const currentDiscount = discount || pricing.discount;
    const discountedPrice = getDiscountedPriceAmount(price.amount, currentDiscount);

    const monthlyPriceAmount = getPriceAmountForSubscriptionType(discountedPrice, subscriptionType, SubscriptionType.Monthly);
    const annualPriceAmount = getPriceAmountForSubscriptionType(discountedPrice, subscriptionType, SubscriptionType.Annual);

    const currencySymbol = getCurrencySymbol(price.currency);

    return (
        <div className="k-rounded-lg k-icp-bg-secondary-4 k-border k-border-solid k-border-secondary k-overflow-hidden">
            {currentDiscount && (
                <div className="k-text-center k-px-2 k-py-1 k-bg-secondary k-icp-white-text k-fs-sm">
                    <strong>
                        {currentDiscount.name}{' '}
                        {currentDiscount.percentOff
                            ? `${currentDiscount.percentOff}%`
                            : currentDiscount.amountOff
                            ? `${getCurrencySymbol(currentDiscount.amountOffCurrency!)}${currentDiscount.amountOff}`
                            : undefined}
                    </strong>
                </div>
            )}
            <StackLayout
                align={{ horizontal: 'center', vertical: 'middle' }}
                className={combineClassNames('k-px-3 k-pb-4', currentDiscount ? 'k-pt-3' : 'k-pt-4')}
            >
                <div className="k-flex-1 k-text-right">
                    <span className="k-display-4">
                        {currencySymbol}
                        {stringifyPriceAmount(monthlyPriceAmount)}
                    </span>
                    <span className="k-fs-sm k-ml-1 k-pl-thin k-mr-7">/month</span>
                </div>

                <div className="k-flex-1 k-fs-sm k-icp-subtle-text k-text-left">
                    {discountedPrice !== price.amount && (
                        <div>
                            original price {currencySymbol}
                            {stringifyPriceAmount(getPriceAmountForSubscriptionType(price.amount, subscriptionType, SubscriptionType.Monthly))}/mo
                        </div>
                    )}
                    <div>
                        billed {subscriptionType === SubscriptionType.Monthly ? 'monthly' : 'annually'} at {currencySymbol}
                        {stringifyPriceAmount(annualPriceAmount)}/yr
                    </div>
                </div>
            </StackLayout>
        </div>
    );
}
