import { StackLayout, StackLayoutHandle } from '@progress/kendo-react-layout';
import { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, Outlet, To, useLocation } from 'react-router-dom';
import { TabbedNav } from '../../scripts/tabbedNav';
import { combineClassNames } from '../../services/common';

type TabbedLayoutContextValue = { setHeaderContent: (content: ReactNode) => void };
const TabbedLayoutContext = createContext<TabbedLayoutContextValue>({ setHeaderContent(_) {} });

export function useTabbedLayoutContext() {
    return useContext(TabbedLayoutContext);
}

type TabbedLayoutDataContextValue = unknown;
const TabbedLayoutDataContext = createContext<TabbedLayoutDataContextValue>(undefined);
export function useTabbedLayoutData<TData>() {
    return useContext(TabbedLayoutDataContext) as TData;
}

export type TabHeader = { title: string; to: To };

export function TabbedLayout({ navWrapperClassName, data, ...navProps }: { navWrapperClassName?: string; data?: unknown } & TabbedLayoutNavProps) {
    const [headerContent, setHeaderContent] = useState<ReactNode>();
    const tabbedLayoutContextValue = useMemo<TabbedLayoutContextValue>(() => ({ setHeaderContent }), []);

    return (
        <>
            <StackLayout
                align={{ horizontal: 'start', vertical: 'middle' }}
                className={combineClassNames('k-justify-content-between k-gap-16 k-mb-6', navWrapperClassName)}
            >
                <TabbedLayoutNav {...navProps} />
                {headerContent}
            </StackLayout>

            <TabbedLayoutDataContext.Provider value={data}>
                <TabbedLayoutContext.Provider value={tabbedLayoutContextValue}>
                    <Outlet />
                </TabbedLayoutContext.Provider>
            </TabbedLayoutDataContext.Provider>
        </>
    );
}

type TabbedLayoutNavProps = { headers: TabHeader[]; size?: 'medium' | 'small'; theme?: 'primary' | 'secondary' };
function TabbedLayoutNav({ headers, size, theme }: TabbedLayoutNavProps) {
    const headerNavRef = useRef<StackLayoutHandle>(null);
    const location = useLocation();

    useEffect(() => {
        if (!headerNavRef.current?.element) return;

        const tabbedNav = new TabbedNav(headerNavRef.current.element);

        return () => tabbedNav.destroy();
    }, [location]);

    return (
        <StackLayout
            ref={headerNavRef}
            align={{ horizontal: 'start', vertical: 'middle' }}
            className={combineClassNames(
                size === 'small' ? 'k-gap-5' : 'k-gap-6',
                'k-icp-tabbed-nav',
                theme === 'secondary' ? 'k-icp-tabbed-nav--secondary' : undefined
            )}
        >
            {headers.map((header, index) => (
                <NavLink key={index} className={size === 'small' ? 'k-font-semibold' : 'k-h2'} to={header.to} end={true}>
                    {header.title}
                </NavLink>
            ))}

            <div className="k-icp-tabbed-nav-cursor" />
        </StackLayout>
    );
}
