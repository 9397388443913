import { useCallback, useEffect, useMemo, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { TaskEditorProps, useTaskCommitOperation } from '../components/journey/editors';
import { immutableAddOrUpdate, immutableRemove } from '../services/common';
import { ResearchReachOutData } from '../services/events';
import { Hypothesis, HypothesisGroup, hypothesesService } from '../services/hypothesesService';
import { Insight, InsightContext, InsightContextPropertySource, InsightPropertyType, insightsService } from '../services/insightsService';
import { Interview, InterviewHypothesisVerdict, interviewsService } from '../services/interviewsService';
import { ResearchEditorParams } from '../services/journeyService';
import {
    RealTimeUpdateHypothesisEventData,
    RealTimeUpdateHypothesisUpdateEventData,
    RealTimeUpdateInsightEventData,
    RealTimeUpdateInterviewEventData,
    RealTimeUpdateInterviewHypothesisVerdictEventData,
    RealTimeUpdateInterviewQuoteEventData,
    RealTimeUpdateResearchEventData,
    RealTimeUpdateResearchScheduleAddedEventData,
    RealTimeUpdateScheduleEventData,
    realTimeUpdatesEventHub
} from '../services/realTimeUpdatesService';
import {
    InterviewHypothesisQuote,
    InterviewInsightQuote,
    InterviewQuote,
    InterviewQuoteType,
    ProblemValidationResearch,
    ResearchContact,
    researchService
} from '../services/researchService';
import { FullSchedule, schedulesService } from '../services/schedulesService';
import { ReducedUser } from '../services/usersService';
import { useAppDispatch } from '../state/hooks';
import { addNotification } from '../state/notifications/platformNotificationsSlice';
import { useAsRef } from './commonHooks';
import { useConfirmDialog } from './dialogHooks';

export function useProblemValidationResearch(ideaId: string, researchId?: number, onDeleteNavigateTo?: To) {
    const [research, setResearch] = useState<ProblemValidationResearch | null>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const loadResearch = useCallback(() => {
        if (!researchId) {
            setResearch(undefined);
            return;
        }

        researchService.getProblemValidationResearch(ideaId, researchId).then(setResearch);
    }, [ideaId, researchId]);

    useEffect(() => {
        loadResearch();
    }, [loadResearch]);

    useEffect(() => {
        if (researchId === undefined) return;

        function onResearchDataUpdated(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;

            loadResearch();
        }

        function onResearchDeleted(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;

            dispatch(addNotification({ content: 'The research was deleted' }));
            if (onDeleteNavigateTo) navigate(onDeleteNavigateTo);
            else setResearch(null);
        }

        realTimeUpdatesEventHub.addEventListener('research', 'update', onResearchDataUpdated);
        realTimeUpdatesEventHub.addEventListener('research', 'hypothesesUpdated', onResearchDataUpdated);
        realTimeUpdatesEventHub.addEventListener('research', 'scheduleAdded', onResearchDataUpdated);
        realTimeUpdatesEventHub.addEventListener('research', 'schedulesUpdated', onResearchDataUpdated);
        realTimeUpdatesEventHub.addEventListener('research', 'delete', onResearchDeleted);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('research', 'update', onResearchDataUpdated);
            realTimeUpdatesEventHub.removeEventListener('research', 'hypothesesUpdated', onResearchDataUpdated);
            realTimeUpdatesEventHub.removeEventListener('research', 'scheduleAdded', onResearchDataUpdated);
            realTimeUpdatesEventHub.removeEventListener('research', 'schedulesUpdated', onResearchDataUpdated);
            realTimeUpdatesEventHub.removeEventListener('research', 'delete', onResearchDeleted);
        };
    }, [dispatch, ideaId, loadResearch, navigate, onDeleteNavigateTo, researchId]);

    return research;
}

export function useTaskManageContactsInResearch(props: TaskEditorProps<ResearchEditorParams>) {
    const createCommitOperation = useTaskCommitOperation(props);
    const { show: showConfirmRemoveDialog, element: confirmRemoveDialog } = useConfirmDialog();

    const updateProblemValidationResearchContactsOperations = createCommitOperation(
        researchService.updateProblemValidationResearchContacts.bind(researchService)
    );

    async function updateContactsInResearch(contactIdsToAdd?: number[], contactIdsToRemove?: number[]) {
        const updatedContacts = await updateProblemValidationResearchContactsOperations(
            props.ideaId,
            props.params.researchId,
            contactIdsToAdd,
            contactIdsToRemove
        );
        props.setTaskData(taskData => ({
            ...taskData,
            researchContacts: {
                ...taskData.researchContacts,
                [props.params.researchId]: updatedContacts
            }
        }));
    }

    return {
        confirmRemoveDialog,
        addContactToResearch(contactId: number) {
            return updateContactsInResearch([contactId]);
        },
        removeContactFromResearch(contactId: number, contactName: string) {
            return new Promise<boolean>((resolve, reject) => {
                showConfirmRemoveDialog({
                    title: 'Remove contact from research',
                    content: (
                        <>
                            Are you sure you want to remove <strong>{contactName}</strong> from this research? Data will no longer be considered part of it, but
                            will not be deleted.
                        </>
                    ),
                    confirmButtonText: 'Remove contact',
                    callback: () =>
                        updateContactsInResearch(undefined, [contactId])
                            .then(() => resolve(true))
                            .catch(reject),
                    onCancel: () => resolve(false)
                });
            });
        }
    };
}

export function useTaskManageResearchReachOuts(props: TaskEditorProps<ResearchEditorParams>) {
    const createCommitOperation = useTaskCommitOperation(props);

    function addOrUpdateResearchContact(researchContact: ResearchContact) {
        props.setTaskData(taskData => {
            const researchContacts = taskData.researchContacts?.[props.params.researchId];
            if (!researchContacts) return taskData;

            const updatedResearchContacts = immutableAddOrUpdate(researchContacts, researchContact, rc => rc.contact.id === researchContact.contact.id);
            return {
                ...taskData,
                researchContacts: {
                    ...taskData.researchContacts,
                    [props.params.researchId]: updatedResearchContacts
                }
            };
        });
    }

    async function refreshResearchContact(contactId: number) {
        const researchContact = await researchService.getProblemValidationResearchContact(props.ideaId, props.params.researchId, contactId);
        addOrUpdateResearchContact(researchContact);

        return researchContact;
    }

    const createReachOutOperation = createCommitOperation(async (contactId: number, data: ResearchReachOutData) => {
        await researchService.createProblemValidationResearchReachOut(props.ideaId, props.params.researchId, contactId, data);
        await refreshResearchContact(contactId);
    });

    const refreshResearchContactOperation = createCommitOperation(refreshResearchContact);

    return { createReachOutOperation, refreshResearchContactOperation };
}

export function useResearchSchedulesEditorDataSource(ideaId: string, researchId: number) {
    const [hostsWithPendingSchedule, setHostsWithPendingSchedule] = useState<ReducedUser[]>([]);
    const onResearchSchedulesLoad = useCallback(
        (researchSchedules: FullSchedule[]) =>
            setHostsWithPendingSchedule(hosts => immutableRemove(hosts, host => !researchSchedules.some(schedule => host.userId === schedule.user.userId))),
        []
    );
    const onResearchScheduleExternallyAdded = useCallback(
        (researchSchedule: FullSchedule) => setHostsWithPendingSchedule(hosts => immutableRemove(hosts, host => host.userId !== researchSchedule.user.userId)),
        []
    );
    const [schedules, setSchedules, loadResearchSchedules] = useResearchSchedules(
        ideaId,
        researchId,
        onResearchSchedulesLoad,
        onResearchScheduleExternallyAdded
    );

    useEffect(() => {
        if (!schedules || !schedules.length) return;
    }, [schedules]);

    const removePendingSchedule = useCallback(function(hostId: string) {
        setHostsWithPendingSchedule(hosts => hosts.filter(h => h.userId !== hostId));
    }, []);

    const addPendingSchedule = useCallback(function(host: ReducedUser) {
        setHostsWithPendingSchedule(hosts => (hosts.some(h => h.userId === host.userId) ? hosts : [...hosts, host]));
    }, []);

    return {
        schedules,
        setSchedules,
        hostsWithPendingSchedule,
        loadResearchSchedules,
        removePendingSchedule,
        addPendingSchedule
    } as const;
}

export function useResearchSchedules(
    ideaId: string,
    researchId: number,
    onSchedulesLoad?: (schedules: FullSchedule[]) => void,
    onScheduleExternallyAdded?: (schedule: FullSchedule) => void
) {
    const [schedules, setSchedules] = useState<FullSchedule[]>();

    const loadResearchSchedules = useCallback(async () => {
        const researchSchedules = await schedulesService.getResearchSchedules(ideaId, researchId);
        setSchedules(researchSchedules);
        onSchedulesLoad?.(researchSchedules);
    }, [ideaId, researchId, onSchedulesLoad]);

    useEffect(() => {
        loadResearchSchedules();
    }, [loadResearchSchedules]);

    useEffect(() => {
        async function onResearchScheduleAdded(e: RealTimeUpdateResearchScheduleAddedEventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;

            const newSchedule = await schedulesService.getSchedule(e.ideaId, e.scheduleId);
            setSchedules(s => (s ? [...s, newSchedule] : [newSchedule]));
            onScheduleExternallyAdded?.(newSchedule);
        }

        function onResearchSchedulesUpdated(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;
            loadResearchSchedules();
        }

        async function onScheduleUpdated(e: RealTimeUpdateScheduleEventData) {
            if (e.ideaId !== ideaId) return;
            const schedule = await schedulesService.getSchedule(e.ideaId, e.scheduleId);
            if (!schedule.relatedResearch.some(r => r.id)) return;
            setSchedules(schedules => {
                let scheduleUpdated = false;
                const updatedSchedules =
                    schedules?.map(s => {
                        if (s.id === schedule.id) {
                            scheduleUpdated = true;
                            return schedule;
                        }
                        return s;
                    }) ?? [];
                if (!scheduleUpdated) updatedSchedules.push(schedule);

                return updatedSchedules;
            });
        }

        async function onScheduleDeleted(e: RealTimeUpdateScheduleEventData) {
            if (e.ideaId !== ideaId) return;
            setSchedules(schedules => {
                if (!schedules) return schedules;

                const scheduleIndex = schedules.findIndex(s => s.id === e.scheduleId);
                if (scheduleIndex === -1) return schedules;
                const filteredSchedules = schedules.filter((_, index) => index !== scheduleIndex);

                return filteredSchedules;
            });
        }

        async function onScheduleRestore(e: RealTimeUpdateScheduleEventData) {
            if (e.ideaId !== ideaId) return;
            const schedule = await schedulesService.getSchedule(e.ideaId, e.scheduleId);
            if (!schedule.relatedResearch.some(r => r.id)) return;
            await loadResearchSchedules();
        }

        realTimeUpdatesEventHub.addEventListener('research', 'scheduleAdded', onResearchScheduleAdded);
        realTimeUpdatesEventHub.addEventListener('research', 'schedulesUpdated', onResearchSchedulesUpdated);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleUpdate', onScheduleUpdated);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleDelete', onScheduleDeleted);
        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleRestore', onScheduleRestore);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('research', 'scheduleAdded', onResearchScheduleAdded);
            realTimeUpdatesEventHub.removeEventListener('research', 'schedulesUpdated', onResearchSchedulesUpdated);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleUpdate', onScheduleUpdated);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleDelete', onScheduleDeleted);
            realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleRestore', onScheduleRestore);
        };
    }, [ideaId, loadResearchSchedules, onScheduleExternallyAdded, researchId]);

    return [schedules, setSchedules, loadResearchSchedules] as const;
}

export function useResearchHypotheses(ideaId: string, researchId?: number) {
    const [hypotheses, setHypotheses] = useState<Hypothesis[]>();

    useEffect(() => {
        if (researchId === undefined) return;

        function loadHypotheses() {
            hypothesesService.getAllHypotheses(ideaId, HypothesisGroup.CustomerProblem, researchId).then(setHypotheses);
        }
        loadHypotheses();

        async function onResearchHypothesesUpdated(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;

            loadHypotheses();
        }

        realTimeUpdatesEventHub.addEventListener('research', 'hypothesesUpdated', onResearchHypothesesUpdated);
    }, [ideaId, researchId]);

    const hypothesesRef = useAsRef(hypotheses);
    useEffect(() => {
        function isHypothesisInCurrentResearch(hypothesis: Hypothesis) {
            if (!hypothesis.research) return false;

            return hypothesis.research.some(r => r.id === researchId);
        }

        async function onHypothesisDataUpdated(e: RealTimeUpdateHypothesisEventData) {
            if (!hypothesesRef.current || !hypothesesRef.current.some(h => h.id === e.hypothesisId)) return;

            const updatedHypothesis = await hypothesesService.getHypothesis(ideaId, e.hypothesisGroup, e.hypothesisId);
            if (!isHypothesisInCurrentResearch(updatedHypothesis)) return;

            setHypotheses(hypotheses => immutableAddOrUpdate(hypotheses, updatedHypothesis, h => h.id === e.hypothesisId));
        }

        async function onHypothesisResearchUpdated(e: RealTimeUpdateHypothesisEventData) {
            const updatedHypothesis = await hypothesesService.getHypothesis(ideaId, e.hypothesisGroup, e.hypothesisId);
            const isHypothesisStillInCurrentResearch = isHypothesisInCurrentResearch(updatedHypothesis);
            setHypotheses(hypotheses =>
                isHypothesisStillInCurrentResearch
                    ? immutableAddOrUpdate(hypotheses, updatedHypothesis, h => h.id === e.hypothesisId)
                    : immutableRemove(hypotheses, h => h.id !== e.hypothesisId)
            );
        }

        async function onHypothesisUpdated(e: RealTimeUpdateHypothesisUpdateEventData) {
            if (e.ideaId !== ideaId) return;
            if (e.researchUpdated) await onHypothesisResearchUpdated(e);
            else await onHypothesisDataUpdated(e);
        }

        async function onHypothesisDeleted(e: RealTimeUpdateHypothesisEventData) {
            if (e.ideaId !== ideaId) return;
            setHypotheses(hypotheses => immutableRemove(hypotheses, h => h.id !== e.hypothesisId));
        }

        async function onHypothesisRestore(e: RealTimeUpdateHypothesisEventData) {
            if (e.ideaId !== ideaId) return;
            const restoredHypothesis = await hypothesesService.getHypothesis(ideaId, e.hypothesisGroup, e.hypothesisId);
            if (!isHypothesisInCurrentResearch(restoredHypothesis)) return;

            setHypotheses(hypotheses => immutableAddOrUpdate(hypotheses, restoredHypothesis, h => h.id === e.hypothesisId));
        }

        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisUpdate', onHypothesisUpdated);
        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisDelete', onHypothesisDeleted);
        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisRestore', onHypothesisRestore);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisUpdate', onHypothesisUpdated);
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisDelete', onHypothesisDeleted);
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisRestore', onHypothesisRestore);
        };
    }, [hypothesesRef, ideaId, researchId]);

    return hypotheses;
}

export function useInsightsCatalogContextFromResearch(
    ideaId: string,
    contextDefinition?: InsightContext,
    researchOrResearchId?: number | ProblemValidationResearch | null
) {
    const shouldLoadResearch =
        typeof researchOrResearchId === 'number' &&
        contextDefinition !== undefined &&
        contextDefinition.properties.some(p => p.source === InsightContextPropertySource.Research);
    const loadedResearch = useProblemValidationResearch(ideaId, shouldLoadResearch ? researchOrResearchId : undefined);
    const research = typeof researchOrResearchId === 'object' ? researchOrResearchId : loadedResearch;

    const researchContextProperties = useMemo(() => {
        if (!research || !contextDefinition) return undefined;

        const researchProperties = contextDefinition.properties.filter(p => p.source === InsightContextPropertySource.Research);
        if (!researchProperties.length) return undefined;

        const result: Record<string, unknown> = {};
        for (const catalogProperty of researchProperties) {
            if (catalogProperty.type === InsightPropertyType.CustomerSegment) result[catalogProperty.name] = research.customerSegmentId;
            else if (catalogProperty.type === InsightPropertyType.JobToBeDone) result[catalogProperty.name] = research.jobToBeDoneId;
        }

        return result;
    }, [contextDefinition, research]);

    return [researchContextProperties, research] as const;
}

export function useResearchInterviews(ideaId: string, researchId: number) {
    const [researchInterviews, setResearchInterviews] = useState<Interview[]>();

    useEffect(() => {
        interviewsService.getAllInterviews(ideaId, researchId).then(setResearchInterviews);

        async function onInterviewCreatedOrRestored(e: RealTimeUpdateInterviewEventData) {
            if (e.ideaId !== ideaId) return;

            const interview = await interviewsService.getInterview(ideaId, e.interviewId);
            if (interview.researchId !== researchId) return;

            setResearchInterviews(interviews => immutableAddOrUpdate(interviews, interview, i => i.id === interview.id));
        }

        function onInterviewDeleted(e: RealTimeUpdateInterviewEventData) {
            if (e.ideaId !== ideaId) return;
            setResearchInterviews(interviews => immutableRemove(interviews, i => i.id !== e.interviewId));
        }

        // We are not handling updates since changing research of an interview is not allowed
        realTimeUpdatesEventHub.addEventListener('interview', 'add', onInterviewCreatedOrRestored);
        realTimeUpdatesEventHub.addEventListener('interview', 'delete', onInterviewDeleted);
        realTimeUpdatesEventHub.addEventListener('interview', 'restore', onInterviewCreatedOrRestored);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview', 'add', onInterviewCreatedOrRestored);
            realTimeUpdatesEventHub.removeEventListener('interview', 'delete', onInterviewDeleted);
            realTimeUpdatesEventHub.removeEventListener('interview', 'restore', onInterviewCreatedOrRestored);
        };
    }, [ideaId, researchId]);

    return researchInterviews;
}

export function useResearchInterviewQuotes(ideaId: string, researchId: number, type: InterviewQuoteType.Hypothesis): InterviewHypothesisQuote[] | undefined;
export function useResearchInterviewQuotes(ideaId: string, researchId: number, type: InterviewQuoteType.Insight): InterviewInsightQuote[] | undefined;
export function useResearchInterviewQuotes(ideaId: string, researchId: number): InterviewQuote[] | undefined;
export function useResearchInterviewQuotes(ideaId: string, researchId: number, type?: InterviewQuoteType): InterviewQuote[] | undefined {
    const [quotes, setQuotes] = useState<InterviewQuote[]>();

    useEffect(() => {
        researchService.getInterviewQuotesForResearch(ideaId, researchId, type).then(setQuotes);

        async function onQuoteInsertedOrRestored(e: RealTimeUpdateInterviewQuoteEventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;
            if (type === InterviewQuoteType.Hypothesis && e.hypothesisId === undefined) return;
            if (type === InterviewQuoteType.Insight && e.insightId === undefined) return;

            const quote = await researchService.getInterviewQuote(ideaId, e.quoteId);
            setQuotes(quotes => immutableAddOrUpdate(quotes, quote, q => q.id === quote.id));
        }

        function onQuoteUpdated(e: RealTimeUpdateInterviewQuoteEventData) {
            if (e.ideaId !== ideaId) return;
            if (type === InterviewQuoteType.Hypothesis && e.hypothesisId === undefined) return;
            if (type === InterviewQuoteType.Insight && e.insightId === undefined) return;

            if (e.researchId === researchId) onQuoteInsertedOrRestored(e);
            else setQuotes(quotes => immutableRemove(quotes, q => q.id !== e.quoteId));
        }

        function onQuoteDelete(e: RealTimeUpdateInterviewQuoteEventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;
            if (type === InterviewQuoteType.Hypothesis && e.hypothesisId === undefined) return;
            if (type === InterviewQuoteType.Insight && e.insightId === undefined) return;

            setQuotes(quotes => immutableRemove(quotes, q => q.id !== e.quoteId));
        }

        realTimeUpdatesEventHub.addEventListener('interview', 'quoteAdd', onQuoteInsertedOrRestored);
        realTimeUpdatesEventHub.addEventListener('interview', 'quoteUpdate', onQuoteUpdated);
        realTimeUpdatesEventHub.addEventListener('interview', 'quoteDelete', onQuoteDelete);
        realTimeUpdatesEventHub.addEventListener('interview', 'quoteRestore', onQuoteInsertedOrRestored);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview', 'quoteAdd', onQuoteInsertedOrRestored);
            realTimeUpdatesEventHub.removeEventListener('interview', 'quoteUpdate', onQuoteUpdated);
            realTimeUpdatesEventHub.removeEventListener('interview', 'quoteDelete', onQuoteDelete);
            realTimeUpdatesEventHub.removeEventListener('interview', 'quoteRestore', onQuoteInsertedOrRestored);
        };
    }, [ideaId, researchId, type]);

    return quotes;
}

export function useResearchInterviewHypothesesVerdicts(ideaId: string, researchId: number) {
    const [verdicts, setVerdicts] = useState<InterviewHypothesisVerdict[]>();

    useEffect(() => {
        researchService.getInterviewHypothesesVerdictsForResearch(ideaId, researchId).then(setVerdicts);

        async function onInterviewHypothesisVerdictUpdated(e: RealTimeUpdateInterviewHypothesisVerdictEventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;

            const updatedVerdict = await interviewsService.getInterviewHypothesesVerdict(ideaId, e.interviewId, e.hypothesisId);
            setVerdicts(verdicts =>
                immutableAddOrUpdate(
                    verdicts,
                    updatedVerdict,
                    v => v.interviewId === updatedVerdict.interviewId && v.hypothesisId === updatedVerdict.hypothesisId
                )
            );
        }

        function onInterviewHypothesisVerdictDeleted(e: RealTimeUpdateInterviewHypothesisVerdictEventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;
            setVerdicts(verdicts => immutableRemove(verdicts, v => v.interviewId !== e.interviewId || v.hypothesisId !== e.hypothesisId));
        }

        realTimeUpdatesEventHub.addEventListener('interview', 'hypothesisVerdictUpdate', onInterviewHypothesisVerdictUpdated);
        realTimeUpdatesEventHub.addEventListener('interview', 'hypothesisVerdictDelete', onInterviewHypothesisVerdictDeleted);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview', 'hypothesisVerdictUpdate', onInterviewHypothesisVerdictUpdated);
            realTimeUpdatesEventHub.removeEventListener('interview', 'hypothesisVerdictDelete', onInterviewHypothesisVerdictDeleted);
        };
    }, [ideaId, researchId]);

    return verdicts;
}

export function useResearchInsights(ideaId: string, researchId: number) {
    const [insights, setInsights] = useState<Insight[]>();

    useEffect(() => {
        insightsService.getAllInsights(ideaId, undefined, undefined, researchId).then(setInsights);

        async function onInsightAddedOrRestored(e: RealTimeUpdateInsightEventData) {
            if (e.ideaId !== ideaId) return;

            const insight = await insightsService.getInsight(ideaId, e.insightId);
            if (!insight.researchIds.includes(researchId)) return;

            setInsights(insights => immutableAddOrUpdate(insights, insight, existingInsight => existingInsight.id === e.insightId));
        }

        async function onInsightUpdated(e: RealTimeUpdateInsightEventData) {
            if (e.ideaId !== ideaId) return;

            const insight = await insightsService.getInsight(ideaId, e.insightId);
            if (insight.researchIds.includes(researchId))
                setInsights(insights => immutableAddOrUpdate(insights, insight, existingInsight => existingInsight.id === e.insightId));
            else setInsights(insights => immutableRemove(insights, insight => insight.id !== e.insightId));
        }

        function onInsightDeleted(e: RealTimeUpdateInsightEventData) {
            if (e.ideaId !== ideaId) return;

            setInsights(insights => immutableRemove(insights, insight => insight.id !== e.insightId));
        }

        realTimeUpdatesEventHub.addEventListener('insight', 'add', onInsightAddedOrRestored);
        realTimeUpdatesEventHub.addEventListener('insight', 'update', onInsightUpdated);
        realTimeUpdatesEventHub.addEventListener('insight', 'delete', onInsightDeleted);
        realTimeUpdatesEventHub.addEventListener('insight', 'restore', onInsightAddedOrRestored);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('insight', 'add', onInsightAddedOrRestored);
            realTimeUpdatesEventHub.removeEventListener('insight', 'update', onInsightUpdated);
            realTimeUpdatesEventHub.removeEventListener('insight', 'delete', onInsightDeleted);
            realTimeUpdatesEventHub.removeEventListener('insight', 'restore', onInsightAddedOrRestored);
        };
    }, [ideaId, researchId]);

    return insights;
}
