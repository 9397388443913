import { StackLayout } from '@progress/kendo-react-layout';
import { CSSProperties, Key, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getCurrencySymbol, stringifyPriceAmount } from '../../components/billing/billingDataUtils';
import { BillingPortalLink } from '../../components/billing/billingPortalLink';
import { getButtonClassName } from '../../components/common/DivButton';
import { CountryAndCityView } from '../../components/common/countries';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import Panel from '../../components/ui/panel';
import { H1, H2 } from '../../components/ui/typography';
import { usePaymentData } from '../../hooks/subscriptionHooks';
import { ReactComponent as TimerIcon } from '../../icons/trial-timer.svg';
import upgradeAccountIllustrationUrl from '../../images/upgrade-account-illustration.svg';
import verifyEmailIllustrationUrl from '../../images/verify-email-illustration.svg';
import { SubscriptionType } from '../../services/authenticationService';
import { dateTimeService } from '../../services/dateTimeService';
import {
    SubscriptionData as StripeSubscriptionData,
    SubscriptionStatus as StripeSubscriptionStatus,
    stripeBillingService
} from '../../services/stripeBillingService';
import { LicenseType, UserStatus } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

enum SubscriptionStatus {
    Active,
    ActiveTrial,
    ExpiredTrial,
    ExpiredPaid,
    NoActiveSubscriptionSpecial,
    VerifyEmail
}

type SubscriptionInfo = {
    status: SubscriptionStatus;
    trialDaysLeft?: number;
    expiredTrialDate?: Date;
};

export const ManageSubscriptionPage = () => {
    const navigate = useNavigate();
    const { pricingData, billingInfo, paymentMethod } = usePaymentData();
    const currentUser = useAppSelector(s => s.user);
    const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo | undefined>(undefined);
    const [stripeSubscriptionData, setStripeSubscriptionData] = useState<StripeSubscriptionData | undefined | null>(undefined);
    const currentLicense = currentUser?.license;
    const isLoading = !pricingData || !billingInfo || paymentMethod === undefined || !subscriptionInfo || stripeSubscriptionData === undefined;

    useEffect(() => {
        const loadStripeSubscriptionData = async () => {
            let subscription: StripeSubscriptionData;
            try {
                subscription = await stripeBillingService.getSubscription();
            } catch (e) {
                setStripeSubscriptionData(null);
                return;
            }

            setStripeSubscriptionData(subscription);
        };

        loadStripeSubscriptionData();
    }, []);

    useEffect(() => {
        if (!currentLicense || stripeSubscriptionData === undefined) return;

        if (currentUser.status === UserStatus.New) {
            setSubscriptionInfo({ status: SubscriptionStatus.VerifyEmail });
            return;
        }

        if (
            stripeSubscriptionData &&
            (stripeSubscriptionData.status === StripeSubscriptionStatus.Active ||
                stripeSubscriptionData.status === StripeSubscriptionStatus.Incomplete ||
                stripeSubscriptionData.status === StripeSubscriptionStatus.IncompleteExpired ||
                stripeSubscriptionData.status === StripeSubscriptionStatus.PastDue)
        ) {
            setSubscriptionInfo({ status: SubscriptionStatus.Active });
            return;
        }

        const currentLicenseExpirationData = new Date(currentLicense.expires);
        const isLicenseActive = currentLicenseExpirationData >= new Date();

        if (isLicenseActive && currentLicense.type === LicenseType.Trial) {
            const trialExpirationInDays = dateTimeService.getDurationInDays(new Date(), currentLicenseExpirationData);
            setSubscriptionInfo({ status: SubscriptionStatus.ActiveTrial, trialDaysLeft: trialExpirationInDays });
        } else if (!isLicenseActive && currentLicense.type === LicenseType.Trial) {
            setSubscriptionInfo({ status: SubscriptionStatus.ExpiredTrial, expiredTrialDate: currentLicenseExpirationData });
        } else if (!isLicenseActive && currentLicense.type === LicenseType.Full) {
            setSubscriptionInfo({ status: SubscriptionStatus.ExpiredPaid, expiredTrialDate: currentLicenseExpirationData });
        } else {
            setSubscriptionInfo({ status: SubscriptionStatus.NoActiveSubscriptionSpecial });
        }
    }, [currentLicense, currentUser?.status, stripeSubscriptionData]);

    const calcExpDate = useCallback(() => {
        if (!paymentMethod?.expiresMonth || !paymentMethod?.expiresYear) return '';

        return `${paymentMethod.expiresMonth.toString().padStart(2, '0')}/${paymentMethod.expiresYear.toString().slice(-2)}`;
    }, [paymentMethod?.expiresMonth, paymentMethod?.expiresYear]);

    const isPaymentMethodExpired = !!paymentMethod && stripeBillingService.isDateExpired(paymentMethod.expiresMonth, paymentMethod.expiresYear);

    return (
        <div className="page-content-section">
            <H1 className="heading-row">My Subscription</H1>

            {!isLoading && (
                <div className="page-content-middle">
                    {subscriptionInfo.status === SubscriptionStatus.ActiveTrial && <UpgradeTrialComponent trialEndDays={subscriptionInfo.trialDaysLeft!} />}
                    {subscriptionInfo.status === SubscriptionStatus.VerifyEmail && <VerifyEmailComponent />}
                    {subscriptionInfo.status !== SubscriptionStatus.ActiveTrial && subscriptionInfo.status !== SubscriptionStatus.VerifyEmail && (
                        <StackLayout orientation="vertical" className="k-gap-6">
                            {subscriptionInfo.status !== SubscriptionStatus.Active ? (
                                <ActivateSubscriptionPanel subscriptionStatus={subscriptionInfo.status} expiredTrialDate={subscriptionInfo.expiredTrialDate!} />
                            ) : (
                                <CurrentPlanPanelComponent subscriptionData={stripeSubscriptionData!} />
                            )}

                            <StackLayout orientation="vertical" className="k-gap-1">
                                <Panel
                                    invalid={isPaymentMethodExpired}
                                    title="Payment method"
                                    onEdit={() => navigate('update-payment')}
                                    editBtnSize="small"
                                    editBtnText="Update"
                                >
                                    {!paymentMethod && <span className="k-icp-panel-section k-align-items-center">No payment method added</span>}
                                    {paymentMethod && (
                                        <>
                                            <DataRow label={'Credit card'} data={paymentMethod?.last4digits ? `Ending in ${paymentMethod?.last4digits}` : ''} />
                                            <DataRow label={'Expiring'} data={calcExpDate()} />
                                        </>
                                    )}
                                </Panel>
                                {isPaymentMethodExpired && <span className="k-text-error">Your payment method has expired.</span>}
                            </StackLayout>

                            <Panel title="Billing information" onEdit={() => navigate('edit-billing')} editBtnSize="small" editBtnText="Edit">
                                {billingInfo.name && <DataRow label="Name" data={billingInfo.name} />}
                                {(billingInfo.country || billingInfo.city) && (
                                    <DataRow label="Country, City" data={<CountryAndCityView city={billingInfo.city} countryCode={billingInfo.country} />} />
                                )}
                                {billingInfo.address && <DataRow label="Address" data={billingInfo.address} />}
                                {billingInfo.customerType && <DataRow label="Customer type" data={billingInfo.customerType} />}
                                {billingInfo.taxIds &&
                                    billingInfo.taxIds.map((taxId: { type: Key | null | undefined; value: string }) => {
                                        if (taxId.type === 'bg_uic') return <DataRow key={taxId.type} label="UIC" data={taxId.value} />;
                                        else if (taxId.type === 'eu_vat') return <DataRow key={taxId.type} label="VAT number" data={taxId.value} />;
                                        else return null;
                                    })}
                            </Panel>
                        </StackLayout>
                    )}
                </div>
            )}

            {isLoading && (
                <div className="k-text-center">
                    <LoadingIndicator size="big" />
                </div>
            )}
        </div>
    );
};

const ActivateSubscriptionPanel = ({ subscriptionStatus, expiredTrialDate }: { subscriptionStatus: SubscriptionStatus; expiredTrialDate: Date }) => {
    const periodEndedMessage =
        subscriptionStatus !== SubscriptionStatus.NoActiveSubscriptionSpecial
            ? `Your ${subscriptionStatus === SubscriptionStatus.ExpiredTrial ? 'trial' : 'subscription'} ended on ${dateTimeService.stringifyToDay(
                  expiredTrialDate
              )}. `
            : '';
    return (
        <StackLayout className="k-icp-panel k-p-4 k-text-center k-gap-4" orientation="vertical" align={{ horizontal: 'center' }}>
            <TimerIcon
                className="trial-timer-icon k-icp-icon k-text-dark k-icp-icon-padded trial-timer-over"
                width="64"
                height="64"
                strokeWidth={2}
                style={{ '--progress-percentage': 100, '--icon-scale': 64 / 16 } as CSSProperties}
            />
            <div>
                <span className="k-display-block k-fs-lg k-font-weight-semibold k-mb-2">No active subscription</span>
                <p>{periodEndedMessage}Start a new subscription to continue using the Icanpreneur platform</p>
            </div>
            <Link to={'./../subscribe'} className={getButtonClassName({ themeColor: 'primary' })}>
                Start subscription
            </Link>
        </StackLayout>
    );
};

const CurrentPlanPanelComponent = ({ subscriptionData }: { subscriptionData: StripeSubscriptionData }) => {
    const isOverDue =
        subscriptionData.status === StripeSubscriptionStatus.PastDue ||
        subscriptionData.status === StripeSubscriptionStatus.Incomplete ||
        subscriptionData.status === StripeSubscriptionStatus.IncompleteExpired;

    const cancelling = subscriptionData.cancels;
    const statusMessage = cancelling
        ? `Your plan will be canceled on ${dateTimeService.stringifyToDay(subscriptionData.cancelsAt)}`
        : isOverDue
        ? 'Your payment is overdue'
        : subscriptionData.status === StripeSubscriptionStatus.Active || subscriptionData.status === StripeSubscriptionStatus.Trialing
        ? `Next payment ${dateTimeService.stringifyToDay(subscriptionData.expires)}`
        : undefined;

    const selectedInvoice = (cancelling || isOverDue) && subscriptionData.latestInvoice ? subscriptionData.latestInvoice : subscriptionData.upcomingInvoice;

    return (
        <StackLayout orientation="vertical" className="k-gap-1">
            <Panel
                title="Current plan"
                invalid={isOverDue}
                warning={cancelling}
                customAction={
                    <BillingPortalLink className={getButtonClassName({ size: 'small', fillMode: 'flat', className: '!k-min-w-0' })}>View</BillingPortalLink>
                }
            >
                <div className="k-icp-panel-section k-justify-content-between">
                    <StackLayout orientation="vertical">
                        <span className="k-d-block k-fs-lg k-font-weight-semibold">Icanpreneur Platform</span>
                        {statusMessage && <span className="k-d-block k-fs-sm">{statusMessage}</span>}
                    </StackLayout>
                    {selectedInvoice && (
                        <StackLayout orientation="vertical" align={{ horizontal: 'end' }}>
                            <span className="k-d-block k-fs-lg">
                                {getCurrencySymbol(selectedInvoice.currency)}
                                {stringifyPriceAmount(selectedInvoice.amount_due)}/{subscriptionData.type === SubscriptionType.Annual ? 'year' : 'month'}
                            </span>
                            {selectedInvoice.coupon && (
                                <span className="k-d-block k-fs-sm">
                                    {selectedInvoice.coupon.percentOff
                                        ? `${selectedInvoice.coupon.percentOff}%`
                                        : `${getCurrencySymbol(selectedInvoice.coupon.amountOffCurrency!)}${stringifyPriceAmount(
                                              selectedInvoice.coupon.amountOff!
                                          )}`}{' '}
                                    OFF coupon applied
                                </span>
                            )}
                        </StackLayout>
                    )}
                </div>
            </Panel>
            {isOverDue && (
                <span className="k-text-error">
                    Your latest payment has failed. Go to the <BillingPortalLink className={'k-text-underline'}>Payment portal.</BillingPortalLink>
                </span>
            )}
        </StackLayout>
    );
};

const UpgradeTrialComponent = ({ trialEndDays }: { trialEndDays: number }) => {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center' }} className="k-gap-6">
            <img src={upgradeAccountIllustrationUrl} width="640" height="240" alt="Upgrade account" className="responsive-image" />
            <div className="k-pt-6 k-pb-8 k-px-8 k-text-center">
                <H2 className="!k-mb-4">
                    Your trial period ends in {trialEndDays} day{trialEndDays > 1 ? 's' : ''}
                </H2>
                <p>Upgrade now to ensure uninterrupted access to Icanpreneur.</p>
            </div>
            <Link to={'./../subscribe'} className={getButtonClassName({ themeColor: 'primary', size: 'large' })}>
                Upgrade now
            </Link>
        </StackLayout>
    );
};

const VerifyEmailComponent = () => {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center' }} className="k-gap-6">
            <img src={verifyEmailIllustrationUrl} width="640" height="240" alt="Verify email" className="responsive-image" />
            <div className="k-pt-6 k-pb-8 k-px-8 k-text-center">
                <H2 className="!k-mb-4">Verify your email</H2>
                <p>Check your inbox for a message with a confirmation link. If you don't see it, you may need to check your spam folder.</p>
            </div>
        </StackLayout>
    );
};

const DataRow = ({ label, data }: { label: string; data: React.ReactNode }) => {
    return (
        <div className="k-icp-panel-section k-align-items-center">
            <div className="k-icp-panel-section-header k-label">{label}</div>
            <div className="k-flex-grow -minw0">
                <span className="k-input k-input-md k-button-clear !k-py-1">{data}</span>
            </div>
        </div>
    );
};
