import { useMemo, useRef } from 'react';
import { ideasService } from '../../services/ideasService';
import { ReducedUser, ReducedUserViewModel, UserMembershipEntry, UserRole, buildUserViewModel } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { RemoteDataComboBox } from '../common/remoteDataComboBox';
import { UserSimpleView } from '../user/userSimpleView';

type StartupMemberPickerUserViewModel = ReducedUserViewModel & { fullName: string };
function buildStartupMemberPickerUserViewModel(user: ReducedUser, isCurrent?: boolean): StartupMemberPickerUserViewModel {
    return { ...buildUserViewModel(user), fullName: `${user.firstName} ${user.lastName}${isCurrent ? ' (You)' : ''}` };
}

export function StartupMemberPicker({
    ideaId,
    value,
    onChange,
    roles,
    placeholder,
    disabled,
    valid,
    excludedUserIds,
    className
}: {
    ideaId: string;
    value?: ReducedUser | null;
    onChange?: (e: { value: ReducedUser | undefined | null }) => void;
    roles?: UserRole[];
    placeholder?: string;
    disabled?: boolean;
    valid?: boolean;
    excludedUserIds?: string[];
    className?: string;
}) {
    const membersPromise = useRef<Promise<UserMembershipEntry[]>>();
    const currentUserId = useAppSelector(s => s.user?.userId);
    const viewModelValue = useMemo(() => (value ? buildStartupMemberPickerUserViewModel(value, currentUserId === value.userId) : value), [
        value,
        currentUserId
    ]);

    function getAllMembers(): Promise<UserMembershipEntry[]> {
        if (!membersPromise.current) membersPromise.current = ideasService.getMembers(ideaId);

        return membersPromise.current;
    }

    function fetchUsers(filter: string): Promise<StartupMemberPickerUserViewModel[]> {
        return getAllMembers().then(members => {
            let filteredMembers = members;
            if (roles && roles.length) filteredMembers = filteredMembers.filter(m => roles.includes(m.role));
            if (excludedUserIds && excludedUserIds.length) filteredMembers = filteredMembers.filter(m => !excludedUserIds.includes(m.user.userId));
            if (filter)
                filteredMembers = filteredMembers.filter(user => `${user.user.firstName} ${user.user.lastName}`.toLowerCase().includes(filter.toLowerCase()));

            return filteredMembers.map(m => buildStartupMemberPickerUserViewModel(m.user, currentUserId === m.user.userId));
        });
    }

    return (
        <RemoteDataComboBox
            dataItemKey="userId"
            textField="fullName"
            placeholder={placeholder}
            disabled={disabled}
            valid={valid}
            value={viewModelValue}
            onChange={onChange}
            emptyListText="No startup members found."
            renderItem={u => <UserSimpleView user={u} isCurrent={u.userId === currentUserId} />}
            fetchItems={fetchUsers}
            className={className}
        />
    );
}
