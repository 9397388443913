import { JourneyTask, JourneyTaskBody } from '../../../services/journeyService';

export abstract class TaskLoadExtension {
    protected latestTaskData: { task: JourneyTask; body: JourneyTaskBody | undefined } | undefined;
    constructor(protected readonly ideaId: string, protected readonly loadTaskData: (updatePositionFromMarker: boolean) => Promise<void>) {}

    onTaskLoad(task: JourneyTask, body: JourneyTaskBody | undefined): void {
        this.latestTaskData = { task, body };
        this.onTaskDataChanged();
    }

    onTaskBodyLoad(body: JourneyTaskBody) {
        if (!this.latestTaskData) return;
        this.latestTaskData.body = body;
        this.onTaskDataChanged();
    }

    onUnmount() {
        this.latestTaskData = undefined;
        this.cleanup();
    }

    private onTaskDataChanged() {
        if (!this.isApplicableForTask()) this.cleanup();
        else this.onApplicableTaskDataChanged();
    }

    protected abstract isApplicableForTask(): boolean;
    protected abstract onApplicableTaskDataChanged(): void;
    protected abstract cleanup(): void;
}

export const taskLoadExtensions: (new (ideaId: string, loadTaskData: (updatePositionFromMarker: boolean) => Promise<void>) => TaskLoadExtension)[] = [];

export interface TaskData {}

export type TaskRenderExtension = (
    ideaId: string,
    task: JourneyTask | undefined,
    taskBody: JourneyTaskBody | undefined,
    taskData: TaskData,
    setTaskData: React.Dispatch<React.SetStateAction<TaskData>>
) => void;
export const taskRenderExtensions: TaskRenderExtension[] = [];
export const useTaskRenderExtensions: TaskRenderExtension = (...args) => {
    for (const renderExtension of taskRenderExtensions) {
        renderExtension(...args);
    }
};
