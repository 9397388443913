import { Button, ButtonProps } from '@progress/kendo-react-buttons';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { combineClassNames } from '../../services/common';
import { InterviewStage } from '../../services/interviewsService';
import { getButtonClassName } from '../common/DivButton';

type InterviewActionData = {
    text: string;
};

const interviewStageToActionDatMap: Record<InterviewStage, InterviewActionData> = {
    [InterviewStage.NotStarted]: {
        text: 'Conduct interview'
    },
    [InterviewStage.Started]: {
        text: 'Continue interview'
    },
    [InterviewStage.PendingReview]: {
        text: 'Review notes'
    },
    [InterviewStage.PendingInsightCapture]: {
        text: 'Capture insights'
    },
    [InterviewStage.PendingHypothesesEvaluation]: {
        text: 'Evaluate hypotheses'
    },
    [InterviewStage.Complete]: {
        text: 'Open interview summary'
    }
};

export function InterviewMainActionButton({
    interviewStage,
    ...openInterviewButtonProps
}: Omit<OpenInterviewButtonProps, 'children' | 'view'> & { interviewStage: InterviewStage }) {
    const actionData = interviewStageToActionDatMap[interviewStage];

    return (
        <OpenInterviewButton
            {...openInterviewButtonProps}
            fillMode={openInterviewButtonProps.fillMode ?? 'outline'}
            themeColor={openInterviewButtonProps.themeColor ?? 'secondary'}
        >
            {actionData.text}
        </OpenInterviewButton>
    );
}

type OpenInterviewButtonProps = {
    ideaId: string;
    interviewId?: number;
    disabled?: boolean;
    children?: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    showGuidanceOnOpen?: boolean;
    view?: InterviewStage;
    className?: string;
    additionalParameters?: InterviewLinkAdditionalParameters;
    replaceHistory?: boolean;
} & Pick<ButtonProps, 'fillMode' | 'themeColor' | 'size'>;
export function OpenInterviewButton({
    ideaId,
    interviewId,
    fillMode,
    themeColor,
    size,
    disabled,
    children,
    onClick,
    showGuidanceOnOpen,
    view,
    className,
    additionalParameters,
    replaceHistory
}: OpenInterviewButtonProps) {
    const isDisabled = disabled || interviewId === undefined;

    if (isDisabled)
        return (
            <Button type="button" fillMode={fillMode} themeColor={themeColor} size={size} disabled={true}>
                {children}
            </Button>
        );

    return (
        <Link
            className={combineClassNames(getButtonClassName({ fillMode, themeColor, size }), className)}
            to={resolveInterviewLink(ideaId, interviewId, view, showGuidanceOnOpen, additionalParameters)}
            onClick={onClick}
            replace={replaceHistory}
        >
            {children}
        </Link>
    );
}

type InterviewLinkAdditionalParameters = ConstructorParameters<typeof URLSearchParams>[0];
export function resolveInterviewLink(
    ideaId: string,
    interviewId: number,
    view?: InterviewStage,
    showGuidanceOnOpen?: boolean,
    additionalParameters?: InterviewLinkAdditionalParameters
) {
    const linkParams = new URLSearchParams(additionalParameters);
    if (showGuidanceOnOpen) linkParams.append('showGuidance', 'true');
    if (view) linkParams.append('view', view);

    const queryString = linkParams.toString();

    return `/startups/${ideaId}/interviews/${interviewId}${queryString ? `?${linkParams.toString()}` : ''}`;
}
