import { appConfig } from '../config';
import { ErrorWithOperationDisplayName } from './common';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { LocationOption } from './schedulesService';

export type PublicMeetingProposal = {
    title: string;
    description?: string;
    locationOptions: LocationOption[];
    durationMinutes: number;
    organizerFullName: string;
};

export type AcceptMeetingProposalData = {
    startTime: Date;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    location: LocationOption;
};

class PublicMeetingProposalsService extends HttpServiceBase {
    constructor() {
        super('/api/scheduling/meeting-proposals');
    }

    @ErrorWithOperationDisplayName('Get meeting proposal')
    getMeetingProposal(code: string): Promise<PublicMeetingProposal> {
        return this.performRequest({
            path: `/${code}`
        });
    }

    @ErrorWithOperationDisplayName('Get meeting proposal slots')
    getMeetingProposalAvailableSlots(code: string): Promise<Date[]> {
        return this.performRequest<string[]>({
            path: `/${code}/slots`
        }).then(slots => slots.map(s => new Date(s)));
    }

    @ErrorWithOperationDisplayName('Accept meeting proposal')
    acceptMeetingProposal(code: string, data: AcceptMeetingProposalData, recaptchaToken?: string | null): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${code}/accept`,
            method: RequestMethod.POST,
            body: data,
            headers: recaptchaToken ? { [appConfig.recaptchaTokenHeaderName]: recaptchaToken } : undefined
        });
    }
}

export const publicMeetingProposalsService = new PublicMeetingProposalsService();
