import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IdeasInfoTips = Record<string, string[] | undefined>;
export type IdeaInfoTip = { ideaId: string; infoTip: string };

const activeInfoTipsKey = 'icpActiveInfoTips';
const defaultInfoTips: IdeasInfoTips = {};
function readInfoTipsFromStorage() {
    const serializedInfoTIps = localStorage.getItem(activeInfoTipsKey);
    if (!serializedInfoTIps) return defaultInfoTips;

    const infoTips: IdeasInfoTips = JSON.parse(serializedInfoTIps);

    return infoTips;
}

function storeInfoTipsInStorage(infoTips: IdeasInfoTips | undefined) {
    if (!infoTips || Object.keys(infoTips).length === 0) localStorage.removeItem(activeInfoTipsKey);
    else localStorage.setItem(activeInfoTipsKey, JSON.stringify(infoTips));
}

const initialValue = readInfoTipsFromStorage();

const infoTipsSlice = createSlice({
    name: 'infoTips',
    initialState: initialValue,
    reducers: {
        activateInfoTip(state, action: PayloadAction<IdeaInfoTip>) {
            let activeInfoTipsForIdea = state[action.payload.ideaId];
            if (!activeInfoTipsForIdea) activeInfoTipsForIdea = state[action.payload.ideaId] = [];
            if (!activeInfoTipsForIdea.includes(action.payload.infoTip)) activeInfoTipsForIdea.push(action.payload.infoTip);

            storeInfoTipsInStorage(state);
        },
        deactivateInfoTip(state, action: PayloadAction<IdeaInfoTip>) {
            let activeInfoTipsForIdea = state[action.payload.ideaId];
            if (!activeInfoTipsForIdea) return;

            const activeInfoTipIndex = activeInfoTipsForIdea.indexOf(action.payload.infoTip);
            if (activeInfoTipIndex === -1) return;

            activeInfoTipsForIdea.splice(activeInfoTipIndex, 1);
            if (!activeInfoTipsForIdea.length) delete state[action.payload.ideaId];

            storeInfoTipsInStorage(state);
        }
    }
});

export const { activateInfoTip, deactivateInfoTip } = infoTipsSlice.actions;

export default infoTipsSlice.reducer;
