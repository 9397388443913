import { Button } from '@progress/kendo-react-buttons';
import { AutoComplete, AutoCompleteHandle } from '@progress/kendo-react-dropdowns';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as AddIcon } from '../../icons/plus.svg';
import { combineClassNames } from '../../services/common';
import { Person, contactsService } from '../../services/contactsService';
import { researchService } from '../../services/researchService';
import { NewItemButtonDropDownFooter, itemRenderFromCallback, listNoDataRenderWithText, useFilterItems } from '../common/dropDownCommon';
import { CreatePersonDialog } from '../contacts/edit';
import { PersonSimpleView } from '../contacts/view';
import { SvgIconButtonContent } from '../ui/svgIconButtonContent';

export function ResearchContactPicker({
    ideaId,
    researchId,
    customerSegmentId,
    onSelected,
    disabled,
    valid,
    className,
    showAddButton,
    autoFocus
}: {
    ideaId: string;
    researchId: number;
    customerSegmentId?: number;
    onSelected?: (contact: Person) => void;
    disabled?: boolean;
    valid?: boolean;
    className?: string;
    showAddButton?: boolean;
    autoFocus?: boolean;
}) {
    const autoCompleteRef = useRef<AutoCompleteHandle>(null);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);
    const [contactsFilter, setContactsFilter] = useState('');

    const fetchItems = useCallback((filter: string) => researchService.getRemainingContacts(ideaId, researchId, filter, 5).then(r => r), [ideaId, researchId]);
    const [contacts, isLoading, filterItems] = useFilterItems(fetchItems);

    function onAddNewContact() {
        setIsAutoCompleteOpen(false);
        setShowCreateDialog(true);
    }

    function openAutoComplete() {
        setIsAutoCompleteOpen(true);
        filterItems(contactsFilter, true);
    }

    function onContactSelected(contact: Person) {
        setContactsFilter('');
        setIsAutoCompleteOpen(false);
        onSelected?.(contact);
    }

    useEffect(() => {
        if (!autoFocus || !autoCompleteRef.current) return;

        autoCompleteRef.current.focus();
    }, [autoFocus]);

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className={combineClassNames('k-gap-6', className)}>
            <AutoComplete
                ref={autoCompleteRef}
                value={contactsFilter}
                onChange={e => {
                    if (typeof e.value === 'string') {
                        setContactsFilter(e.value);
                        filterItems(e.value);
                        return;
                    }

                    onContactSelected(e.value);
                }}
                data={contacts}
                dataItemKey="id"
                placeholder="Search for contact..."
                className="k-flex-1"
                disabled={disabled}
                loading={isLoading}
                opened={isAutoCompleteOpen}
                onOpen={openAutoComplete}
                onClose={e => {
                    // Do not automatically close the autocomplete when the value is empty and the element is focused
                    if (!e.target.value && e.target.element && e.target.element.contains(document.activeElement)) return;
                    setIsAutoCompleteOpen(false);
                }}
                onFocus={openAutoComplete}
                listNoDataRender={listNoDataRenderWithText('No contacts outside of the research found.', isLoading)}
                footer={<NewItemButtonDropDownFooter newItemText="New contact" onClick={onAddNewContact} />}
                itemRender={itemRenderFromCallback<Person>(
                    p => (
                        <PersonSimpleView
                            className="k-flex-1"
                            person={p}
                            showJobTitle={true}
                            additionalContent={
                                <Button size="small" fillMode="outline" themeColor="secondary" className="add-research-contact-btn">
                                    Add to Selected
                                </Button>
                            }
                        />
                    ),
                    'research-contact-list-item'
                )}
                focusedItemIndex={(data: Person[] | undefined, filter) =>
                    data ? data.findIndex(p => `${p.firstName} ${p.lastName}`.toLowerCase().includes(filter.toLowerCase())) : -1
                }
                valid={valid}
            />

            {showAddButton && (
                <>
                    or
                    <Button themeColor="secondary" fillMode="outline" onClick={onAddNewContact} disabled={disabled}>
                        <SvgIconButtonContent icon={AddIcon}>Add new contact</SvgIconButtonContent>
                    </Button>
                </>
            )}
            {showCreateDialog && (
                <CreatePersonDialog
                    ideaId={ideaId}
                    onCancel={() => setShowCreateDialog(false)}
                    initialData={{
                        ...contactsService.parsePersonNamesFromString(contactsFilter),
                        customerSegmentIds: customerSegmentId !== undefined ? [customerSegmentId] : undefined
                    }}
                    onCreated={newPerson => {
                        setShowCreateDialog(false);
                        onContactSelected(newPerson);
                    }}
                />
            )}
        </StackLayout>
    );
}
