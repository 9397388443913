import { ActivityOf, ActivityType } from './activitiesService';
import { ErrorWithOperationDisplayName } from './common';
import { Person, ReducedPerson } from './contactsService';
import { dateTimeService } from './dateTimeService';
import { ReachOutConnectionType, ReachOutType, ResearchReachOut, ResearchReachOutData, eventDateFieldsParser } from './events';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { HypothesisGroup } from './hypothesesService';
import { InterviewScript } from './interviewScriptsService';
import { Interview, InterviewEntry, InterviewHypothesisVerdict } from './interviewsService';

export enum ResearchType {
    ProblemValidation = 'ProblemValidation'
}

export type ReducedResearch = {
    id: number;
    type: ResearchType;
    title: string;
    customerSegmentId: number;
};

export type MinimalProblemValidationResearch = ReducedResearch & {
    startDate?: Date;
    endDate?: Date;
    jobToBeDoneId?: number;
};

export type ProblemValidationResearch = MinimalProblemValidationResearch & {
    hypothesisIds?: number[];
    interviewScriptId?: number;
    scheduleIds?: number[];
};

export type ResearchContact = {
    contact: Person;
    stage: ResearchContactStage;
    reachOutActivity?: ActivityOf<ActivityType.ResearchReachOut> | null;
    meetingActivity?: ActivityOf<ActivityType.Meeting> | null;
    interview?: Interview | null;
};

export enum ResearchContactStage {
    NotInvited = 'NotInvited',
    InvitePending = 'InvitePending',
    Invited = 'Invited',
    NotInterviewed = 'NotInterviewed',
    InterviewPending = 'InterviewPending',
    Interviewed = 'Interviewed',
    Reviewed = 'Reviewed',
    InsightsCaptured = 'InsightsCaptured',
    Complete = 'Complete'
}

export type ResearchList = {
    research: MinimalProblemValidationResearch[];
    totalCount: number;
};

export type ResearchUpdateData = {
    title: string;
    startDate: Date | null;
    endDate: Date | null;
    jobToBeDoneId: number | null;
    interviewScriptId: number | null;
};

type InterviewQuoteData = {
    interviewId: number;
    entryId: number;
    fromPosition: number;
    toPosition: number;
};

export type InterviewHypothesisQuoteData = InterviewQuoteData & {
    hypothesisId: number;
};

export type InterviewInsightQuoteData = InterviewQuoteData & {
    insightId: number;
};

export enum InterviewQuoteType {
    Hypothesis = 'Hypothesis',
    Insight = 'Insight'
}

export type InviteContentSectionDetail = {
    key: string;
    label: string;
    htmlContent: string;
};

export type BaseResearchReachOutTemplate = {
    connectionType: ReachOutConnectionType;
};

export type MessageReachOutTemplate = BaseResearchReachOutTemplate & {
    reachOutType: ReachOutType.LinkedIn | ReachOutType.Other;
    message: string;
};

export type EmailMessageReachOutTemplate = BaseResearchReachOutTemplate & {
    reachOutType: ReachOutType.Email;
    subject: string;
    message: string;
};

export type PhoneCallReachOutTemplate = BaseResearchReachOutTemplate & {
    reachOutType: ReachOutType.Phone;
    callScript: string;
    followUpSubject: string;
    followUpMessage: string;
};

export type ResearchReachOutTemplate = MessageReachOutTemplate | EmailMessageReachOutTemplate | PhoneCallReachOutTemplate;

type InterviewQuoteBase = {
    id: number;
    interviewId: number;
    entry: InterviewEntry;
    fromPosition: number;
    toPosition: number;
    interviewContact: ReducedPerson;
};

export type InterviewHypothesisQuote = InterviewQuoteBase & {
    type: InterviewQuoteType.Hypothesis;
    hypothesisId: number;
};

export type InterviewInsightQuote = InterviewQuoteBase & {
    type: InterviewQuoteType.Insight;
    insightId: number;
};

export type InterviewQuote = InterviewHypothesisQuote | InterviewInsightQuote;

export type HypothesisLikelihoodData = {
    research: ReducedResearch;
    calculatedLikelihood: number;
    affirmativeResults: number;
    negativeResults: number;
    inconclusiveResults: number;
};

export const researchContactStageOrder: Record<ResearchContactStage, number> = {
    [ResearchContactStage.NotInvited]: 1,
    [ResearchContactStage.InvitePending]: 2,
    [ResearchContactStage.Invited]: 3,
    [ResearchContactStage.NotInterviewed]: 4,
    [ResearchContactStage.InterviewPending]: 5,
    [ResearchContactStage.Interviewed]: 6,
    [ResearchContactStage.Reviewed]: 7,
    [ResearchContactStage.InsightsCaptured]: 8,
    [ResearchContactStage.Complete]: 9
};

class ResearchService extends HttpServiceBase {
    constructor() {
        super('/api/research');
    }

    private static ensureDateTypes(research: MinimalProblemValidationResearch) {
        dateTimeService.ensureDateField(research, 'startDate');
        dateTimeService.ensureDateField(research, 'endDate');

        return research;
    }

    private static ensureResearchContactDateTypes(contact: ResearchContact) {
        if (contact.reachOutActivity) ResearchService.ensureResearchReachOutDateTypes(contact.reachOutActivity.researchReachOut);
        if (contact.meetingActivity) eventDateFieldsParser.Meeting(contact.meetingActivity.meeting);
        return contact;
    }

    private static ensureResearchReachOutDateTypes(reachOut: ResearchReachOut) {
        eventDateFieldsParser.ResearchReachOut(reachOut);

        return reachOut;
    }

    @ErrorWithOperationDisplayName('Create interview script')
    async createInterviewScript(ideaId: string, researchId: number): Promise<InterviewScript> {
        const newScript = await this.performRequest<InterviewScript>({
            path: `/${ideaId}/problem-validation/${researchId}/interview-script`,
            method: RequestMethod.POST
        });

        dateTimeService.ensureDateType(i => i, 'createdOn', newScript);
        return newScript;
    }

    @ErrorWithOperationDisplayName('Get interview quotes for a given hypothesis')
    getInterviewQuotesForHypothesis(ideaId: string, hypothesisId: number): Promise<InterviewHypothesisQuote[]> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'hypothesisId', hypothesisId.toString());
        return this.performRequest({
            path: `/${ideaId}/data/problem-validation/interview-quotes`,
            queryParams: queryParams
        });
    }

    getInterviewQuotesForInterview(ideaId: string, interviewId: number, type: InterviewQuoteType.Hypothesis): Promise<InterviewHypothesisQuote[]>;
    getInterviewQuotesForInterview(ideaId: string, interviewId: number, type: InterviewQuoteType.Insight): Promise<InterviewInsightQuote[]>;
    getInterviewQuotesForInterview(ideaId: string, interviewId: number, type?: InterviewQuoteType): Promise<InterviewQuote[]>;
    @ErrorWithOperationDisplayName('Get interview quotes for a given interview')
    getInterviewQuotesForInterview(ideaId: string, interviewId: number, type?: InterviewQuoteType): Promise<InterviewQuote[]> {
        const queryParams: URLSearchParams = new URLSearchParams();
        queryParams.append('interviewId', interviewId.toString());
        this.addQueryParamIfPresent(queryParams, 'type', type?.toString());
        return this.performRequest({
            path: `/${ideaId}/data/problem-validation/interview-quotes`,
            queryParams
        });
    }

    getInterviewQuotesForResearch(ideaId: string, researchId: number, type: InterviewQuoteType.Hypothesis): Promise<InterviewHypothesisQuote[]>;
    getInterviewQuotesForResearch(ideaId: string, researchId: number, type: InterviewQuoteType.Insight): Promise<InterviewInsightQuote[]>;
    getInterviewQuotesForResearch(ideaId: string, researchId: number, type?: InterviewQuoteType): Promise<InterviewQuote[]>;
    @ErrorWithOperationDisplayName('Get interview quotes for a given research')
    getInterviewQuotesForResearch(ideaId: string, researchId: number, type?: InterviewQuoteType): Promise<InterviewQuote[]> {
        const queryParams: URLSearchParams = new URLSearchParams();
        queryParams.append('researchId', researchId.toString());
        this.addQueryParamIfPresent(queryParams, 'type', type?.toString());
        return this.performRequest({
            path: `/${ideaId}/data/problem-validation/interview-quotes`,
            queryParams
        });
    }

    @ErrorWithOperationDisplayName('Get a specific quote')
    getInterviewQuote(ideaId: string, quoteId: number): Promise<InterviewQuote> {
        return this.performRequest({
            path: `/${ideaId}/data/problem-validation/interview-quotes/${quoteId}`
        });
    }

    @ErrorWithOperationDisplayName('Create interview hypothesis quote')
    createInterviewHypothesisQuote(ideaId: string, data: InterviewHypothesisQuoteData): Promise<InterviewHypothesisQuote> {
        return this.performRequest({
            path: `/${ideaId}/data/problem-validation/interview-quotes/hypothesis`,
            body: data,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Create interview hypothesis quote')
    createInterviewInsightQuote(ideaId: string, data: InterviewInsightQuoteData): Promise<InterviewInsightQuote> {
        return this.performRequest({
            path: `/${ideaId}/data/problem-validation/interview-quotes/insight`,
            body: data,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Update a specific quote')
    updateInterviewQuote(ideaId: string, quoteId: number, fromPosition: number, toPosition: number): Promise<InterviewQuote> {
        return this.performRequest({
            path: `/${ideaId}/data/problem-validation/interview-quotes/${quoteId}`,
            body: {
                fromPosition: fromPosition,
                toPosition: toPosition
            },
            method: RequestMethod.PUT
        });
    }

    @ErrorWithOperationDisplayName('Delete a specific quote in a given interview instance')
    deleteInterviewQuote(ideaId: string, quoteId: number): Promise<void> {
        return this.performRequest<void>({
            path: `/${ideaId}/data/problem-validation/interview-quotes/${quoteId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore quote')
    restoreInterviewQuote(ideaId: string, quoteId: number): Promise<InterviewQuote> {
        return this.performRequest({
            path: `/${ideaId}/data/problem-validation/interview-quotes/${quoteId}/restore`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Get likelihood research data for a given hypothesis')
    getLikelihoodResearchDataPerHypothesis(ideaId: string, hypothesisGroup: HypothesisGroup, hypothesisId: number): Promise<HypothesisLikelihoodData[]> {
        return this.performRequest<HypothesisLikelihoodData[]>({
            path: `/${ideaId}/data/problem-validation/interview-quotes/hypothesis-likelihood/${hypothesisGroup}/${hypothesisId}`
        });
    }

    @ErrorWithOperationDisplayName('Get problem validation research')
    getProblemValidationResearch(ideaId: string, researchId: number): Promise<ProblemValidationResearch> {
        return this.performRequest<ProblemValidationResearch>({
            path: `/${ideaId}/problem-validation/${researchId}`
        }).then(ResearchService.ensureDateTypes);
    }

    @ErrorWithOperationDisplayName('Update problem validation research')
    partiallyUpdateProblemValidationResearch(ideaId: string, researchId: number, data: Partial<ResearchUpdateData>) {
        return this.performRequest<ProblemValidationResearch>({
            path: `/${ideaId}/problem-validation/${researchId}`,
            method: RequestMethod.PATCH,
            body: data
        }).then(ResearchService.ensureDateTypes);
    }

    @ErrorWithOperationDisplayName('Update problem validation research hypotheses')
    updateProblemValidationResearchHypotheses(ideaId: string, researchId: number, idsToAdd?: number[], idsToRemove?: number[]): Promise<number[]> {
        return this.performRequest({
            path: `/${ideaId}/problem-validation/${researchId}/hypotheses/update`,
            method: RequestMethod.POST,
            body: {
                idsToAdd: idsToAdd ?? [],
                idsToRemove: idsToRemove ?? []
            }
        });
    }

    @ErrorWithOperationDisplayName('Get problem validation research items')
    getProblemValidationResearchItems(ideaId: string, skip?: number, take?: number, search?: string, customerSegmentId?: number): Promise<ResearchList> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());
        this.addQueryParamIfPresent(queryParams, 'search', search?.toString());
        this.addQueryParamIfPresent(queryParams, 'customerSegmentId', customerSegmentId?.toString());

        return this.performRequest<ResearchList>({
            path: `/${ideaId}/problem-validation`,
            queryParams
        }).then(researchList => {
            researchList.research.forEach(ResearchService.ensureDateTypes);
            return researchList;
        });
    }

    @ErrorWithOperationDisplayName('Get contacts')
    async getRemainingContacts(ideaId: string, researchId: number, filter?: string, take?: number): Promise<Person[]> {
        const queryParams: URLSearchParams = new URLSearchParams();

        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());
        this.addQueryParamIfPresent(queryParams, 'search', filter);

        const people = await this.performRequest<Person[]>({
            path: `/${ideaId}/problem-validation/${researchId}/remaining-contacts`,
            queryParams: queryParams
        });

        dateTimeService.ensureDateType(i => i, 'birthDate', ...people);
        dateTimeService.ensureDateType(i => i, 'createdOn', ...people);
        dateTimeService.ensureDateType(i => i, 'updatedOn', ...people);

        return people;
    }

    @ErrorWithOperationDisplayName('Get problem validation contacts')
    getProblemValidationResearchContacts(ideaId: string, researchId: number): Promise<ResearchContact[]> {
        return this.performRequest<ResearchContact[]>({
            path: `/${ideaId}/problem-validation/${researchId}/contacts`
        }).then(contacts => {
            contacts.forEach(ResearchService.ensureResearchContactDateTypes);
            return contacts;
        });
    }

    @ErrorWithOperationDisplayName('Get problem validation contact')
    getProblemValidationResearchContact(ideaId: string, researchId: number, contactId: number): Promise<ResearchContact> {
        return this.performRequest<ResearchContact>({
            path: `/${ideaId}/problem-validation/${researchId}/contacts/${contactId}`
        }).then(ResearchService.ensureResearchContactDateTypes);
    }

    @ErrorWithOperationDisplayName('Update problem validation contacts')
    updateProblemValidationResearchContacts(
        ideaId: string,
        researchId: number,
        contactIdsToAdd?: number[],
        contactIdsToRemove?: number[]
    ): Promise<ResearchContact[]> {
        return this.performRequest<ResearchContact[]>({
            path: `/${ideaId}/problem-validation/${researchId}/contacts/update`,
            method: RequestMethod.POST,
            body: {
                idsToAdd: contactIdsToAdd ?? [],
                idsToRemove: contactIdsToRemove ?? []
            }
        }).then(contacts => {
            contacts.forEach(ResearchService.ensureResearchContactDateTypes);
            return contacts;
        });
    }

    @ErrorWithOperationDisplayName('Get problem validation reach out')
    getProblemValidationResearchReachOut(ideaId: string, researchId: number, reachOutId: number): Promise<ResearchReachOut> {
        return this.performRequest<ResearchReachOut>({
            path: `/${ideaId}/problem-validation/${researchId}/reach-outs/${reachOutId}`
        }).then(ResearchService.ensureResearchReachOutDateTypes);
    }

    @ErrorWithOperationDisplayName('Create problem validation reach out')
    createProblemValidationResearchReachOut(ideaId: string, researchId: number, contactId: number, data: ResearchReachOutData): Promise<ResearchReachOut> {
        return this.performRequest<ResearchReachOut>({
            path: `/${ideaId}/problem-validation/${researchId}/reach-outs`,
            method: RequestMethod.POST,
            body: {
                contactId: contactId,
                ...data
            }
        }).then(ResearchService.ensureResearchReachOutDateTypes);
    }

    @ErrorWithOperationDisplayName('Update problem validation reach out')
    partiallyUpdateProblemValidationResearchReachOut(
        ideaId: string,
        researchId: number,
        reachOutId: number,
        data: Partial<ResearchReachOutData>
    ): Promise<ResearchReachOut> {
        return this.performRequest<ResearchReachOut>({
            path: `/${ideaId}/problem-validation/${researchId}/reach-outs/${reachOutId}`,
            method: RequestMethod.PATCH,
            body: data
        }).then(ResearchService.ensureResearchReachOutDateTypes);
    }

    @ErrorWithOperationDisplayName('Get research reach out templates')
    getResearchReachOutTemplates(ideaId: string, researchId: number): Promise<ResearchReachOutTemplate[]> {
        return this.performRequest<ResearchReachOutTemplate[]>({
            path: `/${ideaId}/problem-validation/${researchId}/reach-out-templates`
        });
    }

    @ErrorWithOperationDisplayName('Delete problem validation reach out')
    deleteProblemValidationResearchReachOut(ideaId: string, researchId: number, reachOutId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/problem-validation/${researchId}/reach-outs/${reachOutId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Get interview hypotheses verdicts')
    getInterviewHypothesesVerdictsForResearch(ideaId: string, researchId: number): Promise<InterviewHypothesisVerdict[]> {
        const queryParams: URLSearchParams = new URLSearchParams();
        queryParams.append('researchId', researchId.toString());

        return this.performRequest({
            path: `/${ideaId}/data/problem-validation/hypothesis-verdicts`,
            queryParams
        });
    }
}

export const researchService = new ResearchService();
