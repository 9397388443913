import { Card, CardBody, CardHeader, CardSubtitle, StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, ReactElement } from 'react';
import { ReactComponent as CompanyIcon } from '../../icons/briefcase.svg';
import { ReactComponent as GlobeIcon } from '../../icons/globe.svg';
import { ReactComponent as MailIcon } from '../../icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as UsersIcon } from '../../icons/users.svg';
import { combineClassNames, generateInitials, getPreferredColorIndex } from '../../services/common';
import { Company, Person } from '../../services/contactsService';
import UserAvatar from '../user/userAvatar';

export function PersonCard({ person }: { person: Person }) {
    const details: ReactElement[] = [];
    if (person.emailAddress)
        details.push(
            <ContactCardDetail key="email" Icon={MailIcon}>
                {person.emailAddress}
            </ContactCardDetail>
        );

    if (person.phoneNumber)
        details.push(
            <ContactCardDetail key="phone" Icon={PhoneIcon}>
                {person.phoneNumber}
            </ContactCardDetail>
        );

    if (person.companyName)
        details.push(
            <ContactCardDetail key="company" Icon={CompanyIcon}>
                {person.companyName}
            </ContactCardDetail>
        );

    return (
        <ContactCard
            avatar={
                <UserAvatar
                    picture={person.picture}
                    initials={generateInitials(2, person.firstName, person.lastName)}
                    colorIndex={getPreferredColorIndex(person.id)}
                />
            }
            title={person.name}
            subTitle={person.jobTitle}
            detailsElements={details}
        />
    );
}

export function CompanyCard({ company }: { company: Company }) {
    const details: ReactElement[] = [];
    if (company.website)
        details.push(
            <ContactCardDetail key="website" Icon={GlobeIcon}>
                {company.website}
            </ContactCardDetail>
        );

    const hasContactsInCompany = (company.peopleCount ?? 0) > 0;
    details.push(
        <ContactCardDetail key="contacts" Icon={UsersIcon}>
            <span className={hasContactsInCompany ? undefined : 'k-icp-subtle-text'}>
                {hasContactsInCompany ? (company.peopleCount === 1 ? '1 contact' : `${company.peopleCount} contacts`) : 'No contacts yet'}
            </span>
        </ContactCardDetail>
    );

    const companyNameParts = company.name.split(' ');

    return (
        <ContactCard
            avatar={
                <UserAvatar
                    picture={company.picture}
                    initials={generateInitials(2, companyNameParts[0], companyNameParts[1])}
                    colorIndex={getPreferredColorIndex(company.id)}
                />
            }
            title={company.name}
            detailsElements={details}
        />
    );
}

function ContactCard({
    avatar,
    title,
    subTitle,
    detailsElements
}: {
    avatar: ReactElement;
    title: string;
    subTitle?: string;
    detailsElements?: (ReactElement | undefined)[];
}) {
    const hasDetails = detailsElements && detailsElements.length > 0;

    return (
        <Card className="k-icp-interactive-card k-gap-3">
            <CardHeader className={combineClassNames('!k-border-bottom-0', hasDetails ? '!k-pb-0' : undefined)}>
                <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                    {avatar}

                    <div>
                        <strong className="k-fs-lg">{title}</strong>
                        {subTitle && <CardSubtitle className="!k-mt-0">{subTitle}</CardSubtitle>}
                    </div>
                </StackLayout>
            </CardHeader>
            {hasDetails && (
                <>
                    <div className="k-px-4">
                        <div className="full-horizontal-separator" />
                    </div>
                    <CardBody className="!k-pt-0">
                        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1">
                            {detailsElements}
                        </StackLayout>
                    </CardBody>
                </>
            )}
        </Card>
    );
}

function ContactCardDetail({ Icon, children }: { Icon: ComponentType<React.SVGProps<SVGSVGElement>>; children?: ReactElement | string }) {
    if (!children) return null;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Icon className="k-icp-icon k-icp-icon-size-4" />
            <span>{children}</span>
        </StackLayout>
    );
}
