import { ReducedPerson } from './contactsService';
import { dateTimeService } from './dateTimeService';
import { ReducedInterview } from './interviewsService';
import { ReducedResearch } from './researchService';
import { Schedule } from './schedulesService';
import { ReducedUser } from './usersService';

export type EventDetailsBase = { id: number };

export type MeetingData = {
    toEmailAddress: string;
    title: string;
    description?: string | null;
    meetingNotes?: string | null;
    startTime: Date;
    endTime: Date;
    allDay?: boolean;
    notifyAttendees?: boolean;
    location?: string | null;
    contactId: number;
};

export type Meeting = EventDetailsBase &
    MeetingData & { user: ReducedUser; contact: ReducedPerson; research?: ReducedResearch | null; interview: ReducedInterview | null };
export type MinimalMeeting = Omit<Meeting, 'toEmailAddress' | 'meetingNotes' | 'notifyAttendees' | 'contactId'>;

export type NoteData = {
    text: string;
    date: Date;
};

export type Note = EventDetailsBase & NoteData & { user: ReducedUser; contact: ReducedPerson };

export enum ReachOutType {
    Email = 'Email',
    Phone = 'Phone',
    LinkedIn = 'LinkedIn',
    Other = 'Other'
}

export type ReachOutData = {
    topic: string;
    details?: string | null;
    date: Date;
    type: ReachOutType;
};

export type ReachOut = EventDetailsBase & ReachOutData & { user: ReducedUser; contact: ReducedPerson };

export type ScheduleSlot = {
    startTime: Date;
    endTime: Date;
};

export type OpenSlot = {
    schedule: Schedule;
    slot: ScheduleSlot;
};

export enum ReachOutConnectionType {
    Direct = 'Direct',
    Referred = 'Referred',
    Cold = 'Cold'
}

export type ResearchReachOutData = {
    date: Date;
    reachOutType?: ReachOutType | null;
    connectionType?: ReachOutConnectionType | null;
    sent?: boolean;
};

export type ResearchReachOut = EventDetailsBase &
    ResearchReachOutData & {
        research: ReducedResearch;
        user?: ReducedUser | null;
        contact: ReducedPerson;
    };

function noteDateFieldsParser(note: Note) {
    dateTimeService.ensureDateField(note, 'date');
}

function reachOutDateFieldsParser(reachOut: ReachOut) {
    dateTimeService.ensureDateField(reachOut, 'date');
}

function meetingDateFieldsParser(meeting: Meeting) {
    dateTimeService.ensureDateField(meeting, 'startTime');
    dateTimeService.ensureDateField(meeting, 'endTime');

    if (meeting.allDay) {
        meeting.startTime = new Date(meeting.startTime.getUTCFullYear(), meeting.startTime.getUTCMonth(), meeting.startTime.getUTCDate(), 0, 0, 0, 0);
        meeting.endTime = new Date(meeting.endTime.getUTCFullYear(), meeting.endTime.getUTCMonth(), meeting.endTime.getUTCDate(), 23, 59, 59, 999);
    }
}

function openSlotDateFieldsParser(openSlot: OpenSlot) {
    dateTimeService.ensureDateField(openSlot.schedule, 'startTime');
    dateTimeService.ensureDateField(openSlot.schedule, 'endTime');
    dateTimeService.ensureDateField(openSlot.slot, 'startTime');
    dateTimeService.ensureDateField(openSlot.slot, 'endTime');
}

function researchReachOutDateFieldsParser(researchReachOut: ResearchReachOut) {
    dateTimeService.ensureDateField(researchReachOut, 'date');
}

export const eventDateFieldsParser = {
    note: noteDateFieldsParser,
    reachOut: reachOutDateFieldsParser,
    ReachOut: reachOutDateFieldsParser,
    meeting: meetingDateFieldsParser,
    Meeting: meetingDateFieldsParser,
    OpenSlot: openSlotDateFieldsParser,
    researchReachOut: researchReachOutDateFieldsParser,
    ResearchReachOut: researchReachOutDateFieldsParser
};
