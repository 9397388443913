import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';

export enum EditorTwoColumnLayoutMode {
    EqualColumns,
    FirstMainColumn
}

export function EditorTwoColumnLayout({
    firstColumn,
    secondColumn,
    mode = EditorTwoColumnLayoutMode.EqualColumns
}: {
    firstColumn: ReactNode;
    secondColumn: ReactNode;
    mode?: EditorTwoColumnLayoutMode;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-gap-6">
            <div className="k-flex-1">{firstColumn}</div>
            <div className={mode === EditorTwoColumnLayoutMode.EqualColumns ? 'k-flex-1' : 'k-flex-initial k-align-self-end'}>{secondColumn}</div>
        </StackLayout>
    );
}
