import { StackLayout } from '@progress/kendo-react-layout';
import emptyHypothesesIllustrationUrl from '../../images/empty-hypotheses-illustration.svg';
import { H2 } from '../ui/typography';

export function EmptyHypothesesView({ hypothesesGroupName, assumptionsDescriptions }: { hypothesesGroupName: string; assumptionsDescriptions: string }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1 k-gap-8">
            <img src={emptyHypothesesIllustrationUrl} width="560" height="109" alt="Empty hypotheses list" />
            <div className="page-content-middle -w2 k-text-center">
                <H2 className="!k-mb-4">No {hypothesesGroupName} hypotheses found</H2>
                <div className="k-fs-lg">Express the underlying assumptions related to {assumptionsDescriptions} as testable hypotheses.</div>
            </div>
        </StackLayout>
    );
}
