import { ComponentType } from 'react';
import { combineClassNames } from '../../services/common';

export type IconProps = { kendoIconName?: string; icon?: ComponentType<React.SVGProps<SVGSVGElement>>; className?: string };
export function Icon(props: IconProps) {
    if (props.kendoIconName) return <span className={combineClassNames(`k-icon k-i-${props.kendoIconName}`, props.className)} />;
    if (props.icon) return <props.icon className={combineClassNames('k-icp-icon k-icp-icon-size-4', props.className)} />;

    return null;
}
