import { Button } from '@progress/kendo-react-buttons';
import { MultiViewCalendar, MultiViewCalendarProps, SelectionRange } from '@progress/kendo-react-dateinputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { PopupProps } from '@progress/kendo-react-popup';
import { createContext, forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import { TaskEditorProps, TaskEditorRef, useTaskCommitOperation } from '.';
import { ReactComponent as WarningIcon } from '../../../icons/alert-triangle.svg';
import { ReactComponent as ErrorIcon } from '../../../icons/x-octagon.svg';
import { combineClassNames } from '../../../services/common';
import { dateTimeService } from '../../../services/dateTimeService';
import { ResearchEditorParams } from '../../../services/journeyService';
import { researchService } from '../../../services/researchService';
import { KeyboardNavigatableDateInput } from '../../common/date';
import { ValidationScope, ValidationScopeHandle, ValidationUnit } from '../../common/validation';
import { ChangeOnBlurDateRangePicker } from '../../ui/inputs';
import LoadingIndicator from '../../ui/loadingIndicator';
import { EditorErrorsList } from './shared/editorErrorsList';
import { EditorMainArea } from './shared/editorMainArea';

const minRangeLengthInWeeks = 4;
const recommendedMaxRangeLength = 8;
export const ResearchDateRangeEditor = forwardRef<TaskEditorRef, TaskEditorProps<ResearchEditorParams>>(function(props, ref) {
    const validationScopeRef = useRef<ValidationScopeHandle>(null);
    useImperativeHandle(
        ref,
        () => ({
            commit() {
                if (!validationScopeRef.current) return;

                validationScopeRef.current.validate();

                return validationScopeRef.current.isValid;
            }
        }),
        []
    );

    const research = props.taskData.problemValidationResearch?.[props.params.researchId];
    const [pendingRange, setPendingRange] = useState<SelectionRange>();
    const createCommitOperation = useTaskCommitOperation(props);

    const updateResearchRange = createCommitOperation(async function(startDate: Date, endDate: Date) {
        const updatedResearch = await researchService.partiallyUpdateProblemValidationResearch(props.ideaId, props.params.researchId, {
            startDate: startDate,
            endDate: endDate
        });
        props.setTaskData(taskData => ({
            ...taskData,
            problemValidationResearch: { ...taskData.problemValidationResearch, [props.params.researchId]: updatedResearch }
        }));
    });

    const value: SelectionRange | undefined = pendingRange ?? (research ? { start: research.startDate ?? null, end: research.endDate ?? null } : undefined);

    const showErrors = props.isEditing && !!props.errors?.length;
    return (
        <EditorMainArea hasError={showErrors}>
            {research ? (
                <ValidationScope ref={validationScopeRef}>
                    <ValidationUnit
                        name="range"
                        value={value}
                        validator={v => {
                            if (!isRangeSet(v)) return 'Range is required';
                            if (!v.start || !v.end) return undefined;
                            if (v.start > v.end) return 'End date should be after start date';
                            const researchDurationInWeeks = dateTimeService.getDurationInWeeks(v.start, v.end);
                            if (researchDurationInWeeks < minRangeLengthInWeeks)
                                return `The selected range should be at least ${minRangeLengthInWeeks} weeks long`;
                            return undefined;
                        }}
                    >
                        {(rangeErrorMessage, rangeOnChange) => (
                            <ValidationUnit
                                name="rangeStart"
                                value={value}
                                validator={v => (!isRangeSet(v) ? undefined : v.start ? undefined : 'Start date is required')}
                            >
                                {(rangeStartErrorMessage, rangeStartOnChange) => (
                                    <ValidationUnit
                                        name="rangeEnd"
                                        value={value}
                                        validator={v => (!isRangeSet(v) ? undefined : v.end ? undefined : 'End date is required')}
                                    >
                                        {(rangeEndErrorMessage, rangeEndOnChange) => {
                                            const errorMessage = rangeErrorMessage || rangeStartErrorMessage || rangeEndErrorMessage;
                                            const rangeDurationInWeeks =
                                                value && value.start && value.end ? dateTimeService.getDurationInWeeks(value.start, value.end) : undefined;
                                            const warningText =
                                                rangeDurationInWeeks && rangeDurationInWeeks > recommendedMaxRangeLength
                                                    ? 'Range longer than 8 weeks is not recommended'
                                                    : undefined;
                                            return (
                                                <MultiViewCalendarWithFooterContext.Provider value={{ errorMessage: errorMessage, warningText: warningText }}>
                                                    <ChangeOnBlurDateRangePicker
                                                        value={value}
                                                        className="k-icp-daterangepicker-with-static-popup"
                                                        startDateInput={KeyboardNavigatableDateInput}
                                                        startDateInputSettings={{ label: 'Start date' }}
                                                        endDateInput={KeyboardNavigatableDateInput}
                                                        endDateInputSettings={{ label: 'End date' }}
                                                        calendar={MultiViewCalendarWithFooter}
                                                        calendarSettings={{ views: 3 }}
                                                        popup={DateRangePickerStaticPopup}
                                                        onChange={async e => {
                                                            setPendingRange(e.value);
                                                            const isRangeValid = rangeOnChange(e.value);
                                                            const oldStart = getRangeNormalizedValue('start', value);
                                                            const oldEnd = getRangeNormalizedValue('start', value);
                                                            const newStart = getRangeNormalizedValue('end', value);
                                                            const newEnd = getRangeNormalizedValue('end', value);

                                                            if (oldStart !== newStart) rangeStartOnChange(e.value);
                                                            if (oldEnd !== newEnd) rangeEndOnChange(e.value);
                                                            if (!isRangeValid || !e.value.start || !e.value.end) return;

                                                            await updateResearchRange(e.value.start, e.value.end);
                                                            setPendingRange(undefined);
                                                        }}
                                                        valid={!errorMessage}
                                                        disabled={!props.isEditing}
                                                    />
                                                </MultiViewCalendarWithFooterContext.Provider>
                                            );
                                        }}
                                    </ValidationUnit>
                                )}
                            </ValidationUnit>
                        )}
                    </ValidationUnit>
                </ValidationScope>
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}
            <EditorErrorsList isEditing={props.isEditing} errors={props.errors} />
        </EditorMainArea>
    );
});

function getRangeNormalizedValue(prop: keyof SelectionRange, range: SelectionRange | undefined) {
    if (!range) return null;
    return range[prop];
}

function DateRangePickerStaticPopup(props: PopupProps) {
    return <>{props.children}</>;
}

type MultiViewCalendarWithFooterContextValue = { errorMessage?: string; warningText?: string };
const MultiViewCalendarWithFooterContext = createContext<MultiViewCalendarWithFooterContextValue>({});

function MultiViewCalendarWithFooter(props: MultiViewCalendarProps) {
    const selectionRangeValue = props.value && 'start' in props.value ? props.value : undefined;
    const isRangeSelected = selectionRangeValue && selectionRangeValue.start && selectionRangeValue.end;
    const selectedDurationInWeeks = isRangeSelected ? dateTimeService.getDurationInWeeks(selectionRangeValue.start!, selectionRangeValue.end!) : undefined;
    const multiViewCalendarWithFooterContextValue = useContext(MultiViewCalendarWithFooterContext);

    return (
        <div className="k-icp-calendar-range-with-footer k-popup k-rounded-0">
            <MultiViewCalendar {...props} className={combineClassNames(props.className, '!k-border-none')} />
            <StackLayout
                align={{ horizontal: 'start', vertical: 'middle' }}
                className="k-gap-4 k-justify-content-between k-icp-bordered-top k-icp-component-border k-p-2"
            >
                <div className="k-fs-sm">
                    {isRangeSelected ? (
                        <>
                            <span className="k-icp-subtle-text">Range:</span>{' '}
                            <span>
                                {selectedDurationInWeeks} week{selectedDurationInWeeks === 1 ? '' : 's'}
                            </span>
                        </>
                    ) : (
                        <span className="k-icp-subtle-text">No range selected</span>
                    )}
                </div>

                <MultiViewCalendarFooterNotice
                    errorMessage={multiViewCalendarWithFooterContextValue.errorMessage}
                    warningText={multiViewCalendarWithFooterContextValue.warningText}
                />

                <Button
                    size="small"
                    fillMode="flat"
                    disabled={!isRangeSelected || props.disabled}
                    onClick={e => props.onChange?.({ value: null, syntheticEvent: e, nativeEvent: e.nativeEvent, target: null })}
                >
                    Clear
                </Button>
            </StackLayout>
        </div>
    );
}

function MultiViewCalendarFooterNotice({ errorMessage, warningText }: { errorMessage?: string; warningText?: string }) {
    if (!errorMessage && !warningText) return null;

    const className = errorMessage ? 'k-icp-bg-error-12' : 'k-icp-bg-warning-16';
    const Icon = errorMessage ? ErrorIcon : WarningIcon;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className={combineClassNames('k-gap-1 k-px-2 k-py-thin k-fs-sm', className)}>
            <Icon className="k-icp-icon k-icp-icon-size-4" />
            {errorMessage || warningText}
        </StackLayout>
    );
}

function isRangeSet(range: SelectionRange | undefined): range is SelectionRange {
    if (!range || (!range.end && !range.start)) return false;

    return true;
}
