import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deleteQueryParamWithValue } from '../services/common';
import { domService } from '../services/domService';
import { JourneyTaskNavigationHighlight } from '../services/journeyService';
import { useAppDispatch } from '../state/hooks';

const activeHighlightClassName = 'highlight-target-active';
export const useHighlight = (highlightElementRef: React.RefObject<HTMLElement | null | undefined>, highlight: JourneyTaskNavigationHighlight | undefined) => {
    const [showHighlight, setShowHighlight] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const [queryParams] = useSearchParams();
    const navigate = useNavigate();
    const navigateRef = useRef(navigate);
    navigateRef.current = navigate;

    const activeHighlights = queryParams.getAll('highlight');
    const isHighlightActive = typeof highlight !== 'undefined' ? activeHighlights.includes(highlight) && showHighlight : false;

    useEffect(() => {
        const highlightElement = highlightElementRef.current;
        if (!isHighlightActive || !highlightElement || highlightElement.classList.contains(activeHighlightClassName)) return;

        const clearHighlight = () => {
            highlightElement.classList.remove(activeHighlightClassName);
            highlightElement.removeEventListener('animationend', onAnimationComplete);
            highlightElement.removeEventListener('animationcancel', onAnimationComplete);

            //setTimeout(() => {
            if (typeof highlight !== 'undefined') {
                const currentQueryParams = new URLSearchParams(window.location.search);
                const highlightRemovedFromUrl = deleteQueryParamWithValue(currentQueryParams, 'highlight', highlight.toString());
                if (highlightRemovedFromUrl) navigateRef.current(window.location.pathname + '?' + currentQueryParams, { replace: true });
            }
            //}, 1000);
        };

        const onAnimationComplete = (e: AnimationEvent) => {
            if (e.animationName !== 'highlight-animation') return;

            clearHighlight();
        };

        domService.scrollIntoViewIfNeeded(highlightElement);
        highlightElement.addEventListener('animationend', onAnimationComplete);
        highlightElement.addEventListener('animationcancel', onAnimationComplete);

        highlightElement.classList.add(activeHighlightClassName);
    }, [dispatch, isHighlightActive, highlight, highlightElementRef]);

    return setShowHighlight;
};
