import { Button, Chip } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ShowActivityDialogProps, useManageActivityDialog } from '../../components/activities/activityDialogs';
import { CanvasItemSimpleView } from '../../components/canvas/canvasItemSimpleView';
import { useCanvasBoxItemsInZone, useCanvasItemsInZone } from '../../components/canvas/canvasItemsZone';
import { BoundDropDownButton } from '../../components/common/boundDropDownButton';
import { useCountryFor } from '../../components/common/countries';
import { CellTemplateProps, createCellTemplateFromComponent } from '../../components/common/grid';
import { PersonCard } from '../../components/contacts/contactCard';
import { ContactListOptions, FilterOptions, ListOptions, PeopleActiveFilters, PeopleAdvancedFilter } from '../../components/contacts/contactListOptions';
import { EmptyContactsView } from '../../components/contacts/view';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { Pager } from '../../components/ui/pager';
import UserAvatar from '../../components/user/userAvatar';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { ReactComponent as RemoveIcon } from '../../icons/x.svg';
import { ActivityType } from '../../services/activitiesService';
import { BoxType } from '../../services/canvasService';
import { generateInitials, getPreferredColorIndex } from '../../services/common';
import { Company, ContactsListViewMode, ContactsSortBy, People, PeopleFilter, Person, contactsService } from '../../services/contactsService';
import { dateTimeService } from '../../services/dateTimeService';
import { listPreferencesService } from '../../services/listPreferencesService';
import { RealTimeUpdateCompanyEventData, RealTimeUpdatePersonEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { useTabbedLayoutContext } from '../layouts/tabbedLayout';

export function PeoplePage() {
    const { ideaId } = useParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canExecuteActions = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    const { setHeaderContent } = useTabbedLayoutContext();
    const { requireBoxItems: requireCustomerSegments } = useCanvasItemsInZone();
    const requireCustomerSegmentsRef = useRef(requireCustomerSegments);
    requireCustomerSegmentsRef.current = requireCustomerSegments;
    const location = useLocation();
    const [listOptions, setListOptions] = useState<ListOptions<PeopleActiveFilters>>(() => {
        const preferences = listPreferencesService.getContactsListPreferences();
        if (preferences.viewMode === ContactsListViewMode.List) requireCustomerSegmentsRef.current(BoxType.CustomerSegments);
        const listOptions: ListOptions<PeopleActiveFilters> = { ...preferences };
        if (location.state) {
            listOptions.filter = { activeFilters: location.state as PeopleActiveFilters };
        }

        return listOptions;
    });
    const [skip, setSkip] = useState(0);

    const navigate = useNavigate();

    const [manageActivityDialogElement, showActivityDialog] = useManageActivityDialog(ideaId);

    const [peopleData, setPeopleData] = useState<People>();

    const onListOptionsChanged = useCallback((value: ListOptions<PeopleActiveFilters>, type: 'filter' | 'sort' | 'view') => {
        setListOptions(value);

        if (type === 'view' && value.viewMode === ContactsListViewMode.List) requireCustomerSegmentsRef.current(BoxType.CustomerSegments);
    }, []);

    useEffect(() => {
        setHeaderContent(
            <ContactListOptions
                ideaId={ideaId!}
                placeholder="Search for a contact"
                AdvancedFilterComponent={PeopleAdvancedFilter}
                options={listOptions}
                onChange={onListOptionsChanged}
            />
        );

        return () => setHeaderContent(undefined);
    }, [ideaId, listOptions, onListOptionsChanged, setHeaderContent]);

    const loadPeople = useCallback((ideaId: string, sortBy: ContactsSortBy, skip: number, take: number, filter?: FilterOptions<PeopleActiveFilters>) => {
        let peopleFilter: PeopleFilter | undefined;
        if (filter && (filter.searchText || filter.activeFilters)) {
            peopleFilter = { search: filter.searchText, ...filter.activeFilters };
            if (filter.activeFilters) {
                if (filter.activeFilters.createdWithin) {
                    peopleFilter.createdFrom = filter.activeFilters?.createdWithin.from;
                    peopleFilter.createdTo = filter.activeFilters?.createdWithin.to;
                }

                if (filter.activeFilters.tags) {
                    peopleFilter.tagIds = filter.activeFilters.tags.map(t => t.id);
                }
            }
        }

        contactsService.getPeople(ideaId, sortBy, peopleFilter, skip, take).then(setPeopleData);
    }, []);

    useEffect(() => {
        setPeopleData(undefined);
        loadPeople(ideaId!, listOptions.sortBy, skip, listOptions.pageSize, listOptions.filter);
    }, [ideaId, listOptions.filter, listOptions.sortBy, skip, listOptions.pageSize, loadPeople]);

    useEffect(() => {
        function reloadCurrentPage(e: RealTimeUpdatePersonEventData) {
            if (e.ideaId !== ideaId) return;
            loadPeople(ideaId, listOptions.sortBy, skip, listOptions.pageSize, listOptions.filter);
        }

        realTimeUpdatesEventHub.addEventListener('contact', 'personAdd', reloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('contact', 'personRestore', reloadCurrentPage);
        // We do full reload on update instead of updating the specific record because of the sorting
        realTimeUpdatesEventHub.addEventListener('contact', 'personUpdate', reloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('contact', 'personDelete', reloadCurrentPage);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('contact', 'personAdd', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personRestore', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personUpdate', reloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personDelete', reloadCurrentPage);
        };
    }, [ideaId, listOptions.filter, listOptions.sortBy, skip, listOptions.pageSize, loadPeople]);

    useEffect(() => {
        if (!peopleData) return;

        const reloadCurrentPage = (checkForPeopleInCompany: boolean, e: RealTimeUpdateCompanyEventData) => {
            if (e.ideaId !== ideaId || (checkForPeopleInCompany && !peopleData.people.some(p => p.companyId === e.companyId))) return;
            loadPeople(ideaId, listOptions.sortBy, skip, listOptions.pageSize, listOptions.filter);
        };

        const alwaysReloadCurrentPage = (e: RealTimeUpdateCompanyEventData) => reloadCurrentPage(false, e);
        const reloadCurrentPageIfNeeded = (e: RealTimeUpdateCompanyEventData) => reloadCurrentPage(true, e);

        realTimeUpdatesEventHub.addEventListener('contact', 'companyAdd', alwaysReloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('contact', 'companyRestore', alwaysReloadCurrentPage);
        realTimeUpdatesEventHub.addEventListener('contact', 'companyUpdate', reloadCurrentPageIfNeeded);
        realTimeUpdatesEventHub.addEventListener('contact', 'companyDelete', reloadCurrentPageIfNeeded);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyAdd', alwaysReloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyRestore', alwaysReloadCurrentPage);
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyUpdate', reloadCurrentPageIfNeeded);
            realTimeUpdatesEventHub.removeEventListener('contact', 'companyDelete', reloadCurrentPageIfNeeded);
        };
    }, [ideaId, listOptions.filter, listOptions.sortBy, skip, listOptions.pageSize, peopleData, loadPeople]);

    return (
        <>
            <PeopleActiveFiltersView
                ideaId={ideaId!}
                filter={listOptions.filter?.activeFilters}
                onFilterChange={f => setListOptions(o => ({ ...o, filter: o.filter?.searchText || f ? { ...o.filter, activeFilters: f } : undefined }))}
            />
            {peopleData ? (
                <>
                    {peopleData.people.length ? (
                        listOptions.viewMode === ContactsListViewMode.Card ? (
                            <div className="k-d-grid cards-grid k-align-items-start k-gap-4">
                                {peopleData.people.map(p => (
                                    <Link key={p.id} className="k-link" to={`people/${p.id}`}>
                                        <PersonCard person={p} />
                                    </Link>
                                ))}
                            </div>
                        ) : (
                            <Grid
                                data={peopleData.people}
                                onRowClick={e => navigate(`people/${(e.dataItem as Person).id}`)}
                                className="k-grid-no-scrollbar k-icp-grid-navigatable"
                                onItemChange={e => {
                                    if (e.field !== 'createActivity') return;

                                    const hasProps = !(typeof e.value === 'string');
                                    const activityType: ActivityType = hasProps ? e.value.type : e.value;
                                    const props: ShowActivityDialogProps<ActivityType> = { person: e.dataItem, ...(hasProps ? e.value.props : {}) };

                                    showActivityDialog(activityType, props);
                                }}
                            >
                                <GridColumn title="Name" cell={PersonInfoGridCellTemplate} />
                                <GridColumn field="jobTitle" title="Job title" width={240} />
                                <GridColumn field="city" title="City" width={160} />
                                <GridColumn field="createdOn" title="Created on" format="{0:d MMM y}" width={160} />
                                <GridColumn title="Segment" cell={PersonCustomerSegmentsGridCellTemplate} />
                                {canExecuteActions && <GridColumn title="Actions" cell={PersonActionsCellTemplate} width={100} />}
                            </Grid>
                        )
                    ) : (
                        <EmptyContactsView filtered={!!listOptions.filter} contactTypeName="contact" contactsTypeName="contacts" />
                    )}
                    {manageActivityDialogElement}

                    <Pager
                        total={peopleData.totalCount}
                        skip={skip}
                        take={listOptions.pageSize}
                        onPageChange={(skip, take) => {
                            const newListOptions = { ...listOptions, pageSize: take };
                            listPreferencesService.saveContactsListPreferences(newListOptions);
                            setListOptions(newListOptions);
                            setSkip(skip);
                        }}
                    />
                </>
            ) : (
                <LoadingIndicator size="big" className="!k-pos-absolute k-centered" />
            )}
        </>
    );
}

const PersonInfoGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: person }: CellTemplateProps<Person>) {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3 k-no-click">
            <UserAvatar
                picture={person.picture}
                initials={generateInitials(2, person.firstName, person.lastName)}
                colorIndex={getPreferredColorIndex(person.id)}
            />

            <strong className="k-fs-lg k-icp-hoverable">{person.name}</strong>
        </StackLayout>
    );
});

const PersonActionsCellTemplate = createCellTemplateFromComponent(function({ dataItem: person, cellProps }: CellTemplateProps<Person>) {
    const onChange = cellProps.onChange;
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Link
                to={`people/${person.id}/edit`}
                className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md k-icp-svg-icon-button"
            >
                <EditIcon className="k-icp-icon" />
            </Link>
            <BoundDropDownButton
                icon="more-horizontal"
                popupSettings={{
                    anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                    popupAlign: { horizontal: 'right', vertical: 'top' }
                }}
                items={[
                    {
                        text: 'Add meeting',
                        action(e) {
                            onChange?.({
                                dataItem: cellProps.dataItem,
                                dataIndex: cellProps.dataIndex,
                                syntheticEvent: e.syntheticEvent,
                                field: 'createActivity',
                                value: ActivityType.Meeting
                            });
                        }
                    },
                    {
                        text: 'Schedule interview',
                        action(e) {
                            onChange?.({
                                dataItem: cellProps.dataItem,
                                dataIndex: cellProps.dataIndex,
                                syntheticEvent: e.syntheticEvent,
                                field: 'createActivity',
                                value: { type: ActivityType.Meeting, props: { initialResearchId: null } as ShowActivityDialogProps<ActivityType.Meeting> }
                            });
                        }
                    },
                    {
                        text: 'Reach out',
                        action(e) {
                            onChange?.({
                                dataItem: cellProps.dataItem,
                                dataIndex: cellProps.dataIndex,
                                syntheticEvent: e.syntheticEvent,
                                field: 'createActivity',
                                value: ActivityType.ReachOut
                            });
                        }
                    },
                    {
                        text: 'Add note',
                        action(e) {
                            onChange?.({
                                dataItem: cellProps.dataItem,
                                dataIndex: cellProps.dataIndex,
                                syntheticEvent: e.syntheticEvent,
                                field: 'createActivity',
                                value: ActivityType.Note
                            });
                        }
                    }
                ]}
            />
        </StackLayout>
    );
});

const PersonCustomerSegmentsGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: person }: CellTemplateProps<Person>) {
    const customerSegments = useCanvasBoxItemsInZone(BoxType.CustomerSegments);

    if (!person.customerSegmentIds || !person.customerSegmentIds.length) return null;
    if (!customerSegments) return <LoadingIndicator size="small" />;

    const itemCustomerSegments = customerSegments.filter(s => person.customerSegmentIds!.includes(s.id));

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2 k-no-click">
            {itemCustomerSegments.map(s => (
                <CanvasItemSimpleView key={s.id} item={s} />
            ))}
        </StackLayout>
    );
});

function PeopleActiveFiltersView({
    ideaId,
    filter,
    onFilterChange
}: {
    ideaId: string;
    filter?: PeopleActiveFilters;
    onFilterChange?: (filter?: PeopleActiveFilters) => void;
}) {
    if (!filter) return null;

    const removeFilter = (filterName: keyof PeopleActiveFilters) => {
        if (!onFilterChange) return;

        const updatedFilter = { ...filter };
        delete updatedFilter[filterName];

        if (!Object.keys(updatedFilter).length) onFilterChange(undefined);
        else onFilterChange(updatedFilter);
    };

    const removeTagFilter = (tagId: number) => {
        if (!onFilterChange || !filter.tags) return;
        const updatedTagsFilter = filter.tags.filter(t => t.id !== tagId);
        if (updatedTagsFilter.length) onFilterChange({ ...filter, tags: updatedTagsFilter });
        else removeFilter('tags');
    };

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-flex-wrap k-gap-2 k-mb-4">
            <strong className="k-fs-sm">Filters applied:</strong>

            {filter.companyId && <PeopleCompanyFilterView ideaId={ideaId} companyId={filter.companyId} onRemove={() => removeFilter('companyId')} />}
            {filter.jobTitle && (
                <PeopleActiveFilterView text="Job title:" onRemove={() => removeFilter('jobTitle')}>
                    {filter.jobTitle}
                </PeopleActiveFilterView>
            )}
            {filter.city && (
                <PeopleActiveFilterView text="From city:" onRemove={() => removeFilter('city')}>
                    {filter.city}
                </PeopleActiveFilterView>
            )}
            {filter.countryCode && <PeopleCountryFilterView countryCode={filter.countryCode} onRemove={() => removeFilter('countryCode')} />}
            {filter.customerSegmentId && (
                <PeopleCustomerSegmentFilterView customerSegmentId={filter.customerSegmentId} onRemove={() => removeFilter('customerSegmentId')} />
            )}
            {filter.tags && (
                <>
                    {filter.tags.map(tag => (
                        <PeopleActiveFilterView text="Tag:" key={tag.id} onRemove={() => removeTagFilter(tag.id)}>
                            {tag.name}
                        </PeopleActiveFilterView>
                    ))}
                </>
            )}
            {filter.createdWithin && (
                <PeopleActiveFilterView text="Created within:" onRemove={() => removeFilter('createdWithin')}>
                    {dateTimeService.stringifyToDay(filter.createdWithin.from)} - {dateTimeService.stringifyToDay(filter.createdWithin.to)}
                </PeopleActiveFilterView>
            )}
            {filter.requireLinkedIn && (
                <PeopleActiveFilterView text="Social:" onRemove={() => removeFilter('requireLinkedIn')}>
                    LinkedIn
                </PeopleActiveFilterView>
            )}
            {filter.requireTwitter && (
                <PeopleActiveFilterView text="Social:" onRemove={() => removeFilter('requireTwitter')}>
                    Twitter
                </PeopleActiveFilterView>
            )}
            {filter.requireFacebook && (
                <PeopleActiveFilterView text="Social:" onRemove={() => removeFilter('requireFacebook')}>
                    Facebook
                </PeopleActiveFilterView>
            )}

            <Button size="small" fillMode="flat" onClick={onFilterChange ? () => onFilterChange(undefined) : undefined}>
                Clear all
            </Button>
        </StackLayout>
    );
}

function PeopleActiveFilterView({ text, onRemove, children }: { text: string; onRemove: () => void; children: string | ReactNode }) {
    return (
        <Chip className="k-icp-chip-solid-base-not-interactive k-py-0">
            <span className="k-icp-subtle-text k-mr-1 k-fs-sm">{text}</span>
            <span className="k-mr-2 k-fs-sm">{children}</span>
            <Button fillMode="flat" size="small" className="k-icp-svg-icon-button !k-p-0 !k-border-0" onClick={onRemove}>
                <RemoveIcon className="k-icp-icon" />
            </Button>
        </Chip>
    );
}

function PeopleCustomerSegmentFilterView({ customerSegmentId, onRemove }: { customerSegmentId: number; onRemove: () => void }) {
    const customerSegments = useCanvasBoxItemsInZone(BoxType.CustomerSegments);
    const filteredByCustomerSegment = customerSegments ? customerSegments.find(s => s.id === customerSegmentId) : undefined;

    return (
        <PeopleActiveFilterView text="Segment:" onRemove={onRemove}>
            <span className="k-text-ellipsis active-filter-limited-length">
                {filteredByCustomerSegment ? filteredByCustomerSegment.content : <LoadingIndicator size="small" />}
            </span>
        </PeopleActiveFilterView>
    );
}

function PeopleCountryFilterView({ countryCode, onRemove }: { countryCode: string; onRemove: () => void }) {
    const [country] = useCountryFor({ countryCode });

    if (country === null) return null;

    return (
        <PeopleActiveFilterView text="From country:" onRemove={onRemove}>
            {country ? country.name : <LoadingIndicator size="small" />}
        </PeopleActiveFilterView>
    );
}

function PeopleCompanyFilterView({ ideaId, companyId, onRemove }: { ideaId: string; companyId: number; onRemove: () => void }) {
    const requestedCompanyId = useRef<number>();
    const [company, setCompany] = useState<Company>();

    useEffect(() => {
        if (companyId === requestedCompanyId.current) return;
        requestedCompanyId.current = companyId;

        contactsService.getCompanyById(ideaId, companyId).then(c => {
            if (c.id === requestedCompanyId.current) setCompany(c);
        });
    }, [ideaId, companyId]);

    return (
        <PeopleActiveFilterView text="Company:" onRemove={onRemove}>
            {company ? company.name : <LoadingIndicator size="small" />}
        </PeopleActiveFilterView>
    );
}
