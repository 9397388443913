import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIsOnPage } from '../../hooks/routerHooks';
import { googleTagManager } from '../../scripts/googleTagManager';
import { AuthenticationContext, externalAuthenticationService } from '../../services/authenticationService';
import { UserFlag } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { login } from '../../state/user/userSlice';
import LoadingIndicator from '../ui/loadingIndicator';

export default function UserZone({ welcomePath }: { welcomePath: string }) {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(s => s.user);
    const navigate = useNavigate();
    const isInAccountSection = useIsOnPage('/account');
    const isCurrentUserLoaded = !!currentUser;
    const loginState = useLocation().state as AuthenticationContext | undefined | null;

    useEffect(() => {
        if (isCurrentUserLoaded) return;
        //If there is no user, exception will be thrown and handled
        //by unauthorized global error handler that will cause the user to be logged out
        dispatch(login()).then(loginResult => {
            if (!loginResult.payload) return;

            const externalAuthenticationContext = externalAuthenticationService.tryReadAndDestroyExternalAuthenticationContext();
            if (loginState) {
                if (loginState.action === 'registered') googleTagManager.reportUserRegisteredEvent(loginState.origin);
                else if (loginState.action === 'logged-in') googleTagManager.reportUserLoggedInEvent(loginState.origin);
                return;
            }

            if (externalAuthenticationContext) {
                if (externalAuthenticationContext.trigger === 'registration') googleTagManager.reportUserRegisteredEvent(externalAuthenticationContext.origin);
                else if (externalAuthenticationContext.trigger === 'login') googleTagManager.reportUserLoggedInEvent(externalAuthenticationContext.origin);
            }
        });
    }, [dispatch, isCurrentUserLoaded, loginState]);

    useEffect(() => {
        if (isInAccountSection || !currentUser || currentUser.flags.includes(UserFlag.CommittedEntrepreneur)) return;
        navigate(welcomePath);
    }, [currentUser, isInAccountSection, navigate, welcomePath]);

    return currentUser ? (
        <Outlet />
    ) : (
        <div className="page k-align-items-center k-justify-content-center">
            <LoadingIndicator size="big" />
        </div>
    );
}
