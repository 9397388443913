import { Button } from '@progress/kendo-react-buttons';
import { guid } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Error as ErrorComponent } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { TaskEditorProps, TaskEditorRef, useTaskCommitOperation } from '.';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/trash-2.svg';
import { BoxType, canvasService } from '../../../services/canvasService';
import { AlternativeSolutionHypothesis, Hypothesis, HypothesisGroup, HypothesisType, hypothesesService } from '../../../services/hypothesesService';
import { CustomerProblemHypothesesEditorParams } from '../../../services/journeyService';
import { researchService } from '../../../services/researchService';
import { deleteItem, refreshBox } from '../../../state/canvas/canvasSlice';
import { useAppDispatch } from '../../../state/hooks';
import { addNotification } from '../../../state/notifications/platformNotificationsSlice';
import { AlternativeSolutionEditor } from '../../canvas/alternativeSolutionEditor';
import { hypothesisValidators } from '../../hypotheses/common';
import { EditableHypothesis, HypothesisEditMode, HypothesisEditor } from '../../hypotheses/hypothesisEditor';
import { SvgIconButtonContent } from '../../ui/svgIconButtonContent';
import { EditorErrorsList } from './shared/editorErrorsList';
import { EditorMainArea } from './shared/editorMainArea';

type NewEditableHypothesis = EditableHypothesis & { newItemId: string; isMissing: boolean };
type AlternativeSolutionHypothesesGroup = {
    alternativeSolutionId?: number;
    alternativeSolutionHypotheses: (EditableHypothesis | NewEditableHypothesis)[];
    alternativeSolutionSatisfactionHypotheses: (EditableHypothesis | NewEditableHypothesis)[];
};
export const CustomerProblemHypothesesEditor = forwardRef<TaskEditorRef, TaskEditorProps<CustomerProblemHypothesesEditorParams>>((props, ref) => {
    const researchHypotheses = props.taskData.researchHypotheses?.[props.params.researchId]?.filter(
        h =>
            h.group === HypothesisGroup.CustomerProblem &&
            h.customerSegmentId === props.params.customerSegmentId &&
            h.customerProblemId === props.params.customerProblemId
    );
    const isLoading = researchHypotheses === undefined;
    const painLevelHypotheses = isLoading ? undefined : researchHypotheses.filter(h => h.type === HypothesisType.PainLevel);

    const [newPainLevelHypotheses, setNewPainLevelHypotheses] = useState<EditableHypothesis>({
        type: HypothesisType.PainLevel,
        customerSegmentId: props.params.customerSegmentId,
        customerProblemId: props.params.customerProblemId
    });
    const [newAlternativeSolutionHypothesesMap, setNewAlternativeSolutionHypothesesMap] = useState<Partial<Record<string, NewEditableHypothesis>>>({});
    const groupedAlternativeSolutionHypotheses = getGroupedAlternativeSolutionHypotheses();

    const [invalidHypotheses, setInvalidHypotheses] = useState<Partial<Record<number, EditableHypothesis>>>({});
    const [enableHypothesesValidation, setEnableHypothesesValidation] = useState(false);
    const isValid =
        painLevelHypotheses !== undefined &&
        painLevelHypotheses.length > 0 &&
        researchHypotheses !== undefined &&
        !hasInvalidHypothesis() &&
        areAlternativeHypothesesValid();
    const isValidRef = useRef(isValid);
    isValidRef.current = isValid;
    useImperativeHandle(
        ref,
        () => ({
            commit() {
                setEnableHypothesesValidation(true);
                return isValidRef.current;
            }
        }),
        []
    );
    const dispatch = useAppDispatch();

    function hasInvalidHypothesis() {
        if (!researchHypotheses) return false;
        return researchHypotheses.some(h => invalidHypotheses[h.id] !== undefined);
    }

    function areAlternativeHypothesesValid() {
        if (!groupedAlternativeSolutionHypotheses) return false;
        if (!groupedAlternativeSolutionHypotheses.length) return false;

        const hasNewAlternativeSolutionHypothesis = groupedAlternativeSolutionHypotheses.some(
            g =>
                g.alternativeSolutionHypotheses.some(h => isNewEditableHypothesis(h)) ||
                g.alternativeSolutionSatisfactionHypotheses.some(h => isNewEditableHypothesis(h))
        );

        return !hasNewAlternativeSolutionHypothesis;
    }

    const createCommitOperation = useTaskCommitOperation(props, () => setEnableHypothesesValidation(false));

    const createHypothesis = createCommitOperation(async function(hypothesis: EditableHypothesis): Promise<Hypothesis> {
        let newHypothesis: Hypothesis | undefined;
        const hypothesisType = hypothesis.type;
        switch (hypothesisType) {
            case HypothesisType.PainLevel:
                newHypothesis = await hypothesesService.createCustomerProblemPainLevelHypothesis(props.ideaId, {
                    customerSegmentId: props.params.customerSegmentId,
                    customerProblemId: props.params.customerProblemId,
                    percentage: hypothesis.percentage!,
                    painLevel: hypothesis.painLevel!
                });
                break;
            case HypothesisType.AlternativeSolutionUsage:
                newHypothesis = await hypothesesService.createCustomerProblemAlternativeSolutionHypothesis(props.ideaId, {
                    customerSegmentId: props.params.customerSegmentId,
                    customerProblemId: props.params.customerProblemId,
                    alternativeSolutionId: hypothesis.alternativeSolutionId!,
                    percentage: hypothesis.percentage!
                });
                break;
            case HypothesisType.AlternativeSolutionSatisfaction:
                newHypothesis = await hypothesesService.createCustomerProblemAlternativeSolutionSatisfactionHypothesis(props.ideaId, {
                    customerSegmentId: props.params.customerSegmentId,
                    customerProblemId: props.params.customerProblemId,
                    alternativeSolutionId: hypothesis.alternativeSolutionId!,
                    percentage: hypothesis.percentage!,
                    satisfactionLevel: hypothesis.satisfactionLevel!
                });
                break;
        }

        if (!newHypothesis) throw new Error(`Unsupported hypothesis type: ${hypothesisType}`);

        await researchService.updateProblemValidationResearchHypotheses(props.ideaId, props.params.researchId, [newHypothesis.id]);

        return newHypothesis;
    });

    function updateHypothesisInTaskData(hypothesis: Hypothesis) {
        props.setTaskData(taskData => {
            const updatedTaskData = { ...taskData };
            updatedTaskData.researchHypotheses = updatedTaskData.researchHypotheses ? { ...updatedTaskData.researchHypotheses } : {};
            const hypothesesInResearch = (updatedTaskData.researchHypotheses[props.params.researchId] = updatedTaskData.researchHypotheses[
                props.params.researchId
            ]
                ? [...updatedTaskData.researchHypotheses[props.params.researchId]!]
                : []);
            if (!hypothesesInResearch.length) hypothesesInResearch.push(hypothesis);
            else {
                const hypothesisIndex = hypothesesInResearch.findIndex(h => h.id === hypothesis.id);
                if (hypothesisIndex === -1) hypothesesInResearch.push(hypothesis);
                else hypothesesInResearch[hypothesisIndex] = hypothesis;
            }

            return updatedTaskData;
        });
    }

    async function trySaveNewHypothesis(hypothesis: EditableHypothesis): Promise<boolean> {
        const validator = hypothesisValidators[hypothesis.type];
        if (validator && validator(hypothesis)) return false;
        const newHypothesis = await createHypothesis(hypothesis);
        updateHypothesisInTaskData(newHypothesis);

        return true;
    }

    async function tryToSaveNewPainLevelHypothesis(updatedPainLevelHypothesis: EditableHypothesis) {
        setNewPainLevelHypotheses(updatedPainLevelHypothesis);
        if (!(await trySaveNewHypothesis(updatedPainLevelHypothesis))) return;
        setNewPainLevelHypotheses({
            type: HypothesisType.PainLevel,
            customerSegmentId: props.params.customerSegmentId,
            customerProblemId: props.params.customerProblemId
        });
    }

    const updateHypothesis = createCommitOperation(function(hypothesisId: number, hypothesis: EditableHypothesis): Promise<Hypothesis> {
        switch (hypothesis.type) {
            case HypothesisType.PainLevel:
                return hypothesesService.partiallyUpdateCustomerProblemPainLevelHypothesis(props.ideaId, hypothesisId, {
                    likelihood: hypothesis.likelihood,
                    painLevel: hypothesis.painLevel,
                    percentage: hypothesis.percentage
                });
            case HypothesisType.AlternativeSolutionUsage:
                return hypothesesService.partiallyUpdateCustomerProblemAlternativeSolutionHypothesis(props.ideaId, hypothesisId, {
                    likelihood: hypothesis.likelihood,
                    percentage: hypothesis.percentage
                });
            case HypothesisType.AlternativeSolutionSatisfaction:
                return hypothesesService.partiallyUpdateCustomerProblemAlternativeSolutionSatisfactionHypothesis(props.ideaId, hypothesisId, {
                    likelihood: hypothesis.likelihood,
                    percentage: hypothesis.percentage,
                    satisfactionLevel: hypothesis.satisfactionLevel
                });
        }
    });

    async function tryUpdateHypothesis(updatedHypothesis: EditableHypothesis) {
        const updatedHypothesisId = updatedHypothesis.id;
        if (updatedHypothesisId === undefined) throw new Error('Cannot update hypothesis without id');
        setInvalidHypotheses(invalidHypotheses => ({ ...invalidHypotheses, [updatedHypothesisId]: updatedHypothesis }));
        const validator = hypothesisValidators[updatedHypothesis.type];
        if (validator && validator(updatedHypothesis)) {
            return;
        }

        const savedHypothesis = await updateHypothesis(updatedHypothesisId, updatedHypothesis);

        updateHypothesisInTaskData(savedHypothesis);
        setInvalidHypotheses(invalidHypotheses => {
            const invalidHypothesis = invalidHypotheses[updatedHypothesisId];
            if (!invalidHypothesis) return invalidHypotheses;
            const updatedInvalidHypotheses = { ...invalidHypotheses };
            delete updatedInvalidHypotheses[updatedHypothesisId];

            return updatedInvalidHypotheses;
        });
    }

    async function updateExistingHypothesesAlternativeSolution(oldAlternativeSolutionId: number, newAlternativeSolutionId: number) {
        if (!researchHypotheses) return;
        const existingAlternativeSolutionHypothesesToUpdate = researchHypotheses.filter(
            (h): h is AlternativeSolutionHypothesis => 'alternativeSolutionId' in h && h.alternativeSolutionId === oldAlternativeSolutionId
        );

        if (!existingAlternativeSolutionHypothesesToUpdate.length) return;

        // All uncommitted/invalid updates are cleared. No validation is required since we are updating already existing hypotheses so changing the alternative solution should not make them invalid
        setInvalidHypotheses(invalidHypotheses => {
            const updatedInvalidHypotheses = { ...invalidHypotheses };
            for (const existingHypothesis of existingAlternativeSolutionHypothesesToUpdate) {
                updatedInvalidHypotheses[existingHypothesis.id] = { ...existingHypothesis, alternativeSolutionId: newAlternativeSolutionId };
            }
            return updatedInvalidHypotheses;
        });

        const savedHypotheses = await Promise.all(
            existingAlternativeSolutionHypothesesToUpdate.map(hypothesisToUpdate =>
                hypothesisToUpdate.type === HypothesisType.AlternativeSolutionUsage
                    ? hypothesesService.partiallyUpdateCustomerProblemAlternativeSolutionHypothesis(props.ideaId, hypothesisToUpdate.id, {
                          alternativeSolutionId: newAlternativeSolutionId
                      })
                    : hypothesisToUpdate.type === HypothesisType.AlternativeSolutionSatisfaction
                    ? hypothesesService.partiallyUpdateCustomerProblemAlternativeSolutionSatisfactionHypothesis(props.ideaId, hypothesisToUpdate.id, {
                          alternativeSolutionId: newAlternativeSolutionId
                      })
                    : Promise.reject(`Unsupported hypothesis type`)
            )
        );

        props.setTaskData(taskData => {
            const updatedTaskData = { ...taskData };
            updatedTaskData.researchHypotheses = updatedTaskData.researchHypotheses ? { ...updatedTaskData.researchHypotheses } : {};
            const hypothesesInResearch = (updatedTaskData.researchHypotheses[props.params.researchId] = updatedTaskData.researchHypotheses[
                props.params.researchId
            ]
                ? [...updatedTaskData.researchHypotheses[props.params.researchId]!]
                : []);

            if (!hypothesesInResearch.length) hypothesesInResearch.push(...savedHypotheses);
            else
                for (const savedHypothesis of savedHypotheses) {
                    const hypothesisIndex = hypothesesInResearch.findIndex(h => h.id === savedHypothesis.id);
                    if (hypothesisIndex === -1) hypothesesInResearch.push(savedHypothesis);
                    else hypothesesInResearch[hypothesisIndex] = savedHypothesis;
                }

            return updatedTaskData;
        });

        setInvalidHypotheses(invalidHypotheses => {
            const updatedInvalidHypotheses = { ...invalidHypotheses };
            for (const existingHypothesis of existingAlternativeSolutionHypothesesToUpdate) delete updatedInvalidHypotheses[existingHypothesis.id];

            return updatedInvalidHypotheses;
        });
    }

    function createNewAlternativeSolutionHypothesis(isMissing: boolean, alternativeSolutionId?: number): NewEditableHypothesis {
        return {
            newItemId: guid(),
            type: HypothesisType.AlternativeSolutionUsage,
            customerSegmentId: props.params.customerSegmentId,
            customerProblemId: props.params.customerProblemId,
            alternativeSolutionId,
            isMissing
        };
    }

    function createNewAlternativeSolutionSatisfactionHypothesis(isMissing: boolean, alternativeSolutionId?: number): NewEditableHypothesis {
        return {
            newItemId: guid(),
            type: HypothesisType.AlternativeSolutionSatisfaction,
            customerSegmentId: props.params.customerSegmentId,
            customerProblemId: props.params.customerProblemId,
            alternativeSolutionId,
            isMissing
        };
    }

    function getGroupedAlternativeSolutionHypotheses(): AlternativeSolutionHypothesesGroup[] | undefined {
        if (isLoading) return undefined;

        const hypothesesByAlternativeSolutionGroups: AlternativeSolutionHypothesesGroup[] = [];

        const alternativeSolutionHypotheses = researchHypotheses.filter(
            (h): h is AlternativeSolutionHypothesis =>
                h.type === HypothesisType.AlternativeSolutionUsage || h.type === HypothesisType.AlternativeSolutionSatisfaction
        );
        for (const alternativeSolutionHypothesis of alternativeSolutionHypotheses) {
            registerAlternativeSolutionHypothesis(
                alternativeSolutionHypothesis.alternativeSolutionId,
                alternativeSolutionHypothesis,
                hypothesesByAlternativeSolutionGroups
            );
        }

        for (const newAlternativeSolutionHypothesisId in newAlternativeSolutionHypothesesMap) {
            if (!Object.prototype.hasOwnProperty.call(newAlternativeSolutionHypothesesMap, newAlternativeSolutionHypothesisId)) continue;
            const newAlternativeSolutionHypothesis = newAlternativeSolutionHypothesesMap[newAlternativeSolutionHypothesisId]!;
            if (!('alternativeSolutionId' in newAlternativeSolutionHypothesis)) continue;

            const newAlternativeHypothesisRegistered = registerAlternativeSolutionHypothesis(
                newAlternativeSolutionHypothesis.alternativeSolutionId,
                newAlternativeSolutionHypothesis,
                hypothesesByAlternativeSolutionGroups
            );

            if (!newAlternativeHypothesisRegistered) deleteNewAlternativeSolutionHypothesis(newAlternativeSolutionHypothesis.newItemId);
        }

        const missingHypothesesMap: Record<string, NewEditableHypothesis> = {};
        let hasMissingHypotheses = false;
        for (const alternativeSolutionHypothesesGroup of hypothesesByAlternativeSolutionGroups) {
            if (!alternativeSolutionHypothesesGroup.alternativeSolutionHypotheses.length) {
                const newAlternativeSolutionHypothesis = createNewAlternativeSolutionHypothesis(true, alternativeSolutionHypothesesGroup.alternativeSolutionId);
                missingHypothesesMap[newAlternativeSolutionHypothesis.newItemId] = newAlternativeSolutionHypothesis;
                hasMissingHypotheses = true;
            }
            if (!alternativeSolutionHypothesesGroup.alternativeSolutionSatisfactionHypotheses.length) {
                const newAlternativeSolutionSatisfactionHypothesis = createNewAlternativeSolutionSatisfactionHypothesis(
                    true,
                    alternativeSolutionHypothesesGroup.alternativeSolutionId
                );
                missingHypothesesMap[newAlternativeSolutionSatisfactionHypothesis.newItemId] = newAlternativeSolutionSatisfactionHypothesis;
                hasMissingHypotheses = true;
            }
        }

        if (hasMissingHypotheses) setNewAlternativeSolutionHypothesesMap({ ...newAlternativeSolutionHypothesesMap, ...missingHypothesesMap });

        return hypothesesByAlternativeSolutionGroups;
    }

    async function onAlternativeSolutionChanged(oldAlternativeSolutionId: number | undefined, newAlternativeSolutionId: number) {
        setNewAlternativeSolutionHypothesesMap(m => {
            const newHypothesesToUpdate = Object.values(m).filter(
                (
                    newHypotheses
                ): newHypotheses is NewEditableHypothesis & {
                    type: HypothesisType.AlternativeSolutionUsage | HypothesisType.AlternativeSolutionSatisfaction;
                } =>
                    newHypotheses !== undefined &&
                    (newHypotheses.type === HypothesisType.AlternativeSolutionUsage || newHypotheses.type === HypothesisType.AlternativeSolutionSatisfaction) &&
                    newHypotheses.alternativeSolutionId === oldAlternativeSolutionId
            );
            if (!newHypothesesToUpdate.length) return m;

            const updatedMap = { ...m };
            for (const newHypothesisToUpdate of newHypothesesToUpdate) {
                updatedMap[newHypothesisToUpdate.newItemId] = { ...newHypothesisToUpdate, alternativeSolutionId: newAlternativeSolutionId };
            }

            return updatedMap;
        });

        if (oldAlternativeSolutionId !== undefined) await updateExistingHypothesesAlternativeSolution(oldAlternativeSolutionId, newAlternativeSolutionId);
    }

    async function deleteAlternativeSolutionHypotheses(alternativeSolutionId: number | undefined) {
        setNewAlternativeSolutionHypothesesMap(m => {
            const newHypothesesToRemove = Object.values(m).filter(
                (
                    newHypotheses
                ): newHypotheses is NewEditableHypothesis & {
                    type: HypothesisType.AlternativeSolutionUsage | HypothesisType.AlternativeSolutionSatisfaction;
                } =>
                    newHypotheses !== undefined &&
                    (newHypotheses.type === HypothesisType.AlternativeSolutionUsage || newHypotheses.type === HypothesisType.AlternativeSolutionSatisfaction) &&
                    newHypotheses.alternativeSolutionId === alternativeSolutionId
            );
            if (!newHypothesesToRemove.length) return m;

            const updatedMap = { ...m };
            for (const newHypothesisToUpdate of newHypothesesToRemove) {
                delete updatedMap[newHypothesisToUpdate.newItemId];
            }

            return updatedMap;
        });

        setInvalidHypotheses(invalidHypotheses => {
            const invalidHypothesesToRemove: { hypothesisId: number; hypothesis: EditableHypothesis }[] = [];
            for (const invalidHypothesisKey in invalidHypotheses) {
                if (!Object.prototype.hasOwnProperty.call(invalidHypotheses, invalidHypothesisKey)) continue;
                const invalidHypothesis = invalidHypotheses[invalidHypothesisKey];
                if (
                    invalidHypothesis &&
                    (invalidHypothesis.type === HypothesisType.AlternativeSolutionUsage ||
                        invalidHypothesis.type === HypothesisType.AlternativeSolutionSatisfaction) &&
                    invalidHypothesis.alternativeSolutionId === alternativeSolutionId
                )
                    invalidHypothesesToRemove.push({ hypothesisId: parseInt(invalidHypothesisKey), hypothesis: invalidHypothesis });
            }

            if (!invalidHypothesesToRemove.length) return invalidHypotheses;
            const updatedInvalidHypothesis = { ...invalidHypotheses };
            for (const invalidHypothesisToRemove of invalidHypothesesToRemove) {
                delete updatedInvalidHypothesis[invalidHypothesisToRemove.hypothesisId];
            }

            return updatedInvalidHypothesis;
        });

        let alternativeSolutionDeleted = false;
        await deleteHypothesesWithUndo(
            h => 'alternativeSolutionId' in h && h.alternativeSolutionId === alternativeSolutionId,
            alternativeSolutionId !== undefined
                ? async () => {
                      const alternativeSolutionStats = await canvasService.getItemStats(props.ideaId, BoxType.AlternativeSolutions, alternativeSolutionId);
                      if (alternativeSolutionStats.insightCount || alternativeSolutionStats.hypothesisCount) return;

                      alternativeSolutionDeleted = true;

                      await dispatch(deleteItem({ boxType: BoxType.AlternativeSolutions, itemId: alternativeSolutionId }));
                  }
                : undefined,
            alternativeSolutionId !== undefined
                ? async () => {
                      if (!alternativeSolutionDeleted) return;
                      await canvasService.restoreItem(props.ideaId, BoxType.AlternativeSolutions, alternativeSolutionId);
                      await dispatch(refreshBox(BoxType.AlternativeSolutions));
                  }
                : undefined
        );
    }

    const deleteHypothesesWithUndo = createCommitOperation(async function(
        filter: (hypothesis: Hypothesis) => boolean,
        cleanUp?: () => Promise<unknown>,
        onRestore?: () => Promise<unknown>
    ) {
        const hypothesesIdsToDelete = researchHypotheses?.filter(filter).map(h => h.id);
        const hasHypothesesToDelete = hypothesesIdsToDelete && hypothesesIdsToDelete.length > 0;
        if (!hasHypothesesToDelete) {
            await cleanUp?.();
            return;
        }

        await Promise.all(
            hypothesesIdsToDelete.map(hypothesisIdToDelete =>
                hypothesesService.deleteHypothesis(props.ideaId, HypothesisGroup.CustomerProblem, hypothesisIdToDelete)
            )
        );

        props.setTaskData(taskData => {
            if (!taskData.researchHypotheses) return taskData;
            const researchHypotheses = taskData.researchHypotheses[props.params.researchId];
            if (!researchHypotheses || !researchHypotheses.length) return taskData;

            return {
                ...taskData,
                researchHypotheses: {
                    ...taskData.researchHypotheses,
                    [props.params.researchId]: researchHypotheses.filter(h => !hypothesesIdsToDelete.includes(h.id))
                }
            };
        });

        await cleanUp?.();

        dispatch(
            addNotification({ content: 'Hypotheses deleted.', actionText: 'Undo' }, async () => {
                await onRestore?.();
                const restoredHypotheses = await Promise.all(
                    hypothesesIdsToDelete.map(hypothesisIdToRestore =>
                        hypothesesService.restoreHypothesis(props.ideaId, HypothesisGroup.CustomerProblem, hypothesisIdToRestore)
                    )
                );
                for (const restoredHypothesis of restoredHypotheses) {
                    updateHypothesisInTaskData(restoredHypothesis);
                }
            })
        );
    });

    function addAlternativeSolutionHypotheses(alternativeSolutionId?: number) {
        setNewAlternativeSolutionHypothesesMap(m => {
            const updatedMap = { ...m };
            const newAlternativeSolutionHypothesis = createNewAlternativeSolutionHypothesis(false, alternativeSolutionId);
            updatedMap[newAlternativeSolutionHypothesis.newItemId] = newAlternativeSolutionHypothesis;
            const newAlternativeSolutionSatisfactionHypothesis = createNewAlternativeSolutionSatisfactionHypothesis(false, alternativeSolutionId);
            updatedMap[newAlternativeSolutionSatisfactionHypothesis.newItemId] = newAlternativeSolutionSatisfactionHypothesis;

            return updatedMap;
        });
    }

    function deleteNewAlternativeSolutionHypothesis(newHypothesisId: string) {
        setNewAlternativeSolutionHypothesesMap(m => {
            const updatedMap = { ...m };
            delete updatedMap[newHypothesisId];
            return updatedMap;
        });
    }

    async function tryToSaveNewAlternativeSolutionHypothesis(updatedAlternativeSolutionHypothesis: NewEditableHypothesis) {
        setNewAlternativeSolutionHypothesesMap(m => ({ ...m, [updatedAlternativeSolutionHypothesis.newItemId]: updatedAlternativeSolutionHypothesis }));
        if (!(await trySaveNewHypothesis(updatedAlternativeSolutionHypothesis))) return;
        deleteNewAlternativeSolutionHypothesis(updatedAlternativeSolutionHypothesis.newItemId);
    }

    function renderHypothesesEditor(
        hypothesis: EditableHypothesis,
        newHypothesisEditorKey?: React.Key,
        newHypothesisEditorOnChange?: (updatedHypothesis: EditableHypothesis) => void
    ) {
        const isExistingHypothesis = hypothesis.id !== undefined;
        const editorEditMode = props.isEditing ? HypothesisEditMode.EditUserDefined : HypothesisEditMode.View;
        if (isExistingHypothesis)
            return (
                <HypothesisEditor
                    key={hypothesis.id}
                    value={invalidHypotheses[hypothesis.id!] ?? hypothesis}
                    validate={props.isEditing}
                    editMode={editorEditMode}
                    bordered={true}
                    onChange={e => tryUpdateHypothesis(e.value)}
                />
            );

        return (
            <HypothesisEditor
                key={newHypothesisEditorKey}
                value={hypothesis}
                validate={props.isEditing && enableHypothesesValidation}
                editMode={editorEditMode}
                bordered={true}
                onChange={newHypothesisEditorOnChange ? e => newHypothesisEditorOnChange(e.value) : undefined}
            />
        );
    }

    function renderAlternativeSolutionHypotheses(hypothesis: EditableHypothesis | NewEditableHypothesis) {
        const isNewHypothesis = isNewEditableHypothesis(hypothesis);
        return renderHypothesesEditor(
            hypothesis,
            isNewHypothesis ? hypothesis.newItemId : undefined,
            isNewHypothesis ? h => tryToSaveNewAlternativeSolutionHypothesis({ ...hypothesis, ...h }) : undefined
        );
    }

    function renderAlternativeSolutionEditor(alternativeSolutionId?: number) {
        const isAlternativeSolutionSelected = alternativeSolutionId !== undefined;
        const hasAlternativeSolutionGroups = groupedAlternativeSolutionHypotheses && groupedAlternativeSolutionHypotheses.length > 0;

        return (
            <>
                <AlternativeSolutionEditor
                    canEdit={props.isEditing}
                    alternativeSolutionId={alternativeSolutionId}
                    createButtonText="Generate hypotheses"
                    updateButtonText="Update hypotheses"
                    placeholder="Type an alternative solution"
                    valid={isAlternativeSolutionSelected ? undefined : props.isEditing && enableHypothesesValidation ? false : undefined}
                    allowNewItemCancel={isAlternativeSolutionSelected ? undefined : hasAlternativeSolutionGroups}
                    onCancel={isAlternativeSolutionSelected || !hasAlternativeSolutionGroups ? undefined : () => deleteAlternativeSolutionHypotheses(undefined)}
                    onChange={
                        !isAlternativeSolutionSelected && !hasAlternativeSolutionGroups
                            ? e => addAlternativeSolutionHypotheses(e.value)
                            : e => onAlternativeSolutionChanged(alternativeSolutionId, e.value)
                    }
                    excludedAlternativeSolutionIds={
                        isAlternativeSolutionSelected
                            ? alternativeSolutionIdsWithHypothesis?.filter(id => id !== alternativeSolutionId)
                            : alternativeSolutionIdsWithHypothesis
                    }
                />
                {props.isEditing && enableHypothesesValidation && !isAlternativeSolutionSelected && (
                    <ErrorComponent>Alternative solution is required</ErrorComponent>
                )}
            </>
        );
    }

    const showErrors = props.isEditing && !!props.errors?.length;
    const alternativeSolutionIdsWithHypothesis = groupedAlternativeSolutionHypotheses
        ?.map(g => g.alternativeSolutionId)
        .filter((alternativeSolutionId): alternativeSolutionId is number => alternativeSolutionId !== undefined);
    return (
        <EditorMainArea hasError={showErrors}>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                <HypothesesGroup title="Pain-level hypothesis">
                    <HypothesesGroupContentSection>
                        <HypothesesGroupRow
                            firstColumn="Choose what you believe the pain-level of this Customer Problem is for the Customer Segment:"
                            secondColumn={
                                isLoading ? (
                                    <Skeleton shape="rectangle" style={{ height: 82 }} />
                                ) : (
                                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                                        {!painLevelHypotheses?.length
                                            ? renderHypothesesEditor(newPainLevelHypotheses, 'newPainLevelHypothesisEditor', h =>
                                                  tryToSaveNewPainLevelHypothesis(h)
                                              )
                                            : painLevelHypotheses.map(hypothesis => renderHypothesesEditor(hypothesis))}
                                    </StackLayout>
                                )
                            }
                        />
                    </HypothesesGroupContentSection>
                </HypothesesGroup>
                <HypothesesGroup title="Alternative solutions hypotheses">
                    {isLoading ? (
                        <HypothesesGroupContentSection>
                            <HypothesesGroupRow firstColumn={<Skeleton shape="text" />} secondColumn={<Skeleton shape="rectangle" style={{ height: 82 }} />} />
                        </HypothesesGroupContentSection>
                    ) : groupedAlternativeSolutionHypotheses && groupedAlternativeSolutionHypotheses.length > 0 ? (
                        <>
                            {groupedAlternativeSolutionHypotheses.map(alternativeSolutionHypothesesGroup => (
                                <HypothesesGroupContentSection
                                    key={alternativeSolutionHypothesesGroup.alternativeSolutionId ?? 'newAlternativeSolutionHypotheses'}
                                >
                                    {alternativeSolutionHypothesesGroup.alternativeSolutionId ? (
                                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                                            <HypothesesGroupRow
                                                firstColumn={<div className="-mt-1.5">Alternative solution:</div>}
                                                secondColumn={renderAlternativeSolutionEditor(alternativeSolutionHypothesesGroup.alternativeSolutionId)}
                                            />
                                            <HypothesesGroupRow
                                                firstColumn={
                                                    <div>
                                                        <div className="k-mb-2">Hypotheses:</div>
                                                        <Button
                                                            size="small"
                                                            onClick={() =>
                                                                deleteAlternativeSolutionHypotheses(alternativeSolutionHypothesesGroup.alternativeSolutionId)
                                                            }
                                                            disabled={!props.isEditing}
                                                        >
                                                            <SvgIconButtonContent icon={DeleteIcon}>Delete solution & its hypotheses</SvgIconButtonContent>
                                                        </Button>
                                                    </div>
                                                }
                                                secondColumn={
                                                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                                                        {alternativeSolutionHypothesesGroup.alternativeSolutionHypotheses.map(h =>
                                                            renderAlternativeSolutionHypotheses(h)
                                                        )}
                                                        {alternativeSolutionHypothesesGroup.alternativeSolutionSatisfactionHypotheses.map(h =>
                                                            renderAlternativeSolutionHypotheses(h)
                                                        )}
                                                    </StackLayout>
                                                }
                                            />
                                        </StackLayout>
                                    ) : (
                                        <HypothesesGroupRow
                                            firstColumn={<div className="-mt-1.5">Alternative solution:</div>}
                                            secondColumn={renderAlternativeSolutionEditor()}
                                        />
                                    )}
                                </HypothesesGroupContentSection>
                            ))}
                            {!groupedAlternativeSolutionHypotheses.some(g => !g.alternativeSolutionId) && (
                                <HypothesesGroupContentSection key="addAlternativeSolutionHypotheses">
                                    <Button
                                        themeColor="secondary"
                                        fillMode="flat"
                                        size="small"
                                        onClick={() => addAlternativeSolutionHypotheses()}
                                        disabled={!props.isEditing}
                                    >
                                        <SvgIconButtonContent icon={PlusIcon}>Add another alternative solution</SvgIconButtonContent>
                                    </Button>
                                </HypothesesGroupContentSection>
                            )}
                        </>
                    ) : (
                        <HypothesesGroupContentSection>
                            <HypothesesGroupRow
                                firstColumn="Type an alternative solutions used by your Customer Segment to address this Customer Problem."
                                secondColumn={renderAlternativeSolutionEditor()}
                            />
                        </HypothesesGroupContentSection>
                    )}
                </HypothesesGroup>
            </StackLayout>
            <EditorErrorsList errors={props.errors} isEditing={props.isEditing} />
        </EditorMainArea>
    );
});

function registerAlternativeSolutionHypothesis(
    alternativeSolutionId: number | undefined,
    alternativeSolutionHypothesis: EditableHypothesis | NewEditableHypothesis,
    hypothesesByAlternativeSolutionGroups: AlternativeSolutionHypothesesGroup[]
): boolean {
    const isNewHypothesis = isNewEditableHypothesis(alternativeSolutionHypothesis);
    let hypothesesForAlternativeSolution = hypothesesByAlternativeSolutionGroups.find(g => g.alternativeSolutionId === alternativeSolutionId);
    if (!hypothesesForAlternativeSolution) {
        if (isNewHypothesis && alternativeSolutionHypothesis.isMissing) return false;

        hypothesesForAlternativeSolution = {
            alternativeSolutionId: alternativeSolutionId,
            alternativeSolutionHypotheses: [],
            alternativeSolutionSatisfactionHypotheses: []
        };
        hypothesesByAlternativeSolutionGroups.push(hypothesesForAlternativeSolution);
    }

    const hypothesesCollection =
        alternativeSolutionHypothesis.type === HypothesisType.AlternativeSolutionUsage
            ? hypothesesForAlternativeSolution.alternativeSolutionHypotheses
            : alternativeSolutionHypothesis.type === HypothesisType.AlternativeSolutionSatisfaction
            ? hypothesesForAlternativeSolution.alternativeSolutionSatisfactionHypotheses
            : undefined;
    if (!hypothesesCollection) return false;

    if (isNewHypothesis && hypothesesCollection.length) return false;
    hypothesesCollection.push(alternativeSolutionHypothesis);

    return true;
}

function HypothesesGroup({ title, children }: { title: string; children: ReactNode }) {
    return (
        <div className="k-icp-panel !k-rounded-md k-px-4">
            <div className="k-fs-lg k-font-medium k-pt-2 k-pb-3">{title}</div>
            {children}
        </div>
    );
}

function HypothesesGroupContentSection({ children }: { children: ReactNode }) {
    return <div className="k-icp-component-border k-icp-bordered-top k-py-4">{children}</div>;
}

function HypothesesGroupRow({ firstColumn, secondColumn }: { firstColumn: ReactNode; secondColumn: ReactNode }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
            <div className="task-hypothesis-editor-first-column">{firstColumn}</div>
            <div className="k-flex-1">{secondColumn}</div>
        </StackLayout>
    );
}

function isNewEditableHypothesis(hypothesis: EditableHypothesis): hypothesis is NewEditableHypothesis {
    return 'newItemId' in hypothesis;
}
