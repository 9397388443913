import { StackLayout, StackLayoutHandle } from '@progress/kendo-react-layout';
import { ReactElement, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as OpenInterviewIcon } from '../../icons/external-link.svg';
import { combineClassNames } from '../../services/common';
import { domService } from '../../services/domService';
import { InterviewStage } from '../../services/interviewsService';
import { InterviewMainActionButton, OpenInterviewButton, resolveInterviewLink } from './interviewMainActionButton';

type InterviewPhase = {
    title: string;
    stages: InterviewStage[];
    defaultDescription: string;
    stageDescriptions?: Partial<Record<InterviewStage, string>>;
};

const interviewPhases: InterviewPhase[] = [
    {
        title: 'Interview',
        stages: [InterviewStage.NotStarted, InterviewStage.Started, InterviewStage.PendingReview],
        defaultDescription: 'Interview has been conducted.',
        stageDescriptions: {
            [InterviewStage.NotStarted]: 'Interview has not been started yet.',
            [InterviewStage.Started]: 'Interview in progress.',
            [InterviewStage.PendingReview]: 'Interview has been conducted. Note review pending.'
        }
    },
    {
        title: 'Insights',
        stages: [InterviewStage.PendingInsightCapture],
        defaultDescription: 'Insights have been captured.',
        stageDescriptions: {
            [InterviewStage.NotStarted]: 'Insights should be captured after interview is conducted.',
            [InterviewStage.Started]: 'Insights should be captured after interview is conducted.',
            [InterviewStage.PendingReview]: 'Insights should be captured after interview notes are reviewed.',
            [InterviewStage.PendingInsightCapture]: 'Insight capture in progress.'
        }
    },
    {
        title: 'Hypotheses',
        stages: [InterviewStage.PendingHypothesesEvaluation],
        defaultDescription: 'Hypotheses cannot be evaluated before insights are captured.',
        stageDescriptions: {
            [InterviewStage.PendingHypothesesEvaluation]: 'Hypotheses evaluation in progress.',
            [InterviewStage.Complete]: 'All relevant hypotheses evaluated.'
        }
    }
];

const interviewStageToPhaseIndexMap = interviewPhases.reduce(
    (interviewStageToPhaseIndexMap, phase, phaseIndex) => {
        for (const phaseStage of phase.stages) {
            interviewStageToPhaseIndexMap[phaseStage] = phaseIndex;
        }
        return interviewStageToPhaseIndexMap;
    },
    { [InterviewStage.Complete]: interviewPhases.length } as Record<InterviewStage, number>
);

export type InterviewPhasesBreadcrumbProps = {
    currentInterviewStage: InterviewStage;
    interviewId: number;
    ideaId: string;
    onPhaseClick?: () => void;
};
export function InterviewPhasesBreadcrumb({ currentInterviewStage, interviewId, ideaId, onPhaseClick }: InterviewPhasesBreadcrumbProps) {
    const currentPhaseIndex = interviewStageToPhaseIndexMap[currentInterviewStage];
    const navigate = useNavigate();
    const progressCanvasRef = useRef<HTMLCanvasElement>(null);
    const phasesStackRef = useRef<StackLayoutHandle>(null);
    const phasesViewsRefs = useRef<(InterviewPhaseViewHandle | null)[]>([]);

    useEffect(() => {
        const progressCanvasElement = progressCanvasRef.current;
        const phasesWrapperElement = phasesStackRef.current?.element;
        if (!progressCanvasElement || !phasesWrapperElement) return;

        const progressDrawer = new InterviewPhasesProgressDrawer(progressCanvasElement);

        const draw = () => {
            const phasesWrapperOffset = domService.getRelativeOffset(phasesWrapperElement);
            const phaseTitlesWrapperRelativeVerticalOffsets = phasesViewsRefs.current.map(phaseViewRef =>
                phaseViewRef?.titleElement
                    ? phaseViewRef.titleElement.clientHeight / 2 + domService.getRelativeOffset(phaseViewRef?.titleElement).top - phasesWrapperOffset.top
                    : 0
            );

            progressDrawer.draw(phaseTitlesWrapperRelativeVerticalOffsets, currentPhaseIndex);
        };

        draw();

        const phasesWrapperResizeObserver = new ResizeObserver(draw);
        phasesWrapperResizeObserver.observe(phasesWrapperElement);

        return () => phasesWrapperResizeObserver.disconnect();
    }, [currentPhaseIndex]);
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }}>
            <canvas ref={progressCanvasRef} width={19} style={{ marginRight: 9.5 }} />
            <StackLayout ref={phasesStackRef} orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-2">
                {interviewPhases.map((interviewPhase, interviewPhaseIndex) => {
                    return (
                        <InterviewPhaseView
                            ref={r => (phasesViewsRefs.current[interviewPhaseIndex] = r)}
                            key={interviewPhaseIndex}
                            title={interviewPhase.title}
                            description={interviewPhase.stageDescriptions?.[currentInterviewStage] || interviewPhase.defaultDescription}
                            highlighted={interviewPhaseIndex === currentPhaseIndex}
                            disabled={interviewPhaseIndex > currentPhaseIndex}
                            onClick={() => {
                                onPhaseClick?.();
                                navigate(
                                    resolveInterviewLink(
                                        ideaId,
                                        interviewId,
                                        interviewPhaseIndex === currentPhaseIndex ? undefined : interviewPhase.stages.at(-1)
                                    ),
                                    { replace: true }
                                );
                            }}
                            action={
                                interviewPhaseIndex === currentPhaseIndex ? (
                                    <InterviewMainActionButton
                                        ideaId={ideaId}
                                        interviewId={interviewId}
                                        interviewStage={currentInterviewStage}
                                        fillMode="flat"
                                        themeColor="secondary"
                                        size="small"
                                        onClick={onPhaseClick}
                                        replaceHistory
                                    />
                                ) : (
                                    <OpenInterviewButton
                                        ideaId={ideaId}
                                        interviewId={interviewId}
                                        view={interviewPhase.stages.at(-1)}
                                        fillMode="flat"
                                        themeColor="secondary"
                                        size="small"
                                        className="k-icp-svg-icon-button"
                                        onClick={onPhaseClick}
                                        replaceHistory
                                    >
                                        <OpenInterviewIcon className="k-icp-icon" />
                                    </OpenInterviewButton>
                                )
                            }
                        />
                    );
                })}
            </StackLayout>
        </StackLayout>
    );
}

type InterviewPhaseViewHandle = {
    titleElement: HTMLElement | null;
};
type InterviewPhaseViewProps = {
    title: string;
    description: string;
    action?: ReactElement;
    disabled?: boolean;
    onClick?: () => void;
    highlighted?: boolean;
};
const InterviewPhaseView = forwardRef<InterviewPhaseViewHandle, InterviewPhaseViewProps>(function InterviewPhaseView(
    { title, description, action, disabled, onClick, highlighted },
    ref
) {
    const titleElementRef = useRef<HTMLElement>(null);
    useImperativeHandle(
        ref,
        () => ({
            get titleElement() {
                return titleElementRef.current;
            }
        }),
        []
    );

    return (
        <div
            onClick={disabled ? undefined : onClick}
            className={combineClassNames(
                'k-p-3 k-border k-border-solid k-rounded',
                disabled ? 'k-icp-border-secondary-24' : 'k-border-secondary',
                highlighted ? 'k-icp-bg-component' : 'k-icp-bg-component-40',
                onClick && !disabled ? 'k-cursor-pointer' : undefined
            )}
        >
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1 k-justify-content-between k-mb-2">
                <span ref={titleElementRef} className="k-font-semibold k-icp-line-height-inline-md k-mt-hair">
                    {title}
                </span>
                <div style={{ minHeight: 24 }} onClick={onClick && !disabled ? e => e.stopPropagation() : undefined}>
                    {disabled ? undefined : action}
                </div>
            </StackLayout>
            <div className="k-fs-sm">{description}</div>
        </div>
    );
});

const PROGRESS_DRAWER_NODE_RADIUS = 2.5;
const PROGRESS_DRAWER_COLOR = '#0053a6';
const PROGRESS_DRAWER_HORIZONTAL_SPREAD = 16;
class InterviewPhasesProgressDrawer {
    constructor(private readonly canvas: HTMLCanvasElement) {}

    draw(nodesVerticalPositions: number[], maxAvailableNodeIndex?: number): void {
        const drawingContext = this.canvas.getContext('2d');
        if (!drawingContext) throw new Error('Canvas 2d context not supported.');

        this.clearCanvas(drawingContext);
        this.applySizeAndScale(drawingContext);

        const nodeHorizontalPosition = this.canvas.clientWidth - PROGRESS_DRAWER_NODE_RADIUS;
        this.drawPathsBetweenNodes(drawingContext, nodeHorizontalPosition, nodesVerticalPositions, maxAvailableNodeIndex);

        drawingContext.fillStyle = PROGRESS_DRAWER_COLOR;
        for (const nodeVerticalPosition of nodesVerticalPositions) this.drawNode(drawingContext, nodeHorizontalPosition, nodeVerticalPosition);
    }

    private drawPathsBetweenNodes(
        drawingContext: CanvasRenderingContext2D,
        nodeHorizontalPosition: number,
        nodesVerticalPositions: number[],
        maxAvailableNodeIndex: number | undefined
    ) {
        drawingContext.strokeStyle = PROGRESS_DRAWER_COLOR;
        drawingContext.lineWidth = 1;
        drawingContext.setLineDash([4, 4]);

        const startNodeVerticalPosition = nodesVerticalPositions[0];
        for (let endNodeIndex = nodesVerticalPositions.length - 1; endNodeIndex > 0; endNodeIndex--) {
            if (endNodeIndex === maxAvailableNodeIndex) drawingContext.setLineDash([]);

            const endNodeVerticalPosition = nodesVerticalPositions[endNodeIndex];
            drawingContext.beginPath();
            drawingContext.moveTo(nodeHorizontalPosition, startNodeVerticalPosition);
            drawingContext.lineTo(nodeHorizontalPosition - PROGRESS_DRAWER_HORIZONTAL_SPREAD, startNodeVerticalPosition);
            drawingContext.lineTo(nodeHorizontalPosition - PROGRESS_DRAWER_HORIZONTAL_SPREAD, endNodeVerticalPosition);
            drawingContext.lineTo(nodeHorizontalPosition, endNodeVerticalPosition);
            drawingContext.stroke();
        }
    }

    private clearCanvas(drawingContext: CanvasRenderingContext2D) {
        drawingContext.clearRect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
    }

    private applySizeAndScale(drawingContext: CanvasRenderingContext2D) {
        this.canvas.style.height = 'auto';

        const scaleFactor = this.getDrawingScaleFactor();
        const initialWidth = this.canvas.clientWidth;
        this.canvas.width = initialWidth * scaleFactor;
        this.canvas.style.width = initialWidth + 'px';
        const initialHeight = this.canvas.clientHeight;
        this.canvas.height = initialHeight * scaleFactor;
        this.canvas.style.height = initialHeight + 'px';

        drawingContext.scale(scaleFactor, scaleFactor);
    }

    private drawNode(drawingContext: CanvasRenderingContext2D, x: number, y: number) {
        drawingContext.beginPath();
        drawingContext.arc(x, y, PROGRESS_DRAWER_NODE_RADIUS, 0, 2 * Math.PI, false);
        drawingContext.fill();
    }

    private getDrawingScaleFactor() {
        return Math.max(window.devicePixelRatio, 2);
    }
}
