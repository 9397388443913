import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useGlobalCanvas } from '../../hooks/canvasHooks';
import { useCacheBust } from '../../hooks/commonHooks';
import skipImage from '../../images/skip-animation.svg';
import { BoxItem, BoxType } from '../../services/canvasService';
import { ChangingSegmentsWarning, MarkerTransitionWarning, SkippingTasksWarning } from '../../services/journeyService';
import { H1, P } from '../ui/typography';

export function TaskTransitionDialog({ onConfirm, onCancel, warning }: { onConfirm: () => void; onCancel: () => void; warning: MarkerTransitionWarning }) {
    const warningType = warning.warningType;
    if (warningType === 'TaskSkip') return <TaskSkippingDialog warning={warning} onConfirm={onConfirm} onCancel={onCancel} />;
    if (warningType === 'SegmentChange') return <SegmentsChangingDialog warning={warning} onConfirm={onConfirm} onCancel={onCancel} />;

    throw new Error(`Unknown warning type: ${warningType}`);
}

export function TaskSkippingDialog({ onConfirm, onCancel, warning }: { onConfirm: () => void; onCancel: () => void; warning: SkippingTasksWarning }) {
    return (
        <TaskTransitionDialogBase
            title="Skipping tasks"
            confirmButtonText={`I want to work on Task ${warning.newTaskNumber}`}
            onConfirm={onConfirm}
            onCancel={onCancel}
        >
            You are about to move from{' '}
            <strong>
                Task {warning.oldTaskNumber}: {warning.oldTaskName}
            </strong>{' '}
            directly to{' '}
            <strong>
                Task {warning.newTaskNumber}: {warning.newTaskName}
            </strong>
            . It is recommended to follow the sequence.
        </TaskTransitionDialogBase>
    );
}

export function SegmentsChangingDialog({ onConfirm, onCancel, warning }: { onConfirm: () => void; onCancel: () => void; warning: ChangingSegmentsWarning }) {
    const [oldCustomerSegment, setOldCustomerSegment] = useState<BoxItem>();
    const [newCustomerSegment, setNewCustomerSegment] = useState<BoxItem>();
    const { canvas } = useGlobalCanvas(undefined, true);

    const onConfirmRef = useRef(onConfirm);
    onConfirmRef.current = onConfirm;
    useEffect(() => {
        if (!canvas.boxes) return;

        const customerSegments = canvas.boxes.find(b => b.type === BoxType.CustomerSegments)?.items;
        const oldCustomerSegment = customerSegments?.find(cs => cs.id === warning.oldSegmentId);
        const newCustomerSegment = customerSegments?.find(cs => cs.id === warning.newSegmentId);

        // If one of the segments is missing, we are assuming that it's deleted so the dialog is auto-confirmed
        if (!oldCustomerSegment || !newCustomerSegment) onConfirmRef.current();
        else {
            setOldCustomerSegment(oldCustomerSegment);
            setNewCustomerSegment(newCustomerSegment);
        }
    }, [canvas.boxes, warning.newSegmentId, warning.oldSegmentId]);

    if (!oldCustomerSegment || !newCustomerSegment) return null;

    function renderCustomerSegmentInWarning(customerSegment: BoxItem) {
        return (
            <>
                {customerSegment.colorCode && (
                    <>
                        <span className="k-white-space-nowrap">
                            <span className="palette-color k-display-inline-block k-align-sub" style={{ backgroundColor: `#${customerSegment.colorCode}` }} />
                        </span>
                        &nbsp;
                    </>
                )}
                <strong>{customerSegment.content}</strong>
            </>
        );
    }

    return (
        <TaskTransitionDialogBase
            title="Switching Customer Segments"
            confirmButtonText="Proceed to new Customer Segment"
            onConfirm={onConfirm}
            onCancel={onCancel}
        >
            You are about to switch from working on Customer Segment {renderCustomerSegmentInWarning(oldCustomerSegment)} to Customer Segment{' '}
            {renderCustomerSegmentInWarning(newCustomerSegment)}. Only proceed if this is intentional.
        </TaskTransitionDialogBase>
    );
}

function TaskTransitionDialogBase({
    title,
    confirmButtonText,
    onConfirm,
    onCancel,
    children
}: {
    title: string;
    confirmButtonText: string;
    onConfirm: () => void;
    onCancel: () => void;
    children: ReactNode;
}) {
    const animationCacheBust = useCacheBust();
    return (
        <Dialog closeIcon={false} width={480} onClose={onCancel}>
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-4 k-px-8 k-py-4">
                <img src={`${skipImage}?t=${animationCacheBust}`} alt="Task completion" width={480} height={86} className="responsive-image" />
                <div className="k-text-center">
                    <H1 className="!k-mb-3">{title}</H1>
                    <P>{children}</P>
                </div>
            </StackLayout>
            <DialogActionsBar layout="center">
                <Button fillMode="outline" themeColor="secondary" onClick={onConfirm}>
                    {confirmButtonText}
                </Button>
                <Button fillMode="outline" themeColor="base" onClick={onCancel}>
                    Cancel
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}
