import { Form } from '@progress/kendo-react-form';
import { useState } from 'react';
import { useReturnUrl } from '../../hooks/routerHooks';
import emailSentImageUrl from '../../images/auth/reset-password-sent-illustration.svg';
import { AuthForm, AuthFormSubmitButton } from '../../pages/layouts/authLayout';
import { authenticationService } from '../../services/authenticationService';
import { HttpException } from '../../services/httpServiceBase';
import { useInvisibleReCaptcha } from '../common/invisibleReCaptcha';
import { EmailField } from '../ui/inputs';
import { H1, P } from '../ui/typography';

export function ForgottenPasswordForm({ onSubmit }: { onSubmit?: (email: string) => void }) {
    const returnUrl = useReturnUrl();
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>();
    const getReCaptchaToken = useInvisibleReCaptcha();

    async function onForgottenPasswordSubmit(data: Record<string, string>) {
        try {
            const reCaptchaToken = await getReCaptchaToken?.();
            await authenticationService.forgotPassword(data.emailAddress, returnUrl, reCaptchaToken);
            onSubmit?.(data.emailAddress);
        } catch (e) {
            if (e instanceof HttpException && e.status === 404) {
                setEmailErrorMessage('User with this email does not exist');
            } else if (e instanceof HttpException && e.status === 409) {
                setEmailErrorMessage('Cannot reset password for user. Contact support@icanpreneur.com.');
            } else {
                throw e;
            }
        }
    }

    return (
        <Form
            onSubmit={onForgottenPasswordSubmit}
            ignoreModified={true}
            render={formRenderProps => (
                <AuthForm button={<AuthFormSubmitButton disabled={!formRenderProps.allowSubmit}>Send reset instructions</AuthFormSubmitButton>} gap={6}>
                    <P>No worries, just enter your email address below. If you have an account, a link will be sent to reset your password.</P>
                    <EmailField errorMessage={emailErrorMessage} onChange={() => setEmailErrorMessage(undefined)} />
                </AuthForm>
            )}
        />
    );
}

export function ForgottenPasswordRequested({ emailAddress }: { emailAddress: string }) {
    return (
        <>
            <H1>Check your email address</H1>
            <img src={emailSentImageUrl} width="230" height="160" alt="Reset password email sent" />
            <div className="auth-page-content-section">
                <P className="!k-mb-4">
                    An email with password reset instructions was sent to <strong>{emailAddress}</strong>.
                </P>
                <P>If you don't see the message, you may need to check your spam folder.</P>
            </div>
        </>
    );
}
