import { TextAreaProps } from '@progress/kendo-react-inputs';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { findCanvasItem, useGlobalCanvas } from '../../hooks/canvasHooks';
import { useAsRef } from '../../hooks/commonHooks';
import { BoxType } from '../../services/canvasService';
import { combineClassNames, toRecord } from '../../services/common';
import { InsightParameter, InsightParameterValue, InsightPropertyType, InsightTemplateParameter } from '../../services/insightsService';
import { saveItem } from '../../state/canvas/canvasSlice';
import { AppDispatch } from '../../state/store';
import {
    InlineEditor,
    InlineEditorComponentHandle,
    InlineEditorComponentProps,
    InlineEditorHandle,
    InlineEditorProps,
    InlineEditorTextArea,
    InlineEditorViewerDefaultLayout,
    InlineEditorViewerProps
} from '../common/inlineEditor';
import { ServerTemplate } from '../common/serverTemplate';
import { TextTokenPredefinedItem, TextTokenWrapper, TextTokenWrapperHandle, TextTokensEditorLayout } from '../common/tokenizedTextEditor';
import { ValidationSubScope, ValidationSubScopeHandle, ValidationUnit } from '../common/validation';
import { requiredValidator } from '../ui/inputs';

export type InsightEditorHandle = {
    element?: HTMLElement | null;
    isValid: boolean;
};

export type InsightEditorProps = {
    content: string | null;
    parameters?: (InsightTemplateParameter | InsightParameter)[];
    parametersValues?: InsightParameterValue[];
    inEdit?: boolean;
    onSave?: (parameterValues: InsightParameterValue[]) => Promise<void> | void;
    onDelete?: () => void;
    disabled?: boolean;
    invalid?: boolean;
    onClick?: () => void;
    hideEditControls?: boolean;
    hideActions?: boolean;
    readonly?: boolean;
    onParameterChange?: (parameterId: string, parameterValue: unknown) => void;
};

export const InsightEditor = forwardRef<InsightEditorHandle, InsightEditorProps>(function InsightEditor(
    { content, parameters, parametersValues, inEdit, disabled, onSave, onDelete, onClick, hideEditControls, hideActions, readonly, invalid, onParameterChange },
    ref
) {
    const inlineEditorRef = useRef<InlineEditorHandle>(null);
    const validationSubScopeRef = useRef<ValidationSubScopeHandle>(null);
    const [overriddenValuesMap, setOverriddenValuesMapInternal] = useState<Partial<Record<string, unknown>>>();
    const overriddenValuesMapRef = useAsRef(overriddenValuesMap);
    function setOverriddenValuesMap(
        overriddenValuesMap:
            | Partial<Record<string, unknown>>
            | undefined
            | ((overriddenValuesMap: Partial<Record<string, unknown>> | undefined) => Partial<Record<string, unknown>> | undefined)
    ) {
        if (overriddenValuesMap && typeof overriddenValuesMap === 'function')
            overriddenValuesMapRef.current = overriddenValuesMap(overriddenValuesMapRef.current);
        else overriddenValuesMapRef.current = overriddenValuesMap;
        setOverriddenValuesMapInternal(overriddenValuesMap);
    }
    const [isEditingInternal, setIsEditing] = useState<boolean>();
    const isEditing = inEdit ?? isEditingInternal;

    const providedValuesMap =
        parametersValues &&
        toRecord(
            parametersValues,
            v => v.id,
            v => v.value
        );
    const valuesMap = providedValuesMap && overriddenValuesMap ? { ...providedValuesMap, ...overriddenValuesMap } : overriddenValuesMap ?? providedValuesMap;

    function updateParameter(parameterId: string, value: unknown) {
        setOverriddenValuesMap(overriddenValuesMap => (overriddenValuesMap ? { ...overriddenValuesMap, [parameterId]: value } : { [parameterId]: value }));
        onParameterChange?.(parameterId, value);
    }

    useImperativeHandle(
        ref,
        () => ({
            get element() {
                return inlineEditorRef.current?.editor?.element;
            },
            get isValid() {
                if (!validationSubScopeRef.current) return true;

                return validationSubScopeRef.current.isValid;
            }
        }),
        []
    );

    const commonInlineEditorProps: Partial<InlineEditorProps<any, any, any>> = {
        isEditing: isEditing,
        onEdit: readonly || disabled ? undefined : () => setIsEditing(true),
        onCancel: () => {
            setIsEditing(false);
            setOverriddenValuesMap(undefined);
        },
        onSave: async () => {
            if (validationSubScopeRef.current && !validationSubScopeRef.current.isValid) return;
            const overriddenValuesMap = overriddenValuesMapRef.current;
            if (!overriddenValuesMap) {
                setIsEditing(false);
                return;
            }

            const updatedValues = parametersValues
                ? parametersValues.map(v => {
                      if (v.id in overriddenValuesMap) return { ...v, value: overriddenValuesMap[v.id] };

                      return v;
                  })
                : [];

            for (const overriddenParameterId in overriddenValuesMap) {
                if (!Object.prototype.hasOwnProperty.call(overriddenValuesMap, overriddenParameterId)) continue;
                if (updatedValues.some(v => v.id === overriddenParameterId)) continue;

                updatedValues.push({ id: overriddenParameterId, value: overriddenValuesMap[overriddenParameterId] });
            }

            await onSave?.(updatedValues);
            setIsEditing(false);
            setOverriddenValuesMap(undefined);
        },
        actions:
            hideActions || readonly || disabled
                ? undefined
                : [
                      {
                          text: 'Edit',
                          action() {
                              setIsEditing(true);
                          }
                      },
                      {
                          text: 'Delete',
                          danger: true,
                          separated: true,
                          action: onDelete
                      }
                  ],
        additionalEditControls: isEditing ? (
            <span className="k-fs-sm k-icp-subtle-text">Editing an insight updates it in all interviews where it is attached.</span>
        ) : (
            undefined
        ),
        autoFocus: true,
        onClick: onClick,
        hideEditControls: hideEditControls || readonly || disabled,
        invalid: invalid,
        disabled: disabled
    };

    if (!content) {
        if (!parameters || parameters.length !== 1) throw new Error('One insight parameter should be provided when the content is missing!');

        const contentParameter = parameters[0];
        const contentParameterId = contentParameter.id;
        const contentParameterValue = valuesMap?.[contentParameterId] as string | undefined;
        if (contentParameterValue && typeof contentParameterValue !== 'string')
            throw new Error('When the insight content is missing the parameter value should be of type string!');

        return (
            <ValidationSubScope ref={validationSubScopeRef}>
                <InlineEditor
                    ref={inlineEditorRef}
                    {...commonInlineEditorProps}
                    value={contentParameterValue}
                    onEditorChange={({ value }) => updateParameter(contentParameterId, value)}
                    viewer={FreeTextInsightViewer}
                    viewerSettings={{
                        placeholder: contentParameter.placeholder
                    }}
                    editor={FreeTextInsightEditor}
                    editorSettings={{
                        validationPrefix: contentParameterId,
                        placeholder: contentParameter.placeholder,
                        readOnly: readonly
                    }}
                />
            </ValidationSubScope>
        );
    }

    const parametersMap = parameters && toRecord(parameters, p => p.id);

    return (
        <ValidationSubScope ref={validationSubScopeRef}>
            <InlineEditor
                ref={inlineEditorRef}
                {...commonInlineEditorProps}
                value={valuesMap}
                viewer={TemplatedInsightsViewer}
                viewerSettings={{
                    content: content,
                    parameters: parametersMap,
                    disabled: disabled
                }}
                editor={TemplatedInsightsEditor}
                editorSettings={{
                    content: content,
                    parameters: parametersMap,
                    onParameterChange: updateParameter,
                    readOnly: readonly,
                    inEdit: true
                }}
                autoFocus
            />
        </ValidationSubScope>
    );
});

const customInsightContentValidator = requiredValidator('Content');
const FreeTextInsightEditor = forwardRef<
    InlineEditorComponentHandle,
    InlineEditorComponentProps<string, { validationPrefix: string; placeholder?: string; readOnly?: boolean }>
>(function(props, ref) {
    const settings: TextAreaProps = {
        placeholder: props.settings?.placeholder,
        readOnly: props.settings?.readOnly,
        rows: 1,
        autoSize: true,
        className: 'k-textarea-no-resize'
    };
    if (!settings.readOnly)
        return (
            <ValidationUnit name={props.settings?.validationPrefix ?? ''} value={props.value} validator={customInsightContentValidator}>
                {(validationErrorMessage, onValidationChange) => (
                    <InlineEditorTextArea
                        ref={ref}
                        {...props}
                        settings={settings}
                        onChange={e => {
                            onValidationChange(e.value);
                            props.onChange?.(e);
                        }}
                        valid={props.valid && !validationErrorMessage}
                    />
                )}
            </ValidationUnit>
        );

    return <InlineEditorTextArea ref={ref} {...props} settings={settings} />;
});

function FreeTextInsightViewer(props: InlineEditorViewerProps<string, { placeholder?: string }>) {
    const content = props.value ? (
        props.renderValue ? (
            props.renderValue(props.value)
        ) : (
            props.value
        )
    ) : props.settings?.placeholder ? (
        <span className="k-icp-placeholder-text">{props.settings.placeholder}</span>
    ) : (
        undefined
    );

    if (!content) return null;
    return <InlineEditorViewerDefaultLayout>{content}</InlineEditorViewerDefaultLayout>;
}

function TemplatedInsightsViewer(
    props: InlineEditorViewerProps<Partial<Record<string, unknown>>, { disabled?: boolean } & Pick<TemplatedInsightsEditorSettings, 'content' | 'parameters'>>
) {
    if (!props.settings) throw new Error('Settings are required');

    return <TemplatedInsightsEditor value={props.value} settings={{ ...props.settings, inEdit: false }} disabled={props.settings.disabled} valid />;
}

const requiredParameterValidator = requiredValidator('Parameter');
type TemplatedInsightsEditorSettings = {
    content: string;
    parameters?: Partial<Record<string, InsightTemplateParameter | InsightParameter>>;
    onParameterChange?: (parameterId: string, value: unknown) => void;
    readOnly?: boolean;
    inEdit?: boolean;
};
const TemplatedInsightsEditor = forwardRef<
    InlineEditorComponentHandle,
    InlineEditorComponentProps<Partial<Record<string, unknown>>, TemplatedInsightsEditorSettings>
>(function TemplatedInsightsEditor(props, ref) {
    const wrapperElementRef = useRef<HTMLDivElement>(null);
    const firstEditableTokenWrapperRef = useRef<TextTokenWrapperHandle | null>(null);

    useImperativeHandle(
        ref,
        () => ({
            get element() {
                return wrapperElementRef.current;
            },
            focus() {
                firstEditableTokenWrapperRef.current?.token?.focus();
            }
        }),
        []
    );

    if (!props.settings) throw new Error('Settings are required');

    const parameters = props.settings.parameters;
    const parametersValues = props.value;
    const onParameterChange = props.settings.onParameterChange;
    const disabled = props.disabled;
    const readOnly = props.settings.readOnly;
    const inEdit = props.settings.inEdit;

    let firstEditableParameterName: string | undefined;

    return (
        <TextTokensEditorLayout
            ref={wrapperElementRef}
            bordered={inEdit}
            inEdit={inEdit && !readOnly}
            invalid={!props.valid}
            className={combineClassNames('k-flex-1', inEdit ? 'inline-editor-padded' : undefined)}
        >
            <ServerTemplate
                evaluateParameter={parameterName => {
                    if (!parameters) throw new Error('parameters not provided');

                    const parameter = parameters[parameterName];
                    if (!parameter) throw new Error(`Missing parameter ${parameterName}`);

                    const parameterValue = parametersValues?.[parameterName];

                    const isParameterReadOnly = isInsightParameterReadOnly(parameter);

                    const isParameterEditable = inEdit && !isParameterReadOnly;
                    if (isParameterEditable && !firstEditableParameterName) firstEditableParameterName = parameterName;

                    const commonParameterEditorProps: InsightParameterEditorProps = {
                        value: parameterValue,
                        readOnly: !isParameterEditable || disabled || readOnly,
                        placeholder: parameter.placeholder,
                        disabled: (!inEdit && disabled) || isParameterReadOnly,
                        onParameterChange: onParameterChange && (parameterValue => onParameterChange(parameterName, parameterValue))
                    };

                    const canvasParameterProps = resolveCanvasInsightParameterEditorAdditionalProps(parameter.type);
                    const parameterEditorProps = canvasParameterProps ? { ...commonParameterEditorProps, ...canvasParameterProps } : commonParameterEditorProps;
                    const ParameterEditorType = canvasParameterProps ? CanvasInsightParameterEditor : DefaultInsightParameterEditor;

                    return isParameterEditable ? (
                        <ValidationUnit key={parameterName} name={parameterName} value={parameterValue} validator={requiredParameterValidator}>
                            {(errorMessage, onParameterValidationChange) => (
                                <ParameterEditorType
                                    ref={parameterName === firstEditableParameterName ? firstEditableTokenWrapperRef : undefined}
                                    {...(parameterEditorProps as any)}
                                    invalid={!!errorMessage}
                                    onParameterChange={v => {
                                        onParameterValidationChange(v);
                                        parameterEditorProps.onParameterChange?.(v);
                                    }}
                                />
                            )}
                        </ValidationUnit>
                    ) : (
                        <ParameterEditorType
                            key={parameterName}
                            ref={parameterName === firstEditableParameterName ? firstEditableTokenWrapperRef : undefined}
                            {...(parameterEditorProps as any)}
                        />
                    );
                }}
            >
                {props.settings.content}
            </ServerTemplate>
        </TextTokensEditorLayout>
    );
});

function isInsightParameterReadOnly(parameter: InsightTemplateParameter | InsightParameter) {
    if ('readonly' in parameter) return parameter.readonly;
    if ('locked' in parameter) return parameter.locked;
}

type InsightParameterEditorProps = {
    value: unknown;
    onParameterChange?: (value: unknown) => void;
    readOnly?: boolean;
    placeholder?: string;
    disabled?: boolean;
    invalid?: boolean;
};

const DefaultInsightParameterEditor = forwardRef<TextTokenWrapperHandle, InsightParameterEditorProps>(function DefaultInsightParameterEditor(
    { value, onParameterChange, readOnly, placeholder, disabled, invalid },
    ref
) {
    return (
        <TextTokenWrapper
            ref={ref}
            readonly={readOnly}
            placeholder={placeholder}
            onChange={onParameterChange && (({ value }) => onParameterChange(value ? value : undefined))}
            forceEdit={!disabled}
            forceView={disabled}
            invalid={invalid}
        >
            {value === undefined ? undefined : String(value)}
        </TextTokenWrapper>
    );
});

function resolveCanvasInsightParameterEditorAdditionalProps(parameterType: InsightPropertyType): CanvasInsightParameterEditorAdditionalProps | undefined {
    if (parameterType === InsightPropertyType.CustomerSegment)
        return {
            box: BoxType.CustomerSegments,
            existingItemsOnly: true
        };

    if (parameterType === InsightPropertyType.JobToBeDone)
        return {
            box: BoxType.JobsToBeDone,
            existingItemsOnly: true
        };

    if (parameterType === InsightPropertyType.AlternativeSolution)
        return {
            box: BoxType.AlternativeSolutions,
            existingItemsOnly: false
        };

    return undefined;
}

type CanvasInsightParameterEditorAdditionalProps = {
    box: BoxType;
    existingItemsOnly?: boolean;
};
export type CanvasInsightParameterInstantValue = {
    itemId?: number;
    box: BoxType;
    content: string;
};
const CanvasInsightParameterEditor = forwardRef<TextTokenWrapperHandle, InsightParameterEditorProps & CanvasInsightParameterEditorAdditionalProps>(
    function CanvasInsightParameterEditor({ box, value, existingItemsOnly, onParameterChange, readOnly, placeholder, disabled, invalid }, ref) {
        const isInstantValue = isCanvasInsightParameterInstantValue(value);
        const isNumber = value !== undefined && typeof value === 'number';

        const { canvas } = useGlobalCanvas(undefined, true);
        const canvasItem = isNumber ? findCanvasItem(canvas.boxes, box, value) : undefined;
        const itemId = isNumber ? value : isInstantValue ? value.itemId : undefined;

        const predefinedItems = disabled
            ? undefined
            : canvas.boxes
                  ?.find(b => b.type === box)
                  ?.items.map<TextTokenPredefinedItem>(i => ({ text: i.content, value: i.id }));

        return (
            <TextTokenWrapper
                ref={ref}
                readonly={readOnly}
                placeholder={placeholder}
                onChange={
                    existingItemsOnly
                        ? undefined
                        : onParameterChange &&
                          (({ value: textValue }) => {
                              if (!textValue) {
                                  onParameterChange(undefined);
                                  return;
                              }

                              const existingSolution = predefinedItems?.find(pi => pi.text.toLowerCase().trim() === textValue.toLowerCase().trim());
                              if (existingSolution) {
                                  onParameterChange(existingSolution.value);
                                  return;
                              }

                              const instantValue: CanvasInsightParameterInstantValue = {
                                  itemId: itemId,
                                  box: box,
                                  content: textValue
                              };
                              onParameterChange(instantValue);
                          })
                }
                forceEdit={!disabled}
                forceView={disabled}
                isLoading={!canvas.boxes}
                loaderWidth={250}
                predefinedValues={predefinedItems}
                predefinedValuesOnly={existingItemsOnly}
                onPredefinedItemSelected={onParameterChange && (({ value }) => onParameterChange(value))}
                invalid={invalid}
            >
                {isNumber ? canvasItem?.content : isInstantValue ? value.content : undefined}
            </TextTokenWrapper>
        );
    }
);

function isCanvasInsightParameterInstantValue(value: unknown): value is CanvasInsightParameterInstantValue {
    if (!value) return false;

    if (typeof value === 'object' && 'box' in value) return true;

    return false;
}

export async function saveCanvasInsightParameterInstantValues(
    dispatch: AppDispatch,
    allowUpdate: boolean,
    parameters: (InsightTemplateParameter | InsightParameter)[],
    parametersValues: InsightParameterValue[]
): Promise<InsightParameterValue[]> {
    const updatedParametersValues: InsightParameterValue[] = [];
    let hasUpdatedParameter = false;
    const parametersMap = toRecord(parameters, p => p.id);
    for (const parameterValue of parametersValues) {
        const parameter = parametersMap[parameterValue.id];
        if (
            !parameter ||
            (parameter.type !== InsightPropertyType.AlternativeSolution &&
                parameter.type !== InsightPropertyType.CustomerSegment &&
                parameter.type !== InsightPropertyType.JobToBeDone)
        ) {
            updatedParametersValues.push(parameterValue);
            continue;
        }

        const value = parameterValue.value;
        const isInstantCanvasValue = isCanvasInsightParameterInstantValue(value);
        if (!isInstantCanvasValue) {
            updatedParametersValues.push(parameterValue);
            continue;
        }

        hasUpdatedParameter = true;
        const savedItem = await dispatch(
            saveItem({
                itemId: allowUpdate ? value.itemId : undefined,
                boxType: value.box,
                content: value.content
            })
        ).unwrap();

        if (!savedItem) {
            updatedParametersValues.push(parameterValue);
            continue;
        }

        updatedParametersValues.push({ id: parameterValue.id, value: savedItem.itemId });
    }

    return hasUpdatedParameter ? updatedParametersValues : parametersValues;
}
