import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useNavigate, useParams } from 'react-router-dom';
import { useCountries } from '../../components/common/countries';
import { CompanyEditor } from '../../components/contacts/edit';
import { H1 } from '../../components/ui/typography';
import { useStickyFooter } from '../../hooks/commonHooks';
import { CompanyEditData, contactsService } from '../../services/contactsService';

export function CreateCompanyPage() {
    const { ideaId } = useParams();
    const countriesList = useCountries();

    const navigate = useNavigate();
    const footerRef = useStickyFooter();

    return (
        <>
            <H1 className="!k-mb-6 !k-px-6">Add company</H1>
            <Form
                onSubmit={async (data: Record<string, any>) => {
                    const createData: CompanyEditData = {
                        name: data.name,
                        address: data.address || null,
                        city: data.city || null,
                        countryCode: data.country?.code,
                        facebook: data.facebook || null,
                        linkedIn: data.linkedIn || null,
                        twitter: data.twitter || null,
                        website: data.website || null,
                        picture: data.picture
                    };

                    await contactsService.createCompany(ideaId!, createData);
                    navigate('./..');
                }}
                ignoreModified={true}
                render={formRenderProps => (
                    <FormElement noValidate={true}>
                        <CompanyEditor countriesList={countriesList} className="k-px-6" />
                        <StackLayout
                            ref={r => (footerRef.current = r?.element ?? null)}
                            align={{ horizontal: 'center', vertical: 'top' }}
                            className="k-mt-11 k-gap-4 k-px-6 k-py-3 sticky-footer"
                        >
                            <Button themeColor="primary" type="submit" size="large" disabled={!formRenderProps.allowSubmit}>
                                Save company
                            </Button>
                            <Button type="reset" size="large" onClick={() => navigate('./..')}>
                                Cancel
                            </Button>
                        </StackLayout>
                    </FormElement>
                )}
            />
        </>
    );
}
