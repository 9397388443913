import { Push } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { TextArea } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useMemo, useState } from 'react';
import { ReactComponent as SuggestionIcon } from '../../icons/light-bulb-1.svg';
import { ReactComponent as DislikeIcon } from '../../icons/thumbs-down.svg';
import { ReactComponent as LikeIcon } from '../../icons/thumbs-up.svg';
import { Feedback, FeedbackType, feedbackService } from '../../services/feedbackService';
import { FileUpload, ValidatedInput, defaultValidators } from '../ui/inputs';

export default function FeedbackForm({
    initialType,
    onSubmitted,
    onCancel
}: {
    initialType?: FeedbackType;
    onSubmitted: (feedbackItem: Feedback) => void;
    onCancel: Function;
}) {
    const [feedbackType, setFeedbackType] = useState(initialType);
    const feedbackContext = useMemo(() => window.location.href, []);
    const [feedbackFormShown, setFeedbackFormShown] = useState(false);

    const onSubmitFeedback = async (data: { [name: string]: any }) => {
        if (!feedbackType) return;

        const feedbackItem = await feedbackService.submitFeedback(feedbackType, feedbackContext, data.description, data.screenshot);
        onSubmitted(feedbackItem);
    };

    return (
        <Push
            direction="left"
            className={`k-display-block k-flex-auto k-flex-shrink-0${feedbackFormShown ? ' k-animation-container-shown' : ''}`}
            componentChildClassName="-h100 -minh100"
            onEntered={e => setFeedbackFormShown(true)}
            onExit={e => setFeedbackFormShown(false)}
        >
            {feedbackType ? (
                <StackLayout key="step2" orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4 -h100 -minh100">
                    <span className="k-button k-button-md k-button-link k-no-click k-rounded-md k-icp-bg-info-8 k-align-self-start">
                        <FeedbackTypeWithIcon type={feedbackType} />
                    </span>
                    <Form
                        ignoreModified={true}
                        onSubmit={onSubmitFeedback}
                        render={formRenderProps => (
                            <FormElement className="k-flex-1">
                                <StackLayout className="k-gap-6 -h100" orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
                                    <Field
                                        name="description"
                                        component={ValidatedInput}
                                        maxLengthCount={1000}
                                        validator={defaultValidators.feedbackDescriptionValidators}
                                        inputType={TextArea}
                                        rows={3}
                                        label="Tell us more..."
                                        placeholder="Describe your experience..."
                                        wrapperClass="k-flex-1 k-d-flex k-flex-col"
                                        className="k-flex-1 k-textarea-no-resize"
                                    />
                                    <Field
                                        name="screenshot"
                                        component={FileUpload}
                                        label="Upload image"
                                        accept=".png, .jpg, .jpeg"
                                        uploadFunction={(f: File) => feedbackService.uploadScreenshot(f)}
                                        fileCategory="feedback-screenshots"
                                        multiple={false}
                                        filesValidators={defaultValidators.feedbackScreenshotValidators}
                                    />
                                    <StackLayout className="k-gap-4" align={{ horizontal: 'start' }}>
                                        <Button type="submit" themeColor="primary" disabled={!formRenderProps.allowSubmit}>
                                            Send Feedback
                                        </Button>
                                        <Button type="button" fillMode="flat" onClick={() => onCancel()}>
                                            Cancel
                                        </Button>
                                    </StackLayout>
                                </StackLayout>
                            </FormElement>
                        )}
                    />
                </StackLayout>
            ) : (
                <StackLayout key="step1" orientation="vertical" align={{ horizontal: 'start' }} className="k-gap-4">
                    <div>We're always striving to improve and would love your feedback on the experience.</div>
                    {[FeedbackType.Positive, FeedbackType.Negative, FeedbackType.Suggestion].map(f => (
                        <Button key={f} fillMode="outline" themeColor="secondary" onClick={() => setFeedbackType(f)}>
                            <FeedbackTypeWithIcon type={f} />
                        </Button>
                    ))}
                </StackLayout>
            )}
        </Push>
    );
}

function FeedbackTypeWithIcon({ type }: { type: FeedbackType }) {
    const renderFeedbackType = (iconClass: string) => {
        switch (type) {
            case FeedbackType.Positive:
                return (
                    <>
                        <LikeIcon className={iconClass} />
                        <span>I like something</span>
                    </>
                );
            case FeedbackType.Negative:
                return (
                    <>
                        <DislikeIcon className={iconClass} />
                        <span>I don’t like something</span>
                    </>
                );
            case FeedbackType.Suggestion:
                return (
                    <>
                        <SuggestionIcon className={iconClass} />
                        <span>I have a suggestion</span>
                    </>
                );
        }
    };

    return <div className="k-d-flex k-align-items-center k-gap-1">{renderFeedbackType('k-icp-icon k-icp-icon-size-4')}</div>;
}
