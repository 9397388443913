import { Button } from '@progress/kendo-react-buttons';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { H2, P } from '../../components/ui/typography';
import forbiddenImageUrl from '../../images/403-error.svg';
import notFoundImageUrl from '../../images/404-error.svg';
import serverErrorImageUrl from '../../images/500-error.svg';
import { GraphicData } from '../../services/common';
import TopNavLayout from '../layouts/topNavLayout';

interface ErrorPageData {
    title: string;
    content: ReactNode;
    image: Omit<GraphicData, 'description'>;
    link: { text: string; to: string | number };
}

const errorPagesData: { 403: ErrorPageData; 404: ErrorPageData; 500: ErrorPageData } = {
    403: {
        title: 'Not authorized',
        content: (
            <>
                It seems you don’t have access to this page
                <br />
                or resource.
            </>
        ),
        image: {
            url: forbiddenImageUrl,
            width: 560,
            height: 354
        },
        link: {
            text: 'Back to Home',
            to: '/'
        }
    },
    404: {
        title: 'Nothing here!',
        content: (
            <>
                The page you are looking for doesn’t exist
                <br />
                or is unavailable.
            </>
        ),
        image: {
            url: notFoundImageUrl,
            width: 560,
            height: 278
        },
        link: {
            text: 'Back to Home',
            to: '/'
        }
    },
    500: {
        title: 'Sorry, unexpected error',
        content: 'Try again later or contact us if the problem persists.',
        image: {
            url: serverErrorImageUrl,
            width: 560,
            height: 278
        },
        link: {
            text: 'Go back',
            to: -1
        }
    }
};

export default function ErrorPage({ errorCode }: { errorCode: 403 | 404 | 500 }) {
    const data = errorPagesData[errorCode];
    const navigate = useNavigate();

    return (
        <TopNavLayout mainColumnClassName="page-content-section k-d-flex k-flex-col k-align-items-center k-justify-content-center k-text-center">
            <Helmet>
                <title>{`Error ${errorCode} | Icanpreneur Platform`}</title>
            </Helmet>
            <img src={data.image.url} alt={data.title} width={data.image.width} height={data.image.height} className="k-mb-5 responsive-image" />
            <H2 className="!k-mb-4">{data.title}</H2>
            <P className="!k-fs-lg !k-mb-8">{data.content}</P>
            {typeof data.link.to === 'number' ? (
                <Button themeColor="primary" onClick={() => navigate(data.link.to as number)}>
                    {data.link.text}
                </Button>
            ) : (
                <Link to={data.link.to} className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md">
                    {data.link.text}
                </Link>
            )}
        </TopNavLayout>
    );
}
