import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useGlobalCanvas } from '../../../hooks/canvasHooks';
import { useInterviewHypothesesVerdicts, useInterviewQuotes, useInterviewSections } from '../../../hooks/interviewHooks';
import { useResearchHypotheses } from '../../../hooks/researchHooks';
import { useIntQueryParam } from '../../../hooks/routerHooks';
import { BoxItem, BoxType } from '../../../services/canvasService';
import { groupBy, toRecord } from '../../../services/common';
import {
    CustomerProblemAlternativeSolutionHypothesis,
    CustomerProblemAlternativeSolutionSatisfactionHypothesis,
    CustomerProblemPainLevelHypothesis,
    Hypothesis,
    HypothesisType
} from '../../../services/hypothesesService';
import { HypothesisVerdict, Interview, InterviewHypothesisVerdict } from '../../../services/interviewsService';
import { InterviewQuoteType } from '../../../services/researchService';
import { HypothesesByProblemAndTypeGroup } from '../../hypotheses/hypothesesByProblemAndTypeList';
import { InterviewEntrySelectionContext, InterviewSectionsConversationList, useInterviewEntrySelectionContextValue } from '../entries/interviewEntries';
import { InterviewContentHandle } from '../interviewModal';
import { InterviewThreeColumnLayout } from '../interviewModalLayout';
import { InterviewHypothesesEditList, InterviewHypothesesEditListHandle } from './interviewHypothesesEditList';
import { InterviewHypothesesGroupedList } from './interviewHypothesesGroupedList';

export const InterviewHypothesesContent = forwardRef<
    InterviewContentHandle,
    { ideaId: string; interviewId: number; interview?: Interview; isReadOnly?: boolean }
>(function InterviewHypothesesContent({ ideaId, interviewId, interview, isReadOnly }, ref) {
    const initiallySelectedQuoteId = useIntQueryParam('quoteId');
    const interviewResearchHypotheses = useResearchHypotheses(ideaId, interview?.researchId);
    const [interviewHypothesesVerdicts, updateInterviewHypothesesVerdict] = useInterviewHypothesesVerdicts(ideaId, interviewId);
    const { canvas } = useGlobalCanvas(ideaId, true);
    const groupedHypotheses = getCustomerProblemsHypothesesGroups(
        interviewResearchHypotheses,
        interviewHypothesesVerdicts,
        canvas.boxes?.find(b => b.type === BoxType.Problem)?.items,
        canvas.boxes?.find(b => b.type === BoxType.AlternativeSolutions)?.items
    );
    const [selection, setSelection] = useState<number | { hypothesisId: number; quoteId: number; entryId: number }>();
    const selectedHypothesisId = selection === undefined ? undefined : typeof selection === 'number' ? selection : selection.hypothesisId;
    const selectedQuoteId = typeof selection === 'object' ? selection.quoteId : undefined;
    const selectedEntryId = typeof selection === 'object' ? selection.entryId : undefined;

    const { interviewSections } = useInterviewSections(ideaId, interview?.id);
    const hypothesesEditListRef = useRef<InterviewHypothesesEditListHandle>(null);
    const { quotes, addHypothesisQuote, updateQuote, deleteQuote } = useInterviewQuotes(ideaId, interviewId, InterviewQuoteType.Hypothesis);

    useImperativeHandle(
        ref,
        () => ({
            onComplete() {
                return hypothesesEditListRef.current?.commit();
            }
        }),
        []
    );

    const resolveSelectionFromQuoteId = useCallback(
        function resolveSelectionFromQuoteId(quoteId: number): { hypothesisId: number; quoteId: number; entryId: number } | undefined {
            const selectedQuote = quotes?.find(q => q.id === quoteId);
            if (!selectedQuote) return undefined;

            return { quoteId: quoteId, hypothesisId: selectedQuote.hypothesisId, entryId: selectedQuote.entry.id };
        },
        [quotes]
    );

    const selectedHypothesisIdInitialized = useRef(false);
    const initializeSelection = !selection && initiallySelectedQuoteId === undefined;
    useEffect(() => {
        if (selectedHypothesisIdInitialized.current || !groupedHypotheses || !initializeSelection) return;
        selectedHypothesisIdInitialized.current = true;

        const firstNotEvaluatedHypothesis = findHypothesis(groupedHypotheses, h => !h.verdict);
        if (firstNotEvaluatedHypothesis) setSelection(firstNotEvaluatedHypothesis.hypothesis.id);
    }, [groupedHypotheses, initializeSelection]);

    const shouldSetSelectionFromQuote = !selection && initiallySelectedQuoteId !== undefined && quotes !== undefined;
    const selectionSetFromQuote = useRef(false);
    useEffect(() => {
        if (selectionSetFromQuote.current || !shouldSetSelectionFromQuote || initiallySelectedQuoteId === undefined) return;
        selectionSetFromQuote.current = true;

        const selection = resolveSelectionFromQuoteId(initiallySelectedQuoteId);
        if (selection) setSelection(selection);
    }, [initiallySelectedQuoteId, resolveSelectionFromQuoteId, shouldSetSelectionFromQuote]);

    function onHypothesisVerdictChange(hypothesisId: number, verdict: HypothesisVerdict | null, isAutomatic?: boolean) {
        if (!isAutomatic) setSelection(hypothesisId);

        return updateInterviewHypothesesVerdict(hypothesisId, verdict);
    }

    const interviewEntrySelectionContextValue = useInterviewEntrySelectionContextValue();

    return (
        <InterviewEntrySelectionContext.Provider value={interviewEntrySelectionContextValue}>
            <InterviewThreeColumnLayout
                leftContent={
                    <InterviewHypothesesGroupedList
                        interviewee={interview?.contact}
                        groups={groupedHypotheses}
                        selectedHypothesisId={selectedHypothesisId}
                        onHypothesisSelect={setSelection}
                    />
                }
                rightContent={
                    <InterviewSectionsConversationList
                        interviewSections={interviewSections}
                        interviewee={interview?.contact}
                        onEntryAnswerClick={interviewEntrySelectionContextValue.onEntrySelected}
                        selectedEntryId={selectedEntryId}
                    />
                }
            >
                <InterviewHypothesesEditList
                    ref={hypothesesEditListRef}
                    ideaId={ideaId}
                    interviewee={interview?.contact}
                    groups={groupedHypotheses}
                    onVerdictChange={onHypothesisVerdictChange}
                    selectedHypothesisId={selectedHypothesisId}
                    onHypothesisSelect={setSelection}
                    quotes={quotes}
                    currentInterviewId={interviewId}
                    onCreateQuote={(hypothesisId: number, entryId: number, startIndex: number, endIndex: number) =>
                        addHypothesisQuote({ interviewId, hypothesisId, entryId, fromPosition: startIndex, toPosition: endIndex })
                    }
                    onUpdateQuote={updateQuote}
                    onDeleteQuote={deleteQuote}
                    onQuoteClick={quoteId => {
                        const selection = resolveSelectionFromQuoteId(quoteId);
                        if (!selection) return;
                        selectedQuoteId === quoteId ? setSelection(selection.hypothesisId) : setSelection(selection);
                    }}
                    selectedQuoteId={selectedQuoteId}
                    isReadOnly={isReadOnly}
                />
            </InterviewThreeColumnLayout>
        </InterviewEntrySelectionContext.Provider>
    );
});

export type EvaluatedHypothesis<THypothesis extends Hypothesis> = {
    hypothesis: THypothesis;
    verdict?: HypothesisVerdict;
};

export type CustomerProblemEvaluatedHypothesesGroup = HypothesesByProblemAndTypeGroup<
    EvaluatedHypothesis<CustomerProblemPainLevelHypothesis>,
    EvaluatedHypothesis<CustomerProblemAlternativeSolutionHypothesis>,
    EvaluatedHypothesis<CustomerProblemAlternativeSolutionSatisfactionHypothesis>
>;
export type AlternativeSolutionEvaluatedHypothesesGroup = CustomerProblemEvaluatedHypothesesGroup['alternativeSolutionHypothesesGroups'][number];

function getCustomerProblemsHypothesesGroups(
    hypotheses?: Hypothesis[],
    verdicts?: InterviewHypothesisVerdict[],
    customerProblems?: BoxItem[],
    alternativeSolutions?: BoxItem[]
): CustomerProblemEvaluatedHypothesesGroup[] | undefined {
    if (!hypotheses || !customerProblems) return undefined;

    const hypothesesByCustomerProblem = groupBy(hypotheses, h => h.customerProblemId);
    const hypothesisVerdictMap = verdicts && toRecord(verdicts, v => v.hypothesisId);
    const alternativeSolutionsMap = alternativeSolutions && toRecord(alternativeSolutions, as => as.id);

    const groups: CustomerProblemEvaluatedHypothesesGroup[] = [];
    customerProblems.forEach(problem => {
        const hypothesesForProblem = hypothesesByCustomerProblem[problem.id];
        if (!hypothesesForProblem || !hypothesesForProblem.length) return;

        const groupedHypotheses = groupCustomerProblemHypotheses(hypothesesForProblem, hypothesisVerdictMap);
        const alternativeSolutionHypothesesGroups = Array.from(groupedHypotheses.alternativeSolutionHypothesesMap.entries()).map<
            AlternativeSolutionEvaluatedHypothesesGroup
        >(([alternativeSolutionId, alternativeSolutionHypotheses]) => ({
            alternativeSolutionId,
            alternativeSolution: alternativeSolutionsMap?.[alternativeSolutionId],
            alternativeSolutionHypotheses: alternativeSolutionHypotheses.alternativeSolutionHypotheses,
            alternativeSolutionSatisfactionHypotheses: alternativeSolutionHypotheses.alternativeSolutionSatisfactionHypotheses
        }));

        const group: CustomerProblemEvaluatedHypothesesGroup = {
            customerProblemId: problem.id,
            customerProblem: problem,
            painLevelHypotheses: groupedHypotheses.painLevelHypotheses,
            alternativeSolutionHypothesesGroups: alternativeSolutionHypothesesGroups
        };

        groups.push(group);
    });

    return groups;
}

function groupCustomerProblemHypotheses(
    hypothesesForProblem: Hypothesis[],
    hypothesisVerdictMap?: Partial<Record<number, InterviewHypothesisVerdict>>
): Pick<CustomerProblemEvaluatedHypothesesGroup, 'painLevelHypotheses'> & {
    alternativeSolutionHypothesesMap: Map<
        number,
        Pick<AlternativeSolutionEvaluatedHypothesesGroup, 'alternativeSolutionHypotheses' | 'alternativeSolutionSatisfactionHypotheses'>
    >;
} {
    const painLevelHypotheses: EvaluatedHypothesis<CustomerProblemPainLevelHypothesis>[] = [];
    const alternativeSolutionHypothesesMap: Map<
        number,
        Pick<AlternativeSolutionEvaluatedHypothesesGroup, 'alternativeSolutionHypotheses' | 'alternativeSolutionSatisfactionHypotheses'>
    > = new Map();

    for (const hypothesis of hypothesesForProblem) {
        const verdict = hypothesisVerdictMap?.[hypothesis.id];
        if (hypothesis.type === HypothesisType.PainLevel) {
            painLevelHypotheses.push({
                hypothesis: hypothesis,
                verdict: verdict?.verdict
            });

            continue;
        }

        let alternativeSolutionHypotheses = alternativeSolutionHypothesesMap.get(hypothesis.alternativeSolutionId);
        if (!alternativeSolutionHypotheses) {
            alternativeSolutionHypotheses = {
                alternativeSolutionHypotheses: [],
                alternativeSolutionSatisfactionHypotheses: []
            };

            alternativeSolutionHypothesesMap.set(hypothesis.alternativeSolutionId, alternativeSolutionHypotheses);
        }

        if (hypothesis.type === HypothesisType.AlternativeSolutionUsage)
            alternativeSolutionHypotheses.alternativeSolutionHypotheses.push({
                hypothesis: hypothesis,
                verdict: verdict?.verdict
            });
        else if (hypothesis.type === HypothesisType.AlternativeSolutionSatisfaction)
            alternativeSolutionHypotheses.alternativeSolutionSatisfactionHypotheses.push({
                hypothesis: hypothesis,
                verdict: verdict?.verdict
            });
    }

    return {
        painLevelHypotheses,
        alternativeSolutionHypothesesMap
    };
}

export function findHypothesis(groups: CustomerProblemEvaluatedHypothesesGroup[], condition: (hypothesis: EvaluatedHypothesis<Hypothesis>) => unknown) {
    for (const group of groups) {
        for (const painLevelHypothesis of group.painLevelHypotheses) {
            if (condition(painLevelHypothesis)) return painLevelHypothesis;
        }

        for (const alternativeSolutionGroup of group.alternativeSolutionHypothesesGroups) {
            for (const alternativeSolutionHypothesis of alternativeSolutionGroup.alternativeSolutionHypotheses) {
                if (condition(alternativeSolutionHypothesis)) return alternativeSolutionHypothesis;
            }

            for (const alternativeSolutionSatisfactionHypothesis of alternativeSolutionGroup.alternativeSolutionSatisfactionHypotheses) {
                if (condition(alternativeSolutionSatisfactionHypothesis)) return alternativeSolutionSatisfactionHypothesis;
            }
        }
    }
}
