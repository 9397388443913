import { Button } from '@progress/kendo-react-buttons';
import { ListItemProps } from '@progress/kendo-react-dropdowns';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { combineClassNames, debounce } from '../../services/common';
import LoadingIndicator from '../ui/loadingIndicator';

export function itemRenderFromCallback<TDataItem>(renderCallback?: (item: TDataItem) => ReactNode, className?: string) {
    if (!renderCallback) return undefined;

    return function itemRender(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps): ReactNode {
        return React.cloneElement(
            li,
            className ? { ...li.props, className: combineClassNames(li.props.className, className) } : undefined,
            renderCallback(itemProps.dataItem)
        );
    };
}

export function listNoDataRenderWithText(emptyListText?: string, isLoading?: boolean) {
    return function(element: React.ReactElement<HTMLDivElement>) {
        return React.cloneElement(element, undefined, <div>{isLoading ? <LoadingIndicator size="small" /> : emptyListText || 'NO DATA FOUND.'}</div>);
    };
}

export function useFilterItems<TData>(
    fetchItems: (filter: string) => Promise<TData[]>,
    minFilterLength = 3
): [TData[] | undefined, boolean, (filter: string, immediate?: boolean) => void] {
    const [items, setItems] = useState<TData[]>();
    const [isLoading, setIsLoading] = useState(false);
    const lastFilterValueRef = useRef<string>();
    const isLoadingRef = useRef(isLoading);
    isLoadingRef.current = isLoading;

    const loadData = useCallback(
        function(filter: string) {
            if (isLoadingRef.current && lastFilterValueRef.current === filter) return;

            lastFilterValueRef.current = filter;
            setIsLoading(true);
            fetchItems(filter)
                .then(items => {
                    if (lastFilterValueRef.current !== filter) return;

                    setItems(items);
                })
                .finally(() => {
                    if (lastFilterValueRef.current === filter) setIsLoading(false);
                });
        },
        [fetchItems]
    );
    const loadDataDebounced = useMemo(() => debounce(loadData, 300), [loadData]);

    function filterItems(filter: string, immediate?: boolean) {
        const loadFunction = immediate ? loadData : loadDataDebounced;
        if (!filter || filter.length < minFilterLength) loadFunction('');
        else loadFunction(filter);
    }

    return [items, isLoading, filterItems];
}

export function DropDownButtonFooterWithButton({ children, onClick }: { children?: ReactNode; onClick?: () => void }) {
    return (
        <div className="k-icp-component-border k-icp-bordered-top k-px-2 k-py-1">
            <Button themeColor="secondary" fillMode="flat" onMouseDown={e => e.preventDefault()} onClick={onClick} tabIndex={-1}>
                {children}
            </Button>
        </div>
    );
}

export function NewItemButtonDropDownFooter({ newItemText, onClick }: { newItemText: string; onClick?: () => void }) {
    return (
        <DropDownButtonFooterWithButton onClick={onClick}>
            <StackLayout className="k-gap-1">
                <PlusIcon className="k-icp-icon" />
                {newItemText}
            </StackLayout>
        </DropDownButtonFooterWithButton>
    );
}
