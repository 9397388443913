import { Zoom } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, ReactElement, ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { DaysRestrictedCalendar } from '../../components/common/daysRestrictedCalendar';
import { useInvisibleReCaptcha } from '../../components/common/invisibleReCaptcha';
import { EventLocationSelector } from '../../components/events/eventLocationSelector';
import { ValidatedInput, emailValidator, maxLengthValidator, phoneValidator, requiredValidator } from '../../components/ui/inputs';
import { H1, H2, H3 } from '../../components/ui/typography';
import { ResponsiveGroup, useResponsiveLayout, useSingleClickButton } from '../../hooks/commonHooks';
import { ReactComponent as LeftArrowIcon } from '../../icons/arrow-left.svg';
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { ReactComponent as MapPinIcon } from '../../icons/map-pin-1.svg';
import { ReactComponent as UserIcon } from '../../icons/user-circle.svg';
import meetingProposalAcceptedImageUrl from '../../images/meeting-proposal-accepted-illustration.svg';
import meetingProposalErrorImageUrl from '../../images/meeting-proposal-error-illustration.svg';
import { combineClassNames } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { HttpException } from '../../services/httpServiceBase';
import { PublicMeetingProposal, publicMeetingProposalsService } from '../../services/meetingProposalsService';
import { LocationOption, LocationOptionType } from '../../services/schedulesService';

export function BookMeetingPage() {
    const { code } = useParams();
    const [meetingProposal, setMeetingProposal] = useState<PublicMeetingProposal & { slots: Date[] }>();
    const [pickedMeetingTime, setPickedMeetingTime] = useState<Date>();
    const [isMeetingAccepted, setIsMeetingAccepted] = useState(false);
    const [invalidLinkError, setInvalidLinkError] = useState<string>();
    const responsiveGroup = useResponsiveLayout();
    const isTablet = responsiveGroup === ResponsiveGroup.sm || responsiveGroup === ResponsiveGroup.md;
    const isMobile = responsiveGroup === ResponsiveGroup.xs;
    const [selectedDate, setSelectedDate] = useState<Date>();

    useEffect(() => {
        if (!code) return;

        (async () => {
            try {
                const [meetingProposal, availableSlots] = await Promise.all([
                    publicMeetingProposalsService.getMeetingProposal(code),
                    publicMeetingProposalsService.getMeetingProposalAvailableSlots(code)
                ]);
                setMeetingProposal({ ...meetingProposal, slots: availableSlots.sort((a, b) => a.getTime() - b.getTime()) });
            } catch (e) {
                if (e instanceof HttpException && (e.status === 404 || e.status === 410)) {
                    setInvalidLinkError(e.status === 410 ? 'The booking link has expired' : 'The booking link does not exist or is invalid');

                    return;
                }
                throw e;
            }
        })();
    }, [code]);

    if (invalidLinkError) return <MeetingProposalError description={invalidLinkError} />;

    return (
        <div className="page-content-middle page-content--xxl">
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=1.0, minimum-scale=1.0, maximum-scale=1.0" />
            </Helmet>
            <StackLayout align={{ vertical: 'middle', horizontal: 'start' }} className="!k-mb-8 k-gap-0.5 k-mr-8 k-pr-thin">
                {isMobile && (
                    <Button
                        themeColor="base"
                        fillMode="flat"
                        className={`${selectedDate && !isMeetingAccepted ? '' : 'k-visibility-invisible '}k-icp-svg-icon-button`}
                        onClick={() => {
                            if (pickedMeetingTime) setPickedMeetingTime(undefined);
                            else if (selectedDate) setSelectedDate(undefined);
                        }}
                    >
                        <LeftArrowIcon className="k-icp-icon k-icp-icon-size-4" />
                    </Button>
                )}
                <H1 className={`${isMobile ? 'k-h2 ' : ''} k-text-center k-flex-1`}>
                    {!meetingProposal ? (
                        <Skeleton shape="text" className="-block-center" style={isMobile ? { width: 250 } : { width: 500 }} />
                    ) : (
                        meetingProposal.title
                    )}
                </H1>
            </StackLayout>
            <StackLayout orientation="vertical" align={isMobile ? { horizontal: 'center' } : { horizontal: 'stretch' }}>
                <Zoom enter={true} exit={false} className={'k-max-w-full'}>
                    {pickedMeetingTime && meetingProposal ? (
                        isMeetingAccepted ? (
                            <ConfirmedMeetingProposalView key="confirmedMeeting" meetingProposal={meetingProposal} slotTime={pickedMeetingTime} />
                        ) : (
                            <MeetingProposalConfirmation
                                key="slotConfirmation"
                                code={code!}
                                locations={meetingProposal.locationOptions}
                                slotTime={pickedMeetingTime}
                                onCancel={() => {
                                    setPickedMeetingTime(undefined);
                                    isMobile && setSelectedDate(undefined);
                                }}
                                onConfirmed={() => setIsMeetingAccepted(true)}
                                isMobile={isMobile}
                            />
                        )
                    ) : (
                        <MeetingProposalTimeSlotPicker
                            key="slotPicker"
                            meetingProposal={meetingProposal}
                            slots={meetingProposal?.slots}
                            onSlotPick={setPickedMeetingTime}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            selectedDate={selectedDate}
                            onSelectedDate={setSelectedDate}
                        />
                    )}
                </Zoom>
            </StackLayout>
        </div>
    );
}

function MeetingProposalTimeSlotPicker({
    meetingProposal,
    slots,
    onSlotPick,
    isMobile,
    isTablet,
    selectedDate,
    onSelectedDate
}: {
    meetingProposal?: PublicMeetingProposal;
    slots?: Date[];
    onSlotPick?: (slotDate: Date) => void;
    isMobile: boolean;
    isTablet: boolean;
    selectedDate: Date | undefined;
    onSelectedDate: (date?: Date) => void;
}) {
    const [firstAvailableSlot, setFirstAvailableSlot] = useState<Date>();
    const [isCalendarInitialized, setIsCalendarInitialized] = useState(false);
    const isLoading = !meetingProposal || !slots;
    const isInMobileSlotState = isMobile && selectedDate;

    useEffect(() => {
        if (!isMobile && selectedDate) {
            setIsCalendarInitialized(true);
            return;
        }

        if (!slots) return;
        const pickedAvailableSlot = slots.length ? slots.reduce((s1, s2) => (s1 < s2 ? s1 : s2)) : undefined;
        setFirstAvailableSlot(pickedAvailableSlot);

        if (isMobile) {
            setIsCalendarInitialized(true);
            return;
        }

        onSelectedDate(pickedAvailableSlot ?? new Date());
        setIsCalendarInitialized(true);
    }, [isMobile, onSelectedDate, selectedDate, slots]);

    const isSmaller = isMobile || isTablet;

    return (
        <StackLayout
            align={isSmaller ? { horizontal: 'center' } : { horizontal: 'start', vertical: 'stretch' }}
            orientation={isSmaller ? 'vertical' : 'horizontal'}
            className={isSmaller ? 'k-gap-8' : undefined}
        >
            <div
                className={`${
                    isMobile
                        ? 'meeting-proposal-details-column--mobile'
                        : isTablet
                        ? 'meeting-proposal-details-column--tablet'
                        : 'meeting-proposal-details-column'
                }`}
            >
                {!isSmaller && <H3>Details</H3>}
                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className={`${isSmaller ? 'k-gap-2' : 'k-gap-4'}`}>
                    <StackLayout
                        orientation={isSmaller ? 'horizontal' : 'vertical'}
                        align={{ horizontal: 'start' }}
                        className={`${isSmaller ? 'k-gap-2' : 'k-gap-4'}`}
                    >
                        <MeetingProposalDetail icon={UserIcon} loaderWidth={80} isLoading={isLoading}>
                            {meetingProposal?.organizerFullName}
                        </MeetingProposalDetail>
                        {isSmaller && <span>{'•'}</span>}
                        <MeetingProposalDetail icon={ClockIcon} loaderWidth={50} isLoading={isLoading}>
                            {meetingProposal ? `${meetingProposal.durationMinutes} min` : undefined}
                        </MeetingProposalDetail>
                    </StackLayout>
                    <MeetingProposalDetail icon={MapPinIcon} loaderWidth={200} isLoading={isLoading}>
                        {meetingProposal
                            ? meetingProposal.locationOptions.length === 1
                                ? meetingProposal.locationOptions[0].type === LocationOptionType.Other
                                    ? 'Specify a location after a time slot is selected'
                                    : meetingProposal.locationOptions[0].details || meetingProposal.locationOptions[0].type
                                : `Pick from ${meetingProposal.locationOptions.length} available options after a time slot is selected`
                            : undefined}
                    </MeetingProposalDetail>
                    {isLoading ? (
                        <Skeleton shape="rectangle" style={{ width: '100%', height: 63 }} />
                    ) : (
                        meetingProposal.description && <div className="max-lines-3">{meetingProposal.description}</div>
                    )}
                </StackLayout>
            </div>
            <div className={`${isSmaller ? '' : 'k-flex-1 k-pl-8'}`}>
                <H3 className={`${isMobile ? 'k-text-center ' : ''}!k-mb-6`}>{isMobile ? 'Choose from available dates' : 'Available dates and time slots'}</H3>

                <Zoom
                    enter={isMobile}
                    exit={false}
                    className={`k-d-flex k-gap-8 ${isMobile ? 'k-flex-col' : 'k-flex-row k-justify-items-start k-align-items-start'}`}
                >
                    {isLoading || !isCalendarInitialized ? (
                        <Skeleton shape="rectangle" style={{ width: 360, height: 290 }} />
                    ) : isInMobileSlotState ? (
                        <StackLayout orientation="vertical" className="k-gap-4" key={'mobile-timeslots'}>
                            <StackLayout orientation="vertical" className="k-text-center k-gap-0.5">
                                <strong className="k-d-block">{dateTimeService.stringifyToDay(selectedDate, true)}</strong>
                                <span className="k-fs-sm">Time zone: {dateTimeService.getGenericTimeZoneTitle(dateTimeService.getCurrentTimeZone())}</span>
                            </StackLayout>
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch' }} className="k-gap-2">
                                <DaySlotPicker day={selectedDate} slots={slots} onPick={onSlotPick} />
                            </StackLayout>
                        </StackLayout>
                    ) : (
                        <DaysRestrictedCalendar
                            key={'calendar-view'}
                            navigation={!isMobile && !isTablet}
                            topView="month"
                            value={!isMobile ? selectedDate : undefined}
                            onChange={e => {
                                onSelectedDate(e.value);
                            }}
                            focusedDate={firstAvailableSlot}
                            allowedDays={slots}
                        />
                    )}
                    {!isMobile && (
                        <div className="meeting-timeslots-column">
                            {isLoading ? (
                                <Skeleton shape="text" className="k-mb-4" />
                            ) : (
                                selectedDate && (
                                    <StackLayout orientation="vertical" className="k-gap-0.5 k-mb-4">
                                        <strong className="k-d-block">{dateTimeService.stringifyToDay(selectedDate, true)}</strong>
                                        <span className="k-fs-sm">
                                            Time zone: {dateTimeService.getGenericTimeZoneTitle(dateTimeService.getCurrentTimeZone())}
                                        </span>
                                    </StackLayout>
                                )
                            )}
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                                {isLoading ? (
                                    <>
                                        <Skeleton shape="rectangle" style={{ height: 32 }} />
                                        <Skeleton shape="rectangle" style={{ height: 32 }} />
                                    </>
                                ) : (
                                    selectedDate && <DaySlotPicker day={selectedDate} slots={slots} onPick={onSlotPick} />
                                )}
                            </StackLayout>
                        </div>
                    )}
                </Zoom>
            </div>
        </StackLayout>
    );
}

function DaySlotPicker({ day, slots, onPick }: { day: Date; slots?: Date[]; onPick?: (slotDate: Date) => void }) {
    const [selectedSlot, setSelectedSlot] = useState<number>();
    const slotsInDay = slots?.filter(s => dateTimeService.isSameDay(s, day));

    if (!slotsInDay?.length) {
        return <span className="k-icp-subtle-text">No time slots available</span>;
    }

    return (
        <>
            {slotsInDay.map(s => {
                const isSlotSelected = selectedSlot === s.getTime();
                return (
                    <PickSlotButton
                        key={s.getTime()}
                        isSelected={isSlotSelected}
                        onClick={() => {
                            setSelectedSlot(isSlotSelected ? undefined : s.getTime());
                        }}
                        onBook={onPick ? () => onPick(s) : undefined}
                    >
                        {dateTimeService.stringifyToTime(s)}
                    </PickSlotButton>
                );
            })}
        </>
    );
}

function MeetingProposalDetail({
    icon: Icon,
    children,
    isLoading,
    loaderWidth
}: {
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    children?: ReactNode;
    isLoading?: boolean;
    loaderWidth?: string | number;
}) {
    if (!isLoading && !children) return null;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }}>
            <Icon className="k-icp-icon k-icp-icon-size-4 -mr-1.5 k-mt-thin k-flex-shrink-0" />
            {isLoading ? <Skeleton shape="text" style={{ width: loaderWidth }} /> : <div className="max-lines-3">{children}</div>}
        </StackLayout>
    );
}

function PickSlotButton({ onClick, isSelected, children, onBook }: { onClick?: () => void; isSelected?: boolean; children?: string; onBook?: () => void }) {
    return (
        <div
            className={combineClassNames(
                'k-button k-button-md k-button-outline k-button-outline-secondary pick-slot-outline-secondary-button k-rounded-md select-slot-button !k-p-0',
                isSelected ? 'k-hover' : undefined
            )}
            onClick={onClick}
        >
            <StackLayout className="-w100 k-gap-1" align={{ horizontal: 'start', vertical: 'middle' }}>
                <StackLayout className="k-flex-1 k-gap-2" align={{ horizontal: 'center', vertical: 'middle' }}>
                    <AutoWidthAnimatable isExpanded={isSelected}>
                        <span className="k-icon k-i-check-circle k-button-icon" role="presentation"></span>
                    </AutoWidthAnimatable>
                    <span>{children}</span>
                </StackLayout>
                <AutoWidthAnimatable isExpanded={isSelected}>
                    <Button
                        fillMode="solid"
                        themeColor="secondary"
                        className="book-slot-button !k-border-none"
                        onClick={e => {
                            e.stopPropagation();
                            onBook?.();
                        }}
                    >
                        Book
                    </Button>
                </AutoWidthAnimatable>
            </StackLayout>
        </div>
    );
}

function AutoWidthAnimatable({ isExpanded, children }: { isExpanded?: boolean; children: ReactElement }) {
    return (
        <div className={combineClassNames('auto-width-animatable', isExpanded ? 'auto-width-animatable-expanded' : undefined)}>
            <div className="k-overflow-hidden k-d-flex">{children}</div>
        </div>
    );
}

const firstNameValidators = [requiredValidator('First name'), maxLengthValidator('First name', 50)];
const lastNameValidators = [requiredValidator('Last name'), maxLengthValidator('Last name', 50)];
const emailValidators = [requiredValidator('Email'), emailValidator, maxLengthValidator('Email', 320)];
const phoneValidators = [requiredValidator('Phone number'), maxLengthValidator('Phone', 50)];
const locationValidators = [
    requiredValidator('Location'),
    (value: LocationOption | undefined) => {
        if (!value || value.type !== LocationOptionType.Other) return;
        if (!value.details) return 'Specify a location';
    }
];
function MeetingProposalConfirmation({
    code,
    slotTime,
    locations,
    onCancel,
    onConfirmed,
    isMobile
}: {
    code: string;
    slotTime: Date;
    locations: LocationOption[];
    onCancel?: () => void;
    onConfirmed?: () => void;
    isMobile: boolean;
}) {
    const [confirmDisabled, confirmCallbackCreator] = useSingleClickButton<Parameters<typeof confirmSubscription>, ReturnType<typeof confirmSubscription>>();
    const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>();
    const getReCaptchaToken = useInvisibleReCaptcha();

    async function confirmSubscription(data: Record<string, any>) {
        const phoneErrorMessage = await phoneValidator(data.phoneNumber, 'Phone number');
        setPhoneErrorMessage(phoneErrorMessage || undefined);
        if (phoneErrorMessage) return;

        const reCaptchaToken = await getReCaptchaToken?.();
        await publicMeetingProposalsService.acceptMeetingProposal(
            code,
            {
                emailAddress: data.emailAddress,
                firstName: data.firstName,
                lastName: data.lastName,
                location: data.location || locations[0],
                phoneNumber: data.phoneNumber,
                startTime: slotTime
            },
            reCaptchaToken
        );

        onConfirmed?.();
    }

    return (
        <div className="page-content-middle">
            <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} orientation="horizontal" className="k-gap-4 k-mb-8">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }}>
                    <CalendarIcon className="k-icp-icon k-icp-icon-size-4 -mr-1.5" />
                    <strong>{dateTimeService.stringifyToDay(slotTime, true)}</strong>
                </StackLayout>
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }}>
                    <ClockIcon className="k-icp-icon k-icp-icon-size-4 -mr-1.5" />
                    <strong>{dateTimeService.stringifyToTime(slotTime)}</strong>
                </StackLayout>
            </StackLayout>
            <Form
                ignoreModified={true}
                onSubmit={confirmCallbackCreator(confirmSubscription)}
                render={formRenderProps => (
                    <FormElement className="k-pt-6 k-icp-component-border k-icp-bordered-top">
                        <StackLayout
                            orientation="vertical"
                            align={{ horizontal: 'stretch', vertical: 'top' }}
                            className={`k-gap-8${isMobile ? ' k-px-xs' : ''}`}
                        >
                            <StackLayout
                                orientation="vertical"
                                align={{ horizontal: 'stretch', vertical: 'top' }}
                                className={`${isMobile ? 'k-gap-4' : 'k-gap-6'}`}
                            >
                                <H3>Details</H3>
                                <StackLayout
                                    orientation={isMobile ? 'vertical' : 'horizontal'}
                                    align={{ horizontal: 'stretch', vertical: 'top' }}
                                    className="k-gap-4"
                                >
                                    <Field
                                        name="firstName"
                                        component={ValidatedInput}
                                        label="First name"
                                        validator={firstNameValidators}
                                        maxLength={50}
                                        placeholder="Add first name..."
                                    />
                                    <Field
                                        name="lastName"
                                        component={ValidatedInput}
                                        label="Last name"
                                        validator={lastNameValidators}
                                        maxLength={50}
                                        placeholder="Add last name..."
                                    />
                                </StackLayout>
                                <StackLayout
                                    orientation={isMobile ? 'vertical' : 'horizontal'}
                                    align={{ horizontal: 'stretch', vertical: 'top' }}
                                    className="k-gap-4"
                                >
                                    <Field
                                        name="emailAddress"
                                        component={ValidatedInput}
                                        label="Email"
                                        validator={emailValidators}
                                        maxLength={320}
                                        placeholder="Add email..."
                                    />
                                    <Field
                                        name="phoneNumber"
                                        component={ValidatedInput}
                                        label="Phone number"
                                        validator={phoneValidators}
                                        maxLength={50}
                                        placeholder="Add phone number..."
                                        errorMessage={phoneErrorMessage}
                                        onChange={() => setPhoneErrorMessage(undefined)}
                                    />
                                </StackLayout>
                            </StackLayout>

                            <div>
                                <H3 className="!k-mb-4">
                                    {locations.length === 1
                                        ? locations[0].type === LocationOptionType.Other
                                            ? 'Specify location'
                                            : 'Location'
                                        : 'Pick location'}
                                </H3>
                                {locations.length === 1 && locations[0].type !== LocationOptionType.Other ? (
                                    locations[0].details || locations[0].type
                                ) : (
                                    <Field
                                        name="location"
                                        component={ValidatedInput}
                                        validator={locationValidators}
                                        inputType={EventLocationSelector}
                                        availableLocations={locations}
                                        isMobile={isMobile}
                                    />
                                )}
                            </div>

                            <StackLayout
                                orientation="horizontal"
                                align={{ horizontal: 'center' }}
                                className={`${isMobile ? 'k-gap-4' : 'k-gap-6 k-mt-4'} k-align-self-center`}
                            >
                                <Button
                                    type="submit"
                                    themeColor="primary"
                                    size={`${isMobile ? 'medium' : 'large'}`}
                                    disabled={!formRenderProps.allowSubmit || confirmDisabled}
                                >
                                    Confirm appointment
                                </Button>
                                <Button type="button" fillMode="flat" size={`${isMobile ? 'medium' : 'large'}`} onClick={onCancel}>
                                    Cancel
                                </Button>
                            </StackLayout>
                        </StackLayout>
                    </FormElement>
                )}
            />
        </div>
    );
}

function ConfirmedMeetingProposalView({ meetingProposal, slotTime }: { meetingProposal: PublicMeetingProposal; slotTime: Date }) {
    return (
        <div className="k-text-center k-p-4">
            <img src={meetingProposalAcceptedImageUrl} alt="Confirmed meeting" width="276" height="161" className="responsive-image k-mb-8" />
            <H2 className="!k-mb-2">Appointment confirmed!</H2>
            <div className="k-fs-lg">
                {dateTimeService.stringifyToDay(slotTime)} | {dateTimeService.stringifyToTime(slotTime)}
                <br />
                {meetingProposal.durationMinutes} min meeting with {meetingProposal.organizerFullName}
            </div>
        </div>
    );
}

function MeetingProposalError({ description }: { description: string }) {
    return (
        <div className="k-text-center">
            <img src={meetingProposalErrorImageUrl} alt="Invalid booking link" width="560" height="220" className="k-mt-16 k-mb-18 responsive-image" />
            <H2 className="!k-mb-3">Booking error</H2>
            <div className="k-fs-lg">{description}</div>
        </div>
    );
}
