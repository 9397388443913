import { useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { appConfig } from '../config';
import { HttpServiceBase } from '../services/httpServiceBase';
import { useAppDispatch } from '../state/hooks';
import { addNotification } from '../state/notifications/platformNotificationsSlice';

export function useAppNewVersionListener() {
    const dispatch = useAppDispatch();
    const refreshOnNavigate = useRef(false);
    const location = useLocation();

    useEffect(() => {
        function appVersionCheckInterceptor(url: string, response: Response) {
            const apiVersion = response.headers.get('X-Server-Version');
            if (apiVersion && apiVersion !== appConfig.appVersion) {
                HttpServiceBase.unregisterResponseInterceptor(appVersionCheckInterceptor);
                refreshOnNavigate.current = true;

                dispatch(
                    addNotification(
                        { content: 'A new version was just released! Hit refresh to start using it.', actionText: 'Refresh', timeout: 0, type: 'info' },
                        reloadApp
                    )
                );
            }
        }

        HttpServiceBase.registerResponseInterceptor(appVersionCheckInterceptor);

        return () => {
            refreshOnNavigate.current = false;
            HttpServiceBase.unregisterResponseInterceptor(appVersionCheckInterceptor);
        };
    }, [dispatch]);

    useLayoutEffect(() => {
        if (!refreshOnNavigate.current || location.state) return;

        reloadApp();
    }, [location]);
}

function reloadApp() {
    window.location.reload();
}
