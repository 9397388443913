import { FormContext } from '@progress/kendo-react-form/dist/es/FormContext';
import { ElementType, ReactNode, useContext } from 'react';
import { combineClassNames } from '../../services/common';

// HTML forms are submitted on enter only when an input element is focused inside of them (this is by specification). This component enables form submit on enter no matter what the focused element is within it.
export function SubmitFormOnEnter({
    Element = 'div',
    className,
    children
}: {
    Element?: ElementType<{ className?: string }>;
    className?: string;
    children?: ReactNode;
}) {
    const formContext = useContext(FormContext);
    const submitForm = formContext?.onSubmit;
    return (
        <Element
            className={combineClassNames('k-outline-none', className)}
            tabIndex={0}
            onKeyDown={
                submitForm &&
                (e => {
                    if (e.key !== 'Enter') return;
                    submitForm(e);
                })
            }
        >
            {children}
        </Element>
    );
}
