import { useEffect, useState } from 'react';

export function useLazyHtmlParser() {
    const [htmlParseFunction, setParseFunction] = useState<((html: string) => any) | undefined>();

    useEffect(() => {
        import('html-react-parser').then(module => {
            setParseFunction(() => module.default);
        });
    }, []);

    return htmlParseFunction;
}
