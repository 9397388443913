import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CellTemplateProps, createCellTemplateFromComponent } from '../../components/common/grid';
import { hypothesisGroupLabelMap, hypothesisTypeLabelMap } from '../../components/hypotheses/common';
import { EmptyHypothesesView } from '../../components/hypotheses/emptyHypothesesView';
import { HypothesisEditMode, HypothesisEditor } from '../../components/hypotheses/hypothesisEditor';
import { HypothesisLikelihoodChip } from '../../components/hypotheses/hypothesisLikelihoodChip';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { Pager } from '../../components/ui/pager';
import { useGlobalCanvas } from '../../hooks/canvasHooks';
import { BoxType } from '../../services/canvasService';
import { Hypotheses, Hypothesis, HypothesisGroup, HypothesisSortOrder, hypothesesService } from '../../services/hypothesesService';
import { HypothesesListPreferences, listPreferencesService } from '../../services/listPreferencesService';
import { RealTimeUpdateCanvasItemEventData, RealTimeUpdateHypothesisEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';

export function CustomerProblemsHypothesesPage() {
    const { ideaId } = useParams();
    const [hypothesesData, setHypothesesData] = useState<Hypotheses>();
    const [listPreferences, setListPreferences] = useState<HypothesesListPreferences>(() => listPreferencesService.getHypothesesListPreferences());
    const [skip, setSkip] = useState(0);
    const navigate = useNavigate();

    const hypothesesGroup = HypothesisGroup.CustomerProblem;
    const loadHypothesesData = useCallback(() => {
        if (!ideaId) return;
        hypothesesService.getHypotheses(ideaId, hypothesesGroup, skip, HypothesisSortOrder.UpdatedOnDesc, listPreferences.pageSize).then(setHypothesesData);
    }, [hypothesesGroup, ideaId, listPreferences.pageSize, skip]);

    useEffect(() => {
        setHypothesesData(undefined);
        loadHypothesesData();
    }, [loadHypothesesData]);

    useEffect(() => {
        function handleHypothesisEvent(e: RealTimeUpdateHypothesisEventData) {
            if (e.ideaId !== ideaId || e.hypothesisGroup !== hypothesesGroup) return;

            loadHypothesesData();
        }

        function onCanvasItemDeleted(e: RealTimeUpdateCanvasItemEventData) {
            if (e.ideaId !== ideaId || (e.box !== BoxType.CustomerSegments && e.box !== BoxType.Problem)) return;

            loadHypothesesData();
        }

        function onCanvasItemRestored(e: RealTimeUpdateCanvasItemEventData) {
            if (e.ideaId !== ideaId || (e.box !== BoxType.CustomerSegments && e.box !== BoxType.Problem)) return;

            loadHypothesesData();
        }

        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisAdd', handleHypothesisEvent);
        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisUpdate', handleHypothesisEvent);
        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisDelete', handleHypothesisEvent);
        realTimeUpdatesEventHub.addEventListener('idea', 'hypothesisRestore', handleHypothesisEvent);

        realTimeUpdatesEventHub.addEventListener('idea', 'itemDelete', onCanvasItemDeleted);
        realTimeUpdatesEventHub.addEventListener('idea', 'itemRestore', onCanvasItemRestored);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisAdd', handleHypothesisEvent);
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisUpdate', handleHypothesisEvent);
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisDelete', handleHypothesisEvent);
            realTimeUpdatesEventHub.removeEventListener('idea', 'hypothesisRestore', handleHypothesisEvent);

            realTimeUpdatesEventHub.removeEventListener('idea', 'itemDelete', onCanvasItemDeleted);
            realTimeUpdatesEventHub.removeEventListener('idea', 'itemRestore', onCanvasItemRestored);
        };
    }, [hypothesesGroup, ideaId, loadHypothesesData]);

    if (!hypothesesData) return <LoadingIndicator className="k-d-block -block-center" size="big" />;
    if (!hypothesesData.hypotheses.length)
        return (
            <EmptyHypothesesView
                hypothesesGroupName={hypothesisGroupLabelMap[hypothesesGroup]}
                assumptionsDescriptions="your potential customers and their supposed problems"
            />
        );

    return (
        <>
            <Grid
                data={hypothesesData.hypotheses}
                scrollable="none"
                className="k-icp-grid-navigatable"
                onRowClick={e => {
                    const hypothesis: Hypothesis = e.dataItem;
                    navigate(`${hypothesis.id}/view`);
                }}
            >
                <GridColumn title="Hypothesis" cell={HypothesisGridCellTemplate} />
                <GridColumn title="Type" cell={HypothesisTypeGridCellTemplate} />
                <GridColumn title="Segment" cell={HypothesisCustomerSegmentGridCellTemplate} width={78} />
                {/* <GridColumn title="Related research" cell={HypothesisRelatedResearchItemsGridCellTemplate} width={157} /> */}
                <GridColumn title="Likelihood" cell={HypothesisLikelihoodGridCellTemplate} />
            </Grid>
            <Pager
                total={hypothesesData.totalCount}
                skip={skip}
                take={listPreferences.pageSize}
                onPageChange={(skip, take) => {
                    const newListPreferences: HypothesesListPreferences = { pageSize: take };
                    listPreferencesService.saveHypothesesListPreferences(newListPreferences);
                    setListPreferences(newListPreferences);
                    setSkip(skip);
                }}
            />
        </>
    );
}

const HypothesisGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: hypothesis }: CellTemplateProps<Hypothesis>) {
    return <HypothesisEditor value={hypothesis} editMode={HypothesisEditMode.View} bordered={false} className="k-no-click" />;
});

const HypothesisCustomerSegmentGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: hypothesis, ...gg }: CellTemplateProps<Hypothesis>) {
    const { canvas } = useGlobalCanvas(undefined, true);

    if (!canvas.boxes) return <LoadingIndicator size="small" />;

    const customerSegmentsBox = canvas.boxes.find(b => b.type === BoxType.CustomerSegments);
    if (!customerSegmentsBox) return null;
    const customerSegment = customerSegmentsBox.items.find(i => i.id === hypothesis.customerSegmentId);
    if (!customerSegment || !customerSegment.colorCode) return null;

    return (
        <Tooltip anchorElement="target" position="bottom" openDelay={0}>
            <div className="palette-color" style={{ backgroundColor: `#${customerSegment.colorCode}` }} title={customerSegment.content} />
        </Tooltip>
    );
});

// const HypothesisRelatedResearchItemsGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: hypothesis }: CellTemplateProps<Hypothesis>) {
//     if (!hypothesis.interviewResearchCount) return <span className="k-icp-subtle-text k-no-click">No research</span>;

//     return (
//         <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1 k-no-click">
//             <InterviewIcon className="k-icp-icon k-icp-icon-size-4" />
//             <span>{hypothesis.interviewResearchCount}</span>
//         </StackLayout>
//     );
// });

const HypothesisLikelihoodGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: hypothesis }: CellTemplateProps<Hypothesis>) {
    return <HypothesisLikelihoodChip likelihood={hypothesis.likelihood} className="k-no-click k-white-space-nowrap" />;
});

const HypothesisTypeGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: hypothesis }: CellTemplateProps<Hypothesis>) {
    return <span className="k-no-click">{hypothesisTypeLabelMap[hypothesis.type]}</span>;
});
