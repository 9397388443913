export class Awaitable<T> {
    private readonly _promise: Promise<T>;
    private _resolve!: (value: T) => void;
    private _reject!: (reason?: any) => void;

    constructor() {
        this._promise = new Promise<T>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }

    get promise() {
        return this._promise;
    }
    get resolve() {
        return this._resolve;
    }
    get reject() {
        return this._reject;
    }
}

export function executeExclusively<TResult>(operationName: string, callback: () => TResult | Promise<TResult>): Promise<TResult> {
    if (window.navigator.locks?.request) return window.navigator.locks.request(operationName, callback);
    else
        return new Promise(async (resolve, reject) => {
            const superTokensLockModule = await import('browser-tabs-lock');
            const lock = new superTokensLockModule.default();
            let acquiredLock = false;
            try {
                acquiredLock = await lock.acquireLock(operationName);
                if (!acquiredLock) throw new Error('Filed to acquire lock: ' + operationName);
                const result = callback();
                if (result instanceof Promise) {
                    const awaitedResult = await result;
                    resolve(awaitedResult);
                } else {
                    resolve(result);
                }
            } catch (e) {
                reject(e);
            } finally {
                acquiredLock && (await lock.releaseLock(operationName));
            }
        });
}

export class AsyncOperationsQueue {
    private pendingOperation: Promise<void> | undefined;

    execute<TResult>(operation: () => Promise<TResult>): Promise<TResult> {
        const resultAwaitable = new Awaitable<TResult>();
        const operationToAwait = this.pendingOperation;
        const newPendingOperation = (this.pendingOperation = new Promise<void>(async resolve => {
            await operationToAwait;

            try {
                const result = await operation();
                resultAwaitable.resolve(result);
            } catch (e) {
                resultAwaitable.reject(e);
            } finally {
                resolve();
            }
        }).finally(() => {
            if (this.pendingOperation === newPendingOperation) this.pendingOperation = undefined;
        }));

        return resultAwaitable.promise;
    }
}

export class AsyncOperationsTracker {
    private readonly trackedOperations = new Set<Promise<unknown>>();

    track<TResult>(operation: Promise<TResult>): Promise<TResult> {
        const trackedOperation = operation.finally(() => this.trackedOperations.delete(trackedOperation));
        this.trackedOperations.add(trackedOperation);

        return operation;
    }

    await(): Promise<unknown> {
        if (!this.trackedOperations.size) return Promise.resolve();

        return Promise.all(Array.from(this.trackedOperations));
    }
}

export function toAsyncTrackableOperation<TArgs extends unknown[], TResult>(
    tracker: AsyncOperationsTracker,
    operation?: (...args: TArgs) => Promise<TResult>
): undefined | ((...args: TArgs) => Promise<TResult>) {
    if (!operation) return undefined;

    return function(...args: TArgs) {
        return tracker.track(operation(...args));
    };
}
