import {
    Sortable,
    SortableItemUIProps,
    SortableOnDragEndEvent,
    SortableOnDragOverEvent,
    SortableOnDragStartEvent,
    SortableOnNavigateEvent
} from '@progress/kendo-react-sortable';
import { useEffect, useState } from 'react';
import { combineClassNames, composeFunctions } from '../../services/common';
import CanvasBoxItem, { CanvasBoxItemProps } from './item';

type CanvasBoxItemsListItem = Omit<CanvasBoxItemProps, 'elementAttributes' | 'forwardElementRef'> & { id: number };

let dragCuePositionCompensations: Record<number, { x: number; y: number }> = {};
const overlayClassName = 'canvas-box-drag-and-drop-overlay';

export function CanvasBoxItemsSortableList({
    items,
    onItemReordered,
    onItemDragStarted,
    onItemDragEnded,
    dropAreaClueElement
}: {
    items: CanvasBoxItemsListItem[];
    onItemReordered: (id: number, newIndex: number) => void;
    onItemDragStarted?: (id: number, index: number) => void;
    onItemDragEnded?: () => void;
    dropAreaClueElement?: Element | null;
}) {
    const [dragInProgress, setDragInProgress] = useState(false);
    const [reorderedItems, setReorderedItems] = useState<CanvasBoxItemsListItem[]>();

    useEffect(() => {
        dragCuePositionCompensations = {};

        return () => {
            dragCuePositionCompensations = {};
        };
    }, []);

    useEffect(() => {
        if (!dropAreaClueElement) return;

        const createOverlay = () => {
            const topElement = document.createElement('div');
            const rightElement = document.createElement('div');
            const bottomElement = document.createElement('div');
            const leftElement = document.createElement('div');

            topElement.className = rightElement.className = bottomElement.className = leftElement.className = overlayClassName;

            const draggableRectangle = dropAreaClueElement.getBoundingClientRect();
            topElement.style.inset = `0 0 auto 0`;
            topElement.style.height = draggableRectangle.top + 'px';

            rightElement.style.inset = `${draggableRectangle.top}px 0 auto ${draggableRectangle.left + draggableRectangle.width}px`;
            rightElement.style.height = draggableRectangle.height + 'px';

            bottomElement.style.inset = `${draggableRectangle.top + draggableRectangle.height}px 0 0 0`;
            leftElement.style.inset = `${draggableRectangle.top}px auto auto 0`;
            leftElement.style.height = draggableRectangle.height + 'px';
            leftElement.style.width = draggableRectangle.left + 'px';

            document.body.append(topElement, rightElement, bottomElement, leftElement);
        };

        const destroyOverlay = () => {
            const overlayElements = Array.prototype.slice.call(document.getElementsByClassName(overlayClassName));
            overlayElements.forEach(e => e.remove());
        };

        const onScroll = () => {
            destroyOverlay();
            createOverlay();
        };

        if (dragInProgress) {
            createOverlay();
            document.addEventListener('scroll', onScroll, true);
        } else {
            destroyOverlay();
        }

        return () => {
            document.removeEventListener('scroll', onScroll, true);
        };
    }, [dropAreaClueElement, dragInProgress]);

    const onItemDragStart = (e: SortableOnDragStartEvent) => {
        const draggedItem = items[e.prevIndex];
        if (draggedItem.isEditing) {
            e.preventDefault();
            return;
        }

        setDragInProgress(true);
        setReorderedItems(items);
        onItemDragStarted?.(draggedItem.id, e.prevIndex);
    };

    const onReorder = (e: SortableOnDragOverEvent) => {
        setReorderedItems(e.newState as CanvasBoxItemsListItem[]);
    };

    const onReorderWithKeyboard = (e: SortableOnNavigateEvent) => {
        setReorderedItems(e.newState as CanvasBoxItemsListItem[]);
        onReorderComplete(e.nextIndex, e.newState as CanvasBoxItemsListItem[]);
    };

    const onItemDragEnd = (e: SortableOnDragEndEvent) => {
        onReorderComplete(e.nextIndex, e.newState as CanvasBoxItemsListItem[]);
    };

    const onReorderComplete = (itemNewIndex: number, newOrder: CanvasBoxItemsListItem[]) => {
        if (itemNewIndex >= 0) {
            const reorderedItem = newOrder[itemNewIndex];
            if (reorderedItem) onItemReordered(reorderedItem.id, itemNewIndex);
        }

        setReorderedItems(undefined);
        setDragInProgress(false);
        onItemDragEnded?.();
    };

    return (
        <Sortable
            idField="id"
            data={reorderedItems || items}
            itemUI={CanvasBoxSortableItem}
            onDragStart={onItemDragStart}
            onDragOver={onReorder}
            onNavigate={onReorderWithKeyboard}
            onDragEnd={onItemDragEnd}
            className={dragInProgress ? 'sortable-in-progress' : undefined}
        />
    );
}

function CanvasBoxSortableItem(props: SortableItemUIProps) {
    const boxItem = props.dataItem as CanvasBoxItemsListItem;

    const classes = ['draggable-item'];
    if (props.isDragCue) {
        classes.push('draggable-item-cue', 'k-icp-shadow-md');
        const cueCompensation = dragCuePositionCompensations[boxItem.id];
        if (cueCompensation) {
            props.style.transform = `translate(-${cueCompensation.x + 10}px, -${cueCompensation.y + 10}px) rotate(3deg)`;
            props.style.transformOrigin = `${cueCompensation.x}px ${cueCompensation.y}px`;
        }
    } else if (props.isDragged) {
        classes.push('draggable-item-placeholder');
    }

    const onMouseDown =
        !props.isDisabled && !props.isDragged
            ? (e: React.MouseEvent<HTMLDivElement>) => {
                  const mouseX = e.pageX;
                  const mouseY = e.pageY;
                  const pressedElementRect = e.currentTarget.getBoundingClientRect();
                  const elementX = pressedElementRect.left;
                  const elementY = pressedElementRect.top;

                  dragCuePositionCompensations[boxItem.id] = { x: mouseX - elementX, y: mouseY - elementY };
              }
            : undefined;

    const elementAttributes: React.HtmlHTMLAttributes<HTMLDivElement> = { ...props.attributes };
    elementAttributes.style = { ...props.style, cursor: undefined };
    elementAttributes.onMouseDown = composeFunctions(onMouseDown, props.attributes.onMouseDown);
    elementAttributes.className = combineClassNames(props.attributes.className, classes.join(' '));

    return <CanvasBoxItem {...props.dataItem} elementAttributes={elementAttributes} forwardElementRef={props.forwardRef} />;
}

export type CanvasBoxListItem = CanvasBoxItemProps & { key: React.Key };
export function CanvasBoxItemsList({ items }: { items?: CanvasBoxListItem[] }) {
    return items ? (
        <>
            {items.map(item => (
                <CanvasBoxItem {...item} />
            ))}
        </>
    ) : null;
}
