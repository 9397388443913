import { ComboBox, ComboBoxFilterChangeEvent, ComboBoxProps } from '@progress/kendo-react-dropdowns';
import React, { CSSProperties, useEffect, useState } from 'react';
import { dateTimeService } from '../../services/dateTimeService';
import LoadingIndicator from '../ui/loadingIndicator';

type TimeZoneData = { name: string; title: string };
export function TimeZonePicker({
    id,
    value,
    onChange,
    disabled,
    valid,
    placeholder,
    className,
    size,
    style
}: {
    id?: string;
    value?: string | null;
    onChange?: (e: { value?: string | undefined }) => void;
    disabled?: boolean;
    valid?: boolean;
    placeholder?: string;
    className?: string;
    size?: ComboBoxProps['size'];
    style?: CSSProperties;
}) {
    const [timeZones, setTimeZones] = useState<TimeZoneData[]>();
    const [filteredTimeZones, setFilteredTimeZones] = useState<TimeZoneData[]>();

    useEffect(() => {
        dateTimeService.getAllIanaTimeZones().then(timeZones =>
            setTimeZones(
                timeZones.map(tz => ({
                    name: tz,
                    title: `(${dateTimeService.getShortTimeZoneTitle(tz)}) ${dateTimeService.getGenericTimeZoneTitle(tz)} - ${tz}`
                }))
            )
        );
    }, []);

    function onFilterChange(event: ComboBoxFilterChangeEvent) {
        if (!event.filter.value || !timeZones) {
            setFilteredTimeZones(undefined);
            return;
        }

        setFilteredTimeZones(timeZones.filter(c => c.title.toLowerCase().includes(event.filter.value.toLowerCase())));
    }

    const isLoading = !timeZones;
    const timeZoneValue: TimeZoneData | null = isLoading ? null : value ? timeZones.find(tz => tz.name === value) ?? { name: value, title: value } : null;
    return (
        <ComboBox
            id={id}
            data={filteredTimeZones ?? timeZones}
            textField="title"
            dataItemKey="name"
            value={timeZoneValue}
            onChange={onChange ? e => onChange({ value: e.value ? (e.value as TimeZoneData).name : undefined }) : undefined}
            loading={isLoading}
            filterable={true}
            suggest={true}
            onFilterChange={onFilterChange}
            listNoDataRender={e => React.cloneElement(e, undefined, <div>{isLoading ? <LoadingIndicator size="small" /> : 'NO TIMEZONES FOUND.'}</div>)}
            disabled={disabled}
            valid={valid}
            placeholder={placeholder}
            className={className}
            size={size}
            style={style}
        />
    );
}
