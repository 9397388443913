import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScheduleEditor, ScheduleEditorHandle } from '../../components/events/scheduleEditor';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { useStickyFooter } from '../../hooks/commonHooks';
import { useScheduleParams } from '../../hooks/routerHooks';
import { RealTimeUpdateScheduleEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { FullSchedule, ScheduleData, schedulesService } from '../../services/schedulesService';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

export function EditSchedulePage() {
    const { ideaId, scheduleId } = useScheduleParams();
    const navigate = useNavigate();
    const [schedule, setSchedule] = useState<FullSchedule>();
    const scheduleEditorRef = useRef<ScheduleEditorHandle>(null);

    useEffect(() => {
        schedulesService.getSchedule(ideaId, scheduleId).then(setSchedule);
    }, [ideaId, scheduleId]);

    const dispatch = useAppDispatch();
    useEffect(() => {
        const handleScheduleDelete = (e: RealTimeUpdateScheduleEventData) => {
            if (e.ideaId !== ideaId || e.scheduleId !== scheduleId) return;

            dispatch(addNotification({ content: 'The schedule was deleted' }));
            navigate('../schedules');
        };

        realTimeUpdatesEventHub.addEventListener('scheduling', 'scheduleDelete', handleScheduleDelete);

        return () => realTimeUpdatesEventHub.removeEventListener('scheduling', 'scheduleDelete', handleScheduleDelete);
    }, [dispatch, ideaId, navigate, scheduleId]);

    const isLoaded = !!schedule;
    const footerRef = useStickyFooter(isLoaded);

    return (
        <>
            <H1 className="!k-mb-6 !k-px-6">Edit schedule</H1>
            {isLoaded ? (
                <Form
                    onSubmitClick={() => {
                        scheduleEditorRef.current?.validate();
                    }}
                    onSubmit={async (data: Record<string, any>) => {
                        const isRelatedToSchedule = schedule.relatedResearch.length > 0;

                        const updatedScheduleData: Partial<ScheduleData> = {
                            title: data.title,
                            startTime: isRelatedToSchedule ? undefined : data.scheduleRange.start,
                            endTime: isRelatedToSchedule ? undefined : data.scheduleRange.end,
                            durationMinutes: data.durationMinutes,
                            bufferBeforeMinutes: data.bufferBeforeMinutes ?? 0,
                            bufferAfterMinutes: data.bufferAfterMinutes ?? 0,
                            allowSameDayBooking: data.allowSameDayBooking ?? false,
                            timeZone: data.timeZone,
                            description: data.description,
                            locationOptions: data.locationOptions,
                            timetable: data.timetable
                        };

                        await schedulesService.partiallyUpdateSchedule(ideaId, scheduleId, updatedScheduleData);
                        navigate('./..');
                    }}
                    initialValues={{ ...schedule, scheduleRange: { start: schedule.startTime, end: schedule.endTime } }}
                    ignoreModified={true}
                    render={formRenderProps => (
                        <FormElement noValidate={true}>
                            <div className="k-px-6">
                                <ScheduleEditor
                                    ref={scheduleEditorRef}
                                    ideaId={ideaId}
                                    scheduleId={scheduleId}
                                    className="page-content-middle"
                                    formRenderProps={formRenderProps}
                                />
                            </div>
                            <StackLayout
                                ref={r => (footerRef.current = r?.element ?? null)}
                                align={{ horizontal: 'center', vertical: 'top' }}
                                className="k-mt-11 k-gap-4 k-px-6 k-py-3 sticky-footer"
                            >
                                <Button themeColor="primary" type="submit" size="large" disabled={!formRenderProps.allowSubmit}>
                                    Save changes
                                </Button>
                                <Button type="reset" size="large" onClick={() => navigate('./..')}>
                                    Discard
                                </Button>
                            </StackLayout>
                        </FormElement>
                    )}
                />
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}
        </>
    );
}
