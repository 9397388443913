import { Outlet } from 'react-router-dom';
import TopNav from '../../components/topNav/topNav';
import { combineClassNames } from '../../services/common';

export function PublicTopNavLayout({ contentClassName, suppressLogoLink = true }: { contentClassName?: string, suppressLogoLink? : boolean }) {
    return (
        <div className="page">
            <TopNav suppressLogoLink={suppressLogoLink} />
            <div className={combineClassNames('page-content page-content-section', contentClassName)}>
                <Outlet />
            </div>
        </div>
    );
}
