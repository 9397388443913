import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCountryFor } from '../../components/common/countries';
import { CompanyEditor } from '../../components/contacts/edit';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { useStickyFooter } from '../../hooks/commonHooks';
import { CompanyEditData, FullCompany, contactsService } from '../../services/contactsService';
import { RealTimeUpdateCompanyEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

export function EditCompanyPage() {
    const { ideaId, companyId: companyIdString } = useParams();
    const companyId = parseInt(companyIdString ?? '');
    const [company, setCompany] = useState<FullCompany>();
    const [initialCountry, countriesList] = useCountryFor(company);

    const navigate = useNavigate();

    useEffect(() => {
        if (!companyId) return;
        contactsService.getCompanyById(ideaId!, companyId).then(setCompany);
    }, [companyId, ideaId]);

    const dispatch = useAppDispatch();
    useEffect(() => {
        const handleCompanyDelete = (e: RealTimeUpdateCompanyEventData) => {
            if (e.ideaId !== ideaId || e.companyId !== companyId) return;

            dispatch(addNotification({ content: 'The company was deleted' }));
            navigate('../companies');
        };

        realTimeUpdatesEventHub.addEventListener('contact', 'companyDelete', handleCompanyDelete);

        return () => realTimeUpdatesEventHub.removeEventListener('contact', 'companyDelete', handleCompanyDelete);
    }, [companyId, dispatch, ideaId, navigate]);

    const isLoaded = typeof company !== 'undefined' && typeof initialCountry !== 'undefined';
    const footerRef = useStickyFooter(isLoaded);

    return (
        <>
            <H1 className="!k-mb-6 !k-px-6">Edit company</H1>
            {isLoaded ? (
                <Form
                    onSubmit={async (data: Record<string, any>) => {
                        const updateData: CompanyEditData = {
                            name: data.name,
                            address: data.address || null,
                            city: data.city || null,
                            countryCode: data.country?.code,
                            website: data.website || null,
                            facebook: data.facebook || null,
                            linkedIn: data.linkedIn || null,
                            twitter: data.twitter || null,
                            picture: data.picture
                        };

                        await contactsService.updateCompany(ideaId!, company.id, updateData);
                        navigate('./..');
                    }}
                    initialValues={{ ...company, country: initialCountry }}
                    ignoreModified={true}
                    render={formRenderProps => (
                        <FormElement noValidate={true}>
                            <CompanyEditor countriesList={countriesList} className="k-px-6" />
                            <StackLayout
                                ref={r => (footerRef.current = r?.element ?? null)}
                                align={{ horizontal: 'center', vertical: 'top' }}
                                className="k-mt-11 k-gap-4 k-px-6 k-py-3 sticky-footer"
                            >
                                <Button themeColor="primary" type="submit" size="large" disabled={!formRenderProps.allowSubmit}>
                                    Save changes
                                </Button>
                                <Button type="reset" size="large" onClick={() => navigate('./..')}>
                                    Discard
                                </Button>
                            </StackLayout>
                        </FormElement>
                    )}
                />
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}
        </>
    );
}
