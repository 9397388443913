import { Calendar, CalendarCell, CalendarCellProps, CalendarProps } from '@progress/kendo-react-dateinputs';
import { createContext, useContext } from 'react';
import { combineClassNames } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';

type CalendarRestrictedDaysContextValue = { allowedDays?: Date[] };
const CalendarRestrictedDaysContext = createContext<CalendarRestrictedDaysContextValue>({});

function useCalendarRestrictedDaysContext() {
    return useContext(CalendarRestrictedDaysContext);
}

export function DaysRestrictedCalendar({ allowedDays, ...calendarProps }: { allowedDays?: Date[] } & CalendarProps) {
    const currentTime = new Date();
    const isTodayEnabled = !allowedDays || allowedDays.some(d => dateTimeService.isSameDay(d, currentTime));

    return (
        <CalendarRestrictedDaysContext.Provider value={{ allowedDays: allowedDays }}>
            <Calendar
                {...calendarProps}
                cell={RestrictedDayCell}
                className={combineClassNames(calendarProps.className, isTodayEnabled ? undefined : 'k-icp-calendar-today-disabled')}
            />
        </CalendarRestrictedDaysContext.Provider>
    );
}

function RestrictedDayCell(props: CalendarCellProps) {
    const { allowedDays } = useCalendarRestrictedDaysContext();
    const isAllowed = !allowedDays || allowedDays.some(d => dateTimeService.isSameDay(d, props.value));
    const isDisabled = props.isDisabled || !isAllowed;

    return (
        <CalendarCell
            {...props}
            isDisabled={isDisabled}
            className={combineClassNames(props.className, (allowedDays && isAllowed) || props.isSelected ? 'k-icp-calendar-available-day' : undefined)}
        />
    );
}
