import { useEffect, useState } from 'react';
import { HypothesisGroup } from '../../services/hypothesesService';
import { HypothesisResearchScaleView } from './hypothesisResearchScaleView';
import { HypothesisLikelihoodData, researchService } from '../../services/researchService';

export type ResearchScaleProps = {
    ideaId: string;
    hypothesisId: number;
    hypothesisGroup: HypothesisGroup;
    researchIdsInContext?: number[];
    className?: string;
};

export const HypothesisResearchScale = ({ ideaId, hypothesisId, hypothesisGroup, researchIdsInContext, className }: ResearchScaleProps) => {
    const [hypothesisLikelihoodData, setHypothesisLikelihoodData] = useState<HypothesisLikelihoodData[] | undefined>();

    useEffect(() => {
        const fetchHypothesisLikelihoodData = async () => {
            const hypothesisLikelihoodData = await researchService.getLikelihoodResearchDataPerHypothesis(ideaId, hypothesisGroup, hypothesisId);
            setHypothesisLikelihoodData(hypothesisLikelihoodData);
        };
        fetchHypothesisLikelihoodData();
    }, [ideaId, hypothesisGroup, hypothesisId]);

    const researchData = hypothesisLikelihoodData?.map(researchData => ({
        researchId: researchData.research.id,
        researchTitle: researchData.research.title,
        evaluatedPercentage: researchData.calculatedLikelihood,
        isSpecial: researchIdsInContext && researchIdsInContext.includes(researchData.research.id) ? true : false
    }));

    return <HypothesisResearchScaleView evaluationData={researchData} className={className} isLoading={!hypothesisLikelihoodData} />;
};
