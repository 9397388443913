import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { Link, useNavigate } from 'react-router-dom';
import { H1, H2, P } from '../../components/ui/typography';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { logoutPagePath } from '../../hooks/userHooks';
import deleteAccountIllustrationUrl from '../../images/delete-account-illustration.svg';
import { usersService } from '../../services/usersService';

export function DeleteAccountPage() {
    const { show: showDeleteAccountDialog, element: deleteAccountDialog } = useConfirmDialog();
    const navigate = useNavigate();

    function deleteCurrentAccount() {
        showDeleteAccountDialog({
            title: 'Delete account',
            content: 'Are you sure you want to delete your account? This action cannot be undone.',
            confirmCheckboxText: 'I confirm I want to delete my account',
            confirmButtonText: 'Delete account',
            callback: async () => {
                await usersService.deleteCurrent();
                navigate(logoutPagePath);
            }
        });
    }

    return (
        <div className="page-content-section">
            <H1 className="heading-row">Delete account</H1>
            <StackLayout className="page-content-middle k-gap-6 k-text-center" orientation="vertical">
                <img src={deleteAccountIllustrationUrl} width="640" height="250" alt="Delete account" className="responsive-image" />
                <div className="k-px-8 k-pt-6 k-pb-8">
                    <H2 className="!k-mb-4">We are sorry to see you go...</H2>
                    <P>Be advised, account deletion is final. There will be no way to restore your account.</P>
                </div>
                <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-6">
                    <Button size="large" onClick={deleteCurrentAccount}>
                        <span className="k-text-error">Delete my account</span>
                    </Button>
                    <Link to=".." className="k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md">
                        Cancel
                    </Link>
                </StackLayout>
            </StackLayout>
            {deleteAccountDialog}
        </div>
    );
}
