import { useLocation } from 'react-router-dom';
import { InviteErrorComponent } from './acceptInvitePage';
import { useAppSelector } from '../../state/hooks';

export default function DifferentUserLoggedErrorPage() {
    const location = useLocation();
    const locationStateParams = location.state as { inviteeEmail?: string } | undefined;
    const currentUser = useAppSelector(s => s.user);

    if (!currentUser || !locationStateParams?.inviteeEmail) throw new Error('User or invitee email not found');

    return (
        <InviteErrorComponent
            errorTitle="Cannot accept invitation"
            errorMessage={
                <>
                    You have currently logged in using <span className="k-text-secondary">{currentUser.emailAddress}</span> and the open invite link
                    is intended for <span className="k-text-secondary">{locationStateParams.inviteeEmail}</span>.
                </>
            }
        />
    );
}
