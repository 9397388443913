import { Error as ErrorComponent } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';

export function EditorErrorsList({ errors, isEditing }: { errors?: string[]; isEditing?: boolean }) {
    const listErrors = isEditing && !!errors?.length;
    if (!listErrors) return null;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }}>
            {errors.map((e, i) => (
                <ErrorComponent key={i}>{e}</ErrorComponent>
            ))}
        </StackLayout>
    );
}
