import { DropDownList, DropDownListHandle } from '@progress/kendo-react-dropdowns';
import { StackLayout } from '@progress/kendo-react-layout';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { TaskEditorProps, TaskEditorRef, useTaskCommitOperation } from '.';
import { findCanvasItem, useGlobalCanvas } from '../../../hooks/canvasHooks';
import { BoxItem, BoxType } from '../../../services/canvasService';
import { distinct, groupBy, immutableUpdate } from '../../../services/common';
import {
    AlternativeSolutionHypothesis,
    Hypothesis,
    HypothesisGroup,
    HypothesisLikelihood,
    HypothesisType,
    hypothesesService
} from '../../../services/hypothesesService';

import { useHypothesesInterviewQuotesDataSource } from '../../../hooks/hypothesesHooks';
import { domService } from '../../../services/domService';
import { HypothesesLikelihoodEditorParams } from '../../../services/journeyService';
import { ValidationScope, ValidationScopeHandle, ValidationUnit, ValidationUnitHandle } from '../../common/validation';
import { hypothesisLikelihoodLabelMap, likelihoodDropDownValues } from '../../hypotheses/common';
import { HypothesisEditMode, HypothesisEditor } from '../../hypotheses/hypothesisEditor';
import { HypothesisResearchScale } from '../../hypotheses/hypothesisResearchScale';
import { InterviewItemQuotesEditList } from '../../interview/interviewItemQuotes';
import LoadingIndicator from '../../ui/loadingIndicator';
import { EditorErrorsList } from './shared/editorErrorsList';
import { EditorMainArea } from './shared/editorMainArea';

const hypothesisTypeTexts = {
    [HypothesisType.PainLevel]: 'Pain-level hypothesis',
    [HypothesisType.AlternativeSolutionUsage]: 'Alternative solution hypothesis',
    [HypothesisType.AlternativeSolutionSatisfaction]: 'Alternative solution satisfaction hypothesis'
};

const hypothesisTypeServiceMap = {
    [HypothesisType.PainLevel]: hypothesesService.partiallyUpdateCustomerProblemPainLevelHypothesis.bind(hypothesesService),
    [HypothesisType.AlternativeSolutionUsage]: hypothesesService.partiallyUpdateCustomerProblemAlternativeSolutionHypothesis.bind(hypothesesService),
    [HypothesisType.AlternativeSolutionSatisfaction]: hypothesesService.partiallyUpdateCustomerProblemAlternativeSolutionSatisfactionHypothesis.bind(
        hypothesesService
    )
};

export const HypothesisLikelihoodEditor = forwardRef<TaskEditorRef, TaskEditorProps<HypothesesLikelihoodEditorParams>>(function HypothesesLikelihoodEditor(
    props,
    ref
) {
    const problemId = props.params.customerProblemId;
    const hypotheses = props.taskData.researchHypotheses?.[props.params.researchId] ?? [];
    const hypothesesByProblemId = hypotheses.filter(h => h.customerProblemId === problemId);
    const painLevelHypotheses = hypothesesByProblemId.filter(h => h.type === HypothesisType.PainLevel);
    const alternativeSolutionAndSatisfactionHypotheses = hypothesesByProblemId.filter(
        (h): h is AlternativeSolutionHypothesis =>
            h.type === HypothesisType.AlternativeSolutionUsage || h.type === HypothesisType.AlternativeSolutionSatisfaction
    );
    const { canvas } = useGlobalCanvas(undefined, true);
    const alternativeSolutionUniqueIds = distinct(alternativeSolutionAndSatisfactionHypotheses.map(h => h.alternativeSolutionId));
    const alternativeSolutionItems = Array.from(alternativeSolutionUniqueIds).reduce((map: Record<number, BoxItem | undefined>, id) => {
        const alternativeSolution = findCanvasItem(canvas.boxes, BoxType.AlternativeSolutions, id);
        map[id] = alternativeSolution;
        return map;
    }, {});

    const groupedAltSSatisfactionHypotheses = groupBy(alternativeSolutionAndSatisfactionHypotheses, h => h.alternativeSolutionId);
    const hypothesesLikelihoodValidationScope = useRef<ValidationScopeHandle>(null);
    const dropdownValidationHandlers = useRef<Map<number, HypothesesLikelihoodWithGraphHandle>>(new Map());

    useImperativeHandle(
        ref,
        () => ({
            commit() {
                if (!hypothesesLikelihoodValidationScope.current) return;
                hypothesesLikelihoodValidationScope.current.validate();

                if (dropdownValidationHandlers.current) {
                    const invalidHandler = Array.from(dropdownValidationHandlers.current.values()).find(e => !e.isValid);
                    if (invalidHandler && invalidHandler.dropdownListHandle?.element)
                        domService.scrollIntoViewIfNeeded(invalidHandler.dropdownListHandle.element);
                }

                return {
                    isValid: hypothesesLikelihoodValidationScope.current.isValid,
                    preventScroll: true
                };
            }
        }),
        []
    );

    const setDropdownValidationRef = (ref: HypothesesLikelihoodWithGraphHandle | null, hypothesisId: number) => {
        if (ref) {
            dropdownValidationHandlers.current.set(hypothesisId, ref);
        } else {
            dropdownValidationHandlers.current.delete(hypothesisId);
        }
    };

    return (
        <>
            <ValidationScope ref={hypothesesLikelihoodValidationScope}>
                <StackLayout orientation="vertical" className="k-gap-6" align={{ horizontal: 'stretch', vertical: 'top' }}>
                    {painLevelHypotheses.map(hyp => (
                        <EditorAreaWrapper key={hyp.id}>
                            <div className="k-font-size-lg k-font-medium">Likelihood of pain-level hypothesis</div>
                            <HypothesesLikelihoodWithGraph
                                ref={ref => setDropdownValidationRef(ref, hyp.id)}
                                hypothesesType={HypothesisType.PainLevel}
                                hypothesis={hyp}
                                props={props}
                            />
                        </EditorAreaWrapper>
                    ))}
                </StackLayout>
                <StackLayout orientation="vertical" className="k-gap-6" align={{ horizontal: 'stretch', vertical: 'top' }}>
                    {Object.entries(groupedAltSSatisfactionHypotheses).map(([alternativeSolutionId, hypotheses]) => {
                        const alternativeSolHypotheses = hypotheses?.filter(h => h.type === HypothesisType.AlternativeSolutionUsage) || [];
                        const alternativeSolutionSatisfaction = hypotheses?.filter(h => h.type === HypothesisType.AlternativeSolutionSatisfaction) || [];
                        const alternativeSolutionItem = alternativeSolutionItems[parseInt(alternativeSolutionId)];

                        return (
                            <EditorAreaWrapper key={alternativeSolutionId}>
                                <StackLayout orientation="vertical">
                                    <div className="k-font-size-lg k-font-medium">Likelihood of alternative solution hypotheses</div>
                                    {alternativeSolutionItem && (
                                        <div className="k-font-size-sm k-font-small ">Alternative solution: {alternativeSolutionItem!.content}</div>
                                    )}
                                </StackLayout>
                                <StackLayout orientation="vertical" className="k-gap-2">
                                    {alternativeSolHypotheses.map(h => {
                                        return (
                                            <HypothesesLikelihoodWithGraph
                                                key={h.id}
                                                ref={ref => setDropdownValidationRef(ref, h.id)}
                                                hypothesesType={HypothesisType.AlternativeSolutionUsage}
                                                hypothesis={h}
                                                props={props}
                                            />
                                        );
                                    })}
                                    {alternativeSolutionSatisfaction.map(h => {
                                        return (
                                            <HypothesesLikelihoodWithGraph
                                                key={h.id}
                                                ref={ref => setDropdownValidationRef(ref, h.id)}
                                                hypothesesType={HypothesisType.AlternativeSolutionSatisfaction}
                                                hypothesis={h}
                                                props={props}
                                            />
                                        );
                                    })}
                                </StackLayout>
                            </EditorAreaWrapper>
                        );
                    })}
                </StackLayout>
            </ValidationScope>
        </>
    );
});

function EditorAreaWrapper({ children }: { children?: React.ReactNode }) {
    return (
        <EditorMainArea>
            <StackLayout orientation="vertical" className="k-gap-6">
                {children}
            </StackLayout>
        </EditorMainArea>
    );
}

type HypothesesLikelihoodWithGraphHandle = {
    hypothesisId: number;
    dropdownListHandle: DropDownListHandle | null;
    isValid: boolean;
};

type HypothesesLikelihoodWithGraphProps = {
    hypothesesType: HypothesisType;
    hypothesis: Hypothesis;
    props: TaskEditorProps<HypothesesLikelihoodEditorParams>;
};

const HypothesesLikelihoodWithGraph = forwardRef<HypothesesLikelihoodWithGraphHandle, HypothesesLikelihoodWithGraphProps>(
    ({ hypothesesType, hypothesis, props }, ref) => {
        const dropDownListValidationHandle = useRef<ValidationUnitHandle>(null);
        const dropDownListHandle = useRef<DropDownListHandle>(null);
        const { ideaId, taskData, setTaskData, isEditing } = props;
        const researchId = props.params.researchId;
        const createCommitOperation = useTaskCommitOperation(props);

        useImperativeHandle(
            ref,
            () => {
                return {
                    dropdownListHandle: dropDownListHandle.current,

                    get hypothesisId() {
                        return hypothesis.id;
                    },

                    get isValid() {
                        return dropDownListValidationHandle.current!.isValid ?? true;
                    }
                };
            },
            [hypothesis.id]
        );

        const [tmpLikelihood, setTmpValue] = useState<{ value: HypothesisLikelihood; text: string }>();
        const hypothesisInterviewQuotes = useHypothesesInterviewQuotesDataSource(ideaId, hypothesis.id);
        const likelihoodValue = hypothesis.likelihood ? { value: hypothesis.likelihood, text: hypothesisLikelihoodLabelMap[hypothesis.likelihood] } : null;

        const updateHypothesisLikelihood = createCommitOperation(async (ideaId: string, likelihood: HypothesisLikelihood, hypothesis: Hypothesis) => {
            await updateHypothesisWithService(ideaId, likelihood, hypothesis);

            const hypothesesInResearch = taskData.researchHypotheses![researchId];
            if (!hypothesesInResearch) return;

            const updatedHypothesesInResearch = immutableUpdate<Hypothesis>(
                hypothesesInResearch,
                h => ({ ...h, likelihood: likelihood }),
                h => h.id === hypothesis.id
            );

            setTaskData({
                ...taskData,
                researchHypotheses: {
                    ...taskData.researchHypotheses,
                    [researchId]: updatedHypothesesInResearch
                }
            });
        });

        const updateHypothesisWithService = (ideaId: string, likelihood: HypothesisLikelihood, hypothesis: Hypothesis): Promise<Hypothesis> => {
            const commonPayload = {
                likelihood: likelihood,
                percentage: hypothesis.percentage
            };

            const additionalPayload =
                hypothesis.type === HypothesisType.PainLevel
                    ? { painLevel: hypothesis.painLevel }
                    : hypothesis.type === HypothesisType.AlternativeSolutionSatisfaction
                    ? { satisfactionLevel: hypothesis.satisfactionLevel }
                    : {};

            return hypothesisTypeServiceMap[hypothesis.type](ideaId, hypothesis.id, {
                ...commonPayload,
                ...additionalPayload
            });
        };

        const dropdownListValue = tmpLikelihood ?? likelihoodValue ?? { value: undefined, text: 'Select likelihood' };

        return (
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-6">
                <StackLayout
                    orientation="vertical"
                    align={{ horizontal: 'stretch', vertical: 'top' }}
                    className="k-icp-panel !k-rounded-md k-p-4 k-gap-6 k-align-self-stretch k-flex-basis-3/5 k-flex-shrink-0"
                >
                    <ValidationUnit
                        ref={dropDownListValidationHandle}
                        name={`likelihood_${hypothesis.id}`}
                        value={dropdownListValue}
                        validator={obj => (obj.value ? undefined : 'Hypothesis likelihood is required')}
                    >
                        {(errorMessage, onLikelihoodChange) => {
                            return (
                                <StackLayout orientation="vertical" className="k-gap-2">
                                    <div>Likelihood</div>
                                    <DropDownList
                                        data={likelihoodDropDownValues}
                                        textField="text"
                                        dataItemKey="value"
                                        valid={!errorMessage}
                                        value={dropdownListValue}
                                        disabled={!isEditing}
                                        ref={dropDownListHandle}
                                        onChange={async e => {
                                            setTmpValue(e.value);
                                            onLikelihoodChange(e.value);
                                            await updateHypothesisLikelihood(ideaId, e.value.value as HypothesisLikelihood, hypothesis);
                                            setTmpValue(undefined);
                                        }}
                                    />
                                    <EditorErrorsList isEditing={isEditing} errors={errorMessage ? [errorMessage] : []} />
                                </StackLayout>
                            );
                        }}
                    </ValidationUnit>

                    <div className="k-separator" />
                    <StackLayout orientation="vertical" className="k-gap-2">
                        <div>{hypothesisTypeTexts[hypothesesType]}</div>
                        <HypothesisEditor editMode={HypothesisEditMode.NoEdit} value={hypothesis} bordered />
                    </StackLayout>
                    {hypothesisInterviewQuotes && hypothesisInterviewQuotes.length > 0 && (
                        <InterviewItemQuotesEditList ideaId={ideaId} quotes={hypothesisInterviewQuotes} />
                    )}
                    {!hypothesisInterviewQuotes && <LoadingIndicator size={'small'} className="k-align-self-center" />}
                </StackLayout>
                <HypothesisResearchScale
                    ideaId={ideaId}
                    hypothesisId={hypothesis.id}
                    researchIdsInContext={[researchId]}
                    hypothesisGroup={HypothesisGroup.CustomerProblem}
                    className="hypothesis-research-scale-condensed"
                />
            </StackLayout>
        );
    }
);
