import { Reveal } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, Fragment, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TaskEditorProps } from '.';
import { useAsRef, useSingleClickButton } from '../../../hooks/commonHooks';
import { useResearchSchedules, useTaskManageContactsInResearch, useTaskManageResearchReachOuts } from '../../../hooks/researchHooks';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as CompletedInterviewIcon } from '../../../icons/research-contacts/completed.svg';
import { ReactComponent as ExecuteInterviewIcon } from '../../../icons/research-contacts/execute.svg';
import { ReactComponent as InviteContactIcon } from '../../../icons/research-contacts/invite.svg';
import { ReactComponent as PendingBookingIcon } from '../../../icons/research-contacts/pending.svg';
import { ReactComponent as CloseIcon } from '../../../icons/x.svg';
import emptyResearchContactsPhaseIllustrationUrl from '../../../images/empty-contacts-illustration-extra-small.svg';
import { Activity, ActivityType } from '../../../services/activitiesService';
import { combineClassNames, groupBy } from '../../../services/common';
import { Person, ReducedPerson } from '../../../services/contactsService';
import { dateTimeService } from '../../../services/dateTimeService';
import { Meeting, ResearchReachOut } from '../../../services/events';
import { Interview, InterviewStage } from '../../../services/interviewsService';
import { ResearchContactsEditorParams } from '../../../services/journeyService';
import { ResearchContact, ResearchContactStage } from '../../../services/researchService';
import { FullSchedule } from '../../../services/schedulesService';
import { ShowActivityDialogProps, useDeleteActivityDialog, useManageActivityDialog } from '../../activities/activityDialogs';
import { DropDownButtonItem } from '../../common/boundDropDownButton';
import { InterviewMainActionButton, resolveInterviewLink } from '../../interview/interviewMainActionButton';
import { InterviewStageGuidance } from '../../interview/interviewStage';
import { ResearchContactPicker } from '../../research/researchContactPicker';
import { ResearchContactStagePresentation } from '../../research/researchContactStageView';
import { ResearchContactRelatedUser, ResearchContactTogglePanel } from '../../research/researchContactTogglePanel';
import LoadingIndicator from '../../ui/loadingIndicator';
import { SvgIconButtonContent } from '../../ui/svgIconButtonContent';
import { H3 } from '../../ui/typography';
import { EditorErrorsList } from './shared/editorErrorsList';
import { EditorMainArea } from './shared/editorMainArea';

enum ResearchContactPhaseStageType {
    Passed,
    Current,
    Upcoming
}

export function ResearchMeetingsExecutionEditor(props: TaskEditorProps<ResearchContactsEditorParams>) {
    const researchContacts = props.taskData.researchContacts?.[props.params.researchId];
    const researchContactsByPhaseIndex = researchContacts && groupBy(researchContacts, rc => researchContactStageToPhaseIndexMap[rc.stage]);
    const { confirmRemoveDialog, addContactToResearch, removeContactFromResearch } = useTaskManageContactsInResearch(props);
    const { refreshResearchContactOperation } = useTaskManageResearchReachOuts(props);
    const [deleteActivity, confirmDeleteActivityElement] = useDeleteActivityDialog(props.ideaId, ({ personId }) => refreshResearchContactOperation(personId));
    const [expandedContactId, setExpandedContactId] = useState<number>();
    const [researchSchedules] = useResearchSchedules(props.ideaId, props.params.researchId);

    const showErrors = props.isEditing && !!props.errors?.length;
    const isLoading = !researchContactsByPhaseIndex;

    const [manageActivityDialogElement, showActivityDialog] = useManageActivityDialog(props.ideaId);
    const navigate = useNavigate();

    const autoExpandedContactRef = useRef(false);
    const researchContactsByPhaseIndexRef = useAsRef(researchContactsByPhaseIndex);
    useEffect(() => {
        if (isLoading || autoExpandedContactRef.current || !researchContactsByPhaseIndexRef.current) return;

        for (let phaseIndex = 0; phaseIndex < researchContactPhases.length; phaseIndex++) {
            const contactsInPhase = researchContactsByPhaseIndexRef.current[phaseIndex];
            if (!contactsInPhase) continue;

            const contactToAutoExpand = contactsInPhase.find(c => c.stage !== ResearchContactStage.Complete && c.stage !== ResearchContactStage.Invited);
            if (contactToAutoExpand) {
                autoExpandedContactRef.current = true;
                setExpandedContactId(contactToAutoExpand.contact.id);

                return;
            }
        }
    }, [isLoading, researchContactsByPhaseIndexRef]);

    return (
        <EditorMainArea hasError={showErrors}>
            {isLoading ? (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            ) : (
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-5 k-icp-component-border">
                    {researchContactPhases.map((researchContactPhase, researchContactPhaseIndex) => {
                        const contactsInPhase = researchContactsByPhaseIndex[researchContactPhaseIndex];
                        const elementKey = researchContactPhaseIndex;
                        return (
                            <Fragment key={elementKey}>
                                {researchContactPhaseIndex !== 0 && <div className="k-separator" />}
                                <ResearchContactsPhase
                                    ideaId={props.ideaId}
                                    researchId={props.params.researchId}
                                    phase={researchContactPhase}
                                    contacts={contactsInPhase}
                                    disabled={!props.isEditing}
                                    onDelete={
                                        props.isEditing
                                            ? rc => removeContactFromResearch(rc.contact.id, `${rc.contact.firstName} ${rc.contact.lastName}`)
                                            : undefined
                                    }
                                    expandedContactId={expandedContactId}
                                    onExpandContact={setExpandedContactId}
                                    isFinal={researchContactPhaseIndex === researchContactPhases.length - 1}
                                    researchSchedules={researchSchedules}
                                    canInviteContacts={researchContactPhaseIndex === 0}
                                    customerSegmentId={props.params.customerSegmentId}
                                    onInviteContactSelected={async c => {
                                        await addContactToResearch(c.id);
                                        setExpandedContactId(c.id);
                                    }}
                                    onManageActivity={<TActivity extends ActivityType>(
                                        contactId: number,
                                        activityType: TActivity,
                                        manageActivityDialogProps?: ShowActivityDialogProps<TActivity>
                                    ) => {
                                        showActivityDialog(activityType, {
                                            ...manageActivityDialogProps,
                                            onSaved: async function(item: any) {
                                                await manageActivityDialogProps?.onSaved?.(item);
                                                await refreshResearchContactOperation(contactId);
                                            }
                                        } as ShowActivityDialogProps<TActivity>);
                                    }}
                                    onDeleteActivity={deleteActivity}
                                    onOpenInterview={(interviewId, view) => navigate(resolveInterviewLink(props.ideaId, interviewId, view))}
                                />
                            </Fragment>
                        );
                    })}
                    {confirmRemoveDialog}
                    {manageActivityDialogElement}
                    {confirmDeleteActivityElement}
                </StackLayout>
            )}
            <EditorErrorsList isEditing={props.isEditing} errors={props.errors} />
        </EditorMainArea>
    );
}

function ResearchContactsPhase({
    ideaId,
    researchId,
    phase,
    contacts,
    disabled,
    onDelete,
    expandedContactId,
    onExpandContact,
    isFinal,
    researchSchedules,
    canInviteContacts,
    customerSegmentId,
    onInviteContactSelected,
    onManageActivity,
    onDeleteActivity,
    onOpenInterview
}: {
    ideaId: string;
    researchId: number;
    phase: ResearchContactPhaseData;
    contacts?: ResearchContact[];
    disabled?: boolean;
    onDelete?: (researchContact: ResearchContact) => void;
    expandedContactId?: number;
    onExpandContact?: (contactId?: number) => void;
    isFinal?: boolean;
    researchSchedules?: FullSchedule[];
    canInviteContacts?: boolean;
    customerSegmentId?: number;
    onInviteContactSelected?: (contact: Person) => Promise<void>;
    onManageActivity?: <TActivity extends ActivityType>(contactId: number, activityType: TActivity, props?: ShowActivityDialogProps<TActivity>) => void;
    onDeleteActivity: (contactId: number, activity: Activity) => void;
    onOpenInterview?: (interviewId: number, view: InterviewStage) => void;
}) {
    const [inviteDisabled, inviteCallbackCreator] = useSingleClickButton<
        Parameters<NonNullable<typeof onInviteContactSelected>>,
        ReturnType<NonNullable<typeof onInviteContactSelected>>
    >();

    const [invitingContacts, setInvitingContacts] = useState<boolean>();

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            <StackLayout align={{ horizontal: 'start', vertical: 'bottom' }} className="k-gap-2">
                <H3>{phase.title}</H3>
                <span>{contacts?.length ?? 0} contacts</span>

                {canInviteContacts && (
                    <Button
                        type="button"
                        size="small"
                        themeColor="secondary"
                        fillMode="flat"
                        disabled={disabled || invitingContacts}
                        onClick={() => setInvitingContacts(true)}
                        className="k-ml-auto"
                    >
                        <SvgIconButtonContent icon={PlusIcon}>Invite more contacts</SvgIconButtonContent>
                    </Button>
                )}
            </StackLayout>

            {invitingContacts && (
                <InviteContactForm
                    ideaId={ideaId}
                    researchId={researchId}
                    customerSegmentId={customerSegmentId}
                    onCancelSelection={() => setInvitingContacts(undefined)}
                    onSelected={inviteCallbackCreator(async c => {
                        await onInviteContactSelected?.(c);
                        setInvitingContacts(undefined);
                    })}
                    disabled={disabled || inviteDisabled}
                />
            )}

            {contacts?.length
                ? contacts.map(contactInPhase => {
                      const contactId = contactInPhase.contact.id;
                      const isExpanded = contactId === expandedContactId;
                      let isUpcomingPhaseStage = false;
                      const relatedUser =
                          contactInPhase.interview?.user ??
                          contactInPhase.meetingActivity?.meeting.user ??
                          contactInPhase.reachOutActivity?.researchReachOut.user;
                      const relatedSchedule = relatedUser ? researchSchedules?.find(s => s.user.userId === relatedUser.userId) : undefined;
                      const hasNotStartedInterview = !!contactInPhase.interview && contactInPhase.interview.stage === InterviewStage.NotStarted;
                      const hasInterviewToday =
                          hasNotStartedInterview &&
                          !!contactInPhase.meetingActivity &&
                          dateTimeService.isToday(contactInPhase.meetingActivity.meeting.startTime);
                      const hasOverdueInterview =
                          hasNotStartedInterview && !!contactInPhase.meetingActivity && new Date() > contactInPhase.meetingActivity.meeting.startTime;

                      const headerAdditionalActions = resolveResearchContactAdditionalActions(
                          contactInPhase,
                          disabled,
                          onManageActivity,
                          onDeleteActivity,
                          onOpenInterview
                      );

                      return (
                          <ResearchContactTogglePanel
                              key={contactId}
                              researchContact={contactInPhase}
                              isExpanded={contactId === expandedContactId}
                              onToggle={onExpandContact && (() => (isExpanded ? onExpandContact() : onExpandContact(contactId)))}
                              disabled={disabled}
                              onDelete={onDelete && (() => onDelete(contactInPhase))}
                              headerAdditionalContent={
                                  relatedUser && <ResearchContactRelatedUser ideaId={ideaId} user={relatedUser} scheduleId={relatedSchedule?.id} />
                              }
                              stagePresentation={
                                  contactInPhase.stage === ResearchContactStage.NotInterviewed && hasOverdueInterview
                                      ? overdueInterviewPresentation
                                      : hasInterviewToday
                                      ? todayInterviewPresentation
                                      : undefined
                              }
                              highlighted={contactInPhase.stage === ResearchContactStage.NotInterviewed && hasInterviewToday && !hasOverdueInterview}
                              additionalActions={headerAdditionalActions}
                          >
                              <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4 k-icp-component-border">
                                  {phase.stages.map((phaseStage, phaseStageIndex) => {
                                      const isCurrentStage = phaseStage.stages.includes(contactInPhase.stage);
                                      if (isCurrentStage && !isUpcomingPhaseStage) isUpcomingPhaseStage = true;
                                      const phaseStageType = isCurrentStage
                                          ? ResearchContactPhaseStageType.Current
                                          : isUpcomingPhaseStage
                                          ? ResearchContactPhaseStageType.Upcoming
                                          : ResearchContactPhaseStageType.Passed;
                                      const isLastPhase = phaseStageIndex === phase.stages.length - 1;

                                      return (
                                          <Fragment key={phaseStageIndex}>
                                              {phaseStageIndex !== 0 && <div className="k-separator" />}
                                              <ResearchContactStagePanel stage={phaseStage} stageType={phaseStageType} hideArrow={isFinal && isLastPhase}>
                                                  <ResearchContactPhaseStageActions
                                                      ideaId={ideaId}
                                                      researchId={researchId}
                                                      researchStages={phaseStage.stages}
                                                      researchContact={contactInPhase}
                                                      disabled={disabled}
                                                      phaseStageType={phaseStageType}
                                                      onManageActivity={
                                                          onManageActivity &&
                                                          ((activityType, props) => onManageActivity(contactInPhase.contact.id, activityType, props))
                                                      }
                                                  />
                                              </ResearchContactStagePanel>
                                          </Fragment>
                                      );
                                  })}
                              </StackLayout>
                          </ResearchContactTogglePanel>
                      );
                  })
                : !invitingContacts && <EmptyResearchContactsPhaseView text={phase.emptyText} />}
        </StackLayout>
    );
}
const overdueInterviewPresentation: ResearchContactStagePresentation = {
    label: 'Not started, Overdue',
    className: 'k-icp-bg-error-4 k-text-error'
};
const todayInterviewPresentation: ResearchContactStagePresentation = {
    label: 'Not started, Meeting today',
    className: 'k-icp-bg-error-4'
};

function resolveResearchContactAdditionalActions(
    researchContact: ResearchContact,
    disabled?: boolean,
    onManageActivity?: <TActivity extends ActivityType>(contactId: number, activityType: TActivity, props?: ShowActivityDialogProps<TActivity>) => void,
    onDeleteActivity?: (contactId: number, activity: Activity) => void,
    onOpenInterview?: (interviewId: number, view: InterviewStage) => void
): DropDownButtonItem[] | undefined {
    const additionalActions: DropDownButtonItem[] = [];
    if (
        (researchContact.stage === ResearchContactStage.InvitePending || researchContact.stage === ResearchContactStage.Invited) &&
        researchContact.reachOutActivity
    )
        additionalActions.push({
            text: 'Delete invite',
            disabled: disabled,
            separated: true,
            action: onDeleteActivity && (() => onDeleteActivity(researchContact.contact.id, researchContact.reachOutActivity!))
        });

    if (researchContact.stage === ResearchContactStage.NotInterviewed && researchContact.meetingActivity) {
        const meetingActivity = researchContact.meetingActivity;
        additionalActions.push({
            text: 'Reschedule meeting',
            disabled: disabled,
            separated: true,
            action:
                onManageActivity &&
                (() => {
                    onManageActivity(researchContact.contact.id, ActivityType.Meeting, {
                        itemKey: meetingActivity.meeting.id,
                        person: researchContact.contact
                    });
                })
        });

        additionalActions.push({
            text: 'Cancel meeting',
            disabled: disabled,
            action:
                onDeleteActivity &&
                (() =>
                    onDeleteActivity(
                        researchContact.contact.id,
                        researchContact.interview && researchContact.interview.meetingId === meetingActivity.meeting.id
                            ? { ...meetingActivity, meeting: { ...meetingActivity.meeting, interview: researchContact.interview } }
                            : meetingActivity
                    ))
        });
    }

    if (
        (researchContact.stage === ResearchContactStage.Reviewed ||
            researchContact.stage === ResearchContactStage.InsightsCaptured ||
            researchContact.stage === ResearchContactStage.Complete) &&
        researchContact.interview
    )
        additionalActions.push({
            text: 'Open Interview page',
            separated: true,
            action: onOpenInterview && (() => onOpenInterview(researchContact.interview!.id, InterviewStage.PendingReview))
        });

    return additionalActions.length ? additionalActions : undefined;
}

function InviteContactForm({
    ideaId,
    researchId,
    customerSegmentId,
    disabled,
    onSelected,
    onCancelSelection
}: {
    ideaId: string;
    researchId: number;
    customerSegmentId?: number;
    disabled?: boolean;
    onSelected?: (contact: Person) => void;
    onCancelSelection?: () => void;
}) {
    return (
        <div className="k-icp-panel !k-rounded k-p-4">
            <div className="k-mb-2">Search for a contact or create a new one</div>
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                <ResearchContactPicker
                    ideaId={ideaId}
                    researchId={researchId}
                    customerSegmentId={customerSegmentId}
                    disabled={disabled}
                    onSelected={onSelected}
                    className="k-flex-1"
                    autoFocus
                />
                <Button type="button" fillMode="flat" onClick={onCancelSelection} className="k-icp-svg-icon-button">
                    <CloseIcon className="k-icp-icon" />
                </Button>
            </StackLayout>
        </div>
    );
}

function ResearchContactStagePanel({
    stage,
    stageType,
    children,
    hideArrow
}: {
    stage: ResearchContactStageData;
    stageType: ResearchContactPhaseStageType;
    children?: ReactNode;
    hideArrow?: boolean;
}) {
    const isCurrent = stageType === ResearchContactPhaseStageType.Current;
    const [isExpanded, setIsExpanded] = useState<boolean>(isCurrent);
    const [showArrow, setShowArrow] = useState(isExpanded);
    if (isExpanded && !showArrow) setShowArrow(true);

    const isCurrentOrPassed = stageType !== ResearchContactPhaseStageType.Upcoming;

    const isExpandedRef = useAsRef(isExpanded);
    useEffect(() => {
        if (isCurrent && !isExpandedRef.current) {
            setIsExpanded(true);
            return;
        }

        if (!isCurrent && isExpandedRef.current) {
            setIsExpanded(false);
            return;
        }
    }, [isCurrent, isExpandedRef]);

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'center', vertical: 'top' }}
                className={combineClassNames('k-align-self-stretch', isCurrentOrPassed ? 'k-text-secondary' : undefined)}
            >
                <stage.icon className={combineClassNames('k-icp-icon-size-6 k-m-1', stage.iconClassName)} />
                {!hideArrow && showArrow && <div className="k-flex-1 dashed-stretching-arrow-down" />}
            </StackLayout>
            <div style={{ flexBasis: 196 }}>
                <Button
                    type="button"
                    fillMode="flat"
                    themeColor={isCurrentOrPassed ? 'secondary' : undefined}
                    className={isCurrent ? undefined : '!k-font-normal'}
                    onClick={() => setIsExpanded(e => !e)}
                >
                    {stage.title}
                </Button>
            </div>
            <Reveal className="k-flex-1" onExited={() => setShowArrow(false)}>
                {isExpanded && <div className="-mt-1.5 k-mr-6">{children}</div>}
            </Reveal>
        </StackLayout>
    );
}

function EmptyResearchContactsPhaseView({ text }: { text?: string }) {
    return (
        <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-6 k-icp-panel !k-rounded k-p-4">
            <img src={emptyResearchContactsPhaseIllustrationUrl} width="48" height="48" alt="No contacts in phase" />
            <span style={{ flexBasis: 420 }}>{text || 'No contact in this phase'}</span>
        </StackLayout>
    );
}

const researchContactStageToInterviewStageMap: Partial<Record<ResearchContactStage, InterviewStage>> = {
    [ResearchContactStage.NotInterviewed]: InterviewStage.NotStarted,
    [ResearchContactStage.InterviewPending]: InterviewStage.Started,
    [ResearchContactStage.Interviewed]: InterviewStage.PendingReview,
    [ResearchContactStage.Reviewed]: InterviewStage.PendingInsightCapture,
    [ResearchContactStage.InsightsCaptured]: InterviewStage.PendingHypothesesEvaluation,
    [ResearchContactStage.Complete]: InterviewStage.Complete
};

const researchContactStageUnavailableActionsMessage: Partial<Record<ResearchContactStage, string>> = {
    [ResearchContactStage.Interviewed]: 'You can start reviewing the notes after you conduct the interview',
    [ResearchContactStage.InsightsCaptured]: 'You can start evaluating the hypotheses after you capture the insights from the interview',
    [ResearchContactStage.Invited]: 'You must invite the contact first'
};

function ResearchContactPhaseStageActions({
    ideaId,
    researchId,
    researchStages,
    researchContact,
    disabled,
    phaseStageType,
    onManageActivity
}: {
    ideaId: string;
    researchId: number;
    researchStages: ResearchContactStage[];
    researchContact: ResearchContact;
    phaseStageType: ResearchContactPhaseStageType;
    disabled?: boolean;
    onManageActivity?: <TActivity extends ActivityType>(activityType: TActivity, props?: ShowActivityDialogProps<TActivity>) => void;
}) {
    const isCurrent = phaseStageType === ResearchContactPhaseStageType.Current;
    const isUpcoming = phaseStageType === ResearchContactPhaseStageType.Upcoming;
    const preferredResearchStage = isCurrent ? researchContact.stage : researchStages[0];
    const interviewStage = researchContactStageToInterviewStageMap[preferredResearchStage];

    if (interviewStage) {
        return (
            <InterviewActions
                ideaId={ideaId}
                stage={(isCurrent ? researchContact.interview?.stage : undefined) ?? interviewStage}
                interview={researchContact.interview ?? undefined}
                meeting={researchContact.meetingActivity?.meeting}
                contact={researchContact.contact}
                disabled={disabled || !isCurrent}
                disabledText={isUpcoming ? researchContactStageUnavailableActionsMessage[preferredResearchStage] : undefined}
                onManageActivity={onManageActivity}
            />
        );
    }

    if (
        preferredResearchStage === ResearchContactStage.NotInvited ||
        preferredResearchStage === ResearchContactStage.InvitePending ||
        preferredResearchStage === ResearchContactStage.Invited
    ) {
        return (
            <ResearchReachOutActions
                ideaId={ideaId}
                researchId={researchId}
                stage={preferredResearchStage}
                contact={researchContact.contact}
                researchReachOut={researchContact.reachOutActivity?.researchReachOut}
                disabled={disabled || !isCurrent}
                disabledText={isUpcoming ? researchContactStageUnavailableActionsMessage[preferredResearchStage] : undefined}
                canScheduleInterview={!researchContact.interview}
                onManageActivity={onManageActivity}
            />
        );
    }

    throw new Error('Unsupported stages: ' + researchStages.join(' '));
}

function InterviewActions({
    ideaId,
    interview,
    stage,
    meeting,
    contact,
    disabledText,
    disabled,
    onManageActivity
}: {
    ideaId: string;
    interview?: Interview;
    stage: InterviewStage;
    meeting?: Meeting;
    contact: ReducedPerson;
    disabledText?: string;
    disabled?: boolean;
    onManageActivity?: <TActivity extends ActivityType>(activityType: TActivity, props?: ShowActivityDialogProps<TActivity>) => void;
}) {
    const isDisabled = disabled || !interview;
    const isInterviewOverdue = interview && interview.stage === InterviewStage.NotStarted && meeting && new Date() > meeting.startTime;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4 k-icp-component-border">
            {(stage === InterviewStage.NotStarted || stage === InterviewStage.Started) && meeting && (
                <>
                    <div>
                        Interview scheduled for{' '}
                        <span className={isInterviewOverdue ? 'k-text-error' : undefined}>{dateTimeService.stringifyToDateAndTime(meeting.startTime)}</span> -
                        see{' '}
                        <span
                            className="k-button-link-secondary k-cursor-pointer"
                            onClick={onManageActivity && (() => onManageActivity(ActivityType.Meeting, { itemKey: meeting.id, person: contact }))}
                        >
                            meeting activity
                        </span>
                        .
                    </div>
                    <div className="k-separator" />
                </>
            )}
            <InterviewStageGuidance stage={stage} />
            {isDisabled && disabledText && <DisabledActionsView text={disabledText} />}
            <InterviewMainActionButton ideaId={ideaId} interviewId={interview?.id} interviewStage={stage} disabled={isDisabled} />
        </StackLayout>
    );
}

function ResearchReachOutActions({
    researchId,
    stage,
    contact,
    researchReachOut,
    disabled,
    disabledText,
    canScheduleInterview,
    onManageActivity
}: {
    ideaId: string;
    researchId: number;
    stage: ResearchContactStage.NotInvited | ResearchContactStage.InvitePending | ResearchContactStage.Invited;
    contact: ReducedPerson;
    researchReachOut?: ResearchReachOut;
    disabled?: boolean;
    disabledText?: string;
    canScheduleInterview?: boolean;
    onManageActivity?: <TActivity extends ActivityType>(activityType: TActivity, props?: ShowActivityDialogProps<TActivity>) => void;
}) {
    let actionsDescription: ReactElement | undefined;
    if (stage === ResearchContactStage.NotInvited) actionsDescription = <span>Invite this contact by picking the best way to reach out to him/her.</span>;
    else if (stage === ResearchContactStage.InvitePending) {
        if (researchReachOut && !researchReachOut.sent)
            actionsDescription = (
                <span>
                    A reminder to invite this contact on{' '}
                    <span className={researchReachOut.date < new Date() ? 'k-text-error' : undefined}>
                        {dateTimeService.stringifyToDay(researchReachOut.date)}
                    </span>{' '}
                    has been created - see{' '}
                    <span
                        className="k-button-link-secondary k-cursor-pointer"
                        onClick={
                            onManageActivity &&
                            (() =>
                                onManageActivity(ActivityType.ResearchReachOut, {
                                    itemKey: { researchId, reachOutId: researchReachOut.id },
                                    person: researchReachOut.contact
                                }))
                        }
                    >
                        reach out activity
                    </span>
                    .
                </span>
            );
    } else if (stage === ResearchContactStage.Invited) {
        actionsDescription = (
            <span>
                {researchReachOut && researchReachOut.sent && (
                    <>
                        This contact has been invited - see{' '}
                        <span
                            className="k-button-link-secondary k-cursor-pointer"
                            onClick={
                                onManageActivity &&
                                (() =>
                                    onManageActivity(ActivityType.ResearchReachOut, {
                                        itemKey: { researchId, reachOutId: researchReachOut.id },
                                        person: researchReachOut.contact
                                    }))
                            }
                        >
                            reach out activity
                        </span>
                        .{' '}
                    </>
                )}
                Wait for the contact to accept your invitation or reach out to him again.
            </span>
        );
    }

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
            {actionsDescription}
            {disabled && disabledText && <DisabledActionsView text={disabledText} />}
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                <Button
                    type="button"
                    fillMode="outline"
                    themeColor="secondary"
                    onClick={
                        disabled || !onManageActivity
                            ? undefined
                            : () => {
                                  if (researchReachOut && !researchReachOut.sent)
                                      onManageActivity(ActivityType.ResearchReachOut, {
                                          itemKey: { researchId, reachOutId: researchReachOut.id },
                                          person: researchReachOut.contact,
                                          executingNowInitialValue: true
                                      });
                                  else onManageActivity(ActivityType.ResearchReachOut, { person: contact, initialData: { researchId: researchId } });
                              }
                    }
                    disabled={disabled}
                >
                    {stage === ResearchContactStage.Invited ? 'Invite again' : 'Invite now'}
                </Button>
                {canScheduleInterview && (
                    <>
                        <span>or</span>
                        <Button
                            type="button"
                            fillMode="outline"
                            onClick={onManageActivity && (() => onManageActivity(ActivityType.Meeting, { person: contact, initialResearchId: researchId }))}
                            disabled={disabled}
                        >
                            Schedule interview
                        </Button>
                    </>
                )}
            </StackLayout>
        </StackLayout>
    );
}

function DisabledActionsView({ text }: { text: string }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            <span className="k-icon k-i-warning k-mt-hair" />
            <span className="k-fs-sm">{text}</span>
        </StackLayout>
    );
}

type ResearchContactStageData = {
    stages: ResearchContactStage[];
    title: string;
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    iconClassName?: string;
};

type ResearchContactPhaseData = {
    title: string;
    emptyText: string;
    stages: ResearchContactStageData[];
};

const researchContactPhases: ResearchContactPhaseData[] = [
    {
        title: 'Invitation phase',
        stages: [
            {
                stages: [ResearchContactStage.NotInvited, ResearchContactStage.InvitePending],
                title: 'Inviting contact',
                icon: InviteContactIcon
            },
            {
                stages: [ResearchContactStage.Invited],
                title: 'Awaiting booking',
                icon: PendingBookingIcon,
                iconClassName: 'k-icp-icon'
            }
        ],
        emptyText: 'Contacts who do not have a scheduled interview yet are listed here.'
    },
    {
        title: 'Interviewing phase',
        stages: [
            {
                stages: [ResearchContactStage.NotInterviewed, ResearchContactStage.InterviewPending],
                title: 'Conducting interview',
                icon: ExecuteInterviewIcon,
                iconClassName: 'interview-execution-icon-25%'
            },
            {
                stages: [ResearchContactStage.Interviewed],
                title: 'Reviewing notes',
                icon: ExecuteInterviewIcon,
                iconClassName: 'interview-execution-icon-50%'
            }
        ],
        emptyText: 'Contacts will be moved here when they have a scheduled interview.'
    },
    {
        title: 'Evaluating phase',
        stages: [
            {
                stages: [ResearchContactStage.Reviewed],
                title: 'Capturing Insights',
                icon: ExecuteInterviewIcon,
                iconClassName: 'interview-execution-icon-75%'
            },
            {
                stages: [ResearchContactStage.InsightsCaptured],
                title: 'Evaluating Hypotheses',
                icon: ExecuteInterviewIcon,
                iconClassName: 'interview-execution-icon-100%'
            }
        ],
        emptyText: 'Contacts are put in the evaluating phase when they require additional attention after their interview is conducted.'
    },
    {
        title: 'Completed phase',
        stages: [
            {
                stages: [ResearchContactStage.Complete],
                title: 'Completed',
                icon: CompletedInterviewIcon
            }
        ],
        emptyText: 'Contacts will be moved here when all follow-up work derived from their interview is complete.'
    }
];

const researchContactStageToPhaseIndexMap = researchContactPhases.reduce<Record<ResearchContactStage, number>>(
    (researchContactStageToPhaseIndexMap, phase, phaseIndex) => {
        for (const phaseStage of phase.stages) {
            for (const researchContactStage of phaseStage.stages) {
                researchContactStageToPhaseIndexMap[researchContactStage] = phaseIndex;
            }
        }

        return researchContactStageToPhaseIndexMap;
    },
    {} as any
);
