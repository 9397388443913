import { FocusEventHandler, KeyboardEventHandler, forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useRef } from 'react';

function inValidPlainTextElementSelectionRange(element: HTMLElement, selectionRange: Range) {
    // No child nodes or The only child node should be text node and the selection range is within that node
    if (!element.childNodes.length) return true;
    if (element.childNodes.length === 1) {
        const firstChildNode = element.childNodes[0];
        if (firstChildNode.nodeType !== Node.TEXT_NODE) return false;
        if (selectionRange.startContainer !== selectionRange.endContainer) return false;
        if (selectionRange.startContainer !== firstChildNode && selectionRange.startContainer !== element) return false;

        return true;
    }

    return false;
}

function insertContentToPlainTextElementAtSelectionRange(element: HTMLElement, selectionRange: Range, content: string) {
    if (!inValidPlainTextElementSelectionRange(element, selectionRange)) return;

    if (!content) return;

    const currentContent = element.innerHTML;
    const selectionStart = selectionRange.startOffset;
    const selectionEnd = selectionRange.endOffset;
    if (selectionStart > currentContent.length || selectionEnd > currentContent.length) return;

    const normalizedContent = content.replaceAll('\r\n', '\n');
    element.innerHTML = currentContent.slice(0, selectionStart) + normalizedContent + currentContent.slice(selectionEnd);
    const newCursorPosition = selectionEnd + normalizedContent.length - (selectionEnd - selectionStart);
    selectionRange.setStart(element.childNodes[0], newCursorPosition);
    selectionRange.collapse();
}

function getCurrentSelectionRange() {
    const currentSelection = window.getSelection();
    if (!currentSelection || currentSelection.rangeCount !== 1) {
        return;
    }

    return currentSelection.getRangeAt(0);
}

function insertContentToPlainTextElementAtCaretPosition(element: HTMLElement, content: string) {
    const currentSelectionRange = getCurrentSelectionRange();
    if (!currentSelectionRange) return;

    insertContentToPlainTextElementAtSelectionRange(element, currentSelectionRange, content);
}

function moveCursorToTheEndOfPlainTextElementIfFocused(element: HTMLElement) {
    const currentSelectionRange = getCurrentSelectionRange();
    if (!currentSelectionRange) return;
    if (document.activeElement !== element || !inValidPlainTextElementSelectionRange(element, currentSelectionRange)) return;
    currentSelectionRange.selectNodeContents(element);
    currentSelectionRange.collapse(false);
}

function insertContentToPlainTextElementAtCoordinates(element: HTMLElement, clientX: number, clientY: number, content: string) {
    const caretPosition = getCaretPositionFromPoint(clientX, clientY);
    if (!caretPosition) return;

    const currentSelection = window.getSelection();
    if (!currentSelection) return;

    currentSelection.removeAllRanges();
    const newRange = new Range();
    newRange.setStart(caretPosition.offsetNode, caretPosition.offset);
    newRange.collapse();
    currentSelection.addRange(newRange);

    insertContentToPlainTextElementAtSelectionRange(element, newRange, content);
}

type CaretPositionShim = { offsetNode: Node; offset: number };
function getCaretPositionFromPoint(clientX: number, clientY: number): CaretPositionShim | undefined {
    if ((document as any).caretPositionFromPoint) {
        return (document as any).caretPositionFromPoint(clientX, clientY);
    }

    if (document.caretRangeFromPoint) {
        const range = document.caretRangeFromPoint(clientX, clientY);
        return range ? { offsetNode: range.startContainer, offset: range.startOffset } : undefined;
    }

    return undefined;
}

export type PlaintTextContentEditableHandle = { value: string; focus: () => void; blur: () => void };
type PlaceholderSizeData = { placeholder: string; width: number; height: number; widthData: number[] };
export const PlaintTextContentEditable = forwardRef(
    (
        {
            onChange,
            className,
            initialValue,
            onKeyDown,
            onFocus,
            onBlur,
            placeholder
        }: {
            onChange?: (e: { value: string }) => void;
            className?: string;
            initialValue?: string;
            onKeyDown?: KeyboardEventHandler<HTMLElement>;
            onFocus?: FocusEventHandler<HTMLElement>;
            onBlur?: FocusEventHandler<HTMLElement>;
            placeholder?: string;
        },
        ref: React.ForwardedRef<PlaintTextContentEditableHandle>
    ) => {
        const editorRef = useRef<HTMLSpanElement>(null);
        const placeholderRef = useRef<HTMLSpanElement>(null);
        const initialValueRef = useRef(initialValue);
        const placeholderSizeCompensationRef = useRef<HTMLSpanElement>(null);
        const placeholderSizeDataRef = useRef<PlaceholderSizeData>();

        useLayoutEffect(() => {
            if (!editorRef.current || !initialValueRef.current) return;

            editorRef.current.innerText = initialValueRef.current;
            moveCursorToTheEndOfPlainTextElementIfFocused(editorRef.current);
        }, []);

        const updatePlaceholder = useCallback(() => {
            const editorElement = editorRef.current;
            const placeholderCompensationElement = placeholderSizeCompensationRef.current;
            if (!editorElement || !placeholderCompensationElement) return;

            const placeholderSizeData = placeholderSizeDataRef.current;
            if (!placeholderSizeData) {
                placeholderCompensationElement.style.display = 'none';
                return;
            }

            editorElement.style.position = 'fixed';
            editorElement.style.top = '0';
            editorElement.style.left = '0';
            const editorCurrentSize = editorElement.getBoundingClientRect();
            const compensationWidth = Math.max(placeholderSizeData.width - editorCurrentSize.width, 0);
            if (!compensationWidth) {
                placeholderCompensationElement.innerText = '';
                placeholderCompensationElement.style.paddingLeft = '0px';
            } else {
                let placeholderIndex = 0;
                while (placeholderIndex < placeholderSizeData.widthData.length && placeholderSizeData.widthData[placeholderIndex] > compensationWidth)
                    ++placeholderIndex;
                if (placeholderIndex === placeholderSizeData.widthData.length) {
                    placeholderCompensationElement.innerText = '';
                    placeholderCompensationElement.style.paddingLeft = '0px';
                } else {
                    placeholderCompensationElement.innerText = placeholderSizeData.placeholder.substring(placeholderIndex);
                    placeholderCompensationElement.style.paddingLeft = compensationWidth - placeholderSizeData.widthData[placeholderIndex] + 'px';
                }
            }
            editorElement.style.position = '';
            editorElement.style.top = '';
            editorElement.style.left = '';

            const placeholderElement = placeholderRef.current;
            if (!placeholderElement) return;

            const showPlaceholder = !editorElement.innerHTML;
            if (showPlaceholder) {
                placeholderCompensationElement.style.display = 'none';
                placeholderElement.style.display = '';
            } else {
                if (compensationWidth && editorCurrentSize.height <= placeholderSizeData.height) placeholderCompensationElement.style.display = '';
                else placeholderCompensationElement.style.display = 'none';
                placeholderElement.style.display = 'none';
            }
        }, []);

        useImperativeHandle(
            ref,
            () => ({
                get value(): string {
                    return editorRef.current?.innerText ?? initialValueRef.current ?? '';
                },
                set value(value: string) {
                    if (!editorRef.current) return;
                    editorRef.current.innerText = value;
                    moveCursorToTheEndOfPlainTextElementIfFocused(editorRef.current);
                    updatePlaceholder();
                },
                focus() {
                    if (!editorRef.current) return;
                    editorRef.current.focus();
                    moveCursorToTheEndOfPlainTextElementIfFocused(editorRef.current);
                },
                blur() {
                    editorRef.current?.blur();
                }
            }),
            [updatePlaceholder]
        );

        useLayoutEffect(() => {
            if (!placeholderRef.current) return;
            if (!placeholder) {
                placeholderSizeDataRef.current = undefined;
                return;
            }

            const isHidden = placeholderRef.current.style.display === 'none';
            if (isHidden) placeholderRef.current.style.display = '';
            placeholderRef.current.style.position = 'fixed';
            placeholderRef.current.style.top = '0';
            placeholderRef.current.style.left = '0';

            let placeholderBoundingRect = placeholderRef.current.getBoundingClientRect();
            const placeholderSizeData: PlaceholderSizeData = {
                width: placeholderBoundingRect.width,
                height: placeholderBoundingRect.height,
                placeholder: placeholder,
                widthData: []
            };
            for (let placeholderIndex = 0; placeholderIndex <= placeholder.length; placeholderIndex++) {
                placeholderRef.current.innerText = placeholder.substring(placeholderIndex);
                placeholderBoundingRect = placeholderRef.current.getBoundingClientRect();
                placeholderSizeData.widthData.push(placeholderBoundingRect.width);
            }

            placeholderRef.current.innerText = placeholder;
            placeholderRef.current.style.position = '';
            placeholderRef.current.style.top = '';
            placeholderRef.current.style.left = '';
            if (isHidden) placeholderRef.current.style.display = 'none';

            placeholderSizeDataRef.current = placeholderSizeData;

            updatePlaceholder();
        }, [placeholder, updatePlaceholder]);

        function triggerOnChange() {
            updatePlaceholder();
            if (!editorRef.current) return;
            onChange?.({ value: editorRef.current.innerText });
        }

        return (
            <span className={className}>
                <span
                    ref={editorRef}
                    contentEditable
                    onInput={triggerOnChange}
                    className="k-icp-plain-text-editor-content"
                    onKeyDown={e => {
                        onKeyDown?.(e);
                        if (e.defaultPrevented) return;

                        const preventCommand =
                            (e.ctrlKey || e.metaKey) &&
                            (e.keyCode === 66 ||
                            e.keyCode === 98 || // [CTRL|CMD + B|b] - toggle bold
                            e.keyCode === 73 ||
                            e.keyCode === 105 || // [CTRL|CMD + I|i] - toggle italic
                                e.keyCode === 85 ||
                                e.keyCode === 117); // [CTRL|CMD + U|u] - toggle underlined
                        if (preventCommand) {
                            e.preventDefault();
                            return;
                        }

                        if (e.key === 'Enter') {
                            e.preventDefault();
                            insertContentToPlainTextElementAtCaretPosition(e.currentTarget, '\n');
                            triggerOnChange();
                            return;
                        }
                    }}
                    onPaste={e => {
                        e.preventDefault();
                        const contentEditableElement = e.currentTarget;
                        const plainTextData = e.clipboardData.getData('text/plain');
                        insertContentToPlainTextElementAtCaretPosition(contentEditableElement, plainTextData);
                        triggerOnChange();
                    }}
                    onDrop={e => {
                        e.preventDefault();
                        const contentEditableElement = e.currentTarget;
                        const plainTextData = e.dataTransfer.getData('text/plain');
                        insertContentToPlainTextElementAtCoordinates(contentEditableElement, e.clientX, e.clientY, plainTextData);
                        triggerOnChange();
                    }}
                    onBlur={e => {
                        onBlur?.(e);
                        updatePlaceholder();
                    }}
                    onFocus={e => {
                        onFocus?.(e);
                        updatePlaceholder();
                    }}
                />
                {placeholder && (
                    <>
                        <span ref={placeholderSizeCompensationRef} className="k-visibility-invisible k-white-space-pre-wrap" />
                        <span
                            ref={placeholderRef}
                            className="k-icp-subtle-text k-icp-ghost k-white-space-pre-wrap"
                            onMouseDown={e => {
                                e.preventDefault();
                                editorRef.current?.focus();
                            }}
                        >
                            {placeholder}
                        </span>
                    </>
                )}
            </span>
        );
    }
);
