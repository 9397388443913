import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import { RemoteDataComboBox } from './remoteDataComboBox';

export function PickersList<
    TKeyField extends keyof TData & string,
    TTextField extends keyof TData & string,
    TData extends { [Property in TTextField]: string } & { [Property in TKeyField]: PropertyKey }
>({
    dataItemKey,
    textField,
    fetchItems,
    placeholder,
    value,
    onChange,
    emptyListText,
    renderItem,
    minFilterLength,
    insertItemText,
    onInsertItem,
    disabled,
    valid
}: {
    dataItemKey: TKeyField;
    textField: TTextField;
    fetchItems: (filter: string) => Promise<TData[]>;
    placeholder?: string;
    value?: TData[];
    onChange?: (e: { value: TData[] | undefined }) => void;
    emptyListText?: string;
    renderItem?: (item: TData) => ReactNode;
    minFilterLength?: number;
    insertItemText?: string;
    onInsertItem?: (text?: string) => void;
    disabled?: boolean;
    valid?: boolean;
}) {
    function onItemValueChange(itemIndex: number | undefined, itemValue: TData | undefined | null) {
        if (itemIndex === undefined) {
            if (itemValue) {
                onChange?.({ value: value ? [...value, itemValue] : [itemValue] });
            }
        } else {
            if (itemValue) {
                const newValue = value ? [...value] : [];
                if (itemIndex < newValue.length) newValue[itemIndex] = itemValue;
                else newValue.push(itemValue);
                onChange?.({ value: newValue });
            } else if (value) {
                const newValue = value.filter((_, index) => index !== itemIndex);
                onChange?.({ value: newValue.length ? newValue : undefined });
            }
        }
    }

    async function fetchItemsWithSelectedItemsFiltration(filter: string, itemIndex: number | undefined) {
        const fetchedData = await fetchItems(filter);
        if (value) {
            const itemIdToKeep = itemIndex !== undefined && value ? value[itemIndex]?.[dataItemKey] : undefined;
            return fetchedData.filter(
                i => !value.some(v => v[dataItemKey] === i[dataItemKey]) || (itemIdToKeep !== undefined && i[dataItemKey] === itemIdToKeep)
            );
        }

        return fetchedData;
    }

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {value &&
                value.map((selectedItem, selectedItemIndex: number) => (
                    <RemoteDataComboBox
                        key={selectedItemIndex}
                        dataItemKey={dataItemKey}
                        textField={textField}
                        fetchItems={f => fetchItemsWithSelectedItemsFiltration(f, selectedItemIndex)}
                        placeholder={placeholder}
                        value={selectedItem}
                        onChange={e => onItemValueChange(selectedItemIndex, e.value)}
                        emptyListText={emptyListText}
                        renderItem={renderItem}
                        minFilterLength={minFilterLength}
                        disabled={disabled}
                    />
                ))}
            {!disabled && (
                <RemoteDataComboBox
                    key={`newItemPicker${value?.length ?? 0}`}
                    dataItemKey={dataItemKey}
                    textField={textField}
                    fetchItems={f => fetchItemsWithSelectedItemsFiltration(f, undefined)}
                    placeholder={placeholder}
                    onChange={e => onItemValueChange(undefined, e.value)}
                    emptyListText={emptyListText}
                    renderItem={renderItem}
                    minFilterLength={minFilterLength}
                    insertItemText={insertItemText}
                    onInsertItem={onInsertItem}
                    valid={valid}
                />
            )}
        </StackLayout>
    );
}
