import { ErrorWithOperationDisplayName } from './common';
import { dateTimeService } from './dateTimeService';
import { EventDetailsBase, Meeting, MeetingData, Note, NoteData, ReachOut, ReachOutData, ResearchReachOut, eventDateFieldsParser } from './events';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { researchService } from './researchService';

export enum ActivityType {
    Note = 'note',
    ReachOut = 'reachOut',
    Meeting = 'meeting',
    ResearchReachOut = 'researchReachOut'
}

export type ActivityTypeDataMap = {
    [ActivityType.Note]: { propertyName: 'note'; type: Note };
    [ActivityType.ReachOut]: { propertyName: 'reachOut'; type: ReachOut };
    [ActivityType.Meeting]: { propertyName: 'meeting'; type: Meeting };
    [ActivityType.ResearchReachOut]: { propertyName: 'researchReachOut'; type: ResearchReachOut };
};

export type ActivityOf<T extends ActivityType> = { id: number; type: T } & {
    [P in ActivityTypeDataMap[T]['propertyName']]: ActivityTypeDataMap[T]['type'];
};
export type Activity = {
    [P in ActivityType]: ActivityOf<P>;
}[ActivityType];

export class GenericActivity {
    readonly details: EventDetailsBase;
    readonly date: Date;

    constructor(readonly data: Activity) {
        const activityType = data.type;
        switch (activityType) {
            case ActivityType.Note:
                this.details = data.note;
                eventDateFieldsParser[activityType](data.note);
                this.date = data.note.date;
                break;
            case ActivityType.ReachOut:
                this.details = data.reachOut;
                eventDateFieldsParser[activityType](data.reachOut);
                this.date = data.reachOut.date;
                break;
            case ActivityType.Meeting:
                this.details = data.meeting;
                eventDateFieldsParser[activityType](data.meeting);
                this.date = data.meeting.startTime;
                break;
            case ActivityType.ResearchReachOut:
                this.details = data.researchReachOut;
                eventDateFieldsParser[activityType](data.researchReachOut);
                this.date = data.researchReachOut.date;
                break;
        }
    }
}

class ActivitiesService extends HttpServiceBase {
    constructor() {
        super('/api/contacts');
    }

    private getActivityServiceForType(activityType: ActivityType) {
        switch (activityType) {
            case ActivityType.Note:
                return notesService;
            case ActivityType.ReachOut:
                return reachOutsService;
        }

        throw new Error(`Unsupported activity type: ${activityType}`);
    }

    @ErrorWithOperationDisplayName('Get contact activities')
    getPersonActivities(ideaId: string, personId: number, type?: ActivityType): Promise<GenericActivity[]> {
        return this.performRequest<{ activities: Activity[] }>({
            path: `/${ideaId}/people/${personId}/activities`,
            queryParams: type ? { type } : undefined
        }).then(response => response.activities.map(a => new GenericActivity(a)));
    }

    deleteActivity(ideaId: string, hostEntityId: number, activityType: ActivityType, activityId: number) {
        if (activityType === ActivityType.Meeting) return meetingsService.deleteMeeting(ideaId, activityId);
        if (activityType === ActivityType.ResearchReachOut) return researchService.deleteProblemValidationResearchReachOut(ideaId, hostEntityId, activityId);

        return this.getActivityServiceForType(activityType).deleteActivity(ideaId, hostEntityId, activityId);
    }

    getUnderlyingItem(activity: Activity) {
        switch (activity.type) {
            case ActivityType.Note:
                return activity.note;
            case ActivityType.ReachOut:
                return activity.reachOut;
            case ActivityType.Meeting:
                return activity.meeting;
            case ActivityType.ResearchReachOut:
                return activity.researchReachOut;
        }
    }
}

export abstract class ActivityServiceBase<TItem extends EventDetailsBase, TData> extends HttpServiceBase {
    constructor(protected readonly itemSegmentPath: string) {
        super('/api/contacts');
        this.ensureDateFieldType = this.ensureDateFieldType.bind(this);
    }

    protected ensureDateFieldType<TResult extends TItem | TItem[]>(data: TResult): TResult {
        if (data instanceof Array) data.forEach(this.ensureDateFieldType);
        else this.ensureItemDateFiledType(data as TItem);

        return data;
    }

    protected abstract ensureItemDateFiledType(item: TItem): TItem;

    protected abstract normalizeDates(item: TData): void;

    @ErrorWithOperationDisplayName('Create activity')
    createActivity(ideaId: string, personId: number, data: TData) {
        this.normalizeDates(data);
        return this.performRequest<TItem>({
            path: `/${ideaId}/people/${personId}/${this.itemSegmentPath}`,
            method: RequestMethod.POST,
            body: data
        }).then(this.ensureDateFieldType);
    }

    @ErrorWithOperationDisplayName('Update activity')
    updateActivity(ideaId: string, personId: number, itemId: number, data: TData) {
        this.normalizeDates(data);
        return this.performRequest<TItem>({
            path: `/${ideaId}/people/${personId}/${this.itemSegmentPath}/${itemId}`,
            method: RequestMethod.PUT,
            body: data
        }).then(this.ensureDateFieldType);
    }

    @ErrorWithOperationDisplayName('Get activity')
    getActivity(ideaId: string, personId: number, itemId: number) {
        return this.performRequest<TItem>({
            path: `/${ideaId}/people/${personId}/${this.itemSegmentPath}/${itemId}`
        }).then(this.ensureDateFieldType);
    }

    @ErrorWithOperationDisplayName('Delete activity')
    deleteActivity(ideaId: string, personId: number, itemId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/people/${personId}/${this.itemSegmentPath}/${itemId}`,
            method: RequestMethod.DELETE
        });
    }
}

export const activitiesService = new ActivitiesService();

export type MeetingDataForResearch = {
    title: string;
    description: string;
    durationMinutes: number;
};
class MeetingsService extends HttpServiceBase {
    constructor() {
        super('/api/scheduling');
    }

    private ensureDateFieldType(item: Meeting): Meeting {
        eventDateFieldsParser[ActivityType.Meeting](item);

        return item;
    }

    private normalizeDates(startTime: Date, endTime: Date, isAllDay?: boolean) {
        startTime.setSeconds(0, 0);
        if (isAllDay) endTime.setSeconds(59, 999);
        else endTime.setSeconds(0, 0);
    }

    private adjustAllDayDates(startTime: Date, endTime: Date, isAllDay?: boolean): Pick<MeetingData, 'startTime' | 'endTime'> {
        return isAllDay
            ? {
                  startTime: new Date(Date.UTC(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), 0, 0, 0, 0)),
                  endTime: new Date(Date.UTC(endTime.getFullYear(), endTime.getMonth(), endTime.getDate(), 23, 59, 59, 999))
              }
            : { startTime, endTime };
    }

    @ErrorWithOperationDisplayName('Create meeting')
    createMeeting(ideaId: string, data: MeetingData, researchId?: number) {
        this.normalizeDates(data.startTime, data.endTime, data.allDay);

        const creatingMeetingForResearch = typeof researchId === 'number';

        return this.performRequest<Meeting>({
            ignoreBaseUrl: creatingMeetingForResearch,
            path: creatingMeetingForResearch ? `/api/research/${ideaId}/problem-validation/${researchId}/meetings` : `/${ideaId}/meetings`,
            method: RequestMethod.POST,
            body: { ...data, ...this.adjustAllDayDates(data.startTime, data.endTime, data.allDay), timeZone: dateTimeService.getCurrentTimeZone() }
        }).then(this.ensureDateFieldType);
    }

    @ErrorWithOperationDisplayName('Update meeting')
    updateMeeting(ideaId: string, meetingId: number, data: MeetingData) {
        this.normalizeDates(data.startTime, data.endTime, data.allDay);

        return this.performRequest<Meeting>({
            path: `/${ideaId}/meetings/${meetingId}`,
            method: RequestMethod.PUT,
            body: { ...data, ...this.adjustAllDayDates(data.startTime, data.endTime, data.allDay), timeZone: dateTimeService.getCurrentTimeZone() }
        }).then(this.ensureDateFieldType);
    }

    @ErrorWithOperationDisplayName('Get meeting')
    getMeeting(ideaId: string, meetingId: number) {
        return this.performRequest<Meeting>({
            path: `/${ideaId}/meetings/${meetingId}`
        }).then(this.ensureDateFieldType);
    }

    @ErrorWithOperationDisplayName('Delete meeting')
    deleteMeeting(ideaId: string, meetingId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/meetings/${meetingId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Reschedule meeting')
    rescheduleMeeting(ideaId: string, meetingId: number, start: Date, end: Date, isAllDay?: boolean) {
        this.normalizeDates(start, end, isAllDay);

        return this.performRequest<Meeting>({
            path: `/${ideaId}/meetings/${meetingId}`,
            method: RequestMethod.PATCH,
            body: { ...this.adjustAllDayDates(start, end, isAllDay), allDay: isAllDay, timeZone: dateTimeService.getCurrentTimeZone() }
        }).then(this.ensureDateFieldType);
    }

    getMeetingDataForResearch(ideaId: string, researchId: number): Promise<MeetingDataForResearch> {
        return this.performRequest({
            ignoreBaseUrl: true,
            path: `/api/research/${ideaId}/problem-validation/${researchId}/interview-meeting-settings`
        });
    }
}

export const meetingsService = new MeetingsService();

class NotesService extends ActivityServiceBase<Note, NoteData> {
    constructor() {
        super('notes');
    }

    protected ensureItemDateFiledType(item: Note): Note {
        eventDateFieldsParser[ActivityType.Note](item);

        return item;
    }

    protected normalizeDates(item: NoteData): void {
        item.date.setSeconds(0, 0);
    }
}

export const notesService = new NotesService();

class ReachOutsService extends ActivityServiceBase<ReachOut, ReachOutData> {
    constructor() {
        super('reach-outs');
    }

    protected ensureItemDateFiledType(item: ReachOut): ReachOut {
        eventDateFieldsParser[ActivityType.ReachOut](item);

        return item;
    }

    protected normalizeDates(item: ReachOut): void {
        this.normalizeItemDate(item.date);
    }

    private normalizeItemDate(date: Date) {
        date.setSeconds(0, 0);
    }

    @ErrorWithOperationDisplayName('Reschedule reach out')
    rescheduleReachOut(ideaId: string, personId: number, reachOutId: number, date: Date) {
        this.normalizeItemDate(date);
        return this.performRequest<ReachOut>({
            path: `/${ideaId}/people/${personId}/${this.itemSegmentPath}/${reachOutId}`,
            method: RequestMethod.PATCH,
            body: { date }
        }).then(this.ensureDateFieldType);
    }
}

export const reachOutsService = new ReachOutsService();
