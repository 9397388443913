import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { processWithGlobalErrorHandler } from '../../services/common';
import { ideasService } from '../../services/ideasService';
import { MemberActivity } from '../../services/usersService';

export interface IdeaMembersState {
    ideaId?: string;
    membersActivity?: MemberActivity[];
}

const initialState: IdeaMembersState = {};

export const loadMembersActivityForIdea = createAsyncThunk(
    'idea-members/load',
    processWithGlobalErrorHandler((ideaId: string) => {
        return ideasService.getConnectedMembers(ideaId);
    })
);

const ideaMembersSlice = createSlice({
    name: 'ideaMembers',
    initialState: initialState,
    reducers: {
        resetIdeaMembers: () => {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadMembersActivityForIdea.fulfilled, (state, action) => {
                return { ideaId: action.meta.arg, membersActivity: action.payload.length ? action.payload : undefined };
            })
            .addCase(loadMembersActivityForIdea.rejected, () => {
                return initialState;
            });
    }
});

export const { resetIdeaMembers } = ideaMembersSlice.actions;

export default ideaMembersSlice.reducer;
