import { combineClassNames } from '../../services/common';
import { HypothesisLikelihood } from '../../services/hypothesesService';
import { hypothesisLikelihoodLabelMap } from './common';

const hypothesisLikelihoodClassNameMap: Record<HypothesisLikelihood, string> = {
    [HypothesisLikelihood.VeryLikely]: 'k-border-success',
    [HypothesisLikelihood.Likely]: 'k-icp-border-success-30',
    [HypothesisLikelihood.Neutral]: 'k-border-warning',
    [HypothesisLikelihood.Unlikely]: 'k-icp-border-error-30',
    [HypothesisLikelihood.VeryUnlikely]: 'k-border-error'
};

export function HypothesisLikelihoodChip({ likelihood, className }: { likelihood?: HypothesisLikelihood | null; className?: string }) {
    if (!likelihood) return <span className={combineClassNames('k-icp-subtle-text', className)}>Not evaluated</span>;

    return (
        <span
            className={combineClassNames(
                combineClassNames('k-px-2 k-rounded k-fs-sm k-border k-border-solid k-d-inline-block', hypothesisLikelihoodClassNameMap[likelihood]),
                className
            )}
        >
            {hypothesisLikelihoodLabelMap[likelihood]}
        </span>
    );
}
