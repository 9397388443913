import { debounce } from '../services/common';

type JointPosition = 'left' | 'right' | 'top' | 'bottom';
type Point = { x: number; y: number };
type JointEnd = { position: JointPosition; offset: Point };
type PathJoint = { start: JointEnd; end: JointEnd };

const pathWidth = 16;
const arrowWidth = 24;
const roadClass = 'welcome-page-path';
const revealedRoadClass = 'welcome-page-path-revealed';
const arrowAnimationClass = 'arrowAnimation';

const pathJoints: PathJoint[] = [
    {
        start: { position: 'bottom', offset: { x: pathWidth / 2, y: pathWidth / 2 } },
        end: { position: 'top', offset: { x: -arrowWidth / 2, y: -pathWidth / 2 } }
    },
    {
        start: { position: 'bottom', offset: { x: -arrowWidth / 2, y: arrowWidth / 2 } },
        end: { position: 'left', offset: { x: -pathWidth, y: arrowWidth / 2 } }
    },
    {
        start: { position: 'right', offset: { x: pathWidth + 180, y: -pathWidth / 2 } },
        end: { position: 'right', offset: { x: 0, y: pathWidth / 2 } }
    },
    { start: { position: 'bottom', offset: { x: -pathWidth / 2, y: 0 } }, end: { position: 'left', offset: { x: 0, y: pathWidth / 2 } } },
    { start: { position: 'bottom', offset: { x: pathWidth / 2, y: 0 } }, end: { position: 'right', offset: { x: 0, y: pathWidth / 2 } } },
    {
        start: { position: 'bottom', offset: { x: -pathWidth / 2, y: 0 } },
        end: { position: 'top', offset: { x: arrowWidth / 2, y: -pathWidth / 2 } }
    }
];

const getJointCoordinates = (element: HTMLElement, jointEnd: JointEnd): Point => {
    switch (jointEnd.position) {
        case 'top':
            return { x: element.offsetLeft + element.offsetWidth / 2 + jointEnd.offset.x, y: element.offsetTop + jointEnd.offset.y };
        case 'bottom':
            return { x: element.offsetLeft + element.offsetWidth / 2 + jointEnd.offset.x, y: element.offsetTop + element.offsetHeight + jointEnd.offset.y };
        case 'left':
            return { x: element.offsetLeft + jointEnd.offset.x, y: element.offsetTop + element.offsetHeight / 2 + jointEnd.offset.y };
        case 'right':
            return { x: element.offsetLeft + element.offsetWidth + jointEnd.offset.x, y: element.offsetTop + element.offsetHeight / 2 + jointEnd.offset.y };
    }
};

const updatePathLineLength = (pathData: string, lineType: 'horizontal' | 'vertical', lengthChange: number) => {
    const lineRegex = lineType === 'horizontal' ? /(h\s*-?)(\d+\.?\d*)/gm : /(v\s*-?)(\d+\.?\d*)/gm;
    let lineMatch = lineRegex.exec(pathData);

    if (!lineMatch) throw new Error(`Could not find ${lineType} line in path: ${pathData}`);
    if (lineRegex.exec(pathData) != null) throw new Error(`Only one ${lineType} line is allowed. Keep only the longest one and replace the rest with l H, Y`);
    const lineLength = parseFloat(lineMatch[2]);
    let newLineLength = lineLength + lengthChange;
    if (newLineLength < 1) {
        newLineLength = 1;
    }

    const newPathData =
        pathData.substring(0, lineMatch.index + lineMatch[1].length) + newLineLength + pathData.substring(lineMatch.index + lineMatch[0].length);

    return { newPathData, actualChange: newLineLength - lineLength };
};

const updatePathStartingPoint = (pathData: string, x: number, y: number) => {
    const startingPointMatch = /^M\s*(?<X>-?\d+\.?\d*)\s*,\s*(?<Y>-?\d+\.?\d*)/gm.exec(pathData);

    if (!startingPointMatch || !startingPointMatch.groups?.X || !startingPointMatch.groups?.Y)
        throw new Error('Path data must start with Move command (M X, Y): ' + pathData);
    const currentX = parseFloat(startingPointMatch.groups.X);
    const currentY = parseFloat(startingPointMatch.groups.Y);
    const updatedMoveCommand = `M${currentX + x},${currentY + y}`;
    const newPathData = updatedMoveCommand + pathData.substring(startingPointMatch[0].length);

    return newPathData;
};

const fitElementInRectangle = (element: Element & ElementCSSInlineStyle, start: Point, end: Point) => {
    const newWidth = Math.abs(end.x - start.x);
    const newHeight = Math.abs(end.y - start.y);
    const pathDocumentRectangle = element.getBoundingClientRect();
    const widthChange = newWidth - pathDocumentRectangle.width;
    const heightChange = newHeight - pathDocumentRectangle.height;

    element.style.top = Math.min(start.y, end.y) + 'px';
    element.style.left = Math.min(start.x, end.x) + 'px';
    element.style.width = newWidth + 'px';
    element.style.height = newHeight + 'px';

    return { widthChange, heightChange };
};

const updateElementSize = (element: Element & ElementCSSInlineStyle, widthChange: number, heightChange: number) => {
    if (widthChange === 0 && heightChange === 0) return;

    const pathDocumentRectangle = element.getBoundingClientRect();

    if (widthChange !== 0) element.style.width = pathDocumentRectangle.width + widthChange + 'px';
    if (heightChange !== 0) element.style.height = pathDocumentRectangle.height + heightChange + 'px';
};

const transformPath = (pathDefinition: SVGGeometryElement, pathPresentation: SVGGeometryElement, widthChange: number, heightChange: number) => {
    let pathData = pathDefinition.getAttribute('d');
    if (pathData != null) {
        const initialPathBoundingBox = pathPresentation.getBBox();
        const { newPathData: pathData1, actualChange: actualWidthChange } = updatePathLineLength(pathData, 'horizontal', widthChange);
        const { newPathData: pathData2, actualChange: actualHeightChange } = updatePathLineLength(pathData1, 'vertical', heightChange);
        pathData = pathData2;
        pathDefinition.setAttribute('d', pathData);

        const updatedPathBoundingBix = pathPresentation.getBBox();
        if (initialPathBoundingBox.x !== updatedPathBoundingBix.x || initialPathBoundingBox.y !== updatedPathBoundingBix.y) {
            pathData = updatePathStartingPoint(
                pathData,
                initialPathBoundingBox.x - updatedPathBoundingBix.x,
                initialPathBoundingBox.y - updatedPathBoundingBix.y
            );
            pathDefinition.setAttribute('d', pathData);
        }

        return { actualWidthChange, actualHeightChange };
    }

    return { actualWidthChange: 0, actualHeightChange: 0 };
};

const updateRevealPathMask = (revealMaskPath: Element, pathLength: number) => {
    revealMaskPath.setAttribute('stroke-dasharray', pathLength.toString());
    revealMaskPath.setAttribute('stroke-dashoffset', pathLength.toString());
    const maskRevealAnimation = revealMaskPath.querySelector('animate[attributeName="stroke-dashoffset"]');
    if (!maskRevealAnimation) throw new Error('stroke-dashoffset animation is required');
    maskRevealAnimation.setAttribute('values', `${pathLength}; 0`);
};

const completedArrowAnimationDuration = '1ms';
const tryUpdateArrowPosition = (roadDocument: Element) => {
    if (!roadDocument.classList.contains(revealedRoadClass)) return;
    const arrowAnimation = roadDocument.querySelector<SVGAnimationElement>(`.${arrowAnimationClass}`);
    if (!arrowAnimation || arrowAnimation.getAttribute('dur') !== completedArrowAnimationDuration) return;
    arrowAnimation.beginElement();
};

const onRoadRevealed = (roadDocument: Element) => {
    const arrowAnimation = roadDocument.querySelector(`.${arrowAnimationClass}`);
    if (arrowAnimation) {
        arrowAnimation.setAttribute('dur', completedArrowAnimationDuration);
    }
};

const tryRevealRoad = (roadDocument: Element) => {
    if (roadDocument.classList.contains(revealedRoadClass)) return;

    roadDocument.classList.add(revealedRoadClass);
    const allAnimations = roadDocument.querySelectorAll<SVGAnimationElement>('animate, animateMotion, animateTransform');
    const lastAnimation = allAnimations[allAnimations.length - 1];
    if (lastAnimation) {
        lastAnimation.addEventListener('endEvent', onRoadRevealed.bind(undefined, roadDocument), { once: true });
    }

    allAnimations.forEach(a => a.beginElement());
};

export const positionAndScaleRoads = () => {
    const pathDocuments = document.getElementsByClassName(roadClass);
    if (!pathDocuments.length) return;

    const pathStops = document.getElementsByClassName('path-stop');

    const requiredPathDocumentsCount = pathStops.length - 1;

    if (requiredPathDocumentsCount > pathDocuments.length) throw new Error('Path and path stops count mismatch.');

    for (let i = 0; i < requiredPathDocumentsCount; i++) {
        const roadDocument = pathDocuments[i] as HTMLElement;
        const joint = pathJoints[i];
        const startStop = pathStops[i] as HTMLElement;
        const endStop = pathStops[i + 1] as HTMLElement;

        const startCoordinates = getJointCoordinates(startStop, joint.start);
        const endCoordinates = getJointCoordinates(endStop, joint.end);

        const { widthChange, heightChange } = fitElementInRectangle(roadDocument, startCoordinates, endCoordinates);

        const pathDefinition = roadDocument.querySelector<SVGGeometryElement>('.roadPath');
        if (!pathDefinition) throw new Error('Path with class roadPath is required');

        // NOTE: Under firefox pathDefinition.getBBox() returns always an empty rect. That's why we get the respective use element.
        const pathPresentation = roadDocument.querySelector<SVGGeometryElement>('.roadPathPresentation');
        if (!pathPresentation) throw new Error('Use with class roadPathPresentation is required');

        const { actualWidthChange, actualHeightChange } = transformPath(pathDefinition, pathPresentation, widthChange, heightChange);
        updateElementSize(roadDocument, actualWidthChange - widthChange, actualHeightChange - heightChange);

        const revealMaskPath = roadDocument.querySelector('.roadRevealMaskPath');
        if (!revealMaskPath) throw new Error('Path with class roadRevealMaskPath is required');
        updateRevealPathMask(revealMaskPath, pathDefinition.getTotalLength());

        tryUpdateArrowPosition(roadDocument);
    }
};

const positionAndScaleRoadsDebounced = debounce(positionAndScaleRoads, 100);

let lastKnownRoadsHeight: number | undefined;
const roadsLayoutResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    const resizedItem = entries[0];
    if (!resizedItem) return;
    let currentHeight = 0;
    if (resizedItem.borderBoxSize?.length > 0) {
        currentHeight = resizedItem.borderBoxSize[0].blockSize;
    } else if (resizedItem.contentRect) {
        currentHeight = resizedItem.contentRect.height;
    }

    if (!currentHeight) {
        positionAndScaleRoadsDebounced();
        return;
    }

    if (lastKnownRoadsHeight !== currentHeight) {
        lastKnownRoadsHeight = currentHeight;
        positionAndScaleRoads();
    } else {
        positionAndScaleRoadsDebounced();
    }
});

export const enableRoadsAutoLayout = () => {
    const roadsLayout = document.querySelector('.roads-layout');
    if (!roadsLayout) throw new Error('Roads layout element not found');
    roadsLayoutResizeObserver.observe(roadsLayout);
    window.addEventListener('resize', positionAndScaleRoadsDebounced);
};

export const disableRoadsAutoLayout = () => {
    lastKnownRoadsHeight = undefined;
    window.removeEventListener('resize', positionAndScaleRoadsDebounced);
    roadsLayoutResizeObserver.disconnect();
};

const revealElement = (el: HTMLElement, transitionDurationMs: Number, transitionDealyMs: Number) => {
    if (el.style.opacity === '1') {
        return;
    }

    el.style.transition = `opacity ${transitionDurationMs}ms ease-out ${transitionDealyMs}ms`;
    // NOTE: If set directly the transition works only in Chrome.
    window.requestAnimationFrame(() => (el.style.opacity = '1'));
};

const revealRoadTimers: NodeJS.Timeout[] = [];
const revealRoad = (roadPath: string, revealDelayMs?: number) => {
    const roadElement = document.getElementById(roadPath) as Element;
    if (!revealDelayMs) {
        tryRevealRoad(roadElement);
    } else {
        let timeoutId = setTimeout(() => tryRevealRoad(roadElement), revealDelayMs);
        revealRoadTimers.push(timeoutId);
    }
};

export const revealScreenInitialState = () => {
    const ceoAvatar = document.getElementById('ceo-avatar') as HTMLElement;
    revealElement(ceoAvatar, 200, 800);

    const ceoMessage = document.getElementById('ceo-message') as HTMLElement;
    revealElement(ceoMessage, 1500, 900);

    const readyToCommitHeading = document.getElementById('ready-to-commit-heading') as HTMLElement;
    revealElement(readyToCommitHeading, 1000, 1500);

    const cq1 = document.getElementById('commitment-question-1') as HTMLElement;
    revealElement(cq1, 1000, 2500);

    revealRoad(`road-path-initial-1`);
    revealRoad(`road-path-initial-2`, 1000);
    revealRoad(`road-path-initial-3`, 2000);
};

export const cleanUpRevealRoads = () => {
    revealRoadTimers?.forEach(tId => clearTimeout(tId));
};

export const revealOnAnswerCommitted = (answer: number) => {
    if (answer < 3) {
        const cq = document.getElementById(`commitment-question-${answer + 1}`) as HTMLElement;
        revealElement(cq, 1000, 1000);
    } else {
        const startMyJourneyButton = document.getElementById('start-my-journey-button') as HTMLElement;
        revealElement(startMyJourneyButton, 100, 1000);
    }

    revealRoad(`road-path-answer-${answer}`, 500);
};
