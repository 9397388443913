function resolveItem<TItem, TExistingItem extends TItem | undefined>(
    item: TItem | ((existingItem: TExistingItem) => TItem),
    existingItem: TExistingItem
): TItem {
    if (typeof item === 'function') {
        const itemFactory = item as (existingItem?: TItem) => TItem;
        return itemFactory(existingItem);
    }

    return item;
}

export function immutableAddOrUpdate<TItem>(
    collection: TItem[] | undefined,
    item: TItem | ((existingItem?: TItem) => TItem),
    updateCondition: (existingItem: TItem) => boolean
): TItem[] {
    if (!collection || !collection.length) return [resolveItem(item, undefined)];

    let itemUpdated = false;
    const updatedCollection = collection.map(existingItem => {
        if (updateCondition(existingItem)) {
            itemUpdated = true;
            return resolveItem(item, existingItem);
        }

        return existingItem;
    });

    if (!itemUpdated) updatedCollection.push(resolveItem(item, undefined));

    return updatedCollection;
}

export function immutableUpdate<TItem, TCollection extends TItem[] | undefined = TItem[]>(
    collection: TCollection,
    item: TItem | ((existingItem: TItem) => TItem),
    updateCondition: (existingItem: TItem) => boolean
): TCollection extends undefined ? TItem[] | undefined : TItem[] {
    if (!collection || !collection.length) return collection as any;

    let itemUpdated = false;
    const updatedCollection = collection.map((existingItem, existingItemIndex) => {
        if (updateCondition(existingItem)) {
            itemUpdated = true;
            return resolveItem(item, existingItem);
        }

        return existingItem;
    });

    return itemUpdated ? updatedCollection : collection;
}

export function immutableRemove<TItem, TCollection extends TItem[] | undefined = TItem[]>(
    collection: TCollection,
    removeCondition: (item: TItem) => boolean
): TCollection extends undefined ? TItem[] | undefined : TItem[] {
    if (!collection || !collection.length) return collection as any;

    const updatedCollection = collection.filter(removeCondition);

    return updatedCollection.length !== collection.length ? updatedCollection : collection;
}

export function immutableAdd<TItem>(collection: TItem[] | undefined, item: TItem) {
    if (!collection || !collection.length) return [item];

    return [...collection, item];
}

export function immutableAddAt<TItem>(collection: TItem[] | undefined, item: TItem, index: number) {
    if (!collection || !collection.length) return [item];

    const validIndex = Math.max(0, Math.min(collection.length, index));

    const updatedCollection = [...collection];
    updatedCollection.splice(validIndex, 0, item);

    return updatedCollection;
}
