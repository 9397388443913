export const authenticationIntentUrlParamName = 'intent';
export const returnUrlParamName = 'returnUrl';
export const pathWithReturnUrl = (path: string, returnUrl: string) => `${path}?${returnUrlParamName}=${encodeURIComponent(returnUrl)}`;
export const urlWithQueryParams = (url: string, queryParams: Record<string, string> = {}) => {
    const params = new URLSearchParams(queryParams);
    const separator = url.indexOf('?') === -1 ? '?' : '&';

    return `${url}${separator}${params.toString()}`;
};
export const pathWithReturnUrlToCurrentPage = (path: string) => {
    const currentPagePath = window.location.pathname + window.location.search + window.location.hash;

    return pathWithReturnUrl(path, currentPagePath);
};

export function resolveAbsoluteUrl(relativeUrl: string) {
    const absoluteUrl = new URL(relativeUrl, document.baseURI);

    return absoluteUrl.href;
}

export const deleteQueryParamWithValue = (queryParams: URLSearchParams, key: string, value: string) => {
    const keyValues = queryParams.getAll(key);
    const valueIndex = keyValues.indexOf(value);
    if (valueIndex === -1) return false;

    keyValues.splice(valueIndex, 1);
    queryParams.delete(key);
    keyValues.forEach(v => queryParams.append(key, v));

    return true;
};

export function addRequestHeader(request: RequestInit, name: string, value: string) {
    if (!request.headers) request.headers = {};
    (request.headers as Record<string, string>)[name] = value;
}
