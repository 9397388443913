import { StackLayout } from '@progress/kendo-react-layout';
import { ReactElement } from 'react';

export function TextMarker({ children, text }: { children?: ReactElement; text?: string }) {
    if (!children) return null;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            {children}
            {text && <span className="text-marker k-fs-xs k-px-1 k-py-thin k-icp-bg-info-8">{text}</span>}
        </StackLayout>
    );
}
