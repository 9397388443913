import { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { usersService } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentUser } from '../../state/user/userSlice';

export function SetFlagPage() {
    const currentUser = useAppSelector(s => s.user);
    const { flag, flagState } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!currentUser || !flag || (flagState !== 'on' && flagState !== 'off')) {
            return;
        }

        let flags = currentUser.flags;

        if (flagState === 'on') {
            if (!flags.find(f => f === flag)) {
                flags.push(flag);
            }
        } else if (flagState === 'off') {
            flags = flags.filter(f => f !== flag);
        }

        usersService.partiallyUpdateCurrent({ flags: flags }).then(updatedUser => {
            dispatch(setCurrentUser(updatedUser));
            navigate('/');
        });
    }, [flag, flagState, navigate, currentUser, dispatch]);

    if (flagState !== 'on' && flagState !== 'off') {
        return <Navigate to="/404" />;
    }

    return <></>;
}
