export type Country = { code: string; name: string };

class CountriesService {
    getAllCountries(): Promise<Country[]> {
        return this.ensureCountriesModule().then(countryListModule => countryListModule.getData());
    }

    findCountryByCode(countryCode: string) {
        return this.ensureCountriesModule().then(countryListModule => countryListModule.getData().find(c => c.code === countryCode));
    }

    private ensureCountriesModule() {
        return import('country-list');
    }
}

export const countriesService = new CountriesService();
