import { Error as ErrorComponent, ErrorProps } from '@progress/kendo-react-labels';
import { ReactElement, ReactNode, createContext, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

type ValidatorHandle = {
    validate: (enforceShowErrorMessage: boolean) => void;
    isValid: boolean;
};
type ValidationContextValue = {
    registerValidator: (name: string, validator: ValidatorHandle) => void;
    unregisterValidator: (name: string) => void;
    setErrorMessage: (name: string, errorMessage: string | undefined) => void;
};
const ValidationContext = createContext<ValidationContextValue | undefined>(undefined);

type ValidationErrorMessagesContextValue = Partial<Record<string, string>>;
const ValidationErrorMessagesContext = createContext<ValidationErrorMessagesContextValue>({});

export type ValidationScopeHandle = {
    isValid: boolean;
    validate: () => void;
};

type ValidationSubContextValue = {
    registerValidator: (name: string, validator: ValidatorHandle) => void;
    unregisterValidator: (name: string) => void;
};

const ValidationSubContext = createContext<ValidationSubContextValue | undefined>(undefined);
export type ValidationSubScopeHandle = {
    isValid: boolean;
};

export const ValidationScope = forwardRef<ValidationScopeHandle, { children?: ReactNode }>(function(props, ref) {
    const registeredValidators = useRef<Partial<Record<string, ValidatorHandle>>>({});
    const [errorMessages, setErrorMessages] = useState<ValidationErrorMessagesContextValue>({});

    const validationContextValue = useMemo<ValidationContextValue>(() => {
        return {
            registerValidator(name, validator) {
                const currentValidator = registeredValidators.current[name];
                if (currentValidator === validator) return;
                registeredValidators.current[name] = validator;
                validator.validate(false);
            },
            unregisterValidator(name) {
                delete registeredValidators.current[name];
            },
            setErrorMessage(name, errorMessage) {
                setErrorMessages(errorMessages => {
                    if (!errorMessage) {
                        if (!errorMessages[name]) return errorMessages;
                        const updatedErrorMessages = { ...errorMessages };
                        delete updatedErrorMessages[name];
                        return updatedErrorMessages;
                    }

                    return {
                        ...errorMessages,
                        [name]: errorMessage
                    };
                });
            }
        };
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            get isValid() {
                return !Object.values(registeredValidators.current).some(registeredValidator => registeredValidator && !registeredValidator.isValid);
            },
            validate() {
                const validators = Object.values(registeredValidators.current);
                for (const validator of validators) {
                    if (!validator) continue;
                    validator.validate(true);
                }
            }
        }),
        []
    );

    return (
        <ValidationContext.Provider value={validationContextValue}>
            <ValidationErrorMessagesContext.Provider value={errorMessages}>{props.children}</ValidationErrorMessagesContext.Provider>
        </ValidationContext.Provider>
    );
});

export const ValidationSubScope = forwardRef<ValidationSubScopeHandle, { children?: ReactNode }>(function(props, ref) {
    const registeredValidators = useRef<Partial<Record<string, ValidatorHandle>>>({});

    const parentValidationSubContext = useContext(ValidationSubContext);

    const validationSubContextValue = useMemo<ValidationSubContextValue>(() => {
        return {
            registerValidator(name, validator) {
                const currentValidator = registeredValidators.current[name];
                if (currentValidator === validator) return;
                registeredValidators.current[name] = validator;
                parentValidationSubContext?.registerValidator(name, validator);
            },
            unregisterValidator(name) {
                delete registeredValidators.current[name];
                parentValidationSubContext?.unregisterValidator(name);
            }
        };
    }, [parentValidationSubContext]);

    useImperativeHandle(
        ref,
        () => ({
            get isValid() {
                return !Object.values(registeredValidators.current).some(registeredValidator => registeredValidator && !registeredValidator.isValid);
            }
        }),
        []
    );

    return <ValidationSubContext.Provider value={validationSubContextValue}>{props.children}</ValidationSubContext.Provider>;
});

export type ValidationUnitValidator<TValue> = (value: TValue) => string | undefined;
type ValidationUnitProps<TValue> = {
    name: string;
    children?: (
        errorMessage: string | undefined,
        onChange: (value: TValue, suppressEnforceShowErrorMessage?: boolean) => boolean,
        reset: () => void
    ) => ReactElement;
    validator?: ValidationUnitValidator<TValue>;
    value: TValue;
};
export type ValidationUnitHandle = { isValid: boolean };
export const ValidationUnit = forwardRef(function ValidationUnit<TValue>(
    { name, children, validator, value }: ValidationUnitProps<TValue>,
    ref: React.ForwardedRef<ValidationUnitHandle>
) {
    const validationContextValue = useContext(ValidationContext);
    if (!validationContextValue) throw new Error('Use within ValidationScope');
    const [errorMessage, setErrorMessage] = useState<string>();
    const isValidRef = useRef(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const showErrorMessageRef = useRef(showErrorMessage);
    showErrorMessageRef.current = showErrorMessage;
    const valueRef = useRef(value);
    valueRef.current = value;
    const validationSubContext = useContext(ValidationSubContext);
    useImperativeHandle(
        ref,
        () => ({
            get isValid() {
                return isValidRef.current;
            }
        }),
        []
    );

    const validate = useCallback(
        function(value: TValue, enforceShowErrorMessage: boolean) {
            if (enforceShowErrorMessage) setShowErrorMessage(true);
            const errorMessage = validator?.(value);
            setErrorMessage(errorMessage);
            validationContextValue.setErrorMessage(name, showErrorMessageRef.current || enforceShowErrorMessage ? errorMessage : undefined);

            const isValid = !errorMessage;
            isValidRef.current = isValid;

            return isValid;
        },
        [name, validationContextValue, validator]
    );

    const onChange = useCallback(
        function(value: TValue, suppressEnforceShowErrorMessage?: boolean) {
            valueRef.current = value;
            return validate(value, suppressEnforceShowErrorMessage ? false : true);
        },
        [validate]
    );

    const reset = useCallback(function() {
        setErrorMessage(undefined);
        setShowErrorMessage(false);
        isValidRef.current = true;
    }, []);

    useEffect(() => {
        const validator: ValidatorHandle = {
            get isValid() {
                return isValidRef.current;
            },
            validate(enforceShowErrorMessage: boolean) {
                validate(valueRef.current, enforceShowErrorMessage);
            }
        };

        validationContextValue.registerValidator(name, validator);
        validationSubContext?.registerValidator(name, validator);

        return () => {
            validationContextValue.unregisterValidator(name);
            validationSubContext?.unregisterValidator(name);
        };
    }, [name, validationContextValue, validate, validationSubContext]);

    if (!children) return null;

    return children(showErrorMessage ? errorMessage : undefined, onChange, reset);
}) as <TValue>(props: ValidationUnitProps<TValue> & React.RefAttributes<ValidationUnitHandle>) => React.ReactElement | null;

export function ValidationErrorMessage({ name, errorProps }: { name: string; errorProps?: ErrorProps }) {
    const validationErrorMessagesContext = useContext(ValidationErrorMessagesContext);
    const errorMessage = validationErrorMessagesContext[name];
    if (!errorMessage) return null;

    return <ErrorComponent {...errorProps}>{errorMessage}</ErrorComponent>;
}

export function composeValidators<TValue>(validators: ValidationUnitValidator<TValue>[]): ValidationUnitValidator<TValue> {
    return function(value) {
        for (const validator of validators) {
            const errorMessage = validator(value);
            if (errorMessage) return errorMessage;
        }

        return undefined;
    };
}
