import { Button } from '@progress/kendo-react-buttons';
import { TextAreaHandle } from '@progress/kendo-react-inputs';
import { Error as ErrorComponent } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { forwardRef, ReactElement, ReactNode, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useSingleClickButton } from '../../../hooks/commonHooks';
import { ReactComponent as AddIcon } from '../../../icons/plus-circle.svg';
import tipsIllustrationUrl from '../../../images/tips-illustration.svg';
import { combineClassNames } from '../../../services/common';
import { domService } from '../../../services/domService';
import { ValidationUnit } from '../../common/validation';
import { ChangeOnBlurTextArea, maxLengthValidator } from '../../ui/inputs';
import { InterviewItemEditorView, InterviewItemEditorViewActionsBar, InterviewItemEditorViewHandle, InterviewItemEditorViewProps } from '../interviewItem';
import { InterviewScriptEntryLabelView } from './interviewScriptEntryLabelView';

export type InterviewEntryEditorViewHandle = {
    questionEditor: TextAreaHandle | null;
    answerEditor: TextAreaHandle | null;
    element: HTMLElement | null;
};

export type InterviewEntryEditorViewProps = Omit<InterviewItemEditorViewProps, 'children'> & {
    validationPrefix: string;
    tips?: string[];
    question?: string | null;
    answer?: string | null;
    onAnswerChange?: (answer: string, isValid: boolean) => void;
    answerPlaceholder?: string;
    actionElements?: ReactElement[];
    canEditQuestion?: boolean;
    onQuestionChange?: (question: string, isValid: boolean) => void;
    questionPlaceholder?: string;
    labelText?: string | null;
    labelColor?: string | null;
    readonly?: boolean;
    children?: ReactNode;
};

const questionValidator = maxLengthValidator('Question', 10000);
const answerValidator = maxLengthValidator('Answer', 10000);
export const InterviewEntryEditorView = forwardRef<InterviewEntryEditorViewHandle, InterviewEntryEditorViewProps>(function InterviewEntryEditorView(
    {
        validationPrefix,
        tips,
        question,
        answer,
        onAnswerChange,
        answerPlaceholder,
        selected,
        actionElements,
        canDelete,
        onDelete,
        canEditQuestion,
        onQuestionChange,
        questionPlaceholder,
        labelText,
        labelColor,
        onBlur,
        onFocus,
        errorMessage,
        readonly,
        children
    },
    ref
) {
    const itemEditorViewRef = useRef<InterviewItemEditorViewHandle>(null);
    const questionEditorRef = useRef<TextAreaHandle>(null);
    const answerEditorRef = useRef<TextAreaHandle>(null);

    const isFocused = useCallback(function() {
        if (!itemEditorViewRef.current?.element) return false;

        return itemEditorViewRef.current.element.contains(document.activeElement);
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            get questionEditor() {
                return questionEditorRef.current;
            },
            get answerEditor() {
                return answerEditorRef.current;
            },
            get element() {
                if (!itemEditorViewRef.current) return null;
                return itemEditorViewRef.current.element;
            }
        }),
        []
    );

    useEffect(() => {
        if (!selected) return;

        if (isFocused()) return;

        const textAreaToFocus = questionEditorRef.current?.element.current ?? answerEditorRef.current?.element.current;
        if (!textAreaToFocus) return;
        domService.focusTextArea(textAreaToFocus);
    }, [isFocused, selected]);

    const hasTips = tips && tips.length > 0;
    const hasActions = actionElements && actionElements.length > 0;

    return (
        <InterviewItemEditorView
            ref={itemEditorViewRef}
            selected={selected}
            canDelete={!readonly && canDelete}
            onDelete={onDelete}
            onBlur={onBlur}
            onFocus={onFocus}
            errorMessage={errorMessage}
        >
            {hasTips && (
                <StackLayout
                    align={{ horizontal: 'start', vertical: 'top' }}
                    className="k-gap-1 k-px-4 k-py-2 k-border-b k-border-b-solid k-icp-component-border k-rounded-t-lg k-icp-bg-secondary-4"
                >
                    <img src={tipsIllustrationUrl} width="25" height="25" alt="Tips" />
                    <ul className={combineClassNames('k-fs-sm k-my-0 k-pl-5', tips.length === 1 ? 'k-pt-1' : undefined)}>
                        {tips.map((tip, tipIndex) => (
                            <li key={tipIndex}>{tip}</li>
                        ))}
                    </ul>
                </StackLayout>
            )}
            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'stretch', vertical: 'top' }}
                className={combineClassNames('k-gap-2 k-px-4', hasTips && !canEditQuestion ? 'k-pt-3' : 'k-pt-4', hasActions || children ? 'k-pb-2' : 'k-pb-4')}
            >
                {labelText && <InterviewScriptEntryLabelView color={labelColor}>{labelText}</InterviewScriptEntryLabelView>}
                {canEditQuestion ? (
                    <ValidationUnit name={`${validationPrefix}_question`} value={question} validator={readonly ? undefined : questionValidator}>
                        {(questionErrorMessage, validationOnQuestionChange) => (
                            <div>
                                <ChangeOnBlurTextArea
                                    ref={questionEditorRef}
                                    value={question ?? ''}
                                    onChange={
                                        onQuestionChange &&
                                        (e => {
                                            const questionIsValid = validationOnQuestionChange(e.value);
                                            onQuestionChange(e.value, questionIsValid);
                                        })
                                    }
                                    autoSize={true}
                                    rows={2}
                                    placeholder={questionPlaceholder}
                                    readOnly={readonly}
                                    valid={!questionErrorMessage}
                                />
                                {questionErrorMessage && <ErrorComponent>{questionErrorMessage}</ErrorComponent>}
                            </div>
                        )}
                    </ValidationUnit>
                ) : (
                    question && <span className="k-font-medium">{question}</span>
                )}
                <ValidationUnit name={`${validationPrefix}_answer`} value={answer} validator={readonly ? undefined : answerValidator}>
                    {(answerErrorMessage, validationOnAnswerChange) => (
                        <div>
                            <ChangeOnBlurTextArea
                                ref={answerEditorRef}
                                value={answer ?? ''}
                                onChange={
                                    onAnswerChange &&
                                    (e => {
                                        const answerIsValid = validationOnAnswerChange(e.value);
                                        onAnswerChange(e.value, answerIsValid);
                                    })
                                }
                                autoSize={true}
                                rows={2}
                                placeholder={answerPlaceholder}
                                readOnly={readonly}
                                valid={!answerErrorMessage}
                            />
                            {answerErrorMessage && <ErrorComponent>{answerErrorMessage}</ErrorComponent>}
                        </div>
                    )}
                </ValidationUnit>
                {hasActions && <InterviewItemEditorViewActionsBar isResponsive actions={actionElements} />}
                {children}
            </StackLayout>
        </InterviewItemEditorView>
    );
});

export function InterviewEntryInsert({ onInsert, hidden }: { onInsert?: () => Promise<void>; hidden?: boolean }) {
    const [disabled, onInsertCallbackCreator] = useSingleClickButton();
    const onInsertCallback = onInsert && onInsertCallbackCreator(onInsert);

    return (
        <Button
            type="button"
            size="small"
            fillMode="flat"
            className={combineClassNames('k-icp-svg-icon-button k-no-focus !k-px-0 interview-entry-add-btn', hidden ? 'k-visibility-invisible' : undefined)}
            onClick={onInsertCallback}
            disabled={disabled}
        >
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                <div className="k-flex-1 full-horizontal-separator" />
                <AddIcon className="k-icp-icon" />
                <div className="k-flex-1 full-horizontal-separator" />
            </StackLayout>
        </Button>
    );
}

export const customQuestionTips: string[] = [
    'Formulate short, open-ended, exploratory questions about specific in their past.',
    'Avoid asking for generic opinions about the future.',
    'Start questions with words like who, what, why, and how.',
    'Avoid questions that start with is, are, would, and do you.'
];
