import { ReactComponent as PositiveVerdictIcon } from '../../../icons/check-circle-closed.svg';
import { ReactComponent as UnknownVerdictIcon } from '../../../icons/minus-circle.svg';
import { ReactComponent as NegativeVerdictIcon } from '../../../icons/x-circle.svg';
import { countWhere } from '../../../services/common';
import { getOrdinalSuffix } from '../../../services/common/common.string';
import { ReducedPerson } from '../../../services/contactsService';
import { Hypothesis } from '../../../services/hypothesesService';
import { HypothesisVerdict } from '../../../services/interviewsService';
import { PersonalizedHypothesisView } from '../../hypotheses/hypothesisView';
import LoadingIndicator from '../../ui/loadingIndicator';
import {
    InterviewItemCard,
    InterviewItemCardIconData,
    InterviewItemGroup,
    InterviewItemGroupHeader,
    InterviewItemGroupsList,
    useInterviewItemCardsList
} from '../interviewItem';
import { CustomerProblemEvaluatedHypothesesGroup, EvaluatedHypothesis } from './interviewHypotheses';

export function InterviewHypothesesGroupedList({
    interviewee,
    groups,
    selectedHypothesisId,
    onHypothesisSelect
}: {
    interviewee?: ReducedPerson;
    groups?: CustomerProblemEvaluatedHypothesesGroup[];
    selectedHypothesisId?: number;
    onHypothesisSelect?: (hypothesisId: number) => void;
}) {
    const setHypothesisCardRef = useInterviewItemCardsList(groups !== undefined, selectedHypothesisId);

    if (!groups || !interviewee) return <LoadingIndicator size="big" className="k-display-block -block-center" />;

    return (
        <InterviewItemGroupsList>
            {groups.map((group, groupIndex) => {
                const hypothesesInGroup: EvaluatedHypothesis<Hypothesis>[] = [];
                hypothesesInGroup.push(...group.painLevelHypotheses);
                group.alternativeSolutionHypothesesGroups.forEach(alternativeSolutionHypothesesGroup => {
                    hypothesesInGroup.push(...alternativeSolutionHypothesesGroup.alternativeSolutionHypotheses);
                    hypothesesInGroup.push(...alternativeSolutionHypothesesGroup.alternativeSolutionSatisfactionHypotheses);
                });

                const hypothesesWithVerdictCount = countWhere(hypothesesInGroup, h => h.verdict);
                const groupOrdinal = groupIndex + 1;

                return (
                    <InterviewItemGroup
                        key={group.customerProblemId}
                        header={
                            <InterviewItemGroupHeader entriesWithStatusCount={hypothesesWithVerdictCount} totalEntriesCount={hypothesesInGroup.length}>
                                Hypotheses for the {groupOrdinal}
                                {getOrdinalSuffix(groupOrdinal)} Customer Problem
                            </InterviewItemGroupHeader>
                        }
                    >
                        {hypothesesInGroup.map(hypothesis => {
                            const hypothesisId = hypothesis.hypothesis.id;
                            const iconData = resolveInterviewItemCardIconData(hypothesis.verdict);

                            return (
                                <InterviewItemCard
                                    key={hypothesisId}
                                    ref={r => setHypothesisCardRef(r, hypothesisId)}
                                    icon={iconData?.icon}
                                    iconClassName={iconData?.className}
                                    selected={hypothesisId === selectedHypothesisId}
                                    complete={!!hypothesis.verdict}
                                    onClick={onHypothesisSelect && (() => onHypothesisSelect(hypothesisId))}
                                >
                                    <PersonalizedHypothesisView person={interviewee} hypothesis={hypothesis.hypothesis} plainText />
                                </InterviewItemCard>
                            );
                        })}
                    </InterviewItemGroup>
                );
            })}
        </InterviewItemGroupsList>
    );
}

function resolveInterviewItemCardIconData(verdict?: HypothesisVerdict): InterviewItemCardIconData | undefined {
    if (!verdict)
        return {
            className: 'k-text-secondary'
        };

    switch (verdict) {
        case HypothesisVerdict.True:
            return {
                icon: PositiveVerdictIcon,
                className: 'k-text-success'
            };
        case HypothesisVerdict.False:
            return {
                icon: NegativeVerdictIcon,
                className: 'k-text-error'
            };
        case HypothesisVerdict.Unknown:
            return {
                icon: UnknownVerdictIcon
            };
    }
}
