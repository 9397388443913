import { findCanvasItem, useGlobalCanvas } from '../../hooks/canvasHooks';
import { BoxType } from '../../services/canvasService';
import {
    CustomerProblemAlternativeSolutionHypothesis,
    CustomerProblemAlternativeSolutionSatisfactionHypothesis,
    CustomerProblemPainLevelHypothesis,
    Hypothesis,
    HypothesisType,
    PainLevel,
    SatisfactionLevel
} from '../../services/hypothesesService';
import { saveItem } from '../../state/canvas/canvasSlice';
import { useAppDispatch } from '../../state/hooks';
import { TextTokenPredefinedItem, TextTokenWrapper, TextTokensEditorLayout, requiredTextTokenValidator } from '../common/tokenizedTextEditor';

export type EditableHypothesis = Pick<Hypothesis, 'type'> & Partial<Hypothesis>;
export enum HypothesisEditMode {
    EditUserDefined, // Edit only "yellow items"
    EditAll, // Edit all items
    NoEdit, // All items are "grey"
    View, // User defined items are yellow, others are grey
    Plain // The hypothesis is shown as plain text
}
export function HypothesisEditor({
    value,
    editMode,
    onChange,
    bordered,
    validate,
    className
}: {
    value: EditableHypothesis;
    editMode: HypothesisEditMode;
    onChange?: (e: { value: EditableHypothesis }) => void;
    bordered?: boolean;
    validate?: boolean;
    className?: string;
}) {
    const type = value.type;
    if (type === HypothesisType.PainLevel)
        return (
            <CustomerProblemPainLevelHypothesisEditor
                hypothesis={value}
                editMode={editMode}
                onChange={v => onChange?.(v as any)}
                bordered={bordered}
                validate={validate}
                className={className}
            />
        );
    if (type === HypothesisType.AlternativeSolutionUsage)
        return (
            <CustomerProblemAlternativeSolutionHypothesisEditor
                hypothesis={value}
                editMode={editMode}
                onChange={v => onChange?.(v as any)}
                bordered={bordered}
                validate={validate}
                className={className}
            />
        );
    if (type === HypothesisType.AlternativeSolutionSatisfaction)
        return (
            <CustomerProblemAlternativeSolutionSatisfactionHypothesisEditor
                hypothesis={value}
                editMode={editMode}
                onChange={v => onChange?.(v as any)}
                bordered={bordered}
                validate={validate}
                className={className}
            />
        );

    throw new Error('Unknown hypothesis type: ' + type);
}

type ConcreteHypothesisEditor<THypothesis> = {
    hypothesis: Partial<THypothesis>;
    editMode: HypothesisEditMode;
    onChange?: (e: { value: Partial<THypothesis> }) => void;
    bordered?: boolean;
    validate?: boolean;
    className?: string;
};

const percentagePredefinedValues: TextTokenPredefinedItem[] = [
    { text: '15%', value: 15 },
    { text: '30%', value: 30 },
    { text: '45%', value: 45 },
    { text: '60%', value: 60 },
    { text: '75%', value: 75 },
    { text: '90%', value: 90 }
];
const painLevelPredefinedValues: TextTokenPredefinedItem[] = [
    { text: 'No pain', value: PainLevel.NoPain },
    { text: PainLevel.Mild, value: PainLevel.Mild },
    { text: PainLevel.Moderate, value: PainLevel.Moderate },
    { text: PainLevel.Severe, value: PainLevel.Severe }
];

function useHypothesisEditor<THypothesis>(
    editMode: HypothesisEditMode,
    hypothesis: ConcreteHypothesisEditor<THypothesis>['hypothesis'],
    onChange: ConcreteHypothesisEditor<THypothesis>['onChange']
) {
    return {
        inEdit: editMode === HypothesisEditMode.EditAll || editMode === HypothesisEditMode.EditUserDefined,
        forceView: editMode === HypothesisEditMode.NoEdit || editMode === HypothesisEditMode.Plain,
        forceEdit: editMode === HypothesisEditMode.EditAll,
        plainText: editMode === HypothesisEditMode.Plain,
        handleChange<TFiledName extends keyof THypothesis>(fieldName: TFiledName, value: Partial<THypothesis>[TFiledName]) {
            onChange?.({ value: { ...hypothesis, [fieldName]: value } });
        }
    };
}

function CustomerProblemPainLevelHypothesisEditor({
    hypothesis,
    editMode,
    onChange,
    bordered,
    validate,
    className
}: ConcreteHypothesisEditor<CustomerProblemPainLevelHypothesis>) {
    const { canvas } = useGlobalCanvas(undefined, true);
    const customerSegment = findCanvasItem(canvas.boxes, BoxType.CustomerSegments, hypothesis.customerSegmentId);
    const customerProblem = findCanvasItem(canvas.boxes, BoxType.Problem, hypothesis.customerProblemId);
    const { inEdit, forceView, forceEdit, handleChange, plainText } = useHypothesisEditor(editMode, hypothesis, onChange);

    return (
        <TextTokensEditorLayout inEdit={inEdit} bordered={bordered} className={className} compactLines={plainText}>
            At least{' '}
            <TextTokenWrapper
                forceEdit={true}
                forceView={forceView}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="e.g. 30%"
                predefinedValues={percentagePredefinedValues}
                onPredefinedItemSelected={e => handleChange('percentage', e.value)}
                onChange={e => {
                    let newPercentage = parseInt(e.value);
                    if (Number.isNaN(newPercentage)) {
                        handleChange('percentage', undefined);
                        return '';
                    }
                    if (newPercentage < 0) newPercentage = 0;
                    if (newPercentage > 100) newPercentage = 100;
                    handleChange('percentage', newPercentage);

                    return `${newPercentage}%`;
                }}
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {hypothesis.percentage === undefined ? undefined : `${hypothesis.percentage}%`}
            </TextTokenWrapper>{' '}
            of{' '}
            <TextTokenWrapper
                isLoading={hypothesis.customerSegmentId !== undefined && !customerSegment}
                loaderWidth={250}
                forceEdit={forceEdit}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="select Customer Segment"
                predefinedValuesOnly={true}
                predefinedValues={canvas.boxes
                    ?.find(b => b.type === BoxType.CustomerSegments)
                    ?.items.map<TextTokenPredefinedItem>(i => ({ text: i.content, value: i.id }))}
                onPredefinedItemSelected={v => handleChange('customerSegmentId', v.value)}
                emptyPredefinedValuesText="No Customer Segments defined"
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {customerSegment?.content}
            </TextTokenWrapper>{' '}
            will rate{' '}
            <TextTokenWrapper
                isLoading={hypothesis.customerProblemId !== undefined && !customerProblem}
                loaderWidth={200}
                forceEdit={editMode === HypothesisEditMode.EditAll}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="select Customer Problem"
                predefinedValuesOnly={true}
                predefinedValues={
                    hypothesis.customerSegmentId
                        ? canvas.boxes
                              ?.find(b => b.type === BoxType.Problem)
                              ?.items.filter(i => i.relatedItemIds?.includes(hypothesis.customerSegmentId!))
                              .map<TextTokenPredefinedItem>(i => ({ text: i.content, value: i.id }))
                        : []
                }
                onPredefinedItemSelected={v => handleChange('customerProblemId', v.value)}
                emptyPredefinedValuesText={
                    !hypothesis.customerSegmentId ? 'Select a Customer Segment first' : 'No Customer Problems defined for the selected Customer Segment'
                }
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {customerProblem?.content}
            </TextTokenWrapper>{' '}
            as a{' '}
            <TextTokenWrapper
                forceEdit={true}
                forceView={forceView}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="e.g. severe"
                predefinedValuesOnly={true}
                predefinedValues={painLevelPredefinedValues}
                onPredefinedItemSelected={v => handleChange('painLevel', v.value)}
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {hypothesis.painLevel === PainLevel.NoPain ? 'No pain' : hypothesis.painLevel}
            </TextTokenWrapper>{' '}
            problem
        </TextTokensEditorLayout>
    );
}

function CustomerProblemAlternativeSolutionHypothesisEditor({
    hypothesis,
    editMode,
    onChange,
    bordered,
    validate,
    className
}: ConcreteHypothesisEditor<CustomerProblemAlternativeSolutionHypothesis>) {
    const { canvas } = useGlobalCanvas(undefined, true);
    const customerSegment = findCanvasItem(canvas.boxes, BoxType.CustomerSegments, hypothesis.customerSegmentId);
    const customerProblem = findCanvasItem(canvas.boxes, BoxType.Problem, hypothesis.customerProblemId);
    const alternativeSolution = findCanvasItem(canvas.boxes, BoxType.AlternativeSolutions, hypothesis.alternativeSolutionId);
    const alternativeSolutionPredefinedItems = canvas.boxes
        ?.find(b => b.type === BoxType.AlternativeSolutions)
        ?.items.map<TextTokenPredefinedItem>(i => ({ text: i.content, value: i.id }));
    const dispatch = useAppDispatch();
    const { inEdit, forceView, forceEdit, handleChange, plainText } = useHypothesisEditor(editMode, hypothesis, onChange);

    return (
        <TextTokensEditorLayout inEdit={inEdit} bordered={bordered} className={className} compactLines={plainText}>
            At least{' '}
            <TextTokenWrapper
                forceEdit={true}
                forceView={forceView}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="e.g. 30%"
                predefinedValues={percentagePredefinedValues}
                onPredefinedItemSelected={e => handleChange('percentage', e.value)}
                onChange={e => {
                    let newPercentage = parseInt(e.value);
                    if (Number.isNaN(newPercentage)) {
                        handleChange('percentage', undefined);
                        return '';
                    }
                    if (newPercentage < 0) newPercentage = 0;
                    if (newPercentage > 100) newPercentage = 100;
                    handleChange('percentage', newPercentage);

                    return `${newPercentage}%`;
                }}
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {hypothesis.percentage === undefined ? undefined : `${hypothesis.percentage}%`}
            </TextTokenWrapper>{' '}
            of{' '}
            <TextTokenWrapper
                isLoading={hypothesis.customerSegmentId !== undefined && !customerSegment}
                loaderWidth={250}
                forceEdit={forceEdit}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="select Customer Segment"
                predefinedValuesOnly={true}
                predefinedValues={canvas.boxes
                    ?.find(b => b.type === BoxType.CustomerSegments)
                    ?.items.map<TextTokenPredefinedItem>(i => ({ text: i.content, value: i.id }))}
                onPredefinedItemSelected={v => handleChange('customerSegmentId', v.value)}
                emptyPredefinedValuesText="No Customer Segments defined"
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {customerSegment?.content}
            </TextTokenWrapper>{' '}
            currently address{' '}
            <TextTokenWrapper
                isLoading={hypothesis.customerProblemId !== undefined && !customerProblem}
                loaderWidth={200}
                forceEdit={editMode === HypothesisEditMode.EditAll}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="select Customer Problem"
                predefinedValuesOnly={true}
                predefinedValues={
                    hypothesis.customerSegmentId
                        ? canvas.boxes
                              ?.find(b => b.type === BoxType.Problem)
                              ?.items.filter(i => i.relatedItemIds?.includes(hypothesis.customerSegmentId!))
                              .map<TextTokenPredefinedItem>(i => ({ text: i.content, value: i.id }))
                        : []
                }
                onPredefinedItemSelected={v => handleChange('customerProblemId', v.value)}
                emptyPredefinedValuesText={
                    !hypothesis.customerSegmentId ? 'Select a Customer Segment first' : 'No Customer Problems defined for the selected Customer Segment'
                }
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {customerProblem?.content}
            </TextTokenWrapper>{' '}
            by using{' '}
            <TextTokenWrapper
                isLoading={hypothesis.alternativeSolutionId !== undefined && !alternativeSolution}
                loaderWidth={150}
                forceEdit={forceEdit}
                readonly={!inEdit || !canvas.boxes}
                plainText={plainText}
                placeholder="alternative solution"
                predefinedValues={alternativeSolutionPredefinedItems}
                onPredefinedItemSelected={v => handleChange('alternativeSolutionId', v.value)}
                emptyPredefinedValuesText="Type to define an alternative solution"
                onChange={e => {
                    if (!e.value) {
                        handleChange('alternativeSolutionId', undefined);
                        return;
                    }

                    const existingSolution = alternativeSolutionPredefinedItems?.find(pi => pi.text.toLowerCase().trim() === e.value.toLowerCase().trim());
                    if (existingSolution) {
                        handleChange('alternativeSolutionId', existingSolution.value);
                        return;
                    }

                    dispatch(
                        saveItem({
                            boxType: BoxType.AlternativeSolutions,
                            content: e.value
                        })
                    )
                        .unwrap()
                        .then(result => result && handleChange('alternativeSolutionId', result.itemId));
                }}
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {alternativeSolution?.content}
            </TextTokenWrapper>
        </TextTokensEditorLayout>
    );
}

const satisfactionLevelPredefinedValues: TextTokenPredefinedItem[] = [
    { text: SatisfactionLevel.Satisfied, value: SatisfactionLevel.Satisfied },
    { text: SatisfactionLevel.Neutral, value: SatisfactionLevel.Neutral },
    { text: SatisfactionLevel.Dissatisfied, value: SatisfactionLevel.Dissatisfied }
];

function CustomerProblemAlternativeSolutionSatisfactionHypothesisEditor({
    hypothesis,
    editMode,
    onChange,
    bordered,
    validate,
    className
}: ConcreteHypothesisEditor<CustomerProblemAlternativeSolutionSatisfactionHypothesis>) {
    const { canvas } = useGlobalCanvas(undefined, true);
    const customerSegment = findCanvasItem(canvas.boxes, BoxType.CustomerSegments, hypothesis.customerSegmentId);
    const customerProblem = findCanvasItem(canvas.boxes, BoxType.Problem, hypothesis.customerProblemId);
    const alternativeSolution = findCanvasItem(canvas.boxes, BoxType.AlternativeSolutions, hypothesis.alternativeSolutionId);
    const alternativeSolutionPredefinedItems = canvas.boxes
        ?.find(b => b.type === BoxType.AlternativeSolutions)
        ?.items.map<TextTokenPredefinedItem>(i => ({ text: i.content, value: i.id }));
    const dispatch = useAppDispatch();
    const { inEdit, forceView, forceEdit, handleChange, plainText } = useHypothesisEditor(editMode, hypothesis, onChange);

    return (
        <TextTokensEditorLayout inEdit={inEdit} bordered={bordered} className={className} compactLines={plainText}>
            At least{' '}
            <TextTokenWrapper
                forceEdit={true}
                forceView={forceView}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="e.g. 30%"
                predefinedValues={percentagePredefinedValues}
                onPredefinedItemSelected={e => handleChange('percentage', e.value)}
                onChange={e => {
                    let newPercentage = parseInt(e.value);
                    if (Number.isNaN(newPercentage)) {
                        handleChange('percentage', undefined);
                        return '';
                    }
                    if (newPercentage < 0) newPercentage = 0;
                    if (newPercentage > 100) newPercentage = 100;
                    handleChange('percentage', newPercentage);

                    return `${newPercentage}%`;
                }}
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {hypothesis.percentage === undefined ? undefined : `${hypothesis.percentage}%`}
            </TextTokenWrapper>{' '}
            of{' '}
            <TextTokenWrapper
                isLoading={hypothesis.customerSegmentId !== undefined && !customerSegment}
                loaderWidth={250}
                forceEdit={forceEdit}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="select Customer Segment"
                predefinedValuesOnly={true}
                predefinedValues={canvas.boxes
                    ?.find(b => b.type === BoxType.CustomerSegments)
                    ?.items.map<TextTokenPredefinedItem>(i => ({ text: i.content, value: i.id }))}
                onPredefinedItemSelected={v => handleChange('customerSegmentId', v.value)}
                emptyPredefinedValuesText="No Customer Segments defined"
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {customerSegment?.content}
            </TextTokenWrapper>{' '}
            who currently address{' '}
            <TextTokenWrapper
                isLoading={hypothesis.customerProblemId !== undefined && !customerProblem}
                loaderWidth={200}
                forceEdit={editMode === HypothesisEditMode.EditAll}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="select Customer Problem"
                predefinedValuesOnly={true}
                predefinedValues={
                    hypothesis.customerSegmentId
                        ? canvas.boxes
                              ?.find(b => b.type === BoxType.Problem)
                              ?.items.filter(i => i.relatedItemIds?.includes(hypothesis.customerSegmentId!))
                              .map<TextTokenPredefinedItem>(i => ({ text: i.content, value: i.id }))
                        : []
                }
                onPredefinedItemSelected={v => handleChange('customerProblemId', v.value)}
                emptyPredefinedValuesText={
                    !hypothesis.customerSegmentId ? 'Select a Customer Segment first' : 'No Customer Problems defined for the selected Customer Segment'
                }
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {customerProblem?.content}
            </TextTokenWrapper>{' '}
            by using{' '}
            <TextTokenWrapper
                isLoading={hypothesis.alternativeSolutionId !== undefined && !alternativeSolution}
                loaderWidth={150}
                forceEdit={forceEdit}
                readonly={!inEdit || !canvas.boxes}
                plainText={plainText}
                placeholder="alternative solution"
                predefinedValues={alternativeSolutionPredefinedItems}
                onPredefinedItemSelected={v => handleChange('alternativeSolutionId', v.value)}
                emptyPredefinedValuesText="Type to define an alternative solution"
                onChange={e => {
                    if (!e.value) {
                        handleChange('alternativeSolutionId', undefined);
                        return;
                    }

                    const existingSolution = alternativeSolutionPredefinedItems?.find(pi => pi.text.toLowerCase().trim() === e.value.toLowerCase().trim());
                    if (existingSolution) {
                        handleChange('alternativeSolutionId', existingSolution.value);
                        return;
                    }

                    dispatch(
                        saveItem({
                            boxType: BoxType.AlternativeSolutions,
                            content: e.value
                        })
                    )
                        .unwrap()
                        .then(result => result && handleChange('alternativeSolutionId', result.itemId));
                }}
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {alternativeSolution?.content}
            </TextTokenWrapper>{' '}
            are{' '}
            <TextTokenWrapper
                forceEdit={true}
                forceView={forceView}
                readonly={!inEdit}
                plainText={plainText}
                placeholder="e.g. dissatisfied"
                predefinedValuesOnly={true}
                predefinedValues={satisfactionLevelPredefinedValues}
                onPredefinedItemSelected={v => handleChange('satisfactionLevel', v.value)}
                validator={validate ? requiredTextTokenValidator : undefined}
            >
                {hypothesis.satisfactionLevel}
            </TextTokenWrapper>{' '}
            by that solution
        </TextTokensEditorLayout>
    );
}
