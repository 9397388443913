import { ErrorWithOperationDisplayName } from './common';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { InterviewType } from './interviewsService';

export type InsightCatalog = {
    id: string;
    context: InsightContext;
    sections: (InsightSection | ForkingInsightSection)[];
};

export type InsightContext = {
    properties: InsightContextProperty[];
};

export type InsightContextProperty = {
    name: string;
    type: InsightPropertyType;
    source: InsightContextPropertySource;
    array?: boolean;
};

export enum InsightPropertyType {
    CustomerSegment = 'CustomerSegment',
    AlternativeSolution = 'AlternativeSolution',
    JobToBeDone = 'JobToBeDone',
    Text = 'Text',
    Boolean = 'Boolean'
}

export enum InsightContextPropertySource {
    Insight = 'Insight',
    Research = 'Research'
}

export type InsightSection = {
    title: string;
    tag: string;
    groups: (InsightGroup | ForkingInsightGroup)[];
};

export type InsightForkingParent = {
    contextProperties: string[];
};

export type ForkingInsightSection = InsightSection &
    InsightForkingParent & {
        groups: (InsightGroup | ForkedInsightGroup)[];
    };

export type InsightGroup = {
    title: string;
    questions: InsightQuestion[];
};

export type InsightForkedItem = {
    condition: ForkCondition;
};

export type ForkedInsightGroup = InsightGroup & InsightForkedItem;

export type ForkingInsightGroup = InsightGroup &
    InsightForkingParent & {
        questions: (InsightQuestion | ForkedInsightQuestion)[];
    };

export type InsightQuestion = {
    content: string;
    tag: string;
    insightTemplates: InsightCatalogTemplate[];
};

export type ForkedInsightQuestion = InsightQuestion & InsightForkedItem;

export type ForkCondition = {
    contextProperty: string;
};

export type InsightCatalogTemplate = {
    content: string | null;
    tag: string;
    type: InsightType;
    parameters: InsightTemplateParameter[];
};

export enum InsightType {
    JobToBeDone = 'JobToBeDone',
    AlternativeSolution = 'AlternativeSolution'
}

export type InsightTemplateParameter = {
    id: string;
    type: InsightPropertyType;
    placeholder?: string;
    readonly?: boolean;
    binding?: InsightParameterBinding;
};

export type InsightParameterBinding = ReadFromContextBinding | AggregateInContextBinding | ReadFromForkBinding;

export type ReadFromContextBinding = {
    readFromContext: true;
    propertyName: string;
};

export type AggregateInContextBinding = {
    aggregateToContext: true;
    propertyName: string;
};

export type ReadFromForkBinding = {
    readFromFork: true;
};

type InsightBase = {
    id: number;
    origin: InsightOrigin;
    template: InsightTemplate;
    contextBindings: InsightContextBinding[];
    parameterValues: InsightParameterValue[];
    researchIds: number[];
    interviewIds: number[];
    customerSegmentId: number;
    jobToBeDoneId: number;
};

export type JobToBeDoneInsight = InsightBase & { type: InsightType.JobToBeDone };
export type AlternativeSolutionInsight = InsightBase & { type: InsightType.AlternativeSolution; alternativeSolutionId: number };

export type Insight = JobToBeDoneInsight | AlternativeSolutionInsight;

export type InsightOrigin = {
    catalog: string;
    section: string;
    question: string;
};

export type InsightTemplate = {
    content: string | null;
    parameters: InsightParameter[];
};

export type InsightParameter = {
    id: string;
    type: InsightPropertyType;
    placeholder?: string;
    locked: boolean;
};

export type InsightParameterValue = {
    id: string;
    value: any;
};

export type InsightContextBinding = {
    insightParameterId: string;
    contextProperty: string;
};

export type Insights = {
    insights: Insight[];
    totalCount: number;
};

export type CreateInsightData = {
    customerSegmentId: number;
    jobToBeDoneId: number;
};

export type CreateTemplatedInsightData = CreateInsightData & {
    template: InsightOrigin & { template: string };
    parameterValues: InsightParameterValue[];
};

export type CreateTemplatedAlternativeSolutionInsightData = CreateTemplatedInsightData & { alternativeSolutionId: number };

export type InsightCoverageEntryData = {
    catalog: string;
    section: string;
    question: string;
    branch: string | null;
    coverage: InsightCoverage;
};

export type InsightCoverageEntry = InsightCoverageEntryData & {
    id: number;
};

export enum InsightCoverage {
    NotCaptured = 'NotCaptured',
    Captured = 'Captured',
    NoInsights = 'NoInsights'
}

class InsightsService extends HttpServiceBase {
    constructor() {
        super('/api/insights');
    }

    @ErrorWithOperationDisplayName('Get insight catalog for interview type')
    getCatalogByInterviewType(interviewType: InterviewType): Promise<InsightCatalog> {
        return this.performRequest({
            path: `/catalogs/by-interview-type/${interviewType}`
        });
    }

    @ErrorWithOperationDisplayName('Get insights')
    getInsights(
        ideaId: string,
        interviewId?: number,
        notInInterviewId?: number,
        researchId?: number,
        customerSegmentId?: number,
        jobToBeDoneId?: number,
        alternativeSolutionId?: number,
        catalog?: string,
        section?: string,
        question?: string,
        skip?: number,
        take?: number
    ): Promise<Insights> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'interviewId', interviewId?.toString());
        this.addQueryParamIfPresent(queryParams, 'notInInterviewId', notInInterviewId?.toString());
        this.addQueryParamIfPresent(queryParams, 'researchId', researchId?.toString());
        this.addQueryParamIfPresent(queryParams, 'customerSegmentId', customerSegmentId?.toString());
        this.addQueryParamIfPresent(queryParams, 'jobToBeDoneId', jobToBeDoneId?.toString());
        this.addQueryParamIfPresent(queryParams, 'alternativeSolutionId', alternativeSolutionId?.toString());
        this.addQueryParamIfPresent(queryParams, 'catalog', catalog?.toString());
        this.addQueryParamIfPresent(queryParams, 'section', section?.toString());
        this.addQueryParamIfPresent(queryParams, 'question', question?.toString());
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());

        return this.performRequest({
            path: `/${ideaId}/insights`,
            queryParams
        });
    }

    @ErrorWithOperationDisplayName('Get all insights')
    async getAllInsights(
        ideaId: string,
        interviewId?: number,
        notInInterviewId?: number,
        researchId?: number,
        customerSegmentId?: number,
        jobToBeDoneId?: number,
        alternativeSolutionId?: number,
        catalog?: string,
        section?: string,
        question?: string
    ): Promise<Insight[]> {
        const insights: Insight[] = [];
        while (true) {
            const pagedInsights = await this.getInsights(
                ideaId,
                interviewId,
                notInInterviewId,
                researchId,
                customerSegmentId,
                jobToBeDoneId,
                alternativeSolutionId,
                catalog,
                section,
                question,
                insights.length ? insights.length : undefined
            );
            insights.push(...pagedInsights.insights);
            if (!pagedInsights.insights.length || insights.length === pagedInsights.totalCount) return insights;
        }
    }

    @ErrorWithOperationDisplayName('Get interview insights')
    getInsight(ideaId: string, insightId: number): Promise<Insight> {
        return this.performRequest({
            path: `/${ideaId}/insights/${insightId}`
        });
    }

    @ErrorWithOperationDisplayName('Create Job-to-be-done insight')
    createJobToBeDoneInsight(ideaId: string, interviewId: number, data: CreateTemplatedInsightData): Promise<Insight> {
        return this.performRequest({
            path: `/${ideaId}/insights/job-to-be-done`,
            method: RequestMethod.POST,
            body: {
                interviewId,
                ...data
            }
        });
    }

    @ErrorWithOperationDisplayName('Create alternative solution insight')
    createAlternativeSolutionInsight(ideaId: string, interviewId: number, data: CreateTemplatedAlternativeSolutionInsightData): Promise<Insight> {
        return this.performRequest({
            path: `/${ideaId}/insights/alternative-solution`,
            method: RequestMethod.POST,
            body: {
                interviewId,
                ...data
            }
        });
    }

    @ErrorWithOperationDisplayName('Attach insight to interview')
    attachInsightToInterview(ideaId: string, insightId: number, interviewId: number): Promise<Insight> {
        return this.performRequest({
            path: `/${ideaId}/insights/${insightId}/attach-to-interview`,
            method: RequestMethod.POST,
            body: {
                interviewId
            }
        });
    }

    @ErrorWithOperationDisplayName('Detach insight form interview')
    detachInsightFromInterview(ideaId: string, insightId: number, interviewId: number): Promise<Insight> {
        return this.performRequest({
            path: `/${ideaId}/insights/${insightId}/detach-from-interview`,
            method: RequestMethod.POST,
            body: {
                interviewId
            }
        });
    }

    @ErrorWithOperationDisplayName('Delete insight')
    deleteInsight(ideaId: string, insightId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/insights/${insightId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore insight')
    restoreInsight(ideaId: string, insightId: number): Promise<Insight> {
        return this.performRequest({
            path: `/${ideaId}/insights/${insightId}/restore`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Update insight')
    updateInsight(ideaId: string, insightId: number, parameterValues: InsightParameterValue[]): Promise<Insight> {
        return this.performRequest({
            path: `/${ideaId}/insights/${insightId}`,
            method: RequestMethod.PUT,
            body: {
                parameterValues
            }
        });
    }

    @ErrorWithOperationDisplayName('Get interview insights coverage list')
    getInterviewInsightsCoverageList(ideaId: string, interviewId: number): Promise<InsightCoverageEntry[]> {
        return this.performRequest({
            path: `/${ideaId}/interview-insight-coverage/${interviewId}`
        });
    }

    @ErrorWithOperationDisplayName('Get interview insights coverage')
    getInterviewInsightCoverage(ideaId: string, interviewId: number, coverageEntryId: number): Promise<InsightCoverageEntry> {
        return this.performRequest({
            path: `/${ideaId}/interview-insight-coverage/${interviewId}/${coverageEntryId}`
        });
    }

    @ErrorWithOperationDisplayName('Create interview insight coverage')
    createInterviewInsightsCoverage(ideaId: string, interviewId: number, data: InsightCoverageEntryData): Promise<InsightCoverageEntry> {
        return this.performRequest({
            path: `/${ideaId}/interview-insight-coverage/${interviewId}`,
            method: RequestMethod.POST,
            body: data
        });
    }

    @ErrorWithOperationDisplayName('Set interview insights coverage')
    setInterviewInsightsCoverage(ideaId: string, interviewId: number, coverageEntryId: number, coverage: InsightCoverage): Promise<InsightCoverageEntry> {
        return this.performRequest({
            path: `/${ideaId}/interview-insight-coverage/${interviewId}/${coverageEntryId}`,
            method: RequestMethod.PUT,
            body: {
                coverage
            }
        });
    }
}

export const insightsService = new InsightsService();
