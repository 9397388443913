import { Reveal } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, forwardRef, useImperativeHandle, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as SmallExpandIcon } from '../../icons/chevron-down-sm.svg';
import { ReactComponent as ExpandIcon } from '../../icons/chevron-down.svg';
import { ReactComponent as CollapseIcon } from '../../icons/chevron-up.svg';
import { combineClassNames } from '../../services/common';
import { ResearchContact, ResearchContactStage } from '../../services/researchService';
import { ReducedUser, generateInitials, getPreferredColorIndex } from '../../services/usersService';
import { BoundDropDownButton, DropDownButtonItem } from '../common/boundDropDownButton';
import { PersonSimpleView } from '../contacts/view';
import UserAvatar from '../user/userAvatar';
import { ResearchContactStagePresentation, ResearchContactStageView } from './researchContactStageView';

export type ResearchContactTogglePanelHandle = {
    element: HTMLElement | null;
};

export type ResearchContactTogglePanelProps = {
    researchContact: ResearchContact;
    isExpanded?: boolean;
    onToggle?: () => void;
    onDelete?: () => void;
    stageOverride?: ResearchContactStage;
    children?: ReactNode;
    disabled?: boolean;
    invalid?: boolean;
    headerAdditionalContent?: ReactNode;
    stagePresentation?: ResearchContactStagePresentation;
    highlighted?: boolean;
    additionalActions?: DropDownButtonItem[];
};

export const ResearchContactTogglePanel = forwardRef<ResearchContactTogglePanelHandle, ResearchContactTogglePanelProps>(function ResearchContactTogglePanel(
    {
        researchContact,
        isExpanded,
        onToggle,
        onDelete,
        stageOverride,
        children,
        disabled,
        invalid,
        headerAdditionalContent,
        stagePresentation,
        highlighted,
        additionalActions
    },
    ref
) {
    const elementRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
        ref,
        () => ({
            get element() {
                return elementRef.current;
            }
        }),
        []
    );

    const { ideaId } = useParams();
    const navigate = useNavigate();
    const stage = stageOverride ?? researchContact.stage;

    const headerActions: DropDownButtonItem[] = [
        {
            text: 'Open Contact page',
            action() {
                navigate(`/startups/${ideaId}/contacts/people/${researchContact.contact.id}`);
            }
        }
    ];

    if (additionalActions) headerActions.push(...additionalActions);

    headerActions.push({
        children: (
            <>
                Remove Contact
                <br />
                from this research
            </>
        ),
        danger: true,
        separated: true,
        disabled: disabled || !onDelete,
        action: onDelete
    });
    return (
        <div
            ref={elementRef}
            className={combineClassNames(
                'k-icp-panel !k-rounded k-px-4 k-py-2',
                disabled ? 'k-disabled k-icp-ghost' : undefined,
                invalid ? 'k-invalid' : undefined,
                highlighted ? '!k-border-dark' : undefined
            )}
        >
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
                <PersonSimpleView
                    person={researchContact.contact}
                    renderAsLink={true}
                    showJobTitle={true}
                    additionalContent={
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-icp-component-border">
                            <ResearchContactStageView stage={stage} presentation={stagePresentation} />
                            {headerAdditionalContent}

                            <BoundDropDownButton fillMode="flat" size="small" icon="more-horizontal" items={headerActions} />
                            <div className="k-separator" />
                            <Button size="small" fillMode="flat" className="k-icp-svg-icon-button" onClick={onToggle} disabled={!children}>
                                {isExpanded ? <CollapseIcon className="k-icp-icon" /> : <ExpandIcon className="k-icp-icon" />}
                            </Button>
                        </StackLayout>
                    }
                />
                {children && (
                    <Reveal>
                        {isExpanded && (
                            <div className="k-pt-3 k-pb-2 k-ml-14">
                                <div className="k-icp-bordered-top k-icp-component-border k-pt-4">{children}</div>
                            </div>
                        )}
                    </Reveal>
                )}
            </StackLayout>
        </div>
    );
});

export function ResearchContactRelatedUser({ ideaId, user, scheduleId }: { ideaId: string; user: ReducedUser; scheduleId?: number }) {
    const navigate = useNavigate();

    const items: DropDownButtonItem[] = [{ text: `Assigned to: ${user.firstName} ${user.lastName}` }];
    if (scheduleId !== undefined)
        items.push({
            text: 'Open Schedule',
            separated: true,
            action() {
                navigate(`/startups/${ideaId}/calendar/schedules/${scheduleId}`);
            }
        });

    return (
        <BoundDropDownButton
            size="small"
            rounded="full"
            buttonClass="k-icp-svg-icon-button"
            openedClassName="k-hover"
            popupSettings={{
                popupClass: 'k-menu-popup-with-header research-contact-related-user-popup'
            }}
            text={
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-thin">
                    <UserAvatar size="small" picture={user.picture} initials={generateInitials(user, 2)} colorIndex={getPreferredColorIndex(user)} />
                    <SmallExpandIcon className="k-icp-icon" />
                </StackLayout>
            }
            items={items}
        />
    );
}
