import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQueryParam, useReturnUrl } from '../../hooks/routerHooks';
import emailConfirmedImageUrl from '../../images/auth/email-confirmed-illustration.svg';
import invalidEmailConfirmationImageUrl from '../../images/auth/invalid-email-confirmation-illustration.svg';
import checkEmailImageUrl from '../../images/auth/verify-email-illustration.svg';
import { AuthFormSubmitButton } from '../../pages/layouts/authLayout';
import { authenticationService } from '../../services/authenticationService';
import { pathWithReturnUrl } from '../../services/common';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import { useInvisibleReCaptcha } from '../common/invisibleReCaptcha';
import { H1, P } from '../ui/typography';

export function ActivationRequiredNotice({ emailAddress, justSentEmail }: { emailAddress: string; justSentEmail?: boolean }) {
    const returnUrl = useReturnUrl();
    const dispatch = useAppDispatch();
    const getReCaptchaToken = useInvisibleReCaptcha();

    async function resendActivationEmail() {
        const reCaptchaToken = await getReCaptchaToken?.();
        await authenticationService.resendActivationEmail(emailAddress, returnUrl, reCaptchaToken);
        dispatch(addNotification({ content: `Email with confirmation link was sent to ${emailAddress}` }));
    }

    return (
        <>
            <H1>Verify your email address</H1>
            <img src={checkEmailImageUrl} width="230" height="160" alt="Check email" />
            <div className="auth-page-content-section">
                <P className="!k-mb-4">
                    {justSentEmail ? (
                        <span>
                            Almost there! We've sent an email with confirmation link to <strong>{emailAddress}</strong>.
                        </span>
                    ) : (
                        <span>
                            Your account is almost ready! Just verify your email address: <strong>{emailAddress}</strong>.
                        </span>
                    )}
                </P>
                <P>
                    {justSentEmail
                        ? "Just click on the link to complete your registration. If you don't see it, you may need to check your spam folder."
                        : "Check your inbox for a message with a confirmation link. If you don't see it, you may need to check your spam folder."}
                </P>
            </div>
            <div className="auth-page-content-section k-pt-4 k-icp-bordered-top k-icp-component-border k-text-center">
                <span className="k-icp-line-height-inline-md">Can't find the email? </span>
                <Button fillMode="link" themeColor="secondary" className="!k-p-0" onClick={resendActivationEmail}>
                    Resend email
                </Button>
            </div>
        </>
    );
}

export function ActiveAccountView({ heading, children }: { heading: string; children: string }) {
    const returnUrl = useReturnUrl();

    return (
        <>
            <H1 className="auth-page-content-section k-text-center">{heading}</H1>
            <img src={emailConfirmedImageUrl} width="230" height="160" alt="Email confirmed" />
            <StackLayout className="auth-page-content-section k-gap-8" orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
                <P>{children}</P>
                <Link to={returnUrl} className="k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md k-align-self-center">
                    Go to Icanpreneur platform
                </Link>
            </StackLayout>
        </>
    );
}

export function InvalidActivationView({ heading, children }: { heading: string; children?: ReactNode }) {
    const returnUrl = useReturnUrl();
    const email = useQueryParam('email');
    const [requestedNewActivationLink, setRequestedNewActivationLink] = useState(false);
    const getReCaptchaToken = useInvisibleReCaptcha();

    async function requestNewResetPassword() {
        if (!email) {
            return;
        }

        const reCaptchaToken = await getReCaptchaToken?.();
        await authenticationService.resendActivationEmail(email, returnUrl, reCaptchaToken);
        setRequestedNewActivationLink(true);
    }

    if (requestedNewActivationLink) return <ActivationRequiredNotice emailAddress={email!} justSentEmail={true} />;

    return (
        <>
            <H1>{heading}</H1>
            <img src={invalidEmailConfirmationImageUrl} width="230" height="160" alt="Invalid email confirmation link" />
            <div className="auth-page-content-section">
                <StackLayout className="k-gap-8" orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }}>
                    {email ? (
                        <>
                            {children}
                            <AuthFormSubmitButton onClick={requestNewResetPassword}>Resend confirmation link</AuthFormSubmitButton>
                        </>
                    ) : (
                        <Link
                            to={pathWithReturnUrl('/login', returnUrl)}
                            className="k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md k-align-self-center"
                        >
                            Log in
                        </Link>
                    )}
                </StackLayout>
            </div>
        </>
    );
}
