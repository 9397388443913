import { Popover } from '@progress/kendo-react-tooltip';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useFittingPopoverCallout } from '../../hooks/commonHooks';
import { useSoleToggle } from '../../hooks/toggleHooks';
import { logoutPagePath } from '../../hooks/userHooks';
import { useAppSelector } from '../../state/hooks';
import UserAvatar from '../user/userAvatar';

export default function UserProfileNavItem() {
    const currentUser = useAppSelector(s => s.user);

    const toggleRef = useRef<HTMLButtonElement>(null);
    const [show, toggleShow] = useSoleToggle(false);
    const [popoverId, onPopoverPosition] = useFittingPopoverCallout('horizontal', toggleRef.current, -10);

    if (!currentUser) return null;

    return (
        <div>
            <button
                className={`k-button-clear k-reset k-cursor-pointer k-icp-avatar-interactive-group${show ? ' k-selected' : ''}`}
                ref={toggleRef}
                onClick={e => {
                    e.stopPropagation();
                    toggleShow();
                }}
            >
                <UserAvatar picture={currentUser.picture} initials={currentUser.initials} colorIndex={currentUser.colorIndex} />
                <span className="k-icon k-i-arrow-chevron-down"></span>
            </button>
            <Popover
                id={popoverId}
                show={show}
                anchor={toggleRef.current}
                collision={{ horizontal: 'fit', vertical: 'flip' }}
                position="bottom"
                className="!k-pr-3 k-icp-no-padding-popover"
                margin={{ vertical: 10, horizontal: 0 }}
                positionMode="fixed"
                title={
                    <div>
                        <span className="k-h2 k-icp-font-weight-medium k-text-nowrap">
                            {currentUser.firstName} {currentUser.lastName}
                        </span>
                        <div className="k-icp-subtle-text">{currentUser.emailAddress}</div>
                    </div>
                }
                onPosition={onPopoverPosition}
            >
                <div className="k-list k-list-md">
                    <Link className="k-list-item !k-px-4" to="/account">
                        My profile
                    </Link>
                    <Link className='k-list-item !k-px-4' to="/account/subscription">My subscription</Link>
                    <div className="k-separator"></div>
                    <Link className="k-list-item !k-px-4" to={logoutPagePath}>
                        Log out
                    </Link>
                </div>
            </Popover>
        </div>
    );
}
