import { ButtonProps } from '@progress/kendo-react-buttons';
import { IconWrap, SVGIcon, classNames, kendoThemeMaps } from '@progress/kendo-react-common';
import { ReactNode, forwardRef } from 'react';

export const DivButton = forwardRef<
    HTMLDivElement,
    {
        size?: ButtonProps['size'];
        fillMode?: ButtonProps['fillMode'];
        themeColor?: ButtonProps['themeColor'];
        rounded?: ButtonProps['rounded'];
        disabled?: boolean;
        selected?: boolean;
        className?: string;
        children?: ReactNode;
        icon?: string;
        svgIcon?: SVGIcon;
        onClick?: (e: React.UIEvent<HTMLDivElement>) => void;
    }
>(function({ size, fillMode, themeColor, rounded, disabled, selected, className, children, icon, svgIcon, onClick }, ref) {
    const hasIcon = icon !== undefined || svgIcon !== undefined;

    return (
        <div
            ref={ref}
            className={getButtonClassName({ size, fillMode, themeColor, rounded, hasIcon, disabled, selected, className })}
            tabIndex={0}
            onClick={onClick}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    e.stopPropagation();
                    e.preventDefault();
                    onClick?.(e);
                } else if (e.key === ' ') {
                    // Prevent page scroll on space
                    e.preventDefault();
                }
            }}
            onKeyUp={e => {
                if (e.key === ' ') {
                    e.stopPropagation();
                    e.preventDefault();
                    onClick?.(e);
                }
            }}
        >
            {hasIcon && <IconWrap className="k-button-icon" name={icon} icon={svgIcon} />}
            {children && <span className="k-button-text">{children}</span>}
        </div>
    );
});

export function getButtonClassName({
    size = 'medium',
    fillMode = 'solid',
    themeColor = 'base',
    rounded = 'medium',
    hasIcon,
    disabled,
    selected,
    className
}: {
    size?: ButtonProps['size'];
    fillMode?: ButtonProps['fillMode'];
    themeColor?: ButtonProps['themeColor'];
    rounded?: ButtonProps['rounded'];
    hasIcon?: boolean;
    disabled?: boolean;
    selected?: boolean;
    className?: string;
}) {
    return classNames(
        'k-button',
        {
            ['k-button-'.concat(size ? kendoThemeMaps.sizeMap[size].valueOf() || size : '')]: size,
            ['k-button-'.concat(fillMode ?? '')]: fillMode,
            ['k-button-'.concat(fillMode ?? '', '-').concat(themeColor ?? '')]: Boolean(fillMode && themeColor),
            ['k-rounded-'.concat(rounded ? kendoThemeMaps.roundedMap[rounded].valueOf() || rounded : '')]: rounded,
            'k-icon-button': hasIcon,
            'k-disabled': disabled,
            'k-selected': selected
        },
        className
    );
}
