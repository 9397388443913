import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CountriesComboBox, useCountryFor } from '../../components/common/countries';
import { ValidatedInput, defaultValidators, phoneValidator } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { useProfile } from '../../hooks/profileHooks';

export default function EditProfilePage() {
    const navigate = useNavigate();
    const [phoneNumberError, setPhoneNumberError] = useState<string>();
    const { currentProfile, updateProfile } = useProfile();
    const [initialCountry, countriesList] = useCountryFor(currentProfile);

    async function onSubmitProfile(data: { [name: string]: any }) {
        const phoneErrorMessage = await phoneValidator(data.phoneNumber, 'Phone number');
        if (phoneErrorMessage) {
            setPhoneNumberError(phoneErrorMessage);

            return;
        }

        await updateProfile({
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber || null,
            countryCode: data.country?.code || null,
            city: data.city || null,
            jobTitle: data.jobTitle || null,
            companyName: data.companyName || null
        });

        navigate('..');
    }

    return (
        <div className="page-content-section">
            <H1 className="heading-row">Edit personal info</H1>
            <div className="page-content-middle">
                {currentProfile && typeof initialCountry !== 'undefined' ? (
                    <Form
                        initialValues={{ ...currentProfile, country: initialCountry }}
                        onSubmit={onSubmitProfile}
                        ignoreModified={true}
                        render={formRenderProps => (
                            <FormElement>
                                <StackLayout className="k-gap-6 " orientation="vertical">
                                    <StackLayout className="k-gap-6 k-px-8 k-pb-8" orientation="vertical">
                                        <Field
                                            name="firstName"
                                            component={ValidatedInput}
                                            validator={defaultValidators.firstNameValidators}
                                            maxLength={50}
                                            label="First name"
                                        />{' '}
                                        <Field
                                            name="lastName"
                                            label="Last name"
                                            component={ValidatedInput}
                                            validator={defaultValidators.lastNameValidators}
                                            maxLength={50}
                                        />
                                        <Field name="emailAddress" component={ValidatedInput} type="email" disabled={true} label="Email" />
                                        <Field
                                            name="phoneNumber"
                                            label="Phone number"
                                            component={ValidatedInput}
                                            errorMessage={phoneNumberError}
                                            onChange={() => setPhoneNumberError(undefined)}
                                            maxLength={50}
                                        />
                                        <Field
                                            name="country"
                                            label="Country"
                                            component={ValidatedInput}
                                            inputType={CountriesComboBox}
                                            data={countriesList}
                                            required={true}
                                        />
                                        <Field name="city" label="City" component={ValidatedInput} maxLength={50} />
                                        <Field name="jobTitle" component={ValidatedInput} maxLength={50} label="Job title" />
                                        <Field name="companyName" label="Company" component={ValidatedInput} maxLength={50} />
                                    </StackLayout>
                                    <StackLayout className="k-gap-6" align={{ horizontal: 'center' }}>
                                        <Button type="submit" themeColor="primary" size="large" disabled={!formRenderProps.allowSubmit}>
                                            Update
                                        </Button>
                                        <Button type="button" fillMode="flat" size="large" onClick={() => navigate('..')}>
                                            Cancel
                                        </Button>
                                    </StackLayout>
                                </StackLayout>
                            </FormElement>
                        )}
                    />
                ) : (
                    <div className="k-text-center">
                        <LoadingIndicator size="big" />
                    </div>
                )}
            </div>
        </div>
    );
}
