import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { cloneElement, useEffect, useMemo, useState } from 'react';
import { appConfig } from '../../config';
import { useGlobalCanvas } from '../../hooks/canvasHooks';
import { useInProgressOperationsTracker } from '../../hooks/commonHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReactComponent as CreateIcon } from '../../icons/check.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { ReactComponent as AddIcon } from '../../icons/plus.svg';
import { ReactComponent as DeleteIcon } from '../../icons/trash-2.svg';
import { ReactComponent as RemoveIcon } from '../../icons/x.svg';
import noAlternativeSolutionsImageUrl from '../../images/empty-alternative-solutions-illustration.svg';
import { BoxItem, BoxType, ItemStats, canvasService } from '../../services/canvasService';
import { toRecord } from '../../services/common';
import { deleteItemWithUndo, saveItem } from '../../state/canvas/canvasSlice';
import { useAppDispatch } from '../../state/hooks';
import { CellTemplateProps, createCellTemplateFromComponent } from '../common/grid';
import { ValidatedInput, defaultValidators } from '../ui/inputs';
import LoadingIndicator from '../ui/loadingIndicator';
import { SvgIconButtonContent } from '../ui/svgIconButtonContent';
import { P } from '../ui/typography';

type AlternativeSolutionWithStats = { id: null; inEdit: true } | { id: number; inEdit: boolean; item: BoxItem; stats?: ItemStats };
export function ManageAlternativeSolutionsDialog({ onClose }: { onClose?: () => void }) {
    const { ideaId } = useIdeaParams();
    const { canvas } = useGlobalCanvas(undefined, true);
    const alternativeSolutions = canvas.boxes?.find(b => b.type === BoxType.AlternativeSolutions)?.items;
    const [itemInEditId, setItemInEditId] = useState<number | null | undefined>();
    const [deleteAlternativeSolutionStats, setDeleteAlternativeSolutionStats] = useState<ItemStats>();
    const [alternativeSolutionsStats, setAlternativeSolutionsStats] = useState<Partial<Record<number, ItemStats>>>();

    useEffect(() => {
        canvasService.getItemsStats(ideaId, BoxType.AlternativeSolutions).then(stats => setAlternativeSolutionsStats(toRecord(stats, s => s.itemId)));
    }, [ideaId]);

    const alternativeSolutionsWithStats = useMemo(() => {
        if (!alternativeSolutions) return undefined;

        const alternativeSolutionsWithStats: AlternativeSolutionWithStats[] = itemInEditId === null ? [{ id: null, inEdit: true }] : [];
        alternativeSolutions.forEach(alternativeSolution =>
            alternativeSolutionsWithStats.push({
                id: alternativeSolution.id,
                item: alternativeSolution,
                inEdit: alternativeSolution.id === itemInEditId,
                stats: alternativeSolutionsStats?.[alternativeSolution.id]
            })
        );
        return alternativeSolutionsWithStats;
    }, [alternativeSolutions, alternativeSolutionsStats, itemInEditId]);

    const dispatch = useAppDispatch();

    return (
        <Dialog title="Manage Alternative solutions" onClose={onClose} width={640} contentStyle={{ minHeight: 327 }}>
            {alternativeSolutionsWithStats ? (
                alternativeSolutionsWithStats.length ? (
                    <Grid
                        data={alternativeSolutionsWithStats}
                        className="k-grid-no-scrollbar k-icp-grid-no-header -maxh100"
                        dataItemKey="id"
                        rowRender={(row, props) => {
                            if (!props.isInEdit || props.rowType !== 'data') return row;

                            const alternativeSolution: AlternativeSolutionWithStats = props.dataItem;
                            const isCreating = alternativeSolution.id === null;
                            return cloneElement(
                                row,
                                undefined,
                                <td colSpan={props.children instanceof Array ? props.children.length : undefined}>
                                    <AlternativeSolutionUpsertForm
                                        content={isCreating ? '' : alternativeSolution.item.content}
                                        onSave={content =>
                                            dispatch(
                                                saveItem({
                                                    boxType: BoxType.AlternativeSolutions,
                                                    content: content,
                                                    itemId: isCreating ? undefined : alternativeSolution.id
                                                })
                                            )
                                                .unwrap()
                                                .then(result => {
                                                    setItemInEditId(undefined);
                                                    if (result && isCreating)
                                                        setAlternativeSolutionsStats(stats => ({
                                                            ...stats,
                                                            [result.itemId]: {
                                                                itemId: result.itemId,
                                                                contactCount: 0,
                                                                hypothesisCount: 0,
                                                                insightCount: 0,
                                                                researchCount: 0
                                                            }
                                                        }));
                                                })
                                        }
                                        onCancel={() => setItemInEditId(undefined)}
                                        saveButtonText={isCreating ? 'Create' : 'Update'}
                                    />
                                </td>
                            );
                        }}
                        editField="inEdit"
                        onItemChange={e => {
                            if (e.field !== 'command') return;
                            const alternativeSolutionDataItem: AlternativeSolutionWithStats = e.dataItem;
                            if (e.value === 'edit') setItemInEditId(alternativeSolutionDataItem.id);
                            else if (e.value === 'delete') {
                                if (alternativeSolutionDataItem.id === null) setItemInEditId(undefined);
                                else {
                                    if (alternativeSolutionDataItem.inEdit) setItemInEditId(undefined);

                                    if (
                                        alternativeSolutionDataItem.stats &&
                                        (alternativeSolutionDataItem.stats.hypothesisCount || alternativeSolutionDataItem.stats.insightCount)
                                    ) {
                                        setDeleteAlternativeSolutionStats(alternativeSolutionDataItem.stats);
                                        return;
                                    }

                                    dispatch(
                                        deleteItemWithUndo(BoxType.AlternativeSolutions, alternativeSolutionDataItem.id, {
                                            content: 'Item deleted.',
                                            actionText: 'Undo'
                                        })
                                    );
                                }
                            }
                        }}
                    >
                        <GridToolbar>
                            <Button onClick={() => setItemInEditId(null)}>
                                <SvgIconButtonContent icon={AddIcon}>Add new Alternative solution</SvgIconButtonContent>
                            </Button>
                        </GridToolbar>
                        <GridColumn field="item.content" />
                        <GridColumn cell={AlternativeSolutionStatsGridCellTemplate} width="230" />
                        <GridColumn cell={AlternativeSolutionActionsCellTemplate} width="100" className="k-icp-actions-column" />
                    </Grid>
                ) : (
                    <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="-h100">
                        <img src={noAlternativeSolutionsImageUrl} width="86" height="87" alt="No alternative solutions" className="responsive-image" />
                        <P className="!k-mb-4">No Alternative solutions available</P>
                        <Button onClick={() => setItemInEditId(null)} fillMode="outline" themeColor="secondary">
                            <SvgIconButtonContent icon={AddIcon}>Add new Alternative solution</SvgIconButtonContent>
                        </Button>
                    </StackLayout>
                )
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}

            {deleteAlternativeSolutionStats && (
                <AlternativeSolutionInUseDeleteDialog stats={deleteAlternativeSolutionStats} onClose={() => setDeleteAlternativeSolutionStats(undefined)} />
            )}
            <DialogActionsBar layout="center">
                <Button type="button" onClick={onClose}>
                    Done
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

const AlternativeSolutionActionsCellTemplate = createCellTemplateFromComponent(function({
    dataItem: alternativeSolution,
    cellProps
}: CellTemplateProps<AlternativeSolutionWithStats>) {
    const onChange = cellProps.onChange;
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Button
                className="k-icp-svg-icon-button"
                onClick={
                    onChange
                        ? e => {
                              onChange({ dataItem: cellProps.dataItem, dataIndex: cellProps.dataIndex, syntheticEvent: e, field: 'command', value: 'edit' });
                          }
                        : undefined
                }
            >
                <EditIcon className="k-icp-icon" />
            </Button>
            <Button
                disabled={alternativeSolution.id !== null && !alternativeSolution.stats}
                className="k-icp-svg-icon-button"
                onClick={
                    onChange
                        ? e => {
                              onChange({
                                  dataItem: cellProps.dataItem,
                                  dataIndex: cellProps.dataIndex,
                                  syntheticEvent: e,
                                  field: 'command',
                                  value: 'delete'
                              });
                          }
                        : undefined
                }
            >
                <DeleteIcon className="k-icp-icon" />
            </Button>
        </StackLayout>
    );
});

const AlternativeSolutionStatsGridCellTemplate = createCellTemplateFromComponent(function({
    dataItem: alternativeSolution
}: CellTemplateProps<AlternativeSolutionWithStats>) {
    if (alternativeSolution.id === null) return null;

    return (
        <span className="k-fs-sm k-icp-subtle-text">
            Used in{' '}
            {alternativeSolution.stats ? (
                alternativeSolution.stats.hypothesisCount
            ) : (
                <Skeleton shape="text" className="!k-display-inline-block" style={{ width: 7 }} />
            )}{' '}
            hypothes{alternativeSolution.stats && alternativeSolution.stats.hypothesisCount === 1 ? 'i' : 'e'}s,{' '}
            {alternativeSolution.stats ? (
                alternativeSolution.stats.insightCount
            ) : (
                <Skeleton shape="text" className="!k-display-inline-block" style={{ width: 7 }} />
            )}{' '}
            insight{alternativeSolution.stats && alternativeSolution.stats.insightCount === 1 ? '' : 's'}
        </span>
    );
});

function AlternativeSolutionUpsertForm({
    content,
    onSave,
    onCancel,
    saveButtonText
}: {
    content?: string;
    onSave: (content: string) => Promise<unknown>;
    onCancel: () => void;
    saveButtonText?: string;
}) {
    const [saveInProgress, createSaveCallback] = useInProgressOperationsTracker();

    return (
        <Form
            onSubmit={createSaveCallback(async (data: Record<string, any>) => {
                const content = data.content;
                return onSave?.(content);
            })}
            ignoreModified={true}
            initialValues={{ content }}
            render={formRenderProps => (
                <FormElement>
                    <div
                        onKeyDown={e => {
                            if (e.key === 'Escape') {
                                onCancel();
                                e.stopPropagation();
                            }
                        }}
                    >
                        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                            <Field
                                name="content"
                                component={ValidatedInput}
                                validator={defaultValidators.itemTextValidators}
                                maxLength={appConfig.canvas.item.maxLength}
                                placeholder="Type an alternative solution..."
                                wrapperClass="k-flex-1 k-mr-3"
                                disabled={saveInProgress}
                                autoFocus
                            />

                            <Button disabled={!formRenderProps.allowSubmit || saveInProgress} type="submit">
                                <SvgIconButtonContent icon={CreateIcon}>{saveButtonText || 'Save'}</SvgIconButtonContent>
                            </Button>
                            <Button type="reset" onClick={onCancel} disabled={saveInProgress}>
                                <SvgIconButtonContent icon={RemoveIcon}>Cancel</SvgIconButtonContent>
                            </Button>
                        </StackLayout>
                    </div>
                </FormElement>
            )}
        />
    );
}

function AlternativeSolutionInUseDeleteDialog({ onClose, stats }: { onClose: () => void; stats: ItemStats }) {
    return (
        <Dialog title="Delete Alternative solution" onClose={onClose} width={480}>
            This alternative solution cannot be deleted since it is associated with
            {stats.hypothesisCount ? ` ${stats.hypothesisCount} hypothes${stats.hypothesisCount === 1 ? 'i' : 'e'}s` : ''}
            {stats.hypothesisCount > 0 && stats.insightCount > 0 && ' and'}
            {stats.insightCount ? ` ${stats.insightCount} insight${stats.insightCount === 1 ? '' : 's'}` : ''}.
            <DialogActionsBar layout="center">
                <Button onClick={onClose}>OK</Button>
            </DialogActionsBar>
        </Dialog>
    );
}
