import { Reveal } from '@progress/kendo-react-animation';
import { ComponentClass, FunctionComponent, createElement } from 'react';
import { ReactComponent as CloseIcon } from '../../icons/x.svg';
import { Feedback } from '../../services/feedbackService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { ModalView, showModal } from '../../state/ui/modalSlice';
import { resetSidePanel } from '../../state/ui/sidePanelSlice';
import FeedbackForm from '../feedback/feedbackForm';
import { H1 } from '../ui/typography';
import SidePanelButton from './sidePanelButton';

const sidePanelDefinitions: Record<string, { title: string; componentType: FunctionComponent<any> | ComponentClass<any, any> }> = {
    feedback: { title: 'Feedback', componentType: FeedbackSidePanel }
};

export default function SidePanel() {
    const sidePanelInfo = useAppSelector(s => s.sidePanel);
    const sidePanelDefinition = sidePanelInfo.view ? sidePanelDefinitions[sidePanelInfo.view] : null;

    return (
        <Reveal direction="horizontal" className="k-flex-shrink-0" componentChildClassName="-h100">
            {sidePanelDefinition ? (
                <div className="side-panel page-content-section">
                    <div className="heading-row k-d-flex k-justify-content-between k-align-items-center">
                        <H1>{sidePanelDefinition.title}</H1>
                        <SidePanelButton>
                            <CloseIcon className="k-icp-icon" />
                        </SidePanelButton>
                    </div>
                    {createElement(sidePanelDefinition.componentType, sidePanelInfo.params)}
                </div>
            ) : null}
        </Reveal>
    );
}

function FeedbackSidePanel({ ...params }) {
    const dispatch = useAppDispatch();

    const onFeedbackSubmitted = (item: Feedback) => {
        dispatch(showModal({ view: ModalView.FeedbackSubmitted, data: item.id }));
        dispatch(resetSidePanel());
    };

    const onFeedbackCanceled = () => {
        dispatch(resetSidePanel());
    };

    return <FeedbackForm onCancel={onFeedbackCanceled} onSubmitted={onFeedbackSubmitted} {...params} />;
}
