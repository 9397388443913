import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: { view?: string; params?: any } = {};

export const sidePanelSlice = createSlice({
    name: 'sidePanel',
    initialState,
    reducers: {
        resetSidePanel: () => {
            return {};
        },
        showInSidePanel: (state, action: PayloadAction<{ view: string; params?: undefined }>) => {
            state.view = action.payload.view;
            state.params = action.payload.params;
        },
    },
});

export const { resetSidePanel, showInSidePanel } = sidePanelSlice.actions;

export default sidePanelSlice.reducer;
