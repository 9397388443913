import { Account } from './accountsService';
import { ErrorWithOperationDisplayName, FileResponse } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { MemberActivity, ReducedUser, UserMembershipEntry, UserRole } from './usersService';

export interface IdeaStats {
    lastActivity: Date;
}

export interface Idea {
    uniqueId: string;
    account: Account;
    title: string;
    logo?: string | null;
    description: string;
    stats?: IdeaStats;
    memberships?: UserMembershipEntry[];
    invites?: Invite[];
}

export interface UpdateIdea extends Omit<Idea, 'account'> {
    accountUniqueId: string;
}

export enum InviteState {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Declined = 'Declined',
    Revoked = 'Revoked'
}

export interface Invite {
    id: number;
    expires: Date;
    role: UserRole;
    emailAddress: string;
    creator: ReducedUser;
    idea?: Idea;
    state: InviteState;
}

class IdeasService extends HttpServiceBase {
    constructor() {
        super('/api/ideas');
    }

    @ErrorWithOperationDisplayName('Get startups for current user')
    async getAvailable(includeMembers = false, includeStats = false, includeInvites = false): Promise<Idea[]> {
        const ideas = await this.performRequest<Idea[]>({
            path: '',
            queryParams: {
                includeMembers: includeMembers.toString(),
                includeStats: includeStats.toString(),
                includeInvites: includeInvites.toString()
            }
        });

        dateTimeService.ensureDateType(i => i.stats, 'lastActivity', ...ideas);

        return ideas;
    }

    @ErrorWithOperationDisplayName('Create startup')
    async create(ideaData: Partial<UpdateIdea>): Promise<Idea> {
        const ideaToCreate: UpdateIdea = {
            uniqueId: '',
            accountUniqueId: '',
            title: '',
            logo: null,
            description: '',
            ...ideaData
        };

        const createdIdea = await this.performRequest<Idea>({
            path: '',
            body: ideaToCreate,
            method: RequestMethod.POST
        });

        return createdIdea;
    }

    @ErrorWithOperationDisplayName('Get startup')
    async get(ideaId: string, includeMembers = false, includeStats = false, includeInvites = false): Promise<Idea | null> {
        const foundIdea = await this.performRequest<Idea>({
            path: `/${ideaId}`,
            queryParams: {
                includeMembers: includeMembers.toString(),
                includeStats: includeStats.toString(),
                includeInvites: includeInvites.toString()
            }
        });
        if (!foundIdea) return null;

        dateTimeService.ensureDateType(i => i.stats, 'lastActivity', foundIdea);

        return foundIdea;
    }

    @ErrorWithOperationDisplayName('Update startup')
    async partialUpdate(ideaId: string, ideaData: Partial<Omit<UpdateIdea, 'uniqueId'>>): Promise<Idea> {
        if (!ideaData || !ideaId) throw new Error('Idea id adn data are required!');

        return this.performRequest({
            path: `/${ideaId}`,
            method: RequestMethod.PATCH,
            body: ideaData
        });
    }

    @ErrorWithOperationDisplayName('Update startup')
    async update(idea: UpdateIdea): Promise<Idea> {
        const updatedIdea = await this.performRequest<Idea>({
            path: `/${idea.uniqueId}`,
            method: RequestMethod.PUT,
            body: idea
        });

        return updatedIdea;
    }

    @ErrorWithOperationDisplayName('Delete startup')
    delete(ideaId: string) {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName("Get connected accounts")
    async getAssociatedAccounts(ideaId: string): Promise<Account[]> {
        return this.performRequest<Account[]>({ path: `/${ideaId}/associated-accounts` });
    }

    @ErrorWithOperationDisplayName('Get members of startup')
    getMembers(ideaId: string) {
        return this.performRequest<UserMembershipEntry[]>({ path: `/${ideaId}/members` });
    }

    @ErrorWithOperationDisplayName('Get connected members for a startup')
    getConnectedMembers(ideaId: string) {
        return this.performRequest<MemberActivity[]>({ path: `/${ideaId}/members/connected` });
    }

    @ErrorWithOperationDisplayName('Update member of startup')
    updateMember(ideaId: string, userId: string, role: UserRole) {
        return this.performRequest<UserMembershipEntry>({
            path: `/${ideaId}/members`,
            method: RequestMethod.PUT,
            body: {
                userId: userId,
                role: role
            }
        });
    }

    @ErrorWithOperationDisplayName('Remove member from startup')
    removeMember(ideaId: string, userId: string) {
        return this.performRequest({
            path: `/${ideaId}/members/${userId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Upload startup logo')
    uploadLogo(ideaId: string, file: File): Promise<FileResponse> {
        const dataToPost = new FormData();
        dataToPost.append('file', file);

        return this.performRequest({ path: `/${ideaId}/logo`, method: RequestMethod.POST, body: dataToPost });
    }

    @ErrorWithOperationDisplayName('Leave startup')
    leave(ideaId: string) {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/members/me`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Create startup invite')
    async createInvite(ideaId: string, email: string, role: UserRole) {
        const createdInvite = await this.performRequest<Invite>({
            path: `/${ideaId}/invites`,
            method: RequestMethod.POST,
            body: {
                emailAddress: email,
                role: role,
                expirationInDays: 10
            }
        });

        dateTimeService.ensureDateType(i => i, 'expires', createdInvite);

        return createdInvite;
    }

    @ErrorWithOperationDisplayName('Get startup invites')
    async getInvites(ideaId: string) {
        const invites = await this.performRequest<Invite[]>({
            path: `/${ideaId}/invites`
        });

        dateTimeService.ensureDateType(i => i, 'expires', ...invites);

        return invites;
    }

    @ErrorWithOperationDisplayName('Resend startup invite')
    async resendInvite(ideaId: string, id: number) {
        const resentInvite = await this.performRequest<Invite>({
            path: `/${ideaId}/invites/${id}/resend`,
            method: RequestMethod.POST
        });

        dateTimeService.ensureDateType(i => i, 'expires', resentInvite);

        return resentInvite;
    }

    @ErrorWithOperationDisplayName('Revoke startup invite')
    revokeInvite(ideaId: string, id: number) {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/invites/${id}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Get invite for startup')
    async getInvite(secret: string) {
        const invite = await this.performRequest<Invite>({
            path: `/invites/${secret}`,
            method: RequestMethod.GET
        });

        dateTimeService.ensureDateType(i => i, 'expires', invite);

        return invite;
    }

    @ErrorWithOperationDisplayName('Accept invite for startup')
    acceptInvite(secret: string) {
        return this.performRequestWithoutParsingResponse({
            path: `/invites/${secret}/accept`,
            method: RequestMethod.POST,
            body: {
                accept: true
            }
        });
    }

    @ErrorWithOperationDisplayName('Decline invite for startup')
    declineInvite(secret: string) {
        return this.performRequestWithoutParsingResponse({
            path: `/invites/${secret}/decline`,
            method: RequestMethod.POST
        });
    }
}

export const ideasService = new IdeasService();
