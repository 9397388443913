import { StackLayout } from '@progress/kendo-react-layout';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useInterviewSections } from '../../../hooks/interviewHooks';
import emptyInterviewConversationIllustrationUrl from '../../../images/empty-interview-conversation-illustration.svg';
import { ReducedPerson } from '../../../services/contactsService';
import { Interview, InterviewEntryStatus, InterviewSection, InterviewStage, interviewsService } from '../../../services/interviewsService';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { addNotification } from '../../../state/notifications/platformNotificationsSlice';
import { InlineTimer, InlineTimerHandle } from '../../common/inlineTimer';
import { InterviewContentHandle, useInterviewOperationsContext, useInterviewTitleContext } from '../interviewModal';
import { InterviewContentLoader, InterviewEmptyColumnView, InterviewSecondaryColumnTitle, InterviewThreeColumnLayout } from '../interviewModalLayout';
import { InterviewEntriesCounter, InterviewSectionsEntriesEditList, InterviewSectionsEntriesEditListHandle, InterviewSectionsList } from './interviewEntries';
import { InterviewEntryWithDate, InterviewMessageLog } from './interviewMessageLog';

function findFirstEntryWithoutStatus(interviewSections: InterviewSection[]) {
    for (const interviewSection of interviewSections) {
        const entryWithoutStatus = interviewSection.entries.find(e => !e.hidden && !e.status);
        if (entryWithoutStatus) return entryWithoutStatus;
    }
}
export const InterviewExecuteAndReviewContent = forwardRef<
    InterviewContentHandle,
    { ideaId: string; interviewId: number; interview?: Interview; validateEntries?: boolean; isReadOnly?: boolean }
>(function InterviewExecuteAndReviewContent({ ideaId, interviewId, interview, validateEntries, isReadOnly }, ref) {
    const [selectedEntryId, setSelectedEntryId] = useState<number>();
    const {
        interviewSections,
        loadInterviewSections,
        updateInterviewEntryInState,
        addInterviewEntryToState,
        removeInterviewEntryFromState
    } = useInterviewSections(ideaId, interview?.id, interviewSections => setSelectedEntryId(findFirstEntryWithoutStatus(interviewSections)?.id));
    const currentUser = useAppSelector(s => s.user);
    const { setContent: setTitleContent } = useInterviewTitleContext();
    const titleBarContentRef = useRef<InterviewExecutionTitleBarContentHandle>(null);
    const { updateStage: updateInterviewStage } = useInterviewOperationsContext();
    const isReviewing = interview?.stage === InterviewStage.PendingReview;
    const dispatch = useAppDispatch();
    const entriesEditListRef = useRef<InterviewSectionsEntriesEditListHandle>(null);

    useImperativeHandle(
        ref,
        () => ({
            async onClose() {
                await titleBarContentRef.current?.timer?.pauseIfRunning();
                await entriesEditListRef.current?.awaitPendingOperations();
            },
            onComplete() {
                return entriesEditListRef.current?.commit();
            }
        }),
        []
    );

    useEffect(() => {
        if (!interview || (interview.stage !== InterviewStage.NotStarted && interview.stage !== InterviewStage.Started)) return;

        setTitleContent(
            <InterviewExecutionTitleBarContent
                ref={titleBarContentRef}
                ideaId={ideaId}
                timerId={interview.timerId}
                interviewSections={interviewSections}
                onTimerStart={() => {
                    if (interview.stage !== InterviewStage.NotStarted) return;

                    updateInterviewStage(InterviewStage.Started);
                }}
                readonly={isReadOnly}
            />
        );

        return () => setTitleContent(undefined);
    }, [ideaId, interview, interviewSections, setTitleContent, updateInterviewStage, isReadOnly]);

    async function addEntry(sectionId: number, afterEntryId: number | undefined) {
        await ensureInterviewStarted();
        const newEntry = await interviewsService.createInterviewEntry(ideaId, interviewId, sectionId, afterEntryId);
        setSelectedEntryId(newEntry.id);
        addInterviewEntryToState(sectionId, afterEntryId, newEntry);
    }

    const reviewDependentActions = {
        delete: (isReviewing ? interviewsService.hideInterviewEntry : interviewsService.deleteInterviewEntry).bind(interviewsService),
        restore: (isReviewing ? interviewsService.unhideInterviewEntry : interviewsService.restoreInterviewEntry).bind(interviewsService),
        updateQuestion: (isReviewing ? interviewsService.updateInterviewEntryQuestionRevision : interviewsService.updateInterviewEntryQuestion).bind(
            interviewsService
        ),
        updateAnswer: (isReviewing ? interviewsService.updateInterviewEntryAnswerRevision : interviewsService.updateInterviewEntryAnswer).bind(
            interviewsService
        )
    };

    async function deleteEntry(sectionId: number, entryId: number, explicit?: boolean) {
        const deletedEntry = await reviewDependentActions.delete(ideaId, interviewId, entryId);
        setSelectedEntryId(selectedEntryId => (selectedEntryId === entryId ? undefined : selectedEntryId));

        deletedEntry ? updateInterviewEntryInState(sectionId, deletedEntry) : removeInterviewEntryFromState(sectionId, entryId);

        if (explicit)
            dispatch(
                addNotification({ content: 'Question deleted.', actionText: 'Undo' }, async () => {
                    await reviewDependentActions.restore(ideaId, interviewId, entryId);
                    await loadInterviewSections();
                })
            );
    }

    async function updateEntryQuestion(sectionId: number, entryId: number, question: string) {
        await ensureInterviewStarted();
        const updatedEntry = await reviewDependentActions.updateQuestion(ideaId, interviewId, entryId, question);
        updateInterviewEntryInState(sectionId, updatedEntry);
    }

    async function updateEntryAnswer(sectionId: number, entryId: number, answer: string) {
        await ensureInterviewStarted();
        const updatedEntry = await reviewDependentActions.updateAnswer(ideaId, interviewId, entryId, answer);
        updateInterviewEntryInState(sectionId, updatedEntry);
    }

    async function updateEntryStatus(sectionId: number, entryId: number, status: InterviewEntryStatus | null) {
        await ensureInterviewStarted();
        const updatedEntry = await interviewsService.updateInterviewEntryStatus(ideaId, interviewId, entryId, status);
        updateInterviewEntryInState(sectionId, updatedEntry);
    }

    async function ensureInterviewStarted() {
        if (!interview) return;
        const operationsToAwait: Promise<unknown>[] = [];

        if (interview.stage === InterviewStage.NotStarted) operationsToAwait.push(updateInterviewStage(InterviewStage.Started));
        if (interview.stage === InterviewStage.NotStarted || interview.stage === InterviewStage.Started) {
            const startTimerPromise = titleBarContentRef.current?.timer?.startIfNotRunning();
            if (startTimerPromise) operationsToAwait.push(startTimerPromise);
        }

        if (operationsToAwait.length) await Promise.all(operationsToAwait);
    }

    if (!interviewSections) return <InterviewContentLoader />;

    return (
        <InterviewThreeColumnLayout
            leftContent={
                <>
                    <InterviewSecondaryColumnTitle>Interview script</InterviewSecondaryColumnTitle>
                    <InterviewSectionsList interviewSections={interviewSections} selectedEntryId={selectedEntryId} onEntrySelect={setSelectedEntryId} />
                </>
            }
            rightContent={
                <InterviewConversationView
                    interviewSections={interviewSections}
                    interviewee={interview?.contact}
                    selectedEntryId={selectedEntryId}
                    emptyText={isReviewing || isReadOnly ? 'No captured conversation' : undefined}
                />
            }
        >
            <InterviewSectionsEntriesEditList
                ref={entriesEditListRef}
                interviewSections={interviewSections}
                selectedEntryId={selectedEntryId}
                onEntrySelect={setSelectedEntryId}
                onInsertAfter={addEntry}
                onDelete={deleteEntry}
                onEntryFocus={(_, entryId) => setSelectedEntryId(entryId)}
                onQuestionChange={updateEntryQuestion}
                onAnswerChange={updateEntryAnswer}
                onStatusChange={updateEntryStatus}
                questionPlaceholder={currentUser ? `${currentUser.firstName} ${currentUser.lastName} said...` : undefined}
                answerPlaceholder={interview && `${interview.contact.firstName} ${interview.contact.lastName} answered...`}
                allowMarkAsNotAsked={validateEntries}
                validateEntries={validateEntries}
                isReadOnly={isReadOnly}
            />
        </InterviewThreeColumnLayout>
    );
});

type InterviewExecutionTitleBarContentHandle = {
    timer: InlineTimerHandle | null;
};
const InterviewExecutionTitleBarContent = forwardRef<
    InterviewExecutionTitleBarContentHandle,
    { ideaId: string; timerId?: number | null; interviewSections?: InterviewSection[]; onTimerStart?: () => void; readonly?: boolean }
>(function InterviewTitleBarContent({ ideaId, timerId, interviewSections, onTimerStart, readonly }, ref) {
    const timerRef = useRef<InlineTimerHandle>(null);

    useImperativeHandle(
        ref,
        () => ({
            get timer() {
                return timerRef.current;
            }
        }),
        []
    );

    const timerElement = typeof timerId === 'number' && (
        <InlineTimer ref={timerRef} ideaId={ideaId} timerId={timerId} onStart={onTimerStart} readonly={readonly} />
    );

    const counterElement = interviewSections && <InterviewEntriesCounter interviewSections={interviewSections} />;

    if (!timerElement && !counterElement) return null;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-fs-md k-font-normal k-icp-component-border">
            {counterElement}
            {counterElement && timerElement && <div className="k-separator" />}
            {timerElement}
        </StackLayout>
    );
});

export function InterviewConversationView({
    interviewSections,
    interviewee,
    selectedEntryId,
    emptyText
}: {
    interviewSections: InterviewSection[];
    interviewee?: ReducedPerson;
    selectedEntryId?: number;
    emptyText?: string;
}) {
    const conversation = useMemo(
        () =>
            interviewSections
                .flatMap(s => s.entries.filter((e): e is InterviewEntryWithDate => !!e.date && e.status === InterviewEntryStatus.Covered))
                .sort((a, b) => a.date.getTime() - b.date.getTime()),
        [interviewSections]
    );
    if (!conversation.length || !interviewee)
        return (
            <InterviewEmptyColumnView image={{ url: emptyInterviewConversationIllustrationUrl, width: 64, height: 64, description: 'Empty conversation' }}>
                {emptyText || "Once you start the interview, you'll see the conversation here"}
            </InterviewEmptyColumnView>
        );

    return (
        <>
            <InterviewSecondaryColumnTitle>Conversation</InterviewSecondaryColumnTitle>
            <InterviewMessageLog entries={conversation} interviewee={interviewee} scrollToEntryId={selectedEntryId} />
        </>
    );
}
